import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Container, pxToRem, Title, mq } from '@kisskissbankbank/kitten'
import Buttons from './buttons'
import { fetchProjectOwnerPanelData } from 'kiss/modules/project-page/page-state/actions'
import { markOwnerPanelInitialDataAsFetched } from './actions'
import {
  canCurrentUserUpdate,
  isOwnerPanelDataFetched,
  getProjectSlug,
} from 'kiss/modules/project-page/page-state/selectors'
import StateAndLanguages from './state-and-languages'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { useTranslation } from 'kiss/hooks/use-translation'

const StyledContainer = styled(Container)`
  padding-bottom: ${pxToRem(30)};
  padding-top: ${pxToRem(30)};
  gap: ${pxToRem(15)};

  @media ${mq.mobileAndTablet} {
    padding-left: ${pxToRem(20)};
    padding-right: ${pxToRem(20)};
    display: flex;
    flex-direction: column;
  }

  @media ${mq.tablet} {
    padding-left: ${pxToRem(100)};
    padding-right: ${pxToRem(40)};
  }

  @media ${mq.desktop} {
    padding-left: ${pxToRem(150)};
    padding-right: ${pxToRem(40)};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'title           buttons'
      'projectInfo     projectInfo';

    .kiss-OwnerPanel-title {
      grid-area: title;
    }
    .kiss-OwnerPanel-buttonsWrapper {
      grid-area: buttons;
    }
    .kiss-OwnerPanel-projectInfo {
      gap: ${pxToRem(15)};
      grid-area: projectInfo;
    }
  }
`

const OwnerPanel = () => {
  const dispatch = useDispatch()
  const t = useTranslation()
  const canUpdate = useSelector(canCurrentUserUpdate)
  const projectSlug = useSelector(getProjectSlug)
  const dataFetched = useSelector(isOwnerPanelDataFetched)

  useEffect(() => {
    if (canUpdate && projectSlug) {
      dispatch(fetchProjectOwnerPanelData()).then(() => {
        dispatch(markOwnerPanelInitialDataAsFetched())
      })
    }
  }, [canUpdate, projectSlug])

  if (!canUpdate) return null

  return (
    <div className="k-u-background-color-background3">
      <StyledContainer>
        <div className="kiss-OwnerPanel-title">
          <Title modifier="quaternary">{t('projects.owner_panel.title')}</Title>
          {!dataFetched && (
            <div className="k-u-margin-bottom-double k-u-flex k-u-flex-alignItems-center k-u-flex-justifyContent-center">
              <LoadingAnimation />
            </div>
          )}
        </div>

        {dataFetched && (
          <div className="kiss-OwnerPanel-projectInfo">
            <StateAndLanguages />
          </div>
        )}

        <div className="kiss-OwnerPanel-buttonsWrapper">
          <Buttons />
        </div>
      </StyledContainer>
    </div>
  )
}

export default OwnerPanel
