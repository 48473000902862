import React from 'react'
import {
  Button,
  HorizontalStroke,
  ProfileCard,
  pxToRem,
  Text,
} from '@kisskissbankbank/kitten'
import styled from 'styled-components'
import {
  getProject,
  isSubscribersCountHidden,
  currentUserIsBacking,
} from '../../../../page-state/selectors'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import { getRouteFor, PROJECT_ROUTE } from 'kiss/routes/redux'

const StyledProfileCard = styled(ProfileCard)`
  max-width: ${pxToRem(300)};
`

const NewsProfile = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const project = useSelector(getProject)
  const hideSubscribersCount = useSelector(isSubscribersCountHidden)
  const userIsBacking = useSelector(currentUserIsBacking)

  return (
    <StyledProfileCard className="k-u-margin-top-decuple@m-down">
      <ProfileCard.Avatar
        imageProps={{
          src:
            project?.organization?.avatarImage?.url ||
            project?.owner?.avatarImage?.url,

          alt: project?.organization?.name || project?.owner?.name,
        }}
      />

      <ProfileCard.Content>
        <Text
          size="huge"
          weight="600"
          tag="h1"
          className="k-u-margin-bottom-single"
        >
          {project?.name}
        </Text>
        <Text size="small" tag="p" className="k-u-margin-none">
          {project?.shortDesc}
        </Text>
        {!hideSubscribersCount && (
          <>
            <HorizontalStroke
              size="micro"
              className="k-u-margin-vertical-single k-u-margin-horizontal-auto"
            />
            <Text
              size="micro"
              tag="p"
              className="k-u-margin-none"
              letterSpacing="10%"
              transform="uppercase"
              weight="500"
            >
              {project?.subscriptionsConnection?.totalCount || 0}{' '}
              {t(
                project?.subscriptionsConnection?.totalCount > 1
                  ? 'permanent_project.text.subscribers'
                  : 'permanent_project.text.subscriber',
              )}
            </Text>
          </>
        )}
      </ProfileCard.Content>

      <ProfileCard.Action>
        {!userIsBacking && (
          <Button
            href={routeFor(PROJECT_ROUTE, {
              project: project.slug,
            })}
            tag="a"
            rounded
            modifier="helium"
            size="small"
            fit="content"
            className="k-u-align-center"
          >
            {t('permanent_project.discover_project')}
          </Button>
        )}
      </ProfileCard.Action>
    </StyledProfileCard>
  )
}
export default NewsProfile
