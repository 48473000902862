import React from 'react'

export const LiftOff = (props) => (
  <svg viewBox="0 0 800 800" {...props}>
    <defs>
      <circle id="a" cx="400" cy="400" r="400" />
    </defs>
    <clipPath id="liftOff--b">
      <circle cx="400" cy="400" r="400" />
    </clipPath>
    <circle
      clipPath="url(#liftOff--b)"
      cx="400"
      cy="400"
      fill="#caf4fe"
      r="400"
    />
    <g clipPath="url(#liftOff--b)">
      <path
        d="M436.5 548.9v116.2c30.3-35.3 96.3-16.2 88.4 44 30.6-31.7 126.8 0 93 89.8L417.7 800H155.1c0-67.6 52.8-89.8 89.8-80.3 21.5-45.3 81.9-61.6 116.9-24.3V548.9"
        fill="#e8f8ff"
      />
      <path
        d="M366.6 549h3.5v98.7h-3.5zM378.9 600h3.5v89h-3.5zM391.4 545.5h3.5v84h-3.5zM391.2 652h3.5v71.5h-3.5zM403.7 575.1h3.5v111.5h-3.5zM415.9 558.5h3.5V631h-3.5zM428.2 575.1h3.5V658h-3.5zM505.1 677.9c-5-8.1-12.9-13.8-22.1-16-9.3-2.2-18.8-.6-27 4.4l-2-3.3c18.6-11.5 43-5.7 54.4 12.9l-3.3 2zM353.2 708.2c-7.5-14.4-23.7-21.9-39.6-18.3l-.9-3.8c17.5-4 35.6 4.4 43.8 20.3l-3.3 1.8z"
        fill="#83dafc"
      />
      <path d="M362 211h74.7v337.9H362z" fill="#ffebe0" />
      <defs>
        <path id="c" d="M362 211h74.7v337.9H362z" />
      </defs>
      <clipPath id="d">
        <path d="M362 211h74.7v337.9H362z" />
      </clipPath>
      <path
        clipPath="url(#d)"
        d="M349.1 309.5c0-16.3 100.2-63.7 100.2-79.6v-35.4c0 15.9-100.2 61.9-100.2 79.6v35.4zM349.1 382.8c0-16.3 100.2-63.7 100.2-79.6v-35.4c0 15.9-100.2 61.9-100.2 79.6v35.4zM349.1 456.1c0-16.3 100.2-63.7 100.2-79.6v-35.4c0 15.9-100.2 61.9-100.2 79.6v35.4zM349.1 529.5c0-16.3 100.2-63.7 100.2-79.6v-35.4c0 15.9-100.2 61.9-100.2 79.6v35.4z"
        fill="red"
      />
      <path
        clipPath="url(#d)"
        d="M349.1 602.8c0-16.3 100.2-63.7 100.2-79.6v-35.4c0 15.9-100.2 61.9-100.2 79.6v35.4z"
        fill="#ff2600"
      />
      <g clipPath="url(#d)" opacity=".1">
        <circle cx="401.3" cy="302.3" r="18" />
      </g>
      <circle
        clipPath="url(#d)"
        cx="399.4"
        cy="298"
        fill="#101054"
        r="18"
        stroke="#f8e5dc"
        strokeWidth="3.301"
      />
      <g clipPath="url(#d)" opacity=".1">
        <circle cx="401.3" cy="412.1" r="18" />
      </g>
      <circle
        clipPath="url(#d)"
        cx="399.4"
        cy="407.9"
        fill="#101054"
        r="18"
        stroke="#f8e5dc"
        strokeWidth="3.301"
      />
      <path d="M399.4 69.5L362.2 211h74.3z" fill="red" />
      <path d="M399.4 69.5L423.9 211h12.6z" opacity=".1" />
      <g opacity=".1">
        <path d="M406.7 211h26v337.9h-26z" />
      </g>
      <path
        d="M554.4 384.7c-9.5 5.8-12.2 22.8-15.9 39.2-3.7 16.4-3.7 26.5-14.3 28.6-10.6 2.1-15.4 3.2-12.7 9 2.7 5.8 6.9 9.5 5.3 17.5-1.6 8-1.6 11.1 6.9 16.4 8.5 5.3 4.2 10.1-1.1 11.1-5.3 1.1-8.4 2.1-8.7 14.3-.3 12.2-6.2 19.6 16.1 29.2 22.3 9.5 50.9 21.2 40.3 56.2-10.6 35-22.8 49.8-22.8 49.8 25.5 12.7 73.7 29.2 144.8 5.8-11.1-15.4-31.8-38.2-27-93.9 4.8-55.7-14.8-101.3-14.8-101.3l-19.6-58.3-62.6-34.5-13.9 10.9z"
        fill="#724a44"
      />
      <path
        d="M692.7 662.3c-11.1-15.4-31.8-38.2-27-93.9.3-3.6.5-7.2.6-10.8l-6.5-7.2-28.6 7.8c3.6 9.7 4.2 23-7.5 49s-9 48.5 0 66.9c20 0 42.9-3.2 69-11.8zM548 425.5c4.2-13.8 6.9-25.5 16.5-38.8l-4.4-6.4-5.7 4.4c-9.5 5.8-12.2 22.8-15.9 39.2-3.7 16.4-3.7 26.5-14.3 28.6-10.6 2.1-15.4 3.2-12.7 9 .7 1.6 1.6 3.1 2.4 4.5-.2-8.7-2.4-4.5 14-8.8 16.4-4.1 15.9-17.9 20.1-31.7z"
        opacity=".1"
      />
      <path
        d="M758.5 706.6c-50.9-29.2-47.2-25.5-66.3-44.5-71.1 23.3-119.3 6.9-144.8-5.8-17.5 37.7-110.8 59.1-110.8 123.5v27.1h365.9c.1-.1 7-71.2-44-100.3z"
        fill="#83dafc"
      />
      <path
        d="M802.6 806.8s6.9-71.1-44-100.2c-50.9-29.2-47.2-25.5-66.3-44.5-23.5 7.7-44.6 11.1-63.1 11.7-9.4 14.9-18.2 28.3-41.4 37.3-23.2 9-37.1 21.3-33.9 61.1s-12.7 34.7-12.7 34.7h261.4z"
        opacity=".1"
      />
      <path
        d="M714 432.4c-19-18.6-19.7-36-25.4-51.2.2.6.4 1.1.6 1.7-.2-.6-.4-1.1-.6-1.7-.1-.2-.1-.3-.2-.5-.2-.4-.3-.9-.5-1.3-.1-.2-.1-.3-.2-.5 0-.1-.1-.2-.1-.3 0 .1.1.2.1.3l-1.5-3.3c.3.5.5 1.1.8 1.6-.3-.6-.5-1.1-.8-1.7-.1-.2-.2-.3-.2-.5-.2-.4-.4-.8-.7-1.2-.1-.2-.2-.3-.3-.5-.1-.1-.1-.2-.2-.3.1.1.1.2.2.3-.6-1.1-1.3-2.1-2-3.2.7 1 1.3 1.9 1.9 2.9-.6-1-1.2-2-1.9-2.9-.1-.2-.2-.3-.3-.5l-.9-1.2c-.1-.2-.2-.3-.4-.5-.4-.5-.8-1-1.3-1.5-.4-.4-.8-.9-1.1-1.3l-.4-.4c-.3-.3-.5-.6-.8-.8l-.2-.2.2.2c-.1-.1-.3-.2-.4-.4l.2.2-.2-.2c-.3-.3-.6-.5-.8-.8l-.3-.3c-.3-.3-.7-.6-1-.9l-.1-.1c-.8-.7-1.6-1.2-2.4-1.8h-.1c-.4-.2-.8-.5-1.1-.7 0 0-.1 0-.1-.1l-1.2-.6c-6.6-3.4-13.3-3.4-21.3-3.5-1.2 0-2.5 0-3.8-.1h-.4c-4.2-.1-8.8-.2-13.8-.6-15.4-1.4-34.2-6.9-53.6-30.7-26-31.8-51.6-12.2-57.3 0-5.7 12.2-20.2 50.4 34.5 61 21.2 4.2 29.2 4.8 29.2 18.6s-16.4 52 15.4 50.9c28.6-6.4 35.5 7.4 31.8 19.6-3.7 12.2-8 22.8-11.7 34.5-3.7 11.7-11.1 45.1 19.6 52.5 0 0 5.3 1.5 11.5 3.3h.2c.5.1 1 .3 1.5.4.2.1.4.1.5.1.6.2 1.1.3 1.7.5 4.2 1.2 8.3 2.3 11.1 3.1 8 2.1 18.6 0 18.6-19.6s3.2-43 22.3-52.5c18.8-9.2 33.2-38.4 7.7-63.3zm-54.9-52.6c.8 1.2 1.5 2.5 2.2 3.8-.7-1.3-1.4-2.6-2.2-3.8zm-14-24.8c1.3 0 2.6.1 3.8.1-1.3-.1-2.5-.1-3.8-.1zm19 146.9c-9.1 17.5-19 27.5-19.1 42.1 0 1.9.2 3.9.5 6-.4-2.1-.5-4.1-.5-6 .1-14.7 10-24.6 19.1-42.1 4.8-9.1 6-15.9 5.2-21.4.9 5.5-.4 12.2-5.2 21.4zm18.5-132.1l-.9-1.2.9 1.2zm-6.1-6.8l-.3-.3.3.3zm-1.4-1.2l-.1-.1.1.1zm-3.7-2.6s-.1 0 0 0c-.1 0 0 0 0 0zm7.1 5.8l.4.4c-.1-.2-.2-.3-.4-.4zm1.5 1.6s.1 0 0 0c.5.5.9 1 1.3 1.5-.4-.4-.8-1-1.3-1.5.1 0 0 0 0 0zm8.4 14.1c-.2-.4-.3-.8-.5-1.3.1.5.3.9.5 1.3zm-2.5-5.6c-.2-.4-.4-.8-.7-1.2.2.4.4.8.7 1.2z"
        fill="#002f7d"
      />
      <path
        d="M714 432.4c-25.5-24.9-18-47.7-33.9-65.8-11.1-12.7-21.2-11.3-35-11.7-4.3-.1-9.1-.1-14.3-.6 16.4 10.7 36.5 25 37.1 55.3 0 11.1-3.2 19.1-10.6 23.9-7.4 4.8-14.8 13.3-3.7 24.9s23.3 19.1 10.6 43.5c-12.7 24.4-26.9 34.1-14 62.1 5.3 1.5 11.3 3.2 15 4.2 8 2.1 18.6 0 18.6-19.6s3.2-43 22.3-52.5c19-9.6 33.4-38.8 7.9-63.7z"
        fill="#002057"
      />
      <path
        d="M212.6 583.7c14.6 35 78.8 59.6 96.3 67.4 17.5 7.8 41.7 21 43.5 76 1.8 54.9 1.8 72 1.8 72l-344 7.8c-5.1-31.2 49.2-121.5 49.2-121.5l153.2-101.7z"
        fill="#ffebe0"
      />
      <path
        d="M172.3 354l-86.2 95.1s-4.6 44.1-3.2 95.2c.7 24.9 3 45 4 60.6L99.6 685l16.8 81.1 91.9-28.9 9.5-144.2c-12-16.7-6.7-36.3 3.3-41.1 4.9-2.2 10-4 14.7-5.7 20.8-7.5 15.9-14.6 16.3-25.8.4-11.2-2.4-12.3-7.2-13.5-4.8-1.2-12.2-7.3-.4-10.2 8.9-2.2 8.2-7.3 7.2-14.6-1-7.3 3-10.5 5.8-15.7 2.7-5.2-1.6-6.4-11.1-8.9-9.6-2.5-9.1-11.7-11.6-26.9-1.9-11.9-.7-20.8-12.5-36.7s-34.5-35.1-50-39.9z"
        fill="#ffebe0"
      />
      <path
        d="M246.3 457.5c-9.6-2.5-9.1-11.7-11.6-26.9-1.9-11.9-.7-20.8-12.5-36.7-4.7-6.4-11.3-13.3-18.4-19.7l-2.7 11.9c16.6 16.5 23.9 28.4 27.6 51.1 3.7 22.7 13.3 28 28.7 29.2 2.8-5.2-1.5-6.4-11.1-8.9z"
        opacity=".1"
      />
      <path
        d="M243.8 439.2c-4.4-14.2-16.6-23-27.3-19.7-10.3 3.2-15.4 16.6-11.7 30.3l-65.4 14.9 1 4.6 65.9-15c2.2 5.4 5.7 10 9.9 13.1 3.7 2.8 7.7 4.2 11.6 4.2 1.6 0 3.1-.2 4.7-.7 10.6-3.3 15.7-17.5 11.3-31.7zm-12.6 27.5c-3.9 1.2-8.3.2-12.3-2.8-4.3-3.2-7.6-8.2-9.4-14-3.7-11.9.1-23.6 8.4-26.2 8.3-2.6 18.1 5 21.8 16.8 3.6 11.9-.2 23.6-8.5 26.2z"
        fill="#19b4fa"
      />
      <path
        d="M216.2 710.5s1.5 47.1 39.9 48.4c-21.8 3.8-53.1-.4-39.9-48.4zM171.6 740.4c26.4-20.4 22.1-39.9 18.3-58-3.8-18.1-2.4-36.1 9.5-52.3 18.1-34.2-1-55.6-20.5-83.6l-60-35.6c-15.3 61.9 18.6 99.9-13.8 119-31.9 18.8-46.2 31.9-70.6 53.1-38.2 30.9-29.9 100.4-24.3 124l78.9-1.8s56.1-44.3 82.5-64.8z"
        opacity=".1"
      />
      <path
        d="M222.7 348.2c5 27.8 14.2 37.1-43.3 90.8-15 20-34.2 48.3-12.8 86.2s45.6 67.9 4.3 106.4c-9.3 10.7-10.7 30.7-10.7 42.1s7.8 49.2-54.9 60.6c-30.7 5.7-60.6-12.8-57.8-77.7-.7-25.7-32.1-43.4-29.2-92.7 4.3-38.7 38.5-33.6 54.9-83.5s-16.3-100.2 69.9-124.2c39.2-8.5 73.2 6.9 79.6-8z"
        fill="#002f7d"
      />
      <path
        d="M71.5 806.2v-60c68.2 16.5 213.5 0 213.5 0v54.5l-213.5 5.5z"
        fill="red"
      />
      <path
        d="M71.5 746.1v51.6c65.3 15.8 86.1-21.9 100.1-44.4-36.3.6-73.9-.8-100.1-7.2z"
        opacity=".1"
      />
      <path
        d="M160.2 673.6c0-8.7.9-22.5 5.4-33.3 5.4-33.3 15.3-62.2-14.7-71.5s-31.4-27.1-26.7-47.1c4.7-20 6-30-17.5-72.8s-.4-76.9 17.5-86.4c-62.2 25.8-35.9 71.9-51 117.8-16.4 49.9-50.6 44.8-54.9 83.5-2.9 49.3 28.5 67 29.2 92.7-17.7 89.6 27.8 113.9 58.5 108.2 62.8-11.4 54.2-79.7 54.2-91.1z"
        fill="#002057"
      />
      <circle cx="465.5" cy="439.8" fill="red" r="14.1" />
      <circle cx="464.6" cy="582.7" fill="#83dafc" r="10.7" />
      <circle cx="527.9" cy="268.1" fill="#fff" r="10.7" />
      <circle cx="571.3" cy="708.5" fill="#19b4fa" r="10.7" />
      <circle cx="464.2" cy="48.4" fill="red" r="10.7" />
      <circle cx="264.1" cy="181" fill="#fff" r="10.7" />
      <ellipse
        cx="126.5"
        cy="495.6"
        fill="red"
        rx="11.5"
        ry="5.7"
        transform="rotate(-37.908 126.46 495.593)"
      />
      <ellipse
        cx="605.9"
        cy="195.5"
        fill="red"
        rx="11.5"
        ry="5.7"
        transform="rotate(-2.458 605.89 195.524) scale(1.00002)"
      />
      <ellipse
        cx="622.6"
        cy="323.8"
        fill="#19b4fa"
        rx="5.7"
        ry="12.3"
        transform="rotate(-57.9 622.622 323.774)"
      />
      <ellipse
        cx="552.8"
        cy="622.6"
        fill="#19b4fa"
        rx="12.3"
        ry="5.7"
        transform="rotate(-37.908 552.794 622.588)"
      />
      <circle cx="106.4" cy="331.7" fill="red" r="10.7" />
      <circle cx="475.3" cy="713.7" fill="#fff" r="10.7" />
      <ellipse
        cx="308.3"
        cy="96.4"
        fill="#19b4fa"
        rx="7.7"
        ry="10.2"
        transform="rotate(-44.49 308.246 96.374)"
      />
      <circle cx="572.8" cy="522.2" fill="#83dafc" r="10.7" />
      <circle cx="683.6" cy="274.8" fill="#19b4fa" r="10.7" />
      <ellipse
        cx="206.5"
        cy="268"
        fill="red"
        rx="5.7"
        ry="11.5"
        transform="rotate(-12.022 206.565 268.082)"
      />
      <ellipse
        cx="646.9"
        cy="653.2"
        fill="red"
        rx="11.5"
        ry="5.7"
        transform="rotate(-66.573 646.859 653.145)"
      />
      <ellipse
        cx="54.1"
        cy="402"
        fill="#19b4fa"
        rx="12.3"
        ry="5.7"
        transform="rotate(-32.016 54.116 402.003)"
      />
      <ellipse
        cx="295.2"
        cy="425.1"
        fill="#83dafc"
        rx="5.7"
        ry="12.3"
        transform="rotate(-12.022 295.28 425.11)"
      />
      <circle cx="315.5" cy="322.3" fill="#19b4fa" r="10.7" />
      <circle cx="331.8" cy="484.9" fill="red" r="10.7" />
      <circle cx="646.9" cy="439.8" fill="#fff" r="10.7" />
      <circle cx="293" cy="278.8" fill="#83dafc" r="10.7" />
      <ellipse
        cx="515.9"
        cy="174.5"
        fill="#fff"
        rx="9.4"
        ry="10.7"
        transform="rotate(-13.975 515.767 174.532)"
      />
      <ellipse
        cx="729.5"
        cy="527.3"
        fill="red"
        rx="5.7"
        ry="11.5"
        transform="rotate(-24.861 729.442 527.341)"
      />
      <ellipse
        cx="326.3"
        cy="629.1"
        fill="#19b4fa"
        rx="12.3"
        ry="5.7"
        transform="rotate(-80.305 326.289 629.055)"
      />
      <ellipse
        cx="139.5"
        cy="637.8"
        fill="#83dafc"
        rx="5.7"
        ry="12.3"
        transform="rotate(-60.31 139.55 637.818)"
      />
      <ellipse
        cx="484.2"
        cy="233.4"
        fill="#19b4fa"
        rx="10.1"
        ry="13.4"
        transform="rotate(-31.273 484.174 233.437)"
      />
    </g>
  </svg>
)
