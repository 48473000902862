import { useSelector } from 'react-redux'
import { useRouteMatch, useLocation } from 'react-router'

import { useTranslation } from 'kiss/hooks/use-translation'
import * as qs from 'neoqs'

import {
  CARD_PAYMENT_FAILURE,
  CART,
  CONTRIBUTE_LOGIN,
  CONTRIBUTE_SIGN_IN as SIGN_IN,
  CONTRIBUTE_SIGN_UP as SIGN_UP,
  IDENTITY_CHECK,
  PAYMENT_CHOICES,
  PAYMENT_CHOICES_RETURN,
  PROJECT_ROUTE,
  SHIPPING_ADDRESS,
  THANK_YOU,
  BILLING_ADDRESS,
} from 'kiss/routes/redux'
import { isLogged as isLoggedSelector } from 'kiss/session/redux'
import {
  isFiscalReceiptsEnabled,
  isIdentityCheckRequired,
  isPostalDeliveryRequired,
  isSpecificShippingForDonationReceipts,
  isBillingAddressRequired,
} from 'kiss/modules/contribute/cart/redux'
import { LONG_PROCESSING } from 'kiss/modules/contribute/polling'

const isExact = (obj) => obj?.isExact ?? false

const CONFIRMATION = 'CONFIRMATION'

const useContributeStepper = () => {
  // Selectors.
  const t = useTranslation()

  const isLogged = useSelector(isLoggedSelector)
  const postalDeliveryRequired = useSelector(isPostalDeliveryRequired)
  const identityCheckRequired = useSelector(isIdentityCheckRequired)
  const hasDonationReceipts = useSelector(isFiscalReceiptsEnabled)
  const needsBillinAddress = useSelector(isBillingAddressRequired)
  const specificShippingForDonationReceipts = useSelector(
    isSpecificShippingForDonationReceipts,
  )

  const needShippingAdressStep = postalDeliveryRequired || hasDonationReceipts

  // Routing.
  const isMatchCart = isExact(useRouteMatch(CART))
  const isMatchLogin = isExact(useRouteMatch(CONTRIBUTE_LOGIN))
  const isMatchSignIn = isExact(useRouteMatch(SIGN_IN))
  const isMatchSignUp = isExact(useRouteMatch(SIGN_UP))
  const isMatchShipping = isExact(useRouteMatch(SHIPPING_ADDRESS))
  const isMatchIdentityCheck = isExact(useRouteMatch(IDENTITY_CHECK))
  const isMatchPayment = isExact(useRouteMatch(PAYMENT_CHOICES))
  const isMatchPaymentReturn = isExact(useRouteMatch(PAYMENT_CHOICES_RETURN))
  const isMatchThankYou = isExact(useRouteMatch(THANK_YOU))
  const isMatchFailure = isExact(useRouteMatch(CARD_PAYMENT_FAILURE))
  const isMatchBillingAddress = isExact(useRouteMatch(BILLING_ADDRESS))

  // Generate breadcrumb.
  const stepsRoutes = [PROJECT_ROUTE, CART]
  if (!isLogged) stepsRoutes.push(CONTRIBUTE_LOGIN)
  if (needShippingAdressStep) stepsRoutes.push(SHIPPING_ADDRESS)
  if (needsBillinAddress && !needShippingAdressStep)
    stepsRoutes.push(BILLING_ADDRESS)
  if (identityCheckRequired) stepsRoutes.push(IDENTITY_CHECK)
  stepsRoutes.push(PAYMENT_CHOICES)

  if (isMatchFailure) {
    stepsRoutes.push(CARD_PAYMENT_FAILURE)
  } else {
    stepsRoutes.push(CONFIRMATION)
  }

  // Stepper ignores ProjectPage and Cart routes

  const difference = (arr1, arr2) => arr1.filter((x) => !arr2.includes(x))
  const stepperRoutes = difference(stepsRoutes, [PROJECT_ROUTE, CART])

  const currentRoute = (() => {
    switch (true) {
      case isMatchCart:
        return CART
      case isMatchSignIn:
      case isMatchSignUp:
      case isMatchLogin:
        return CONTRIBUTE_LOGIN
      case isMatchShipping:
        return SHIPPING_ADDRESS
      case isMatchBillingAddress:
        return BILLING_ADDRESS
      case isMatchIdentityCheck:
        return IDENTITY_CHECK
      case isMatchPayment:
      case isMatchPaymentReturn:
        return PAYMENT_CHOICES
      case isMatchThankYou:
        return CONFIRMATION
      case isMatchFailure:
        return CARD_PAYMENT_FAILURE
      default:
        return null
    }
  })()

  const currentStepIndex = stepsRoutes.findIndex(
    (stepRoute) => stepRoute === currentRoute,
  )

  const currentStepperIndex = stepperRoutes.findIndex(
    (stepRoute) => stepRoute === currentRoute,
  )

  const prevRoute = stepsRoutes[currentStepIndex > 0 ? currentStepIndex - 1 : 0]
  const nextRoute =
    stepsRoutes[
      currentStepIndex < stepsRoutes.length - 1
        ? currentStepIndex + 1
        : stepsRoutes.length - 1
    ]

  const stepperCurrentStepNumber = currentStepperIndex + 1
  const stepperTotalSteps = stepperRoutes.length

  const step = `${stepperCurrentStepNumber}/${stepperTotalSteps}`

  const { search } = useLocation()

  const { errorCode } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })

  const paymentFailureStepLabels = {
    processing_provider: t('cart.contribute.steps.cancelled_payment'),
    [LONG_PROCESSING]: t('cart.contribute.steps.payment_in_process'),
  }

  const wording = (() => {
    switch (currentRoute) {
      case CART:
        return {
          message: t('cart.contribute.steps.cart'),
          previous: '',
          next: '',
          step: '',
        }
      case CONTRIBUTE_LOGIN:
        return {
          message: t('cart.contribute.steps.log'),
          previous: t('cart.contribute.previous.cart'),
          next: t(
            needShippingAdressStep
              ? 'cart.contribute.next.shipping'
              : 'cart.contribute.next.payment',
          ),
          step,
        }
      case SHIPPING_ADDRESS:
        return {
          message: specificShippingForDonationReceipts
            ? t('contribute.shipping_address.donation_receipts.title')
            : t('cart.contribute.steps.shipping'),
          previous: t('cart.contribute.previous.cart'),
          next: t('cart.contribute.next.payment'),
          step,
        }
      case BILLING_ADDRESS:
        return {
          message: t('cart.contribute.steps.billing'),
          previous: t('cart.contribute.previous.cart'),
          next: t('cart.contribute.next.payment'),
          step,
        }
      case IDENTITY_CHECK:
        return {
          message: t('cart.contribute.steps.identity_check'),
          previous: t('cart.contribute.previous.cart'),
          next: t(
            needShippingAdressStep
              ? specificShippingForDonationReceipts
                ? 'contribute.shipping_address.donation_receipts.title'
                : 'cart.contribute.next.shipping'
              : 'cart.contribute.next.payment',
          ),
          step,
        }
      case PAYMENT_CHOICES:
        return {
          message: t('cart.contribute.steps.payment'),
          previous: t(
            needShippingAdressStep
              ? 'cart.contribute.previous.shipping'
              : 'cart.contribute.previous.cart',
          ),
          next: t('cart.contribute.next.confirmation'),
          step,
        }
      case CONFIRMATION:
        return {
          message: t('cart.contribute.steps.confirmation'),
          previous: '',
          next: '',
          step,
        }
      case CARD_PAYMENT_FAILURE:
        return {
          message:
            paymentFailureStepLabels[errorCode] ??
            t('cart.contribute.steps.failed_payment'),
          previous: '',
          next: '',
          step,
        }
      default: {
        return {
          message: '',
          previous: '',
          next: '',
          step: '',
        }
      }
    }
  })()
  return {
    currentRoute,
    prevRoute,
    nextRoute,
    stepperCurrentStepNumber,
    stepperTotalSteps,
    wording,
  }
}

export default useContributeStepper
