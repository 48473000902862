import { Button } from '@kisskissbankbank/kitten'
import { format, formatISO, parse } from 'date-fns'
import { Form, Formik } from 'formik'
import { useMutation } from 'kiss/api/graphql-query'
import {
  addErrorAlert,
  addSuccessAlert,
  removeAllAlerts,
} from 'kiss/app/alerts/redux'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import DateInput from 'kiss/components/formik/date'
import Label from 'kiss/components/formik/label'
import Location from '../browsing/filters/location'
import SelectCountries from 'kiss/components/formik/select-countries'
import SimpleText from 'kiss/components/formik/simple-text'
import UpdateContributorIdentity from 'kiss/graphql/mutations/sporran/update_contributor_identity.graphql'
import { useTranslation } from 'kiss/hooks/use-translation'
import { GooglePlaceHelper } from 'kiss/utils/google-place-helper'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import Prompt from './components/prompt'
import IdentityCard from './identity-card'

const NaturalForm = ({ identity, refetchData }) => {
  const t = useTranslation()
  const stackState = getOr([])('naturalStack.state')(identity)
  const isAccepted = stackState === 'accepted'
  const dispatch = useDispatch()
  const [sendUserData] = useMutation(UpdateContributorIdentity)

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: identity.firstName,
        lastName: identity.lastName,
        birthplaceCountry: identity.birthplaceCountry || '',
        birthday: identity.birthday
          ? format(
              parse(identity.birthday, 'yyyy-MM-dd', new Date()),
              'dd/MM/yyyy',
            )
          : '',
        birthplaceCitySearch: identity.birthplaceCity || '',
        documentState: flow(
          getOr([])('naturalStack.documents'),
          first,
          getOr('')('state'),
        )(identity),
      }}
      validate={({ documentState }) => {
        if (documentState === 'empty') {
          return { documentState: t('users.kyc.message.document.required') }
        }
        return {}
      }}
      validationSchema={Yup.object().shape({
        birthplaceCountry: Yup.string().required(t('app.forms.error.required')),
        birthday: Yup.string().required(t('app.forms.error.required')),
        birthplaceCitySearch: Yup.string().required(
          t('app.forms.error.required'),
        ),
      })}
      onSubmit={async (values) => {
        dispatch(removeAllAlerts())
        try {
          await sendUserData({
            variables: {
              id: identity.id,
              identity: {
                legalType: 'natural',
                birthplaceCountry: values.birthplaceCountry,
                birthday: formatISO(
                  parse(values.birthday, 'dd/MM/yyyy', new Date()),
                ),
                birthplaceCity: values.birthplaceCitySearch,
              },
            },
          })
          dispatch(
            addSuccessAlert(t('users.kyc.documents.send.natural.success'), {
              scroll: true,
            }),
          )
          refetchData()
        } catch (e) {
          console.error(e)
          dispatch(addErrorAlert(t('app.server.error'), { scroll: true }))
        }
      }}
    >
      {({ dirty, handleBlur, isSubmitting, setFieldValue, values }) => {
        return (
          <Form>
            <Prompt isVisible={dirty} />
            <>
              <div className="k-u-margin-bottom-triple">
                <Label htmlFor="firstName">
                  {t('users.kyc.form.type.firstname.label')}
                </Label>
                <SimpleText name="firstName" disabled />
              </div>
              <div className="k-u-margin-bottom-triple">
                <Label htmlFor="lastName">
                  {t('users.kyc.form.type.lastname.label')}
                </Label>
                <SimpleText name="lastName" disabled />
              </div>
              <div className="k-u-margin-bottom-triple">
                <Label htmlFor="birthday">
                  {t('users.kyc.form.birthday.label')}
                </Label>
                <DateInput
                  name="birthday"
                  disabled={isAccepted}
                  size="medium"
                  placeholder={t('app.forms.date_format')}
                />
              </div>
              <div className="k-u-margin-bottom-triple">
                <Label htmlFor="birthplaceCitySearch">
                  {t('users.kyc.form.birthcity.label')}
                </Label>
                <Location
                  name="birthplaceCitySearch"
                  disabled={isAccepted}
                  placeholder={t('users.kyc.form.birthcity.placeholder')}
                  onSelect={({ place }) => {
                    const { city, countryCode } =
                      GooglePlaceHelper.getAddressFields(place)
                    setFieldValue('birthplaceCitySearch', city)
                    setFieldValue('birthplaceCountry', countryCode)
                  }}
                  onChange={({ value }) => {
                    if (value === '' && values.birthplaceCitySearch !== '') {
                      setFieldValue('birthplaceCitySearch', '')
                      setFieldValue('birthplaceCountry', '')
                    }
                  }}
                  onBlur={handleBlur}
                  searchOptions={{
                    types: ['(regions)'],
                  }}
                />
              </div>
              <div className="k-u-margin-bottom-triple">
                <Label htmlFor="birthplaceCountry">
                  {t('users.kyc.form.birthcountry.label')}
                </Label>
                <SelectCountries name="birthplaceCountry" disabled />
              </div>
              <IdentityCard identity={identity} />
              {!isAccepted && (
                <>
                  {isSubmitting ? (
                    <SubmitLoader fit="fluid" />
                  ) : (
                    <Button
                      type="submit"
                      size="large"
                      fit="fluid"
                      modifier="helium"
                    >
                      {t('app.submit.validate')}
                    </Button>
                  )}
                </>
              )}
            </>
          </Form>
        )
      }}
    </Formik>
  )
}

export default NaturalForm
