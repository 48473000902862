import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { isVisible } from 'kiss/utils/project/state'
import { useTranslation } from 'kiss/hooks/use-translation'

import {
  Button,
  FlexWrapper,
  Grid,
  GridCol,
  KissKissLoadingAnimation,
  Paragraph,
  Title,
} from '@kisskissbankbank/kitten'

import {
  getManagerNewsCreateRoute,
  getManagerNewsRoute,
  getNews,
  getNewsCount,
  isCurrentUserOwner,
  getProjectState,
} from 'kiss/modules/project-page/page-state/selectors'

import { ContributeButton } from 'kiss/modules/project-page/permanent/components/contribute-button'
import FiltersNav from 'kiss/modules/project-page/permanent/components/filter/components/filter-nav'
import Dates from 'kiss/modules/project-page/permanent/components/filter/dates'
import Tags from 'kiss/modules/project-page/permanent/components/filter/tags'
import TagsList from 'kiss/modules/project-page/permanent/components/filter/tags/list'
import { CloseReplyFormsProvider } from 'kiss/modules/project-page/common/contexts/CloseReplyForms'
import NewsCard from 'kiss/modules/project-page/permanent/common-pages/news/components/news-card'

const NEWS_OFFSET = 10

const News = ({
  withContributeButton = true,
  withFilter = false,
  loading = false,
  noSearchResult = false,
}) => {
  const { locale } = useParams()

  const t = useTranslation()

  const news = useSelector(getNews)
  const newsCount = useSelector(getNewsCount)
  const currentUserIsOwner = useSelector(isCurrentUserOwner)
  const managerNewsRoute = useSelector(getManagerNewsRoute)
  const managerNewsCreateRoute = useSelector(getManagerNewsCreateRoute)
  const projectState = useSelector(getProjectState)

  const [numberOfNews, setNumberOfNews] = useState(NEWS_OFFSET)

  const newsToDisplay = news.slice(0, numberOfNews)

  return (
    <CloseReplyFormsProvider>
      <div>
        <Title
          tag="h2"
          modifier="quaternary"
          className="k-u-margin-bottom-double@m-down k-u-margin-bottom-triple@l-up"
        >
          {t('project.news.news_title')}
        </Title>
        {withFilter && (
          <>
            <FiltersNav>
              <Dates />
              <div className="k-u-hidden@l-up">
                <Tags />
              </div>
            </FiltersNav>

            <div className="k-u-hidden@m-down">
              <TagsList />
            </div>
          </>
        )}
        {loading ? (
          <div className="kiss-PermanentProject__LoadableSection__loader k-u-margin-top-double">
            <KissKissLoadingAnimation />
          </div>
        ) : (
          <>
            {currentUserIsOwner && (
              <Grid>
                <GridCol col-l="12">
                  <div className="k-u-align-center k-u-margin-top-quintuple@s-up k-u-margin-top-triple@s-down k-u-margin-bottom-triple@s-up k-u-margin-bottom-single@s-down">
                    {newsCount !== 0 && (
                      <Button fit="content" as="a" href={managerNewsRoute}>
                        {t('project.news.list_news')}
                      </Button>
                    )}
                    {isVisible(projectState) && (
                      <Button
                        fit="content"
                        as="a"
                        modifier="helium"
                        href={managerNewsCreateRoute}
                        className="k-u-margin-left-single@s-up k-u-margin-top-single@s-down"
                      >
                        {t('project.news.add_news', { parseHtml: true })}
                      </Button>
                    )}
                  </div>
                </GridCol>
              </Grid>
            )}
            {newsCount > 0 && (
              <div className="k-u-margin-top-double@m-down k-u-margin-top-triple">
                <FlexWrapper gap={30}>
                  {newsToDisplay.map((newsItem) => (
                    <NewsCard
                      newsItem={newsItem}
                      locale={locale}
                      key={newsItem.id}
                      titleLevel="h2"
                    />
                  ))}
                </FlexWrapper>

                {newsCount > numberOfNews && (
                  <Button
                    className="k-u-align-center k-u-margin-top-double"
                    onClick={() => setNumberOfNews(numberOfNews + NEWS_OFFSET)}
                  >
                    {t('project.news.load_more')}
                  </Button>
                )}
              </div>
            )}
            {newsCount === 0 && (
              <Paragraph className="k-u-margin-top-double">
                {noSearchResult
                  ? t('project.news.no_search_result')
                  : t('project.news.no_result')}
              </Paragraph>
            )}
            {withContributeButton && (
              <ContributeButton className="k-u-hidden@l-up" />
            )}
          </>
        )}
      </div>
    </CloseReplyFormsProvider>
  )
}

export default News
