import React from 'react'
import { ContributionCard } from '@kisskissbankbank/kitten'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  removeDonation,
  hasDonation as hasDonationSelector,
  getDonationAmount,
  getDonationCurrency,
} from '../../../cart/redux'

const Donation = ({ className }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const hasDonation = useSelector(hasDonationSelector)
  const donationAmount = useSelector(getDonationAmount)
  const donationCurrency = useSelector(getDonationCurrency)

  if (!hasDonation) return null

  const formattedAmount = t('project_info.amount_with_currency', {
    amount: t(donationAmount, { formatNumber: { decimal: 2 } }),
    currency: t(`currency_symbol.${donationCurrency}`),
    parseHtml: true,
  })
  const title = t('cart.contribute.summary.donation')

  return (
    <div className={className} data-test-id="donation-card">
      <ContributionCard onClose={() => dispatch(removeDonation())}>
        <ContributionCard.Title largeTitle>{title}</ContributionCard.Title>
        <ContributionCard.Amount>{formattedAmount}</ContributionCard.Amount>
      </ContributionCard>
    </div>
  )
}

export default Donation
