import React from 'react'

import styled from 'styled-components'

import { useTranslation } from 'kiss/hooks/use-translation'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { mq, Title } from '@kisskissbankbank/kitten'

import Seo from 'kiss/modules/home/seo'
import TeamSelection from 'kiss/modules/home/components/team-selection'
import Challenges from 'kiss/modules/home/components/challenges'
import Engagements from 'kiss/modules/home/components/engagements'
import Hero from 'kiss/modules/home/components/hero'
import Offers from 'kiss/modules/home/components/offers'
import PartnerCarousel from 'kiss/modules/home/components/partner-carousel'
import PopularProjects from 'kiss/modules/home/components/popular-projects'
import UnderHero from 'kiss/modules/home/components/under-hero'
import Why from 'kiss/modules/home/components/why'

export const StyledTitle = styled(Title)`
  @media ${mq.tabletAndDesktop} {
    max-width: 50vw;
    margin: auto;
  }
`

const HomePage = () => {
  const t = useTranslation()

  return (
    <DefaultLayout>
      <Seo />
      <Hero />
      <UnderHero />
      <Offers />
      <>
        <StyledTitle
          tag="h2"
          noMargin
          family="antiqueolive"
          modifier="tertiary"
          className="k-u-align-center"
        >
          {t('new_home.projects.title')}
        </StyledTitle>
        <TeamSelection />
        <PopularProjects />
      </>

      <Why />
      <Engagements />
      <PartnerCarousel />
      <Challenges />
    </DefaultLayout>
  )
}

export default HomePage
