import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Title, pxToRem, mq, Button } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  ENGAGEMENT_BROWSING,
  CRITERE_SELECTIONS,
  MANIFESTO,
  getRouteFor,
} from 'kiss/routes/redux'

import Manifesto from './manifesto'
import {
  getCultureEngagementProjects,
  getAgainstDiscriminationsEngagementProjects,
  getEnvironmentEngagementProjects,
} from '../../redux'
import Engagement from './engagement'
import { StyledTitle } from '../../index'

const EngagementsWrapper = styled.section`
  color: var(--color-grey-000);
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(20)};
  padding: 0;
  overflow: hidden;
  max-width: ${pxToRem(1440)};
  margin-left: auto;
  margin-right: auto;

  @media ${mq.desktop} {
    padding: 0 ${pxToRem(120)};
    gap: ${pxToRem(100)};
  }

  .kiss-Homepage__engagements__blockWrapper {
    position: relative;
    max-width: ${pxToRem(1440)};
    box-sizing: border-box;

    .kiss-Homepage__engagements__graphic {
      position: absolute;
      z-index: 1;
      @media ${mq.mobile} {
        display: none;
      }
    }

    .kiss-Homepage__engagements__heart {
      width: ${pxToRem(450)};
      transform: rotate(-30deg);
      top: 55%;
      left: -12%;
    }
    .kiss-Homepage__engagements__bolt {
      height: ${pxToRem(580)};
      transform: rotate(-15deg);
      top: 5%;
      right: -20%;
    }
    .kiss-Homepage__engagements__invader {
      width: ${pxToRem(410)};
      transform: rotate(-15deg);
      top: -7%;
      left: -15%;
    }
  }

  .kiss-Homepage__engagements__block {
    position: relative;
    z-index: 2;

    & > * {
      @media ${mq.desktop} {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  .kiss-Homepage__engagements__block__link {
    display: inline-flex;
    align-items: center;

    @media ${mq.tabletAndMobile} {
      display: flex;
      margin-top: ${pxToRem(10)};
    }
  }

  .kiss-Homepage__engagements__carousel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${pxToRem(30)};
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
  }

  .k-CarouselNext--showOtherPages .k-CarouselNext__inner {
    --container-padding: ${pxToRem(20)};

    @media ${mq.desktop} {
      --container-padding: ${pxToRem(150)};
    }
  }

  .k-CarouselNext__page {
    height: 100% !important;
  }
`

const Engagements = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const cultureProjects = useSelector(getCultureEngagementProjects)
  const againstDiscriminationsProjects = useSelector(
    getAgainstDiscriminationsEngagementProjects,
  )
  const environmentProjects = useSelector(getEnvironmentEngagementProjects)
  return (
    <div
      style={{ marginTop: '-90px', backgroundColor: 'var(--color-grey-900)' }}
    >
      <Manifesto
        top={[100, 160]}
        bottom={[50, 50]}
        button={
          <Button modifier="hydrogen" tag="a" href={routeFor(MANIFESTO)}>
            {t('new_home.manifesto.read_our_manifest')}
          </Button>
        }
        title={
          <Title
            tag="h2"
            cssColor="inherit"
            modifier="tertiary"
            family="antiqueolive"
          >
            {t('new_home.manifesto.title', { parseHtml: true })}
          </Title>
        }
        illustration
      />

      <EngagementsWrapper>
        <div className="kiss-Homepage__engagements__blockWrapper">
          <svg
            className="kiss-Homepage__engagements__graphic kiss-Homepage__engagements__heart"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 148 127"
          >
            <path
              fill="#2d2d2d"
              d="M37.07.1A37.12 37.12 0 0 0 10.2 62.8l63.58 63.41 61.65-61.84a37 37 0 0 0 11.92-27.31 37.13 37.13 0 0 0-73.7-6.28A37.09 37.09 0 0 0 37.07.1Z"
            />
          </svg>
          <Engagement
            title={t('new_home.manifesto.culture_title')}
            description={t('new_home.manifesto.culture_description')}
            projects={cultureProjects}
            link={routeFor(ENGAGEMENT_BROWSING, {
              engagement: 'independent-culture',
            })}
          />
        </div>
        <div className="kiss-Homepage__engagements__blockWrapper">
          <svg
            className="kiss-Homepage__engagements__graphic kiss-Homepage__engagements__bolt"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 462 587"
          >
            <path
              fill="#2d2d2d"
              d="M461.97 29.06 244.17 0 0 365.06h141.38L82.52 586.13l322.5-322.06H251.58l210.4-235.01Z"
            />
          </svg>
          <Engagement
            title={t('new_home.manifesto.discriminations_title')}
            description={t('new_home.manifesto.discriminations_description')}
            projects={againstDiscriminationsProjects}
            link={routeFor(ENGAGEMENT_BROWSING, {
              engagement: 'fight-against-discrimination',
            })}
          />
        </div>
        <div className="kiss-Homepage__engagements__blockWrapper">
          <svg
            className="kiss-Homepage__engagements__graphic kiss-Homepage__engagements__invader"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 107 125"
          >
            <path
              fill="#2d2d2d"
              d="M106.17 18.72a61.83 61.83 0 1 0 0 87.45L62.44 62.45l43.73-43.73Z"
            />
          </svg>
          <Engagement
            title={t('new_home.manifesto.environment_title')}
            description={t('new_home.manifesto.environment_description')}
            projects={environmentProjects}
            link={routeFor(ENGAGEMENT_BROWSING, {
              engagement: 'environment-and-biodiversity',
            })}
          />
        </div>
      </EngagementsWrapper>
      <Manifesto
        top={[50, 70]}
        bottom={[70, 120]}
        button={
          <Button
            modifier="hydrogen"
            tag="a"
            href={routeFor(CRITERE_SELECTIONS)}
          >
            {t('new_home.manifesto.see_our_selection_criteria')}
          </Button>
        }
        title={
          <StyledTitle
            cssColor="inherit"
            modifier="senary"
            family="antiqueolive"
          >
            {t('new_home.manifesto.ethical_charter', { parseHtml: true })}
          </StyledTitle>
        }
      />
    </div>
  )
}

export default Engagements
