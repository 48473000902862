import styled from 'styled-components'
import { pxToRem, mq } from '@kisskissbankbank/kitten'

export const StyledFooter = styled.footer`
  padding: ${pxToRem(40)} ${pxToRem(20)} ${pxToRem(40)} ${pxToRem(20)};
  background-color: var(--color-grey-900);
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;

  @media ${mq.mobileAndTablet} {
    padding: ${pxToRem(20)};
  }

  .k-Footer__gridWrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;

    @media ${mq.mobileAndTablet} {
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }

  .k-Footer__stroke {
    margin: ${pxToRem(10)} 0;

    @media ${mq.mobile} {
      margin: ${pxToRem(10)} auto;
    }
  }

  /* BASELINE */

  .k-Footer__baseline {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${mq.tablet} {
      flex-direction: row;
      justify-content: space-around;
      align-items: start;
    }
  }

  .k-Footer__baselineSocial {
    width: 70%;
    text-align: left;
    margin-top: ${pxToRem(30)};
    align-self: baseline;

    @media ${mq.tablet} {
      width: 50%;
      margin-top: 0;
    }

    @media ${mq.mobile} {
      width: 100%;
      margin-top: ${pxToRem(30)};
      text-align: center;
    }
  }

  .k-Footer__baselineText {
    margin: 0 ${pxToRem(40)};
    margin-bottom: ${pxToRem(40)};

    @media ${mq.tablet} {
      margin: 0;
    }

    @media ${mq.desktop} {
      margin: 0;
    }
  }

  /* SOCIALBUTTON */

  .k-Footer__socialButton {
    display: flex;
    gap: ${pxToRem(12)};

    @media ${mq.mobile} {
      gap: ${pxToRem(15)};
      flex-wrap: wrap;
    }
    @media ${mq.desktop} {
      margin-left: auto;
    }
  }

  /* LISTITEMS */

  .k-Footer__listWrapper {
    display: grid;
    grid-template-columns: 1fr;
    text-align: left;
    gap: ${pxToRem(20)};

    @media ${mq.tablet} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @media ${mq.desktop} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @media (min-width: 1440px) {
      margin: auto;
    }
  }

  .k-Footer__linkList {
    list-style-type: none;
    margin-top: ${pxToRem(20)};
  }

  .k-Footer__linkList--item {
    display: inline-block;
    cursor: pointer;
    margin-bottom: ${pxToRem(5)};

    color: var(--color-grey-000);
    font-size: ${pxToRem(12)};
    height: 44px;
    align-content: center;

    line-height: normal;
    text-decoration: none;
    font-family: 'GeneralSans';
    font-weight: 400;
    font-style: normal;

    @media ${mq.tablet} {
      height: 44px;
    }
    @media ${mq.desktop} {
      height: auto;
    }

    &:active {
      color: var(--color-primary-900);
    }
    &:hover,
    &:focus {
      color: var(--color-primary-500);
    }
    transition: color 0.2s;
  }

  /* ECOSYSTEMS */

  .k-Footer__ecosystem--title {
    font-size: ${pxToRem(18)};
    text-align: center;
    margin-bottom: ${pxToRem(30)};
    font-weight: 600;

    @media (min-width: 1080px) {
      font-size: ${pxToRem(20)};
      font-weight: 700;
    }
  }

  .k-Footer__ecosystem--logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${pxToRem(30)};
    margin: 0 ${pxToRem(20)};
    flex-direction: column;

    @media ${mq.tablet} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media ${mq.desktop} {
      flex-direction: row;
      justify-content: flex-start;
      margin: 0;
    }

    a {
      width: 200px;
    }
  }

  /* CREATED WITH */

  .k-Footer__createdWith {
    background-color: var(--color-grey-200);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${pxToRem(20)};
    margin: 0 ${pxToRem(-20)};
    flex-direction: column;

    @media ${mq.desktop} {
      flex-direction: row;
    }
  }

  .k-Footer__createdWith--logo {
    display: flex;
    align-items: center;
    gap: ${pxToRem(20)};
    text-align: left;
    flex-direction: column;

    @media ${mq.desktop} {
      padding-top: ${pxToRem(20)};
      padding-bottom: ${pxToRem(20)};
      align-self: center;
    }
  }

  .k-Footer__createdWith--paragraph {
    line-height: normal !important;
    max-width: ${pxToRem(350)};

    @media ${mq.mobile} {
      text-align: center;
    }
  }

  /* LEGAL */

  .k-Footer__legal {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${pxToRem(20)};

    @media ${mq.mobileAndTablet} {
      flex-direction: column;
      gap: ${pxToRem(20)};
    }
  }

  .k-Footer__legal--list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
    margin-block: 0px;
    padding-inline-start: 0px;
    -webkit-box-pack: center;
    gap: 20px;
  }

  .k-Footer__legal--select {
    width: 100%;

    @media ${mq.desktop} {
      max-width: 25%;
    }
  }
`
