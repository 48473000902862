import React from 'react'

import { getAssetFor } from 'kiss/assets/redux'
import { useSelector } from 'react-redux'

import DefaultLayout from 'kiss/app/layouts/default-layout'

import styled from 'styled-components'

const StyledAuthenticateContainer = styled.div`
  display: flex;
  height: 900px;
  width: 100%;
  height: 90vh;
`

const StyledAuthenticateFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  @media (min-width: 1080px) {
    width: 50%;
  }
`

const StyledElement = styled.div`
  padding: 1.25rem;
  margin: auto;
  max-width: 440px;

  @media (min-width: 1080px) {
    min-width: 440px;
    padding: 0;
  }
`

const StyledIllustrationContainer = styled.div`
  display: flex;
  width: 50%;
`

const StyledIllustration = styled.img`
  max-height: 910px;
  width: -webkit-fill-available;
  margin: auto;
  height: -webkit-fill-available;

  @media (min-width: 1900px) {
    max-height: 1180px;
  }
`

const AuthenticateLayout = ({ children }) => {
  const assetFor = useSelector(getAssetFor)

  return (
    <DefaultLayout displayFooter={false}>
      <StyledAuthenticateContainer>
        <StyledAuthenticateFormContainer>
          <StyledElement>
            {children}
          </StyledElement>
        </StyledAuthenticateFormContainer>
        <StyledIllustrationContainer className="k-u-hidden@m-down">
          <StyledIllustration src={assetFor('illustrations/home/Crowdfunding.svg')} />
        </StyledIllustrationContainer>       
      </StyledAuthenticateContainer>
    </DefaultLayout>
  )
}

export default AuthenticateLayout
