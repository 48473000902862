import { Modal, Separator } from '@kisskissbankbank/kitten'
import SubscriptionCard from 'kiss/components/cards/subscription-card'
import { useTranslation } from 'kiss/hooks/use-translation'
import Form from 'kiss/modules/components/reward-modal/form'
import {
  imageProps,
  imageProps_noForm,
  StyledBlock,
} from 'kiss/modules/components/reward-modal/mobile'
import FormAction from 'kiss/modules/components/reward-modal/mobile/form-action'
import SimpleAction from 'kiss/modules/components/reward-modal/mobile/simple-action'
import {
  getDeliveryCountryAmounts,
  getPageColors,
  getProjectState,
  hasFees,
} from 'kiss/modules/project-page/page-state/selectors'
import { isNotPublished } from 'kiss/utils/project/state'
import flow from 'lodash/fp/flow'
import propOr from 'lodash/fp/propOr'
import size from 'lodash/fp/size'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import SubscriptionHelper from '../subscription-helper'
import ContentWithForm from './content-with-form'

const Mobile = ({ reward, isDisabled, ...props }) => {
  const t = useTranslation()
  const pageColors = useSelector(getPageColors)

  const projectState = useSelector(getProjectState)
  const isProjectNotPublished = isNotPublished(projectState)

  const hasMultipleDeliveryModes =
    flow(propOr([])('deliveryModes'), size)(reward) > 1

  const rewardHasFees = useMemo(
    () => hasFees(getDeliveryCountryAmounts(reward)),
    [reward],
  )

  const isUserCanChooseNextPeriod =
    reward?.nextPeriodSubscribable && reward?.currentPeriodSubscribable

  const noForm =
    !reward.hasVariationSelection &&
    !rewardHasFees &&
    !hasMultipleDeliveryModes &&
    !isUserCanChooseNextPeriod

  return (
    <>
      <Modal isOpen closeButtonLabel={t('global.close')} {...props}>
        {isDisabled || noForm ? (
          <Modal.Content align="left">
            <StyledBlock
              style={pageColors}
              className="kiss-RewardModal__contentBlock kiss-RewardModal__contentBlock--noForm"
            >
              <SubscriptionCard
                hasBorder={false}
                className="kiss-RewardModal__content__card"
                reward={reward}
                imageProps={imageProps_noForm}
                displayButton={false}
                truncateTitle={false}
              />
              <SubscriptionHelper
                period={reward.period}
                startDateText={reward.subscribablePeriodLabel}
              />
            </StyledBlock>

            {!isDisabled && (
              <Modal.Actions>
                <SimpleAction
                  reward={reward}
                  buttonText={t('project.modal.submit_subscription')}
                  disabled={isProjectNotPublished}
                />
              </Modal.Actions>
            )}
          </Modal.Content>
        ) : (
          <Modal.Content align="left">
            <Form reward={reward}>
              {({ handleSubmit, isSubmitting, isValid }) => {
                if (!isValid) {
                  const formElement =
                    document.getElementById('reward_modal_form')
                  formElement.scrollIntoView({ behavior: 'smooth' })
                }

                return (
                  <div style={pageColors}>
                    <StyledBlock className="kiss-RewardModal__contentBlock">
                      <SubscriptionCard
                        hasBorder={false}
                        className="kiss-RewardModal__content__card"
                        reward={reward}
                        imageProps={imageProps}
                        displayButton={false}
                        truncateTitle={false}
                      />
                      <Separator className="k-u-hidden@s-up" />
                      <ContentWithForm
                        id="reward_modal_form"
                        className="kiss-RewardModal__content__form"
                        reward={reward}
                      />
                    </StyledBlock>
                    <Modal.Actions>
                      <FormAction
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        buttonText={t('project.modal.submit_subscription')}
                        disabled={isProjectNotPublished}
                      />
                    </Modal.Actions>
                  </div>
                )
              }}
            </Form>
          </Modal.Content>
        )}
      </Modal>
    </>
  )
}

Mobile.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default Mobile
