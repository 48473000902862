import {
  getProjectSlug,
  getProjectId,
} from 'kiss/modules/project-page/page-state/selectors'
import mutation from 'kiss/api/graphql-query'
import query from 'kiss/api/graphql-query'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import {
  MARK_ADMIN_PANEL_INITIAL_DATA_AS_FETCHED,
  UPDATE_PROJECT,
} from 'kiss/modules/project-page/page-state/redux'
import adminPanelQuery from 'kiss/graphql/queries/project/project_admin_panel.graphql'
import rejectProjectQuery from 'kiss/graphql/mutations/project/reject_project.graphql'
import acceptProjectQuery from 'kiss/graphql/mutations/project/accept_project.graphql'
import markProjectAsToImproveQuery from 'kiss/graphql/mutations/project/mark_project_as_to_improve.graphql'
import markProjectAsProcessedQuery from 'kiss/graphql/mutations/project/mark_project_as_processed.graphql'

export const fetchProjectAdminPanelData = () => async (dispatch, getState) => {
  const state = getState()
  const projectSlug = getProjectSlug(state)

  try {
    const response = await query(
      adminPanelQuery,
      {
        slug: projectSlug,
      },
      state,
    )

    dispatch({
      type: UPDATE_PROJECT,
      payload: response.project,
    })
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const rejectProject = () => async (dispatch, getState) => {
  const state = getState()
  const projectId = getProjectId(state)

  try {
    const project = await mutation(
      rejectProjectQuery,
      {
        id: projectId,
      },
      state,
    )
    dispatch({
      type: UPDATE_PROJECT,
      payload: project.rejectProject,
    })
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const acceptProject = () => async (dispatch, getState) => {
  const state = getState()
  const projectId = getProjectId(state)

  try {
    const project = await mutation(
      acceptProjectQuery,
      {
        id: projectId,
      },
      state,
    )
    dispatch({
      type: UPDATE_PROJECT,
      payload: project.acceptProject,
    })
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const markProjectAsToImprove = () => async (dispatch, getState) => {
  const state = getState()
  const projectId = getProjectId(state)

  try {
    const project = await mutation(
      markProjectAsToImproveQuery,
      {
        id: projectId,
      },
      state,
    )
    dispatch({
      type: UPDATE_PROJECT,
      payload: project.markProjectAsToImprove,
    })
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const markProjectAsProcessed = () => async (dispatch, getState) => {
  const state = getState()
  const projectId = getProjectId(state)

  try {
    const project = await mutation(
      markProjectAsProcessedQuery,
      {
        id: projectId,
      },
      state,
    )
    dispatch({
      type: UPDATE_PROJECT,
      payload: project.markProjectAsProcessed,
    })
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const markAdminPanelInitialDataAsFetched = () => ({
  type: MARK_ADMIN_PANEL_INITIAL_DATA_AS_FETCHED,
  payload: {
    adminPanelInitialDataFetched: true,
  },
})
