import {
  Button,
  Container,
  HEADER_HEIGHT,
  KissKissBankBankLogo,
  pxToRem,
  Text,
  Title,
} from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getRouteFor, HOMEPAGE } from '../../routes/redux'

const StyledContainer = styled(Container)`
  min-height: calc(100vh - ${pxToRem(HEADER_HEIGHT * 2)});
  padding-block: ${pxToRem(20)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(50)};
  text-align: center;

  & > * {
    flex-shrink: 0;
  }

  .kiss-403__content > * + * {
    margin-top: ${pxToRem(20)};
  }

  .kiss-403__list {
    list-style-position: inside;

    li + li {
      margin-top: ${pxToRem(5)};
    }

    & li::before {
      content: '•';
      margin-right: 0.5em;
    }
  }
`

const ProjectDeleted = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  return (
    <DefaultLayout displayFooter={false}>
      <main id="main" role="main">
        <StyledContainer>
          <KissKissBankBankLogo small height={20} />
          <Title modifier="tertiary">{t('project_deleted.title')}</Title>
          <div className="kiss-403__content">
            <Text tag="p">{t('project_deleted.precision')} </Text>
          </div>
          <Button modifier="helium" tag="a" href={routeFor(HOMEPAGE)}>
            {t('project_deleted.button')}
          </Button>
        </StyledContainer>
      </main>
    </DefaultLayout>
  )
}

export default ProjectDeleted
