import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'

import {
  getRouteFor,
  LENDOPOLIS,
  MAISON_DE_CROWDFUNDING,
  GOODEED,
  MICRODON,
  YOUMATTER,
} from 'kiss/routes/redux'

import {
  Text,
  LendopolisLogoOnDark,
  MicrodonLogo,
} from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledTitleText = styled(Text)`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
`

const StyledEcosystemContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--color-grey-800);

  @media (min-width: 1440px) {
    margin: auto;
  }
`

const StyledEcosystemImg = styled.img`
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`

export const Ecosystem = () => {
  const t = useTranslation()

  const routeFor = useSelector(getRouteFor)
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledEcosystemContainer>
      <div>
        <StyledTitleText
          tag="h3"
          cssColor="var(--color-grey-000)"
          weight="700"
          family="antiqueolive"
        >
          {t('layouts.footer.ecosystem')}
        </StyledTitleText>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="4"
          viewBox="0 0 31 4"
          fill="none"
        >
          <rect x="0.5" y="0.000244141" width="30" height="4" fill="white" />
        </svg>
      </div>

      <div className="k-Footer__ecosystem--logos">
        <a
          href={routeFor(LENDOPOLIS)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LendopolisLogoOnDark width="179" height="33" />
        </a>
        <a href={routeFor(GOODEED)} target="_blank" rel="noopener noreferrer">
          <StyledEcosystemImg
            width="183"
            height="33"
            src={assetFor('logos/Goodeed.svg')}
            alt={'logos/Goodeed.svg'}
          />
        </a>
        <a
          href={routeFor(MAISON_DE_CROWDFUNDING)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="k-u-flex">
            <StyledEcosystemImg
              width="114"
              height="57"
              style={{ margin: 'auto' }}
              src={assetFor('logos/LaMaison_Blc.svg')}
              alt={'logos/LaMaison_Blc.svg'}
            />
          </div>
        </a>
        <a href={routeFor(YOUMATTER)} target="_blank" rel="noopener noreferrer">
          <StyledEcosystemImg
            width="183"
            height="31"
            src={assetFor('logos/YouMatter-1.svg')}
            alt={'logos/YouMatter-1.svg'}
          />
        </a>
        <a href={routeFor(MICRODON)} target="_blank" rel="noopener noreferrer">
          <MicrodonLogo
            color1="var(--color-grey-000)"
            color2="var(--color-grey-000)"
            width="177"
            height="33"
          />
        </a>
      </div>
    </StyledEcosystemContainer>
  )
}
