import { gql } from '@apollo/client'
import { useMutation } from '../api/graphql-query'

const PAY_BANKWIRE = gql`
  mutation PayBankwire($projectId: ID!) {
    payBankwire(projectId: $projectId) {
      redirectUrl
      error
    }
  }
`

const useCampaignBankwirePayment = () => {
  const [payBankwire] = useMutation(PAY_BANKWIRE)
  return {
    callPayBankwire: async ({ projectId }) => {
      const response = await payBankwire({ variables: { projectId } })
      if (!response) {
        throw new Error('No response given for call PayBankwire')
      }
      if (response.data.payBankwire.error) {
        throw new Error('Call Paybankwire failed')
      }
      return response.data.payBankwire.redirectUrl
    },
  }
}

export default useCampaignBankwirePayment
