import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import React from 'react'
import ProjectCardInfo from './info'

const ProjectCardCollectedCountInfo = ({
  collectedCount,
  goalCount,
  forceDesktop = false,
}) => {
  const t = useTranslation()
  const { viewportIsSOrLess } = useMediaQuery()

  const showMobileVersion = viewportIsSOrLess && !forceDesktop
  const goal = t(goalCount, { formatNumber: true })

  return (
    <ProjectCardInfo
      value={t(
        showMobileVersion
          ? 'app.project_card.mobile.collected_count'
          : 'app.project_card.collected_count',
        {
          count: collectedCount,
          value: t(collectedCount, { formatNumber: true }),
          parseHtml: true,
        },
      )}
      text={t('browsing.project_card.funded_amount', {
        goal_with_currency: goal,
      })}
    />
  )
}

export default ProjectCardCollectedCountInfo
