import { Modal, Separator } from '@kisskissbankbank/kitten'
import BackingCard from 'kiss/components/cards/backing-card'
import { useTranslation } from 'kiss/hooks/use-translation'
import Form from 'kiss/modules/components/reward-modal/form'
import {
  imageProps,
  imageProps_noForm,
  StyledBlock,
} from 'kiss/modules/components/reward-modal/mobile'
import FormAction from 'kiss/modules/components/reward-modal/mobile/form-action'
import SimpleAction from 'kiss/modules/components/reward-modal/mobile/simple-action'
import {
  getDeliveryCountryAmounts,
  getPageColors,
  getProjectState,
  hasFees,
  isRewardDisabled,
} from 'kiss/modules/project-page/page-state/selectors'
import { isNotPublished } from 'kiss/utils/project/state'
import flow from 'lodash/fp/flow'
import propOr from 'lodash/fp/propOr'
import size from 'lodash/fp/size'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ContentWithForm from './content-with-form'

const Mobile = ({ reward, isDisabled, ...props }) => {
  const t = useTranslation()
  const pageColors = useSelector(getPageColors)

  const projectState = useSelector(getProjectState)
  const isProjectNotPublished = isNotPublished(projectState)

  const rewardIsDisabled = useSelector(isRewardDisabled)(reward) || isDisabled
  const hasMultipleDeliveryModes =
    flow(propOr([])('deliveryModes'), size)(reward) > 1

  const rewardHasFees = useMemo(
    () => hasFees(getDeliveryCountryAmounts(reward)),
    [reward],
  )

  const noForm =
    !reward.hasVariationSelection && !rewardHasFees && !hasMultipleDeliveryModes
  return (
    <>
      <Modal isOpen closeButtonLabel={t('global.close')} {...props}>
        {() => {
          return rewardIsDisabled || noForm ? (
            <Modal.Content style={pageColors}>
              <StyledBlock className="kiss-RewardModal__contentBlock kiss-RewardModal__contentBlock--noForm">
                <BackingCard
                  hasBorder={false}
                  hasShadow={false}
                  className="kiss-RewardModal__content__card"
                  reward={reward}
                  imageProps={imageProps_noForm}
                  displayButton={false}
                  truncateTitle={false}
                />
              </StyledBlock>
              {!isDisabled && (
                <Modal.Actions>
                  <SimpleAction
                    reward={reward}
                    disabled={isProjectNotPublished || rewardIsDisabled}
                  />
                </Modal.Actions>
              )}
            </Modal.Content>
          ) : (
            <Modal.Content align="left" style={pageColors}>
              <Form reward={reward}>
                {({ handleSubmit, isSubmitting, isValid }) => {
                  if (!isValid) {
                    const formElement =
                      document.getElementById('reward_modal_form')
                    formElement.scrollIntoView({ behavior: 'smooth' })
                  }

                  return (
                    <>
                      <StyledBlock className="kiss-RewardModal__contentBlock">
                        <BackingCard
                          hasBorder={false}
                          hasShadow={false}
                          className="kiss-RewardModal__content__card"
                          reward={reward}
                          imageProps={imageProps}
                          displayButton={false}
                          truncateTitle={false}
                        />
                        <Separator className="k-u-hidden@s-up" />
                        <ContentWithForm
                          id="reward_modal_form"
                          className="kiss-RewardModal__content__form"
                          reward={reward}
                        />
                      </StyledBlock>
                      <Modal.Actions>
                        <FormAction
                          handleSubmit={handleSubmit}
                          isSubmitting={isSubmitting}
                          buttonText={t('project.modal.submit')}
                          disabled={isProjectNotPublished}
                        />
                      </Modal.Actions>
                    </>
                  )
                }}
              </Form>
            </Modal.Content>
          )
        }}
      </Modal>
    </>
  )
}

Mobile.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default Mobile
