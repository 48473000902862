import React from 'react'
import PropTypes from 'prop-types'
import { parseHtml } from '@kisskissbankbank/kitten'

const RichTextParagraph = ({ html, ...props }) => {
  if (!html || html === '') return null

  return <p {...props}>{parseHtml(html)}</p>
}

RichTextParagraph.propTypes = {
  html: PropTypes.string.isRequired,
}

export default RichTextParagraph
