import { ProjectCard, Text } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const SubscriptionLines = ({ project }) => {
  const t = useTranslation()
  const {
    organization,
    owner,
    shortDesc,
    subscriptionsConnection,
    onlyRecurringDonation,
  } = project

  return (
    <>
      <ProjectCard.Line>
        <Text size="small" className="k-u-clamp-2">
          {shortDesc}
        </Text>
      </ProjectCard.Line>
      <ProjectCard.Line>
        <Text size="micro">
          {t('app.project_card.by')}{' '}
          <strong className="k-u-weight-600">
            {organization?.name || owner?.username}
          </strong>
        </Text>
      </ProjectCard.Line>
      <ProjectCard.Line
        lastLine
        className={classNames(
          'k-u-flex',
          'k-u-margin-top-double',
          'k-u-flex-alignItems-end',
          {
            'k-u-flex-justifyContent-sb': !project?.hideSubscribersCount,

            'k-u-flex-justifyContent-end': project?.hideSubscribersCount,
          },
        )}
      >
        {!project?.hideSubscribersCount && (
          <Text size="small">
            {t(
              onlyRecurringDonation
                ? 'app.project_card.donators'
                : 'app.project_card.subscribers',
              {
                parseHtml: true,
                count: subscriptionsConnection?.totalCount || 0,
              },
            )}
          </Text>
        )}

        <Text size="small" weight="500" color="primary1">
          {t(
            onlyRecurringDonation
              ? 'app.project_card.donation'
              : 'app.project_card.subscription',
          )}
        </Text>
      </ProjectCard.Line>
    </>
  )
}

export default SubscriptionLines
