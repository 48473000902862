import {
  Button,
  Container,
  HEADER_HEIGHT,
  KissKissBankBankLogo,
  pxToRem,
  Title,
} from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, HOMEPAGE } from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const StyledContainer = styled(Container)`
  min-height: calc(100vh - ${pxToRem(HEADER_HEIGHT * 2)});
  padding-block: ${pxToRem(20)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(50)};
  text-align: center;

  & > * {
    flex-shrink: 0;
  }
`

const NotFound = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <DefaultLayout>
      <main id="main" role="main">
        <StyledContainer>
          <KissKissBankBankLogo small height={20} />
          <Title modifier="tertiary">{t('not_found.title')}</Title>
          <Button modifier="helium" tag="a" href={routeFor(HOMEPAGE)}>
            {t('not_found.button')}
          </Button>
        </StyledContainer>
      </main>
    </DefaultLayout>
  )
}

export default NotFound
