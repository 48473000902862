import merge from 'lodash/fp/merge'

export const NAME = 'PROJECT_PAGE'
export const NAVIGATION_ID = 'project-navigation'
export const REWARDS_MOBILE_ID = 'project-rewards'
export const REWARDS_TARGET_ID = 'rewards'
export const MENTORS_LIMIT = 12
export const BACKERS_PER_LOAD = 12
export const COMMENTS_PER_LOAD = 12
export const MANAGER_COMMENTS_PER_LOAD = 96

export const UPDATE_PROJECT_SLUG = `${NAME}/UPDATE_PROJECT_SLUG`
export const UPDATE_PROJECT = `${NAME}/UPDATE_PROJECT`
const UPDATE_CURRENT_USER_HAS_FOLLOWED = `${NAME}/UPDATE_CURRENT_USEFOLLOWED`
export const INIT_NAV_ITEMS = `${NAME}/INIT_NAV_ITEMS`
export const UPDATE_PROJECT_ORDERS = `${NAME}/UPDATE_PROJECT_ORDERS`
export const UPDATE_PROJECT_COMMENTS = `${NAME}/UPDATE_PROJECT_COMMENTS`
const DELETE_COMMENT = `${NAME}/DELETE_COMMENT`
export const LIKE_COMMENT = `${NAME}/LIKE_COMMENT`
export const UNLIKE_COMMENT = `${NAME}/UNLIKE_COMMENT`
const LIKE_NEWS = `${NAME}/LIKE_NEWS`
const UNLIKE_NEWS = `${NAME}/UNLIKE_NEWS`
export const REPLACE_NEWS = `${NAME}/REPLACE_NEWS`
export const UPDATE_NEWS = `${NAME}/UPDATE_NEWS`
export const UPDATE_COMMENTS = `${NAME}/UPDATE_COMMENTS`
export const GET_FILTERED_NEWS = `${NAME}/GET_FILTERED_NEWS`
export const UPDATE_DRAFT_LINK = `${NAME}/UPDATE_DRAFT_LINK`
export const ADD_MENTOR = `${NAME}/ADD_MENTOR`
export const SHOW_REWARDS_HALO = `${NAME}/SHOW_REWARDS_HALO`
export const HIDE_REWARDS_HALO = `${NAME}/HIDE_REWARDS_HALO`
const UPDATE_CART = `${NAME}/UPDATE_CART`
export const ADD_REWARDS = `${NAME}/ADD_REWARDS`
export const MARK_ADMIN_PANEL_INITIAL_DATA_AS_FETCHED = `${NAME}/MARK_ADMIN_PANEL_INITIAL_DATA_AS_FETCHED`
export const MARK_OWNER_PANEL_INITIAL_DATA_AS_FETCHED = `${NAME}/MARK_OWNER_PANEL_INITIAL_DATA_AS_FETCHED`

export const initialState = {
  projectSlug: null,
  id: null,
  locale: 'fr',
  slug: '',
  state: '',
  name: '',
  categories: [],
  city: '',
  hideSubscribersCount: true,
  image: {
    url: '',
    thumbUrl: '',
    normalUrl: '',
    mp4Url: '',
    webmUrl: '',
  },
  owner: {
    avatarUrl: '',
    name: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  },
  pageColors: {
    primary_100: '',
    primary_300: '',
    primary_500: '',
    primary_700: '',
    primary_900: '',
  },
  payouts: [],
  kissbankersCount: 0,
  collectedAmount: {
    cents: 0,
  },
  collectedCount: 0,
  goalType: 'amount',
  goalCount: 0,
  goalAmount: {
    cents: 0,
    currency: 'eur',
  },
  endAt: 0,
  deletedAt: null,
  suspendedAt: null,
  shortDesc: '',
  description: '',
  descriptionFunding: '',
  teamDescription: '',
  facebookUrl: null,
  twitterUrl: null,
  instagramUrl: null,
  websiteUrl: null,
  youtubeUrl: null,
  draftPageSharingEnabled: false,
  draftPageToken: null,
  navItems: [
    { key: 'description', text: 'project_tab.description' },
    { key: 'news', text: 'project_tab.news' },
    { key: 'comments', text: 'project_tab.comments' },
    { key: 'backers', text: 'project_tab.backers' },
  ],
  currentUser: {
    hasSubscribed: false,
    isOwner: false,
    isManager: false,
  },
  rewards: [],
  mentors: [],
  newsConnection: [],
  contributionsConnection: {
    edges: [],
  },
  commentsConnection: {
    totalCount: 0,
    edges: [],
  },
  faqs: [],
  rewardsHaloStatus: false,
  challenges: [],
  languages: [],
  inExtratime: false,
  adminPanelInitialDataFetched: false,
  ownerPanelInitialDataFetched: false,
  isIndexable: true,
  engagements: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_NAV_ITEMS:
      return { ...state, navItems: action.payload }
    case ADD_MENTOR:
    case SHOW_REWARDS_HALO:
    case HIDE_REWARDS_HALO:
    case UPDATE_PROJECT_SLUG:
    case MARK_ADMIN_PANEL_INITIAL_DATA_AS_FETCHED:
    case MARK_OWNER_PANEL_INITIAL_DATA_AS_FETCHED:
    case UPDATE_NEWS:
    case UPDATE_COMMENTS:
      return { ...state, ...action.payload }
    case GET_FILTERED_NEWS:
      return { ...state, newsConnection: action.payload }
    case UPDATE_DRAFT_LINK:
      return {
        ...state,
        draftPageSharingEnabled: action.payload.draftPageSharingEnabled,
        draftPageToken: action.payload.draftPageToken,
      }
    case UPDATE_PROJECT_ORDERS:
    case UPDATE_PROJECT_COMMENTS:
    case UPDATE_CURRENT_USER_HAS_FOLLOWED:
    case DELETE_COMMENT:
    case LIKE_COMMENT:
    case UNLIKE_COMMENT:
    case LIKE_NEWS:
    case UNLIKE_NEWS:
    case UPDATE_CART:
    case UPDATE_PROJECT:
      return merge(state, action.payload)
    case ADD_REWARDS:
      return {
        ...state,
        rewards: action.payload.rewards,
      }
    case REPLACE_NEWS: {
      const updatedNews = Array.isArray(action.payload.updatedNews)
        ? action.payload.updatedNews.map((news) => ({ node: news }))
        : state.newsConnection.edges
      return {
        ...state,
        newsConnection: {
          ...state.newsConnection,
          edges: updatedNews,
        },
      }
    }
    default:
      return state
  }
}

export default { [NAME]: reducer }
