import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import useContributeStepper from '../hooks/use-stepper'
import { Progress } from '@kisskissbankbank/kitten'

const ProgressBar = () => {
  const t = useTranslation()
  const { stepperCurrentStepNumber, stepperTotalSteps } = useContributeStepper()
  const progressPercent = (stepperCurrentStepNumber / stepperTotalSteps) * 100

  return (
    <Progress
      aria-label={t('cart.contribute.progress.aria_label')}
      value={progressPercent}
    />
  )
}

export default ProgressBar
