import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

const NAME = 'MEDIA_QUERIES'
const UPDATE_MEDIA_QUERIES = `${NAME}/UPDATE_MEDIA_QUERIES`

const initialState = {
  prefersDarkMode: false,
  prefersLightMode: true,
  viewportIsXXS: false,
  viewportIsXSOrLess: false,
  viewportIsSOrLess: false,
  viewportIsMOrLess: false,
  viewportIsLOrLess: false,
}

const reducer = (state = initialState, action) => {
  if (
    state === null ||
    state === undefined ||
    Object.keys(state).length === 0
  ) {
    state = initialState
  }

  switch (action.type) {
    case UPDATE_MEDIA_QUERIES:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export const updateMediaQueries = (mediaQueries) => (dispatch) =>
  dispatch({ type: UPDATE_MEDIA_QUERIES, payload: mediaQueries })

export const matchMediaQuery = createSelector(
  [(state) => state?.[NAME] ?? false],
  (mediaQueries) => (mediaQuery) => mediaQueries[mediaQuery] || false,
)

export const useMediaQuery = () => {
  return useSelector((state) => state?.[NAME] ?? {})
}

export default { [NAME]: reducer }
