import React from 'react'
import styled from 'styled-components'
import {
  AdaptableGrid as Grid,
  AdaptableGridCol as GridCol,
  pxToRem,
} from '@kisskissbankbank/kitten'

const StyledGridCol = styled(({ innerAs, ...others }) => (
  <GridCol as={innerAs} {...others} />
))`
  border-bottom: var(--border-width) solid var(--color-grey-200);
  padding-top: ${pxToRem(15)};
  padding-bottom: ${pxToRem(15)};
  word-break: break-word;
`

const DefinitionList = styled((props) => <Grid as="dl" {...props} />)`
  border-top: var(--border-width) solid var(--color-grey-200);
  margin-top: ${pxToRem(40)};
  line-height: 1.5rem;
`

const Title = ({ children }) => (
  <StyledGridCol col="12" col-s="2" innerAs="dt">
    {children}
  </StyledGridCol>
)

const Description = ({ children }) => (
  <StyledGridCol col="12" col-s="10" innerAs="dd">
    {children}
  </StyledGridCol>
)

DefinitionList.Title = Title
DefinitionList.Description = Description

export default DefinitionList
