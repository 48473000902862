import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { RewardCard } from '@kisskissbankbank/kitten'

const HeadingTag = ({ reward, isProjectNotPublished, isUnavailable }) => {
  const t = useTranslation()

  if (isUnavailable) {
    return <RewardCard.HeadingTag text={t('reward_card.unavailable')} />
  }

  if (isProjectNotPublished) {
    return <RewardCard.HeadingTag text={t('reward_card.available_soon')} />
  }

  return (
    <>
      {reward.starred && (
        <RewardCard.HeadingTag
          icon="star"
          text={t('subscription_card.star_label')}
        />
      )}
      {reward.exclusive && (
        <RewardCard.HeadingTag
          icon="diamond"
          text={t('subscription_card.exclusive_label')}
        />
      )}
    </>
  )
}

export default HeadingTag
