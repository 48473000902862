import React from 'react'
import { Paragraph } from '@kisskissbankbank/kitten'

const NoResult = ({ total, message }) => {
  if (total > 0) return null

  return (
    <Paragraph noMargin modifier="secondary" className="k-u-align-center">
      {message}
    </Paragraph>
  )
}

export default NoResult
