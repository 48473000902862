export const ReducerHelper = {
  // TODO: create a custom combineReducers to prevent Redux warning.
  // cf. https://github.com/reactjs/redux/issues/2427
  attachPlainReducer: (stateEntries = []) => {
    const plainReducer = (state = {}) => state

    return stateEntries.reduce((acc, entry) => {
      acc[entry] = plainReducer

      return acc
    }, {})
  },
}
