import { Text } from '@kisskissbankbank/kitten'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  line-break: anywhere;
`

const ProjectCardInfo = ({ value, text }) => {
  return (
    <Wrapper>
      <Text weight="600" size="small" className="k-u-block k-u-ellipsis">
        {value}
      </Text>

      <Text size="micro" className="k-u-ellipsis">
        {text}
      </Text>
    </Wrapper>
  )
}

ProjectCardInfo.propTypes = {
  value: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}

export default ProjectCardInfo
