import {
  Carousel,
  Container,
  CONTAINER_PADDING,
  Grid,
  GridCol,
  HorizontalStroke,
  parseHtml,
  pxToRem,
  SCREEN_SIZE_M,
  ScreenConfig,
  SimpleCard,
  Title,
} from '@kisskissbankbank/kitten'
import { getAssetFor } from 'kiss/assets/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { matchMediaQuery } from 'kiss/media-queries/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const StyledLightningBackground = styled.div`
  position: relative;
  overflow: hidden;
  padding: ${pxToRem(50)} 0;
  background: linear-gradient(
    to right,
    var(--color-grey-000) 0%,
    var(--color-grey-000) 50%,
    var(--color-primary-100) 50%,
    var(--color-primary-100) 100%
  );

  @media (min-width: ${ScreenConfig.S.min}px) {
    padding: ${pxToRem(100)} 0;
  }

  .k-CarouselSection__svgBackground {
    position: absolute;
    z-index: 1;
    top: ${pxToRem(50)};
    right: 0;
  }

  .k-CarouselSection__svg {
    margin-top: ${pxToRem(-150)};
    margin-right: -${pxToRem(CONTAINER_PADDING)};
  }

  .k-CarouselSection__content {
    position: relative;
    z-index: 2;
  }

  .k-CarouselSection__content--position {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-end;
  }

  .k-CarouselSection__content--title {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-bottom: ${pxToRem(30)};

    @media (min-width: ${ScreenConfig.L.min}px) {
      margin-left: ${pxToRem(-50)};
    }
  }

  .k-CarouselSection__content--horizontalStroke {
    display: none;

    @media (min-width: ${ScreenConfig.L.min}px) {
      margin-right: ${pxToRem(20)};
      flex-shrink: 0;
      display: block;
    }
  }
`

const CarouselSection = () => {
  const t = useTranslation()

  return (
    <StyledLightningBackground>
      <div className="k-CarouselSection__svgBackground">
        <svg
          className="k-CarouselSection__svg"
          width="454"
          height="666"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 454 666"
        >
          <path
            d="M201.09.21L.683 87.636l138.371 368.668 151.323-127.358 105.1 244.722-30.16-470.375-117.735 86.608L201.09.21"
            fill="var(--color-grey-000)"
            fillRule="evenodd"
          />
        </svg>
      </div>
      <div className="k-CarouselSection__content">
        <Container>
          <Grid>
            <GridCol
              className="k-CarouselSection__content--position"
              col-l="5"
              offset-l="1"
              col-s="7"
              col-xs="10"
            >
              <div className="k-CarouselSection__content--title">
                <HorizontalStroke
                  className="k-CarouselSection__content--horizontalStroke"
                  size="medium"
                />
                <Title
                  tag="h2"
                  modifier="quaternary"
                  noMargin
                  family="antiqueolive"
                  className="has-overrided"
                >
                  {t('fund_my_project.carousel.title', {
                    parseHtml: true,
                  })}
                </Title>
              </div>
            </GridCol>
          </Grid>
        </Container>

        <CarouselList />
      </div>
    </StyledLightningBackground>
  )
}

const CarouselList = () => {
  const assetFor = useSelector(getAssetFor)
  const t = useTranslation()
  const matchMediaQuerySelector = useSelector(matchMediaQuery)
  const itemMinWith = matchMediaQuerySelector('viewportIsXSOrLess') ? 200 : 280

  const creators = [
    {
      imageProps: {
        src: assetFor('maker/fund_my_project/carousel/video1.png'),
        alt: t('fund_my_project.carousel.item1.image_alt'),
      },
      title: t('fund_my_project.carousel.item1.title'),
      paragraph: t('fund_my_project.carousel.item1.paragraph'),
      link: 'https://vimeo.com/285806706',
    },
    {
      imageProps: {
        src: assetFor('maker/fund_my_project/carousel/video2.png'),
        alt: t('fund_my_project.carousel.item2.image_alt'),
      },
      title: t('fund_my_project.carousel.item2.title'),
      paragraph: t('fund_my_project.carousel.item2.paragraph'),
      link: 'https://vimeo.com/289083720',
    },
    {
      imageProps: {
        src: assetFor('maker/fund_my_project/carousel/video3.png'),
        alt: t('fund_my_project.carousel.item3.image_alt'),
      },
      title: t('fund_my_project.carousel.item3.title'),
      paragraph: t('fund_my_project.carousel.item3.paragraph'),
      link: 'https://vimeo.com/293363955',
    },
    {
      imageProps: {
        src: assetFor('maker/fund_my_project/carousel/video4.png'),
        alt: t('fund_my_project.carousel.item4.image_alt'),
      },
      title: t('fund_my_project.carousel.item4.title'),
      paragraph: t('fund_my_project.carousel.item4.paragraph'),
      link: 'https://vimeo.com/288703427',
    },
    {
      imageProps: {
        src: assetFor('maker/fund_my_project/carousel/video5.png'),
        alt: t('fund_my_project.carousel.item5.image_alt'),
      },
      title: t('fund_my_project.carousel.item5.title'),
      paragraph: t('fund_my_project.carousel.item5.paragraph'),
      link: 'https://vimeo.com/288703565',
    },
    {
      imageProps: {
        src: assetFor('maker/fund_my_project/carousel/video6.png'),
        alt: t('fund_my_project.carousel.item6.image_alt'),
      },
      title: t('fund_my_project.carousel.item6.title'),
      paragraph: t('fund_my_project.carousel.item6.paragraph'),
      link: 'https://vimeo.com/288703690',
    },
    {
      imageProps: {
        src: assetFor('maker/fund_my_project/carousel/video7.png'),
        alt: t('fund_my_project.carousel.item7.image_alt'),
      },
      title: t('fund_my_project.carousel.item7.title'),
      paragraph: t('fund_my_project.carousel.item7.paragraph'),
      link: 'https://vimeo.com/289077007',
    },
  ]

  return (
    <Container
      fullWidthBelowScreenSize={SCREEN_SIZE_M}
      style={{ position: 'relative' }}
    >
      <Carousel
        smallButtons
        itemMinWidth={itemMinWith}
        baseItemMarginBetween={CONTAINER_PADDING}
      >
        {creators.map((item, index) => (
          <SimpleCard
            key={item.title + index}
            href={item.link}
            title={parseHtml(item.title, { sanitize: false })}
            paragraph={parseHtml(item.paragraph, { sanitize: false })}
            target="_blank"
            rel="external noopener"
            imageProps={{
              src: item.imageProps.src,
              alt: item.imageProps.alt,
            }}
            withPlayerButtonOnImage
          />
        ))}
      </Carousel>
    </Container>
  )
}

export default CarouselSection
