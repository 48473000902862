export const range = (min, max) => (value) => {
  if (max) return value >= min && value <= max ? void 0 : 'range'
  return value >= min ? void 0 : 'range'
}

/**
 * Check if given url is valid.
 * @param {String} url - URL to test.
 * @return {Boolean} true if url is valid.
 */
export const isValidURL = (url) => {
  const urlFormat = new RegExp('^https?://.*', 'i')

  return urlFormat.test(url)
}

/**
 * Check if given url is a valid social network url.
 * @param {String} socialNetwork - The social network name to test.
 * @param {String} url - URL associated to the social network.
 * @return {Boolean} true if url is a social network one.
 */
export const isValidSocialNetworkURL = (socialNetwork, url) => {
  const socialNetworkTest = new RegExp(
    `^(?:https?://)(?:www.)?(?:${socialNetwork}.com/)(?:.{3,})`,
  )

  return socialNetworkTest.test(url)
}

// Specific validator for social network url
export const checkSocialNetwork = (socialNetworkName) => (value) => {
  if (!value) return void 0

  const availableSocialNetworks = [
    'facebook',
    'twitter',
    'instagram',
    'youtube',
    'website',
  ]
  const errorMessage = 'invalidSocialNetworkUrl'

  if (!availableSocialNetworks.includes(socialNetworkName)) return errorMessage

  if (socialNetworkName === 'website') {
    return isValidURL(value) ? void 0 : errorMessage
  }

  return isValidSocialNetworkURL(socialNetworkName, value)
    ? void 0
    : errorMessage
}

export const isValidMail = (string) =>
  /^[-a-z0-9_+.]+@([-a-z0-9]+\.)+[a-z0-9]+$/i.test(string)

export const isGif = (url) => /(.gif)$/gim.test(url)
export const isSvg = (url) => /(.svg)$/gim.test(url)
