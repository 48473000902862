import React from 'react'
import styled from 'styled-components'
import {
  getFacebookUrl,
  getTwitterUrl,
  getInstagramUrl,
  getYoutubeUrl,
  getWebsiteUrl,
} from 'kiss/modules/project-page/page-state/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  FacebookButtonIcon,
  InstagramButtonIcon,
  pxToRem,
  Text,
  Title,
  TwitterButtonIcon,
  YoutubeButtonIcon,
} from '@kisskissbankbank/kitten'

const StyledSocial = styled.aside`
  padding: ${pxToRem(40)} ${pxToRem(20)};
  border-top: ${pxToRem(4)} solid var(--color-grey-900);
  border-bottom: ${pxToRem(4)} solid var(--color-grey-900);

  & > :nth-child(3) {
    margin-top: ${pxToRem(10)};
  }

  .kiss-ProjectPageSocialLinks__column {
    display: flex;
    margin-top: ${pxToRem(30)};
    flex-wrap: wrap;
  }

  .kiss-ProjectPageSocialLinks__link {
    display: block;
    flex: 1 0 calc(50% - ${pxToRem(10)});
    margin-bottom: ${pxToRem(20)};
    white-space: nowrap;

    &:nth-child(2n + 1) {
      margin-right: ${pxToRem(20)};
    }
  }
`

const Social = () => {
  const t = useTranslation()

  const facebookUrl = useSelector(getFacebookUrl)
  const twitterUrl = useSelector(getTwitterUrl)
  const instagramUrl = useSelector(getInstagramUrl)
  const youtubeUrl = useSelector(getYoutubeUrl)
  const websiteUrl = useSelector(getWebsiteUrl)
  const hasSocialUrl = facebookUrl || twitterUrl || instagramUrl || youtubeUrl

  if (!hasSocialUrl && !websiteUrl) return null

  const linkProps = {
    tag: 'a',
    target: '_blank',
    rel: 'nofollow noopener',
    weight: '500',
    color: 'font1',
    size: 'small',
    className: 'kiss-ProjectPageSocialLinks__link',
  }

  const linkButtonProps = {
    as: 'span',
    size: 'small',
    className: 'k-u-margin-right-single',
  }

  return (
    <StyledSocial className="kiss-ProjectPageSocialLinks">
      {hasSocialUrl && (
        <>
          <Title tag="p" modifier="senary" noMargin>
            {t('project_social.title')}
          </Title>

          <div className="kiss-ProjectPageSocialLinks__column">
            {facebookUrl && (
              <Text {...linkProps} href={facebookUrl}>
                <FacebookButtonIcon {...linkButtonProps} />
                Facebook
              </Text>
            )}

            {twitterUrl && (
              <Text {...linkProps} href={twitterUrl}>
                <TwitterButtonIcon {...linkButtonProps} />
                Twitter
              </Text>
            )}

            {instagramUrl && (
              <Text {...linkProps} href={instagramUrl}>
                <InstagramButtonIcon {...linkButtonProps} />
                Instagram
              </Text>
            )}

            {youtubeUrl && (
              <Text {...linkProps} href={youtubeUrl}>
                <YoutubeButtonIcon {...linkButtonProps} />
                YouTube
              </Text>
            )}
          </div>
        </>
      )}

      {websiteUrl && (
        <Text weight="400" size="small" tag="p" className="k-u-line-height-1-3">
          {t('project_social.website')}
          <br />
          <Text
            tag="a"
            href={websiteUrl}
            target="_blank"
            rel="nofollow noopener"
            weight="500"
            color="primary1"
          >
            {websiteUrl.replace(/(^\w+:|^)\/\//, '')}
          </Text>
        </Text>
      )}
    </StyledSocial>
  )
}

export default Social
