import {
  Button,
  domElementHelper,
  Grid,
  GridCol,
  Text,
} from '@kisskissbankbank/kitten'
import { Form as FormikForm, Formik } from 'formik'
import { addErrorAlert, removeErrorAlerts } from 'kiss/app/alerts/redux'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import Label from 'kiss/components/formik/label'
import Location from 'kiss/components/formik/location'
import Phone from 'kiss/components/formik/phone'
import SimpleText from 'kiss/components/formik/simple-text'
import ScrollOnError from 'kiss/components/formik/scroll-on-error'
import config from 'kiss/config'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor } from 'kiss/routes/redux'
import { GooglePlaceHelper } from 'kiss/utils/google-place-helper'
import omit from 'lodash/fp/omit'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { object, string } from 'yup'
import {
  isBillingAddressRequired,
  isSpecificShippingForDonationReceipts,
} from '../../cart/redux'
import useContributeStepper from '../../hooks/use-stepper'
import { getProjectSlug } from '../../redux'
import {
  getAddress,
  getBillingAddress,
  updateAddress,
  updateBillingAddress,
} from '../redux'
import AddressBis from './address-bis'
import BillingAddress from './billing-address'
import CompanyName from './company-name'
import Countries from './countries'

export const MAX_LENGTH_ADDRESS = 38

const Form = () => {
  const t = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const address = useSelector(getAddress)
  const billingAddress = useSelector(getBillingAddress)
  const projectSlug = useSelector(getProjectSlug)
  const routeFor = useSelector(getRouteFor)
  const specificShippingForDonationReceipts = useSelector(
    isSpecificShippingForDonationReceipts,
  )

  const needsBillingAddress = useSelector(isBillingAddressRequired)
  const { nextRoute } = useContributeStepper()

  const [inputValue, setInputValue] = useState()
  const [showAddressBis, setShowAddressBis] = useState(false)
  const [sameAddress, setSameAddress] = useState(true)

  useEffect(() => {
    domElementHelper.canUseDom() && document.getElementById('firstName').focus()
  }, [])

  const initialValues = omit(['address2', 'address3', 'address4'])(address)

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...initialValues,
        additional2: address.address2,
        additional3: address.address3,
        additional4: address.address4,
        ...(needsBillingAddress && {
          billingAddress: {
            firstName: billingAddress?.firstName ?? '',
            lastName: billingAddress?.lastName ?? '',
            companyName: billingAddress?.companyName ?? '',
            phone: billingAddress?.phone ?? '',
            address1: billingAddress?.address1 ?? '',
            additional2: billingAddress?.address2 ?? '',
            additional3: billingAddress?.address3 ?? '',
            additional4: billingAddress?.address4 ?? '',
            postalCode: billingAddress?.postalCode ?? '',
            city: billingAddress?.city ?? '',
            countryCode: billingAddress?.countryCode ?? '',
          },
        }),
      }}
      validationSchema={object().shape({
        firstName: string().required(
          t('contribute.shipping_address.form.firstname.error'),
        ),
        lastName: string().required(
          t('contribute.shipping_address.form.lastname.error'),
        ),
        address1: string().required(
          t('contribute.shipping_address.form.address.error'),
        ),
        postalCode: string().required(
          t('contribute.shipping_address.form.postal_code.error'),
        ),
        city: string().required(
          t('contribute.shipping_address.form.city.error'),
        ),
        countryCode: string()
          .required(t('contribute.shipping_address.form.country.error'))
          .nullable(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          dispatch(removeErrorAlerts())
          const valuesFormatted = omit([
            'additional2',
            'additional3',
            'additional4',
            'billingAddress',
          ])(values)
          await dispatch(
            updateAddress({
              ...valuesFormatted,
              address2: values.additional2,
              address3: values.additional3,
              address4: values.additional4,
            }),
          )
          await dispatch(updateBillingAddress({ values, sameAddress }))
          history.push(routeFor(nextRoute, { project: projectSlug }))
        } catch (e) {
          setSubmitting(false)
          dispatch(
            addErrorAlert(t('contribute.shipping_address.form.alert.error')),
          )
        }
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        return (
          <FormikForm>
            <ScrollOnError
              message={t('contribute.shipping_address.form.alert.error')}
            />

            <Grid>
              <GridCol
                col-s="12"
                col-l="6"
                className="k-u-margin-bottom-triple@m-down"
              >
                <Label htmlFor="firstName">
                  {t('contribute.shipping_address.form.firstname.label')}
                </Label>

                <SimpleText
                  name="firstName"
                  autocomplete="given-name"
                  placeholder={t(
                    'contribute.shipping_address.form.firstname.placeholder',
                  )}
                />
              </GridCol>
              <GridCol col-s="12" col-l="6">
                <Label htmlFor="lastName">
                  {t('contribute.shipping_address.form.lastname.label')}
                </Label>

                <SimpleText
                  name="lastName"
                  autocomplete="family-name"
                  placeholder={t(
                    'contribute.shipping_address.form.lastname.placeholder',
                  )}
                />
              </GridCol>
            </Grid>

            <CompanyName />

            {!specificShippingForDonationReceipts && (
              <div className="k-u-margin-bottom-triple">
                <Label htmlFor="phone">
                  {t('contribute.shipping_address.form.phone.label')}
                </Label>

                <Text weight="400" size="nano" id="phone-sublabel">
                  {t('contribute.shipping_address.form.phone.sublabel')}
                </Text>

                <Grid>
                  <GridCol col-s="12" col-xl="6">
                    <Phone
                      name="phone"
                      aria-describedby="phone-sublabel"
                      validate={(value) => {
                        if (!value) {
                          return t(
                            'contribute.shipping_address.form.phone.error',
                          )
                        }
                      }}
                    />
                  </GridCol>
                </Grid>
              </div>
            )}

            <Label htmlFor="address1">
              {t('contribute.shipping_address.form.address.label')}
            </Label>
            {values.countryCode === 'FR' ? (
              <Location
                name="address1"
                placeholder={t(
                  'contribute.shipping_address.form.address.placeholder',
                  { parseHtml: true },
                )}
                onChange={({ value }) => {
                  setInputValue(null)
                  setFieldValue('address1', value)
                }}
                onSelect={({ place }) => {
                  const { address, city, zipcode } =
                    GooglePlaceHelper.getAddressFields(place)
                  setInputValue(address)
                  setFieldValue('address1', address)
                  setFieldValue('postalCode', zipcode)
                  setFieldValue('city', city)
                  setShowAddressBis(true)
                }}
                customInputValue={inputValue}
                searchOptions={{
                  componentRestrictions: { country: 'fr' },
                }}
              />
            ) : (
              <SimpleText
                name="address1"
                placeholder={t(
                  'contribute.shipping_address.form.address.placeholder',
                  { parseHtml: true },
                )}
                maxLength={MAX_LENGTH_ADDRESS}
                validate={(value) => {
                  if (value?.length === MAX_LENGTH_ADDRESS) {
                    return t('contribute.shipping_address.error.max_length')
                  }
                }}
              />
            )}

            <AddressBis showAddressBis={showAddressBis} />

            <Grid>
              <GridCol col="4" className="k-u-margin-bottom-triple">
                <Label htmlFor="postalCode">
                  {t('contribute.shipping_address.form.postal_code.label')}
                </Label>

                <SimpleText
                  name="postalCode"
                  autoComplete="kisskiss-prevent-autocomplete"
                  inputMode="text"
                  placeholder={t(
                    'contribute.shipping_address.form.postal_code.placeholder',
                  )}
                  validate={(value) => {
                    if (values.countryCode === 'FR' && value.length > 5) {
                      return t(
                        'contribute.shipping_address.form.postal_code.fr_error',
                      )
                    }
                  }}
                />
              </GridCol>
              <GridCol col="8" className="k-u-margin-bottom-triple">
                <Label htmlFor="city">
                  {t('contribute.shipping_address.form.city.label')}
                </Label>

                <SimpleText
                  name="city"
                  autoComplete="kisskiss-prevent-autocomplete"
                  placeholder={t(
                    'contribute.shipping_address.form.city.placeholder',
                  )}
                  maxLength={MAX_LENGTH_ADDRESS}
                  validate={(value) => {
                    if (value?.length === MAX_LENGTH_ADDRESS) {
                      return t('contribute.shipping_address.error.max_length')
                    }
                  }}
                />
              </GridCol>
            </Grid>
            <div className="k-u-margin-bottom-quadruple">
              <Countries />
            </div>

            {needsBillingAddress && (
              <BillingAddress
                onChangeBillingAddressSameAsDelivery={setSameAddress}
              />
            )}

            <div className="k-u-margin-bottom-double">
              {isSubmitting ? (
                <SubmitLoader fit="fluid" />
              ) : (
                <Button
                  type="submit"
                  modifier="helium"
                  data-test-id="shipping-next-button"
                  fit="fluid"
                  size="large"
                >
                  {t('contribute.shipping_address.button.submit')}
                </Button>
              )}
            </div>
          </FormikForm>
        )
      }}
    </Formik>
  )
}

const FormWrapper = () => {
  return (
    <>
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${config[APP_ENV].google.placeApiKey}&libraries=places&loading=async`}
          type="text/javascript"
        />
      </Helmet>
      <Form />
    </>
  )
}

export default FormWrapper
