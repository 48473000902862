import React from 'react'
import { Route } from 'react-router-dom'

import styled from 'styled-components'
import loadable from '@loadable/component'

import {
  HeroLayout,
  KissKissLoadingAnimation,
  mq,
  pxToRem,
} from '@kisskissbankbank/kitten'

import Helmet from 'kiss/modules/project-page/permanent/components/helmet'
import SubscriberLayout from 'kiss/modules/project-page/permanent/subscriber/components/layout'
import PermanentProjectAbout from 'kiss/modules/project-page/permanent/common-pages/about'

import {
  SUBSCRIBER_PROJECT_NEWS_ROUTE,
  SUBSCRIBER_PROJECT_ABOUT_ROUTE,
  SUBSCRIBER_PROJECT_FAQ_ROUTE,
  SUBSCRIBER_PROJECT_SUBSCRIPTION_ROUTE,
} from 'kiss/routes/redux'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(50)};

  @media ${mq.desktop} {
    gap: ${pxToRem(70)};
  }

  .kiss-PermanentProject__LoadableSection {
    min-height: ${pxToRem(100)};

    &:last-child {
      min-height: 100vh;
    }

    .kiss-PermanentProject__LoadableSection__loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const Loader = () => (
  <div className="kiss-PermanentProject__LoadableSection__loader">
    <KissKissLoadingAnimation />
  </div>
)

const PermanentProjectNews = loadable(
  () =>
    import(
      /* webpackChunkName: 'subscribers-permanent-project-news', webpackrefetch: true */ 'kiss/modules/project-page/permanent/subscriber/news'
    ),
  {
    fallback: <Loader />,
  },
)

const PermanentProjectFaq = loadable(
  () =>
    import(
      /* webpackChunkName: 'subscribers-permanent-project-faq', webpackrefetch: true */ 'kiss/modules/project-page/permanent/common-pages/faq'
    ),
  {
    fallback: <Loader />,
  },
)

const PermanentProjectRewards = loadable(
  () =>
    import(
      /* webpackChunkName: 'subscribers-permanent-project-rewards', webpackrefetch: true */ 'kiss/modules/project-page/permanent/subscriber/rewards'
    ),
  {
    fallback: <Loader />,
  },
)

const Subscriber = () => {
  return (
    <>
      <Helmet />
      <SubscriberLayout>
        <HeroLayout.Main.Content>
          <StyledWrapper>
            <Route path={SUBSCRIBER_PROJECT_ABOUT_ROUTE}>
              <PermanentProjectAbout />
            </Route>
            <Route path={SUBSCRIBER_PROJECT_NEWS_ROUTE}>
              <PermanentProjectNews withContributeButton={false} />
            </Route>
            <Route path={SUBSCRIBER_PROJECT_FAQ_ROUTE}>
              <PermanentProjectFaq withContributeButton={false} />
            </Route>
            <Route path={SUBSCRIBER_PROJECT_SUBSCRIPTION_ROUTE}>
              <PermanentProjectRewards />
            </Route>
          </StyledWrapper>
        </HeroLayout.Main.Content>
      </SubscriberLayout>
    </>
  )
}

export default Subscriber
