import { Separator, Text } from '@kisskissbankbank/kitten'
import { Displayer } from '@kisskissbankbank/underwood'
import config from 'kiss/config'
import { useTranslation } from 'kiss/hooks/use-translation'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import map from 'lodash/fp/map'
import React from 'react'
import { StyledWrapper } from './styles'

const Rewards = ({ order }) => {
  const t = useTranslation()
  const shippedRewards = flow(
    getOr([])('orderLines'),
    map((orderLine) => {
      return {
        ...orderLine,
        projectName: order.projectName,
      }
    }),
  )(order)
  if (isEmpty(shippedRewards)) return null
  return (
    <div>
      <Text
        size="huge"
        tag="h3"
        weight="500"
        className="k-u-margin-bottom-single"
      >
        {shippedRewards.length > 1
          ? t('users.contributions.col.rewards')
          : t('users.contributions.col.reward')}
      </Text>
      <div className="k-u-grid k-u-flex-direction-column k-u-flex-gap-single">
        {shippedRewards.map((reward) => {
          return (
            <StyledWrapper key={reward.label}>
              <div className="kiss-ContributionDetails__gridWrapper">
                <div className="kiss-ContributionDetails__imageWrapper">
                  {reward.image && (
                    <img
                      src={reward.image.thumbUrl}
                      alt={reward.image.filename}
                    />
                  )}
                </div>
                <div>
                  <Text weight="500" size="small">
                    {reward.label}
                  </Text>
                  <Text size="small" weight="400">
                    <br />
                    {t('users.contributions.details.by')}{' '}
                    <Text weight="500" size="medium">
                      {reward.projectName}
                    </Text>
                  </Text>

                  <Text
                    tag="p"
                    size="small"
                    className="k-u-margin-top-single k-u-margin-bottom-none"
                  >
                    <Displayer
                      text={reward.description}
                      compact
                      configResponsiveImageHandler={
                        config[APP_ENV].responsiveImageHandler
                      }
                    />
                  </Text>
                </div>
              </div>

              <Separator className="k-u-margin-top-singleHalf k-u-margin-bottom-singleHalf" />
              <div className="kiss-ContributionDetails__details">
                <div>
                  <Text size="small">
                    {t('users.contributions.details.deliveryMode')}
                  </Text>
                  <Text
                    tag="p"
                    weight="500"
                    size="small"
                    className="k-u-margin-none"
                  >
                    {t(
                      `users.contributions.delivery.${reward.deliveryModeCode}`,
                    )}
                  </Text>
                </div>
                <div>
                  {reward.option && (
                    <>
                      <Text size="small">
                        {t('users.contributions.details.option')}
                      </Text>
                      <Text
                        tag="p"
                        weight="500"
                        size="small"
                        className="k-u-margin-none"
                      >
                        {reward.option}
                      </Text>
                    </>
                  )}
                </div>
              </div>
            </StyledWrapper>
          )
        })}
      </div>
    </div>
  )
}

export default Rewards
