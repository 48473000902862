import {
  Button,
  HorizontalStroke,
  LongArrowIconNext,
  mq,
  pxToRem,
  Title,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { getRouteByName } from 'kiss/routes/redux'
import groupBy from 'lodash/fp/groupBy'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import slugify from 'slugify'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;

  .kiss-CarouselNav__title {
    height: ${pxToRem(400)};
    padding: ${pxToRem(40)};
    box-sizing: border-box;
    background-color: var(--color-primary-100);
    background-repeat: no-repeat;

    @media ${mq.tablet} {
      height: ${pxToRem(600)};
    }

    @media ${mq.desktop} {
      flex: 1;
    }
  }

  &.kiss-CarouselNav--don-recurrent .kiss-CarouselNav__title {
    background-size: 60%;
    background-position: 80% 80%;
  }
  &.kiss-CarouselNav--reward .kiss-CarouselNav__title {
    background-size: 120%;
    background-position: 50% 80%;

    @media ${mq.desktop} {
      background-size: 170%;
    }
  }
  &.kiss-CarouselNav--donation .kiss-CarouselNav__title {
    background-size: 300px;
    background-position: 115% 80%;

    @media ${mq.tablet} {
      background-size: 60%;
    }

    @media ${mq.desktop} {
      background-size: 80%;
    }
  }
  &.kiss-CarouselNav--presale .kiss-CarouselNav__title {
    background-size: 300px;
    background-position: 100% 70%;

    @media ${mq.tablet} {
      background-size: 60%;
    }

    @media ${mq.desktop} {
      background-size: 75%;
    }
  }
  &.kiss-CarouselNav--envoyer-ses-contreparties .kiss-CarouselNav__title,
  &.kiss-CarouselNav--preparation .kiss-CarouselNav__title,
  &.kiss-CarouselNav--communiquer-autour-de-sa-campagne
    .kiss-CarouselNav__title {
    background-size: 50%;
    background-position: 80% 80%;

    @media ${mq.tablet} {
      background-size: 50%;
    }

    @media ${mq.desktop} {
      background-size: 50%;
    }
  }

  .kiss-CarouselNav__title__link {
    align-items: center;
    display: inline-flex;
    gap: ${pxToRem(10)};
    transition:
      transform var(--transition),
      color var(--transition) !important;
    will-change: transform, color;
    cursor: pointer;

    &:focus,
    &:hover {
      transform: translate(${pxToRem(-10)});

      svg {
        fill: currentColor;
      }
    }
  }

  .kiss-CarouselNav__title__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${pxToRem(20)};

    padding: ${pxToRem(40)} 0 0;

    @media ${mq.tablet} {
      padding: ${pxToRem(55)};
    }

    @media ${mq.desktop} {
      padding: ${pxToRem(80)} ${pxToRem(30)};
    }
  }

  .kiss-CarouselNav__nav {
    position: sticky;
    bottom: 0;

    box-sizing: border-box;
    height: ${pxToRem(80)};
    padding: ${pxToRem(20)} ${pxToRem(40)};

    display: grid;
    grid-template-columns: 1fr auto 1fr;
    background-color: var(--color-grey-900);
    align-items: center;

    .kiss-CarouselNav__nav__previous .kiss-CarouselNav__nav__link {
      --translation-multiplier: -1;
    }
    .kiss-CarouselNav__nav__dots {
      padding: ${pxToRem(14)};
      display: flex;
      gap: ${pxToRem(8)};

      a {
        position: relative;
        display: block;
        width: ${pxToRem(8)};
        height: ${pxToRem(8)};
        border-radius: var(--border-radius-rounded);
        background-color: var(--color-grey-000);
        transition: background-color var(--transition);
        outline-offset: ${pxToRem(2)};

        &::after {
          content: '';
          position: absolute;
          top: ${pxToRem(-4)};
          left: ${pxToRem(-4)};
          right: ${pxToRem(-4)};
          bottom: ${pxToRem(-4)};
        }

        &:hover,
        &[aria-current] {
          background-color: var(--color-primary-500);
        }
      }
    }
    .kiss-CarouselNav__nav__next {
      text-align: right;
    }

    .kiss-CarouselNav__nav__link {
      display: flex;
      gap: ${pxToRem(10)};
      align-items: center;
      transition:
        transform var(--transition),
        color var(--transition) !important;
      will-change: transform, color;

      & span {
        flex: 1 1 auto;
      }
      & svg {
        flex: 0 0 auto;
      }

      &:hover,
      &:focus {
        transform: translateX(
          calc(var(--translation-multiplier, 1) * ${pxToRem(10)})
        );
      }
    }

    @media ${mq.mobileAndTablet} {
      display: none;
    }
  }
`

const RichContentCarouselNavigation = ({
  name,
  title,
  index,
  numberOfPages,
  backgroundUrl,
  links,
  className,
}) => {
  const routeByName = useSelector(getRouteByName)
  const linksByName = groupBy((link) => link.name)(links)

  const back = linksByName.back ? linksByName.back[0] : null
  const callToAction = linksByName.callToAction[0]
  const paginationLinks = linksByName.other.map((link) => {
    return {
      ...link,
      url: link?.route ? routeByName(link.route) : link.url,
    }
  })

  const previous =
    linksByName.other[
      (((index - 2) % numberOfPages) + numberOfPages) % numberOfPages
    ]
  const next = linksByName.other[index % numberOfPages]

  return (
    <StyledWrapper
      className={classNames(
        'kiss-CarouselNav',
        className,
        `kiss-CarouselNav--${slugify(name.toLowerCase())}`,
      )}
    >
      <div
        className="kiss-CarouselNav__title"
        style={{ backgroundImage: `url(${backgroundUrl})` }}
      >
        {back && (
          <a
            href={routeByName(back.route)}
            className="k-u-link k-u-link-font1 kiss-CarouselNav__title__link"
          >
            <LongArrowIconNext
              aria-hidden
              color="currentColor"
              width="12"
              direction="left"
            />
            <span>{back.text}</span>
          </a>
        )}

        <div className="kiss-CarouselNav__title__box">
          <Title tag="h1" modifier="tertiary" noMargin family="antiqueolive">
            {title}
          </Title>
          <HorizontalStroke className="k-u-margin-bottom-triple@l-up" />
          <Button
            as="a"
            href={routeByName(callToAction.route)}
            modifier="helium"
          >
            {callToAction.text}
          </Button>
        </div>
      </div>

      <div className="kiss-CarouselNav__nav">
        <div className="kiss-CarouselNav__nav__previous">
          <a
            href={routeByName(previous.route)}
            className="k-u-link k-u-link-background1 kiss-CarouselNav__nav__link"
          >
            <LongArrowIconNext
              aria-hidden
              color="currentColor"
              width="12"
              direction="left"
            />
            <span>{previous.text}</span>
          </a>
        </div>
        <ul className="kiss-CarouselNav__nav__dots">
          {paginationLinks.map((item, idx) => (
            <li key={idx}>
              <a
                href={item.url}
                aria-current={parseInt(index, 10) === idx + 1 ? 'page' : null}
              >
                <span className="k-u-a11y-visuallyHidden">{item.text}</span>
              </a>
            </li>
          ))}
        </ul>
        <div className="kiss-CarouselNav__nav__next">
          <a
            href={routeByName(next.route)}
            className="k-u-link k-u-link-background1 kiss-CarouselNav__nav__link"
          >
            <span>{next.text}</span>
            <LongArrowIconNext
              aria-hidden
              color="currentColor"
              width="12"
              direction="right"
            />
          </a>
        </div>
      </div>
    </StyledWrapper>
  )
}

RichContentCarouselNavigation.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  backgroundUrl: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      route: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
}

export default RichContentCarouselNavigation
