import { gql } from '@apollo/client'
import { useMutation } from 'kiss/api/graphql-query'
import config from 'kiss/config'
import useMessageStatus from 'kiss/modules/messaging/hooks/use-message-status'
import { getCurrentUserId } from 'kiss/session/redux'
import { waitFor } from 'kiss/utils/wait-for'
import filter from 'lodash/fp/filter'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import map from 'lodash/fp/map'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

const CHAT_SIGN_IN = gql`
  mutation ChatSignInMutation($userId: ID) {
    chatSignIn(userId: $userId) {
      accessToken
      username
    }
  }
`

const RequestUnreadMessages = ({ children }) => {
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false)
  const userId = useSelector(getCurrentUserId)
  const [signIn, { data }] = useMutation(CHAT_SIGN_IN)
  const username = data?.chatSignIn?.username
  const accessToken = data?.chatSignIn?.accessToken
  const { getStatusForRoom } = useMessageStatus()
  const checkForUnreadMessages = flow(
    map((room) => getStatusForRoom(room)),
    filter((status) => status === 'unread'),
    isEmpty,
  )
  useEffect(() => {
    signIn({ variables: { userId } })
  }, [userId])
  useEffect(() => {
    if (!username || !accessToken) return
    waitFor('matrixcs', () => {
      const matrixClient = window.matrixcs.createClient({
        baseUrl: config[APP_ENV].matrix.host,
        timelineSupport: true,
        accessToken,
        userId: username,
      })
      matrixClient.on('Room.timeline', function () {
        const rooms = matrixClient.getVisibleRooms()
        if (isEmpty(rooms)) return
        setHasUnreadMessages(!checkForUnreadMessages(rooms))
      })

      matrixClient.startClient({ initialSyncLimit: 1 }).then(() => {
        matrixClient.once('sync', async (state) => {
          if (state === 'PREPARED') {
            const rooms = matrixClient.getVisibleRooms()
            if (isEmpty(rooms)) return
            setHasUnreadMessages(!checkForUnreadMessages(rooms))
          }
        })
      })
    })
  }, [username, accessToken])
  return (
    <>
      <Helmet>
        <script src="/browser-matrix.min.js" defer={true}></script>
      </Helmet>
      {children({ hasUnreadMessages })}
    </>
  )
}

export default RequestUnreadMessages
