import { AlertBox, Button, LinkIconNext, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import Social from 'kiss/modules/project-page/campaign/components/intro/components/social'
import React from 'react'
import { useSelector } from 'react-redux'
import { getProjectUrl } from '../../../page-state/selectors'

const SuccessSection = () => {
  const t = useTranslation()
  const projectUrl = useSelector(getProjectUrl)

  const onCopyLink = () => {
    return navigator.clipboard.writeText(projectUrl)
  }

  return (
    <div className="k-u-flex k-u-flex-direction-column kiss-PreLaunch__success-section">
      <AlertBox status="success" iconPosition="center">
        <Text>{t('prelaunch.success.message', { parseHtml: true })}</Text>
      </AlertBox>

      <Text className="k-u-margin-top-noneHalf" weight="500">
        {t('prelaunch.success.help_project', { parseHtml: true })}
      </Text>

      <div
        className="k-u-flex
          k-u-flex-direction-column@s-down
          k-u-flex-alignItems-center
          k-u-flex-justifyContent-center"
      >
        <Social withFollowButton={false} showWidget={false} />
        <Button
          modifier="boron"
          onClick={onCopyLink}
          className="k-u-margin-top-singleHalf k-u-margin-left-singleHalf"
        >
          <LinkIconNext />
          <Text>{t('prelaunch.success.copy_link')}</Text>
        </Button>
      </div>
    </div>
  )
}

export default SuccessSection
