import { mq, pxToRem, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import React from 'react'
import styled from 'styled-components'
import Category from './category'
import Results from './results'
import State from './state'

const StyledFiltersWrapper = styled.div`
  position: relative;
  z-index: ${Z_INDEX.global.filterModal};

  display: grid;
  gap: ${pxToRem(10)};
  align-items: center;

  @media ${mq.desktop} {
    grid-template-columns:
      auto
      1fr
      auto
      ${pxToRem(200)}
      ${pxToRem(180)};
    grid-template-areas: 'results
                         .
                         display_text
                         category
                         state';
  }
  .kiss-filters__display_text {
    grid-area: display_text;
    white-space: nowrap;
  }
  .kiss-filters__category {
    grid-area: category;
  }
  .kiss-filters__state {
    grid-area: state;
  }
  .kiss-filters__results {
    grid-area: results;
  }

  // fix Autocomplete n'a pas la prop size
  .kiss-filters__location input {
    height: ${pxToRem(40)};
  }
`

const Filters = ({ className }) => {
  const t = useTranslation()
  return (
    <StyledFiltersWrapper className={className}>
      <Results className="kiss-filters__results" />
      <Text size="small" weight="500" className="kiss-filters__display_text">
        {t('browsing.fitler.show')}
      </Text>
      <Category className="kiss-filters__category" />
      <State className="kiss-filters__state" />
    </StyledFiltersWrapper>
  )
}

export default Filters
