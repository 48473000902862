import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { RewardCard, Tag } from '@kisskissbankbank/kitten'
import { isSubscribersCountHidden } from 'kiss/modules/project-page/page-state/selectors'

const Tags = ({ reward }) => {
  const t = useTranslation()
  const hideSubscribersCount = useSelector(isSubscribersCountHidden)

  return (
    <>
      {(!!reward.subscribersCount || !!reward.quantity) && (
        <RewardCard.TagList>
          {!!reward.subscribersCount && !hideSubscribersCount && (
            <Tag as="li" variant="light">
              <strong className="k-u-weight-500">
                {reward.subscribersCount}
              </strong>{' '}
              {t('permanent_project.total_subscriptions', {
                count: reward.subscribersCount,
              })}
            </Tag>
          )}

          {!!reward.quantity && (
            <Tag as="li" variant="light">
              <strong className="k-u-weight-500">
                {reward.remaining ? reward.remaining : 0}
              </strong>{' '}
              {t('reward_card.available', {
                count: reward.remaining ? reward.remaining : 0,
              })}
            </Tag>
          )}
        </RewardCard.TagList>
      )}
    </>
  )
}

export default Tags
