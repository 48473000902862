import {
  CONTAINER_PADDING,
  CONTAINER_PADDING_THIN,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import SubscriptionCard from 'kiss/components/cards/subscription-card'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getProjectState,
  isContributingActionDisabled,
  isRewardDisabled,
} from 'kiss/modules/project-page/page-state/selectors'
import { isNotPublished } from 'kiss/utils/project/state'
import React from 'react'
import { useSelector } from 'react-redux'

const SMALL_MARGIN = CONTAINER_PADDING_THIN + 60
const BIG_MARGIN = CONTAINER_PADDING + 8
const BIG_GUTTER = 20

const imageProps = {
  breakpoints: [0, ScreenConfig.S.min],
  imageSizesInViewport: [
    `100vw - ${CONTAINER_PADDING_THIN + SMALL_MARGIN}px`,
    `(100vw - ${CONTAINER_PADDING + BIG_MARGIN + BIG_GUTTER}px) / 2`,
  ],
  responsiveImageSizes: [190, 220, 275, 325, 375, 414],
}

const Card = ({ subscription, handleModal, userIsBacking = false }) => {
  const t = useTranslation()
  const subscriptionDisabled = useSelector(isRewardDisabled)(subscription)
  const subscriptionActionDisabled = useSelector(isContributingActionDisabled)

  const projectState = useSelector(getProjectState)
  const isProjectNotPublished = isNotPublished(projectState)

  const isDisabled =
    subscriptionDisabled || subscriptionActionDisabled || userIsBacking

  return (
    <SubscriptionCard
      reward={subscription}
      onClick={(event) => !isDisabled && handleModal({ event, subscription })}
      as={isDisabled ? 'article' : 'button'}
      type={isDisabled ? null : 'button'}
      isProjectNotPublished={isProjectNotPublished}
      truncateText
      buttonProps={{
        as: 'span',
        children: t('permanent_project.text.button.subscribe'),
      }}
      imageProps={imageProps}
      isDisabled={userIsBacking}
    />
  )
}

export default Card
