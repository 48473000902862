import React from 'react'
import { TitleWithStroke } from '@kisskissbankbank/kitten'
import { getOrderTotal } from '../redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import FacebookPixelProvider from 'kiss/utils/tracking/facebook-pixel/provider'
import FacebookPixelTracker from 'kiss/utils/tracking/facebook-pixel/tracker'

const Title = ({ noResultTitle }) => {
  const t = useTranslation()
  const orderTotal = useSelector(getOrderTotal)

  return (
    <div className="k-u-hidden@xs-down">
      <TitleWithStroke modifier="quaternary">
        {orderTotal !== 0 && t('contribute.cart.title')}
        {orderTotal === 0 && noResultTitle}
      </TitleWithStroke>

      <FacebookPixelProvider>
        <FacebookPixelTracker eventName="add_to_cart" />
      </FacebookPixelProvider>
    </div>
  )
}

export default Title
