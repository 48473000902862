import { Alert as KittenAlert, parseHtml } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { removeAlert } from 'kiss/app/alerts/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { AlertsContext } from '../context'

const Alert = ({ uuid, message, type, className }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const alertsCtx = useContext(AlertsContext)

  return (
    <KittenAlert
      status={type}
      closeButtonLabel={t('alerts.close_button')}
      closeButton
      onClose={() => {
        dispatch(removeAlert(uuid))
        alertsCtx.removeAlert(uuid)
      }}
      className={classNames('kiss-RichText', className)}
    >
      {parseHtml(message)}
    </KittenAlert>
  )
}

Alert.propTypes = {
  uuid: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['danger', 'success', 'info']),
}

Alert.defaultProps = {
  type: 'info',
}

export default Alert
