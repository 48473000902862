import Label from 'kiss/components/formik/label'
import Dropdown from 'kiss/components/formik/dropdown-select'
import { useTranslation } from 'kiss/hooks/use-translation'
import PropTypes from 'prop-types'
import React from 'react'

const Variations = ({ variations }) => {
  const t = useTranslation()
  return (
    <div className="k-u-margin-bottom-triple">
      <Label htmlFor="variation" label={t('project.modal.options.label')} />
      <Dropdown
        name="variation"
        placeholder={t('project.modal.options.placeholder')}
        options={variations.map(
          ({ uuid, label, totalQuantity, remainingQuantity }) => ({
            label: `${label} ${
              totalQuantity ? ` - ${remainingQuantity}/${totalQuantity}` : ``
            }`,
            value: uuid,
            disabled: totalQuantity && remainingQuantity === 0,
          }),
        )}
      />
    </div>
  )
}

Variations.propTypes = { variations: PropTypes.array.isRequired }

export default Variations
