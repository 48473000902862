import React from 'react'
import { useSelector } from 'react-redux'
import HomePage from '../../home'
import PrismicPage from '../../prismic-page'
import CampaignTipsLayout from '../campaign-tips'
import { getSlices, getType } from './redux'

const PrismicPreview = () => {
  const type = useSelector(getType)
  const slices = useSelector(getSlices)
  switch (type) {
    case 'pre-deposit-subpage':
      return <CampaignTipsLayout slices={slices} />

    case 'homepage':
      return <HomePage />

    default:
      return <PrismicPage slices={slices} />
  }
}

export default PrismicPreview
