import { ContributionCard, RocketIllustration } from '@kisskissbankbank/kitten'
import { Form, Formik, useField } from 'formik'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { getOrganizationOrOwnerName } from 'kiss/modules/contribute/redux'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { getTipAmount, getTipCurrency, updateTip } from '../../../cart/redux'

const TipInput = () => {
  const t = useTranslation()
  const tipCurrency = useSelector(getTipCurrency)
  const [field, meta, helpers] = useField('tip')
  return (
    <ContributionCard.Input
      aria-label={t('cart.tip.amount.description')}
      unit={t(`currency_symbol.${tipCurrency}`)}
      valid={!!field.value && !meta.touched}
      {...field}
      onChange={(e) => {
        field.onChange(e)
        helpers.setTouched(true)
      }}
    />
  )
}

const Tip = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const projectOwner = useSelector(getOrganizationOrOwnerName)
  const tipAmount = useSelector(getTipAmount)
  const { viewportIsSOrLess } = useMediaQuery()
  return (
    <Formik
      initialValues={{ tip: tipAmount }}
      validationSchema={Yup.object().shape({
        tip: Yup.number()
          .min(0, t('app.from.error.minmax', { min: 1, max: 1000000 }))
          .max(1000000, t('app.from.error.minmax', { min: 1, max: 1000000 }))
          .integer(t('app.from.error.integer')),
      })}
      onSubmit={async ({ tip }, { setFieldTouched }) => {
        await dispatch(updateTip(tip * 100))
        setFieldTouched('tip', false)
      }}
    >
      {({ touched }) => {
        return (
          <Form className="k-u-margin-bottom-singleHalf">
            <ContributionCard
              borderStyle="dashed"
              closeButton={false}
              largeInput
            >
              {!viewportIsSOrLess && (
                <ContributionCard.Image>
                  <RocketIllustration width={45} height={81} />
                </ContributionCard.Image>
              )}
              <ContributionCard.Title>
                {t('cart.tip.title', { project_owner_name: projectOwner })}
                <ContributionCard.Description>
                  {t('cart.tip.description')}
                </ContributionCard.Description>
              </ContributionCard.Title>
              <TipInput />
              <ContributionCard.Action
                data-test-id="tip-submit"
                isInputValid={!!tipAmount && !touched.tip}
              >
                {t('cart.tip.new.button')}
              </ContributionCard.Action>
            </ContributionCard>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Tip
