import React, { memo, Fragment } from 'react'
import styled, { css } from 'styled-components'
import RichTextTitle from 'kiss/modules/components/rich-text/title'
import RichContentText from 'kiss/modules/components/rich-content/text'
import {
  Container,
  Grid,
  GridCol,
  HORIZONTAL_STROKE_STYLE_LARGE as LARGE,
  HORIZONTAL_STROKE_STYLE_MEDIUM as MEDIUM,
  HorizontalStroke,
  pxToRem,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'

const StyledHorizontalStroke = styled(({ position, ...others }) => (
  <HorizontalStroke {...others} />
))`
  ${MEDIUM}
  width: ${pxToRem(40)};
  margin: 0 auto;

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    ${LARGE}
    height: ${pxToRem(6)};
    margin: 0;

    ${({ position }) =>
      position === 'center' &&
      css`
        margin: 0 auto;
      `}
  }
`

const TitleGrid = ({ children }) => (
  <Container>
    <Grid>
      <GridCol
        col-xs="10"
        offset-xs="1"
        col-m="8"
        offset-m="2"
        col-l="6"
        offset-l="3"
      >
        {children}
      </GridCol>
    </Grid>
  </Container>
)

const Title = ({ title, subtitle, withoutGrid }) => {
  const GridComponent = withoutGrid ? Fragment : TitleGrid

  return (
    <GridComponent>
      <RichTextTitle
        modifiers={{
          h1: 'secondary',
          h2: 'tertiary',
          h3: 'quaternary',
          h4: 'quinary',
        }}
        {...title}
        className={classNames(
          'k-u-align-center',
          'k-u-margin-bottom-singleHalf',
          'k-u-margin-bottom-double@s-up',
          {
            'k-u-align-left@s-up': title.position !== 'center',
          },
        )}
      />
      <StyledHorizontalStroke position={title.position} />
      {!!subtitle && subtitle.length > 0 && (
        <RichContentText
          content={subtitle}
          className={classNames(
            'k-u-align-center',
            'k-u-size-large',
            'k-u-margin-top-double',
            'k-u-margin-top-triple@s-up',
            {
              'k-u-align-left@s-up': title.position !== 'center',
            },
          )}
        />
      )}
    </GridComponent>
  )
}

export default memo(Title)
