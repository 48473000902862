import { HeroLayout } from '@kisskissbankbank/kitten'
import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import { CloseReplyFormsProvider } from 'kiss/modules/project-page/common/contexts/CloseReplyForms'
import { CommentsProvider } from '../../contexts/comments'
import NewsCard from '../news/components/news-card'
import NewsLayout from './components/layout'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getNews } from '../../../page-state/selectors'

const SharedNews = () => {
  const { locale } = useParams()
  const news = useSelector(getNews)

  if (isEmpty(news)) return null
  return (
    <CloseReplyFormsProvider>
      <CommentsProvider>
        <NewsLayout>
          <HeroLayout.Main.Content>
            <NewsCard newsItem={news[0]} locale={locale} titleLevel="h1" />
          </HeroLayout.Main.Content>
        </NewsLayout>
      </CommentsProvider>
    </CloseReplyFormsProvider>
  )
}

export default SharedNews
