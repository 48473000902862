import {
  Container,
  CONTAINER_PADDING,
  CONTAINER_PADDING_THIN,
  FlexWrapper,
  Grid,
  GridCol,
  mq,
  pxToRem,
  ScreenConfig,
  TitleWithStroke,
  Video,
} from '@kisskissbankbank/kitten'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import RichContentButton from 'kiss/modules/components/rich-content/button'
import RichContentText from 'kiss/modules/components/rich-content/text'
import getTitleId from 'kiss/utils/get-title-id'
import React, { memo } from 'react'
import styled from 'styled-components'

const StyledGrid = styled(Grid)`
  align-items: center;

  @media ${mq.desktop} {
    margin-top: ${pxToRem(-100)};
  }
`

const StyledVideo = styled.div`
  height: ${pxToRem(200)};
  margin-left: -${pxToRem(CONTAINER_PADDING_THIN)};
  margin-right: -${pxToRem(CONTAINER_PADDING_THIN)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    height: ${pxToRem(350)};
    margin-left: -${pxToRem(CONTAINER_PADDING)};
    margin-right: -${pxToRem(CONTAINER_PADDING)};
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    height: ${pxToRem(800)};
    margin-right: 0;
  }
`

const HeroWithVideo = ({ title, description, callToAction, videoUrl }) => (
  <Container>
    <StyledGrid>
      <GridCol col-l="5" className="k-u-hidden@m-down">
        <StyledVideo>
          <Video autoPlay playsInline loop muted src={videoUrl}>
            <Video.Loader>
              <LoadingAnimation />
            </Video.Loader>
          </Video>
        </StyledVideo>
      </GridCol>

      <GridCol col-xs="10" offset-xs="1" col-l="5">
        <FlexWrapper gap={20}>
          {title.text && (
            <TitleWithStroke
              id={getTitleId(title.text)}
              tag={`h${title.level}`}
              modifier="secondary"
              family="antiqueolive"
            >
              {title.text}
            </TitleWithStroke>
          )}

          {description && (
            <RichContentText
              content={description}
              paragraphProps={{
                fontSizeDefault: 'primary',
                fontSizeFromS: null,
              }}
            />
          )}

          {callToAction.text && (
            <RichContentButton
              className="k-u-margin-top-triple k-u-alignSelf-start k-u-alignSelf-stretch@xs-down"
              as="a"
              {...callToAction}
            />
          )}
        </FlexWrapper>
      </GridCol>
    </StyledGrid>
  </Container>
)

export default memo(HeroWithVideo)
