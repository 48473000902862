import React from 'react'

import { useSelector } from 'react-redux'
import {
  getOrganizationOrOwnerAvatarNormalUrl,
  getOrganizationOrOwnerName,
  getProjectCity,
} from 'kiss/modules/project-page/page-state/selectors'

import { imageDensityAttributes } from 'kiss/utils/responsive-image/image-density-attributes'

import {
  AvatarWithTextAndBadge,
  pxToRem,
  ScreenConfig,
  stepToRem,
  Text,
} from '@kisskissbankbank/kitten'
import styled from 'styled-components'

const IMAGE_WIDTH = 50

const StyledText = styled(Text)`
  display: block;
  line-height: 1.3;
  font-size: ${stepToRem(-2)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    font-size: ${stepToRem(-1)};
  }
`

const Avatar = ({ children }) => {
  const representativeAvatarNormalUrl = useSelector(
    getOrganizationOrOwnerAvatarNormalUrl,
  )
  const representativeName = useSelector(getOrganizationOrOwnerName)

  const projectCity = useSelector(getProjectCity)

  return (
    <AvatarWithTextAndBadge>
      <AvatarWithTextAndBadge.Image
        alt=""
        {...imageDensityAttributes(representativeAvatarNormalUrl, {
          imageWidth: IMAGE_WIDTH,
        })}
      />

      <AvatarWithTextAndBadge.Text>
        <StyledText weight="500" className="k-u-link k-u-link-primary1">
          {representativeName}
        </StyledText>
        <StyledText>{projectCity}</StyledText>
      </AvatarWithTextAndBadge.Text>
      {children}
    </AvatarWithTextAndBadge>
  )
}

export default Avatar
