import { parseHtml } from '@kisskissbankbank/kitten'
import i18next from 'i18next'
import { getLocale } from 'kiss/app/redux'
import mapValues from 'lodash/fp/mapValues'
import React, { createContext, useContext } from 'react'
import { useSelector } from 'react-redux'

/**
 * format(v, n, x, s, c)
 *
 * @param integer v: value to format
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 *
 * Source: https://stackoverflow.com/questions/149055
 */
const format = (v, n, x, s, c) => {
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? '\\D' : '$'})`
  const num = v.toFixed(Math.max(0, n))
  let number = c ? num.replace('.', c) : num

  // Remove `.00` if the decimal is equal to zero.
  if (n && number.match(`\\D0{${n}}`)) {
    number = parseInt(number)
  }

  return `${number}`.replace(new RegExp(re, 'g'), `$&${s || ','}`)
}

const formatNumber = (value, decimal, locale) => {
  const thousandLength = 3
  let separator
  let delimiter

  switch (locale) {
    case 'en':
      separator = ','
      delimiter = '.'
      break
    case 'nl':
      separator = '.'
      delimiter = ','
      break
    case 'fr':
      separator = '\xa0'
      delimiter = ','
      break
    default:
      separator = ','
      delimiter = '.'
  }

  return format(
    parseFloat(value),
    decimal,
    thousandLength,
    separator,
    delimiter,
  )
}

const createI18nextInstance = ({ locale = 'en', translations = {} }) => {
  const i18nextInstance = i18next.createInstance()

  i18nextInstance.init({
    lng: locale,
    compatibilityJSON: 'v3',
    resources: {
      [locale]: {
        translation: validTranslation(translations),
      },
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  })

  return i18nextInstance
}

const internationalization = ({ locale, translations }) => {
  const i18nextInstance = createI18nextInstance({ locale, translations })

  return {
    getTranslation: (key, options = {}) => {
      if (options.formatNumber) {
        return formatNumber(
          key,
          options.formatNumber.decimal || 0,
          i18nextInstance.language,
        )
      }

      const value = i18nextInstance.t(key, options)
      return options.parseHtml ? parseHtml(value, { sanitize: false }) : value
    },
  }
}

const convertToI18nextVar = (value) => {
  if (typeof value !== 'string') return value
  // Convert `%{var}` into `{{var}}` in string.
  return value.replace(/%{(\w*)}/gm, '{{$1}}')
}

// TODO: Remove this function when all translation will use {{var}}`
// instead of `%{var}`
const validTranslation = (translations) => {
  return mapValues(convertToI18nextVar)(translations)
}

const TranslationsContext = createContext(() => null)

export const TranslationsProvider = ({ children }) => {
  const locale = useSelector(getLocale)
  const translations = useSelector((state) => state.translations)
  const i18nInstance = internationalization({
    locale,
    translations,
  })
  return (
    <TranslationsContext.Provider value={i18nInstance.getTranslation}>
      {children}
    </TranslationsContext.Provider>
  )
}

export const useTranslation = () => {
  return useContext(TranslationsContext)
}
