import { DropdownSelect } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import * as qs from 'neoqs'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getCategories } from '../redux'
import useSearch from '../use-search'

const Category = (props) => {
  const t = useTranslation()
  const categories = useSelector(getCategories)
  const { updateSearch } = useSearch()
  const { search } = useLocation()
  const { category } = qs.parse(search, { ignoreQueryPrefix: true })
  return (
    <DropdownSelect
      {...props}
      labelText={t('browsing.fitler.category.label')}
      size="small"
      defaultSelectedValue={category || 'all'}
      hideLabel
      id="search-select-categories"
      onChange={({ value }) => {
        updateSearch({ category: value })
      }}
      options={[{ label: 'Toutes les catégories', value: 'all' }].concat(
        categories.map(({ slug }) => {
          return {
            label: (
              <span>
                <span aria-hidden>{t(`browsing.categories.${slug}.logo`)}</span>{' '}
                {t(`browsing.categories.${slug}.name`)}
              </span>
            ),
            value: slug,
          }
        }),
      )}
    />
  )
}

export default Category
