import React from 'react'
import { Tag } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'

const OrderStatus = ({ orderStatus }) => {
  const t = useTranslation()

  switch (orderStatus) {
    case 'in_cart':
      return <Tag type="warning">{t('contributor.orders.payment.in_cart')}</Tag>
    case 'cancelled':
    case 'refunded':
      return (
        <Tag type="error">{t(`contributor.orders.payment.${orderStatus}`)}</Tag>
      )
    case 'confirmed':
    case 'cannot_be_fulfilled':
    case 'approved': //paypal
    case 'funded': //paypal
    case 'unfunded': //paypal
      return (
        <Tag type="success">{t('contributor.orders.payment.confirmed')}</Tag>
      )
    default:
      return null
  }
}

export { OrderStatus }
