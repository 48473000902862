import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  hasGoal,
  getGoalType,
  getGoalAmountDescription,
  getGoalCountDescription,
  getGoalCount,
  getAmount,
  getFundedAmount,
  getCollectedCount,
} from '../../../page-state/selectors'
import { mq, Progress, pxToRem, Text } from '@kisskissbankbank/kitten'
import styled from 'styled-components'

const StyledContainer = styled.div`
  padding: ${pxToRem(30)};
  border-radius: var(--border-radius-s);
  box-shadow: var(--box-shadow-s);
  min-width: 40vw;
  @media ${mq.mobile} {
    width: 80vw;
  }

  .kiss-Project-Goal__ProgressContainer {
    display: flex;
    gap: ${pxToRem(10)};
    align-items: baseline;
    min-width: 30vw;
  }
  .kiss-Project-Goal__Description {
    width: 100%;
    @media ${mq.desktop} {
      max-width: 30vw;
      margin: auto;
    }
  }
  .kiss-Project-Goal__ProgressBar {
    width: 100%;
  }
  .kiss-Project-Goal__ProgressBarText {
    flex-shrink: 0;
    > small {
      font: var(--font-weight-400) var(--font-size-2) / 1
        var(--font-family-generalsans);
      margin-right: ${pxToRem(30)};
    }
  }
`

const Goal = () => {
  const t = useTranslation()
  const projectHasGoal = useSelector(hasGoal)
  const goalType = useSelector(getGoalType)

  const goalAmountDescription = useSelector(getGoalAmountDescription)
  const goalAmount = useSelector(getAmount)
  const fundedAmount = useSelector(getFundedAmount)

  const goalCountDescription = useSelector(getGoalCountDescription)
  const goalCount = useSelector(getGoalCount)
  const collectedCount = useSelector(getCollectedCount)

  const currentlyCollected =
    goalType === 'amount' ? fundedAmount : collectedCount
  const goal = goalType === 'amount' ? goalAmount : goalCount

  if (!projectHasGoal) return null

  return (
    <StyledContainer className="k-u-flex k-u-flex-direction-column k-u-flex-gap-single">
      <Text size="micro" transform="uppercase">
        {t(`permanent_project.goal.title_${goalType}`)}
      </Text>
      <Text className="kiss-Project-Goal__Description k-u-weight-700">
        {goalType === 'amount' ? goalAmountDescription : goalCountDescription}
      </Text>
      <div className="kiss-Project-Goal__ProgressContainer">
        <Text className="k-u-weight-700">
          {goalType === 'amount'
            ? t('permanent_project.goal.progress.amount', {
                amount: t(currentlyCollected, { formatNumber: true }),
                parseHtml: true,
              })
            : t(currentlyCollected, { formatNumber: true })}
        </Text>
        <div className="kiss-Project-Goal__ProgressBar">
          <Progress
            aria-label={t('permanent_project.goal.progress.label')}
            value={(currentlyCollected * 100) / goal}
            rampProps={{ style: { height: 6 } }}
          />
        </div>
        <div className="k-u-flex">
          <Text className="kiss-Project-Goal__ProgressBarText k-u-weight-700">
            {goalType === 'amount'
              ? t('permanent_project.goal.progress.amount', {
                  amount: t(goal, { formatNumber: true }),
                  parseHtml: true,
                })
              : `${t(goal, { formatNumber: true })} `}
            {goalType === 'count' && (
              <small>{t('permanent_project.goal.progress.subscribers')}</small>
            )}
          </Text>
        </div>
      </div>
    </StyledContainer>
  )
}
export default Goal
