export const getLocale = (state) => state.locale

export const getSSRHost = (state) => {
  const { scheme, host, port } = state.railsContext
  const isDefaultPort = port === 80 || port === 443

  if (!scheme || !host) return ''

  return `${scheme}://${host}${port && !isDefaultPort ? `:${port}` : ''}`
}
