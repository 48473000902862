import { Button, HeartIconNext } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  subscribeUserToProject,
  unsubscribeUserFromProject,
} from 'kiss/modules/project-page/page-state/actions'
import { getProject } from 'kiss/modules/project-page/page-state/selectors'
import { AUTHENTICATE_ROUTE } from 'kiss/routes/redux'
import { getCurrentUserId } from 'kiss/session/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRouteFor } from 'kiss/routes/redux'

const FollowButton = () => {
  const project = useSelector(getProject)

  const t = useTranslation()

  const dispatch = useDispatch()

  const currentUserUuid = useSelector(getCurrentUserId)
  const routeFor = useSelector(getRouteFor)

  const currentUserHasFollowed = project?.currentUser?.hasFollowed

  const handleFollowAction = async () => {
    // TODO: this should be handle with React-Router.
    if (!currentUserUuid)
      return RoutingHelper.redirect(routeFor(AUTHENTICATE_ROUTE))

    const variables = {
      userUuid: currentUserUuid,
      projectId: project?.uuid,
    }

    if (currentUserHasFollowed) {
      dispatch(unsubscribeUserFromProject(variables))
      return
    }

    dispatch(subscribeUserToProject(variables))
  }

  return (
    <Button
      fit="content"
      size="small"
      rounded
      aria-checked={currentUserHasFollowed}
      onClick={handleFollowAction}
      key={`followed-${currentUserHasFollowed}`}
    >
      <HeartIconNext
        color={
          currentUserHasFollowed
            ? 'var(--color-danger-500)'
            : 'var(--color-grey-900)'
        }
        bgColor={
          currentUserHasFollowed
            ? 'var(--color-danger-500)'
            : 'var(--color-grey-000)'
        }
        className="k-ColorSvg"
      />
      <span>
        {t(
          currentUserHasFollowed
            ? 'permanent_project.actions.follow_button.unfollow'
            : 'permanent_project.actions.follow_button.follow',
        )}
      </span>
    </Button>
  )
}

export default FollowButton
