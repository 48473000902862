import { Component } from 'react'
import { domElementHelper } from '@kisskissbankbank/kitten'

// Source: https://stackoverflow.com/a/16861050/1149166
export const popupCenterPosition = (popupWidth, popupHeight) => {
  if (!window) return { top: null, left: null }
  // Fixes dual-screen position
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY
  const clientWidth = document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width
  const clientHeight = document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height
  const width = window.innerWidth ? window.innerWidth : clientWidth
  const height = window.innerHeight ? window.innerHeight : clientHeight
  const left = width / 2 - popupWidth / 2 + dualScreenLeft
  const top = height / 2 - popupHeight / 2 + dualScreenTop

  return { top, left }
}

class SocialClickHandler extends Component {
  handleClick = (name, url) => {
    if (!domElementHelper.canUseDom()) return

    const width = 560
    const height = 480
    const { top, left } = popupCenterPosition(width, height)

    window.open(
      url,
      `Sharer ${name}`,
      'resizable=yes,scrollbars=yes,titlebar=no' +
        `,width=${width},height=${height}` +
        `,top=${top},left=${left}`,
    )
  }

  render() {
    return this.props.children(this.handleClick)
  }
}

export default SocialClickHandler
