import {
  Button,
  Label,
  pxToRem,
  RewardCard,
  Text,
  TextInputWithUnit,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getCartDonationAmount } from 'kiss/modules/project-page/page-state/selectors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const StyledRecurringDonation = styled(RewardCard)`
  .kiss-RecurringDonation__thumbnailsTrio {
    display: flex;
    justify-content: space-between;
    gap: ${pxToRem(5)};
    margin-bottom: ${pxToRem(20)};
  }

  .kiss-RecurringDonation__thumbnail {
    width: ${pxToRem(90)};
    height: ${pxToRem(90)};
    padding: ${pxToRem(10)};
  }

  .kiss-RecurringDonation__form {
    padding: 0 ${pxToRem(35)};
  }
`
const amounts = [2, 5, 10]

const RecurringDonationCard = ({
  title,
  onlyDonation,
  handleSubmit,
  handleChange,
  handleBlur,
  handleFocus,
  onSelectAmount,
  errorState,
  errorMessage,
  isLoading,
}) => {
  const t = useTranslation()
  const initialDonationAmount = useSelector(getCartDonationAmount)
  const [proposedAmount, setProposedAmount] = useState(null)
  const [freeAmountValue, setFreeAmountValue] = useState(null)

  useEffect(() => {
    if (initialDonationAmount) {
      const proposedAmount = amounts.find(
        (amount) => amount === initialDonationAmount,
      )
      if (proposedAmount) {
        return setProposedAmount(proposedAmount)
      }
      return setFreeAmountValue(initialDonationAmount)
    }
  }, [initialDonationAmount])

  const onClickProposedAmount = (value) => {
    setProposedAmount(value)
    setFreeAmountValue('')
    onSelectAmount(value)
  }

  const onChangeFreeAmount = (event) => {
    const {
      currentTarget: { value },
    } = event
    setFreeAmountValue(value)
    setProposedAmount(null)
    handleChange(event)
  }

  const renderTitle = () => {
    if (title) return title
    if (onlyDonation)
      return t(
        'permanent_project.recurring_donation.title_without_subscription',
      )
    return t('permanent_project.recurring_donation.title_with_subscription')
  }
  return (
    <StyledRecurringDonation>
      <RewardCard.Contents>
        <RewardCard.Title className="k-u-weight-600">
          {renderTitle()}
        </RewardCard.Title>

        <RewardCard.Form>
          <form id="recurring-donation-form" onSubmit={handleSubmit}>
            <Text
              tag="p"
              size="medium"
              className="k-u-margin-bottom-double k-u-margin-top-none"
            >
              {t('permanent_project.recurring_donation.description', {
                parseHtml: true,
              })}
            </Text>
            <div className="kiss-RecurringDonation__thumbnailsTrio">
              {amounts.map((amount) => (
                <Button
                  type="button"
                  key={amount}
                  fit="content"
                  onClick={() => onClickProposedAmount(amount)}
                  active={amount === proposedAmount}
                  aria-checked={amount === proposedAmount || null}
                  className={classNames(
                    'kiss-RecurringDonation__thumbnail',
                    'k-u-flex-gap-none',
                    'k-u-flex-alignItems-center',
                    'k-u-flex-justifyContent-center',
                    'k-u-flex-direction-column',
                  )}
                >
                  <Text weight="600" size="huge">
                    {amount}&nbsp;{t('currency_symbol.eur')}
                  </Text>
                  <Text size="small">
                    {t('permanent_project.recurring_donation.per_month')}
                  </Text>
                </Button>
              ))}
            </div>

            <div className="kiss-RecurringDonation__form">
              <Label
                className="k-u-margin-bottom-single k-u-align-center"
                htmlFor="recurring-donation-input"
              >
                {t('permanent_project.recurring_donation.free_amount', {
                  parseHtml: true,
                })}
              </Label>
              <TextInputWithUnit
                name="recurring-donation-input"
                id="recurring-donation-input"
                autoComplete="off"
                onChange={onChangeFreeAmount}
                onBlur={handleBlur}
                onFocus={handleFocus}
                type="number"
                error={errorState}
                value={freeAmountValue}
                unit={
                  <span>
                    {t('currency_symbol.eur')}{' '}
                    <Text size="small" weight="400">
                      {t('permanent_project.recurring_donation.per_month')}
                    </Text>
                  </span>
                }
              />
              {errorState && (
                <Text
                  size="micro"
                  color="error"
                  weight="500"
                  className="k-u-margin-top-single"
                >
                  {errorMessage}
                </Text>
              )}
            </div>
          </form>
        </RewardCard.Form>
      </RewardCard.Contents>

      {isLoading ? (
        <RewardCard.Button>
          <LoadingAnimation />
        </RewardCard.Button>
      ) : (
        <RewardCard.Button type="submit" form="recurring-donation-form">
          {t('permanent_project.recurring_donation.submit')}
        </RewardCard.Button>
      )}
    </StyledRecurringDonation>
  )
}

export default RecurringDonationCard
