import React from 'react'
import classNames from 'classnames'
import { HorizontalStroke } from '@kisskissbankbank/kitten'

const Separator = ({ className }) => (
  <HorizontalStroke
    size="small"
    customSize={{ width: '100%' }}
    color="var(--color-grey-300)"
    className={classNames('k-u-margin-vertical-double', className)}
  />
)

export default Separator
