import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import {
  getRouteFor,
  PROJECTS_ADMIN,
  START_MASQUERADE_USER,
} from 'kiss/routes/redux'
import {
  getProjectSlug,
  getOwnerOrContactSlug,
} from 'kiss/modules/project-page/page-state/selectors'
import { FlexWrapper, Button } from '@kisskissbankbank/kitten'

const Buttons = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  const projectSlug = useSelector(getProjectSlug)
  const projectOwner = useSelector(getOwnerOrContactSlug)

  return (
    <FlexWrapper
      gap={10}
      direction="row"
      className="k-u-flex-alignItems-center k-u-flex-justifyContent-end"
    >
      <Button
        as="a"
        modifier="hydrogen"
        size="small"
        fit="content"
        href={routeFor(START_MASQUERADE_USER, {
          project: projectSlug,
          user: projectOwner,
        })}
      >
        {t('projects.admin_panel.buttons.take_identity')}
      </Button>

      <Button
        as="a"
        modifier="hydrogen"
        size="small"
        fit="content"
        href={routeFor(PROJECTS_ADMIN, { project: projectSlug })}
      >
        {t('projects.admin_panel.buttons.check_in_admin')}
      </Button>
    </FlexWrapper>
  )
}

export default Buttons
