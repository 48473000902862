import React from 'react'
import flow from 'lodash/fp/flow'
import without from 'lodash/fp/without'
import size from 'lodash/fp/size'
import { Progress, Text, CheckList } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'

const hasAtLeastHeightChar = (value) => /(?=.{8,})/.test(value)
const hasAtLeastOneUpper = (value) => /(?=.*[A-Z])/.test(value)
const hasAtLeastOneNumber = (value) => /(?=.*[0-9])/.test(value)
const hasAtLeastOneLowerChar = (value) => /(?=.*[a-z])/.test(value)

export const calculateProgress = (value) => {
  return (
    flow(
      without([false]),
      size,
    )([
      hasAtLeastHeightChar(value),
      hasAtLeastOneUpper(value),
      hasAtLeastOneNumber(value),
      hasAtLeastOneLowerChar(value),
    ]) * 25
  )
}

const PasswordHelper = ({ password }) => {
  const t = useTranslation()
  return (
    <>
      <div className="k-u-margin-top-double">
        <Progress
          color="var(--color-primary-500)"
          value={calculateProgress(password)}
          rampProps={{
            style: {
              height: 6,
            },
          }}
        />
      </div>
      <div className="k-u-margin-top-double">
        <Text size="small" color="font1" weight="600">
          {t('authenticate.registration.password.reliability')}
        </Text>
      </div>
      <div className="k-u-margin-top-single">
        <CheckList>
          <CheckList.Item done={hasAtLeastHeightChar(password)}>
            <Text size="small" color="font1" weight="400">
              {t('authenticate.registration.password.reliability_characters')}
            </Text>
          </CheckList.Item>
          <CheckList.Item done={hasAtLeastOneLowerChar(password)}>
            <Text size="small" color="font1" weight="400">
              {t('authenticate.registration.password.reliability_lowercase')}
            </Text>
          </CheckList.Item>
          <CheckList.Item done={hasAtLeastOneUpper(password)}>
            <Text size="small" color="font1" weight="400">
              {t('authenticate.registration.password.reliability_uppercase')}
            </Text>
          </CheckList.Item>
          <CheckList.Item done={hasAtLeastOneNumber(password)}>
            <Text size="small" color="font1" weight="400">
              {t('authenticate.registration.password.reliability_number')}
            </Text>
          </CheckList.Item>
        </CheckList>
      </div>
    </>
  )
}

export default PasswordHelper
