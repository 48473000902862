import { Pagination } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import isEmpty from 'lodash/fp/isEmpty'
import * as qs from 'neoqs'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ITEMS_PER_PAGE } from './context'
import { getTotal, isLoading } from './redux'
import useSearch from './use-search'

const BrowsingPagination = ({ className }) => {
  const { search } = useLocation()
  const { updateSearch, projects } = useSearch()
  const loading = useSelector(isLoading)
  const t = useTranslation()
  const { page = '1' } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })
  const filteredTotalProjects = useSelector(getTotal)
  const totalPages = Math.ceil(filteredTotalProjects / ITEMS_PER_PAGE)
  if (!loading && isEmpty(projects)) return null
  return (
    <Pagination
      onPageClick={(pageNumber, e) => {
        e.preventDefault()
        e.stopPropagation()
        updateSearch({ page: `${pageNumber}` })
      }}
      aria-label={t('browsing.pagination.title')}
      prevButtonLabel={t('browsing.pagination.previous')}
      nextButtonLabel={t('browsing.pagination.next')}
      className={className}
      currentPage={Number(page)}
      totalPages={totalPages}
    />
  )
}

export default BrowsingPagination
