export const PROJECT_STATE = {
  EXTRATIME: 'extratime',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
}

export const getProjectCardState = (state) => {
  switch (state) {
    case PROJECT_STATE.EXTRATIME:
      return {
        status: 'normal',
        sticker: 'browsing.project_card.extra_time',
      }
    case PROJECT_STATE.SUCCESSFUL:
      return {
        status: 'success',
        sticker: 'browsing.project_card.state.successful',
      }
    case PROJECT_STATE.FAILED:
      return {
        status: 'danger',
        sticker: 'browsing.project_card.state.failed',
      }
    default: {
      return {
        status: 'normal',
        sticker: undefined,
      }
    }
  }
}
