import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  getCurrentUserId,
  isCurrentUserAdmin,
  sessionFetched,
} from 'kiss/session/redux'
import { useLocation, useRouteMatch } from 'react-router'
import { PROJECT_ROUTE } from 'kiss/routes/redux'
import { isCurrentUserOwner } from 'kiss/modules/project-page/page-state/selectors'
import { isOwner } from 'kiss/modules/contribute/redux'
import { domElementHelper } from '@kisskissbankbank/kitten'

const MatomoPageView = () => {
  const location = useLocation()
  const userId = useSelector(getCurrentUserId)
  const isAdmin = useSelector(isCurrentUserAdmin)
  const matchProjectFlow = useRouteMatch([PROJECT_ROUTE])
  const isOwnerForProjectPage = useSelector(isCurrentUserOwner)
  const isOwnerForContribution = useSelector(isOwner)
  const sessionIsFetched = useSelector(sessionFetched)

  useEffect(() => {
    if (!sessionIsFetched) return
    const _paq = window._paq
    const currentUrl = `${location.pathname}${location.search}`
    const isOwnerOnProjectAndContributionFlow =
      matchProjectFlow && (isOwnerForContribution || isOwnerForProjectPage)
    isAdmin && _paq.push(['setCustomDimension', '3', 'admin'])
    !isAdmin &&
      isOwnerOnProjectAndContributionFlow &&
      _paq.push(['setCustomDimension', '3', 'owner'])
    _paq.push(['setCustomUrl', currentUrl])
    userId && _paq.push(['setUserId', userId])
    _paq.push(['setSessionCookieTimeout', 1800])
    _paq.push(['setPagePerformanceTiming', 0])
    _paq.push(['trackPageView'])
    _paq.push(['enableLinkTracking'])
  }, [location.pathname, sessionIsFetched])

  return null
}

const MountMatomo = () => {
  const [isMatomoMounted, mountMatomo] = useState(false)
  useEffect(() => {
    if (window._paq) {
      mountMatomo(true)
    }
  }, [])
  useEffect(() => {
    if (isMatomoMounted) return
    const _paq = (window._paq = window._paq || [])
    ;(function () {
      const u = 'https://m.kisskissbankbank.com/'
      _paq.push(['setTrackerUrl', u + 'matomo.php'])
      _paq.push(['setSiteId', '1'])
      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0]
      g.type = 'text/javascript'
      g.async = true
      g.src = u + 'matomo.js?v=2'
      s.parentNode.insertBefore(g, s)
    })()
  }, [])
  useEffect(() => {
    const checkMatomoInterval = setInterval(() => {
      if (window._paq) {
        mountMatomo(true)
        clearInterval(checkMatomoInterval)
      }
    }, 500)
    return () => clearInterval(checkMatomoInterval)
  }, [])
  return isMatomoMounted && domElementHelper.canUseDom() ? (
    <MatomoPageView />
  ) : null
}

export default MountMatomo
