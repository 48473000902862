import React from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { getSeoSlice } from '../redux'

const Seo = () => {
  const seoSlice = useSelector(getSeoSlice)

  return (
    <Helmet
      title={seoSlice.title}
      meta={[
        {
          name: 'description',
          content: seoSlice.description,
        },
      ]}
    />
  )
}

export default Seo
