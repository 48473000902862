import { Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { isBare, isFailed } from 'kiss/utils/project/state'
import React from 'react'

const StateInfo = ({ projectState, inExtratime }) => {
  const t = useTranslation()
  if (inExtratime) {
    return (
      <Text
        className="k-u-transform-capitalize"
        size="micro"
        weight="600"
        color="primary1"
      >
        {t('browsing.project_card.extra_time')}
      </Text>
    )
  }

  if (isFailed(projectState)) {
    return (
      <Text
        className="k-u-transform-capitalize"
        size="micro"
        weight="600"
        color="error"
      >
        {t('browsing.project_card.state.failed')}
      </Text>
    )
  }
  if (isBare(projectState)) return null

  return (
    <Text
      className="k-u-transform-capitalize"
      size="micro"
      weight="600"
      color="valid"
    >
      {t('browsing.project_card.state.successful')}
    </Text>
  )
}

export default StateInfo
