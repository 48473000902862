import Cookies from 'js-cookie'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import {
  ADD_VARIATION,
  getProjectSlug,
  NAME,
  REMOVE_VARIATION,
  UPDATE_CART,
  UPDATE_CONTRIBUTION,
  UPDATE_DONATION,
  UPDATE_TIP,
} from 'kiss/modules/contribute/redux'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import some from 'lodash/fp/some'
import { createSelector } from '@reduxjs/toolkit'
import {
  addVariationByApi,
  removeVariationByApi,
  updateContributionByApi,
  updateDonationByApi,
  updateTipByApi,
} from './api-actions'

export const updateCart = (cart) => (dispatch) =>
  dispatch({ type: UPDATE_CART, payload: cart })

export const addVariation =
  ({ variationId, projectId, deliveryModeCode, deliveryZoneCode }) =>
  (dispatch, getState) => {
    const state = getState()

    return addVariationByApi(
      { variationId, projectId, deliveryModeCode, deliveryZoneCode },
      state,
    ).then(
      (response) => dispatch({ type: ADD_VARIATION, payload: response }),
      (errors) => {
        dispatch(
          addErrorAlert('Error: something went wrong!', { scroll: true }),
        )

        return Promise.reject(errors)
      },
    )
  }

export const removeVariation =
  ({ variationId }) =>
  (dispatch, getState) => {
    const state = getState()
    const projectSlug = getProjectSlug(state)

    return removeVariationByApi(
      { variationUuid: variationId, projectSlug },
      state,
    ).then(
      (response) => dispatch({ type: REMOVE_VARIATION, payload: response }),
      (_error) => {
        dispatch(addErrorAlert('Error: something went wrong!'))
      },
    )
  }

export const updateDonation =
  ({ donationCents, recurring, projectSlug }) =>
  (dispatch, getState) => {
    const state = getState()
    return updateDonationByApi(
      { donationCents, recurring, projectSlug },
      state,
    ).then(
      (response) => dispatch({ type: UPDATE_DONATION, payload: response }),
      (errors) => {
        dispatch(addErrorAlert('Error: something went wrong!'))

        return Promise.reject(errors)
      },
    )
  }

export const removeDonation = () => (dispatch, getState) => {
  const state = getState()
  const projectSlug = getProjectSlug(state)

  return dispatch(
    updateDonation({
      donationCents: 0,
      projectSlug,
    }),
  )
}

export const getCookie = (name) => Cookies.get(name)
export const removeCookie = (name) => Cookies.remove(name)

export const updateContribution =
  ({ hideContributor, hideAmount }) =>
  (dispatch, getState) => {
    const state = getState()
    const id = getContributionId(state)

    return updateContributionByApi(
      { id, contribution: { hideContributor, hideAmount } },
      state,
    ).then(
      (response) => {
        return dispatch({
          type: UPDATE_CONTRIBUTION,
          payload: {
            contribution: response,
          },
        })
      },
      (_errors) => {
        dispatch(addErrorAlert('Error: something went wrong!'))
      },
    )
  }

export const updateTip = (cents) => (dispatch, getState) => {
  const state = getState()
  const projectSlug = getProjectSlug(state)

  return updateTipByApi({ cents, projectSlug }, state).then(
    (response) => {
      dispatch({ type: UPDATE_TIP, payload: response })
    },
    (_errors) => {
      dispatch(addErrorAlert('Error: something went wrong!'))
    },
  )
}

export const getCampaignEndAt = (state) =>
  get(`${NAME}.project.campaignEndAt`)(state)

const getContributionId = (state) =>
  state?.[NAME]?.project?.cart?.contribution?.id || false

export const getTipAmountCents = (state) =>
  state?.[NAME]?.project?.cart?.tip?.cents || 0

export const getDeliveryAmountCents = (state) =>
  getOr(0)(`${NAME}.project.cart.deliveryAmount.cents`)(state)
export const getRedeemedAmountCents = (state) =>
  getOr(0)(`${NAME}.project.cart.redeemedAmount.cents`)(state)

const getCartGiftCardCode = (state) =>
  getOr(null)(`${NAME}.project.cart.giftCard.code`)(state)

export const getShippingCountryCode = (state) =>
  state?.[NAME]?.project?.cart?.shippingAddress?.countryCode

export const hasGiftCardCode = createSelector(
  getCartGiftCardCode,
  (code) => !!code,
)

export const getCartId = (state) => getOr('')(`${NAME}.project.cart.id`)(state)
export const getCart = (state) => getOr('')(`${NAME}.project.cart`)(state)
export const getServiceFeeAmount = (state) =>
  getOr(null)(`${NAME}.project.cart.serviceFeeAmount.cents`)(state)
export const isBillingAddressRequired = (state) =>
  getOr(false)(`${NAME}.project.cart.displayBillingAddress`)(state)
export const getBillingAddressId = (state) =>
  getOr(undefined)(`${NAME}.project.cart.billingAddress.id`)(state)

export const getOrderTotal = (state) => {
  const cents = state[NAME]?.project?.cart?.orderTotal?.cents
  return (cents ?? 0) / 100
}

export const getTotalAmount = (state) => {
  const cents = state[NAME]?.project?.cart?.total?.cents
  return (cents ?? 0) / 100
}

export const isPostalDeliveryRequired = (state) =>
  state?.[NAME]?.project?.cart?.requiresPostalDelivery || false

export const getTotalCurrency = (state) =>
  state?.[NAME]?.project?.cart?.total?.currency || 'eur'

export const getTipAmount = (state) => {
  const cents = state[NAME]?.project?.cart?.tip?.cents
  return cents != null ? cents / 100 : undefined
}

export const getTipCurrency = (state) =>
  state?.[NAME]?.project?.cart?.tip?.currency || 'eur'

export const hasDonation = (state) => {
  const cents = state?.[NAME]?.project?.cart?.donation?.cents || 0
  return cents > 0
}

export const getDonationAmount = (state) => {
  const cents = state?.[NAME]?.project?.cart?.donation?.cents || 0
  return cents / 100
}

export const getDonationCurrency = (state) =>
  state?.[NAME]?.project?.cart?.donation?.currency || 'eur'

export const getDeliveryCountriesAmounts = (state) =>
  state?.[NAME]?.project?.cart?.deliveryCountriesAmounts || []

export const isFiscalReceiptsEnabled = (state) => {
  return state[NAME]?.project?.fiscalReceiptsEnabled
}

export const hasDeliveryZones = createSelector(
  [getDeliveryCountriesAmounts],
  (deliveryCountriesAmounts) => !isEmpty(deliveryCountriesAmounts),
)
export const isStartOnNextPeriod = (state) =>
  getOr(false)(`${NAME}.project.cart.startOnNextPeriod`)(state)
export const getOrderLines = (state) =>
  getOr([])(`${NAME}.project.cart.lines`)(state)
export const hasOrderLines = createSelector(
  [getOrderLines],
  (orderLines) => !isEmpty(orderLines),
)
export const getHideContributor = (state) =>
  state?.[NAME]?.project?.cart?.contribution?.hideContributor || false

const hasPostalDeliveries = createSelector([getOrderLines], (lines) =>
  some({ deliveryModeCode: 'postal' })(lines),
)
export const isSpecificShippingForDonationReceipts = createSelector(
  [isFiscalReceiptsEnabled, hasPostalDeliveries],
  (fiscalReceiptsEnabled, postalDeliveries) =>
    fiscalReceiptsEnabled && !postalDeliveries,
)
const getOwnerName = (state) =>
  getOr(null)(`${NAME}.project.owner.username`)(state)

const getOrganizationName = (state) =>
  getOr(null)(`${NAME}.project.organization.name`)(state)

export const getOrganizationOrOwnerName = createSelector(
  [getOwnerName, getOrganizationName],
  (ownerName, organizationName) => organizationName || ownerName,
)

export const getHideAmount = (state) =>
  state?.[NAME]?.project?.cart?.contribution?.hideAmount || false

export const isIdentityCheckRequired = (state) =>
  getOr(false)(`${NAME}.project.cart.userNeedsIdentification`)(state)
