import creditCardType, { types } from 'credit-card-type'
import size from 'lodash/fp/size'

creditCardType.addCard({
  niceType: 'Bancontact',
  type: 'bcmc',
  patterns: [6703],
  gaps: [4, 8, 12],
  lengths: [16],
  code: null,
})

export const getType = (number) => {
  const type = creditCardType(number)
  if (size(type) !== 1) return {}
  const card = type[0]
  return {
    type: card.type,
    gaps: card.gaps,
  }
}

export const withCvv = (number) => {
  const { type } = getType(number)
  return type !== CardType.BANCONTACT
}

export const CardType = { ...types, BANCONTACT: 'bcmc' }
