import { GifVideo, parseHtml, PlayerIconNext } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { ResponsiveImage } from 'kiss/components/images/responsive-image'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getProjectImageCropped,
  getProjectImageMp4,
  getProjectImageUrl,
  getProjectImageWebm,
  getProjectName,
  getVideoTag,
} from 'kiss/modules/project-page/page-state/selectors'
import { isGif } from 'kiss/utils/validators'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ProjectState from './project-state'

export const mediaRatio = 10 / 16

const StyledProjectMedia = styled.div`
  position: relative;

  @supports (aspect-ratio: 16 / 10) {
    aspect-ratio: 16 / 10;
  }

  @supports not (aspect-ratio: 16 / 10) {
    height: 0;
    padding-bottom: ${`${mediaRatio * 100}%`};
  }

  .kiss-Intro-ProjectMedia__projectImage {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    object-fit: contain;
  }

  .kiss-Intro-ProjectMedia__playerWrapper {
    background-color: transparent;
    transition: background-color 0.3s ease;

    &,
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    .kiss-Intro-ProjectMedia__playerButton {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.kiss-Intro-ProjectMedia--videoIsPlaying
    .kiss-Intro-ProjectMedia__playerWrapper {
    background-color: var(--color-grey-000);
  }
`

const ProjectImage = (props) => {
  const t = useTranslation()
  const projectName = useSelector(getProjectName)
  const projectImageUrl = useSelector(getProjectImageUrl)
  const projectImageCropped = useSelector(getProjectImageCropped)
  const projectImageMp4 = useSelector(getProjectImageMp4)
  const projectImageWebm = useSelector(getProjectImageWebm)

  if (projectImageMp4 || projectImageWebm) {
    return (
      <GifVideo {...props} poster={projectImageUrl} loading="lazy">
        {projectImageMp4 && <source type="video/mp4" src={projectImageMp4} />}
        {projectImageWebm && (
          <source type="video/webm" src={projectImageWebm} />
        )}
      </GifVideo>
    )
  }

  if (!isGif(projectImageCropped)) {
    return (
      <ResponsiveImage
        {...props}
        src={projectImageCropped}
        alt={t('project_hero.project.image_alt', {
          name: projectName,
        })}
        fromXxs={380}
        fromM={800}
        fromL={670}
        ratio={mediaRatio}
      />
    )
  }

  return (
    <img
      {...props}
      src={projectImageUrl}
      alt={t('project_hero.project.image_alt', {
        name: projectName,
      })}
    />
  )
}

const ProjectMedia = ({ className, ...props }) => {
  const t = useTranslation()
  const projectVideo = useSelector(getVideoTag)

  const [isPlayerShown, setPlayerVisibility] = useState(false)

  const isVideoPlaying = projectVideo && isPlayerShown

  return (
    <StyledProjectMedia
      className={classNames('kiss-Intro-ProjectMedia', className, {
        'kiss-Intro-ProjectMedia--videoIsPlaying': isVideoPlaying,
      })}
      {...props}
    >
      <ProjectImage className="kiss-Intro-ProjectMedia__projectImage" />

      <ProjectState />

      {projectVideo && (
        <div className="kiss-Intro-ProjectMedia__playerWrapper">
          {isVideoPlaying ? (
            <>{parseHtml(projectVideo, { sanitize: false })}</>
          ) : (
            <button
              className="k-u-reset-button kiss-Intro-ProjectMedia__playerButton"
              onClick={() => setPlayerVisibility(true)}
              onKeyPress={() => setPlayerVisibility(true)}
              type="button"
              aria-label="Play video"
            >
              <PlayerIconNext />
              <span className="k-u-a11y-visuallyHidden">
                {t('project_media.play_button.label')}
              </span>
            </button>
          )}
        </div>
      )}
    </StyledProjectMedia>
  )
}

export default ProjectMedia
