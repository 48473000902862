import React from 'react'
import PropTypes from 'prop-types'
import Shipping from '../shipping'
import SubscriptionHelper from '../subscription-helper'
import CommonFormContent from 'kiss/modules/components/reward-modal/mobile/content-with-form'
import SubscriptionStarting from '../subscription-starting'
import { useField } from 'formik'

const ContentWithForm = ({ reward, ...props }) => {
  const [nextPeriodField] = useField('startOnNextPeriod')

  const startDateText =
    nextPeriodField?.value === 'true'
      ? reward.nextPeriodLabel
      : reward.subscribablePeriodLabel

  return (
    <CommonFormContent reward={reward} {...props}>
      <SubscriptionStarting reward={reward} />
      <Shipping reward={reward} />
      <SubscriptionHelper
        period={reward.period}
        startDateText={startDateText}
      />
    </CommonFormContent>
  )
}

ContentWithForm.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default ContentWithForm
