import { Button } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { useMediaQuery } from 'kiss/media-queries/redux'
import React from 'react'
import { REWARDS_ID } from '../constants/id'
import { scrollTo } from '../helpers/scroll-to'

export const ContributeButton = ({ className }) => {
  const { viewportIsMOrLess } = useMediaQuery()

  return (
    <Button
      as="a"
      href={`#${REWARDS_ID}`}
      fit="fluid"
      modifier="helium"
      className={classNames('k-u-margin-top-triple', className)}
      onClick={(e) => {
        e.preventDefault()
        scrollTo(REWARDS_ID, viewportIsMOrLess)
      }}
    >
      S’abonner
    </Button>
  )
}
