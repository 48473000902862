import React from 'react'

import { BROWSING_POPULAR_AND_STARTED, getRouteFor } from 'kiss/routes/redux'
import { isLoading } from './redux'
import { ITEMS_PER_PAGE } from './context'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import useBrowsing from './hooks/use-browsing'

import {
  FOUR_IN_CONTAINER,
  FOUR_IN_XL_CONTAINER,
  ONE_IN_XXS_CONTAINER,
  TWO_IN_CONTAINER,
} from 'kiss/utils/responsive-image/image-width-definitions'

import {
  Button,
  ProjectCard as LoadingProjectCard,
  pxToRem,
  ScreenConfig,
  Text,
} from '@kisskissbankbank/kitten'

import ProjectCard from 'kiss/components/cards/project-card'

import classNames from 'classnames'
import styled from 'styled-components'

const StyledGrid = styled.div`
  display: grid;
  grid-gap: ${pxToRem(20)};
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    grid-gap: ${pxToRem(20)};
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    grid-gap: ${pxToRem(30)};
    grid-template-columns: repeat(4, 1fr);
  }

  &.kiss-BrowsingGrid--loading {
    filter: grayscale(1) opacity(0.4);
    transition: filter 0.4s;
  }
`

const responsiveImageOptions = {
  imageSizes: [230, 280, 340, 380],
  breakpoints: [0, ScreenConfig.S.min, ScreenConfig.L.min, ScreenConfig.XL.min],
  imageSizesInViewport: [
    ONE_IN_XXS_CONTAINER,
    TWO_IN_CONTAINER,
    FOUR_IN_CONTAINER,
    FOUR_IN_XL_CONTAINER,
  ],
}

const Projects = ({ className }) => {
  const t = useTranslation()

  const { projects } = useBrowsing()

  const loading = useSelector(isLoading)

  const routeFor = useSelector(getRouteFor)

  if (!loading && projects.length === 0) {
    return (
      <div className="k-u-align-center k-u-margin-vertical-quintuple">
        <Text tag="div" className="k-u-margin-bottom-double">
          {t('browsing.no_result.text')}
        </Text>

        <Button
          modifier="helium"
          as="a"
          href={routeFor(BROWSING_POPULAR_AND_STARTED)}
        >
          {t('browsing.no_result.button')}
        </Button>
      </div>
    )
  }

  return (
    <StyledGrid
      className={classNames('kiss-BrowsingGrid', className, {
        'kiss-BrowsingGrid--loading': loading,
      })}
    >
      {loading &&
        Array.from(Array(ITEMS_PER_PAGE), (_, index) => {
          return (
            <LoadingProjectCard
              key={`loading-card-${index}`}
              loading
              aria-hidden
            >
              <LoadingProjectCard.Title />
              <LoadingProjectCard.Line />
              <LoadingProjectCard.ItemsLine>
                <LoadingProjectCard.Item />
                <LoadingProjectCard.Item />
                <LoadingProjectCard.Item />
              </LoadingProjectCard.ItemsLine>
              <LoadingProjectCard.Progress />
            </LoadingProjectCard>
          )
        })}
      {!loading &&
        projects.length > 0 &&
        projects.map((project) => {
          return (
            <ProjectCard
              key={project?.slug}
              project={project}
              responsiveImageOptions={responsiveImageOptions}
              forceDesktop
            />
          )
        })}
    </StyledGrid>
  )
}

export default Projects
