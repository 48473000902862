import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { Container, Grid, pxToRem } from '@kisskissbankbank/kitten'
import Comment from '../../thankyou/components/comment'
import Header from '../../thankyou/components/header'
import Social from '../../thankyou/components/social'
import { useTranslation } from 'kiss/hooks/use-translation'
import SimilarProjects from '../../thankyou/components/similar-projects'

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(64)};
`

const ThankYou = () => {
  const t = useTranslation()

  return (
    <FlexContainer>
      <Helmet title={t('contribute.thank_you.head.title')} />
      <Container>
        <Grid>
          <Header
            title={t('contribute.thank_you.header.title', { parseHtml: true })}
          />
          <Social withPadding />
          <Comment />
        </Grid>
      </Container>
      <SimilarProjects />
    </FlexContainer>
  )
}

export default ThankYou
