import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Variations from '../variations'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Title } from '@kisskissbankbank/kitten'
import { isOptionsEnabled } from 'kiss/modules/project-page/page-state/selectors'
import NewVariations from '../new-variations'

const ContentWithForm = ({ reward, isPermanent, children, ...props }) => {
  const t = useTranslation()
  const isUsingNewOptions = useSelector(isOptionsEnabled)
  return (
    <div {...props}>
      <Title tag="h3" modifier="senary" className="k-u-margin-bottom-triple">
        {t('project.modal.options.title')}
      </Title>

      {reward.hasVariationSelection &&
        (isUsingNewOptions ? (
          <NewVariations
            variations={reward.variations}
            variationsOptions={reward.variationsOptions}
          />
        ) : (
          <Variations
            variations={reward.variations}
            variationsOptions={reward.variationsOptions}
          />
        ))}
      {children}
    </div>
  )
}

ContentWithForm.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default ContentWithForm
