import React from 'react'
import PropTypes from 'prop-types'
import Shipping from '../shipping'
import SubscriptionHelper from '../subscription-helper'
import CommonFormContent from 'kiss/modules/components/reward-modal/desktop/form-content'
import SubscriptionStarting from '../subscription-starting'
import { useField } from 'formik'

const FormContent = ({ reward, children }) => {
  const [nextPeriodField] = useField('startOnNextPeriod')

  const startDateText =
    nextPeriodField?.value === 'true'
      ? reward.nextPeriodLabel
      : reward.subscribablePeriodLabel

  return (
    <CommonFormContent reward={reward}>
      <SubscriptionStarting reward={reward} />
      <Shipping reward={reward} />
      <SubscriptionHelper
        period={reward.period}
        startDateText={startDateText}
      />
      {children}
    </CommonFormContent>
  )
}

FormContent.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default FormContent
