import React from 'react'
import { useSelector } from 'react-redux'
import { getComments, getCommentsTotalCount } from '../redux'
import Comment from './comment'
import { pxToRem, mq } from '@kisskissbankbank/kitten'
import styled from 'styled-components'

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(5)};

  @media ${mq.tabletAndDesktop} {
    gap: ${pxToRem(20)};
  }
`

const List = () => {
  const comments = useSelector(getComments)
  const commentsTotalCount = useSelector(getCommentsTotalCount)

  if (commentsTotalCount === 0) return null

  return (
    <StyledList>
      {comments.map((props) => (
        <Comment key={`${props.uuid}-${props.text}`} {...props} />
      ))}
    </StyledList>
  )
}

export default List
