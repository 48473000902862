import React, { useContext, useState } from 'react'

import { gql } from '@apollo/client'

import { getLocale } from 'kiss/app/redux'
import { getRouteFor, HELP, USER_MESSAGE_NEW } from 'kiss/routes/redux'
import { useSelector } from 'react-redux'

import { endOfMonth } from 'date-fns'
import addDays from 'date-fns/addDays'
import isAfter from 'date-fns/isAfter'

import { useMutation } from 'kiss/api/graphql-query'
import { AlertsContext } from 'kiss/app/alerts/context'
import { useTranslation } from 'kiss/hooks/use-translation'
import { isFinished } from 'kiss/utils/project/state'

import { AlertBox, Text } from '@kisskissbankbank/kitten'
import Dialog from 'kiss/app/modal/dialog'

const CANCEL_ORDER_MUTATION = gql`
  mutation CancelOrder($orderId: ID!) {
    cancelOrder(id: $orderId) {
      id
      orderStatus
      cancellationDate
    }
  }
`

const CancelOrder = ({ order, project, closeModal }) => {
  const routeFor = useSelector(getRouteFor)
  const IsCampaignAboutToEndIn48Hours = isAfter(
    addDays(Date.now(), 2),
    new Date(project.campaignEndAt),
  )

  const isMonthEndingInLessThan48Hours = () => {
    const now = Date.now()
    const endOfCurrentMonth = endOfMonth(new Date(now))
    const in48Hours = addDays(now, 2)

    return isAfter(in48Hours, endOfCurrentMonth)
  }

  const t = useTranslation()
  const { addErrorAlert, addSuccessAlert, removeErrorAlerts } =
    useContext(AlertsContext)
  const [isLoading, loading] = useState(false)
  const [cancelOrder] = useMutation(CANCEL_ORDER_MUTATION)

  if (order?.disbursed) {
    return (
      <AlertBox
        fit="content"
        iconPosition="center"
        className="k-u-margin-bottom-triple k-u-align-left"
      >
        {t('contributor.orders.cancel.payout_part1')}{' '}
        <Text
          tag="a"
          className="k-u-link k-u-link-primary1 k-u-reset-button"
          href={routeFor(USER_MESSAGE_NEW, {
            user: project?.organization?.contact?.slug || project?.owner?.slug,
          })}
        >
          {project?.organization?.name || project?.owner?.username}
        </Text>
        {t('contributor.orders.cancel.payout_part2')}
      </AlertBox>
    )
  }

  if (order.orderStatus !== 'confirmed' || isFinished(project.state)) {
    return null
  }

  if (!project.isPermanent && IsCampaignAboutToEndIn48Hours) {
    return (
      <AlertBox
        status="warning"
        iconPosition="center"
        className="k-u-margin-bottom-triple k-u-align-left"
      >
        {t('contributor.orders.cancel.warning_48h', { parseHtml: true })}
      </AlertBox>
    )
  }
  if (project.isPermanent && isMonthEndingInLessThan48Hours()) {
    return (
      <AlertBox
        status="warning"
        iconPosition="center"
        className="k-u-margin-bottom-triple k-u-align-left"
      >
        {t('contributor.orders.cancel.warning_48h_before_endOfMonth', {
          parseHtml: true,
        })}
      </AlertBox>
    )
  }

  const handleCancelOrder = async (close) => {
    try {
      removeErrorAlerts()
      loading(true)
      await cancelOrder({ variables: { orderId: order.id } })

      addSuccessAlert(
        t('contributor.orders.cancel.success', {
          projectName: project.name,
        }),
        { scroll: true },
      )
    } catch (e) {
      addErrorAlert(t('app.server.error'), { scroll: true })
    } finally {
      loading(false)
      closeModal()
      close()
    }
  }

  return (
    <Dialog
      title={t('contributor.orders.cancel.title')}
      primaryActionLoading={isLoading}
      primaryActionModifier="iron"
      primaryAction={(close) => handleCancelOrder(close)}
      primaryActionText={t('contributor.orders.cancel.confirm')}
      secondaryAction={(close) => close()}
      secondaryActionText={t('contributor.orders.cancel.cancel')}
      content={
        <Text
          size="small"
          className="k-u-reset-button k-u-cursor-pointer k-u-margin-bottom-triple"
        >
          {t('contributor.orders.cancel.text')}
        </Text>
      }
    >
      <Text
        color="error"
        size="small"
        weight="500"
        tag="button"
        type="button"
        className="k-u-reset-button k-u-cursor-pointer k-u-margin-bottom-triple"
      >
        {t('contributor.orders.cancel.button')}
      </Text>
    </Dialog>
  )
}

const Bottom = ({ order, project, closeModal }) => {
  const t = useTranslation()
  const locale = useSelector(getLocale)
  const routeFor = useSelector(getRouteFor)

  return (
    <div className="k-u-align-center">
      <CancelOrder order={order} project={project} closeModal={closeModal} />
      <Text
        tag="p"
        weight="500"
        size="small"
        className="k-u-margin-bottom-single"
      >
        {t('contributor.orders.project.bottom.label')}
      </Text>
      <Text tag="p" weight="400" size="small">
        {t('contributor.orders.project.bottom.body', {
          link: routeFor(HELP, { locale }),
          parseHtml: true,
        })}
      </Text>
    </div>
  )
}

export default Bottom
