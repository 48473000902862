import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  DocumentsDropUploader,
  DocumentsStatusBox,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'

const DOCUMENT_STATUS_FRONT = {
  READY: 'ready',
  ERROR: 'error',
  WAIT: 'wait',
  ACCEPTED: 'accepted',
  DENIED: 'denied',
}

const KycUploader = ({ document, onChange, onRetry }) => {
  const t = useTranslation()
  const {
    status,
    title,
    documentInfo,
    description,
    filesName,
    rejectionReason,
  } = document
  const [isMount, setIsMount] = useState(false)
  const [uploaderVisible, setUploaderVisible] = useState(
    status === DOCUMENT_STATUS_FRONT.READY ||
      status === DOCUMENT_STATUS_FRONT.ERROR,
  )

  useEffect(() => {
    setIsMount(true)
  }, [])

  useEffect(() => {
    if (status) {
      if (
        (status === DOCUMENT_STATUS_FRONT.READY ||
          status === DOCUMENT_STATUS_FRONT.ERROR ||
          (status === DOCUMENT_STATUS_FRONT.WAIT && filesName?.length === 0)) &&
        !uploaderVisible
      ) {
        return setUploaderVisible(true)
      }
      if (
        ((status === DOCUMENT_STATUS_FRONT.WAIT && filesName?.length > 0) ||
          status === DOCUMENT_STATUS_FRONT.ACCEPTED ||
          status === DOCUMENT_STATUS_FRONT.DENIED) &&
        uploaderVisible
      ) {
        return setUploaderVisible(false)
      }
    }

    return
  }, [status, uploaderVisible, filesName])

  const onUpload = (files) => {
    if (isMount) {
      onChange(files)
    }
  }

  return (
    <>
      {uploaderVisible ? (
        <DocumentsDropUploader
          id="kyc-document-uploader"
          initialValue={
            filesName?.length
              ? filesName.map((file) => {
                  return { name: file }
                })
              : []
          }
          labelText={t('users.kyc.documents.identity_card.addDocument')}
          managerTitle={title}
          managerText={description}
          managerInfo={documentInfo}
          status={status}
          onChange={onUpload}
          acceptedMimeTypes={[
            'application/pdf',
            'image/jpeg',
            'imae/gif',
            'image/png',
          ]}
          typeErrorText={(filename) =>
            t('users.kyc.documents.error.wrong_type', { filename })
          }
          sizeErrorText={(filename) =>
            t('users.kyc.documents.error.too_large', { filename })
          }
        />
      ) : (
        <DocumentsStatusBox
          title={title}
          message={
            status !== DOCUMENT_STATUS_FRONT.DENIED
              ? description
              : rejectionReason
          }
          status={status}
          fileList={filesName?.length ? filesName : []}
          retryAction={onRetry}
          retryActionMessage={
            status === DOCUMENT_STATUS_FRONT.DENIED
              ? t('users.kyc.documents.upload.replace')
              : null
          }
        />
      )}
    </>
  )
}

KycUploader.propTypes = {
  document: PropTypes.any,
  onRetry: PropTypes.func,
  onChange: PropTypes.func,
}

export default KycUploader
