import {
  Badge,
  CONTAINER_MAX_WIDTH,
  CONTAINER_PADDING,
  domElementHelper,
  NavBar,
  pxToRem,
  ScreenConfig,
  ScrollableContainer,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'

import { useTranslation } from 'kiss/hooks/use-translation'
import { NAVIGATION_ID } from 'kiss/modules/project-page/page-state/redux'
import {
  getCurrentProjectRouteFor,
  getNavItems,
  getProjectState,
  getSuspendedAt,
  hasVariations as hasVariationsSelector,
  isAggressivelyCached as isAggressivelyCachedSelector,
} from 'kiss/modules/project-page/page-state/selectors'
import {
  PROJECT_NAVIGATION_ROUTE,
  PROJECT_NAVIGATION_TAB_ROUTE,
} from 'kiss/routes/redux'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import throttle from 'lodash/fp/throttle'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import ContributeButton from './actions/contribute-button'
import GoToCartButton from './actions/goto-cart-button'

const scrollElement =
  domElementHelper.canUseDom() && document.getElementById(NAVIGATION_ID)

const NavItem = ({ navItem, defaultNavTab, item }) => {
  const t = useTranslation()

  const currentProjectRouteFor = useSelector(getCurrentProjectRouteFor)
  const history = useHistory()

  const itemIsSelected = navItem.key === (item || defaultNavTab)
  const itemRoute = currentProjectRouteFor(PROJECT_NAVIGATION_TAB_ROUTE, {
    item: navItem.key,
  })

  return (
    <NavBar.ListItem
      active={itemIsSelected}
      linkProps={{
        href: itemRoute,
        onClick: (e) => {
          e.preventDefault()
          history.push(itemRoute)
          window.scrollTo({
            top: scrollElement?.offsetTop,
            behavior: 'smooth',
          })
        },
      }}
      data-test-id={`${navItem.key}-tab`}
      id={`${navItem.key}-tab`}
    >
      {t(navItem.text)}
      {navItem.badge ? <Badge spaced>{navItem.badge}</Badge> : null}
    </NavBar.ListItem>
  )
}

const Navigation = () => {
  const t = useTranslation()
  const [
    showContributeButtonOnTabletOrLess,
    setShowContributeButtonOnTabletOrLess,
  ] = useState(false)
  const navItems = useSelector(getNavItems)
  const projectState = useSelector(getProjectState)
  const hasVariations = useSelector(hasVariationsSelector)
  const suspendedAt = useSelector(getSuspendedAt)
  const isAggressivelyCached = useSelector(isAggressivelyCachedSelector)

  const {
    params: { item },
  } = useRouteMatch({ path: PROJECT_NAVIGATION_ROUTE, exact: true })

  useEffect(() => {
    window.addEventListener('scroll', throttleWindowScroll)

    return () => {
      window.removeEventListener('scroll', throttleWindowScroll)
    }
  }, [])

  const handleWindowScroll = () => {
    const buttonHeight = 70
    const navHeight = 80
    const offsetHeight =
      window.pageYOffset + window.innerHeight - navHeight - buttonHeight
    const detailsPositionTop =
      document?.getElementById(NAVIGATION_ID)?.offsetTop

    const show = offsetHeight >= detailsPositionTop

    setShowContributeButtonOnTabletOrLess(show)
  }

  const throttleWindowScroll = throttle(200)(handleWindowScroll)

  const shouldDisplayGoToCartButton = () => {
    if (isAggressivelyCached) {
      return true
    }
    return !suspendedAt && projectState === 'started' && hasVariations
  }

  const shouldDisplayContributeButton = () => {
    return projectState === 'started' && !hasVariations && !suspendedAt
  }

  return (
    <>
      <div id={NAVIGATION_ID} />

      <StyledNavigation
        className={classNames('kiss-ProjectNavigation', {
          'kiss-ProjectNavigation--hasCartButton':
            shouldDisplayGoToCartButton(),
          'kiss-ProjectNavigation--hasContributeButton':
            shouldDisplayContributeButton(),
        })}
      >
        <ScrollableContainer shadowColor="var(--color-grey-200)">
          <NavBar
            navProps={{
              'aria-label': t('project.navigation.title'),
            }}
            className="kiss-ProjectNavigation__navBar"
            colors={{
              background: 'var(--color-grey-200)',
            }}
          >
            {navItems.map((navItem) => (
              <NavItem
                key={navItem.key}
                navItem={navItem}
                defaultNavTab="description"
                item={item}
              />
            ))}
          </NavBar>
        </ScrollableContainer>

        <div className="kiss-ProjectNavigation__buttonContainer kiss-ProjectNavigation__buttonContainer__cartButton">
          <GoToCartButton borderRadius={8} fit="fluid" />
        </div>

        <div className="kiss-ProjectNavigation__buttonContainer kiss-ProjectNavigation__buttonContainer__contributeButton">
          <ContributeButton borderRadius={8} fit="fluid" />
        </div>
      </StyledNavigation>

      {shouldDisplayGoToCartButton() && (
        <StyledBottomButtonContainer>
          <GoToCartButton />
        </StyledBottomButtonContainer>
      )}

      {shouldDisplayContributeButton() &&
        showContributeButtonOnTabletOrLess && (
          <StyledBottomButtonContainer>
            <ContributeButton size="large" />
          </StyledBottomButtonContainer>
        )}
    </>
  )
}

const StyledNavigation = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  background-color: var(--color-grey-200);
  z-index: ${Z_INDEX.global.aboveHeader};

  .kiss-ProjectNavigation__buttonContainer {
    display: none;
    flex: 0 0 ${pxToRem(250)};
    width: ${pxToRem(250)};
    margin-right: ${pxToRem(CONTAINER_PADDING)};
    @media (min-width: ${pxToRem(ScreenConfig.XL.min)}) {
      position: absolute;
      top: 0;
      right: calc((100vw - ${pxToRem(CONTAINER_MAX_WIDTH)}) / 2);
      bottom: 0;
    }
  }
  &.kiss-ProjectNavigation--hasCartButton
    .kiss-ProjectNavigation__buttonContainer__cartButton,
  &.kiss-ProjectNavigation--hasContributeButton
    .kiss-ProjectNavigation__buttonContainer__contributeButton {
    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      display: flex;
      align-items: center;
    }
  }
  .kiss-ProjectNavigation__navBar a.k-NavBar__link .k-Badge {
    border: none;
  }
`

const StyledBottomButtonContainer = styled.div`
  display: none;
  @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: ${pxToRem(70)};
    z-index: ${Z_INDEX.global.aboveHeader};
    button {
      z-index: 5;
      width: 100%;
      height: 100%;
    }
  }
`

export default Navigation
