import { ScreenConfig, upcaseFirst } from '@kisskissbankbank/kitten'
import { urlBuilder } from 'kiss/utils/responsive-image-handler'
import { isGif } from 'kiss/utils/validators'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

export class ResponsiveImage extends Component {
  static propTypes = {
    fromXxs: PropTypes.number,
    fromXs: PropTypes.number,
    fromS: PropTypes.number,
    fromM: PropTypes.number,
    fromL: PropTypes.number,
    fromXl: PropTypes.number,
    forceNonResponsive: PropTypes.bool,
    pictureProps: PropTypes.object,
    //  ratio: PropTypes.number,
    upscale: PropTypes.bool,
  }

  static defaultProps = {
    fromXxs: 350,
    forceNonResponsive: false,
    pictureProps: {},
    //  ratio: 0,
    upscale: true,
  }

  isDefined = (value) => value || value === 0

  isPropWithViewportRange = (viewportRange) =>
    this.props && this.props[`from${upcaseFirst(viewportRange)}`]

  viewportRangeMediaQuery = (viewportRange) => {
    let mediaQuerySize = ScreenConfig[viewportRange.toUpperCase()].min
      ? ScreenConfig[viewportRange.toUpperCase()].min
      : 0
    return `(min-width: ${mediaQuerySize}px)`
  }

  getSrcSetForMediaQuery = (viewportRange, mimeType) => {
    const size = this.props[`from${upcaseFirst(viewportRange)}`]

    if (!this.isDefined(size)) return

    let image1x = urlBuilder(this.props.src, {
      width: size,
      // height: Math.floor(size * this.props.ratio),
      height: 0,
      filters: ['quality:90', `format:${mimeType}`],
      upscale: this.props.upscale,
    })

    if (isGif(this.props.src)) return image1x

    let image2x = urlBuilder(this.props.src, {
      width: size * 2,
      // height: Math.floor(size * 2 * this.props.ratio),
      height: 0,
      filters: ['quality:60', `format:${mimeType}`],
      upscale: this.props.upscale,
    })

    return `${image1x}, ${image2x} 2x`
  }

  viewportRangeSourcesByMimeType = (viewportRange, mimeType) => {
    const hasValue = this.isPropWithViewportRange(viewportRange)
    if (!this.isDefined(hasValue)) return

    const viewportRangeMediaQuery = this.viewportRangeMediaQuery(viewportRange)
    const viewportRangeSrcSet = this.getSrcSetForMediaQuery(
      viewportRange,
      mimeType,
    )

    if (!viewportRangeSrcSet) return

    return { viewportRangeMediaQuery, viewportRangeSrcSet, mimeType }
  }

  getDefaultSrcSet = (imageUrl) =>
    urlBuilder(imageUrl, {
      width: this.props.fromXxs,
      // height: Math.floor(this.props.fromXxs * this.props.ratio),
      height: 0,
      filters: ['quality:90'],
      upscale: this.props.upscale,
    })

  render() {
    const {
      src,
      fromXxs,
      fromXs,
      fromS,
      fromM,
      fromL,
      fromXl,
      pictureProps,
      forceNonResponsive,
      ratio,
      upscale,
      ...others
    } = this.props

    if (forceNonResponsive || !ENABLE_RESPONSIVE_IMAGES || isGif(src)) {
      return <img src={src} {...others} />
    }

    const viewportRanges = Object.keys(ScreenConfig)
      .map((size) => size.toLowerCase())
      .reverse()

    const viewportRangesSources = viewportRanges.reduce(
      (acc, viewportRange) => {
        return [
          ...acc,
          this.viewportRangeSourcesByMimeType(viewportRange, 'webp'),
          this.viewportRangeSourcesByMimeType(viewportRange, 'jpeg'),
        ]
      },
      [],
    )

    return (
      <picture {...pictureProps}>
        {viewportRangesSources.map(
          (source, index) =>
            source && (
              <source
                key={source.viewportRangeSrcSet + index}
                type={source.mimeType && `image/${source.mimeType}`}
                media={source.viewportRangeMediaQuery}
                srcSet={source.viewportRangeSrcSet}
              />
            ),
        )}
        <img src={this.getDefaultSrcSet(src)} {...others} />
      </picture>
    )
  }
}
