import { HeaderMenu } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  AUTHENTICATE_ROUTE,
  BLOG,
  BROWSING,
  FUND_MY_PROJECT,
  getRouteFor,
  GIFT_CARD,
  HELP,
} from 'kiss/routes/redux'
import { isLogged as isLoggedSelector } from 'kiss/session/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'

const BurgerMenu = () => {
  const isLogged = useSelector(isLoggedSelector)
  const routeFor = useSelector(getRouteFor)
  const t = useTranslation()
  const { pathname } = useLocation()
  const isPathMatching = (path) =>
    !!matchPath(pathname, {
      path: path,
      exact: true,
    })

  return (
    <HeaderMenu borderSide="right" noBorder>
      <HeaderMenu.Item
        href={routeFor(FUND_MY_PROJECT)}
        isSelected={isPathMatching(FUND_MY_PROJECT)}
      >
        {t('layouts.header.start_your_project')}
      </HeaderMenu.Item>

      <HeaderMenu.Item
        href={routeFor(BROWSING)}
        isSelected={isPathMatching(BROWSING)}
      >
        {t('layouts.header.explore_the_projects')}
      </HeaderMenu.Item>

      <HeaderMenu.Item
        href={routeFor(GIFT_CARD)}
        isSelected={isPathMatching(GIFT_CARD)}
        className="k-u-flex k-u-flex-alignItems-center"
      >
        {t('layouts.header.gift_card')}
      </HeaderMenu.Item>

      <HeaderMenu.Item href={routeFor(HELP)}>
        {t('layouts.header.how_it_works')}
      </HeaderMenu.Item>
      <HeaderMenu.Item href={routeFor(BLOG)}>
        {t('layouts.header.blog')}
      </HeaderMenu.Item>

      {!isLogged && (
        <HeaderMenu.Item
          href={routeFor(AUTHENTICATE_ROUTE)}
          className="k-u-background-color-primary6"
        >
          {t('layouts.header.authenticate')}
        </HeaderMenu.Item>
      )}
    </HeaderMenu>
  )
}

export default BurgerMenu
