import React from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import {
  getProjectName,
  getShortDesc,
} from 'kiss/modules/project-page/page-state/selectors'
import {
  Container,
  Grid,
  GridCol,
  HorizontalStroke,
  Paragraph,
  Title,
} from '@kisskissbankbank/kitten'

const Titles = () => {
  const projectName = useSelector(getProjectName)
  const projectShortDesc = useSelector(getShortDesc)
  const splitedshortDesc = projectShortDesc
    ? projectShortDesc?.split('\n\n\n')
    : []
  const firstLine = splitedshortDesc[0]
  const remainingLines = splitedshortDesc?.slice(1)?.join('\n\n\n')

  return (
    <Container>
      <Grid>
        <GridCol col="12" col-s="10" offset-s="1" col-l="6" offset-l="3">
          <Title
            tag="h1"
            data-test-id="project-title"
            modifier="secondary"
            className={classNames(
              'k-u-align-center',
              'k-u-margin-top-quadruple k-u-margin-top-quintuple@s-up k-u-margin-top-sextuple@l-up',
              'k-u-margin-bottom-single k-u-margin-bottom-double@s-up',
            )}
          >
            {projectName}
          </Title>

          <Paragraph
            modifier="secondary"
            tag="p"
            color="font1"
            noMargin={true}
            weight="400"
            lineHeight="normal"
            className={classNames(
              'k-u-align-center',
              'k-u-margin-top-single k-u-margin-top-double@s-min',
              !remainingLines &&
                'k-u-margin-bottom-triple k-u-margin-bottom-singleHalf@s-min',
            )}
            data-test-id="short-description"
          >
            {firstLine}
          </Paragraph>

          {remainingLines && (
            <Paragraph
              modifier="secondary"
              tag="p"
              color="font1"
              noMargin={true}
              weight="400"
              lineHeight="normal"
              className={classNames(
                'k-u-align-center',
                'k-u-margin-bottom-triple k-u-margin-bottom-singleHalf@s-min',
              )}
              data-test-id="short-description"
            >
              {remainingLines}
            </Paragraph>
          )}

          <HorizontalStroke
            size="medium"
            className={classNames(
              'k-u-hidden@xs-down',
              'k-u-margin-top-singleHalf',
              'k-u-margin-horizontal-auto',
              'k-u-margin-bottom-triple',
            )}
          />
        </GridCol>
      </Grid>
    </Container>
  )
}

export default Titles
