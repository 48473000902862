import {
  AirplaneIconNext,
  Button,
  domElementHelper,
  DropdownMenu,
  FacebookIconNext,
  LinkedinIconNext,
  LinkIconNext,
  MessengerIconNext,
  parseHtml,
  pxToRem,
  TextButton,
  TwitterIconNext,
  WhatsappIconNext,
} from '@kisskissbankbank/kitten'
import SocialClickHandler from 'kiss/components/social/social-click-handler'
import { useTranslation } from 'kiss/hooks/use-translation'
import config from 'kiss/config'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import {
  getNews,
  getProjectName,
  getProjectUrl,
} from '../../../../page-state/selectors'

const SocialItem = ({ icon, label, name, url }) => (
  <SocialClickHandler>
    {(handleClick) => {
      return (
        <DropdownMenu.Button onClick={() => handleClick(name, url)} icon={icon}>
          {label}
        </DropdownMenu.Button>
      )
    }}
  </SocialClickHandler>
)

const SharedNewsShareButton = ({ newsId }) => {
  const t = useTranslation()
  const projectUrl = useSelector(getProjectUrl)
  const sharedNewsUrl = `${projectUrl}/news/${newsId}?utm_source=kkbb&utm_campaign=news-share`
  const news = useSelector(getNews)
  const newsTitle = parseHtml(news[0].title)?.props?.children
  const projectName = useSelector(getProjectName)

  const encodedUrl = encodeURIComponent(sharedNewsUrl)
  const title = encodeURIComponent(newsTitle)
  const text = encodeURIComponent(
    t('project.news.share_msg', {
      project: projectName,
      news: newsTitle,
      parseHtml: true,
    }),
  )

  const shareProjectData = {
    title: newsTitle,
    text: t('project.news.share_msg', {
      project: projectName,
      news: newsTitle,
      parseHtml: true,
    }),
    url: sharedNewsUrl,
  }

  const onShare = () => {
    navigator
      .share(shareProjectData)
      .catch((error) => console.warn('error', error))
  }
  const onCopyLink = () => {
    return navigator.clipboard.writeText(sharedNewsUrl)
  }

  if (
    domElementHelper.canUseDom() &&
    navigator.canShare &&
    navigator.canShare(shareProjectData) &&
    isMobile
  ) {
    return (
      <Button
        rounded
        fit="content"
        as="span"
        size="small"
        onClick={() => onShare()}
      >
        <AirplaneIconNext aria-hidden />
        <span className="k-u-hidden@xs-down" aria-hidden>
          {t('project.comments.share')}
        </span>
      </Button>
    )
  }

  return (
    <DropdownMenu
      menuPosition="center"
      button={({ open }) => (
        <TextButton
          isActive={open}
          size="large"
          as="span"
          type={null}
          aria-label={t('project.comments.share')}
          className="k-u-weight-500"
        >
          <AirplaneIconNext aria-hidden />
          <span className="k-u-hidden@xs-down" aria-hidden>
            {t('project.comments.share')}
          </span>
        </TextButton>
      )}
      top={pxToRem(10)}
    >
      <DropdownMenu.Button onClick={onCopyLink} icon={<LinkIconNext />}>
        {t('permanent_project.text.button.share_copy_link')}
      </DropdownMenu.Button>
      <DropdownMenu.Separator />
      <SocialItem
        label={t('permanent_project.text.button.share_facebook')}
        name="Facebook"
        url={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
        icon={<FacebookIconNext />}
      />
      <SocialItem
        label={t('permanent_project.text.button.share_twitter')}
        name="Twitter"
        url={`https://twitter.com/intent/tweet/?url=${encodedUrl}&text=${text}&via=Kissbankers`}
        icon={<TwitterIconNext />}
      />
      {isMobile && (
        <SocialItem
          label={t('permanent_project.text.button.share_messenger')}
          name="Messenger"
          url={`fb-messenger://share?link=${encodedUrl}&app_id=${config[APP_ENV].facebook.appId}`}
          icon={<MessengerIconNext />}
        />
      )}

      <SocialItem
        label={t('permanent_project.text.button.share_whatsapp')}
        name="Whatsapp"
        url={`https://api.whatsapp.com/send?text=${text}%20${encodedUrl}`}
        icon={<WhatsappIconNext />}
      />

      <SocialItem
        label={t('permanent_project.text.button.share_linkedin')}
        name="Linkedin"
        url={`https://www.linkedin.com/shareArticle?url=${encodedUrl}&title=${title}&summary=${text}&mini=true&source=${encodedUrl}`}
        icon={<LinkedinIconNext width={13} heigh={13} />}
      />
    </DropdownMenu>
  )
}

export default SharedNewsShareButton
