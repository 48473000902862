import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCart,
  updateContribution,
  getHideAmount,
  getHideContributor,
  getOrderTotal,
  getTotalAmount,
  getTotalCurrency,
} from 'kiss/modules/contribute/cart/redux'
import Wrapper from './components/wrapper'
import { Button } from '@kisskissbankbank/kitten'
import { useHistory } from 'react-router'
import useContributeStepper from '../../hooks/use-stepper'
import { getRouteFor as getRouteForSelector } from 'kiss/routes/redux'
import { getProjectSlug } from '../../redux'
import { Form, Formik } from 'formik'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { FacebookPixelHelper } from 'kiss/utils/tracking/facebook-pixel/helper'

const CartSummary = ({ children, onSubmit, setIsSubmitting }) => {
  const t = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const orderTotal = useSelector(getOrderTotal)
  const hideContributor = useSelector(getHideContributor)
  const hideAmount = useSelector(getHideAmount)
  const { nextRoute } = useContributeStepper()
  const getRouteFor = useSelector(getRouteForSelector)
  const projectSlug = useSelector(getProjectSlug)
  const cart = useSelector(getCart)
  const totalAmount = useSelector(getTotalAmount)
  const totalCurrency = useSelector(getTotalCurrency)

  return (
    <Formik
      initialValues={{ hideContributor, hideAmount }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        onSubmit()

        const trackedData = {
          event: 'begin_checkout',
          ecommerce: {
            value: totalAmount,
            currency: totalCurrency,
            items: cart?.lines.map((line) => {
              return {
                item_id: line.variation?.id,
                item_name: line.variation?.label,
                item_category: '',
                price: line.totalAmount?.cents / (line.quantity * 100),
                quantity: line.quantity,
              }
            }),
          },
        }
        FacebookPixelHelper.pushToGtm(window.dataLayer, trackedData)

        return dispatch(updateContribution(values, t)).then((event) => {
          setSubmitting(false)
          setIsSubmitting(false)

          if (event.type !== 'ALERTS/ADD_ALERT') {
            history.push(getRouteFor(nextRoute, { project: projectSlug }))
          }
        })
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Wrapper>{children}</Wrapper>
            <Button
              modifier="helium"
              fit="fluid"
              disabled={orderTotal === 0}
              data-test-id="cart-submit-button"
              type={!isSubmitting ? 'submit' : 'button'}
              className="k-u-margin-top-triple@xs-down k-u-hidden@s-up"
            >
              {isSubmitting ? (
                <LoadingAnimation color="var(--color-primary-500)" />
              ) : (
                t('cart.contribute.summary.button.total')
              )}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
export default CartSummary
