import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { gql } from '@apollo/client'
import { useTranslation } from 'kiss/hooks/use-translation'

import styled from 'styled-components'

import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import {
  LoaderWithParagraph,
  TitleWithStroke,
  pxToRem,
} from '@kisskissbankbank/kitten'

import { useQuery } from 'kiss/api/graphql-query'
import { CARD_PAYMENT_FAILURE, getRouteFor, THANK_YOU } from 'kiss/routes/redux'

import { getCartId } from 'kiss/modules/contribute/cart/redux'
import { getProjectSlug, updatePolling } from 'kiss/modules/contribute/redux'

const GET_ORDER = gql`
  query OrderQuery($id: ID!) {
    order(id: $id) {
      id
      orderStatus
      payment {
        id
        state
        errorCode
        errorMessage
      }
    }
  }
`
const LoadingScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-grey-000);
  z-index: 999;
`

export const LONG_PROCESSING = 'LONG_PROCESSING'
const WAITING_LIMIT = 15000

const Polling = () => {
  const dispatch = useDispatch()

  const history = useHistory()

  const t = useTranslation()

  const projectSlug = useSelector(getProjectSlug)
  const routeFor = useSelector(getRouteFor)
  const orderId = useSelector(getCartId)

  const params = useParams()

  const { data, startPolling, stopPolling } = useQuery(
    GET_ORDER,
    {
      id: params.orderId || orderId,
    },
    { fetchPolicy: 'network-only' },
  )

  useEffect(() => {
    return () => dispatch(updatePolling(false))
  }, [])

  useEffect(() => {
    if (data?.order?.orderStatus === 'confirmed') {
      return history.push(
        `${routeFor(THANK_YOU, { project: projectSlug })}?uuid=${orderId}`,
      )
    } else {
      if (data?.order?.payment?.state === 'pending') {
        startPolling(3000)
      } else if (data?.order?.payment?.state === 'failed') {
        stopPolling()
        return history.push(
          `${routeFor(CARD_PAYMENT_FAILURE, {
            project: projectSlug,
          })}?errorCode=${data.order.payment.errorCode}`,
        )
      }
    }
  }, [data?.order])

  useEffect(() => {
    const timeout = setTimeout(() => {
      stopPolling()
      return history.push(
        `${routeFor(CARD_PAYMENT_FAILURE, {
          project: projectSlug,
        })}?errorCode=${LONG_PROCESSING}`,
      )
    }, WAITING_LIMIT)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <LoadingScreenContainer>
      <LoaderWithParagraph
        loaderPosition="top"
        data-test-id="payment-success-polling-loader"
        loaderComponent={() => (
          <LoadingAnimation
            data-test-id="payment-success-polling-loader"
            width={pxToRem(100)}
            height={pxToRem(75)}
          />
        )}
      >
        <TitleWithStroke
          align="center"
          family="antiqueolive"
          cssColor="var(--color-primary-500)"
          modifier="primary"
        >
          <p className="k-u-margin-top-sextupleHalf">
            {t('contribute.polling.loader_text')}
          </p>
        </TitleWithStroke>
      </LoaderWithParagraph>
    </LoadingScreenContainer>
  )
}

export default Polling
