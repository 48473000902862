import { Modal } from '@kisskissbankbank/kitten'
import PropTypes from 'prop-types'
import React from 'react'
import Bottom from './bottom'
import Delivery from './delivery'
import DownloadFiscal from './download-fiscal'
import MeansOfPayment from './means-of-payment'
import Payment from './payment'
import Rewards from './rewards'

const OrderModal = ({ order, trigger }) => {
  const project = order.contribution.project

  return (
    <Modal size="large" trigger={trigger}>
      {({ close }) => {
        return (
          <Modal.Content align="left">
            <Rewards order={order} />
            <Payment order={order} />
            <MeansOfPayment orderId={order.id} />
            <Delivery
              order={order}
              owner={project.organization?.contact?.slug || project.owner?.slug}
            />
            <DownloadFiscal order={order} />
            <Bottom order={order} project={project} closeModal={close} />
          </Modal.Content>
        )
      }}
    </Modal>
  )
}

OrderModal.propTypes = {
  order: PropTypes.object.isRequired,
}

export default OrderModal
