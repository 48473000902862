import React from 'react'

import { getAssetFor } from 'kiss/assets/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import { Paragraph, Title } from '@kisskissbankbank/kitten'

import { SocialButton } from './social-button'

import styled from 'styled-components'
import { HOMEPAGE, getRouteFor } from 'kiss/routes/redux'

const StyledFooterBaseline = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  background: var(--color-grey-800);

  @media (min-width: 1079px) {
    padding: 40px;
  }
`

const StyledFooterBaselineFirstBlock = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledImage = styled.img`
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  display: flex;
  max-width: 224px;
  width: 55vw;
`

const StyledBaselineText = styled.div`
  align-self: center;
  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px 20px;
  }

  @media (min-width: 1079px) {
    h2 {
      font-size: 20px;
      font-weight: 700;
      margin: 0px;
    }
  }
`

export const Baseline = (props) => {
  const t = useTranslation()

  const assetFor = useSelector(getAssetFor)
  const routeFor = useSelector(getRouteFor)

  return (
    <StyledFooterBaseline {...props}>
      <StyledFooterBaselineFirstBlock>
        <div>
          <a href={routeFor(HOMEPAGE)}>
            <StyledImage
              src={assetFor('logos/Kkbb_Pacman.svg')}
              alt={'logos/Kkbb_Pacman.svg'}
            />
          </a>
        </div>

        <StyledBaselineText>
          <Title noMargin modifier="septenary" cssColor="var(--color-grey-000)">
            {t('layouts.footer.baseline.title', { parseHtml: true })}
          </Title>
          <Paragraph
            modifier="secondary"
            noMargin
            className="k-u-color-grey-000"
          >
            {t('layouts.footer.baseline.subtitle', { parseHtml: true })}
          </Paragraph>
        </StyledBaselineText>

        <SocialButton />
      </StyledFooterBaselineFirstBlock>
    </StyledFooterBaseline>
  )
}
