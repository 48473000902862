import useChangeCreditCard from './calls/change-credit-card'
import usePaylineTokenization from './calls/payline-tokenization'
import useCallRegisterSubscriptionCard from './calls/register-subscription-card'
import useRestartSubscription from './calls/restart-subscription'
import { getPrefix } from './config/card-type'

const useChangeCreditCardPayment = ({
  subscriptionId,
  userId,
  subscriptionState,
}) => {
  const { callRegisterSubscriptionCard } = useCallRegisterSubscriptionCard()
  const { callPaylineTokenization } = usePaylineTokenization()
  const { changeCreditCard } = useChangeCreditCard()
  const { restartSubscription } = useRestartSubscription()

  return {
    changeCreditCardPayment: async ({ cardNumber, cvv, expiry }) => {
      const cardType = getPrefix(cardNumber)
      const cardRegistration = await callRegisterSubscriptionCard({
        cardType,
        subscriptionId,
        userId,
      })
      const registrationData = await callPaylineTokenization({
        cardNumber,
        cvv,
        expiry,
        cardRegistration,
        amountCents: 100,
      })

      return subscriptionState === 'active'
        ? await changeCreditCard({
            subscriptionId,
            registrationData,
            cardRegistrationUuid: cardRegistration.uuid,
          })
        : restartSubscription({
            subscriptionId,
            registrationData,
            cardRegistrationUuid: cardRegistration.uuid,
          })
    },
  }
}

export default useChangeCreditCardPayment
