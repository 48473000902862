import React, { useState } from 'react'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Text } from '@kisskissbankbank/kitten'

const CompanyName = () => {
  const t = useTranslation()
  const [showField, setFieldVisible] = useState(false)

  if (!showField) {
    return (
      <div className="k-u-margin-vertical-triple">
        <Text
          tag="a"
          size="small"
          weight="500"
          className="k-u-link k-u-link-primary1 k-u-cursor-pointer"
          onClick={() => setFieldVisible(true)}
        >
          {t('contribute.shipping_address.companyName.optin')}
        </Text>
      </div>
    )
  }
  return (
    <div className="k-u-margin-vertical-triple">
      <Label htmlFor="companyName">
        {t('contribute.shipping_address.form.company.label')}
      </Label>

      <SimpleText
        name="companyName"
        autocomplete="organization"
        placeholder={t('contribute.shipping_address.form.company.placeholder')}
      />
    </div>
  )
}

export default CompanyName
