import React from 'react'
import { useSelector } from 'react-redux'
import { EnvelopeIconNext, Text, FlexWrapper } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, USER_MESSAGE_NEW } from 'kiss/routes/redux'
import { getOwnerOrContactSlug } from 'kiss/modules/project-page/page-state/selectors'

const Contact = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const contact = useSelector(getOwnerOrContactSlug)
  return (
    <FlexWrapper
      gap={10}
      className="kiss-Contributor__contact k-u-flex k-u-flex-justifyContent-sb"
    >
      <Text>
        {t('projects.contributor_panel.contact.delivery_question', {
          parseHtml: true,
        })}
      </Text>

      <Text
        tag="a"
        weight="500"
        className="k-u-link k-u-link-primary1 k-u-cursor-pointer k-u-flex k-u-flex-alignItems-center k-u-flex-gap-single"
        href={routeFor(USER_MESSAGE_NEW, {
          user: contact,
        })}
      >
        <EnvelopeIconNext color="currentColor" />
        {t('projects.contributor_panel.contact.contact_project')}
      </Text>
    </FlexWrapper>
  )
}

export default Contact
