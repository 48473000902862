import React, { useEffect, useState } from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useFormikContext } from 'formik'

import { Field } from '@kisskissbankbank/kitten'
import Checkbox from 'kiss/components/formik/checkbox'

const Anonymous = () => {
  const t = useTranslation()

  const {
    values: { hideContributor, hideAmount },
    setFieldValue,
  } = useFormikContext()

  const [isActivated, setActivated] = useState(hideContributor || hideAmount)

  useEffect(() => {
    if (isActivated) {
      setFieldValue('hideContributor', true)
      setFieldValue('hideAmount', true)
    } else {
      setFieldValue('hideContributor', false)
      setFieldValue('hideAmount', false)
    }
  }, [isActivated, setFieldValue])

  useEffect(() => {
    if (!hideContributor && !hideAmount) {
      setActivated(false)
    }
  }, [hideContributor, hideAmount])

  return (
    <div>
      <Field.Checkbox
        id="activate-anonymous-mode"
        className="k-u-margin-bottom-none"
        onChange={(e) => setActivated(e?.target?.checked)}
        indeterminate={isActivated && (!hideContributor || !hideAmount)}
        checked={isActivated}
        style={{ border: isActivated ? 'none' : '' }}
      >
        {t('contribute.cart.anonymous_contribution.label')}
      </Field.Checkbox>

      <div aria-live="polite" className="k-u-margin-left-triple">
        {isActivated && (
          <>
            <Checkbox
              id="hideAmount"
              name="hideAmount"
              style={{ border: hideAmount ? 'none' : '' }}
            >
              {t('contribute.cart.anonymous_contribution.hide_amount')}
            </Checkbox>

            <Checkbox
              id="hideContributor"
              name="hideContributor"
              style={{ border: hideContributor ? 'none' : '' }}
            >
              {t('contribute.cart.anonymous_contribution.hide_contributor')}
            </Checkbox>
          </>
        )}
      </div>
    </div>
  )
}

export default Anonymous
