import React from 'react'
import { useSelector } from 'react-redux'
import { getLocale } from 'kiss/app/redux'
import { useField } from 'formik'
import NumberFormat from 'react-number-format'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Field } from '@kisskissbankbank/kitten'
import Label from 'kiss/components/formik/label'

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '01'
    } else if (val > max) {
      val = max
    }
  }

  return val
}

const cardExpiry = (val) => {
  const month = limit(val.substring(0, 2), '12')
  const fullYear = val.substring(2, 6)
  const year =
    fullYear.length === 4 ? fullYear.substring(2, 4) : fullYear.substring(0, 2)

  return val ? month + '/' + year : ''
}

const Expiration = () => {
  const t = useTranslation()
  const locale = useSelector(getLocale)
  const [field, meta, helpers] = useField({
    name: 'expiry',
    validate: (value) => {
      if (!value) return t('app.forms.error.required')

      const today = new Date()
      const expiry = new Date()
      const [month, year] = value.split('/')

      // TODO: Update me to the year 2100.
      expiry.setFullYear(parseInt(`20${year}`), parseInt(month - 1))

      return today <= expiry ? false : t('payment_choice.errors.outdated')
    },
  })
  const error = meta.touched && meta.error

  const handleChange = ({ value }) => {
    const month = value.substring(0, 2)
    const year = value.substring(2, 4)
    const formattedValue = value ? month + '/' + year : ''

    helpers.setValue(formattedValue)
  }

  return (
    <div className="k-u-margin-bottom-triple">
      <Label htmlFor="frmCCExp">{t('payment_choice.expiry_date')}</Label>

      <NumberFormat
        name="cc-exp"
        id="frmCCExp"
        fit="fluid"
        autoComplete="cc-exp"
        format={cardExpiry}
        inputMode="numeric"
        placeholder={locale === 'fr' ? 'MM/AA' : 'MM/YY'}
        error={!!error}
        customInput={Field.Input}
        value={field.value}
        onValueChange={handleChange}
      />

      {!!error && <Field.ErrorMessage>{error}</Field.ErrorMessage>}
    </div>
  )
}

export default Expiration
