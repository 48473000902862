import React from 'react'
import { useSelector } from 'react-redux'
import { AlertBox, Text } from '@kisskissbankbank/kitten'
import { gql } from '@apollo/client'
import ShippingAddressModal from './components/shipping-address/modal'
import { useQuery } from 'kiss/api/graphql-query'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, USER_MESSAGE_NEW } from 'kiss/routes/redux'
import { StyledWrapper } from './styles'

const GET_ORDER_ADDRESS = gql`
  query GetOrderAddress($id: ID!) {
    order(id: $id) {
      id
      editable
      orderAddress {
        id
        firstName
        lastName
        companyName
        phone
        address1
        address2
        postalCode
        city
        countryCode
        user {
          email
        }
      }
    }
  }
`

const Delivery = ({ order, owner }) => {
  const t = useTranslation()
  const { data, refetch } = useQuery(GET_ORDER_ADDRESS, { id: order.id })
  const routeFor = useSelector(getRouteFor)
  const orderAddress = data?.order?.orderAddress
  const isEditable = data?.order?.editable

  if (!orderAddress) {
    return null
  }

  return (
    <div>
      <Text
        size="huge"
        tag="h3"
        weight="500"
        className="k-u-margin-bottom-single"
      >
        {t('contributor.orders.delivery.title')}
      </Text>

      <StyledWrapper>
        <div className="kiss-ContributionDetails__gridWrapper">
          <div>
            <Text weight="500" size="medium">
              {orderAddress.firstName} {orderAddress.lastName}
            </Text>
            <Text tag="p" weight="400">
              {orderAddress.address1}
              {orderAddress.address2 && (
                <>
                  <br />
                  {orderAddress.address2}
                </>
              )}
            </Text>
            <Text tag="p" weight="400">
              {`${orderAddress.postalCode}, ${orderAddress.city}`}
            </Text>
            <Text tag="p" weight="400">
              {t(`countries.${orderAddress.countryCode}`)}
            </Text>
          </div>
          <div className="k-u-margin-top-singleHalf@s-down">
            <Text weight="500">
              {t('users.contributions.details.delivery_tracking', {
                parseHtml: true,
              })}
            </Text>
            <Text tag="p" weight="400">
              {orderAddress.phone}
            </Text>
            <Text tag="p" weight="400">
              {orderAddress.user?.email}
            </Text>
            {isEditable && (
              <ShippingAddressModal address={orderAddress} refetch={refetch} />
            )}
          </div>
        </div>

        {!isEditable && owner && (
          <AlertBox status="warning" className="k-u-margin-top-singleHalf">
            <Text tag="p" weight="400">
              {t('contributor.orders.delivery.noEdit.paragraph')}
            </Text>
            <Text
              tag="a"
              weight="500"
              href={routeFor(USER_MESSAGE_NEW, { user: owner })}
              className="k-u-decoration-underline"
            >
              {t('contributor.orders.delivery.noEdit.contact')}
            </Text>
          </AlertBox>
        )}
      </StyledWrapper>
    </div>
  )
}

export default Delivery
