import React from 'react'
import styled from 'styled-components'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import {
  Container,
  Grid,
  GridCol,
  GUTTER,
  pxToRem,
  TitleWithStroke,
  Video,
  mq,
} from '@kisskissbankbank/kitten'
import getTitleId from 'kiss/utils/get-title-id'

const StyledMediasGrid = styled.div`
  display: grid;
  gap: ${pxToRem(GUTTER)};
  grid-template-columns: repeat(var(--kiss-MediasList-cols), 1fr);
  --kiss-MediasList-cols: var(--kiss-MediasList-cols-mobile);

  @media ${mq.tablet} {
    --kiss-MediasList-cols: var(--kiss-MediasList-cols-tablet);
  }

  @media ${mq.desktop} {
    --kiss-MediasList-cols: var(--kiss-MediasList-cols-desktop);
  }
`

const MediasList = ({ title, medias, mobileCols, tabletCols, desktopCols }) => {
  return (
    <Container>
      <Grid>
        <GridCol col-xs="10" offset-xs="1" col-m="8" offset-m="2">
          {title.text && (
            <TitleWithStroke
              id={getTitleId(title.text)}
              tag={`h${title.level}`}
              modifier="quaternary"
            >
              {title.text}
            </TitleWithStroke>
          )}

          {medias && (
            <StyledMediasGrid
              style={{
                '--kiss-MediasList-cols-mobile': mobileCols,
                '--kiss-MediasList-cols-tablet': tabletCols,
                '--kiss-MediasList-cols-desktop': desktopCols,
              }}
            >
              {medias.map(({ type, data }) => {
                if (type === 'image') {
                  return (
                    <img
                      key={data.url}
                      loading="lazy"
                      className="k-u-image-responsive"
                      style={{ width: '100%' }}
                      src={data.url}
                      alt={data.alt || ''}
                    />
                  )
                } else if (type === 'video') {
                  return (
                    <Video playsInline src={`${data.url}#t=0.1`} key={data.url}>
                      <Video.Loader>
                        <LoadingAnimation />
                      </Video.Loader>
                    </Video>
                  )
                }
              })}
            </StyledMediasGrid>
          )}
        </GridCol>
      </Grid>
    </Container>
  )
}

MediasList.defaultProps = {
  mobileCols: 2,
  tabletCols: 3,
  desktopCols: 3,
}

export default MediasList
