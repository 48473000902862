import {
  Field as KittenField,
  Label,
  pxToRem,
  Radio,
} from '@kisskissbankbank/kitten'
import { ErrorMessage, Field, useField } from 'formik'
import { useTranslation } from 'kiss/hooks/use-translation'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import prop from 'lodash/fp/prop'
import size from 'lodash/fp/size'
import some from 'lodash/fp/some'
import sortBy from 'lodash/fp/sortBy'
import memoize from 'lodash/memoize'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import InPerson from './in_person'

const MethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${pxToRem((50 - 21) / 2)} ${pxToRem(20)} ${pxToRem((50 - 21) / 2)}
    ${pxToRem(15)};
  min-height: ${pxToRem(50)};
  border: var(--border);
  border-radius: var(--border-radius-s);
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  &:focus-within,
  &:focus-visible {
    outline: auto;
  }

  .k-Form-RadioButton__label::before {
    margin-top: ${pxToRem(2)};
  }
  .k-Form-RadioButton__labelText {
    font-weight: 500;
  }
`

const FRANCE_CODE = 'FR'

const placeCountryFirst = ({ options, countryCode }) =>
  options && countryCode
    ? [
        options.find((option) => option.value === countryCode),
        ...options.filter((option) => option.value !== countryCode),
      ]
    : []

export const getDeliveryCountries = memoize(
  (t, deliveryCountriesAmounts, withFranceOnFirst = true, period = null) => {
    const options = map(({ countryCode, amount }) => ({
      value: countryCode,
      amount: amount.cents,
      label: `${t(`countries.${countryCode}`)} + ${amount.cents / 100} ${t(
        `currency_symbol.${amount.currency}`,
      )} ${period ? t(`project.modal.shipping.period.${period}`) : ''}`,
    }))(deliveryCountriesAmounts)
    return withFranceOnFirst &&
      options.some((option) => option.value === FRANCE_CODE)
      ? placeCountryFirst({
          options: sortBy(prop('label'))(options),
          countryCode: FRANCE_CODE,
        })
      : sortBy(prop('label'))(options)
  },
  (_, deliveryCountriesAmounts, period) => {
    return {
      deliveryCountriesAmounts: deliveryCountriesAmounts
        .map(({ amount, countryCode }) => `${countryCode}${amount.cents}`)
        .join('-'),
      period,
    }
  },
)

const RadioField = ({ id, value, error = false, ...props }) => {
  return (
    <Field name="shipping">
      {({ field }) => {
        return (
          <Radio
            {...props}
            {...field}
            id={id}
            value={value}
            error={error}
            className="k-u-margin-none"
          />
        )
      }}
    </Field>
  )
}

const hasMultipleChoices = (reward) =>
  flow(prop('deliveryModes'), size)(reward) > 1

const hasInPerson = (reward) =>
  flow(prop('deliveryModes'), some({ code: 'inPerson' }))(reward)

const hasPostal = (reward) =>
  flow(prop('deliveryModes'), some({ code: 'postal' }))(reward)

const hasNoShipping = (reward) =>
  flow(prop('deliveryModes'), some({ code: 'none' }))(reward)

const getInPersonDescription = (reward) =>
  flow(
    prop('deliveryModes'),
    find({ code: 'inPerson' }),
    prop('description'),
  )(reward)

const Shipping = ({ reward, shippingAddressComponent }) => {
  const t = useTranslation()
  const [, meta, { setValue }] = useField('shipping')

  const handleKeyPress = (event) => {
    event.preventDefault()
    if (['Enter', ' '].includes(event.key)) {
      event.target.click()
    }
  }

  useEffect(() => {
    if (!hasMultipleChoices(reward)) {
      if (hasInPerson(reward)) {
        setValue('inPerson')
      } else if (hasPostal(reward)) {
        setValue('postal')
      } else {
        setValue('none')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (hasNoShipping(reward)) return null

  return (
    <div className="k-u-margin-bottom-double">
      {hasMultipleChoices(reward) ? (
        <>
          {reward.hasVariationSelection && (
            <Label size="small">
              {t('project.modal.shipping.method.label')}
            </Label>
          )}
          <div className="k-u-flex k-u-flex-direction-column k-u-flex-gap-double">
            {hasInPerson(reward) && (
              <MethodWrapper
                role="button"
                tabIndex="0"
                onKeyPress={handleKeyPress}
                onClick={() =>
                  document.getElementById('radio-inPerson').click()
                }
              >
                <RadioField
                  id="radio-inPerson"
                  value="inPerson"
                  error={meta.touched && meta.error}
                  text={t('project.modal.shipping.in_person.label')}
                  checked={
                    (!hasPostal(reward) && hasInPerson(reward)) || undefined
                  }
                >
                  {getInPersonDescription(reward) || null}
                </RadioField>
              </MethodWrapper>
            )}

            {hasPostal(reward) && (
              <MethodWrapper
                role="button"
                tabIndex="0"
                onKeyPress={handleKeyPress}
                onClick={() => document.getElementById('radio-postal').click()}
              >
                <RadioField
                  id="radio-postal"
                  value="postal"
                  error={meta.touched && meta.error}
                  text={t('project.modal.shipping.label')}
                  checked={
                    (hasPostal(reward) && !hasInPerson(reward)) || undefined
                  }
                />
                <div className="k-u-margin-left-doubleHalf">
                  {shippingAddressComponent}
                </div>
              </MethodWrapper>
            )}
          </div>

          <ErrorMessage name="shipping">
            {(msg) => (
              <KittenField.ErrorMessage>{msg}</KittenField.ErrorMessage>
            )}
          </ErrorMessage>
        </>
      ) : (
        <div className="k-u-margin-top-triple">
          {hasInPerson(reward) && (
            <InPerson description={getInPersonDescription(reward)} />
          )}
          {hasPostal(reward) && shippingAddressComponent}
        </div>
      )}
    </div>
  )
}

export default Shipping
