import { pxToRem, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import * as qs from 'neoqs'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useBrowsing from '../hooks/use-browsing'
import FocusTrap from 'focus-trap-react'
import FilterButton from './components/filter-button'
import Filter from './components/filter'
import styled from 'styled-components'

const FILTER_ID = 'state-button'

const ButtonsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(2)};

  .kiss-ButtonsList__button {
    height: ${pxToRem(30)};
    white-space: nowrap;

    &:hover,
    &:focus,
    &:active {
      font-weight: 500 !important;
    }
  }
`

const State = (props) => {
  const [showFilter, setShowFilter] = useState(false)
  const t = useTranslation()
  const { updateSearch } = useBrowsing()
  const { search } = useLocation()
  const { state } = qs.parse(search, { ignoreQueryPrefix: true })

  const handleButtonClick = () => setShowFilter((current) => !current)
  const handleFilterClose = () => setShowFilter(false)

  return (
    <FocusTrap
      active={showFilter}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <div style={{ position: 'relative' }} {...props}>
        <FilterButton
          id={FILTER_ID}
          onClick={handleButtonClick}
          showFilter={showFilter}
        >
          {!['started', 'successful'].includes(state) &&
            t('browsing.filter.state.all')}
          {state === 'started' && t('browsing.filter.state.in_progress')}
          {state === 'successful' && t('browsing.filter.state.completed')}
        </FilterButton>

        {showFilter && (
          <Filter onFilterClose={handleFilterClose}>
            <ButtonsListWrapper>
              <Text
                as="button"
                size="small"
                className="k-u-reset-button kiss-ButtonsList__button"
                weight={
                  !['started', 'successful'].includes(state) ? '500' : '400'
                }
                onClick={() => {
                  updateSearch({ state: 'all' })
                  handleFilterClose()
                }}
              >
                {t('browsing.filter.state.all')}
              </Text>

              <Text
                as="button"
                size="small"
                className="k-u-reset-button kiss-ButtonsList__button"
                weight={state === 'started' ? '500' : '400'}
                onClick={() => {
                  updateSearch({ state: 'started' })
                  handleFilterClose()
                }}
              >
                {t('browsing.filter.state.in_progress')}
              </Text>

              <Text
                as="button"
                size="small"
                className="k-u-reset-button kiss-ButtonsList__button"
                weight={state === 'successful' ? '500' : '400'}
                onClick={() => {
                  updateSearch({ state: 'successful' })
                  handleFilterClose()
                }}
              >
                {t('browsing.filter.state.completed')}
              </Text>
            </ButtonsListWrapper>
          </Filter>
        )}
      </div>
    </FocusTrap>
  )
}

export default State
