import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import PropTypes from 'prop-types'

import { Field } from '@kisskissbankbank/kitten'

const Label = ({ htmlFor, size = 'small', label, children, tooltip }) => {
  const t = useTranslation()

  return (
    <Field.Label
      labelProps={{ htmlFor, size }}
      tooltipId={`tooltip-${htmlFor}`}
      tooltip={tooltip}
      tooltipProps={{
        actionLabel: t('app.tooltip.more_informations'),
      }}
    >
      {label || children}
    </Field.Label>
  )
}

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
}

export default Label
