import {
  Button,
  ProjectCard as KittenProjectCard,
} from '@kisskissbankbank/kitten'
import ProjectCard from 'kiss/components/cards/project-card'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const WidgetCard = ({ project }) => {
  const t = useTranslation()

  return (
    <ProjectCard project={{ ...project }}>
      <KittenProjectCard.Line className="k-u-margin-top-single">
        <Button fit="fluid" modifier="helium" as="span">
          {t('widget.modal.button.label')}
        </Button>
      </KittenProjectCard.Line>
    </ProjectCard>
  )
}

export default WidgetCard
