import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import DefinitionList from 'kiss/modules/project-page/campaign/components/toolbars/admin-panel/definition-list'
import { getProjectCategoriesName } from 'kiss/modules/project-page/page-state/selectors'
import { Text } from '@kisskissbankbank/kitten'

const Info = () => {
  const t = useTranslation()
  const categories = useSelector(getProjectCategoriesName)

  return (
    <>
      <DefinitionList.Title>
        <Text weight="600" size="medium" color="font1">
          {t('projects.admin_panel.categories')}
        </Text>
      </DefinitionList.Title>
      <DefinitionList.Description>
        <Text weight="400" size="medium" color="font1">
          {categories}
        </Text>
      </DefinitionList.Description>
    </>
  )
}

export default Info
