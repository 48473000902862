import ReactOnRails from 'react-on-rails'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { App } from './app/app'
import { storeGenerator, getKissStore } from 'kiss/store'

const AppClient = ({ spa, ...props }) => {
  // DEPRECATED: `getKissStore` is deprecated in favor of `storeGenerator`.
  //             Prefer having `spa: true` here.
  const store = spa ? storeGenerator(props) : getKissStore()

  return (
    <BrowserRouter>
      <App store={store} />
    </BrowserRouter>
  )
}

ReactOnRails.register({ App: AppClient })
