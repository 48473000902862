import React from 'react'
import PropTypes from 'prop-types'
import { pxToRem, Text } from '@kisskissbankbank/kitten'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import getOr from 'lodash/fp/getOr'
import head from 'lodash/fp/head'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getJSONContent } from '@kisskissbankbank/underwood'

const StyledRewardItem = styled.div`
  width: 100%;
  display: flex;
  gap: ${pxToRem(15)};
  align-items: center;

  .kiss-RewardItem__image {
    width: ${pxToRem(80)};
    height: ${pxToRem(50)};
    object-fit: cover;
    object-position: center center;
    align-self: flex-start;
    flex: 0 0 ${pxToRem(80)};
  }

  .kiss-RewardItem__description {
    flex: 0 1 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

const StyledImage = styled.img`
  border-radius: var(--border-radius-xs);
`

const getFirstBlockText = (description) =>
  flow(
    getJSONContent,
    getOr([])('blocks'),
    head,
    getOr('')('text'),
  )(description)

const RewardItem = ({ title, imageUrl, quantity, className, description }) => {
  const rawDescription = getFirstBlockText(description)
  const t = useTranslation()
  return (
    <StyledRewardItem className={className}>
      {imageUrl && (
        <StyledImage src={imageUrl} alt="" className="kiss-RewardItem__image" />
      )}

      <div className="kiss-RewardItem__description k-u-weight-600 k-u-size-micro">
        <Text
          size="micro"
          weight="600"
          lineHeight="1.3"
          color="font1"
          className="k-u-margin-bottom-noneHalf"
          tag="p"
        >
          {!isEmpty(title) ? title : rawDescription}
        </Text>

        {quantity && (
          <Text size="micro" weight="400" color="font1" tag="p">
            {t('cart.contribute.summary.quantity', {
              quantity,
              parseHtml: true,
            })}
          </Text>
        )}
      </div>
    </StyledRewardItem>
  )
}

RewardItem.propTypes = {
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  quantity: PropTypes.number,
}

export default RewardItem
