import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import DefinitionList from 'kiss/modules/project-page/campaign/components/toolbars/admin-panel/definition-list'
import { Text } from '@kisskissbankbank/kitten'
import { getChallenges } from 'kiss/modules/project-page/page-state/selectors'

const Challenges = () => {
  const t = useTranslation()
  const challenges = useSelector(getChallenges)

  if (challenges.length === 0) return null

  return (
    <>
      <DefinitionList.Title>
        <Text weight="600" size="medium" color="font1">
          {t('projects.admin_panel.challenges')}
        </Text>
      </DefinitionList.Title>
      <DefinitionList.Description>
        <ul>
          {challenges.map((challenge) => {
            return (
              <li key={challenge.id}>
                <Text weight="400" size="medium" color="font1">
                  {challenge.name}
                </Text>
              </li>
            )
          })}
        </ul>
      </DefinitionList.Description>
    </>
  )
}

export default Challenges
