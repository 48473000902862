import React, { useEffect } from 'react'
import {
  IconBadge,
  QuestionMarkIcon,
  Text,
  Toggletip,
} from '@kisskissbankbank/kitten'
import isEmpty from 'lodash/fp/isEmpty'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useCart } from '../../../../hooks'
import Wrapper from '../../../../components/summary/components/wrapper'
import Stroke from '../../../../components/summary/components/stroke'
import Separator from '../../../../components/summary/components/separator'
import AmountLine from '../../../../components/summary/components/amount-line'
import RewardItem from '../../../../components/summary/components/reward-item'
import GiftCard from './gift-card'
import ExplainBlock from '../components/explain-block'

const FlowSummary = () => {
  const t = useTranslation()
  const { cart, loading, refetch } = useCart()
  const shippingCountryCode = cart?.shippingAddress?.countryCode
  const lines = cart?.lines ?? []

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading || isEmpty(cart)) return null

  const getFormattedAmount = (amount) => {
    return t('project_info.amount_with_currency', {
      amount: t(amount, { formatNumber: { decimal: 2 } }),
      currency: t(`currency_symbol.${cart?.amountToPay?.currency}`),
      parseHtml: true,
    })
  }

  const tipAmount = (cart?.tipAmount?.cents ?? 0) / 100
  const donationAmount = (cart?.donationAmount?.cents ?? 0) / 100
  const redeemedAmount = (cart?.redeemedAmount?.cents ?? 0) / 100
  const gifCartRemainingAmount =
    (cart?.giftCard?.remainingAmount?.cents ?? 0) / 100 - redeemedAmount
  const giftCardCode = cart?.giftCard?.code
  const serviceFeeAmount = cart?.serviceFeeAmount?.cents / 100

  return (
    <Wrapper>
      <Text
        tag="h2"
        size="small"
        weight="600"
        color="font1"
        className="k-u-margin-bottom-single"
      >
        {t('cart.contribute.your_summary')}
      </Text>
      <Stroke />
      {lines.map((line) => {
        const variation = line?.variation ?? {}
        const reward = variation.reward ?? {}
        return (
          <RewardItem
            key={variation.id}
            title={reward.label}
            imageUrl={reward.image?.url}
            quantity={line?.quantity}
            description={reward.description}
            className="k-u-margin-vertical-single"
          />
        )
      })}
      <Separator />
      <GiftCard />
      <Separator />
      {donationAmount !== 0 && (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.summary.donation')}
          </Text>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(donationAmount)}
          </Text>
        </AmountLine>
      )}
      {tipAmount !== 0 && (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.summary.tip')}
          </Text>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(tipAmount)}
          </Text>
        </AmountLine>
      )}
      <AmountLine>
        <Text size="small" weight="400" color="font1">
          {t('cart.contribute.summary.sub_total')}
        </Text>
        <Text size="small" weight="400" color="font1">
          {getFormattedAmount(cart?.linesAmount?.cents / 100)}
        </Text>
      </AmountLine>
      {shippingCountryCode && (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.summary.shipping', {
              country: t(`countries.${shippingCountryCode}`),
              parseHtml: true,
            })}
          </Text>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(cart?.deliveryAmount?.cents / 100)}
          </Text>
        </AmountLine>
      )}
      {serviceFeeAmount !== 0 && (
        <AmountLine>
          <div>
            <Text size="small" weight="400" color="font1">
              {t('cart.contribute.summary.service_fees.label')}
            </Text>
            <Toggletip
              targetElement={
                <IconBadge size="micro">
                  <QuestionMarkIcon width="8" height="8" />
                </IconBadge>
              }
              className="k-u-margin-left-single"
            >
              <Text>{t('cart.contribute.summary.service_fees.tooltip')}</Text>
            </Toggletip>
          </div>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(serviceFeeAmount)}
          </Text>
        </AmountLine>
      )}
      {giftCardCode && (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.total.gift_card')}
            <br />
            <Text fontStyle="italic" size="micro">
              {t('cart.contribute.total.gift_card_remaining_amount', {
                amount: getFormattedAmount(gifCartRemainingAmount),
              })}
            </Text>
          </Text>
          <Text
            weight="400"
            color="font1"
            size="small"
            data-test-id="cart-total-gift-card"
          >
            -{getFormattedAmount(redeemedAmount)}
          </Text>
        </AmountLine>
      )}
      <Separator />
      <AmountLine>
        <Text size="huge" weight="500" color="font1">
          {t('cart.contribute.summary.total')}
        </Text>
        <Text size="huge" weight="600" color="font1">
          {getFormattedAmount(cart?.amountToPay?.cents / 100)}
        </Text>
      </AmountLine>
      <Separator />
      <ExplainBlock />
    </Wrapper>
  )
}

export default FlowSummary
