import { gql } from '@apollo/client'
import {
  Container,
  FlexWrapper,
  HEADER_HEIGHT,
  KissKissLoadingAnimation,
  pxToRem,
  Title,
} from '@kisskissbankbank/kitten'
import { useMutation, useQuery } from 'kiss/api/graphql-query'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getCurrentUserId } from 'kiss/session/redux'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled(Container)`
  height: calc(100vh - ${pxToRem(HEADER_HEIGHT)} - ${pxToRem(200)});
  margin-top: ${pxToRem(200)};

  .kiss-bigText {
    font-size: ${pxToRem(92)};
  }
`

const GET_PROJECT_ID_BY_SLUG = gql`
  query GetProject($slug: String) {
    project(slug: $slug) {
      id
      name
    }
  }
`

const UNSUBSCRIBE_FROM_PROJECT_NEWS = gql`
  mutation UnsubscribeFromProjectNews($userId: ID, $projectId: ID) {
    unsubscribeFromProjectNews(userId: $userId, projectId: $projectId) {
      id
    }
  }
`

const UnsubscribeNews = () => {
  const t = useTranslation()
  const userId = useSelector(getCurrentUserId)
  const { project } = useParams()
  const { data, loading } = useQuery(GET_PROJECT_ID_BY_SLUG, { slug: project })
  const [unsubscribe] = useMutation(UNSUBSCRIBE_FROM_PROJECT_NEWS)
  useEffect(() => {
    if (!loading && userId) {
      const projectId = data?.project?.id
      unsubscribe({ variables: { projectId, userId } })
    }
  }, [loading, data, userId])
  return (
    <DefaultLayout>
      <Wrapper>
        <FlexWrapper direction="column" className="k-u-align-center" gap={50}>
          {loading ? (
            <div>
              <KissKissLoadingAnimation />
            </div>
          ) : (
            <>
              <Title modifier="senary">
                {t('users.projects.unsubscribe.successful', {
                  parseHtml: true,
                  project: data?.project?.name,
                })}
              </Title>
              <div className="kiss-bigText">
                <span aria-hidden>👋</span>
                <span className="k-u-a11y-visuallyHidden">Au revoir !</span>
              </div>
            </>
          )}
        </FlexWrapper>
      </Wrapper>
    </DefaultLayout>
  )
}

export default UnsubscribeNews
