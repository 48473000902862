import React, { memo } from 'react'
import { FlexWrapper, Container, Grid, GridCol } from '@kisskissbankbank/kitten'

const TableEntry = ({ links }) => {
  return (
    <Container>
      <Grid>
        <GridCol
          col-xs="10"
          offset-xs="1"
          col-m="8"
          offset-m="2"
          col-l="6"
          offset-l="3"
        >
          <FlexWrapper gap={5}>
            {links.map((link) => (
              <a
                key={link.text}
                className="k-u-link k-u-link-primary1"
                href={`#${link.anchor}`}
              >
                {link.text}
              </a>
            ))}
          </FlexWrapper>
        </GridCol>
      </Grid>
    </Container>
  )
}

export default memo(TableEntry)
