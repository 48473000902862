import React from 'react'

import { getLocale } from 'kiss/app/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import {
  getManagerNewsRoute,
  getNews,
  getNewsCount,
  isCurrentUserOwner,
  getProjectState,
} from 'kiss/modules/project-page/page-state/selectors'

import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import orderBy from 'lodash/fp/orderBy'
import { isVisible } from 'kiss/utils/project/state'

import AdminPanel from 'kiss/modules/project-page/campaign/components/toolbars/tabs-admin-panel'
import { CloseReplyFormsProvider } from 'kiss/modules/project-page/common/contexts/CloseReplyForms'
import NewsCard from 'kiss/modules/project-page/campaign/news/components/news-card'

import {
  Button,
  Container,
  FlexWrapper,
  Grid,
  GridCol,
} from '@kisskissbankbank/kitten'
import NoResult from 'kiss/modules/project-page/common/news/NoResult'

const News = () => {
  const t = useTranslation()

  const news = useSelector(getNews)
  const newsCount = useSelector(getNewsCount)

  const locale = useSelector(getLocale)
  const currentUserIsOwner = useSelector(isCurrentUserOwner)
  const projectState = useSelector(getProjectState)
  const managerNewsRoute = useSelector(getManagerNewsRoute)

  return (
    <CloseReplyFormsProvider>
      <Container>
        {currentUserIsOwner && (
          <AdminPanel className="k-u-margin-bottom-triple k-u-margin-bottom-quintuple@s-up">
            {newsCount !== 0 && (
              <Button fit="content" as="a" href={managerNewsRoute}>
                {t('project.news.list_news')}
              </Button>
            )}
            {isVisible(projectState) && (
              <Button
                fit="content"
                as="a"
                modifier="helium"
                href={managerNewsRoute}
                className="k-u-margin-left-single@s-up k-u-margin-top-single@s-down"
              >
                {t('project.news.add_news', { parseHtml: true })}
              </Button>
            )}
          </AdminPanel>
        )}

        <Grid>
          <GridCol col-l="6" offset-l="3">
            <h2 className="k-u-a11y-visuallyHidden">
              {t('project.news.news_title')}
            </h2>
            <FlexWrapper gap={30}>
              {newsCount !== 0 &&
                flow(
                  orderBy(['publicationAt'], ['desc']),
                  map((newsItem) => (
                    <NewsCard
                      newsItem={newsItem}
                      locale={locale}
                      key={newsItem.id}
                      titleLevel="h3"
                    />
                  )),
                )(news)}
            </FlexWrapper>
            <NoResult total={newsCount} message={t('project.news.no_result')} />
          </GridCol>
        </Grid>
      </Container>
    </CloseReplyFormsProvider>
  )
}

export default News
