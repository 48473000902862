import React from 'react'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import {
  Container,
  Grid,
  GridCol,
  TitleWithStroke,
  Video,
} from '@kisskissbankbank/kitten'
import getTitleId from 'kiss/utils/get-title-id'

const FullVideo = ({ videoUrl, title }) => (
  <Container>
    <Grid className="k-u-flex-alignItems-center">
      <GridCol col-xs="10" offset-xs="1" col-m="8" offset-m="2">
        {title.text && (
          <TitleWithStroke
            id={getTitleId(title.text)}
            tag={`h${title.level}`}
            modifier="tertiary"
          >
            {title.text}
          </TitleWithStroke>
        )}

        <Video playsInline src={`${videoUrl}#t=0.1`}>
          <Video.Loader>
            <LoadingAnimation />
          </Video.Loader>
        </Video>
      </GridCol>
    </Grid>
  </Container>
)

export default FullVideo
