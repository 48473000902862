import React from 'react'
import {
  mq,
  pxToRem,
  Text,
  PencilIllustration,
  RainbowIllustration,
  HandAndFlowerIllustration,
} from '@kisskissbankbank/kitten'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'

const ManifestoWrapper = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${pxToRem(30)};
  color: var(--color-grey-000);
  text-align: center;
  padding: var(--padding-top-mobile) ${pxToRem(20)} var(--padding-bottom-mobile);

  @media ${mq.tablet} {
    padding: var(--padding-top-desktop) ${pxToRem(100)}
      var(--padding-bottom-desktop);
  }

  @media ${mq.desktop} {
    padding: var(--padding-top-desktop) ${pxToRem(250)}
      var(--padding-bottom-desktop);
  }

  .k-Title {
    margin: 0;
  }

  .kiss-Homepage__manifesto__illustrations {
    display: flex;
    gap: ${pxToRem(30)};
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: ${pxToRem(50)};
  }

  .kiss-Homepage__manifesto__illustration {
    flex-direction: column;
    justify-content: end;
    display: flex;
    align-items: center;
  }
`

const Manifesto = ({
  title,
  className,
  illustration,
  button,
  top = [],
  bottom = [],
  ...props
}) => {
  const t = useTranslation()
  return (
    <ManifestoWrapper
      className={className}
      style={{
        '--padding-top-mobile': pxToRem(top[0]),
        '--padding-bottom-mobile': pxToRem(bottom[0]),
        '--padding-top-desktop': pxToRem(top[1]),
        '--padding-bottom-desktop': pxToRem(bottom[1]),
      }}
      {...props}
    >
      {title}

      {illustration && (
        <div className="kiss-Homepage__manifesto__illustrations">
          <div className="kiss-Homepage__manifesto__illustration">
            <PencilIllustration />
            <Text
              tag="p"
              weight="500"
              size="large"
              cssColor="var(--color-grey-000)"
              className="k-u-margin-top-double"
            >
              {t('new_home.manifesto.culture', { parseHtml: true })}
            </Text>
          </div>
          <div className="kiss-Homepage__manifesto__illustration">
            <RainbowIllustration />
            <Text
              tag="p"
              weight="500"
              size="large"
              cssColor="var(--color-grey-000)"
              className="k-u-margin-top-double"
            >
              {t('new_home.manifesto.discriminations', { parseHtml: true })}
            </Text>
          </div>
          <div className="kiss-Homepage__manifesto__illustration">
            <HandAndFlowerIllustration />
            <Text
              tag="p"
              weight="500"
              size="large"
              cssColor="var(--color-grey-000)"
              className="k-u-margin-top-double"
            >
              {t('new_home.manifesto.environment', { parseHtml: true })}
            </Text>
          </div>
        </div>
      )}

      {button}
    </ManifestoWrapper>
  )
}

export default Manifesto
