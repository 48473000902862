import { Button, FacebookIconCircleNext } from '@kisskissbankbank/kitten'
import { facebookLoginPath } from 'kiss/api/rails-endpoints'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor as getRouteForSelector } from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'

const FacebookButton = ({ returnTo, routeParams }) => {
  const t = useTranslation()
  const getRouteFor = useSelector(getRouteForSelector)
  return (
    <Button
      fit="fluid"
      as="a"
      href={facebookLoginPath({
        return_to_path: returnTo ? getRouteFor(returnTo, routeParams) : null,
      })}
      data-test-id="authenticate-facebook-connect"
    >
      <FacebookIconCircleNext
        className="k-ColorSvg"
        width={20}
        height={20}
        color="#1977F3"
      />
      <span>{t('authenticate.facebook.label')}</span>
    </Button>
  )
}

export default FacebookButton
