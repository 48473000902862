import styled from 'styled-components'
import { pxToRem } from '@kisskissbankbank/kitten'

const CARD_WIDTH = 325

export const imageProps = {
  breakpoints: [0],
  imageSizesInViewport: [`${CARD_WIDTH}px`],
  responsiveImageSizes: [CARD_WIDTH],
}

export const StyledBlock = styled.div`
  display: grid;
  grid-template-columns: ${pxToRem(CARD_WIDTH)} minmax(0, 1fr);
  gap: ${pxToRem(60)};
  align-items: start;
`
