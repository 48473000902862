import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import map from 'lodash/fp/map'

const NAME = 'MENTORS'

export const getMentors = (state) =>
  flow(
    getOr([])(`${NAME}.mentors.edges`),
    map((edge) => edge.node),
  )(state)

export default { [NAME]: (state = { mentors: { edges: [] } }) => state }
