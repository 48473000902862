import React from 'react'
import { useSelector } from 'react-redux'
import { getCommentsTotalCount } from '../redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Paragraph } from '@kisskissbankbank/kitten'

const NoResult = () => {
  const t = useTranslation()
  const commentsTotalCount = useSelector(getCommentsTotalCount)

  if (commentsTotalCount > 0) return null

  return (
    <Paragraph
      className="k-u-margin-top-triple k-u-align-center"
      modifier="secondary"
    >
      {t('project_comments.no_result')}
    </Paragraph>
  )
}

export default NoResult
