import { gql } from '@apollo/client'
import { useLazyQuery } from '../../../api/graphql-query'

export const CONTRIBUTION_FIELDS = gql`
  fragment ContributionFields on Contribution {
    id
    backer {
      id
      firstName
      lastName
      username
      email
    }
    project {
      id
      name
      slug
      state
      campaignEndAt
      suspendedAt
      image {
        url(size: widget)
        mediumUrl: url(size: homepage)
        largeUrl: url(size: large)
        filename
      }
      organization {
        slug
        name
        avatarImage {
          url(size: thumb)
        }
        contact {
          slug
        }
      }
      owner {
        username
        slug
        avatarImage {
          url(size: thumb)
        }
      }
    }
  }
`
const GET_SUBSCRIPTION = gql`
  ${CONTRIBUTION_FIELDS}
  query SubscriptionQuery($id: ID!) {
    subscription(id: $id) {
      id
      payment {
        id
        state
        errorCode
        errorMessage
        secureModeRedirectUrl
      }
      amount {
        cents
        currency
      }
      cancelledAt
      contribution {
        ...ContributionFields
      }
      donation
      endDate
      period
      state
      currentCard {
        expirationDate
        numbers
      }
      subscriptionDate
      address {
        id
        firstName
        lastName
        companyName
        phone
        address1
        address2
        postalCode
        city
        countryCode
        user {
          email
        }
      }
      installments {
        id
        debitOn
        state
        label
        order {
          id
          deliveryModes
          totalAmount {
            cents
            currency
          }
        }
      }
      lastFulfilledInstallment {
        label
        state
        order {
          id
          deliveryModes
          disbursed
          payment {
            card {
              expirationDate
              numbers
            }
          }
          orderAddress {
            id
            firstName
            lastName
            companyName
            phone
            address1
            address2
            postalCode
            city
            countryCode
            user {
              email
            }
          }
        }
      }
      currentInstallment {
        label
        state
        order {
          id
          deliveryModes
          payment {
            card {
              expirationDate
              numbers
            }
            id
            state
            errorCode
            errorMessage
            secureModeRedirectUrl
          }
        }
      }
      contribution {
        project {
          subscribablePeriodLabel
        }
      }
      variation {
        id
        label
        reward {
          id
          label
          shippingDate {
            month
            year
          }
          subscribablePeriodLabel
          description
          image {
            url(size: cropped)
            thumbUrl: url(size: thumb)
            normalUrl: url(size: normal)
          }
          price {
            cents
            currency
          }
        }
      }
    }
  }
`

const useSubscription = () => {
  const [getSubscription, { data, startPolling, stopPolling }] = useLazyQuery(
    GET_SUBSCRIPTION,
    { fetchPolicy: 'network-only' },
  )

  return {
    getSubscription,
    data,
    paymentState: data?.subscription?.payment?.state,
    orderState: data?.subscription?.currentInstallment?.order?.payment?.state,
    secureModeRedirectUrl:
      data?.subscription?.payment?.secureModeRedirectUrl ||
      data?.subscription?.currentInstallment?.order?.payment
        ?.secureModeRedirectUrl,
    startPolling,
    stopPolling,
  }
}

export default useSubscription
