import { getRouteFor, SEARCH } from 'kiss/routes/redux'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import merge from 'lodash/fp/merge'
import pickBy from 'lodash/fp/pickBy'
import * as qs from 'neoqs'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { SearchContext } from './context'

const useSearch = () => {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const { projects } = useContext(SearchContext)
  const routeFor = useSelector(getRouteFor)

  const getNewSearch = (searchObject) => {
    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true })
    let newSearch = flow(
      merge(parsedSearch),
      pickBy((value) => !isEmpty(value)),
    )(searchObject)
    if (!searchObject.page) {
      delete newSearch.page
    }
    if (!searchObject.state) {
      delete newSearch.state
    }
    return decodeURI(qs.stringify(newSearch, { addQueryPrefix: true }))
  }
  return {
    projects,
    resetSearch: () => {
      history.push(routeFor(SEARCH))
    },
    updateSearch: (searchObject) => {
      history.push(`${pathname}${getNewSearch(searchObject)}`)
    },
  }
}

export default useSearch
