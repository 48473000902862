import React from 'react'
import {
  Button,
  EnvelopeIconNext,
  ProfileCard,
  pxToRem,
  Text,
  HorizontalStroke,
} from '@kisskissbankbank/kitten'
import styled from 'styled-components'
import {
  getProject,
  isSubscribersCountHidden,
} from 'kiss/modules/project-page/page-state/selectors'
import { getRouteFor, USER_MESSAGE_NEW } from 'kiss/routes/redux'
import { useTranslation } from '../../../../../hooks/use-translation'
import ShareButton from '../../components/share-button'
import { useSelector } from 'react-redux'

const StyledProfileCard = styled(ProfileCard)`
  max-width: ${pxToRem(300)};
`

const Profile = () => {
  const t = useTranslation()
  const project = useSelector(getProject)
  const hideSubscribersCount = useSelector(isSubscribersCountHidden)
  const routeFor = useSelector(getRouteFor)

  const userId = project?.owner
    ? project?.owner?.slug
    : project?.organization?.contact?.slug

  return (
    <StyledProfileCard className="k-u-margin-top-decuple@m-down">
      <ProfileCard.Avatar
        imageProps={{
          src:
            project?.organization?.avatarImage?.url ||
            project?.owner?.avatarImage?.url,

          alt: project?.organization?.name || project?.owner?.name,
        }}
      />

      <ProfileCard.Content>
        <Text
          size="huge"
          weight="600"
          tag="h1"
          className="k-u-margin-bottom-single"
        >
          {project?.name}
        </Text>
        <Text size="small" tag="p" className="k-u-margin-none">
          {project?.shortDesc}
        </Text>
        {!hideSubscribersCount && (
          <>
            <HorizontalStroke
              className="k-u-margin-horizontal-auto k-u-margin-vertical-single"
              customSize={{ width: 8, height: 2 }}
            />
            <Text
              size="micro"
              tag="p"
              className="k-u-margin-none"
              letterSpacing="10%"
              transform="uppercase"
              weight="500"
            >
              {project?.subscriptionsConnection?.totalCount || 0}{' '}
              {t(
                project?.subscriptionsConnection?.totalCount > 1
                  ? 'permanent_project.text.subscribers'
                  : 'permanent_project.text.subscriber',
              )}
            </Text>
          </>
        )}
      </ProfileCard.Content>

      <ProfileCard.Action>
        <Button
          tag="a"
          rounded
          modifier="helium"
          size="small"
          fit="content"
          href={routeFor(USER_MESSAGE_NEW, {
            user: userId,
          })}
        >
          <EnvelopeIconNext />
          {t('permanent_project.social.contact.button')}
        </Button>
        <ShareButton />
      </ProfileCard.Action>
    </StyledProfileCard>
  )
}
export default Profile
