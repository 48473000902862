import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  getProgressPercentage,
  getProjectState,
  getProjectExtratimeState,
} from 'kiss/modules/project-page/page-state/selectors'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  Progress,
  pxToRem,
  ScreenConfig,
  stepToRem,
  Text,
  useIntersectionObserver,
} from '@kisskissbankbank/kitten'

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`

const StyledText = styled(Text)`
  flex-shrink: 0;
  font-size: ${stepToRem(-1)};
  @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
    font-size: ${stepToRem(0)};
  }
`

const StyledProgress = styled.div`
  width: 100%;
  margin-right: ${pxToRem(20)};
  @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
    width: auto;
    flex: 1;
  }
`

const getColor = ({ state, isExtratime }) => {
  if (isExtratime) {
    return 'var(--color-success-500)'
  }

  switch (state) {
    case 'successful':
      return 'var(--color-success-500)'
    case 'failed':
      return 'var(--color-grey-400)'
    default:
      return 'var(--color-primary-500)'
  }
}

const ProgressBar = () => {
  const t = useTranslation()
  const progressPercentage = useSelector(getProgressPercentage)
  const state = useSelector(getProjectState)
  const isExtratime = useSelector(getProjectExtratimeState)

  const options = { rootMargin: '-20% 0px' }

  const observedComponentRef = useRef(null)
  const isVisible = useIntersectionObserver({ observedComponentRef, options })

  return (
    <div ref={observedComponentRef}>
      <StyledFlex>
        <StyledProgress>
          <Progress
            aria-label={t('project_details.progress.label')}
            value={isVisible ? progressPercentage : 0}
            rampProps={{ style: { height: 6 } }}
            color={getColor({ state, isExtratime })}
          />
        </StyledProgress>

        <StyledText tag="div" color="font1" weight="500" lineHeight="normal">
          {progressPercentage}
          &nbsp;%
        </StyledText>
      </StyledFlex>
    </div>
  )
}

export default ProgressBar
