import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import * as Validators from 'kiss/utils/validators'
import {
  getCartDonationAmount,
  getProjectSlug,
} from 'kiss/modules/project-page/page-state/selectors'
import { getRouteFor, CART } from 'kiss/routes/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import isEmpty from 'lodash/fp/isEmpty'
import { updateDonation as updateDonationToCart } from 'kiss/modules/contribute/cart/redux'
import RecurringDonationCard from './component/recurring-donation'
import SimpleDonationCard from './component/simple-donation'

const KissDonationCard = ({
  isRecurringDonation,
  onlyDonation = false,
  className,
  isProjectNotPublished,
  title = '',
}) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const initialDonationAmount = useSelector(getCartDonationAmount)
  const routeFor = useSelector(getRouteFor)
  const projectSlug = useSelector(getProjectSlug)

  const [donationValue, setDonationValue] = useState(initialDonationAmount)
  const [errorState, setErrorState] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setLoading] = useState(false)

  const isPristine = (value) => value === initialDonationAmount

  const setError = (message) => {
    setErrorState(true)
    setErrorMessage(message)
  }

  const clearError = () => {
    setErrorState(false)
    setErrorMessage('')
  }

  const hasError = (value) => {
    if (!value) {
      setError(t('entities.errors.reward.amount.required'))
      return true
    }

    if (isPristine(value) && !isEmpty(value)) return false

    const min = 1
    const max = 1000000

    if (Validators.range(min, max)(value) === 'range') {
      setError(t('entities.errors.reward.amount.range_min_max', { min, max }))
      return true
    }

    return false
  }

  const handleChange = () => clearError()
  const handleFocus = () => clearError()

  const handleBlur = (event) => {
    const {
      currentTarget: { value },
    } = event

    setDonationValue(value)

    return hasError(value)
  }
  const onSelectAmount = (value) => {
    setDonationValue(value)
    clearError()
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (hasError(donationValue)) return

    setLoading(true)
    if (isProjectNotPublished) {
      dispatch(
        addErrorAlert(t('project_alerts.errors.contribute_on_draft_project'), {
          scroll: true,
        }),
      )
      return null
    }

    return contribute()
  }

  const contribute = () => {
    const amountInCents = donationValue * 100

    dispatch(
      updateDonationToCart({
        donationCents: amountInCents,
        recurring: isRecurringDonation,
        projectSlug,
      }),
    ).then(() => {
      RoutingHelper.redirect(routeFor(CART, { project: projectSlug }))
    })
  }

  if (isRecurringDonation) {
    return (
      <RecurringDonationCard
        title={title}
        onlyDonation={onlyDonation}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        onSelectAmount={onSelectAmount}
        errorState={errorState}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    )
  }

  return (
    <SimpleDonationCard
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      errorState={errorState}
      errorMessage={errorMessage}
      isLoading={isLoading}
      className={className}
    />
  )
}

export default KissDonationCard
