import React from 'react'
import PropTypes from 'prop-types'
import { Loader, CbIcon, PayPalIcon, Text } from '@kisskissbankbank/kitten'
import PaymentStatusQuery from 'kiss/graphql/queries/contributor/payment-status.graphql'
import { useQuery } from 'kiss/api/graphql-query'
import { useTranslation } from 'kiss/hooks/use-translation'
import { StyledWrapper } from './styles'

const Icon = ({ provider }) => {
  switch (provider) {
    case 'paypal':
      return <PayPalIcon />
    default:
      return <CbIcon />
  }
}

const MeansOfPayment = ({ orderId }) => {
  const t = useTranslation()
  const { data, loading } = useQuery(PaymentStatusQuery, { orderId })
  const order = data?.order || {}
  const payment = order?.payment

  return (
    <div>
      <Text
        size="huge"
        tag="h3"
        weight="500"
        className="k-u-margin-bottom-single"
      >
        {t('users.contributions.details.means_of_payment')}
      </Text>
      <StyledWrapper>
        {loading && (
          <Loader className="k-u-margin-top-single k-u-flex-justifyContent-center" />
        )}
        {!loading && payment && (
          <div className="k-u-flex k-u-flex-direction-row k-u-flex-alignItems-center k-u-flex-gap-double k-u-flex-gap-triple@s-up">
            <div>
              <Icon provider={payment.provider} />
            </div>
            {payment.provider !== 'paypal' && payment.card && (
              <div className="k-u-flex k-u-flex-direction-column">
                {payment.card.numbers && (
                  <Text weight="500" size="medium">
                    {payment.card.numbers.match(/(\w{4})/gm).join(' ')}
                  </Text>
                )}
                {payment.card.expirationDate && (
                  <Text weight="400" cssColor="var(--color-grey-700)">
                    {`${t('contributor.orders.details.expiry')} ${
                      payment.card.expirationDate
                    }`}
                  </Text>
                )}
              </div>
            )}
          </div>
        )}
      </StyledWrapper>
    </div>
  )
}

MeansOfPayment.propTypes = {
  orderId: PropTypes.string.isRequired,
}

export default MeansOfPayment
