import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import {
  getRouteFor,
  PROJECT_ROUTE,
  USER_PUBLIC_PROFILE,
  ORGANIZATION_PROFILE_PUBLIC,
  USER_MESSAGE_NEW,
} from 'kiss/routes/redux'
import {
  getOrganization,
  getOrganizationProjectWithMoreSubscribers,
  getOwnerOrContactSlug,
  currentUserHasBacked,
} from 'kiss/modules/project-page/page-state/selectors'

import isEmpty from 'lodash/fp/isEmpty'

import { ArrowIcon, DropdownMenu } from '@kisskissbankbank/kitten'
import Avatar from 'kiss/modules/project-page/campaign/components/intro/components/avatar'

const RepresentativeInfo = () => {
  const t = useTranslation()

  const ownerOrContactSlug = useSelector(getOwnerOrContactSlug)
  const routeFor = useSelector(getRouteFor)
  const isOrganization = useSelector(getOrganization)
  const hasBacked = useSelector(currentUserHasBacked)
  const projectWithMoreSubscribers = useSelector(
    getOrganizationProjectWithMoreSubscribers,
  )

  if (isEmpty(isOrganization) && isEmpty(ownerOrContactSlug)) {
    return <Avatar />
  }

  return (
    <>
      <div className="k-u-flex k-u-flex-gap-single k-u-flex-alignItems-center">
        <DropdownMenu
          menuPosition="right"
          expandButton={false}
          button={({ open }) => (
            <Avatar>
              <ArrowIcon direction={open ? 'top' : 'bottom'} />
            </Avatar>
          )}
        >
          {isEmpty(isOrganization) && (
            <DropdownMenu.Link
              href={routeFor(USER_PUBLIC_PROFILE, {
                user: ownerOrContactSlug,
              })}
            >
              {t('project_hero.project.view_owner_profile')}
            </DropdownMenu.Link>
          )}
          {!isEmpty(isOrganization) && !isEmpty(projectWithMoreSubscribers) && (
            <DropdownMenu.Link
              href={routeFor(PROJECT_ROUTE, {
                project: projectWithMoreSubscribers.slug,
              })}
            >
              {t('project_hero.project.view_my_page')}
            </DropdownMenu.Link>
          )}
          {!isEmpty(isOrganization) && (
            <DropdownMenu.Link
              href={routeFor(ORGANIZATION_PROFILE_PUBLIC, {
                slug: isOrganization?.slug,
              })}
            >
              {t('project_hero.project.view_owner_profile')}
            </DropdownMenu.Link>
          )}
          {hasBacked && (
            <DropdownMenu.Link
              href={routeFor(USER_MESSAGE_NEW, {
                user: ownerOrContactSlug,
              })}
            >
              {t('project_hero.project.contact_owner')}
            </DropdownMenu.Link>
          )}
        </DropdownMenu>
      </div>
    </>
  )
}

export default RepresentativeInfo
