import { gql } from '@apollo/client'
import includes from 'lodash/fp/includes'
import { useMutation } from '../../api/graphql-query'
import { NO_REDIRECT_URL, TIMEOUT, SYNCHRONOUS_CONFIRMATION } from '../config'
import { getBrowserInfo } from '../helpers/browser-infos'
import isTimeout from '../helpers/is-timeout'

const CHANGE_CREDIT_CARD = gql`
  mutation ChangeCreditCard(
    $subscriptionId: ID!
    $creditCard: CreditCardInput!
    $browserInfo: BrowserInfoInput
  ) {
    updateSubscriptionCard(
      subscriptionId: $subscriptionId
      creditCard: $creditCard
      browserInfo: $browserInfo
    ) {
      payment {
        state
      }
      redirectUrl
      error
    }
  }
`

const useChangeCreditCard = () => {
  const [changeCreditCardMutation] = useMutation(CHANGE_CREDIT_CARD)
  return {
    changeCreditCard: async ({
      subscriptionId,
      cardRegistrationUuid,
      registrationData,
    }) => {
      try {
        const response = await changeCreditCardMutation({
          variables: {
            subscriptionId,
            creditCard: {
              cardRegistrationUuid: cardRegistrationUuid,
              registrationData: registrationData,
            },
            browserInfo: getBrowserInfo(),
          },
        })
        if (isTimeout(response)) {
          throw TIMEOUT
        }
        if (
          ['confirmed', 'refunded'].includes(
            response.data.updateSubscriptionCard.payment.state,
          )
        ) {
          throw SYNCHRONOUS_CONFIRMATION
        }
        if (!response.data.updateSubscriptionCard.redirectUrl) {
          throw NO_REDIRECT_URL
        }
        return response.data.updateSubscriptionCard.redirectUrl
      } catch (e) {
        if (includes('Timeout')(e.message)) {
          throw TIMEOUT
        }
        throw e
      }
    },
  }
}

export default useChangeCreditCard
