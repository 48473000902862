/* eslint-disable no-console */
import { gql } from '@apollo/client'
import { useImperativeQuery, useMutation } from 'kiss/api/graphql-query'
import { waitFor } from 'kiss/utils/wait-for'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import some from 'lodash/fp/some'
import sortBy from 'lodash/fp/sortBy'
import React, { createContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getAssetFor } from '../../assets/redux'
import config from '../../config'
import { getRouteFor, MESSAGING_ROOM } from '../../routes/redux'
import { getCurrentUser, getCurrentUserId } from '../../session/redux'
import {
  getFormattedUsers,
  getLastRoomMessageEvent,
  getUsersChatUsernames,
} from './helpers'

const CHAT_SIGN_IN = gql`
  mutation ChatSignInMutation($userId: ID) {
    chatSignIn(userId: $userId) {
      accessToken
      username
    }
  }
`

const GET_USER_CHAT_USERNAME = gql`
  query GetUser($slug: String) {
    user(slug: $slug) {
      id
      chatUsername
    }
  }
`

const GET_USER_INFOS = gql`
  query GetUserInfos($usersChatUsernames: [String!]) {
    currentUser {
      id
      chatRecipientsConnection(usersChatUsernames: $usersChatUsernames) {
        edges {
          node {
            id
            firstName
            lastName
            email
            username
            chatUsername
            avatarImage {
              url(size: thumb)
            }
            verifiedProfile
          }
        }
      }
    }
  }
`

export const MessagingContext = createContext({})

export const MessagingProvider = ({ children }) => {
  const assetFor = useSelector(getAssetFor)
  const [client, setClient] = useState(undefined)
  const [users, setUsers] = useState([])
  const userId = useSelector(getCurrentUserId)
  const currentUser = useSelector(getCurrentUser)
  const [signIn, { data }] = useMutation(CHAT_SIGN_IN)
  const getUsersInfos = useImperativeQuery(GET_USER_INFOS)
  const getChatUsername = useImperativeQuery(GET_USER_CHAT_USERNAME)
  const username = data?.chatSignIn?.username
  const accessToken = data?.chatSignIn?.accessToken

  const history = useHistory()
  const params = useParams()
  const routeFor = useSelector(getRouteFor)
  const [clientIsReady, setClientIsReady] = useState(false)
  const rooms = client?.getVisibleRooms()
  const currentRoom = rooms?.find(({ name }) => name === params.room)

  useEffect(() => {
    if (!userId) return
    signIn({ variables: { userId } })
  }, [userId])

  useEffect(() => {
    if (!username || !accessToken) return
    waitFor('matrixcs', () => {
      const matrixClient = window.matrixcs.createClient({
        baseUrl: config[APP_ENV].matrix.host,
        timelineSupport: true,
        accessToken,
        userId: username,
      })
      matrixClient.on('RoomMember.membership', async (event, member) => {
        if (member.membership === 'invite' && member.userId === username) {
          await matrixClient.joinRoom(member.roomId)
          console.log('Auto-joined %s', member.roomId)
        }
      })

      matrixClient
        .startClient({ initialSyncLimit: 1 })
        .then(() => {
          matrixClient.once('sync', async (state) => {
            if (state === 'PREPARED') {
              const fetchedRooms = matrixClient.getVisibleRooms()
              const usersChatUsernames = getUsersChatUsernames(fetchedRooms)
              const result = await getUsersInfos({ usersChatUsernames })
              setUsers(getFormattedUsers(result))
              if (params.room && !some({ name: params.room })(fetchedRooms)) {
                const { data } = await getChatUsername({ slug: params.room })
                await matrixClient.createRoom({
                  visibility: 'private',
                  invite: [data?.user?.chatUsername],
                })
              }
              if (!params.room && !isEmpty(fetchedRooms)) {
                history.push(
                  routeFor(MESSAGING_ROOM, {
                    room: flow(
                      filter((room) => room.selfMembership !== 'leave'),
                      sortBy((room) => {
                        const lastMessage = getLastRoomMessageEvent(room)
                        if (!lastMessage) return 0
                        return -new Date(lastMessage.getTs()).getTime()
                      }),
                      first,
                      get('name'),
                    )(fetchedRooms),
                  }),
                )
              }
              setClientIsReady(true)
            } else {
              return
            }
          })
        })
        .catch((err) => console.log('error startClient', err))
      setClient(matrixClient)
    })
  }, [username, accessToken])

  return (
    <>
      <Helmet>
        <script src="/browser-matrix.min.js" defer={true}></script>
      </Helmet>
      <MessagingContext.Provider
        value={{
          clientIsReady,
          client,
          username,
          rooms,
          getUserByChatUsername: (chatUsername) => {
            let user = currentUser
            if (chatUsername !== currentUser.chatUsername) {
              user = find({ chatUsername })(users)
            }
            return {
              shortName: user?.username,
              longName: user?.firstName
                ? `${user?.firstName} ${user?.lastName}`
                : '',
              avatarImageUrl:
                user?.avatarImage?.url ?? assetFor('default_square.svg'),
              email: user?.email || '',
              profileChecked: user?.verifiedProfile,
            }
          },
          hasRooms: !isEmpty(rooms) || false,
          currentRoom,
          deleteRoom: async (roomId) => {
            await client.leave(roomId)
          },
        }}
      >
        {children}
      </MessagingContext.Provider>
    </>
  )
}
