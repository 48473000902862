import { ArrowIcon, Button } from '@kisskissbankbank/kitten'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import React from 'react'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  &[aria-expanded='true'] {
    z-index: ${Z_INDEX.global.filterModal};
  }
`

const FilterButton = ({ children, showFilter, selected, ...props }) => {
  return (
    <StyledButton
      type="button"
      aria-expanded={!!showFilter}
      size="micro"
      active={selected}
      className="kiss-FilterButton"
      fit="content"
      {...props}
    >
      <span>{children}</span>
      <ArrowIcon direction={showFilter ? 'top' : 'bottom'} />
    </StyledButton>
  )
}

export default FilterButton
