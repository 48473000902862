import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import {
  getProjectState,
  getSuspendedAt,
} from 'kiss/modules/project-page/page-state/selectors'
import ContributeButton from 'kiss/modules/project-page/campaign/components/actions/contribute-button'
import { pxToRem, ScreenConfig, stepToRem } from '@kisskissbankbank/kitten'

const StyledContributeButton = styled(ContributeButton)`
  width: 100%;
  height: ${pxToRem(70)};
  font-size: ${stepToRem(1)};
  margin-top: ${pxToRem(25)};
  border-radius: var(--border-radius-s);

  @media (min-width: ${ScreenConfig.S.min}px) {
    height: ${pxToRem(90)};
    margin-top: ${pxToRem(35)};
    border-radius: var(--border-radius-m);
  }
`

const ProjectActions = () => {
  const projectState = useSelector(getProjectState)
  const suspendedAt = useSelector(getSuspendedAt)

  if (suspendedAt) return null
  if (projectState !== 'started') return null

  return <StyledContributeButton size="large" />
}

export default ProjectActions
