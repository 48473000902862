import React, { useCallback, useState } from 'react'

import {
  BROWSING_POPULAR_AND_STARTED,
  getRouteFor,
  SEARCH,
} from 'kiss/routes/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'

import { connectHits, connectStateResults } from 'react-instantsearch-dom'
import debounce from 'lodash/fp/debounce'

import {
  Button,
  CLOSE_OVERLAY_EVENT,
  FlexWrapper,
  HeaderNav,
  OPEN_OVERLAY_EVENT,
  Text,
} from '@kisskissbankbank/kitten'

import SeachResultCard from 'kiss/components/cards/search-result-card'

import styled from 'styled-components'

const StyledButton = styled(Button)`
  width: calc(100% - 40px);
`

const SearchBox = ({ currentRefinement, refine, delay }) => {
  const t = useTranslation()

  const routeFor = useSelector(getRouteFor)
  const [value, setValue] = useState(currentRefinement)

  const debouncedRefine = useCallback(
    debounce(delay)((value) => refine(value)),
    [],
  )

  const handleToggle = (event) => {
    window.dispatchEvent(
      new Event(
        event.isDropdownExpanded ? OPEN_OVERLAY_EVENT : CLOSE_OVERLAY_EVENT,
      ),
    )
  }
  const renderNoResult = () => (
    <FlexWrapper gap={20} padding={20}>
      <Text
        weight="500"
        size="small"
        as="h3"
        className="k-HorizontalProjectCard__title"
      >
        {t('search.no_result.title')}
      </Text>
      <Text as="p" weight="400" size="micro">
        {t('search.no_result.text')}
      </Text>
      <Button
        size="small"
        fit="fluid"
        as="a"
        href={routeFor(BROWSING_POPULAR_AND_STARTED)}
      >
        {t('browsing.no_result.button')}
      </Button>
    </FlexWrapper>
  )

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) => {
      if (searchState && searchState.query === '') return null

      if (searchResults && searchResults.nbHits !== 0) {
        return children
      } else {
        return renderNoResult()
      }
    },
  )

  const CustomHits = connectHits(({ hits }) => {
    return (
      <>
        <p className="k-u-a11y-visuallyHidden">
          {t('search.project.result_explanation')}
        </p>
        <FlexWrapper gap={5} padding={15}>
          {hits.map((item, index) => {
            return (
              <SeachResultCard
                project={item}
                key={`${index}-${item.name}`}
                hideOnMobile={index === hits.length - 1 && hits.length > 4}
              />
            )
          })}
        </FlexWrapper>

        <StyledButton
          size="small"
          type="submit"
          className="k-u-margin-bottom-double k-u-margin-horizontal-double"
        >
          {t('search.project.see_results')}
        </StyledButton>
      </>
    )
  })

  return (
    <HeaderNav.SearchInput
      onMenuToggle={handleToggle}
      searchInputProps={{
        name: 'q',
        placeholder: t('layouts.header.search_label'),
        'aria-label': t('layouts.header.search_label'),
        onChange: (e) => {
          setValue(e.currentTarget.value)
          debouncedRefine(e.currentTarget.value)
        },
        inputValue: value,
      }}
      searchButtonProps={{
        type: 'submit',
        'aria-label': t('search.project.see_results'),
      }}
      action={routeFor(SEARCH)}
      method="GET"
    >
      <Results>
        <CustomHits />
      </Results>
    </HeaderNav.SearchInput>
  )
}

export default SearchBox
