import { Title } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getReturnTo } from 'kiss/session/redux'
import React from 'react'
import { useSelector } from 'react-redux'

const ConditionalTitle = () => {
  const t = useTranslation()
  const returnTo = useSelector(getReturnTo)
  const defaultKey = (function () {
    switch (true) {
      case returnTo?.match(/maker/):
        return 'authenticate.facebook_or_email.title_maker'
      case returnTo?.match(/contributions/):
        return 'authenticate.facebook_or_email.title_contribute'
      default:
        return 'authenticate.facebook_or_email.title_sign_in'
    }
  })()

  return (
    <Title
      modifier="tertiary"
      tag="h3"
      noMargin
      className="k-u-margin-bottom-double k-u-align-center"
    >
      {t(defaultKey)}
    </Title>
  )
}

export default ConditionalTitle
