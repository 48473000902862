import React from 'react'
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import { getRouteFor, USER_PUBLIC_PROFILE } from 'kiss/routes/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import DefinitionList from 'kiss/modules/project-page/campaign/components/toolbars/admin-panel/definition-list'
import {
  getOwnerOrContactSlug,
  getOrganizationMembers,
  getOrganization,
  getOwner,
} from 'kiss/modules/project-page/page-state/selectors'
import { Text } from '@kisskissbankbank/kitten'
import { useSelector } from 'react-redux'

const Owner = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  const owner = useSelector(getOwner)
  const ownerSlug = useSelector(getOwnerOrContactSlug)

  const organization = useSelector(getOrganization)
  const members = useSelector(getOrganizationMembers)
  const membersFormatted = flow(
    map((member) => member.node),
    filter((member) => member.id !== organization?.contact?.id),
  )(members)

  if (!organization && !owner.firstName && !owner.lastName) {
    return (
      <>
        <DefinitionList.Title>
          <Text weight="600" size="medium" color="font1">
            {t('projects.admin_panel.owner')}
          </Text>
        </DefinitionList.Title>
        <DefinitionList.Description>
          <ul>
            <li>
              <Text weight="500" size="medium">
                {t('projects.admin_panel.owner_anonymous')}
              </Text>
            </li>
            <li>
              <Text weight="400" size="medium" color="font1">
                {t('projects.admin_panel.owner_deleted_account')}
              </Text>
            </li>
          </ul>
        </DefinitionList.Description>
      </>
    )
  }

  return (
    <>
      <DefinitionList.Title>
        <Text weight="600" size="medium" color="font1">
          {t('projects.admin_panel.owner')}
        </Text>
      </DefinitionList.Title>
      <DefinitionList.Description>
        {organization ? (
          <ul>
            <li>
              <Text
                tag="a"
                href={routeFor(USER_PUBLIC_PROFILE, {
                  user: organization?.contact?.slug,
                })}
                weight="500"
                size="medium"
                color="primary1"
              >
                {`${organization?.contact?.firstName} ${organization?.contact?.lastName}`}
              </Text>
            </li>
            <li>
              <Text weight="400" size="medium" color="font1">
                {organization?.contact?.phone}
              </Text>
            </li>
            <li>
              <Text
                tag="a"
                href={`mailto:${organization?.contact?.email}`}
                weight="500"
                size="medium"
                color="primary1"
              >
                {organization?.contact?.email}
              </Text>
            </li>
            <div className="k-u-margin-top-single">
              {membersFormatted.map((member) => {
                if (!member.firstName && !member.lastName) {
                  return (
                    <li key={member.id}>
                      <Text weight="500" size="medium">
                        {t('projects.admin_panel.owner_anonymous')}
                        {' - '}
                        {t('projects.admin_panel.owner_deleted_account')}
                      </Text>
                    </li>
                  )
                }
                return (
                  <li key={member.id}>
                    <Text
                      tag="a"
                      href={routeFor(USER_PUBLIC_PROFILE, {
                        user: member.slug,
                      })}
                      weight="500"
                      size="medium"
                      color="primary1"
                    >
                      {`${member.firstName} ${member.lastName}`}
                    </Text>
                  </li>
                )
              })}
            </div>
          </ul>
        ) : (
          <ul>
            <li>
              <Text
                tag="a"
                href={routeFor(USER_PUBLIC_PROFILE, { user: ownerSlug })}
                weight="500"
                size="medium"
                color="primary1"
              >
                {`${owner.firstName} ${owner.lastName}`}
              </Text>
            </li>
            <li>
              <Text weight="400" size="medium" color="font1">
                {owner.phone}
              </Text>
            </li>
            <li>
              <Text
                tag="a"
                href={`mailto:${owner.email}`}
                weight="500"
                size="medium"
                color="primary1"
              >
                {owner.email}
              </Text>
            </li>
          </ul>
        )}
      </DefinitionList.Description>
    </>
  )
}

export default Owner
