import React from 'react'
import { useSelector } from 'react-redux'

import { useTranslation } from 'kiss/hooks/use-translation'

import { Button, FlexWrapper, Text } from '@kisskissbankbank/kitten'
import { getProject } from 'kiss/modules/project-page/page-state/selectors'
import { ENGAGEMENT_BROWSING, getRouteFor } from 'kiss/routes/redux'

const Engagements = () => {
  const t = useTranslation()

  const routeFor = useSelector(getRouteFor)
  const project = useSelector(getProject)

  const { engagements } = project || {}

  if (!engagements || engagements.length === 0) return null

  return (
    <div>
      <Text
        tag="h3"
        size="micro"
        transform="uppercase"
        className="kiss-AsideTitle k-u-margin-top-none k-u-margin-bottom-noneHalf k-u-letter-spacing-10 k-u-flex k-u-flex-justifyContent-center@s-down"
      >
        {t('permanent_project.social.engagements.title')}
      </Text>
      <FlexWrapper
        direction="row"
        gap={10}
        className="k-u-margin-top-single k-u-flex-wrap-wrap k-u-flex-justifyContent-center@s-down"
      >
        {engagements.map(({ code }) => (
          <Button
            key={code}
            tag="a"
            fit="content"
            rounded
            size="micro"
            href={routeFor(ENGAGEMENT_BROWSING, {
              engagement: code,
            })}
          >
            {t(`engagement.${code}.name`, { parseHtml: true })}
          </Button>
        ))}
      </FlexWrapper>
    </div>
  )
}

export default Engagements
