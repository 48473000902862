import { getSSRHost } from 'kiss/app/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getProject } from 'kiss/modules/project-page/page-state/selectors'
import { getRouteFor, PROJECT_ROUTE } from 'kiss/routes/redux'
import { isStarted } from 'kiss/utils/project/state'
import { urlBuilder } from 'kiss/utils/responsive-image-handler'
import React from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'
import { useSelector } from 'react-redux'

const DAY = 86400

const Helmet = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const host = useSelector(getSSRHost)
  const project = useSelector(getProject)

  const defaultLanguage = project?.languages?.[0] || 'fr'

  const title = t('project.seo.title', {
    name: project?.name,
    username: project?.organization?.name || project?.owner?.name,
  })

  const noIndexMeta = !project?.isIndexable
    ? {
        name: 'robots',
        content: 'noindex, nofollow',
      }
    : {}

  const ogImageUrl = urlBuilder(project?.image?.croppedUrl, {
    width: 1200,
    filters: ['quality:90', 'format:jpeg'],
  })

  return (
    <ReactHelmet
      title={title}
      meta={[
        {
          name: 'description',
          content: project?.shortDesc || '',
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: project?.shortDesc || '',
        },
        {
          property: 'og:ttl',
          content: isStarted(project?.state) ? 4 * DAY : 28 * DAY,
        },
        {
          property: 'og:image',
          content: ogImageUrl,
        },
        {
          property: 'og:image:alt',
          content: project?.name,
        },
        {
          property: 'og:image:width',
          content: 1200,
        },
        {
          property: 'og:image:height',
          content: 750,
        },
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:description',
          content: project?.shortDesc || '',
        },
        {
          property: 'twitter:image',
          content: ogImageUrl,
        },
        {
          property: 'twitter:image:alt',
          content: project?.name,
        },
        {
          property: 'twitter:url',
          content: `${host}${routeFor(PROJECT_ROUTE, {
            project: project?.slug,
          })}`,
        },
        {
          property: 'twitter:site',
          content: '@Kissbankers',
        },
        noIndexMeta,
      ]}
      link={[
        {
          hreflang: 'fr',
          href: `${host}${routeFor(PROJECT_ROUTE, {
            project: project?.slug,
            locale: 'fr',
          })}`,
          rel: defaultLanguage === 'fr' ? 'canonical' : 'alternate',
        },
        {
          hreflang: 'en',
          href: `${host}${routeFor(PROJECT_ROUTE, {
            project: project?.slug,
            locale: 'en',
          })}`,
          rel: defaultLanguage === 'en' ? 'canonical' : 'alternate',
        },
      ]}
    />
  )
}

export default Helmet
