import { CheckboxButton, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import React, { useState } from 'react'
import Form from '../../billing-address/components/form'

const BillingAddress = ({
  onChangeBillingAddressSameAsDelivery = () => null,
}) => {
  const t = useTranslation()
  const [isBillingAddressSameAsDelivery, setIsBillingAddressSameAsDelivery] =
    useState(true)

  const onChange = () => {
    onChangeBillingAddressSameAsDelivery(!isBillingAddressSameAsDelivery)
    setIsBillingAddressSameAsDelivery(!isBillingAddressSameAsDelivery)
  }

  return (
    <>
      <Text
        tag="h1"
        size="large"
        weight="600"
        className="k-u-margin-bottom-double"
      >
        {t('contribute.billing_address.title')}
      </Text>
      <CheckboxButton
        size="small"
        checked={isBillingAddressSameAsDelivery}
        onChange={onChange}
        className="k-u-margin-bottom-triple"
      >
        {t('contribute.billing_address.same_as_delivery')}
      </CheckboxButton>
      {!isBillingAddressSameAsDelivery && <Form />}
    </>
  )
}

export default BillingAddress
