import React from 'react'
import { useSelector } from 'react-redux'
import {
  getCurrentUserSlug,
  getCurrentUserNeedsKyc,
  getCurrentUserIdentityAcceptedAt,
} from 'kiss/session/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, USER_PROFILE } from 'kiss/routes/redux'
import { Alert, Text } from '@kisskissbankbank/kitten'

const KycAlert = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const userSlug = useSelector(getCurrentUserSlug)
  const currentUserHasKycActived = useSelector(getCurrentUserNeedsKyc)
  const currentUserProfileIsChecked = useSelector(
    getCurrentUserIdentityAcceptedAt,
  )

  if (!currentUserHasKycActived || currentUserProfileIsChecked) return null

  return (
    <Alert status="warning" closeButton>
      <Text>
        {t('users.kyc.global_alert', {
          parseHtml: true,
          link: routeFor(USER_PROFILE, {
            user: userSlug,
          }),
        })}
      </Text>
    </Alert>
  )
}

export default KycAlert
