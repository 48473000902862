import { gql } from '@apollo/client'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import { useQuery } from '../api/graphql-query'

const GET_USER_INFOS = gql`
  query GetUserInfos($usersChatUsernames: [String!]) {
    currentUser {
      id
      chatRecipientsConnection(usersChatUsernames: $usersChatUsernames) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`

const useMessaging = ({ user }) => {
  const { data, loading } = useQuery(GET_USER_INFOS, {
    usersChatUsernames: [user?.chatUsername],
  })
  return {
    loading,
    canContact: !flow(
      get('currentUser.chatRecipientsConnection.edges'),
      isEmpty,
    )(data),
  }
}

export default useMessaging
