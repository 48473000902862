import query from 'kiss/api/graphql-query'
import createPartnershipContactRequest from 'kiss/graphql/mutations/partnerships/create_partnership_contact_request.graphql'

const NAME = 'PRISMIC_PAGE'

function reducer(state = {}, action) {
  switch (action.type) {
    default:
      return state
  }
}

export const getSlices = (state) => state?.[NAME]?.document?.slices

export const partnershipContactRequest = (values) => (_dispatch, getState) =>
  query(createPartnershipContactRequest, { ...values }, getState())

export default { [NAME]: reducer }
