import React from 'react'

import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { useTranslation } from 'kiss/hooks/use-translation'
import ErrorBoundary from 'kiss/app/layouts/error-boundary'
import { hasOrderLines as hasOrderLinesSelector } from 'kiss/modules/contribute/cart/redux'
import Title from 'kiss/modules/contribute/cart/components/title'
import NoResult from 'kiss/modules/contribute/cart/components/no-result'
import AddReward from 'kiss/modules/contribute/project/cart/components/add-reward'
import Donation from 'kiss/modules/contribute/project/cart/components/donation'
import Rewards from 'kiss/modules/contribute/project/cart/components/rewards'
import Tip from 'kiss/modules/contribute/project/cart/components/tip'

const Cart = () => {
  const hasOrderLines = useSelector(hasOrderLinesSelector)

  const t = useTranslation()

  return (
    <ErrorBoundary>
      <Helmet title={t('contribute.cart.head.title')} />
      <Title noResultTitle={t('cart.page.no_result_title')} />
      <Donation className="k-u-margin-bottom-singleHalf" />
      <Rewards />
      <NoResult
        className="k-u-margin-vertical-quadruple"
        description={t('cart.no_result.paragraph')}
        buttonText={t('cart.rewards.add_reward')}
      />
      {hasOrderLines && <Tip />}
      <AddReward />
    </ErrorBoundary>
  )
}

export default Cart
