import styled from 'styled-components'
import { pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'

const GUTTER = 20
const LATERAL_MARGIN_S = 20
const LATERAL_MARGIN_M = 20 + 60

export const imageProps = {
  breakpoints: [0, ScreenConfig.S.min, ScreenConfig.M.min],
  imageSizesInViewport: [
    `100vw - ${GUTTER * 2}px`,
    `(100vw - ${LATERAL_MARGIN_S * 2 + 60}px) * .4`,
    `(100vw - ${LATERAL_MARGIN_M * 2 + 60}px) * .4`,
  ],
  responsiveImageSizes: [190, 220, 275, 325, 375, 414],
}
export const imageProps_noForm = {
  breakpoints: [0, ScreenConfig.S.min],
  imageSizesInViewport: [`100vw - ${GUTTER * 2}px`, '360px'],
  responsiveImageSizes: [190, 220, 275, 325, 375, 414],
}

export const StyledBlock = styled.div`
  flex: 1 1 auto;
  display: flex;
  gap: ${pxToRem(25)} ${pxToRem(60)};

  @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
    && {
      flex-direction: column;
    }

    .k-RewardCard__imageWrapper {
      border-top-left-radius: var(--border-radius-m);
      border-top-right-radius: var(--border-radius-m);
      margin: ${pxToRem(-50)} ${pxToRem(-20)} 0;
      max-width: calc(100% + ${pxToRem(20 * 2)});
    }

    .kiss-RewardModal__content__card {
      flex: 1 0 auto;

      .k-RewardCard__gridWrapper {
        padding: 0;
        padding-top: ${pxToRem(10)};
      }
    }

    .kiss-RewardModal__content__form {
      flex: 1 0 auto;
    }
  }

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    && {
      padding: ${pxToRem(45)} 0;
      margin: 0;
    }
    align-items: flex-start;

    .kiss-RewardModal__content__card {
      --rewardCard--border-width: var(--border-width);
      --rewardCard--border-radius: var(--border-radius-m);
      flex: 0 1 40%;
      width: 40%;
    }

    .kiss-RewardModal__content__form {
      flex: 0 1 60%;
      width: 60%;
    }
  }

  &.kiss-RewardModal__contentBlock--noForm {
    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      justify-content: center;

      .kiss-RewardModal__content__card {
        flex-basis: ${pxToRem(360)};
        width: ${pxToRem(360)};
      }
    }
  }
`
