import React, { memo } from 'react'
import HeroWithVideo from './components/hero-with-video'
import TextWithVideo from './components/text-with-video'
import TextWithIcons from './components/text-with-icons'
import TextWithImageGrids from './components/text-with-image-grids'
import Title from './components/title'
import TextWithImage from './components/text-with-image'
import FullVideo from './components/full-video'
import FullImage from './components/full-image'
import Triptych from './components/triptych'
import FormWithImage from './components/form-with-image'
import Seo from './components/seo'
import TableOfContents from './components/table-of-contents'
import Text from './components/text'
import MediasList from './components/medias-list'

const Slices = ({ slices }) => {
  const getComponentBySlice = (slice, index) => {
    if (!slice) return null

    const key = `${slice.type}-${index}`

    switch (slice.type) {
      case 'hero_with_video':
        return <HeroWithVideo key={key} {...slice} />
      case 'text_with_video':
        return <TextWithVideo key={key} {...slice} />
      case 'text_with_icons':
        return <TextWithIcons key={key} {...slice} />
      case 'table_of_contents':
        return <TableOfContents key={key} {...slice} />
      case 'text_with_image_grids':
        return <TextWithImageGrids key={key} {...slice} />
      case 'title':
        return <Title key={key} {...slice} />
      case 'rich_text':
        return <Text key={key} {...slice} />
      case 'text_with_image':
        return <TextWithImage key={key} {...slice} />
      case 'full_video':
        return <FullVideo key={key} {...slice} />
      case 'full_image':
        return <FullImage key={key} {...slice} />
      case 'triptych':
        return <Triptych key={key} {...slice} />
      case 'form_with_image':
        return <FormWithImage key={key} {...slice} />
      case 'seo':
        return <Seo key={key} {...slice} />
      case 'medias_list':
        return <MediasList key={key} {...slice} />
      default:
        return null
    }
  }

  return slices.map((slice, index) => getComponentBySlice(slice, index))
}

export default memo(Slices)
