import { RewardCard } from '@kisskissbankbank/kitten'
import { Displayer } from '@kisskissbankbank/underwood'
import config from 'kiss/config'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import styled from 'styled-components'

const StyledDescription = styled.div`
  font-family: var(--font-family-generalsans);

  .public-DraftStyleDefault-ul:first-child,
  .public-DraftStyleDefault-block:first-child {
    margin-top: 0;
  }

  .DraftEditor-editorContainer {
    color: inherit !important;
  }
`

const Description = ({ reward, truncateText }) => {
  const t = useTranslation()

  return (
    <RewardCard.Description
      moreButtonText={t('reward_card.description.read_more')}
      truncateText={truncateText}
    >
      <StyledDescription>
        <Displayer
          text={reward.description}
          compact
          configResponsiveImageHandler={config[APP_ENV].responsiveImageHandler}
        />
      </StyledDescription>
    </RewardCard.Description>
  )
}

export default Description
