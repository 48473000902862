import { Alert, Text, WarningIcon } from '@kisskissbankbank/kitten'
import config from 'kiss/config'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getRouteFor,
  VERCEL_ORGANIZATION_MANAGER,
  VERCEL_PROJECT_MANAGER,
} from 'kiss/routes/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  getOrganizationSlug,
  getProjectSlug,
  needToShowKYCModal,
} from '../../../page-state/selectors'

const KycAlert = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const projectSlug = useSelector(getProjectSlug)
  const organization = useSelector(getOrganizationSlug)
  const showKycAlert = useSelector(needToShowKYCModal)

  const vercelManagerRoute = organization
    ? routeFor(VERCEL_ORGANIZATION_MANAGER, {
        organization,
        project: projectSlug,
      })
    : routeFor(VERCEL_PROJECT_MANAGER, {
        project: projectSlug,
      })

  if (!showKycAlert) return null

  return (
    <Alert status="warning" icon={<WarningIcon />} closeButton>
      <Text>
        {t('maker.flash.kyc_needed')}{' '}
        <Text
          tag="a"
          href={`${config[APP_ENV].manager.host}${vercelManagerRoute}/admin/payout`}
          onClick={(e) => {
            e.preventDefault()
            RoutingHelper.redirect(
              `${config[APP_ENV].manager.host}${vercelManagerRoute}/admin/payout`,
            )
          }}
          className="k-u-cursor-pointer"
        >
          {t('maker.flash.button.link')}
        </Text>
      </Text>
    </Alert>
  )
}

export default KycAlert
