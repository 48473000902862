import { domElementHelper } from '@kisskissbankbank/kitten'
import React, { createContext, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useMediaQuery } from '../../../../../media-queries/redux'
import {
  PROJECT_NAVIGATION_ABOUT_ROUTE,
  PROJECT_NAVIGATION_FAQS_TAB_ROUTE,
  PROJECT_NAVIGATION_NEWS_TAB_ROUTE,
} from '../../../../../routes/redux'
import { ABOUT_ID, FAQ_ID, NEWS_ID, REWARDS_ID } from '../../constants/id'
import { scrollTo } from '../../helpers/scroll-to'

export const ScrollContext = createContext({})

const ClientSideScroll = ({ children, hash }) => {
  const [activeElement, setActiveElement] = useState(hash || REWARDS_ID)

  const ids = [
    `#${REWARDS_ID}`,
    `#${ABOUT_ID}`,
    `#${NEWS_ID}`,
    `#${FAQ_ID}`,
  ].join(',')

  const sections = document.querySelectorAll(ids)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveElement(entry.target.id)
          }
        })
      },
      {
        root: window.document,
        rootMargin: '-15% 0px -60%',
        threshold: 0.001,
      },
    )

    sections.forEach((section) => {
      observer.observe(section)
    })
    return () => {
      sections.forEach((section) => {
        observer.unobserve(section)
      })
    }
  }, [])

  return (
    <ScrollContext.Provider
      value={{
        activeElement,
      }}
    >
      {children}
    </ScrollContext.Provider>
  )
}

export const ScrollProvider = ({ children, hash }) => {
  const isFaqsRoute = useRouteMatch(PROJECT_NAVIGATION_FAQS_TAB_ROUTE)
  const isNewsRoute = useRouteMatch(PROJECT_NAVIGATION_NEWS_TAB_ROUTE)
  const isAboutRoute = useRouteMatch(PROJECT_NAVIGATION_ABOUT_ROUTE)
  const { viewportIsMOrLess } = useMediaQuery()
  useEffect(() => {
    if (isFaqsRoute) {
      scrollTo(FAQ_ID, viewportIsMOrLess, 'auto')
    }
    if (isNewsRoute) {
      scrollTo(NEWS_ID, viewportIsMOrLess, 'auto')
    }
    if (isAboutRoute) {
      scrollTo(ABOUT_ID, viewportIsMOrLess, 'auto')
    }
  }, [isFaqsRoute, isNewsRoute, isAboutRoute])
  if (!domElementHelper.canUseDom() || !('IntersectionObserver' in window)) {
    return (
      <ScrollContext.Provider value={{}}>{children}</ScrollContext.Provider>
    )
  }
  return (
    <ClientSideScroll hash={hash || REWARDS_ID}>{children}</ClientSideScroll>
  )
}
