import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getComments,
  getCommentsTotalCount,
  loadMoreProjectComments,
} from '../redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import LoadMoreButton from 'kiss/components/project/button-load-more'

const LoadMore = () => {
  const comments = useSelector(getComments)
  const commentsTotalCount = useSelector(getCommentsTotalCount)
  const dispatch = useDispatch()
  const t = useTranslation()

  const [loadingMore, setLoadingMore] = useState(false)

  useEffect(() => {
    setLoadingMore(false)
  }, [comments])

  if (commentsTotalCount <= comments.length) return null

  const handleLoadMore = () => {
    setLoadingMore(true)
    dispatch(loadMoreProjectComments())
  }

  return (
    <LoadMoreButton
      className="k-u-margin-top-double k-u-margin-horizontal-auto k-u-block"
      loading={loadingMore}
      label={t('project_backers.load_more')}
      onClick={handleLoadMore}
    />
  )
}

export default LoadMore
