import { RewardCard } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getCurrency,
  isRewardDisabled,
} from 'kiss/modules/project-page/page-state/selectors'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import Button from './components/button'
import Description from './components/description'
import HeadingTag from './components/heading-tag'
import Image from './components/image'
import Infos from './components/infos'
import Tags from './components/tags'

const SubscriptionCard = ({
  reward,
  'data-test-id': dataTestId,
  buttonProps,
  displayButton,
  imageProps,
  truncateText,
  showShippingCost,
  isProjectNotPublished,
  isUnavailable,
  truncateTitle,
  isDisabled,
  withButton,
  ...props
}) => {
  const t = useTranslation()
  const isCardDisabled = useSelector(isRewardDisabled)(reward)

  const currencySymbol = t(`currency_symbol.${useSelector(getCurrency)}`)
  const amount = t(reward.amount, { formatNumber: true })

  const formattedAmount = t('project_info.amount_with_currency', {
    amount,
    currency: currencySymbol,
    parseHtml: true,
  })

  const period = t(
    reward?.period === 'monthly'
      ? 'permanent_project.text.subscription_card.monthly'
      : 'permanent_project.text.subscription_card.quarterly',
  )

  return (
    <RewardCard
      data-test-id={dataTestId}
      disabled={isDisabled || isUnavailable || isCardDisabled}
      {...props}
    >
      {reward.image && (
        <RewardCard.Image>
          <Image reward={reward} imageProps={imageProps} />
        </RewardCard.Image>
      )}

      <HeadingTag
        reward={reward}
        isProjectNotPublished={isProjectNotPublished}
        isUnavailable={isUnavailable}
      />

      <RewardCard.Contents>
        {reward.label && (
          <RewardCard.Title
            truncateText={truncateTitle}
            className="k-u-weight-600"
          >
            {reward.label}
          </RewardCard.Title>
        )}

        <RewardCard.Amount suffix={period}>{formattedAmount}</RewardCard.Amount>

        <Tags reward={reward} />

        <Description reward={reward} truncateText={truncateText} />

        <Infos reward={reward} showShippingCost={showShippingCost} />
      </RewardCard.Contents>

      {withButton && (
        <Button
          reward={reward}
          buttonProps={buttonProps}
          displayButton={displayButton}
        />
      )}
    </RewardCard>
  )
}

SubscriptionCard.propTypes = {
  reward: PropTypes.object.isRequired,
  'data-test-id': PropTypes.string,
  buttonProps: PropTypes.object,
  displayButton: PropTypes.bool,
  imageProps: PropTypes.object,
  truncateText: PropTypes.bool,
  showShippingCost: PropTypes.bool,
  isProjectNotPublished: PropTypes.bool,
  truncateTitle: PropTypes.bool,
  withButton: PropTypes.bool,
}

SubscriptionCard.defaultProps = {
  'data-test-id': '',
  buttonProps: {},
  displayButton: true,
  imageProps: {},
  truncateText: false,
  showShippingCost: false,
  isProjectNotPublished: false,
  truncateTitle: true,
  withButton: true,
}

export default SubscriptionCard
