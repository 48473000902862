import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getPageColors,
  getProjectState,
} from 'kiss/modules/project-page/page-state/selectors'
import { isNotPublished } from 'kiss/utils/project/state'

import Action from 'kiss/modules/components/reward-modal/desktop/action'
import BackingCard from 'kiss/components/cards/backing-card'
import Form from 'kiss/modules/components/reward-modal/form'
import FormContent from 'kiss/modules/project-page/campaign/components/reward-modal/desktop/form-content'
import { Modal } from '@kisskissbankbank/kitten'

import {
  imageProps,
  StyledBlock,
} from 'kiss/modules/components/reward-modal/desktop'

import PropTypes from 'prop-types'

const DesktopModal = ({ reward, ...props }) => {
  const t = useTranslation()
  const pageColors = useSelector(getPageColors)

  const projectState = useSelector(getProjectState)
  const isProjectNotPublished = isNotPublished(projectState)

  return (
    <Modal isOpen closeButtonLabel={t('global.close')} size="large" {...props}>
      {() => (
        <StyledBlock style={pageColors}>
          <BackingCard
            reward={reward}
            className="kiss-RewardModal__content__card"
            imageProps={imageProps}
            displayButton={false}
            truncateTitle={false}
          />
          <div className="kiss-RewardModal__content__form">
            <Form reward={reward}>
              {({ handleSubmit, isSubmitting }) => {
                return (
                  <FormContent reward={reward}>
                    <Action
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                      buttonText={t('project.modal.submit')}
                      disabled={isProjectNotPublished}
                    />
                  </FormContent>
                )
              }}
            </Form>
          </div>
        </StyledBlock>
      )}
    </Modal>
  )
}

DesktopModal.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default DesktopModal
