import React from 'react'
import isEmpty from 'lodash/fp/isEmpty'
import NumberFormat from 'react-number-format'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useField, useFormikContext } from 'formik'
import Label from 'kiss/components/formik/label'
import { withCvv } from './card-type'
import { Field, TextInput } from '@kisskissbankbank/kitten'

const Cvv = () => {
  const t = useTranslation()
  const [field, meta] = useField({
    name: 'cvv',
    validate: (value) => {
      if (withCvv(values.cardNumber) && isEmpty(value)) {
        return t('payment_choice.errors.required')
      }
    },
  })
  const { values } = useFormikContext()
  const error = meta.touched && meta.error

  if (!withCvv(values.cardNumber)) return null

  return (
    <div className="k-u-margin-bottom-quadruple">
      <Label htmlFor="frmCCCVC">{t('payment_choice.cvv')}</Label>

      <NumberFormat
        {...field}
        format="###"
        inputMode="numeric"
        placeholder="CVV"
        id="frmCCCVC"
        autoComplete="cc-csc"
        error={!!error}
        customInput={TextInput}
        fit="fluid"
      />

      {!!error && <Field.ErrorMessage>{error}</Field.ErrorMessage>}
    </div>
  )
}

export default Cvv
