import filter from 'lodash/fp/filter'
import first from 'lodash/fp/first'
import flatten from 'lodash/fp/flatten'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import keys from 'lodash/fp/keys'
import last from 'lodash/fp/last'
import map from 'lodash/fp/map'
import pickBy from 'lodash/fp/pickBy'

export const getLastRoomMessageEvent = flow(
  get('timeline'),
  filter(({ event }) => event.type === 'm.room.message'),
  last,
)

export const isAtTheBottomLine = (container, element) => {
  const listGap = 20
  const avergageMinHeightMessage = 60
  const bottomContainer = container?.getBoundingClientRect()?.bottom + listGap
  const topElement = element?.getBoundingClientRect()?.top ?? 0
  // we tolerate a minor scroll
  return Math.abs(bottomContainer - topElement) < avergageMinHeightMessage
}

export const getUsersChatUsernames = (rooms) => {
  const myUserId = flow(first, get('myUserId'))(rooms)
  const usersChatUsernames = flow(
    map(({ currentState }) => keys(currentState?.userIdsToDisplayNames)),
    flatten,
  )(rooms)
  return filter((userId) => userId !== myUserId)(usersChatUsernames)
}

export const getChatUsernameForName = (room) => {
  return flow(
    pickBy((value) => value === room?.name),
    keys,
    first,
  )(room?.currentState?.userIdsToDisplayNames)
}

export const getFormattedUsers = flow(
  get('data.currentUser.chatRecipientsConnection.edges'),
  map(({ node }) => node),
)
