import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getOrganizationOrOwnerName } from 'kiss/modules/project-page/page-state/selectors'

import {
  AvatarWithTextAndBadge,
  Paragraph,
  pxToRem,
  ScreenConfig,
  stepToRem,
  Text,
} from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledText = styled(Text)`
  display: block;
  line-height: 1.3;
  font-size: ${stepToRem(-2)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    font-size: ${stepToRem(-1)};
  }
`

const Avatar = ({ children }) => {
  const t = useTranslation()

  const representativeName = useSelector(getOrganizationOrOwnerName)

  return (
    <AvatarWithTextAndBadge>
      <Paragraph modifier="tertiary" noMargin>
        {t('project_hero.project.by')}
      </Paragraph>
      <AvatarWithTextAndBadge.Text>
        <StyledText weight="500" className="k-u-link k-u-link-primary1">
          {representativeName}
        </StyledText>
      </AvatarWithTextAndBadge.Text>
      {children}
    </AvatarWithTextAndBadge>
  )
}

export default Avatar
