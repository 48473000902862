import React from 'react'
import PropTypes from 'prop-types'
import ResetPasswordForm from './form'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Text, Modal } from '@kisskissbankbank/kitten'

const ResetPasswordLink = ({ email }) => {
  const t = useTranslation()

  return (
    <Modal
      trigger={
        <Text
          tag="button"
          type="button"
          className="k-u-reset-button k-u-link k-u-link-primary1"
          size="small"
          weight="500"
          lineHeight="normal"
          data-test-id="reset-password-link"
        >
          {t('authenticate.login.form.link.password.new')}
        </Text>
      }
    >
      <ResetPasswordForm email={email} />
    </Modal>
  )
}

ResetPasswordLink.propTypes = {
  email: PropTypes.string.isRequired,
}

export default ResetPasswordLink
