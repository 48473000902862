import React from 'react'
import ProjectCardInfo from './info'
import { useTranslation } from 'kiss/hooks/use-translation'

const ProjectCardCollectedAmountInfo = ({ collectedAmount, goalAmount }) => {
  const t = useTranslation()
  const amount = goalAmount?.cents / 100 || 0
  const fundedAmount = collectedAmount.cents / 100 || 0
  const currency = goalAmount?.currency
  const translatedCurrency = t(`currency_symbol.${currency}`)

  const goal = t('browsing.project_card.amount_with_currency', {
    amount: t(amount, { formatNumber: true }),
    currency: translatedCurrency,
    parseHtml: true,
  })

  return (
    <ProjectCardInfo
      value={t('browsing.project_card.amount_with_currency', {
        amount: t(fundedAmount, { formatNumber: true }),
        currency: translatedCurrency,
        parseHtml: true,
      })}
      text={t('browsing.project_card.funded_amount', {
        goal_with_currency: goal,
      })}
    />
  )
}

export default ProjectCardCollectedAmountInfo
