import React from 'react'
import PropTypes from 'prop-types'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { Button } from '@kisskissbankbank/kitten'

const Action = ({
  isSubmitting,
  handleSubmit,
  buttonText,
  disabled,
  ...props
}) => {
  const buttonProps = {
    fit: 'fluid',
    modifier: 'helium',
    type: 'button',
    ...props,
  }

  return isSubmitting ? (
    <Button {...buttonProps}>
      <LoadingAnimation color="var(--color-grey-000)" />
    </Button>
  ) : (
    <Button {...buttonProps} disabled={disabled} onClick={handleSubmit}>
      {buttonText}
    </Button>
  )
}

Action.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

Action.defaultProps = {
  isSubmitting: false,
  disabled: false,
}

export default Action
