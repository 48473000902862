import { mq, pxToRem, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import React from 'react'
import styled from 'styled-components'
import Category from './category'
import Location from './location'
import Sort from './sort'
import State from './state'

const StyledFiltersWrapper = styled.div`
  position: relative;
  z-index: ${Z_INDEX.global.filterModal};

  display: grid;
  gap: ${pxToRem(10)};
  align-items: center;

  @media ${mq.desktop} {
    grid-template-columns:
      auto
      ${pxToRem(200)}
      ${pxToRem(170)}
      ${pxToRem(220)}
      1fr
      auto
      ${pxToRem(140)};
    grid-template-areas: 'display_text
                         category
                         state
                         location
                         .
                         sort_text
                         sort';
  }
  @media ${mq.tablet} {
    grid-template-columns: auto repeat(3, ${pxToRem(180)}) 1fr;
    grid-template-areas:
      'display_text category state location ...'
      'sort_text    sort     ..... ........ ...';
  }
  .kiss-filters__display_text {
    grid-area: display_text;
    white-space: nowrap;
  }
  .kiss-filters__category {
    grid-area: category;
  }
  .kiss-filters__state {
    grid-area: state;
  }
  .kiss-filters__location {
    grid-area: location;
  }
  .kiss-filters__sort_text {
    grid-area: sort_text;
    white-space: nowrap;
  }
  .kiss-filters__sort {
    grid-area: sort;
  }

  // fix Autocomplete n'a pas la prop size
  .kiss-filters__location input {
    height: ${pxToRem(40)};
  }
`

const Filters = ({ className }) => {
  const t = useTranslation()
  return (
    <StyledFiltersWrapper className={className}>
      <Text size="small" weight="500" className="kiss-filters__display_text">
        {t('browsing.fitler.show')}
      </Text>
      <Category className="kiss-filters__category" />
      <State className="kiss-filters__state" />
      <Location className="kiss-filters__location" />
      <Text size="small" weight="500" className="kiss-filters__sort_text">
        {t('browsing.fitler.order')}
      </Text>
      <Sort className="kiss-filters__sort" />
    </StyledFiltersWrapper>
  )
}

export default Filters
