import React from 'react'

import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Form, Formik } from 'formik'

import {
  AlertBox,
  Button,
  FlexWrapper,
  GridCol,
  pxToRem,
  ScreenConfig,
  Text,
} from '@kisskissbankbank/kitten'

import {
  addErrorAlert,
  addSuccessAlert,
  removeErrorAlerts,
} from 'kiss/app/alerts/redux'

import SubmitLoader from 'kiss/components/buttons/submit-loader'
import SimpleText from 'kiss/components/formik/simple-text'
import { scrollToTop } from 'kiss/utils/animation/scroll-to'
import { getProjectUuid } from 'kiss/modules/contribute/redux'
import { createComment } from 'kiss/modules/contribute/thankyou/redux'

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  @media screen and (max-width: ${ScreenConfig.S.max}px) {
    flex-direction: column;
    gap: ${pxToRem(8)};
    align-items: inherit;
  }
  > div {
    flex-grow: 2;
  }
`
const Comment = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const projectUuid = useSelector(getProjectUuid)
  return (
    <GridCol offset-xs="0" col-m="10" offset-m="1" col-l="6" offset-l="3">
      <FlexWrapper gap={pxToRem(16)}>
        <Text className="k-u-align-center">
          {t('contribute.thank_you.comment.title', { parseHtml: true })}
        </Text>
        <Formik
          initialValues={{ comment: '' }}
          onSubmit={({ comment }, { resetForm }) => {
            dispatch(removeErrorAlerts())
            return dispatch(createComment(projectUuid, comment))
              .then(() => {
                dispatch(
                  addSuccessAlert(t('contribute.thank_you.alert_success')),
                )
                scrollToTop(200)
                resetForm()
              })
              .catch(() => {
                dispatch(addErrorAlert(t('app.server.error')))
                scrollToTop(200)
              })
          }}
        >
          {({ values, isSubmitting }) => (
            <StyledForm>
              <div className="k-u-flex-direction-column k-u-margin-right-single@s-up">
                <SimpleText
                  name="comment"
                  type="textarea"
                  rows="1"
                  placeholder={t('contribute.thank_you.comment.placeholder')}
                  aria-label={t('contribute.thank_you.comment.aria_label')}
                />
              </div>

              {isSubmitting ? (
                <SubmitLoader size="medium" />
              ) : (
                <Button
                  type="submit"
                  modifier="helium"
                  disabled={!values.comment}
                >
                  {t('contribute.thank_you.comment.button.label')}
                </Button>
              )}
            </StyledForm>
          )}
        </Formik>
        <AlertBox status="info">
          <Text as="p" className="k-u-margin-bottom-single">
            {t('contribute.thank_you.header.info_1', { parseHtml: true })}
          </Text>
          <Text as="p">
            {t('contribute.thank_you.header.info_2', { parseHtml: true })}
          </Text>
        </AlertBox>
      </FlexWrapper>
    </GridCol>
  )
}

export default Comment
