import React from 'react'
import { useSelector } from 'react-redux'
import { isCountGoalType as isCountGoalTypeSelector } from 'kiss/modules/project-page/page-state/selectors'
import CollectedCountInfo from './collected-count-info'
import CollectedAmountInfo from './collected-amount-info'

const CollectingInfo = () => {
  const isCountGoalType = useSelector(isCountGoalTypeSelector)

  return isCountGoalType ? <CollectedCountInfo /> : <CollectedAmountInfo />
}

export default CollectingInfo
