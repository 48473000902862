import query from 'kiss/api/graphql-query'
import likeNewsQuery from 'kiss/graphql/mutations/project/like_news.graphql'
import unlikeNewsQuery from 'kiss/graphql/mutations/project/unlike_news.graphql'
import { UPDATE_NEWS } from 'kiss/modules/project-page/page-state/redux'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import newsQuery from 'kiss/graphql/queries/project/news.graphql'
import sharedNewsQuery from 'kiss/graphql/queries/news/news.graphql'

const refreshNews =
  ({ slug, sharedNewsId }) =>
  async (dispatch, getState) => {
    const state = getState()
    let response
    try {
      if (sharedNewsId) {
        response = await query(
          sharedNewsQuery,
          { projectSlug: slug, id: sharedNewsId },
          state,
          {
            fetchPolicy: 'no-cache',
          },
        )
      } else {
        response = await query(newsQuery, { slug }, state, {
          fetchPolicy: 'no-cache',
        })
      }

      dispatch({
        type: UPDATE_NEWS,
        payload: {
          newsConnection: response.project.newsConnection,
        },
      })
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const like =
  ({ newsId, slug, sharedNewsId = null }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(likeNewsQuery, { newsId }, state)

      dispatch(refreshNews({ slug, sharedNewsId }))
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const unlike =
  ({ newsId, slug, sharedNewsId = null }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(unlikeNewsQuery, { newsId }, state)

      dispatch(refreshNews({ slug, sharedNewsId }))
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }
