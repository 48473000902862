export const RoutingHelper = {
  redirect: (url) => {
    window.location.href = url
  },
  formatWithParams(route, params) {
    const routeParams = route.match(/(:[\w]+)/g)

    if (!routeParams) return route

    return routeParams.reduce((acc, value) => {
      const placeholder = new RegExp(value)
      const paramName = value.replace(':', '')

      if (!params[paramName]) {
        return acc
      }

      return acc.replace(placeholder, params[paramName])
    }, route)
  },
}
