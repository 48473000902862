import React from 'react'
import styled from 'styled-components'
import { getAssetFor } from 'kiss/assets/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import classNames from 'classnames'
import {
  Container,
  Grid,
  GridCol,
  pxToRem,
  ScreenConfig,
  Text,
  Title,
  LaBanquePostaleLogo,
} from '@kisskissbankbank/kitten'

const StyledContainer = styled(Container)`
  .kiss-FundMyProject__PartnersSection__logos {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    @media (min-width: ${ScreenConfig.S.min}px) {
      height: ${pxToRem(70)};
    }
  }

  .kiss-FundMyProject__PartnersSection__logoCnil {
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-size: cover;
    width: ${pxToRem(79)};
    height: ${pxToRem(70)};
    margin: 0 auto;
  }
`

const PartnersSection = () => {
  const assetFor = useSelector(getAssetFor)
  const t = useTranslation()

  return (
    <StyledContainer>
      <Grid>
        <GridCol col-l="6" offset-l="3" col-s="8" offset-s="2">
          <div
            className={classNames(
              'k-u-margin-bottom-quadruple@m-down',
              'k-u-margin-bottom-quintuple@s-up',
            )}
          >
            <Title
              tag="h2"
              noMargin
              modifier="senary"
              className="k-u-align-center"
            >
              {t('fund_my_project.partners.title', {
                parseHtml: true,
              })}
            </Title>
          </div>
        </GridCol>

        <GridCol col-l="6" offset-l="3">
          <Grid>
            <GridCol col-s="4" className="k-u-align-center">
              <figure
                className={classNames(
                  'k-u-margin-bottom-quadruple@m-down',
                  'k-u-margin-bottom-none@s-up',
                )}
              >
                <div
                  style={{
                    '--background-image': `url(${assetFor(
                      'maker/fund_my_project/partners/cnil.jpg',
                    )})`,
                  }}
                  className="kiss-FundMyProject__PartnersSection__logoCnil"
                  title={t('fund_my_project.partners.partner1.image_alt')}
                />
                <div className="k-u-margin-top-singleHalf">
                  <figcaption>
                    <Text
                      color="font1"
                      size="small"
                      weight="400"
                      lineHeight="normal"
                    >
                      {t('fund_my_project.partners.partner1.text')}
                    </Text>
                  </figcaption>
                </div>
              </figure>
            </GridCol>
            <GridCol col-s="4" className="k-u-align-center">
              <figure
                className={classNames(
                  'k-u-margin-bottom-quadruple@m-down',
                  'k-u-margin-bottom-none@s-up',
                )}
              >
                <div className="kiss-FundMyProject__PartnersSection__logos">
                  <LaBanquePostaleLogo
                    variant="on-light"
                    width="130"
                    height="130"
                  />
                </div>
                <div className="k-u-margin-top-singleHalf">
                  <figcaption>
                    <Text
                      color="font1"
                      size="small"
                      weight="400"
                      lineHeight="normal"
                    >
                      {t('fund_my_project.partners.partner2.text')}
                    </Text>
                  </figcaption>
                </div>
              </figure>
            </GridCol>
            <GridCol col-s="4" className="k-u-align-center">
              <figure
                className={classNames(
                  'k-u-margin-bottom-quadruple@m-down',
                  'k-u-margin-bottom-none@s-up',
                )}
              >
                <div className="kiss-FundMyProject__PartnersSection__logos">
                  <img
                    src={assetFor(
                      'maker/fund_my_project/partners/mangopay.svg',
                    )}
                    alt={t('fund_my_project.partners.partner3.image_alt')}
                    width="210"
                    height="41"
                  />
                </div>
                <div className="k-u-margin-top-singleHalf">
                  <figcaption>
                    <Text
                      color="font1"
                      size="small"
                      weight="400"
                      lineHeight="normal"
                    >
                      {t('fund_my_project.partners.partner3.text')}
                    </Text>
                  </figcaption>
                </div>
              </figure>
            </GridCol>
          </Grid>
        </GridCol>
      </Grid>
    </StyledContainer>
  )
}

export default PartnersSection
