import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'kiss/hooks/use-translation'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import {
  Grid,
  GridCol,
  TitleWithStroke,
  Button,
  Paragraph,
} from '@kisskissbankbank/kitten'
import {
  getCartId,
  getTotalCurrency,
  hasGiftCardCode,
  getRedeemedAmountCents,
} from 'kiss/modules/contribute/cart/redux'
import { useHistory } from 'react-router-dom'
import { getRouteFor, THANK_YOU } from 'kiss/routes/redux'
import { getProjectSlug } from 'kiss/modules/contribute/redux'
import { useMutation } from 'kiss/api/graphql-query'
import confirmOrderMutation from 'kiss/graphql/mutations/contribute/confirm_order.graphql'
import { addErrorAlert } from 'kiss/app/alerts/redux'

const Confirm = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const [isLoading, loading] = useState(false)
  const [confirmOrder] = useMutation(confirmOrderMutation)
  const routeFor = useSelector(getRouteFor)
  const projectSlug = useSelector(getProjectSlug)
  const hasGiftCard = useSelector(hasGiftCardCode)
  const redeemedAmountCents = useSelector(getRedeemedAmountCents)
  const totalCurrency = useSelector(getTotalCurrency)
  const orderId = useSelector(getCartId)
  const history = useHistory()
  const formattedAmount = t('project_info.amount_with_currency', {
    amount: t(redeemedAmountCents / 100 || 0, { formatNumber: { decimal: 2 } }),
    currency: t(`currency_symbol.${totalCurrency}`),
    parseHtml: true,
  })

  const handleClick = async () => {
    loading(true)

    try {
      await confirmOrder({ variables: { orderId } })

      history.push(
        `${routeFor(THANK_YOU, { project: projectSlug })}?uuid=${orderId}`,
      )
    } catch (_error) {
      loading(false)
      dispatch(addErrorAlert(t('app.server.error'), { scroll: true }))
    }
  }

  return (
    <Grid>
      <GridCol col-l="10">
        <Helmet title={t('contribute.payment_choice.head.title')} />

        <TitleWithStroke modifier="quaternary">
          {t('contribute.payment_choice.confirm_view.title')}
        </TitleWithStroke>

        {hasGiftCard && (
          <Paragraph
            modifier="tertiary"
            className="k-u-margin-bottom-quadruple"
          >
            {t('contribute.payment_choice.confirm_view.paragraph', {
              amount: formattedAmount,
            })}
          </Paragraph>
        )}

        {isLoading ? (
          <SubmitLoader disabled fit="fluid" />
        ) : (
          <Button
            type="button"
            modifier="helium"
            size="large"
            fit="fluid"
            onClick={handleClick}
          >
            {t('contribute.payment_choice.confirm_view.button')}
          </Button>
        )}
      </GridCol>
    </Grid>
  )
}

export default Confirm
