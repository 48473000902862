import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import { RocketCircleIcon } from 'kiss/components/icons/rocket-icon'
import {
  getProjectState,
  getProjectExtratimeState,
} from 'kiss/modules/project-page/page-state/selectors'
import {
  CheckedCircleIcon,
  CrossCircleIcon,
  pxToRem,
  ScreenConfig,
  stepToRem,
  Text,
} from '@kisskissbankbank/kitten'

const StyledState = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background: var(--color-grey-000);
  padding: ${pxToRem(10)};
  padding-right: ${pxToRem(20)};

  .kiss-Intro-ProjectState__icon {
    width: ${pxToRem(30)};
    margin-right: ${pxToRem(10)};

    @media (max-width: ${ScreenConfig.XS.max}px) {
      width: ${pxToRem(20)};
    }
  }

  .kiss-Intro-ProjectState__text {
    font-size: ${stepToRem(-2)};

    @media (min-width: ${ScreenConfig.M.min}px) {
      font-size: ${stepToRem(-1)};
    }
  }
`

const ProjectState = () => {
  const projectState = useSelector(getProjectState)
  const isExtratime = useSelector(getProjectExtratimeState)
  const t = useTranslation()

  if (
    projectState !== 'successful' &&
    projectState !== 'failed' &&
    !isExtratime
  )
    return null

  return (
    <StyledState>
      {projectState === 'successful' && (
        <CheckedCircleIcon
          className="kiss-Intro-ProjectState__icon"
          circleColor="var(--color-success-500)"
          checkedColor="var(--color-grey-000)"
        />
      )}

      {projectState === 'failed' && (
        <CrossCircleIcon
          className="kiss-Intro-ProjectState__icon"
          circleColor="var(--color-grey-400)"
          crossColor="var(--color-grey-000)"
        />
      )}

      {isExtratime && (
        <RocketCircleIcon
          className="kiss-Intro-ProjectState__icon"
          circleColor="var(--color-success-500)"
          illustrationColor="var(--color-grey-000)"
        />
      )}

      <Text
        className="kiss-Intro-ProjectState__text"
        color="font1"
        weight="500"
      >
        {t(
          isExtratime
            ? 'project_hero.project.extratime_badge'
            : `project_state.${projectState}`,
        )}
      </Text>
    </StyledState>
  )
}

export default ProjectState
