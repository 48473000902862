import React from 'react'
import PropTypes from 'prop-types'
import Shipping from '../shipping'
import CommonFormContent from 'kiss/modules/components/reward-modal/mobile/content-with-form'

const ContentWithForm = ({ reward, ...props }) => {
  return (
    <CommonFormContent reward={reward} {...props}>
      <Shipping reward={reward} />
    </CommonFormContent>
  )
}

ContentWithForm.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default ContentWithForm
