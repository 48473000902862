import React from 'react'
import DefinitionList from 'kiss/modules/project-page/campaign/components/toolbars/admin-panel/definition-list'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import {
  getCurrentUserPermissions,
  getProjectState,
  getSuspendedAt,
} from 'kiss/modules/project-page/page-state/selectors'
import FeedbacksForm from 'kiss/modules/project-page/campaign/components/toolbars/admin-panel/feedbacks-form'
import { Text } from '@kisskissbankbank/kitten'

const Feedbacks = () => {
  const t = useTranslation()
  const currentUserPermissions = useSelector(getCurrentUserPermissions)
  const state = useSelector(getProjectState)
  const suspendedAt = useSelector(getSuspendedAt)

  const { canAccept, canReject, canMarkAsToImprove } = currentUserPermissions
  const canDisplayForm = () => {
    if (canAccept) return true
    if (canReject) return true
    if (canMarkAsToImprove) return true

    return false
  }

  const projectStateText = suspendedAt
    ? t('projects.admin_panel.suspended_project')
    : t(`project.states.${state}`)

  return (
    <>
      <DefinitionList.Title>
        <Text weight="600" size="medium" color="font1">
          {t('projects.admin_panel.feedbacks_form.title')}
        </Text>
      </DefinitionList.Title>
      <DefinitionList.Description>
        {canDisplayForm() && <FeedbacksForm />}
        {!canDisplayForm() && (
          <Text weight="400" size="medium" color="valid">
            {projectStateText}
          </Text>
        )}
      </DefinitionList.Description>
    </>
  )
}

export default Feedbacks
