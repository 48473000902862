import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { LiftOff } from 'kiss/components/images/lift-off'
import {
  ScreenConfig,
  FlexWrapper,
  GridCol,
  Title,
  pxToRem,
  domElementHelper,
} from '@kisskissbankbank/kitten'
import FacebookPixelProvider from 'kiss/utils/tracking/facebook-pixel/provider'
import FacebookPixelTracker from 'kiss/utils/tracking/facebook-pixel/tracker'
import JSConfetti from 'js-confetti'
import { getRawPageColors } from 'kiss/modules/contribute/redux'
import { useSelector } from 'react-redux'

const FlexWrapperContainer = styled(FlexWrapper)`
  position: relative;
  z-index: 3;
  align-items: center;
  gap: ${pxToRem(40)};
  @media screen and (max-width: ${ScreenConfig.S.max}px) {
    gap: ${pxToRem(20)};
  }
`

const ConfettiCanvas = styled.canvas`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;

  @media (prefers-reduced-motion: reduce) {
    display: none;
  }
`

const ThanksHeader = ({ title }) => {
  const { primary_300, primary_500, primary_700 } =
    useSelector(getRawPageColors)
  const [confetti, setConfetti] = useState(null)
  const canvas = useRef(null)

  const confettiProps = {
    confettiColors: [
      primary_300 || '#68b7ff',
      primary_500 || '#1f93ff',
      primary_700 || '#0073de',
    ],
    confettiRadius: 10,
    confettiNumber: 120,
  }

  const secondConfettiProps = {
    emojis: ['❤️', '🎉'],
    emojiSize: 80,
    confettiNumber: 20,
  }

  useEffect(() => {
    if (domElementHelper.canUseDom() && !confetti && !!canvas) {
      const confetti = new JSConfetti({ canvas: canvas.current })
      setConfetti(confetti)
      confetti.addConfetti(confettiProps)
      confetti.addConfetti(secondConfettiProps)
    }
  }, [confetti, canvas])

  const handleClick = () => {
    if (confetti) {
      confetti.addConfetti(confettiProps)
      confetti.addConfetti(secondConfettiProps)
    }
  }

  return (
    <GridCol offset-xs="0" col-m="10" offset-m="1" col-l="6" offset-l="3">
      <FacebookPixelProvider>
        <FacebookPixelTracker eventName="purchase" />
      </FacebookPixelProvider>
      <ConfettiCanvas ref={canvas} aria-hidden />
      <FlexWrapperContainer>
        <button
          className="k-u-reset-button"
          onClick={handleClick}
          title="Confetti"
          aria-hidden
        >
          <LiftOff width="95" height="95" />
        </button>
        <Title
          modifier="tertiary"
          data-test-id="thank-you-title"
          className="
              k-u-align-center
              k-u-margin-left-none"
        >
          {title}
        </Title>
      </FlexWrapperContainer>
    </GridCol>
  )
}

export default ThanksHeader
