import { pxToRem, Text } from '@kisskissbankbank/kitten'
import React from 'react'
import classNames from 'classnames'

const ExplainLine = ({ children, icon, className }) => {
  return (
    <div
      className={classNames(
        'k-u-flex k-u-flex-alignItems-center k-u-margin-top-double',
        className,
      )}
    >
      <div style={{ minWidth: pxToRem(36) }}>
        {React.createElement(icon, {
          height: 24,
          color: 'var(--color-grey-700)',
          secondaryColor: 'var(--color-grey-700)',
          'aria-hidden': true,
        })}
      </div>
      {children}
    </div>
  )
}
ExplainLine.Text = ({ children }) => (
  <Text tag="div" size="small" weight="400" cssColor="var(--color-grey-700)">
    {children}
  </Text>
)

ExplainLine.Text.displayName = 'ExplainLineText'

export default ExplainLine
