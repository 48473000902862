import query from 'kiss/api/graphql-query'
import addVariationToCartQuery from 'kiss/graphql/mutations/cart/add_variation_to_cart.graphql'
import removeVariationFromCartQuery from 'kiss/graphql/mutations/cart/remove_variation_from_cart.graphql'
import updateCartDonationQuery from 'kiss/graphql/mutations/cart/update_cart_donation.graphql'
import updateContributionQuery from 'kiss/graphql/mutations/cart/update_contribution.graphql'
import updateCartTipQuery from 'kiss/graphql/mutations/cart/update_cart_tip.graphql'

export const addVariationByApi = (values, state) => {
  return query(addVariationToCartQuery, values, state).then(
    (response) => response.addVariationToCart,
  )
}

export const removeVariationByApi = (values, state) => {
  return query(removeVariationFromCartQuery, values, state).then(
    (response) => response.removeVariationFromCart,
  )
}

export const updateDonationByApi = (
  { donationCents, recurring, projectSlug },
  state,
) => {
  return query(
    updateCartDonationQuery,
    { cents: donationCents, recurring, projectSlug },
    state,
  ).then((response) => response.updateCartDonation)
}

export const updateContributionByApi = (values, state) => {
  return query(updateContributionQuery, values, state).then(
    (response) => response.updateContribution,
  )
}

export const updateTipByApi = (values, state) => {
  return query(updateCartTipQuery, values, state).then(
    (response) => response.updateCartTip,
  )
}
