import { Container, mq, pxToRem } from '@kisskissbankbank/kitten'
import {
  CONTRIBUTE_SIGN_IN,
  CONTRIBUTE_SIGN_UP,
  getRouteFor,
} from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Login from '../../../authenticate/components/login'
import { getCookie } from '../../cart/redux'
import useContributeStepper from '../../hooks/use-stepper'
import { getProjectSlug } from '../../redux'

const SignInWrapper = styled.div`
  width: 100%;
  padding: ${pxToRem(40)};
  border-radius: var(--border-radius-l);
  box-shadow: var(--box-shadow-l);
  @media ${mq.desktop} {
    width: ${pxToRem(400)};
  }
`

const ContributeLogin = () => {
  const routeFor = useSelector(getRouteFor)
  const authenticateReturnTo = getCookie('authenticateReturnTo')
  const projectSlug = useSelector(getProjectSlug)
  const { nextRoute } = useContributeStepper()
  const nextUrl =
    authenticateReturnTo || routeFor(nextRoute, { project: projectSlug })

  return (
    <Container>
      <div className="k-u-flex k-u-flex-justifyContent-center">
        <SignInWrapper>
          <Login
            isSPA
            signInRoute={CONTRIBUTE_SIGN_IN}
            signUpRoute={CONTRIBUTE_SIGN_UP}
            routeParams={{ project: projectSlug }}
            externalReturnTo={nextUrl}
          />
        </SignInWrapper>
      </div>
    </Container>
  )
}

export default ContributeLogin
