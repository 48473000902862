import React from 'react'
import { Helmet } from 'react-helmet'
import ErrorBoundary from 'kiss/app/layouts/error-boundary'
import { useTranslation } from 'kiss/hooks/use-translation'
import Title from '../../cart/components/title'
import NoResult from '../../cart/components/no-result'
import RecurringDonation from './components/recurring-donation'
import SubscriptionReward from './components/subscription-reward'

const Cart = () => {
  const t = useTranslation()

  return (
    <ErrorBoundary>
      <Helmet title={t('contribute.cart.head.title')} />

      <Title
        noResultTitle={t('contribute.cart.subscriptions.no_result_title')}
      />
      <RecurringDonation className="k-u-margin-bottom-singleHalf" />
      <SubscriptionReward />
      <NoResult
        className="k-u-margin-vertical-quadruple"
        description={t('contribute.cart.subscriptions.no_result_description', {
          parseHtml: true,
        })}
        buttonText={t('contribute.cart.subscriptions.no_result_button')}
      />
    </ErrorBoundary>
  )
}

export default Cart
