import { gql } from '@apollo/client'
import { Container, Grid, GridCol } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { useQuery } from 'kiss/api/graphql-query'
import { useTranslation } from 'kiss/hooks/use-translation'
import SignIn from 'kiss/modules/contribute/auth/sign-in'
import SignUp from 'kiss/modules/contribute/auth/sign-up'
import { getProjectSlug, isPolling } from 'kiss/modules/contribute/redux'
import {
  CARD_PAYMENT_FAILURE,
  CART,
  CONTRIBUTE,
  CONTRIBUTE_LOGIN,
  CONTRIBUTE_SIGN_IN,
  CONTRIBUTE_SIGN_UP,
  getRouteFor as getRouteForSelector,
  IDENTITY_CHECK,
  PAYMENT_CHOICES,
  PAYMENT_CHOICES_RETURN,
  PROJECT_ROUTE,
  SHIPPING_ADDRESS,
  THANK_YOU,
} from 'kiss/routes/redux'
import { isLogged as isLoggedSelector } from 'kiss/session/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import Login from '../auth/login'
import CardPaymentFailure from '../card-failure'
import { hasDonation } from '../cart/redux'
import IdentityCheck from '../identity-check'
import Polling from '../polling'
import ShippingAddress from '../shipping-address'
import Cart from './cart'
import PermanentCartSummary from './components/summary/cart'
import FlowSummary from './components/summary/flow'
import PaymentChoices from './payment-choices'
import ThankYou from './thankyou'

const GET_PROJECT_SUBSCRIPTIONS = gql`
  query GetProjectSubscriptions($slug: String!) {
    project(ref: $slug) {
      id
      rewards {
        id
        currentUser {
          isBacking
        }
      }
    }
  }
`

const ContributionFlowContainer = ({ children, wideContent = false }) => {
  const slug = useSelector(getProjectSlug)
  const { data } = useQuery(GET_PROJECT_SUBSCRIPTIONS, {
    slug,
  })

  const currentUserIsBacking = data?.project?.rewards?.find(
    (reward) => reward.currentUser?.isBacking,
  )

  const isDonation = useSelector(hasDonation)
  const isLogged = useSelector(isLoggedSelector)
  const getRouteFor = useSelector(getRouteForSelector)

  useEffect(() => {
    if (isLogged && currentUserIsBacking && !isDonation) {
      RoutingHelper.redirect(
        getRouteFor(`${PROJECT_ROUTE}?already_subscribe=true`, {
          project: slug,
        }),
      )
    }
  }, [isLogged, currentUserIsBacking])

  return (
    <Container>
      <Grid>
        <GridCol
          className={classNames('k-ContributionFlow__grid', {
            'k-ContributionFlow--wideContent': wideContent,
          })}
          col-xs="10"
          offset-xs="1"
          col-l="12"
          offset-l="0"
          col-xl="11"
          offset-xl="1"
        >
          <div className="k-ContributionFlow__ContentCol">{children}</div>
          <div className="k-ContributionFlow__SummaryCol">
            <Route path={[CONTRIBUTE]} exact>
              <div className="k-u-margin-top-nonuple@l-up">
                <PermanentCartSummary />
              </div>
            </Route>
            <Route
              path={[
                SHIPPING_ADDRESS,
                PAYMENT_CHOICES,
                CONTRIBUTE_LOGIN,
                CONTRIBUTE_SIGN_IN,
                CONTRIBUTE_SIGN_UP,
              ]}
            >
              <FlowSummary />
            </Route>
          </div>
        </GridCol>
      </Grid>
    </Container>
  )
}

const PermanentContributeFlow = ({ pageColors }) => {
  const t = useTranslation()
  const isPollingActive = useSelector(isPolling)
  const isDonation = useSelector(hasDonation)

  return (
    <section style={pageColors}>
      <Switch>
        <Route exact path={CONTRIBUTE_LOGIN}>
          <Login />
        </Route>
        <Route exact path={CONTRIBUTE_SIGN_IN}>
          <SignIn />
        </Route>
        <Route exact path={CONTRIBUTE_SIGN_UP}>
          <SignUp
            title={
              isDonation
                ? t('contribute.authenticate.title.signup_recurring_donation')
                : t('contribute.authenticate.title.signup_subscription')
            }
          />
        </Route>
        <Route exact path={CART}>
          <ContributionFlowContainer wideContent>
            <Cart />
          </ContributionFlowContainer>
        </Route>
        <Route path={SHIPPING_ADDRESS}>
          <ContributionFlowContainer>
            <ShippingAddress />
          </ContributionFlowContainer>
        </Route>
        <Route path={IDENTITY_CHECK}>
          <ContributionFlowContainer>
            <IdentityCheck />
          </ContributionFlowContainer>
        </Route>
        <Route exact path={PAYMENT_CHOICES}>
          {isPollingActive ? (
            <Polling />
          ) : (
            <ContributionFlowContainer>
              <PaymentChoices />
            </ContributionFlowContainer>
          )}
        </Route>
        <Route exact path={PAYMENT_CHOICES_RETURN}>
          <Polling />
        </Route>
        <Route exact path={THANK_YOU}>
          <ThankYou />
        </Route>
        <Route exact path={CARD_PAYMENT_FAILURE}>
          <CardPaymentFailure />
        </Route>
      </Switch>
    </section>
  )
}

export default PermanentContributeFlow
