import React, { createContext, useEffect, useRef, useState } from 'react'

export const CloseReplyFormsContext = createContext({})

export const CloseReplyFormsProvider = ({ children }) => {
  const [idFormOpen, setIdFormOpen] = useState(false)
  const [shouldCloseForms, setShouldCloseForms] = useState(false)

  const timeoutRef = useRef()

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = undefined
    },
    [],
  )

  useEffect(() => {
    if (shouldCloseForms) {
      setShouldCloseForms(false)
    }
  }, [shouldCloseForms])

  return (
    <CloseReplyFormsContext.Provider
      value={{
        idFormOpen,
        setIdFormOpen,
        shouldCloseForms,
        setShouldCloseForms,
      }}
    >
      {children}
    </CloseReplyFormsContext.Provider>
  )
}
