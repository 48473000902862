import { Container } from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import { Helmet } from 'react-helmet'
import { SearchProvider } from './context'
import Filters from './filters'
import MobileFilter from './filters/mobile'
import Pagination from './pagination'
import Projects from './projects'

const Search = () => {
  const t = useTranslation()
  return (
    <SearchProvider>
      <DefaultLayout>
        <Helmet
          title={t('search.page.title')}
          meta={[
            {
              name: 'description',
              content: t('search.page.description'),
            },
          ]}
        />
        <Container>
          <MobileFilter className="k-u-margin-top-triple k-u-hidden@m-up" />
          <Filters className="k-u-margin-top-quintuple k-u-hidden@s-down" />
          <Projects className="k-u-margin-top-triple" />
          <Pagination className="k-u-margin-vertical-quintuple" />
        </Container>
      </DefaultLayout>
    </SearchProvider>
  )
}

export default Search
