import alertsReducer from 'kiss/app/alerts/redux'
import * as Assets from 'kiss/assets/redux'
import mediaQueriesReducer from 'kiss/media-queries/redux'
import browsingReducer from 'kiss/modules/browsing/redux'
import campaignTipsReducer from 'kiss/modules/communication/campaign-tips/redux'
import fundMyProjectSubpageReducer from 'kiss/modules/communication/fund-my-project/redux'
import prismicPreviewReducer from 'kiss/modules/communication/prismic-preview/redux'
import contributeReducer from 'kiss/modules/contribute/redux'
import * as HomePage from 'kiss/modules/home/redux'
import mentorPageReducer from 'kiss/modules/mentor-page/redux'
import mentorsReducer from 'kiss/modules/mentors/redux'
import prismicPageReducer from 'kiss/modules/prismic-page/redux'
import projectPageReducer from 'kiss/modules/project-page/page-state/redux'
import searchReducer from 'kiss/modules/search/redux'
import userChangeCardPolling from 'kiss/modules/subscription-modal/components/change-card-polling/redux'
import sessionReducer from 'kiss/session/redux'
import { ReducerHelper } from 'kiss/utils/reducer-helper'

const plainReducerEntries = [
  'railsContext',
  'locale',
  'translations',
  'UPDATE_CARD_POLLING',
  'WIDGET',
  'STATS_PAGE',
  'PRICING_PLANS',
  HomePage.NAME,
  Assets.NAME,
]

export const rootReducer = {
  ...ReducerHelper.attachPlainReducer(plainReducerEntries),
  ...browsingReducer,
  ...alertsReducer,
  ...prismicPageReducer,
  ...contributeReducer,
  ...fundMyProjectSubpageReducer,
  ...campaignTipsReducer,
  ...searchReducer,
  ...sessionReducer,
  ...mediaQueriesReducer,
  ...projectPageReducer,
  ...prismicPreviewReducer,
  ...mentorPageReducer,
  ...userChangeCardPolling,
  ...mentorsReducer,
}
