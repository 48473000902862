import usePaySubscription from './calls/pay-subscription'
import usePaylineTokenization from './calls/payline-tokenization'
import useCallRegisterCard from './calls/register-card'
import { getPrefix } from './config/card-type'

const useSubscriptionPayment = ({
  userId,
  projectId,
  orderId,
  cartState,
  amountCents,
}) => {
  const { callRegisterCard } = useCallRegisterCard()
  const { callPaylineTokenization } = usePaylineTokenization()
  const { callPaySubscription } = usePaySubscription()
  return {
    paySubscription: async ({ cardNumber, cvv, expiry }) => {
      const cardType = getPrefix(cardNumber)
      const cardRegistration = await callRegisterCard({
        orderId,
        cardType,
        userId,
        cartState,
      })
      const registrationData = await callPaylineTokenization({
        cardNumber,
        cvv,
        expiry,
        cardRegistration,
        amountCents,
      })
      return await callPaySubscription({
        projectId,
        orderId,
        cardRegistrationUuid: cardRegistration.uuid,
        registrationData,
      })
    },
  }
}

export default useSubscriptionPayment
