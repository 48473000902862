/*eslint no-console:0*/
import { domElementHelper } from '@kisskissbankbank/kitten'

if (domElementHelper.canUseDom() && 'serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope)
    })
    .catch(function (error) {
      console.log('Service worker registration failed, error:', error)
    })
}
