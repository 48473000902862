import {
  Container,
  Grid,
  GridCol,
  Paragraph,
  Timeline,
  Title,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import FundMyProjectButton from './fund-my-project-button'

const TimelineSection = ({ paragraphProps }) => {
  const t = useTranslation()

  return (
    <Container>
      <Grid>
        <GridCol col-l="4" offset-l="1" style={{ alignSelf: 'center' }}>
          <Title
            tag="h2"
            family="antiqueolive"
            modifier="tertiary"
            noMargin
            className="k-u-align-center@m-down"
          >
            {t('fund_my_project.timeline.title', {
              parseHtml: true,
            })}
          </Title>
          <div
            className={classNames(
              'k-u-margin-top-quadruple',
              'k-u-align-left',
              'k-u-hidden@m-down',
            )}
          >
            <FundMyProjectButton
              buttonText={t('fund_my_project.timeline.button')}
            />
          </div>
        </GridCol>

        <GridCol col-l="5" offset-l="1" col-s="10" offset-s="1">
          <div className="k-u-margin-top-triple@l-down">
            <Timeline itemHeight="thin" color="var(--color-primary-500)">
              <Paragraph {...paragraphProps} modifier="secondary">
                {t('fund_my_project.timeline.item1')}
              </Paragraph>
              <Paragraph {...paragraphProps} modifier="secondary">
                {t('fund_my_project.timeline.item2')}
              </Paragraph>
              <Paragraph {...paragraphProps} modifier="secondary">
                {t('fund_my_project.timeline.item3')}
              </Paragraph>
              <Paragraph {...paragraphProps} modifier="secondary">
                {t('fund_my_project.timeline.item4')}
              </Paragraph>
            </Timeline>
          </div>
        </GridCol>

        <GridCol className="k-u-align-center k-u-hidden@l-up">
          <div className="k-u-margin-top-quadruple">
            <FundMyProjectButton
              buttonText={t('fund_my_project.timeline.button')}
            />
          </div>
        </GridCol>
      </Grid>
    </Container>
  )
}

export default TimelineSection
