import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { scrollToTop } from 'kiss/utils/animation/scroll-to'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'

import {
  CONTRIBUTE_FOOTER_ID,
  getOrganizationOrOwnerName,
  getPageColors,
  getProjectName,
  getProjectSlug,
  hasDonationLine,
  hasRecurringDonation,
  isPermanentProject,
  SCROLL_SPEED,
} from 'kiss/modules/contribute/redux'
import {
  CONTRIBUTE,
  CONTRIBUTE_SIGN_IN,
  CONTRIBUTE_SIGN_UP,
  getRouteFor as getRouteForSelector,
  PROJECT_ROUTE,
} from 'kiss/routes/redux'
import {
  isLogged as isLoggedSelector,
  sessionFetched as sessionFetchedSelector,
} from 'kiss/session/redux'

import {
  HEADER_HEIGHT,
  pxToRem,
  ScreenConfig,
  Text,
} from '@kisskissbankbank/kitten'

import Alerts from 'kiss/app/alerts/alerts'
import Footer from 'kiss/app/footer'
import Header from 'kiss/app/header'
import PageLayout from 'kiss/app/layouts/page-layout'

import { LoaderWithParagraph } from 'kiss/components/loading/loader-with-paragraph'
import CartChecker from './components/cart-checker'
import SubHeader from './components/sub-header'
import PermanentContributeFlow from './permanent'
import ProjectContributeFlow from './project'

import styled from 'styled-components'

const MOBILE_FOOTER_HEIGHT = 115
const DESKTOP_FOOTER_HEIGHT = 150
const PROGRESS_HEIGHT = 4
const SUBHEADER_HEIGHT = 80

const MOBILE_PADDING = 50
const DESKTOP_PADDING_TOP = 80
const DESKTOP_PADDING_BOTTOM = 100

const ContributionLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: calc(100vh - ${pxToRem(HEADER_HEIGHT)});
`

const ContributionFlow = styled.div`
  min-height: calc(
    100vh -
      ${pxToRem(
        HEADER_HEIGHT +
          PROGRESS_HEIGHT +
          SUBHEADER_HEIGHT +
          MOBILE_FOOTER_HEIGHT +
          2 * MOBILE_PADDING,
      )}
  );
  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    min-height: calc(
      100vh -
        ${pxToRem(
          HEADER_HEIGHT +
            PROGRESS_HEIGHT +
            SUBHEADER_HEIGHT +
            DESKTOP_FOOTER_HEIGHT +
            DESKTOP_PADDING_TOP +
            DESKTOP_PADDING_BOTTOM,
        )}
    );
  }
  .k-ContributionFlow__grid {
    display: grid;
    grid-gap: ${pxToRem(20)} ${pxToRem(230)};

    &.k-ContributionFlow--wideContent {
      grid-gap: ${pxToRem(20)} ${pxToRem(60)};
    }

    .k-ContributionFlow__SummaryCol {
      overflow: hidden;
    }

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      grid-template-columns: 1fr ${pxToRem(350)};

      .k-ContributionFlow__SummaryCol {
        align-self: start;
        position: sticky;
        overflow: visible;
        top: ${pxToRem(DESKTOP_PADDING_TOP)};
      }
    }
  }
`

const StyledHeaderContainer = styled.div`
  margin: 0 calc(-1 * (25vw - ${pxToRem(70)}));
  text-align: center;

  .kiss-Header__ProjectName {
    display: block;
    max-height: 2.5em;
    line-height: 1.2em;
    overflow: hidden;
  }
`

const ContributeFlow = () => {
  const [isRedirecting, setRedirecting] = useState(false)
  const t = useTranslation()
  const isPermanent = useSelector(isPermanentProject)
  const isRecurring = useSelector(hasRecurringDonation)
  const hasDonation = useSelector(hasDonationLine)
  const sessionFetched = useSelector(sessionFetchedSelector)
  const isLogged = useSelector(isLoggedSelector)
  const projectSlug = useSelector(getProjectSlug)
  const getRouteFor = useSelector(getRouteForSelector)
  const projectName = useSelector(getProjectName)
  const representativeName = useSelector(getOrganizationOrOwnerName)
  const pageColors = useSelector(getPageColors)

  const { pathname } = useLocation()
  const { viewportIsSOrLess } = useMediaQuery()

  const isAuthenticatePath = useRouteMatch({
    path: [CONTRIBUTE_SIGN_IN, CONTRIBUTE_SIGN_UP],
    exact: true,
  })

  const needPermanentFlow = () => {
    if (isPermanent) {
      if (hasDonation) {
        return isRecurring
      } else {
        return true
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    if (!sessionFetched) return

    if (isLogged && isAuthenticatePath) {
      setRedirecting(true)
      RoutingHelper.redirect(getRouteFor(CONTRIBUTE, { project: projectSlug }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionFetched])

  useEffect(() => {
    scrollToTop(SCROLL_SPEED)
  }, [pathname])

  return (
    <PageLayout>
      <Helmet
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <Header
        navigation={false}
        withoutBurger
        withoutRight
        tinyLogo
        isSticky="never"
      >
        <StyledHeaderContainer>
          <Text
            tag="a"
            color="font1"
            size={viewportIsSOrLess ? 'small' : 'medium'}
            weight="500"
            lineHeight="normal"
            className="k-u-link kiss-Header__ProjectName"
            href={getRouteFor(PROJECT_ROUTE, { project: projectSlug })}
          >
            {projectName}
          </Text>
          <Text
            color="font1"
            tag="div"
            size="small"
            lineHeight="normal"
            weight="400"
            className="k-u-hidden@xs-down"
          >
            {t('cart.sub_header.project_owner', {
              project_owner: representativeName,
            })}
          </Text>
        </StyledHeaderContainer>
      </Header>
      <Alerts />
      <main id="main">
        {isRedirecting && (
          <ContributionLoader>
            <LoaderWithParagraph
              loaderPosition="bottom"
              paragraphProps={{ modifier: 'primary' }}
            >
              {t('contribute.authenticate.redirection')}
            </LoaderWithParagraph>
          </ContributionLoader>
        )}
        {!isRedirecting && (
          <>
            <CartChecker />
            <SubHeader
              style={pageColors}
              className="k-u-margin-bottom-quintuple k-u-margin-bottom-octuple@s-up"
            />
            <ContributionFlow>
              {needPermanentFlow() ? (
                <PermanentContributeFlow pageColors={pageColors} />
              ) : (
                <ProjectContributeFlow pageColors={pageColors} />
              )}
            </ContributionFlow>

            <Footer id={CONTRIBUTE_FOOTER_ID} keepTopMargin minimalist />
          </>
        )}
      </main>
    </PageLayout>
  )
}

export default ContributeFlow
