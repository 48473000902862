import { Button, pxToRem, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getRouteFor,
  PROJECT_ROUTE,
  AUTHENTICATE_ROUTE,
} from 'kiss/routes/redux'
import { isStarted } from 'kiss/utils/project/state'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import orderBy from 'lodash/fp/orderBy'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { isLogged } from 'kiss/session/redux'
import { useMediaQuery } from '../../../../../../media-queries/redux'
import {
  getProjectImageCropped,
  getProjectRewards,
  getProjectState,
} from '../../../../page-state/selectors'
import DesktopModal from '../../../components/reward-modal/desktop'
import Mobile from '../../../components/reward-modal/mobile'
import { REWARDS_ID } from '../../../constants/id'
import { scrollTo } from '../../../helpers/scroll-to'

const LockedNewsWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: ${pxToRem(225)};
  overflow: hidden;

  .kiss-lockedNews__blur-layer {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: ${pxToRem(225)};
    background-size: cover;
    background-image: var(--background-image);
    filter: blur(${pxToRem(20)});
  }

  .kiss-lockedNews__opacity-layer {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: ${pxToRem(225)};
    background: rgba(0, 0, 0, 0.4);
  }
`

const cheapestRewardId = flow(
  map(({ node }) => ({
    id: node.id,
    price: node.price.cents,
    soldOut: node.soldOut,
  })),
  filter({ soldOut: false }),
  orderBy(['price'], ['asc']),
  first,
  get('id'),
)

const LockedNews = ({ rewardsVisibility, visibility }) => {
  const [showRewardModal, setShowRewardModal] = useState(false)
  const t = useTranslation()
  const { project, id } = useParams()
  const routeFor = useSelector(getRouteFor)
  const userIsLogged = useSelector(isLogged)
  const imageUrl = useSelector(getProjectImageCropped)
  const projectState = useSelector(getProjectState)
  const rewards = useSelector(getProjectRewards)
  const { viewportIsMOrLess } = useMediaQuery()
  const rewardToApply = find({ id: cheapestRewardId(rewardsVisibility) })(
    rewards,
  )

  const renderButton = () => {
    if (!isStarted(projectState)) return null

    if (id && visibility !== 'rewards')
      return (
        <Button
          fit="content"
          modifier="helium"
          tag="a"
          href={routeFor(PROJECT_ROUTE, { project })}
        >
          {t(`comments.locked.subscription.button`)}
        </Button>
      )

    return (
      <Button
        fit="content"
        modifier="helium"
        onClick={() => {
          if (visibility === 'rewards' && rewardToApply) {
            setShowRewardModal(true)
          } else {
            scrollTo(REWARDS_ID, viewportIsMOrLess)
          }
        }}
      >
        {t(`comments.locked.subscription.button`)}
      </Button>
    )
  }
  return (
    <>
      <LockedNewsWrapper style={{ '--background-image': `url('${imageUrl}')` }}>
        <div className="kiss-lockedNews__blur-layer" />
        <div className="kiss-lockedNews__opacity-layer" />
        <Text
          size="large"
          color="background1"
          weight="600"
          className="k-u-margin-bottom-double k-u-margin-horizontal-double k-u-align-center"
        >
          {isStarted(projectState)
            ? t('comments.locked.subscription.label', { parseHtml: true })
            : t('comments.locked.ended.label', { parseHtml: true })}
        </Text>
        {renderButton()}
        {!userIsLogged && (
          <Text
            tag="a"
            href={routeFor(AUTHENTICATE_ROUTE)}
            color="background1"
            size="small"
            className="k-u-margin-top-double k-u-align-center"
          >
            {t('comments.locked.news.login', { parseHtml: true })}
          </Text>
        )}
      </LockedNewsWrapper>
      {showRewardModal &&
        (viewportIsMOrLess ? (
          <Mobile
            reward={rewardToApply}
            modalProps={{
              onAfterClose: () => setShowRewardModal(false),
            }}
          />
        ) : (
          <DesktopModal
            reward={rewardToApply}
            modalProps={{
              onAfterClose: () => setShowRewardModal(false),
            }}
          />
        ))}
    </>
  )
}

export default LockedNews
