import React from 'react'
import styled from 'styled-components'
import {
  Container,
  Grid,
  GridCol,
  TitleWithStroke,
  parseHtml,
  pxToRem,
  stepToRem,
  Text,
  mq,
} from '@kisskissbankbank/kitten'
import getTitleId from 'kiss/utils/get-title-id'

const StyledGrid = styled(Grid)`
  align-items: center;

  img {
    width: 100%;
    display: block;
  }

  .kiss-FullImage__legend {
    font-size: ${stepToRem(-2)};
    text-align: center;
    line-height: ${pxToRem(22)};

    @media ${mq.tabletAndDesktop} {
      font-size: ${stepToRem(-1)};
    }
  }
`

const FullImage = ({ image, legend, title }) => {
  return (
    <Container>
      <StyledGrid>
        <GridCol col-xs="10" offset-xs="1" col-m="8" offset-m="2">
          {title.text && (
            <TitleWithStroke
              id={getTitleId(title.text)}
              tag={`h${title.level}`}
              modifier="tertiary"
              align="center"
            >
              {title.text}
            </TitleWithStroke>
          )}

          <figure>
            <img loading="lazy" src={image.url} alt={image.alt} />

            {legend && (
              <Text
                className="kiss-FullImage__legend k-u-margin-top-double"
                tag="figcaption"
                color="font1"
                weight="600"
              >
                {parseHtml(legend, { sanitize: false })}
              </Text>
            )}
          </figure>
        </GridCol>
      </StyledGrid>
    </Container>
  )
}

export default FullImage
