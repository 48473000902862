import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import styled from 'styled-components'
import {
  getOrganizationOrOwnerAvatarNormalUrl,
  getProjectName,
  getProjectImageUrl,
  getPageColors,
  getShortDesc,
} from '../../page-state/selectors'
import EmailSection from './components/email-section'
import SuccessSection from './components/success-section'
import {
  pxToRem,
  Title,
  mq,
  CONTAINER_PADDING,
  CONTAINER_PADDING_THIN,
  HEADER_HEIGHT,
  Text,
  HorizontalStroke,
} from '@kisskissbankbank/kitten'
import { scrollTo } from 'kiss/utils/animation/scroll-to'
import AdminPanel from '../components/toolbars/admin-panel'
import OwnerPanel from '../components/toolbars/owner-panel'

const PageWrapper = styled.div`
  min-height: calc(100vh - ${pxToRem(HEADER_HEIGHT)});
  box-sizing: border-box;
  padding: ${pxToRem(75)} ${pxToRem(CONTAINER_PADDING_THIN)};
  background: linear-gradient(
    var(--color-grey-000) -${pxToRem(HEADER_HEIGHT)},
    var(--color-primary-300)
  );
  border: var(--border-light);
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${mq.tabletAndDesktop} {
    padding: ${pxToRem(150)} ${pxToRem(CONTAINER_PADDING)};
  }

  .kiss-PreLaunch__box {
    position: relative;
    border-radius: var(--border-radius-m);
    box-shadow: var(--box-shadow-xl);
    padding: ${pxToRem(100)} ${pxToRem(20)} ${pxToRem(30)};
    background: var(--color-grey-000);
    width: min(100%, ${pxToRem(670)});
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(30)};
    align-items: center;
    text-align: center;

    @media ${mq.tabletAndDesktop} {
      padding: ${pxToRem(100)} ${pxToRem(50)} ${pxToRem(50)};
    }
  }

  .kiss-PreLaunch__box__avatar {
    --avatar-size: ${pxToRem(100)};
    position: absolute;
    left: 0;
    right: 0;
    top: calc(-1 * var(--avatar-size) / 2);

    @media ${mq.tabletAndDesktop} {
      --avatar-size: ${pxToRem(140)};
    }

    img {
      box-sizing: border-box;
      border: ${pxToRem(10)} solid var(--color-grey-000);
      border-radius: var(--border-radius-rounded);
      box-shadow: var(--box-shadow-s);
      width: var(--avatar-size);
      height: var(--avatar-size);
    }
  }

  .kiss-PreLaunch__content {
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(20)};

    @media ${mq.tabletAndDesktop} {
      gap: ${pxToRem(30)};
    }
  }

  .kiss-PreLaunch__success-section {
    gap: ${pxToRem(15)};
  }

  .kiss-PreLaunch__form {
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(10)};
  }

  .kiss-PreLaunch__box__image img {
    aspect-ratio: 16 / 10;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    display: block;
  }

  .kiss-PreLaunch__box__title,
  .kiss-PreLaunch__box__slogan {
    margin: 0;
  }
`
const Prelaunch = () => {
  const [isEmailSent, sendEmail] = useState(false)
  const t = useTranslation()
  const avatarUrl = useSelector(getOrganizationOrOwnerAvatarNormalUrl)
  const projectName = useSelector(getProjectName)
  const projectImage = useSelector(getProjectImageUrl)
  const projectSlogan = useSelector(getShortDesc)
  const pageColors = useSelector(getPageColors)

  useEffect(() => {
    if (isEmailSent) scrollTo(document.getElementById('prelaunch-container'))
  }, [isEmailSent])

  return (
    <>
      <OwnerPanel />
      <AdminPanel />
      <PageWrapper id="prelaunch-container" style={pageColors}>
        <section className="kiss-PreLaunch__box">
          <div className="kiss-PreLaunch__content">
            <picture className="kiss-PreLaunch__box__avatar">
              <img src={avatarUrl} alt="" />
            </picture>
            {isEmailSent ? (
              <SuccessSection />
            ) : (
              <>
                <Text
                  size="small"
                  className="k-u-letter-spacing-10"
                  transform="uppercase"
                >
                  {t('prelaunch.title', { parseHtml: true })}
                </Text>
                <Title
                  modifier="tertiary"
                  className="kiss-PreLaunch__box__title"
                >
                  {projectName}
                </Title>
                {projectImage && (
                  <picture className="kiss-PreLaunch__box__image">
                    <img src={projectImage} alt="" />
                  </picture>
                )}

                <Text
                  tag="p"
                  lineHeight="normal"
                  className="kiss-PreLaunch__box__slogan"
                >
                  {projectSlogan}
                </Text>
              </>
            )}
          </div>
          {!isEmailSent && (
            <>
              <HorizontalStroke align="center" />
              <EmailSection onSuccess={() => sendEmail(true)} />
            </>
          )}
        </section>
      </PageWrapper>
    </>
  )
}

export default Prelaunch
