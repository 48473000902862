import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getSlices } from './redux'
import Slices from './slices'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { mq, pxToRem } from '@kisskissbankbank/kitten'
import styled from 'styled-components'

const SlicesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${pxToRem(50)};
  padding-block: ${pxToRem(80)};

  & > div {
    width: 100%;
  }

  @media ${mq.tabletAndDesktop} {
    gap: ${pxToRem(80)};
    padding-block: ${pxToRem(100)};
  }
`

const PrismicPage = (props) => {
  const slicesFromSelector = useSelector(getSlices)
  const slices = props.slices || slicesFromSelector

  return (
    <DefaultLayout>
      <SlicesWrapper>
        <Slices slices={slices} />
      </SlicesWrapper>
    </DefaultLayout>
  )
}

PrismicPage.propTypes = {
  slices: PropTypes.array,
}

export default PrismicPage
