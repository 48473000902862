import { ProjectCard, Text } from '@kisskissbankbank/kitten'
import classeNames from 'classnames'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import { isStarted } from '../../../../utils/project/state'
import BackersCountInfo from '../components/backers-count-info'
import ProjectCardCollectingInfo from '../components/collecting-info'
import EndDateInfo from '../components/end-date-info'
import FundingPercentInfo from '../components/funding-percent-info'
import StateInfo from '../components/state-info'

const CampaignLines = ({ project, forceDesktop = false }) => {
  const {
    state,
    inExtratime,
    endAt,
    ordersConnection,
    fundingPercent,
    collectedAmount,
    collectedCount,
    goalAmount,
    goalType,
    goalCount,
    organization,
    owner,
  } = project
  const t = useTranslation()
  return (
    <>
      <ProjectCard.Line>
        <Text size="micro">
          {t('app.project_card.by')}{' '}
          <strong className="k-u-weight-600">
            {organization?.name || owner?.username}
          </strong>
        </Text>
      </ProjectCard.Line>
      <ProjectCard.ItemsLine>
        <ProjectCard.Item>
          <div
            className={classeNames({
              'k-u-hidden@s-up': !forceDesktop,
              'k-u-hidden': forceDesktop,
            })}
          >
            {!isStarted(state) && <StateInfo projectState={state} />}
            {inExtratime && <StateInfo inExtratime={inExtratime} />}
            {isStarted(state) && !inExtratime && (
              <EndDateInfo endAt={endAt} projectState={state} />
            )}
          </div>
          <div className={classeNames({ 'k-u-hidden@xs-down': !forceDesktop })}>
            <BackersCountInfo count={ordersConnection?.totalCount} />
          </div>
        </ProjectCard.Item>
        {!inExtratime && endAt && (
          <ProjectCard.Item
            className={classeNames({ 'k-u-hidden@xs-down': !forceDesktop })}
          >
            <EndDateInfo
              endAt={endAt}
              projectState={state}
              forceDesktop={forceDesktop}
            />
          </ProjectCard.Item>
        )}
        <ProjectCard.Item>
          <div
            className={classeNames({
              'k-u-hidden@s-up': !forceDesktop,
              'k-u-hidden': forceDesktop,
            })}
          >
            <FundingPercentInfo count={fundingPercent} />
          </div>
          <div className={classeNames({ 'k-u-hidden@xs-down': !forceDesktop })}>
            <ProjectCardCollectingInfo
              collectedAmount={collectedAmount}
              collectedCount={collectedCount}
              goalAmount={goalAmount}
              goalType={goalType}
              goalCount={goalCount}
              forceDesktop={forceDesktop}
            />
          </div>
        </ProjectCard.Item>
      </ProjectCard.ItemsLine>
      <ProjectCard.Progress
        aria-label={t('app.project_card.progress.label')}
        value={fundingPercent}
      />
    </>
  )
}

export default CampaignLines
