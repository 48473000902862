import React from 'react'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { useTranslation } from 'kiss/hooks/use-translation'
import { pxToRem, Paragraph } from '@kisskissbankbank/kitten'

const StyledParagraph = styled(Paragraph)`
  ol {
    margin: ${pxToRem(20)};
  }
  li {
    list-style: disc;
  }
`

const StatusMessage = () => {
  const { values } = useFormikContext()
  const t = useTranslation()
  if (!values?.status) {
    return null
  }
  return (
    <StyledParagraph className="k-u-margin-top-double" modifier="tertiary">
      {t(`users.kyc.message.${values.status}`, { parseHtml: true })}
    </StyledParagraph>
  )
}

export default StatusMessage
