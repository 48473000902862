import React, { useEffect } from 'react'

import { useField } from 'formik'
import { useTranslation } from 'kiss/hooks/use-translation'

import isLuhn from 'fast-luhn'
import NumberFormat from 'react-number-format'
import { getType, CardType } from './card-type'

import {
  TextInput,
  Field,
  VisaIcon,
  MasterCardIcon,
  MaestroIcon,
  BancontactIcon,
  domElementHelper,
} from '@kisskissbankbank/kitten'

import Label from 'kiss/components/formik/label'

import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  width: max-content;
  gap: 10px;
`

const StyledIcon = styled.span`
  display: flex;
  position: relative;
  align-self: center;
`

const CB_NUMBER_ID = 'frmCCNum'

const getFormat = (type) => {
  if ([CardType.VISA, CardType.MASTERCARD, CardType.MAESTRO].includes(type)) {
    return '#### #### #### ####'
  }
  return '#### #### #### #####'
}

const CreditCardTypeLogo = ({ type }) => {
  switch (type) {
    case CardType.VISA:
      return <VisaIcon />
    case CardType.MASTERCARD:
      return <MasterCardIcon />
    case CardType.MAESTRO:
      return <MaestroIcon />
    case CardType.BANCONTACT:
      return <BancontactIcon />
    default:
      return null
  }
}

const Number = () => {
  const t = useTranslation()

  const validateCardNumber = (value, t) => {
    const { type } = getType(value)

    const isNotTypeBanOrMaestro = ![
      CardType.BANCONTACT,
      CardType.MAESTRO,
    ].includes(type)

    if (isNotTypeBanOrMaestro && value && !isLuhn(value.replace(/ /g, ''))) {
      return t('payment_choice.errors.luhn')
    }
    if (!value) {
      return t('payment_choice.errors.required')
    }
  }

  const [field, meta] = useField({
    name: 'cardNumber',
    validate: (value) => validateCardNumber(value, t),
  })
  const type = getType(field.value)
  const error = meta.touched && meta.error

  useEffect(() => {
    const frmCCNumInput =
      domElementHelper.canUseDom() && document.getElementById(CB_NUMBER_ID)
    if (frmCCNumInput) {
      frmCCNumInput.focus()
    }
  }, [])

  return (
    <div className="k-u-margin-bottom-triple">
      <Label htmlFor={CB_NUMBER_ID}>{t('payment_choice.card_number')}</Label>
      <StyledContainer>
        <NumberFormat
          {...field}
          format={getFormat(type.type)}
          inputMode="numeric"
          placeholder="XXXX XXXX XXXX XXXX"
          id={CB_NUMBER_ID}
          autoComplete="cc-number"
          error={!!error}
          customInput={TextInput}
        />
        <StyledIcon aria-hidden="true">
          <CreditCardTypeLogo type={type?.type} />
        </StyledIcon>
      </StyledContainer>
      {!!error && <Field.ErrorMessage>{error}</Field.ErrorMessage>}
    </div>
  )
}

export default Number
