import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from '../../hooks/use-translation'
import { ErrorMessage, useField } from 'formik'
import { Field, DropdownSelect } from '@kisskissbankbank/kitten'

const Dropdown = ({
  name,
  validate,
  onChange,
  onBlur,
  searchable,
  disabled,
  placeholder,
  onInputChange,
  withoutErrorMessage,
  labelText,
  ...others
}) => {
  const t = useTranslation()
  const [field, meta, helpers] = useField({ name, validate })

  const a11yTexts = {
    labelText: labelText,
    placeholder: placeholder,
    a11yStatusError: t('app.forms.select.is_error'),
    a11yStatusValid: t('app.forms.select.is_valid'),
    a11ySelectionMessageDisplayer: (value) => {
      t('app.forms.select.selected_message', { value: value })
    },
    comboboxButtonLabelText: t('app.forms.select.combobox_button'),
  }

  return (
    <div data-test-id={field.name}>
      <DropdownSelect
        {...field}
        {...a11yTexts}
        controlled
        id={field.name}
        hideLabel
        labelProps={{
          size: 'micro',
        }}
        onChange={(e) => {
          if (!e) return
          onChange(e)
          helpers.setTouched(true)
          helpers.setValue(e.value)
        }}
        onBlur={(e) => {
          if (!e) return
          onBlur(e)
          helpers.setTouched(true)
          helpers.setValue(e.value)
        }}
        onInputChange={onInputChange}
        combobox={searchable}
        uniqLabelOnSearch
        error={meta.error && meta.touched}
        disabled={disabled}
        {...others}
      />
      {!withoutErrorMessage && (
        <ErrorMessage name={name}>
          {(msg) => <Field.ErrorMessage>{msg}</Field.ErrorMessage>}
        </ErrorMessage>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  validate: PropTypes.func,
  searchable: PropTypes.bool,
  withoutErrorMessage: PropTypes.bool,
}

Dropdown.defaultProps = {
  disabled: false,
  withoutErrorMessage: false,
  searchable: false,
  placeholder: '',
  onChange: () => null,
  onBlur: () => null,
  onInputChange: () => null,
}

export default Dropdown
