import React from 'react'
import { Text } from '@kisskissbankbank/kitten'
import useContributeStepper from '../hooks/use-stepper'

const Steps = () => {
  const { wording } = useContributeStepper()

  if (!wording.message && !wording.step) {
    return null
  }

  return (
    <div className="k-u-align-center">
      {wording.message && (
        <Text
          size="small"
          weight="600"
          color="font1"
          tag="div"
          className="kiss-SubHeader__step k-u-margin-bottom-noneHalf k-u-transform-uppercase"
        >
          {wording.message}
        </Text>
      )}

      {wording.step && (
        <Text tag="div" size="small" weight="500" color="font1">
          {wording.step}
        </Text>
      )}
    </div>
  )
}

export default Steps
