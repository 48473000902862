import { useState, useEffect } from 'react'
import { domElementHelper } from '@kisskissbankbank/kitten'

// https://usehooks.com/useMedia/
export const useMedia = ({ queries, values, defaultValue }) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (!domElementHelper.canUseDom()) {
      setValue(defaultValue)
      return
    }

    const mediaQueryLists = queries.map((q) => window.matchMedia(q))

    const getValue = () => {
      const index = mediaQueryLists.findIndex((mql) => mql.matches)
      return values[index] ?? defaultValue
    }

    const handler = () => setValue(getValue)
    mediaQueryLists.forEach((mql) => mql.addListener(handler))
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler))
  }, [])

  return value
}
