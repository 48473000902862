import React from 'react'
import { useSelector } from 'react-redux'
import Info from './info'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getAmount,
  getCurrency,
  getFundedAmount,
} from 'kiss/modules/project-page/page-state/selectors'

const CollectedAmountInfo = () => {
  const collectedAmount = useSelector(getFundedAmount)
  const amount = useSelector(getAmount)
  const currency = useSelector(getCurrency)

  const t = useTranslation()

  const translatedCurrency = t(`currency_symbol.${currency}`)
  const value = t('project_info.amount_with_currency', {
    amount: t(collectedAmount, { formatNumber: true }),
    currency: translatedCurrency,
    parseHtml: true,
  })

  const goal = t('project_info.amount_with_currency', {
    amount: t(amount, { formatNumber: true }),
    currency: translatedCurrency,
    parseHtml: true,
  })

  const text = t('project_info.funded_amount', { goal_with_currency: goal })

  return <Info text={text} value={value} />
}

export default CollectedAmountInfo
