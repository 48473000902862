import React, { memo } from 'react'
import RichContentText from 'kiss/modules/components/rich-content/text'
import { Container, Grid, GridCol } from '@kisskissbankbank/kitten'

const Text = ({ content }) => (
  <Container>
    <Grid>
      <GridCol
        col-xs="10"
        offset-xs="1"
        col-m="8"
        offset-m="2"
        col-l="6"
        offset-l="3"
      >
        <RichContentText content={content} />
      </GridCol>
    </Grid>
  </Container>
)

export default memo(Text)
