import {
  Button,
  CheckedCircleIcon,
  Container,
  CONTAINER_PADDING,
  DropdownSelect,
  Field,
  Grid,
  GridCol,
  pxToRem,
  Text,
  TitleWithStroke,
  FlexWrapper,
} from '@kisskissbankbank/kitten'
import { ErrorMessage, Field as FormikField, Form, Formik } from 'formik'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { partnershipContactRequest } from 'kiss/modules/prismic-page/redux'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import * as Yup from 'yup'

const StyledImage = styled.div`
  margin-left: -${pxToRem(CONTAINER_PADDING)};

  img {
    width: 100%;
    display: block;
  }
`

const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${pxToRem(20)};
  padding: ${pxToRem(20)} ${pxToRem(15)};
  background-color: var(--color-success-100);

  svg {
    flex-shrink: 0;
  }
`

const FormWithImage = ({ formId, image }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const [submitted, setSubmitted] = useState(false)

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    entity: '',
    partnerType: '',
    partnerDescription: '',
    optin: false,
  }

  const validations = Yup.object().shape({
    firstName: Yup.string().required(t('lander.form.first_name.error')),
    lastName: Yup.string().required(t('lander.form.last_name.error')),
    email: Yup.string()
      .required(t('lander.form.email.error'))
      .email(t('lander.form.email.format_error')),
    entity: Yup.string().required(t('lander.form.entity.error')),
    partnerType: Yup.string().required(t('lander.form.partner_type.error')),
    partnerDescription: Yup.string().required(
      t('lander.form.partner_description.error'),
    ),
  })

  const handleSubmit = async (values, actions) => {
    try {
      await dispatch(partnershipContactRequest(values))
      setSubmitted(true)
      actions.resetForm({ values: initialValues })
    } catch (_e) {
      dispatch(addErrorAlert('Error: something went wrong!', { scroll: true }))
    }
  }

  return (
    <Container id={formId}>
      <Grid>
        <GridCol col-l="6" className="k-u-hidden@m-down">
          <StyledImage>
            <img loading="lazy" src={image.url} alt={image.alt} />
          </StyledImage>
        </GridCol>

        <GridCol col-xs="10" offset-xs="1" col-l="4" offset-l="1">
          <TitleWithStroke
            tag="h3"
            modifier="quaternary"
            className="k-u-align-center@s-up k-u-align-left@l-up"
          >
            {t('lander.partners.form.title')}
          </TitleWithStroke>

          <Formik
            initialValues={initialValues}
            validationSchema={validations}
            validate={() => setSubmitted(false)}
            onSubmit={handleSubmit}
            render={() => (
              <Form>
                <FlexWrapper gap={40}>
                  <FormikField
                    name="firstName"
                    render={({ field, form }) => (
                      <Field tag="div">
                        <Field.Label labelProps={{ htmlFor: field.name }}>
                          {t('lander.form.first_name.label')}
                        </Field.Label>

                        <Field.Input
                          {...field}
                          id={field.name}
                          placeholder={t('lander.form.first_name.placeholder')}
                          error={
                            form.touched[field.name] && form.errors[field.name]
                          }
                        />

                        <ErrorMessage
                          component={Field.ErrorMessage}
                          {...field}
                        />
                      </Field>
                    )}
                  />

                  <FormikField
                    name="lastName"
                    render={({ field, form }) => (
                      <Field tag="div">
                        <Field.Label labelProps={{ htmlFor: field.name }}>
                          {t('lander.form.last_name.label')}
                        </Field.Label>

                        <Field.Input
                          {...field}
                          id={field.name}
                          placeholder={t('lander.form.last_name.placeholder')}
                          error={
                            form.touched[field.name] && form.errors[field.name]
                          }
                        />

                        <ErrorMessage
                          component={Field.ErrorMessage}
                          {...field}
                        />
                      </Field>
                    )}
                  />

                  <FormikField
                    name="email"
                    render={({ field, form }) => (
                      <Field tag="div">
                        <Field.Label labelProps={{ htmlFor: field.name }}>
                          {t('lander.form.email.label')}
                        </Field.Label>

                        <Field.Input
                          {...field}
                          id={field.name}
                          placeholder={t('lander.form.email.placeholder')}
                          error={
                            form.touched[field.name] && form.errors[field.name]
                          }
                        />

                        <ErrorMessage
                          component={Field.ErrorMessage}
                          {...field}
                        />
                      </Field>
                    )}
                  />

                  <FormikField
                    name="phone"
                    render={({ field, form }) => (
                      <Field tag="div">
                        <Field.Label labelProps={{ htmlFor: field.name }}>
                          {t('lander.form.phone.label')}
                        </Field.Label>

                        <Field.Input
                          {...field}
                          id={field.name}
                          placeholder={t('lander.form.phone.placeholder')}
                          error={
                            form.touched[field.name] && form.errors[field.name]
                          }
                        />

                        <ErrorMessage
                          component={Field.ErrorMessage}
                          {...field}
                        />
                      </Field>
                    )}
                  />

                  <FormikField
                    name="entity"
                    render={({ field, form }) => (
                      <Field tag="div">
                        <Field.Label labelProps={{ htmlFor: field.name }}>
                          {t('lander.form.entity.label')}
                        </Field.Label>

                        <Field.Input
                          {...field}
                          id={field.name}
                          placeholder={t('lander.form.entity.placeholder')}
                          error={
                            form.touched[field.name] && form.errors[field.name]
                          }
                        />

                        <ErrorMessage
                          component={Field.ErrorMessage}
                          {...field}
                        />
                      </Field>
                    )}
                  />

                  <FormikField
                    name="partnerType"
                    render={({ field, form }) => (
                      <Field tag="div">
                        <Field.Label labelProps={{ htmlFor: field.name }}>
                          {t('lander.form.partner_type.label')}
                        </Field.Label>

                        <DropdownSelect
                          name={field.name}
                          id={field.name}
                          value={field.value}
                          controlled
                          placeholder={t(
                            'lander.form.partner_type.placeholder',
                          )}
                          options={[
                            {
                              value: 'mentoring',
                              label: t(
                                'lander.form.partner_type.options.mentoring',
                              ),
                            },
                            {
                              value: 'project_calls',
                              label: t(
                                'lander.form.partner_type.options.project_calls',
                              ),
                            },
                            {
                              value: 'training',
                              label: t(
                                'lander.form.partner_type.options.training',
                              ),
                            },
                          ]}
                          onChange={(e) => {
                            form.setFieldValue(field.name, e.value)
                          }}
                          error={
                            form.touched[field.name] && form.errors[field.name]
                          }
                        />

                        <ErrorMessage
                          component={Field.ErrorMessage}
                          {...field}
                        />
                      </Field>
                    )}
                  />

                  <FormikField
                    name="partnerDescription"
                    render={({ field, form }) => (
                      <Field tag="div">
                        <Field.Label labelProps={{ htmlFor: field.name }}>
                          {t('lander.form.partner_description.label')}
                        </Field.Label>

                        <Field.Input
                          {...field}
                          id={field.name}
                          placeholder={t(
                            'lander.form.partner_description.placeholder',
                          )}
                          tag="textarea"
                          rows="4"
                          error={
                            form.touched[field.name] && form.errors[field.name]
                          }
                        />

                        <ErrorMessage
                          component={Field.ErrorMessage}
                          {...field}
                        />
                      </Field>
                    )}
                  />

                  <FormikField
                    name="optin"
                    render={({ field, form }) => (
                      <Field tag="div">
                        <Field.Checkbox
                          {...field}
                          id={field.name}
                          checked={field.value}
                          aria-checked={field.value}
                          error={
                            form.touched[field.name] && form.errors[field.name]
                          }
                        >
                          {t('lander.form.optin.label')}
                        </Field.Checkbox>
                      </Field>
                    )}
                  />

                  <Button fit="fluid" modifier="helium" type="submit">
                    {t('lander.form.submit')}
                  </Button>

                  {submitted && (
                    <SuccessMessage>
                      <CheckedCircleIcon
                        circleColor="var(--color-success-500)"
                        checkedColor="var(--color-grey-000)"
                        style={{ width: 25 }}
                        className="k-u-margin-right-single"
                      />

                      <Text
                        tag="p"
                        size="micro"
                        lineHeight="normal"
                        weight="400"
                        color="font1"
                        style={{ margin: 0 }}
                      >
                        {t('lander.form.success_message')}
                      </Text>
                    </SuccessMessage>
                  )}
                </FlexWrapper>
              </Form>
            )}
          />
        </GridCol>
      </Grid>
    </Container>
  )
}

export default FormWithImage
