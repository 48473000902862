import {
  Container,
  Grid,
  GridCol,
  Marger,
  Paragraph,
  pxToRem,
  ScreenConfig,
  TitleWithStroke,
} from '@kisskissbankbank/kitten'
import { useQuery } from 'kiss/api/graphql-query'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import SelectionQuery from 'kiss/graphql/queries/selection/selection.graphql'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import styled from 'styled-components'
import ProjectCard from '../../components/cards/project-card'
import {
  FOUR_IN_CONTAINER,
  FOUR_IN_XL_CONTAINER,
  ONE_IN_XXS_CONTAINER,
  TWO_IN_CONTAINER,
} from '../../utils/responsive-image/image-width-definitions'

const responsiveImageOptions = {
  imageSizes: [230, 280, 340, 380],
  breakpoints: [0, ScreenConfig.S.min, ScreenConfig.L.min, ScreenConfig.XL.min],
  imageSizesInViewport: [
    ONE_IN_XXS_CONTAINER,
    TWO_IN_CONTAINER,
    FOUR_IN_CONTAINER,
    FOUR_IN_XL_CONTAINER,
  ],
}

const StyledContainer = styled(Container)`
  display: grid;
  grid-gap: ${pxToRem(20)};
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    grid-gap: ${pxToRem(20)};
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    grid-gap: ${pxToRem(30)};
    grid-template-columns: repeat(4, 1fr);
  }
`

const SelectionPage = () => {
  const { selection: slug } = useParams()

  const { data, loading } = useQuery(SelectionQuery, {
    slug: slug,
  })

  if (!data || loading) return null

  const { name, description, projects } = data?.selection || {}

  return (
    <DefaultLayout>
      <Helmet title={name} />
      <Marger bottom={{ default: 8, fromL: 4 }} top={{ default: 6 }}>
        <Container>
          <Grid>
            <GridCol col-s="10" offset-s="1" col-l="6" offset-l="3">
              <Marger bottom="1.5">
                <TitleWithStroke
                  tag="h1"
                  modifier="quaternary"
                  noMargin
                  className="k-u-align-center"
                >
                  {name}
                </TitleWithStroke>
              </Marger>

              <Paragraph
                modifier="tertiary"
                noMargin
                className="k-u-align-center"
              >
                {description}
              </Paragraph>
            </GridCol>
          </Grid>
        </Container>
      </Marger>

      <div className="k-u-margin-vertical-octuple">
        <StyledContainer>
          {projects.map((project) => {
            return (
              <ProjectCard
                key={project?.slug}
                project={project}
                responsiveImageOptions={responsiveImageOptions}
                forceDesktop
              />
            )
          })}
        </StyledContainer>
      </div>
    </DefaultLayout>
  )
}

export default SelectionPage
