import React, { useState } from 'react'
import {
  Text,
  Button,
  Loader,
  CheckedCircleIcon,
  WarningCircleIcon,
  TextInputWithIcon,
  Field,
} from '@kisskissbankbank/kitten'
import { Formik, Form, useField, ErrorMessage } from 'formik'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useCart } from '../../../../hooks'
import { gql } from '@apollo/client'
import { useMutation } from 'kiss/api/graphql-query'
import flow from 'lodash/fp/flow'
import first from 'lodash/fp/first'
import getOr from 'lodash/fp/getOr'

const UPDATE_GIFT_CARD = gql`
  mutation UpdateCartCode($projectId: ID, $code: String) {
    updateCartCode(projectId: $projectId, code: $code) {
      id
    }
  }
`

const InputText = ({ name, state, onChange = () => null, ...props }) => {
  const [field] = useField(name)
  const Icon = (function () {
    switch (state) {
      case 'loading':
        return <Loader />
      case 'valid':
        return (
          <CheckedCircleIcon
            bgColor="var(--color-success-500)"
            color="var(--color-grey-000)"
          />
        )
      case 'error':
        return (
          <WarningCircleIcon
            bgColor="var(--color-danger-500)"
            color="var(--color-grey-000)"
          />
        )
      default:
        return null
    }
  })()
  return (
    <>
      <TextInputWithIcon
        {...field}
        icon={Icon}
        iconPosition="right"
        valid={state === 'valid'}
        error={state === 'error'}
        onChange={(e) => {
          onChange()
          field.onChange(e)
        }}
        {...props}
      />
      <ErrorMessage name={name}>
        {(msg) => <Field.ErrorMessage>{msg}</Field.ErrorMessage>}
      </ErrorMessage>
    </>
  )
}

const GiftCard = () => {
  const t = useTranslation()
  const { cart, projectId, loading, refetch } = useCart()
  const giftCardCode = cart?.giftCard?.code
  const [isFormOpened, openForm] = useState(!!giftCardCode)
  const [giftCardState, setGiftCardState] = useState(
    giftCardCode ? 'valid' : 'wait',
  )
  const [updateGifCard] = useMutation(UPDATE_GIFT_CARD)

  if (loading) return null
  return (
    <div aria-live="polite">
      {isFormOpened ? (
        <Formik
          initialValues={{ giftCode: giftCardCode || '' }}
          onSubmit={async ({ giftCode }, { setFieldError, setSubmitting }) => {
            setGiftCardState('loading')
            try {
              await updateGifCard({ variables: { projectId, code: giftCode } })
              await refetch()
              setGiftCardState('valid')
            } catch (e) {
              const errorCode = flow(first, getOr('')('code'))(e.graphQLErrors)
              const message = (function () {
                switch (errorCode) {
                  case 'unknown_code':
                    return 'contribute.cart.gift_code.errors.invalid'
                  case 'gift_amount_too_low':
                    return 'contribute.cart.gift_code.errors.min_amount'
                  case 'gift_card_empty':
                    return 'contribute.cart.gift_code.errors.empty'
                  case 'gift_card_forbidden':
                    return 'contribute.cart.gift_code.errors.not_allowed'
                  case 'gift_card_assigned':
                    return 'contribute.cart.gift_code.errors.already_assigned'
                  default:
                    return 'global.error'
                }
              })()
              setGiftCardState('error')
              setFieldError('giftCode', t(message))
            } finally {
              setSubmitting(false)
            }
          }}
        >
          {({ values, isSubmitting }) => {
            return (
              <Form
                className="k-u-flex k-u-flex-alignItems-start"
                style={{ gap: 10 }}
              >
                <div>
                  <InputText
                    name="giftCode"
                    state={giftCardState}
                    onChange={() => setGiftCardState('wait')}
                    aria-label={t('cart.contribute.summary.giftcard.label')}
                  />
                </div>
                <Button
                  size="medium"
                  modifier="helium"
                  style={{
                    visibility:
                      giftCardState === 'valid' ? 'hidden' : 'visible',
                    minWidth: 0,
                  }}
                  type={isSubmitting ? 'button' : 'submit'}
                  disabled={values.giftCode === ''}
                >
                  {t('cart.contribute.summary.button.add')}
                </Button>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <Text
          tag="button"
          size="small"
          weight="500"
          color="primary1"
          className="k-u-reset-button k-u-link k-u-link-primary1 k-u-cursor-pointer"
          onClick={() => openForm(true)}
        >
          <span aria-hidden>+</span>{' '}
          {t('cart.contribute.summary.giftcard.label')}
        </Text>
      )}
    </div>
  )
}

export default GiftCard
