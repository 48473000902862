import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import ProjectCardInfo from './info'

const BackersCountInfo = ({ count }) => {
  const t = useTranslation()

  return (
    <ProjectCardInfo
      value={t(count, { formatNumber: true })}
      text={t('browsing.project_card.backer', { count })}
    />
  )
}

export default BackersCountInfo
