import { HEADER_HEIGHT } from '@kisskissbankbank/kitten'

const FLOATING_MENU_HEIGHT = 60

export const scrollTo = (
  anchor,
  viewportIsMOrLess = false,
  behavior = 'smooth',
) => {
  const target = document.getElementById(anchor)
  const currentScroll = document.querySelector('html').scrollTop

  let offset = 0

  // Stick to top
  offset = target.getBoundingClientRect().top + window.scrollY

  if (viewportIsMOrLess) {
    // Offset for floating menu height on mobile + tablet
    offset -= FLOATING_MENU_HEIGHT
  }

  if (currentScroll > offset) {
    // Offset for header menu when scroll up
    offset -= HEADER_HEIGHT
  }

  // Offset for a smol margin
  offset -= 20

  window.scrollTo({
    behavior,
    top: offset,
  })
}
