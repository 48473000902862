import { gql } from '@apollo/client'
import { DownloadIcon, Separator, Text } from '@kisskissbankbank/kitten'
import { useMutation } from 'kiss/api/graphql-query'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const CREATE_RECEIPT_MUTATION = gql`
  mutation CreateReceiptMutation($orderId: ID) {
    downloadPaymentReceipt(orderId: $orderId) {
      id
      receiptUrl
    }
  }
`

const isAccepted = (orderStatus) =>
  [
    'confirmed',
    'cannot_be_fulfilled',
    'approved',
    'funded',
    'unfunded',
  ].includes(orderStatus)

const DownloadReceipt = ({ order }) => {
  const t = useTranslation()
  const [createReceipt] = useMutation(CREATE_RECEIPT_MUTATION, {
    orderId: order.id,
  })
  if (!isAccepted(order.orderStatus)) return null
  return (
    <>
      <Separator className="k-u-margin-top-singleHalf k-u-margin-bottom-singleHalf" />
      <Text
        tag="a"
        href={order.receiptUrl}
        download
        size="small"
        weight="500"
        className="k-u-reset-button k-u-link k-u-link-primary1 k-u-flex k-u-flex-alignItems-center k-u-flex-justifyContent-start"
        rel="noopener"
        onClick={async (e) => {
          if (!order.receiptUrl) {
            e.preventDefault()
            e.stopPropagation()
            const result = await createReceipt()
            const receiptUrl = result?.data?.downloadPaymentReceipt?.receiptUrl
            window.open(receiptUrl, '_blank').focus()
          }
        }}
      >
        <DownloadIcon
          className="k-u-margin-right-single"
          color="var(--color-primary-500)"
        />
        {t('contributor.orders.payment.download')}
      </Text>
    </>
  )
}

export default DownloadReceipt
