import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getProject } from 'kiss/modules/project-page/page-state/selectors'
import { Button, Text } from '@kisskissbankbank/kitten'

const Website = () => {
  const t = useTranslation()
  const project = useSelector(getProject)
  const { websiteUrl } = project || {}

  if (!websiteUrl) return null

  return (
    <div>
      <Text
        tag="h3"
        size="micro"
        transform="uppercase"
        className="kiss-AsideTitle k-u-margin-top-none k-u-margin-bottom-noneHalf k-u-letter-spacing-10"
      >
        {t('permanent_project.social.website')}
      </Text>
      <div className="k-u-margin-top-single">
        <Button
          tag="a"
          target="_blank"
          href={websiteUrl}
          rel="noopener noreferrer"
          fit="content"
          rounded
          size="micro"
        >
          {websiteUrl}
        </Button>
      </div>
    </div>
  )
}

export default Website
