import compact from 'lodash/fp/compact'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import { useContext } from 'react'
import { getLastRoomMessageEvent } from '../helpers'
import { MessagingContext } from '../messaging-context'

const MESSAGE_STATUS = {
  READ: 'read',
  UNREAD: 'unread',
}

const getUnreadMessagesNumber = (room, currentRoom) => {
  if (currentRoom?.roomId === room.roomId) return MESSAGE_STATUS.READ
  const currentUserId = room?.myUserId
  const lastMessage = getLastRoomMessageEvent(room)
  if (!currentUserId || !lastMessage) return MESSAGE_STATUS.READ
  if (lastMessage?.event?.sender === currentUserId) return MESSAGE_STATUS.READ
  const currentUserReceipt = flow(
    get(['receipts', 'm.read', currentUserId]),
    compact,
    first,
    get('eventId'),
  )(room)
  if (!currentUserReceipt) return MESSAGE_STATUS.READ
  return lastMessage?.event?.event_id === currentUserReceipt
    ? MESSAGE_STATUS.READ
    : MESSAGE_STATUS.UNREAD
}

const useMessageStatus = () => {
  const { currentRoom } = useContext(MessagingContext)
  return {
    getStatusForRoom: (room) => getUnreadMessagesNumber(room, currentRoom),
  }
}

export default useMessageStatus
