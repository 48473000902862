import { getAssetFor } from 'kiss/assets/redux'
import WidgetCard from 'kiss/components/widget-card'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { getWidget } from './redux'

const WidgetPageCard = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)
  const widget = useSelector(getWidget)

  const name = widget?.organization?.name || widget?.owner?.username

  return (
    <>
      <Helmet
        title={t('project.seo.title', {
          name: widget.name,
          username: name,
        })}
        meta={[
          {
            name: 'description',
            content: widget.shortDesc,
          },
          {
            charset: 'utf-8',
          },
        ]}
        link={[
          {
            href: assetFor('fonts.css'),
            rel: 'stylesheet',
            type: 'text/css',
            media: 'screen',
          },
          {
            href: assetFor('reset.css'),
            rel: 'stylesheet',
            type: 'text/css',
            media: 'screen',
          },
        ]}
      />

      <WidgetCard project={widget} />
    </>
  )
}

export default WidgetPageCard
