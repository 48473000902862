import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Label from 'kiss/components/formik/label'
import Dropdown from 'kiss/components/formik/dropdown-select'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useFormikContext } from 'formik'
import { getDeliveryCountries } from 'kiss/modules/components/reward-modal/shipping'

const ShippingAddress = ({
  deliveryCountries,
  hideLabel,
  handleSelectChange,
  period,
}) => {
  const t = useTranslation()
  const { values, setFieldValue } = useFormikContext()
  const options = getDeliveryCountries(t, deliveryCountries, true, period)
  const [selectedOption, setSelectedOption] = useState(options[0])

  useEffect(() => {
    setFieldValue('countryCode', selectedOption.value, false)
  }, [])

  const onChange = (option) => {
    handleSelectChange()
    setSelectedOption(option)
  }

  return (
    <div className="k-u-margin-top-single">
      {!hideLabel && (
        <Label
          htmlFor="countryCode"
          label={t('project.modal.shipping.label')}
        />
      )}
      <Dropdown
        name="countryCode"
        value={selectedOption.value}
        validate={(value) => {
          if (!value && values.shipping === 'postal') {
            return t('app.forms.error.required')
          }
        }}
        options={options}
        aria-label={t('project.modal.shipping.placeholder')}
        aria-labelledBy="shipping-notification"
        onChange={onChange}
      />
    </div>
  )
}

ShippingAddress.propTypes = {
  deliveryCountries: PropTypes.array.isRequired,
  hideLabel: PropTypes.bool,
  handleSelectChange: PropTypes.func,
  period: PropTypes.string.isRequired,
}

ShippingAddress.defaultProps = {
  hideLabel: false,
  handleSelectChange: () => {},
}

export default ShippingAddress
