import React from 'react'
import ProjectCardCollectedAmountInfo from './collected-amount-info'
import ProjectCardCollectedCountInfo from './collected-count-info'

const ProjectCardCollectingInfo = ({
  collectedAmount,
  collectedCount,
  goalAmount,
  goalType,
  goalCount,
  forceDesktop = false,
}) => {
  if (goalType === 'count') {
    return (
      <ProjectCardCollectedCountInfo
        collectedCount={collectedCount || 0}
        forceDesktop={forceDesktop}
        goalCount={goalCount}
      />
    )
  }
  return (
    <ProjectCardCollectedAmountInfo
      collectedAmount={collectedAmount || { cents: 0 }}
      goalAmount={goalAmount}
    />
  )
}

export default ProjectCardCollectingInfo
