import React from 'react'

import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'

import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { useTranslation } from 'kiss/hooks/use-translation'

import * as qs from 'neoqs'

import {
  Button,
  FormActions,
  GridCol,
  Marger,
  pxToRem,
  Text,
  Title,
  mq,
} from '@kisskissbankbank/kitten'

import { getRouteFor, PAYMENT_CHOICES, PROJECT_ROUTE } from 'kiss/routes/redux'
import { PaymentIllustration } from 'kiss/payment/illustration'
import WaitForConfirmationCard from 'kiss/modules/contribute/card-failure/WaitForConfirmationCard'
import Message from 'kiss/modules/contribute/card-failure/Message'
import { LONG_PROCESSING } from 'kiss/modules/contribute/polling'

const ErrorMessage = styled.div`
  text-align: center;
  font-weight: 500;
`
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(60)};
  margin: 0 ${pxToRem(200)} 0 ${pxToRem(200)};

  @media ${mq.mobileAndTablet} {
    margin: 0 ${pxToRem(20)} 0 ${pxToRem(20)};
  }
`

const CardPaymentFailure = () => {
  const routeFor = useSelector(getRouteFor)

  const { project } = useParams()

  const t = useTranslation()

  const { search } = useLocation()

  const { errorCode } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })

  const isGenericError = () =>
    t(`payment_errors.mangopay.${errorCode}`).indexOf(
      'payment_errors.mangopay',
    ) !== -1

  if (errorCode === 'processing_provider') {
    return (
      <StyledContainer>
        <Helmet title={t('projects.payment.cancelled')} />
        <Message
          title={t('cart.contribute.duplicate_payment.title')}
          text={t('cart.contribute.duplicate_payment.explanation', {
            parseHtml: true,
          })}
          imageSrc="illustrations/generique/AvionPapier.svg"
        />
        <WaitForConfirmationCard />
      </StyledContainer>
    )
  }

  if (errorCode === LONG_PROCESSING) {
    return (
      <StyledContainer>
        <Helmet title={t('projects.payment.in_process')} />
        <Message
          title={t('cart.contribute.payment_in_process.title')}
          text={t('cart.contribute.payment_in_process.explanation', {
            parseHtml: true,
          })}
          imageSrc="illustrations/noel/AvionPapierNoel.svg"
        />
        <WaitForConfirmationCard />
      </StyledContainer>
    )
  }

  return (
    <>
      <Helmet title={t('projects.payment.failed')} />
      <GridCol>
        <Marger top="8" bottom="3">
          <Title className="k-u-align-center" noMargin modifier="secondary">
            {t('projects.payment.failed')}
          </Title>
        </Marger>
        {errorCode && (
          <ErrorMessage>
            <Text color="font1" weight="400">
              {`${t('payment_errors.mangopay.head')} ${t(
                `payment_errors.mangopay.${
                  isGenericError() ? 'generic' : errorCode
                }`,
              )}.`}
              <br />
              {t('payment_errors.mangopay.subheader')}
            </Text>
          </ErrorMessage>
        )}

        <Marger top="3" bottom="3" className="k-u-align-center">
          <PaymentIllustration />
        </Marger>
        <Marger top="3" bottom="8">
          <FormActions spreadOutAt={false}>
            <FormActions.Item className="k-u-margin-bottom-double k-u-margin-right-single">
              <Button
                size="large"
                href={routeFor(PROJECT_ROUTE, {
                  project,
                })}
                as="a"
              >
                {t('projects.payment.return_button')}
              </Button>
            </FormActions.Item>

            <FormActions.Item className="k-u-margin-bottom-double k-u-margin-left-single">
              <Button
                size="large"
                href={routeFor(PAYMENT_CHOICES, {
                  project,
                })}
                modifier="helium"
                as="a"
              >
                {t('projects.payment.retry_button')}
              </Button>
            </FormActions.Item>
          </FormActions>
        </Marger>
      </GridCol>
    </>
  )
}

export default CardPaymentFailure
