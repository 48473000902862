import { useParams } from 'react-router'
import { gql } from '@apollo/client'
import { useQuery } from 'kiss/api/graphql-query'

const GET_PROJECT = gql`
  query GetProject($slug: String, $id: ID) {
    project(slug: $slug, id: $id) {
      id
      name
      slug
      currentUser {
        hasFollowed
      }
      pricingPlan {
        label
      }
    }
  }
`

const useProject = () => {
  const params = useParams()

  const { data, loading, refetch, fetchMore } = useQuery(GET_PROJECT, {
    slug: params.project,
  })

  return {
    project: data?.project,
    loading,
    refetch,
    fetchMore,
  }
}

export default useProject
