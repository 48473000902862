import React from 'react'

import { getLocale } from 'kiss/app/redux'
import { useSelector } from 'react-redux'
import {
  getRouteFor,
  COOKIES,
  COMPLAINTS,
  CGU,
  LEGAL,
  PRIVACY,
} from 'kiss/routes/redux'

import { useTranslation } from 'kiss/hooks/use-translation'

import { DropdownSelect, Text } from '@kisskissbankbank/kitten'

export const Legal = (props) => {
  const t = useTranslation()

  const locale = useSelector(getLocale)
  const routeFor = useSelector(getRouteFor)

  const value = {
    value: locale,
    label: t(`layouts.footer.lang.${locale}`),
  }

  const options = [
    { value: 'en', label: t('layouts.footer.lang.en') },
    { value: 'fr', label: t('layouts.footer.lang.fr') },
  ]

  const handleOnChange = (option) => {
    const LOCALE_REGEX = /^\/(fr|en)\/.*/
    const newLocation = window.location.pathname

    if (LOCALE_REGEX.test(window.location.pathname)) {
      const newPath = newLocation.substring(3)
      window.location.pathname = `/${option.value}${newPath}`
    } else {
      window.location.pathname = option.value
    }
  }

  return (
    <div className="k-Footer__legal" {...props}>
      <DropdownSelect
        id="select-language"
        labelText={t('layouts.footer.lang.select')}
        hideLabel
        size="small"
        modifier="boron"
        direction="up"
        className="k-Footer__legal--select"
        placeholder={value.label}
        options={options}
        value={value}
        onChange={handleOnChange}
      />
      <ul className="k-Footer__legal--list">
        <li>
          <a
            href={routeFor(CGU)}
            hrefLang="fr"
            className="k-Footer__linkList--item"
            style={{ height: 'auto' }}
          >
            {t('layouts.footer.terms')}
          </a>
        </li>
        <li>
          <a
            href={routeFor(LEGAL)}
            hrefLang="fr"
            className="k-Footer__linkList--item"
            style={{ height: 'auto' }}
          >
            {t('layouts.footer.legal')}
          </a>
        </li>
        <li>
          <a
            href={routeFor(PRIVACY)}
            className="k-Footer__linkList--item"
            style={{ height: 'auto' }}
          >
            {t('layouts.footer.privacy')}
          </a>
        </li>
        <li>
          <a
            href={routeFor(COOKIES)}
            className="k-Footer__linkList--item"
            style={{ height: 'auto' }}
          >
            {t('layouts.footer.cookies')}
          </a>
        </li>
        <li>
          <a
            href={routeFor(COMPLAINTS)}
            hrefLang="fr"
            className="k-Footer__linkList--item"
            style={{ height: 'auto' }}
          >
            {t('layouts.footer.claims')}
          </a>
        </li>
      </ul>
      <Text size="small" cssColor="var(--color-grey-600)">
        {t('layouts.footer.copyright', {
          parseHtml: true,
          year: new Date().getFullYear(),
        })}
      </Text>
    </div>
  )
}
