import React from 'react'
import {
  LockOutlineIcon,
  BankCardIconNext,
  ColorCrossCircleIconNext,
} from '@kisskissbankbank/kitten'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import ExplainLine from '../../../../components/summary/components/explain-line'
import {
  getOrderLines,
  hasDonation,
  hasOrderLines as hasOrderLinesSelector,
} from '../../../../cart/redux'

const ExplainBlock = () => {
  const t = useTranslation()
  const isDonation = useSelector(hasDonation)
  const orderLines = useSelector(getOrderLines)
  const hasOrderLines = useSelector(hasOrderLinesSelector)

  const reward = orderLines[0]?.variation?.reward

  const renderLabel = () => {
    if (isDonation && !hasOrderLines)
      return t('cart.contribute.summary.reassure.recurring_donation.debit')
    if (reward?.period === 'monthly')
      return t('cart.contribute.summary.reassure.subscription.monthly_debit')
    return t('cart.contribute.summary.reassure.subscription.quarterly_debit')
  }
  return (
    <>
      <ExplainLine icon={LockOutlineIcon}>
        <ExplainLine.Text>
          {t('cart.contribute.summary.reassure.lock')}
        </ExplainLine.Text>
      </ExplainLine>
      <ExplainLine icon={BankCardIconNext}>
        <ExplainLine.Text>{renderLabel()}</ExplainLine.Text>
      </ExplainLine>
      <ExplainLine icon={ColorCrossCircleIconNext}>
        <ExplainLine.Text>
          {isDonation
            ? t('cart.contribute.summary.reassure.recurring_donation.stop')
            : t('cart.contribute.summary.reassure.subscription.stop')}
        </ExplainLine.Text>
      </ExplainLine>
    </>
  )
}

export default ExplainBlock
