import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import { PaymentIllustration } from 'kiss/payment/illustration'
import {
  Button,
  GridCol,
  Marger,
  Title,
  FormActions,
} from '@kisskissbankbank/kitten'
import { getRouteFor, PAYMENT_CHOICES, PROJECT_ROUTE } from 'kiss/routes/redux'

const PaymentFailure = () => {
  const routeFor = useSelector(getRouteFor)
  const { project } = useParams()
  const t = useTranslation()

  return (
    <DefaultLayout>
      <Helmet title={t('projects.payment.failed')} />
      <GridCol>
        <Marger top="8" bottom="3">
          <Title className="k-u-align-center" noMargin modifier="secondary">
            {t('projects.payment.failed')}
          </Title>
        </Marger>

        <Marger top="3" bottom="3" className="k-u-align-center">
          <PaymentIllustration />
        </Marger>
        <Marger top="3" bottom="8">
          <FormActions spreadOutAt={false}>
            <FormActions.Item className="k-u-margin-bottom-double k-u-margin-right-single">
              <Button
                size="large"
                href={routeFor(PROJECT_ROUTE, {
                  project,
                })}
                as="a"
              >
                {t('projects.payment.return_button')}
              </Button>
            </FormActions.Item>

            <FormActions.Item className="k-u-margin-bottom-double k-u-margin-left-single">
              <Button
                size="large"
                href={routeFor(PAYMENT_CHOICES, {
                  project,
                })}
                modifier="helium"
                as="a"
              >
                {t('projects.payment.retry_button')}
              </Button>
            </FormActions.Item>
          </FormActions>
        </Marger>
      </GridCol>
    </DefaultLayout>
  )
}

export default PaymentFailure
