import { Container, Title } from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import { useSelector } from 'react-redux'
import { getLocale } from '../../app/redux'
import { BrowsingProvider } from './context'
import Engagements from './engagements'
import Filters from './filters'
import MobileFilter from './filters/mobile'
import Pagination from './pagination'
import Projects from './projects'
import { getCategories, getTotalProjects } from './redux'
import Seo from './seo'

const Browsing = () => {
  const categories = useSelector(getCategories)
  const totalProjects = useSelector(getTotalProjects)
  const locale = useSelector(getLocale)
  const t = useTranslation()
  return (
    <BrowsingProvider>
      <DefaultLayout>
        <Seo categories={categories} />
        <Title
          modifier="quaternary"
          family="antiqueolive"
          className="k-u-align-center k-u-margin-top-quintuple k-u-hidden@s-down"
        >
          {t('browsing.title', {
            count: new Intl.NumberFormat(locale, {
              style: 'decimal',
            }).format(totalProjects),
          })}
        </Title>
        <Container>
          <Engagements className="k-u-hidden@s-down" />
          <MobileFilter className="k-u-margin-top-singleHalf k-u-hidden@m-up" />
          <Filters className="k-u-margin-top-quintuple k-u-hidden@s-down" />
          <Projects className="k-u-margin-top-singleHalf k-u-margin-top-quintuple@m-up" />
          <Pagination className="k-u-margin-vertical-quintuple" />
        </Container>
      </DefaultLayout>
    </BrowsingProvider>
  )
}

export default Browsing
