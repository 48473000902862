import { domElementHelper, FloatingMenu } from '@kisskissbankbank/kitten'
import { useMediaQuery } from 'kiss/media-queries/redux'
import {
  getFaqs,
  getProject,
  getProjectRewards,
} from 'kiss/modules/project-page/page-state/selectors'
import { getRouteFor, PROJECT_ROUTE } from 'kiss/routes/redux'
import isEmpty from 'lodash/fp/isEmpty'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from '../../../../../hooks/use-translation'
import { ABOUT_ID, FAQ_ID, NEWS_ID, REWARDS_ID } from '../../constants/id'
import { ScrollContext, ScrollProvider } from '../contexts/scroll'
import { scrollTo } from '../../helpers/scroll-to'
import { ContributeButton } from '../../components/contribute-button'

const MenuItem = ({ route, children }) => {
  const [isActive, setActive] = useState(false)
  const { viewportIsMOrLess } = useMediaQuery()
  const routeFor = useSelector(getRouteFor)
  const project = useSelector(getProject)
  const { activeElement } = useContext(ScrollContext)

  useEffect(() => {
    setActive(activeElement === route)
  }, [activeElement])

  const builtRoute = routeFor(PROJECT_ROUTE, {
    project: project?.slug,
  })

  return (
    <FloatingMenu.Item
      isActive={isActive}
      href={`${builtRoute}#${route}`}
      onClick={(e) => {
        e.preventDefault()
        scrollTo(route, viewportIsMOrLess)
      }}
    >
      {children}
    </FloatingMenu.Item>
  )
}

const ButtonWrapper = () => {
  const { activeElement } = useContext(ScrollContext)
  if (![ABOUT_ID, NEWS_ID, FAQ_ID].includes(activeElement)) {
    return null
  }
  return <ContributeButton className="k-u-hidden@m-down" />
}

const Menu = () => {
  const t = useTranslation()
  const faqs = useSelector(getFaqs)
  const rewards = useSelector(getProjectRewards)
  const hash = domElementHelper.canUseDom()
    ? document.location.hash.substring(1)
    : REWARDS_ID

  return (
    <ScrollProvider hash={hash}>
      <FloatingMenu aria-label={t('permanent_project.menu.title')}>
        <MenuItem route={REWARDS_ID}>
          {isEmpty(rewards)
            ? t('permanent_project.menu.support')
            : t('permanent_project.menu.subscriptions')}
        </MenuItem>
        <MenuItem route={ABOUT_ID}>
          {t('permanent_project.menu.about')}
        </MenuItem>
        <MenuItem route={NEWS_ID}>{t('permanent_project.menu.news')}</MenuItem>
        {faqs.length > 0 && (
          <MenuItem route={FAQ_ID}>{t('permanent_project.menu.faq')}</MenuItem>
        )}
      </FloatingMenu>
      <ButtonWrapper />
    </ScrollProvider>
  )
}

export default Menu
