import ReactOnRails from 'react-on-rails'
import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from 'kiss/root-reducer'

// We use ReactOnRails to enable server rendering. It registers stores for us,
// so we can fetch them in our React components.
// ReactOnRails store generators are functions that take two arguments (props,
// railsContext) and return a Redux store.
//
// cf.
// https://github.com/shakacode/react_on_rails/tree/add8aa8b919ec78ff3040e773e7c9a6d11ca18af#multiple-react-components-on-a-page-with-one-store

export function storeGenerator(props, railsContext = {}) {
  const initialState = { railsContext, ...props }

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  })

  return store
}

ReactOnRails.registerStore({ app: storeGenerator })

export function getKissStore() {
  return ReactOnRails.getStore('app')
}
