import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  Title,
  Container,
  Grid,
  GridCol,
  Marger,
  Paragraph,
  List,
  Text,
  pxToRem,
} from '@kisskissbankbank/kitten'
import IdentityQuery from 'kiss/graphql/queries/sporran/identity.graphql'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import FormKyc from './form'
import config from 'kiss/config'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useQuery } from 'kiss/api/graphql-query'
import { isCurrentUserAdmin } from 'kiss/session/redux'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'

const StyledList = styled(List)`
  list-style: disc;
  li {
    margin-left: ${pxToRem(20)};
    margin-bottom: ${pxToRem(5)};
  }
`

const UserKyc = () => {
  const t = useTranslation()
  const { user } = useParams()
  const { data, refetch } = useQuery(IdentityQuery, {
    slug: user,
  })
  const isAdmin = useSelector(isCurrentUserAdmin)

  const Page = () => {
    return (
      <Grid>
        <GridCol col-s="12" col-m="10" offset-m="1">
          <Title
            modifier="senary"
            className="k-u-margin-top-octuple@s-up k-u-margin-top-quintuple@m-down k-u-margin-bottom-double"
          >
            {t('users.kyc.title')}
          </Title>
          <div className="k-u-margin-bottom-quadruple k-u-margin-top-double">
            <Paragraph modifier="tertiary" className="k-u-margin-bottom-single">
              {t('users.kyc.subtitle.paragraph1')}
            </Paragraph>
            <Paragraph
              modifier="tertiary"
              className="k-u-margin-bottom-noneHalf"
            >
              {t('users.kyc.subtitle.list.title')}
            </Paragraph>
            <StyledList className="k-u-margin-bottom-single">
              <Text size="small">{t('users.kyc.subtitle.list.item1')}</Text>
              <Text size="small">{t('users.kyc.subtitle.list.item2')}</Text>
              <Text size="small">{t('users.kyc.subtitle.list.item3')}</Text>
            </StyledList>
            <Paragraph modifier="tertiary">
              {t('users.kyc.subtitle.paragraph2', { parseHtml: true })}
            </Paragraph>
          </div>
          {!data || !data?.user?.identity ? (
            <Marger top="3" bottom="3" className="k-u-align-center">
              <LoadingAnimation />
            </Marger>
          ) : (
            <FormKyc
              identity={data.user.identity}
              refetchData={() => refetch()}
              isAdmin={isAdmin}
            />
          )}
        </GridCol>
      </Grid>
    )
  }

  return (
    <DefaultLayout>
      <Helmet>
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${config[APP_ENV].google.placeApiKey}&libraries=places&loading=async`}
          type="text/javascript"
        />
      </Helmet>

      <Container>
        <Page />
      </Container>
    </DefaultLayout>
  )
}

export default UserKyc
