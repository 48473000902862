import React, { useCallback } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { Prompt } from 'react-router'
import { useTranslation } from 'kiss/hooks/use-translation'

const UserPrompt = ({ isVisible = false }) => {
  const t = useTranslation()
  const handleBeforeUnload = useCallback(
    () => isVisible && t('apps.forms.exit_prompt.message', { parseHtml: true }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible],
  )
  useBeforeunload(handleBeforeUnload)

  return (
    <Prompt
      when={isVisible}
      message={t('apps.forms.exit_prompt.message', { parseHtml: true })}
    />
  )
}

export default UserPrompt
