import { Text } from '@kisskissbankbank/kitten'
import { getLocale } from 'kiss/app/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { isoDateToLocaleFormat } from 'kiss/utils/iso-date-to-locale-format'
import { isStarted } from 'kiss/utils/project/state'
import React from 'react'
import { useSelector } from 'react-redux'
import ProjectCardInfo from './info'

const EndDateInfo = ({ endAt, projectState, forceDesktop = false }) => {
  const t = useTranslation()
  const locale = useSelector(getLocale)
  const now = new Date().getTime()
  const end = endAt * 1000
  const duration = end - now

  const msPerDay = 24 * 60 * 60 * 1000
  const msPerHour = msPerDay / 24
  const msPerMinute = msPerHour / 60

  const durationPerDay = Math.floor(duration / msPerDay)
  const durationPerHour = Math.floor(duration / msPerHour)
  const durationPerMinute = Math.floor(duration / msPerMinute)

  const endDate = isoDateToLocaleFormat(end, {
    format: locale === 'en' ? 'MM/dd/yy' : 'dd/MM/yy',
    locale,
  })

  const { viewportIsSOrLess } = useMediaQuery()

  const showMobileVersion = viewportIsSOrLess && !forceDesktop

  let value
  let text

  if (!isStarted(projectState) || durationPerMinute <= 0) {
    value = `${showMobileVersion ? '' : endDate}`
    text = t('browsing.project_card.end_at')
  } else if (durationPerDay > 0) {
    value = t(`browsing.project_card${showMobileVersion ? '.count' : '.day'}`, {
      count: durationPerDay,
    })
    text = t(
      `browsing.project_card.day${showMobileVersion ? '_mobile' : '_left'}`,
      { count: durationPerDay, parseHtml: true },
    )
  } else if (durationPerHour > 0) {
    value = t(
      `browsing.project_card${showMobileVersion ? '.count' : '.hour'}`,
      {
        count: durationPerHour,
      },
    )
    text = t(
      `browsing.project_card.hour${showMobileVersion ? '_mobile' : '_left'}`,
      { count: durationPerHour, parseHtml: true },
    )
  } else {
    value = t(
      `browsing.project_card${showMobileVersion ? '.count' : '.minute'}`,
      {
        count: durationPerMinute,
      },
    )
    text = t(
      `browsing.project_card.minute${showMobileVersion ? '_mobile' : '_left'}`,
      { count: durationPerMinute, parseHtml: true },
    )
  }

  return showMobileVersion ? (
    <div>
      <Text weight="400" size="small">
        {text}
      </Text>
      <Text weight="500" size="small">
        {value}
      </Text>
    </div>
  ) : (
    <ProjectCardInfo value={value} text={text} />
  )
}

export default EndDateInfo
