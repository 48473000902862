import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, useField } from 'formik'
import { Field, PasswordInput } from '@kisskissbankbank/kitten'

const Password = ({ name, validate, onBlur, ...others }) => {
  const [field, meta] = useField({ name, validate })
  return (
    <>
      <PasswordInput
        id={field.name}
        {...field}
        onBlur={(e) => {
          field.onBlur(e)
          onBlur(e)
        }}
        error={meta.error && meta.touched}
        {...others}
      />
      <ErrorMessage name={name}>
        {(msg) => (
          <Field.ErrorMessage data-test-id="password-error-message">
            {msg}
          </Field.ErrorMessage>
        )}
      </ErrorMessage>
    </>
  )
}

Password.propTypes = {
  onBlur: PropTypes.func,
}

Password.defaultProps = {
  onBlur: () => null,
}

export default Password
