import React from 'react'
import { useSelector } from 'react-redux'
import Info from './info'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getTotalOrders } from 'kiss/modules/project-page/page-state/selectors'

const ProjectInfoContributors = () => {
  const totalOrders = useSelector(getTotalOrders)
  const t = useTranslation()

  return (
    <Info
      text={t('project_info.contributors', { count: totalOrders })}
      value={t(totalOrders, { formatNumber: true })}
    />
  )
}

export default ProjectInfoContributors
