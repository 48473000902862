import { gql } from '@apollo/client'
import { sentryCaptureException } from 'kiss/app/sentry'
import { useTranslation } from 'kiss/hooks/use-translation'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import includes from 'lodash/fp/includes'
import { useMutation } from '../../api/graphql-query'
import { PaylineError } from './payline-error'

const CREATE_PAYMENT_ERROR = gql`
  mutation CreatePaymentError(
    $cardRegistrationUuid: String!
    $amountCents: Int!
    $errorCode: String!
  ) {
    createPaymentError(
      cardRegistrationUuid: $cardRegistrationUuid
      amountCents: $amountCents
      errorCode: $errorCode
    ) {
      id
    }
  }
`

const usePaymentErrors = () => {
  const t = useTranslation()
  const [createPaymentErrorMutation] = useMutation(CREATE_PAYMENT_ERROR)
  const isPaylineError = (e) => e instanceof PaylineError
  const isGenericError = (e) =>
    t(`payment_errors.payline.${e.code}`).indexOf('payment_errors.payline') !==
    -1

  const getPaylineErrorMessage = (e) =>
    t(`payment_errors.payline.${isGenericError(e) ? 'generic' : e.code}`)

  const isTimeoutPaymentRequest = (e) =>
    flow(first, getOr('')('message'), includes('Timeout'))(e)

  const sendPaymentErrorToSentry = (e) =>
    sentryCaptureException(e, {
      tags: {
        query: 'payment-errors',
      },
    })
  return {
    createPaymentError: async (variables) => {
      return createPaymentErrorMutation({
        variables,
      })
    },
    isPaylineError,
    getPaylineErrorMessage,
    isTimeoutPaymentRequest,
    sendPaymentErrorToSentry,
  }
}

export default usePaymentErrors
