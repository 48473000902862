import React, { useMemo, useState, useEffect } from 'react'

import { isFinished, isNotPublished } from 'kiss/utils/project/state'
import { REWARDS_MOBILE_ID } from 'kiss/modules/project-page/page-state/redux'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import {
  getAvailableRewards,
  getProjectState,
  getSoldOutRewards,
  isProjectDonationEnabled,
} from 'kiss/modules/project-page/page-state/selectors'

import BackingCard from 'kiss/components/cards/backing-card'
import KissDonationCard from 'kiss/modules/components/donation-card'
import MobileModal from 'kiss/modules/project-page/campaign/components/reward-modal/mobile'
import {
  CAROUSEL_OUTLINE_PLUS_OFFSET as OUTLINE_PLUS_OFFSET,
  Carousel,
  CONTAINER_PADDING_THIN,
  CONTAINER_PADDING,
  Container,
  pxToRem,
  ScreenConfig,
  Title,
} from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const BIG_GUTTER = 20
const BIG_MARGIN = CONTAINER_PADDING + 60
const SMALL_GUTTER = 10
const SMALL_MARGIN = CONTAINER_PADDING_THIN + 40

const StyledCarouselContainer = styled.section`
  .k-Carousel__page {
    height: 100% !important;

    .k-RewardCard {
      height: 100% !important;
    }
  }
  .k-Carousel__page__item {
    padding-top: ${pxToRem(12)} !important;
  }

  .k-Carousel--showOtherPages .k-Carousel__inner {
    padding: 0 ${pxToRem(SMALL_MARGIN - OUTLINE_PLUS_OFFSET)} 0
      ${pxToRem(CONTAINER_PADDING_THIN - OUTLINE_PLUS_OFFSET)};

    .k-Carousel__inner__pageContainer:last-child {
      padding-right: ${pxToRem(
        SMALL_MARGIN + CONTAINER_PADDING_THIN - OUTLINE_PLUS_OFFSET,
      )};
    }

    @media (min-width: ${ScreenConfig.S.min}px) {
      padding: 0 ${pxToRem(BIG_MARGIN - OUTLINE_PLUS_OFFSET)} 0
        ${pxToRem(CONTAINER_PADDING - OUTLINE_PLUS_OFFSET)};

      .k-Carousel__inner__pageContainer:last-child {
        padding-right: ${pxToRem(
          BIG_MARGIN + CONTAINER_PADDING - OUTLINE_PLUS_OFFSET,
        )};
      }
    }

    .k-RewardCard {
      width: 100%;
      box-sizing: border-box;
    }
  }
`
const imageProps = {
  breakpoints: [0, ScreenConfig.S.min],
  imageSizesInViewport: [
    `100vw - ${CONTAINER_PADDING_THIN + SMALL_MARGIN}px`,
    `(100vw - ${CONTAINER_PADDING + BIG_MARGIN + BIG_GUTTER}px) / 2`,
  ],
  responsiveImageSizes: [190, 220, 275, 325, 375, 414],
}

const RewardsBase = () => {
  const [modalReward, setModalReward] = useState(null)

  const t = useTranslation()

  const projectDisabledRewards = useSelector(getSoldOutRewards)
  const projectEnabledRewards = useSelector(getAvailableRewards)
  const projectDonationEnabled = useSelector(isProjectDonationEnabled)
  const projectState = useSelector(getProjectState)

  const isProjectNotPublished = isNotPublished(projectState)
  const isProjectFinished = isFinished(projectState)

  const { viewportIsXSOrLess, viewportIsSOrLess } = useMediaQuery()

  // Modal handlers
  const handleRewardClick = ({ event, reward, isDisabled = false }) => {
    event.preventDefault()

    setModalReward({ reward, isDisabled })
    window.history.pushState({}, '', `?reward=${reward.uuid}`)
  }

  const handleClose = () => {
    setModalReward(null)
    window.history.pushState({}, '', window.location.pathname)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const rewardUuid = params.get('reward')

    if (rewardUuid) {
      const reward = projectEnabledRewards.find((r) => r.uuid === rewardUuid)
      if (reward) {
        setModalReward({ reward: reward, isDisabled: false })
      }
    }
  }, [])

  if (
    projectEnabledRewards.length === 0 &&
    !projectDonationEnabled &&
    projectDisabledRewards === 0
  ) {
    return null
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const carouselChildren = useMemo(() => {
    const tempCarouselChildren = []

    if (projectEnabledRewards.length > 0) {
      tempCarouselChildren.push(
        ...projectEnabledRewards.map((reward) => (
          <BackingCard
            reward={reward}
            key={reward.uuid}
            onClick={(event) => handleRewardClick({ event, reward })}
            as="button"
            type="button"
            truncateText
            buttonProps={{ as: 'span' }}
            imageProps={imageProps}
            isProjectNotPublished={isProjectNotPublished}
            isProjectFinished={isProjectFinished}
          />
        )),
      )
    }

    if (projectDonationEnabled) {
      tempCarouselChildren.push(
        <KissDonationCard
          key="mobileRewards-donation"
          className="kiss-MobileRewards__donationCard"
          isProjectNotPublished={isProjectNotPublished}
        />,
      )
    }

    if (projectDisabledRewards.length > 0) {
      tempCarouselChildren.push(
        ...projectDisabledRewards.map((reward) => (
          <BackingCard
            reward={reward}
            key={reward.uuid}
            imageProps={imageProps}
            as="button"
            type="button"
            truncateText
            onClick={(event) =>
              handleRewardClick({ event, reward, isDisabled: true })
            }
            isProjectNotPublished={isProjectNotPublished}
            isProjectFinished={isProjectFinished}
            isUnavailable
          />
        )),
      )
    }

    return tempCarouselChildren
  }, [projectEnabledRewards, projectDonationEnabled, projectDisabledRewards])

  return (
    <StyledCarouselContainer
      className="k-u-hidden@l-up k-u-margin-bottom-quadruple"
      id={REWARDS_MOBILE_ID}
    >
      <Container>
        <Title tag="h2" modifier="senary" className="k-u-margin-bottom-double">
          {isProjectFinished
            ? t('rewards.finished_title')
            : t('rewards.main_title')}
        </Title>
      </Container>

      <Carousel
        itemMinWidth={viewportIsSOrLess ? 0 : 250}
        itemsPerPage={viewportIsXSOrLess ? 1 : viewportIsSOrLess ? 2 : null}
        baseItemMarginBetween={viewportIsXSOrLess ? SMALL_GUTTER : BIG_GUTTER}
        hidePagination
        showOtherPages
        loop
        smallButtons
      >
        {carouselChildren}
      </Carousel>
      {!!modalReward && (
        <MobileModal
          reward={modalReward.reward}
          isDisabled={modalReward.isDisabled}
          modalProps={{
            onAfterClose: handleClose,
          }}
        />
      )}
    </StyledCarouselContainer>
  )
}

export default RewardsBase
