import React from 'react'
import PropTypes from 'prop-types'
import { parseHtml } from '@kisskissbankbank/kitten'

const RichTextUnorderedList = ({ htmlItems }) => {
  return (
    <ul>
      {htmlItems.map((item, index) => (
        <li key={index}>{parseHtml(item, { sanitize: false })}</li>
      ))}
    </ul>
  )
}

RichTextUnorderedList.propTypes = {
  htmlItems: PropTypes.array.isRequired,
}

export default RichTextUnorderedList
