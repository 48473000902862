import {
  Container,
  Grid,
  GridCol,
  pxToRem,
  Tag,
  Title,
  Triptych,
  MoneyBankIllustration,
  LibraryIllustration,
  HandIllustration,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  FUND_MY_PROJECT_PRE_ORDER,
  FUND_MY_PROJECT_CAMPAIGN,
  FUND_MY_PROJECT_SUBSCRIPTION,
  getRouteFor,
} from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import CardWithButton from './card-with-button'

const StyledSolutionTriptych = styled(Container)`
  .anchor {
    display: block;
    position: relative;
    top: -40px;
    visibility: hidden;
  }
`

const StyledTag = styled(Tag)`
  vertical-align: middle;
  margin-left: ${pxToRem(10)};
`

const SolutionsTriptych = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <StyledSolutionTriptych>
      <a className="anchor" id="financing-methods"></a>
      <Grid>
        <GridCol
          col-l="10"
          offset-l="1"
          col-m="12"
          offset-m="0"
          col-s="10"
          offset-s="1"
        >
          <Triptych
            title={
              <Title
                tag="h2"
                family="antiqueolive"
                modifier="tertiary"
                noMargin
                cssColor="var(--color-grey-900)"
              >
                {t('fund_my_project.triptych.title', {
                  parseHtml: true,
                })}
              </Title>
            }
            item1={
              <CardWithButton
                title={t('fund_my_project.triptych.item1.title')}
                titleProps={{ tag: 'h3' }}
                paragraph={t('fund_my_project.triptych.item1.paragraph')}
                illustration={
                  <MoneyBankIllustration width="165" height="293" />
                }
                horizontalStroke={false}
                marginBetweenImgAndBody="3"
                buttonProps={{
                  as: 'a',
                  href: routeFor(FUND_MY_PROJECT_CAMPAIGN),
                  children: t('fund_my_project.triptych.button.see_more'),
                }}
              />
            }
            item2={
              <CardWithButton
                title={t('fund_my_project.triptych.item2.title')}
                titleProps={{ tag: 'h3' }}
                paragraph={t('fund_my_project.triptych.item2.paragraph')}
                illustration={
                  <LibraryIllustration
                    width="200"
                    height="293"
                    viewBox="0 0 1365 1293"
                  />
                }
                horizontalStroke={false}
                marginBetweenImgAndBody="3"
                buttonProps={{
                  as: 'a',
                  href: routeFor(FUND_MY_PROJECT_PRE_ORDER),
                  children: t('fund_my_project.triptych.button.see_more'),
                }}
              />
            }
            item3={
              <CardWithButton
                title={
                  <>
                    {t('fund_my_project.triptych.item3.title')}
                    <StyledTag variant="dark">Nouveau</StyledTag>
                  </>
                }
                titleProps={{ tag: 'h3' }}
                paragraph={t('fund_my_project.triptych.item3.paragraph')}
                illustration={<HandIllustration width="135" height="263" />}
                horizontalStroke={false}
                marginBetweenImgAndBody="3"
                buttonProps={{
                  as: 'a',
                  href: routeFor(FUND_MY_PROJECT_SUBSCRIPTION),
                  children: t('fund_my_project.triptych.button.see_more'),
                }}
                smallerImage
              />
            }
          />
        </GridCol>
      </Grid>
    </StyledSolutionTriptych>
  )
}

export default SolutionsTriptych
