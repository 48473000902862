import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import findIndex from 'lodash/fp/findIndex'
import { RewardCard } from '@kisskissbankbank/kitten'

const Infos = ({ reward, showShippingCost }) => {
  const t = useTranslation()
  const months = t('date.month_names', { returnObjects: true })

  const getShippingInfo = (zone) => {
    if (!zone) return null

    const shippingCurrencySymbol = t(`currency_symbol.${zone.amount.currency}`)
    const shippingCost = t(zone.amount.cents / 100, { formatNumber: true })

    return {
      amount: t('project_info.amount_with_currency', {
        amount: shippingCurrencySymbol,
        currency: shippingCost,
        parseHtml: true,
      }),
      destination: t(`countries.${zone.zoneCode}`),
    }
  }

  const primaryDeliveryZone =
    reward.deliveryZones[findIndex(['priority', 1])(reward.deliveryZones)]
  const shipping = getShippingInfo(primaryDeliveryZone)

  return (
    <>
      {showShippingCost && !!primaryDeliveryZone && (
        <RewardCard.Info
          legend={t('reward_card.shipping_cost')}
          value={`${shipping.amount} ${t('reward_card.shipping_destination', {
            destination: shipping.destination,
          })}`}
        />
      )}
      {!!reward.shippingDate && (
        <RewardCard.Info
          fullWidth={!showShippingCost}
          legend={t('reward_card.delivery_small_text')}
          value={`${t(`${months[reward.shippingDate.month]}`)} ${
            reward.shippingDate.year
          }`}
        />
      )}
    </>
  )
}

export default Infos
