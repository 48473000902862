import React from 'react'
import Form from './components/form'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Helmet } from 'react-helmet'
import ErrorBoundary from 'kiss/app/layouts/error-boundary'
import { Text } from '@kisskissbankbank/kitten'
import { useSelector } from 'react-redux'
import { isSpecificShippingForDonationReceipts } from '../cart/redux'
import DefiscModal from './components/defisc-modal'
import FacebookPixelProvider from 'kiss/utils/tracking/facebook-pixel/provider'
import FacebookPixelTracker from 'kiss/utils/tracking/facebook-pixel/tracker'

const ShippingAddress = () => {
  const t = useTranslation()
  const specificShippingForDonationReceipts = useSelector(
    isSpecificShippingForDonationReceipts,
  )

  return (
    <ErrorBoundary>
      <FacebookPixelProvider>
        <FacebookPixelTracker eventName="begin_checkout" />
      </FacebookPixelProvider>

      <Helmet title={t('contribute.shipping_address.head.title')} />

      <div className="k-u-margin-bottom-double">
        <Text
          tag="h1"
          size="large"
          weight="600"
          className="k-u-margin-bottom-double"
        >
          {specificShippingForDonationReceipts
            ? t('contribute.shipping_address.donation_receipts.title')
            : t('contribute.shipping_address.title')}
        </Text>
        {specificShippingForDonationReceipts && <DefiscModal />}
      </div>
      <Form />
    </ErrorBoundary>
  )
}

export default ShippingAddress
