import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUserPermissions } from 'kiss/modules/project-page/page-state/selectors'
import { acceptProject, markProjectAsToImprove, rejectProject } from './actions'
import { Formik } from 'formik'
import { addSuccessAlert, removeAllAlerts } from 'kiss/app/alerts/redux'
import { Button, pxToRem } from '@kisskissbankbank/kitten'
import Dialog from 'kiss/app/modal/dialog'

const Actions = styled.div`
  & > button {
    margin-right: ${pxToRem(20)};
  }

  & > button.error {
    color: var(--color-danger-500);
    border-color: var(--color-danger-100);
  }

  & > button.error:not([disabled]):hover {
    color: var(--color-grey-000);
    background-color: var(--color-danger-500);
    border-color: var(--color-danger-500);
  }
`
const getColorButton = (action) => {
  switch (action) {
    case 'reject':
      return 'iron'
    case 'markAsToImprove':
      return 'neon'
    default:
      return 'helium'
  }
}
const Form = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const { canAccept, canReject, canMarkAsToImprove } = useSelector(
    getCurrentUserPermissions,
  )

  return (
    <Formik
      initialValues={{
        action: 'reject',
      }}
      onSubmit={(values) => {
        const { action } = values

        switch (action) {
          case 'accept':
            return dispatch(acceptProject())
              .then(() => {
                dispatch(
                  addSuccessAlert(
                    t('projects.admin_panel.alerts.project_accepted'),
                    { scroll: true },
                  ),
                )
              })
              .catch(() => {})
          case 'markAsToImprove':
            return dispatch(markProjectAsToImprove())
              .then(() => {
                dispatch(
                  addSuccessAlert(
                    t(
                      'projects.admin_panel.alerts.project_marked_as_to_improve',
                    ),
                    { scroll: true },
                  ),
                )
              })
              .catch(() => {})

          case 'reject':
            return dispatch(rejectProject())
              .then(() => {
                dispatch(
                  addSuccessAlert(
                    t('projects.admin_panel.alerts.project_rejected'),
                    { scroll: true },
                  ),
                )
              })
              .catch(() => {})

          default:
            return
        }
      }}
    >
      {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
        const handleActionClick = (action) => () => {
          dispatch(removeAllAlerts())
          setFieldValue('action', action, false)
        }

        return (
          <form>
            <Dialog
              title={t(
                `projects.admin_panel.modal.are_you_sure_${values.action}`,
                { parseHtml: true },
              )}
              primaryActionModifier={getColorButton(values.action)}
              primaryActionText={t(`projects.admin_panel.${values.action}`)}
              primaryAction={(close) => {
                handleSubmit()
                close()
              }}
              secondaryActionText={t('app.submit.cancel')}
              secondaryAction={(close) => close()}
            >
              <Actions>
                {canAccept && (
                  <Button
                    type="button"
                    modifier="helium"
                    size="small"
                    disabled={isSubmitting}
                    onClick={handleActionClick('accept')}
                  >
                    {t('projects.admin_panel.feedbacks.accept')}
                  </Button>
                )}
                {canMarkAsToImprove && (
                  <Button
                    type="button"
                    modifier="neon"
                    size="small"
                    disabled={isSubmitting}
                    onClick={handleActionClick('markAsToImprove')}
                  >
                    {t('projects.admin_panel.feedbacks.mark_as_to_improve')}
                  </Button>
                )}
                {canReject && (
                  <Button
                    type="button"
                    modifier="iron"
                    size="small"
                    disabled={isSubmitting}
                    onClick={handleActionClick('reject')}
                  >
                    {t('projects.admin_panel.feedbacks.reject')}
                  </Button>
                )}
              </Actions>
            </Dialog>
          </form>
        )
      }}
    </Formik>
  )
}

export default Form
