import query from 'kiss/api/graphql-query'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import updateAddressMutation from 'kiss/graphql/mutations/contribute/update_address.graphql'
import updateCartShippingAddressQuery from 'kiss/graphql/mutations/contribute/update_cart_shipping_address.graphql'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import omit from 'lodash/fp/omit'
import { createSelector } from '@reduxjs/toolkit'
import { getBillingAddressId, isBillingAddressRequired } from '../cart/redux'
import {
  getProjectSlug,
  NAME,
  SELECT_COUNTRY,
  UPDATE_ADDRESS,
  UPDATE_BILLING_ADDRESS,
} from '../redux'

export const updateAddress = (shippingAddress) => (dispatch, getState) => {
  const state = getState()
  const projectSlug = getProjectSlug(state)

  return query(
    updateCartShippingAddressQuery,
    { projectSlug, shippingAddress },
    state,
  ).then(
    (response) => {
      return dispatch({
        type: UPDATE_ADDRESS,
        payload: omit(['__typename'])(response.updateCartShippingAddress),
      })
    },
    (_error) => {
      dispatch(addErrorAlert('Error: something went wrong!'))
    },
  )
}

export const updateBillingAddress =
  ({ values, sameAddress }) =>
  (dispatch, getState) => {
    const state = getState()
    const addressIfSameAddress = omit([
      'additional2',
      'additional3',
      'additional4',
      'billingAddress',
    ])(values)
    const differentBillindAddress = omit([
      'additional2',
      'additional3',
      'additional4',
    ])(values.billingAddress)
    const billingAddressId = getBillingAddressId(state)
    const billingAddressRequired = isBillingAddressRequired(state)
    if (!billingAddressId || !billingAddressRequired) return Promise.resolve()
    return query(updateAddressMutation, {
      id: billingAddressId,
      address: sameAddress
        ? {
            ...addressIfSameAddress,
            address2: values.additional2,
            address3: values.additional3,
            address4: values.additional4,
          }
        : {
            ...differentBillindAddress,
            address2: values.billingAddress?.additional2,
            address3: values.billingAddress?.additional3,
            address4: values.billingAddress?.additional4,
          },
    }).then(
      (response) => {
        return dispatch({
          type: UPDATE_BILLING_ADDRESS,
          payload: omit(['__typename'])(response.updateAddress),
        })
      },
      (_error) => {
        dispatch(addErrorAlert('Error: something went wrong!'))
      },
    )
  }

export const updateSelectedCountry = (countryCode) => (dispatch) => {
  dispatch({
    type: SELECT_COUNTRY,
    payload: {
      project: {
        cart: {
          shippingAddress: { countryCode },
        },
      },
    },
  })
}

export const getShippingAddress = (state) => {
  const address = getOr(null)(`${NAME}.project.cart.shippingAddress`)(state)
  return omit(['__typename'])(address)
}

export const getBillingAddress = (state) => {
  const address = getOr(null)(`${NAME}.project.cart.billingAddress`)(state)
  return omit(['__typename'])(address)
}

const getContributor = (state) => {
  const address = getOr(null)(`${NAME}.project.cart.contributor`)(state)
  return omit(['__typename'])(address)
}

export const getAddress = createSelector(
  [getShippingAddress, getContributor],
  (shippingAddress, contributor) => {
    return {
      firstName:
        get('firstName')(shippingAddress) ||
        getOr('')('firstName')(contributor),
      lastName:
        get('lastName')(shippingAddress) || getOr('')('lastName')(contributor),
      companyName:
        get('companyName')(shippingAddress) ||
        getOr('')('shippingAddress.companyName')(contributor),
      phone:
        get('phone')(shippingAddress) ||
        getOr('')('shippingAddress.phone')(contributor),
      address1:
        get('address1')(shippingAddress) ||
        getOr('')('shippingAddress.address1')(contributor),
      address2:
        get('address2')(shippingAddress) ||
        getOr('')('shippingAddress.address2')(contributor),
      address3:
        get('address3')(shippingAddress) ||
        getOr('')('shippingAddress.address3')(contributor),
      address4:
        get('address4')(shippingAddress) ||
        getOr('')('shippingAddress.address4')(contributor),
      postalCode:
        get('postalCode')(shippingAddress) ||
        getOr('')('shippingAddress.postalCode')(contributor),
      city:
        get('city')(shippingAddress) ||
        getOr('')('shippingAddress.city')(contributor),
      countryCode:
        getOr('')('countryCode')(shippingAddress) ||
        getOr('')('shippingAddress.countryCode')(contributor),
    }
  },
)
