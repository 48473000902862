import { DownloadIcon, Separator, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const DownloadInvoice = ({ order }) => {
  const t = useTranslation()
  const invoice = order?.invoice

  if (!invoice) return null
  return (
    <>
      <Separator className="k-u-margin-top-singleHalf k-u-margin-bottom-singleHalf" />
      <Text
        tag="a"
        href={invoice.url}
        download
        size="small"
        weight="500"
        className="k-u-reset-button k-u-link k-u-link-primary1 k-u-flex k-u-flex-alignItems-center k-u-flex-justifyContent-start"
        rel="noopener"
      >
        <DownloadIcon
          className="k-u-margin-right-single"
          color="var(--color-primary-500)"
        />
        {t('contributor.orders.invoice.download')}
      </Text>
    </>
  )
}

export default DownloadInvoice
