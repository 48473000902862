import mutation from 'kiss/api/graphql-query'
import query from 'kiss/api/graphql-query'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import updateMentorMutation from 'kiss/graphql/mutations/user/update_mentor.graphql'
import MentorProjectsQuery from 'kiss/graphql/queries/mentor/load_projects.graphql'
import { getCurrentUserSlug, isCurrentUserAdmin } from 'kiss/session/redux'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import map from 'lodash/fp/map'
import { createSelector } from '@reduxjs/toolkit'

const NAME = 'MENTOR_PAGE'
const UPDATE_MENTOR = `${NAME}/UPDATE_MENTOR`
const UPDATE_MENTORED_PROJECTS = `${NAME}/UPDATE_MENTORED_PROJECTS`

export const UPLOADER_ACCEPT = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
]
export const UPLOADER_MAX_SIZE = 5000000 // 5 Mo.

const initialState = {
  slug: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MENTOR:
      return { ...state, ...action.payload }
    case UPDATE_MENTORED_PROJECTS: {
      return {
        ...state,
        mentoredProjects: {
          edges: [...state.mentoredProjects.edges, ...action.payload],
        },
      }
    }
    default:
      return state
  }
}

export const updateMentor = (values) => async (dispatch, getState) => {
  const state = getState()
  const mentorId = getId(state)

  try {
    const mentor = await mutation(
      updateMentorMutation,
      {
        id: mentorId,
        mentor: {
          ...values,
        },
      },
      state,
    )

    dispatch({
      type: UPDATE_MENTOR,
      payload: mentor.updateMentor,
    })
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const updateMentoredProjects =
  ({ after }) =>
  async (dispatch, getState) => {
    const state = getState()
    const mentorSlug = getMentorSlug(state)

    try {
      const projects = await query(
        MentorProjectsQuery,
        {
          slug: mentorSlug,
          after,
        },
        state,
      )

      dispatch({
        type: UPDATE_MENTORED_PROJECTS,
        payload: projects.user.mentoredProjects.edges,
      })
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const getMentorSlug = (state) =>
  getOr(initialState.mentorSlug)(`${NAME}.slug`)(state)

export const canEditPage = createSelector(
  [isCurrentUserAdmin, getCurrentUserSlug, getMentorSlug],
  (isAdmin, currentUserSlug, currentMentorSlug) =>
    isAdmin || currentUserSlug === currentMentorSlug,
)
export const getId = (state) => getOr(null)(`${NAME}.id`)(state)

export const getUsername = (state) => getOr(null)(`${NAME}.username`)(state)

export const getCity = (state) => getOr(null)(`${NAME}.city`)(state)

export const getPostalCode = (state) => getOr(null)(`${NAME}.postalCode`)(state)

export const getNumberOfBackedProjects = (state) =>
  getOr(0)(`${NAME}.numberOfBackedProjects`)(state)

export const getBackedAmountCents = (state) =>
  getOr(0)(`${NAME}.backedAmount.cents`)(state)

export const getBackedAmountCurrency = (state) =>
  getOr('€')(`${NAME}.backedAmount.currency`)(state)

export const getDescription = (state) =>
  getOr(null)(`${NAME}.description`)(state)

export const getTitle = (state) => getOr(null)(`${NAME}.title`)(state)

export const getWebsiteUrl = (state) => getOr(null)(`${NAME}.websiteUrl`)(state)

export const getFacebookUrl = (state) =>
  getOr(null)(`${NAME}.facebookUrl`)(state)

export const getTwitterUrl = (state) => getOr(null)(`${NAME}.twitterUrl`)(state)

export const getLinkedInUrl = (state) =>
  getOr(null)(`${NAME}.linkedInUrl`)(state)

export const getInstagramUrl = (state) =>
  getOr(null)(`${NAME}.instagramUrl`)(state)

export const getCustomFields = (state) =>
  getOr({})(`${NAME}.customFields`)(state)

export const isNumberOfBackedProjectsHidden = (state) =>
  getOr(false)(`${NAME}.hideNumberOfBackedProjects`)(state)

export const isBackedAmountHidden = (state) =>
  getOr(false)(`${NAME}.hideBackedAmount`)(state)

export const getMentoredProjectCards = (state) => {
  return flow(
    getOr([])(`${NAME}.mentoredProjects.edges`),
    map(({ node }) => node),
  )(state)
}

export const getCoverImageUrl = (state) =>
  getOr(null)(`${NAME}.coverImage.url`)(state)

export const getAvatarImageUrl = (state) =>
  getOr(null)(`${NAME}.avatarImage.url`)(state)

export const getInitialValues = createSelector(
  [getTitle, getDescription],
  (title, description) => {
    return {
      coverImageFile: undefined,
      avatarImageFile: undefined,
      title: title || '',
      description: description || '',
    }
  },
)

export default { [NAME]: reducer }
