import { gql } from '@apollo/client'
import { Field } from '@kisskissbankbank/kitten'
import { ErrorMessage, Formik } from 'formik'
import { useMutation } from 'kiss/api/graphql-query'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import FormikInputWithButton from 'kiss/components/formik/formik-input-with-button'
import { useTranslation } from 'kiss/hooks/use-translation'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { getProjectId } from '../../../page-state/selectors'

const SAVE_EMAIL_FOR_LAUNCH_MUTATION = gql`
  mutation SubscribeEmailToCampaign(
    $email: String!
    $campaignCode: String!
    $projectId: ID!
  ) {
    subscribeEmailToCampaign(
      email: $email
      campaignCode: $campaignCode
      projectId: $projectId
    ) {
      email
      id
    }
  }
`

const EmailSection = ({ onSuccess }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const projectId = useSelector(getProjectId)
  const [saveEmailForLaunch] = useMutation(SAVE_EMAIL_FOR_LAUNCH_MUTATION)

  return (
    <Formik
      initialValues={{ email: '' }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .trim()
          .email(t('login.form.email.error.invalid'))
          .required(t('prelaunch.email.error.required')),
      })}
      onSubmit={async ({ email }) => {
        try {
          await saveEmailForLaunch({
            variables: {
              email: email.trim(),
              campaignCode: 'prelaunch',
              projectId,
            },
          })
          onSuccess()
        } catch {
          dispatch(addErrorAlert('Error: something went wrong!'))
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <div className="kiss-PreLaunch__form">
            <Field.Label center labelProps={{ htmlFor: 'email' }}>
              {t('prelaunch.join', { parseHtml: true })}
            </Field.Label>
            <FormikInputWithButton
              name="email"
              center={true}
              placeholder={t('prelaunch.email.label')}
              buttonValue={t('prelaunch.email.submit')}
              wrapperProps={{
                className: 'k-u-flex-wrap-wrap@s-down',
              }}
              modifier="helium"
              buttonProps={{
                type: 'submit',
                onClick: () => {
                  if (!isSubmitting) {
                    handleSubmit()
                  }
                },
                disabled: isSubmitting,
              }}
            />
            <ErrorMessage name="email">
              {(msg) => <Field.ErrorMessage>{msg}</Field.ErrorMessage>}
            </ErrorMessage>
            <Field.Help>
              {t('prelaunch.email.disclaimer', { parseHtml: true })}
            </Field.Help>
          </div>
        )
      }}
    </Formik>
  )
}

EmailSection.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default EmailSection
