import React from 'react'
import PropTypes from 'prop-types'
import { Field } from '@kisskissbankbank/kitten'
import { ErrorMessage, Field as FormikField } from 'formik'
import prop from 'lodash/fp/prop'

const Checkbox = ({
  field,
  form,
  text,
  children,
  disabled,
  value,
  onChange,
  ...props
}) => {
  const fieldName = prop(field.name)
  const fieldValues = fieldName(form.values)
  return (
    <>
      {Array.isArray(fieldValues) ? (
        <Field.Checkbox
          id={field.name}
          {...field}
          value={value}
          onChange={({ target: { value } }) => {
            const fieldValue = fieldName(form.values)
            const action = !fieldValue.includes(value)
              ? (fieldValue) => [...fieldValue, value]
              : (fieldValue) => fieldValue.filter((item) => item !== value)

            form.setFieldValue(field.name, action(fieldValue))

            onChange(fieldValue)
          }}
          checked={fieldName(form.values).includes(value)}
          disabled={disabled}
          error={fieldName(form.errors) && fieldName(form.touched)}
          {...props}
        >
          <span>{children || text}</span>
        </Field.Checkbox>
      ) : (
        <Field.Checkbox
          id={field.name}
          {...field}
          disabled={disabled}
          checked={!!field.value}
          onChange={(evt) => {
            form.setFieldValue(field.name, evt.target.checked)
            onChange(evt)
          }}
          error={fieldName(form.errors) && fieldName(form.touched)}
          {...props}
        >
          <span>{children || text}</span>
        </Field.Checkbox>
      )}

      <ErrorMessage name={field.name}>
        {(msg) => <Field.ErrorMessage>{msg}</Field.ErrorMessage>}
      </ErrorMessage>
    </>
  )
}

const FormikCheckbox = (props) => {
  return <FormikField {...props} component={Checkbox} />
}

FormikCheckbox.defaultProps = {
  onChange: () => {},
  validate: () => {},
  disabled: false,
}

FormikCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
}

export default FormikCheckbox
