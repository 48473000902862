import React from 'react'
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'
import { useSelector } from 'react-redux'
import DefinitionList from './definition-list'
import {
  getOrganization,
  getOrganizationMembers,
} from 'kiss/modules/project-page/page-state/selectors'
import { Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, USER_PUBLIC_PROFILE } from 'kiss/routes/redux'

const Owner = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const members = useSelector(getOrganizationMembers)
  const organization = useSelector(getOrganization)

  const membersFormatted = flow(
    map((member) => member.node),
    filter((member) => member.id !== organization?.contact?.id),
  )(members)
  return (
    <>
      <DefinitionList.Title>
        <Text weight="600" size="medium" color="font1">
          {t('projects.admin_panel.owner')}
        </Text>
      </DefinitionList.Title>
      <DefinitionList.Description>
        <ul>
          <li>
            <Text
              tag="a"
              href={routeFor(USER_PUBLIC_PROFILE, {
                user: organization?.contact?.slug,
              })}
              weight="500"
              size="medium"
              color="primary1"
            >
              {`${organization?.contact?.firstName} ${organization?.contact?.lastName}`}
            </Text>
          </li>
          <li>
            <Text weight="400" size="medium" color="font1">
              {organization?.contact?.phone}
            </Text>
          </li>
          <li>
            <Text
              tag="a"
              href={`mailto:${organization?.contact?.email}`}
              weight="500"
              size="medium"
              color="primary1"
            >
              {organization?.contact?.email}
            </Text>
          </li>
          <div className="k-u-margin-top-single">
            {membersFormatted.map((member) => (
              <li key={member.id}>
                <Text
                  tag="a"
                  href={routeFor(USER_PUBLIC_PROFILE, {
                    user: member.slug,
                  })}
                  weight="500"
                  size="medium"
                  color="primary1"
                >
                  {`${member.firstName} ${member.lastName}`}
                </Text>
              </li>
            ))}
          </div>
        </ul>
      </DefinitionList.Description>
    </>
  )
}

export default Owner
