import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Button, pxToRem, Text } from '@kisskissbankbank/kitten'
import { isLogged } from 'kiss/session/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import {
  getRouteFor,
  PROJECT_ROUTE,
  AUTHENTICATE_ROUTE,
} from 'kiss/routes/redux'

import { scrollTo } from 'kiss/utils/animation/scroll-to'
import { isStarted } from 'kiss/utils/project/state'
import { REWARDS_MOBILE_ID } from 'kiss/modules/project-page/page-state/redux'
import {
  getGoalType,
  getProjectImageCropped,
  getProjectRewards,
  getProjectSlug,
  getProjectState,
} from 'kiss/modules/project-page/page-state/selectors'

import DesktopModal from 'kiss/modules/project-page/campaign/components/reward-modal/desktop'
import Mobile from 'kiss/modules/project-page/campaign/components/reward-modal/mobile'

import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import orderBy from 'lodash/fp/orderBy'

const LockedNewsWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: ${pxToRem(225)};
  overflow: hidden;

  .kiss-lockedNews__blur-layer {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: ${pxToRem(225)};
    background-size: cover;
    background-image: var(--background-image);
    filter: blur(${pxToRem(20)});
  }

  .kiss-lockedNews__opacity-layer {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: ${pxToRem(225)};
    background: rgba(0, 0, 0, 0.4);
  }
`

const cheapestRewardId = flow(
  map(({ node }) => ({
    id: node.id,
    price: node.price.cents,
    soldOut: node.soldOut,
  })),
  filter({ soldOut: false }),
  orderBy(['price'], ['asc']),
  first,
  get('id'),
)

const LockedNews = ({ rewardsVisibility, visibility }) => {
  const [showRewardModal, setShowRewardModal] = useState(false)

  const { viewportIsMOrLess } = useMediaQuery()
  const t = useTranslation()

  const userIsLogged = useSelector(isLogged)
  const goalType = useSelector(getGoalType)
  const projectSlug = useSelector(getProjectSlug)
  const routeFor = useSelector(getRouteFor)
  const imageUrl = useSelector(getProjectImageCropped)
  const projectState = useSelector(getProjectState)
  const rewards = useSelector(getProjectRewards)

  const rewardToApply = find({ id: cheapestRewardId(rewardsVisibility) })(
    rewards,
  )
  const history = useHistory()

  return (
    <>
      <LockedNewsWrapper style={{ '--background-image': `url('${imageUrl}')` }}>
        <div className="kiss-lockedNews__blur-layer" />
        <div className="kiss-lockedNews__opacity-layer" />
        <Text
          size="large"
          color="background1"
          weight="600"
          className="k-u-margin-bottom-double k-u-margin-horizontal-double k-u-align-center"
        >
          {isStarted(projectState)
            ? t(`comments.locked.${goalType}.label`, { parseHtml: true })
            : t('comments.locked.ended.label', { parseHtml: true })}
        </Text>
        {isStarted(projectState) && (
          <Button
            fit="content"
            modifier="helium"
            onClick={() => {
              if (visibility === 'rewards' && rewardToApply) {
                setShowRewardModal(true)
              } else if (viewportIsMOrLess) {
                scrollTo(document.getElementById(REWARDS_MOBILE_ID), 500, {
                  offset: -80,
                })
              } else {
                history.push(routeFor(PROJECT_ROUTE, { project: projectSlug }))
              }
            }}
          >
            {t(`comments.locked.${goalType}.button`)}
          </Button>
        )}
        {!userIsLogged && (
          <Text
            tag="a"
            href={routeFor(AUTHENTICATE_ROUTE)}
            color="background1"
            size="small"
            className="k-u-margin-top-double k-u-align-center"
          >
            {t('comments.locked.news.login', { parseHtml: true })}
          </Text>
        )}
      </LockedNewsWrapper>
      {showRewardModal &&
        (viewportIsMOrLess ? (
          <Mobile
            reward={rewardToApply}
            modalProps={{
              onAfterClose: () => setShowRewardModal(false),
            }}
          />
        ) : (
          <DesktopModal
            reward={rewardToApply}
            modalProps={{
              onAfterClose: () => setShowRewardModal(false),
            }}
          />
        ))}
    </>
  )
}

export default LockedNews
