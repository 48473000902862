import React, { useEffect } from 'react'
import {
  IconBadge,
  QuestionMarkIcon,
  Text,
  Toggletip,
} from '@kisskissbankbank/kitten'
import isEmpty from 'lodash/fp/isEmpty'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useCart } from '../../../../hooks'
import Wrapper from '../../../../components/summary/components/wrapper'
import Stroke from '../../../../components/summary/components/stroke'
import Separator from '../../../../components/summary/components/separator'
import AmountLine from '../../../../components/summary/components/amount-line'
import RewardItem from '../../../../components/summary/components/reward-item'
import ExplainBlock from '../components/explain-block'

const FlowSummary = () => {
  const t = useTranslation()
  const { cart, loading, refetch } = useCart()
  const shippingCountryCode = cart?.shippingAddress?.countryCode

  const reward = cart?.lines?.[0]?.variation?.reward ?? null

  useEffect(() => {
    refetch()
  }, [])

  if (loading || isEmpty(cart)) return null

  const getFormattedAmount = (amount) => {
    return t('project_info.amount_with_currency', {
      amount: t(amount, { formatNumber: { decimal: 2 } }),
      currency: t(`currency_symbol.${cart?.amountToPay?.currency}`),
      parseHtml: true,
    })
  }

  const donationAmount = (cart?.donationAmount?.cents ?? 0) / 100
  const serviceFeeAmount = cart?.serviceFeeAmount?.cents / 100

  return (
    <Wrapper>
      <Text
        tag="h2"
        size="small"
        weight="600"
        color="font1"
        className="k-u-margin-bottom-single"
      >
        {t('cart.contribute.your_summary')}
      </Text>
      <Stroke />
      {reward && (
        <>
          <RewardItem
            title={reward?.label}
            imageUrl={reward?.image?.url}
            description={reward?.description}
            className="k-u-margin-vertical-single"
          />

          <Separator />
        </>
      )}

      {donationAmount !== 0 ? (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.summary.donation')}
          </Text>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(donationAmount)}
          </Text>
        </AmountLine>
      ) : (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.summary.subscription', {
              date:
                (cart?.startOnNextPeriod
                  ? reward?.nextPeriodLabel
                  : reward?.subscribablePeriodLabel) || '',
            })}
          </Text>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(cart?.linesAmount?.cents / 100)}
          </Text>
        </AmountLine>
      )}

      {shippingCountryCode && (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.summary.shipping', {
              country: t(`countries.${shippingCountryCode}`),
              parseHtml: true,
            })}
          </Text>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(cart?.deliveryAmount?.cents / 100)}
          </Text>
        </AmountLine>
      )}
      {serviceFeeAmount !== 0 && (
        <AmountLine>
          <div>
            <Text size="small" weight="400" color="font1">
              {t('cart.contribute.summary.service_fees.label')}
            </Text>
            <Toggletip
              targetElement={
                <IconBadge size="micro">
                  <QuestionMarkIcon width="8" height="8" />
                </IconBadge>
              }
              className="k-u-margin-left-single"
            >
              <Text>{t('cart.contribute.summary.service_fees.tooltip')}</Text>
            </Toggletip>
          </div>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(serviceFeeAmount)}
          </Text>
        </AmountLine>
      )}
      <Separator />
      <AmountLine>
        <Text size="huge" weight="500" color="font1">
          {t('cart.contribute.summary.total')}
        </Text>
        <Text size="huge" weight="600" color="font1">
          {getFormattedAmount(cart?.amountToPay?.cents / 100)}
        </Text>
      </AmountLine>
      <Separator />
      <ExplainBlock />
    </Wrapper>
  )
}

export default FlowSummary
