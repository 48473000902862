import {
  Button,
  CheckedIcon,
  CrossIconNext,
  EditIconNext,
  List,
  mq,
  pxToRem,
  Text,
} from '@kisskissbankbank/kitten'
import {
  addErrorAlert,
  addSuccessAlert,
  removeAllAlerts,
} from 'kiss/app/alerts/redux'
import Dialog from 'kiss/app/modal/dialog'
import config from 'kiss/config'
import { useTranslation } from 'kiss/hooks/use-translation'
import useNumbers from 'kiss/hooks/use-numbers'
import {
  getCurrentUserPermissions,
  getOrganizationSlug,
  getPayouts,
  getProjectSlug,
  getProjectState,
  getPricingPlan,
  getProjectDeletedAt,
  isHandleServiceFee,
} from 'kiss/modules/project-page/page-state/selectors'
import {
  getRouteFor,
  PROJECT_DONATION_RECEIPT,
  CGU,
  VERCEL_ORGANIZATION_MANAGER,
  VERCEL_PROJECT_MANAGER,
} from 'kiss/routes/redux'
import { isCurrentUserAdmin } from 'kiss/session/redux'
import { isEditProjectActivated, isSuccessful } from 'kiss/utils/project/state'
import useCancellablePromise from 'kiss/utils/promise-helper'
import isEmpty from 'lodash/fp/isEmpty'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { cancelProject, publishProject, submitProject } from './actions'

const ButtonsWrapper = styled.div`
  @media ${mq.mobile} {
    display: grid;
    gap: ${pxToRem(15)};
    grid-template-columns: repeat(2, 1fr);

    .kiss-Button-fullWidth {
      grid-column: span 2;
    }
  }

  @media ${mq.tabletAndDesktop} {
    display: flex;
    gap: ${pxToRem(10)};
  }
`

const Buttons = () => {
  const t = useTranslation()
  const { formatPercent } = useNumbers()
  const { canSubmit, canStartPublication, canCancel } = useSelector(
    getCurrentUserPermissions,
  )
  const payouts = useSelector(getPayouts)
  const state = useSelector(getProjectState)
  const deletedAt = useSelector(getProjectDeletedAt)
  const routeFor = useSelector(getRouteFor)
  const projectSlug = useSelector(getProjectSlug)
  const organization = useSelector(getOrganizationSlug)
  const isAdmin = useSelector(isCurrentUserAdmin)
  const pricingPlan = useSelector(getPricingPlan)
  const handleServiceFee = useSelector(isHandleServiceFee)

  const dispatch = useDispatch()
  const { cancellablePromise } = useCancellablePromise()

  const vercelManagerRoute = organization
    ? routeFor(VERCEL_ORGANIZATION_MANAGER, {
        organization,
        project: projectSlug,
      })
    : routeFor(VERCEL_PROJECT_MANAGER, {
        project: projectSlug,
      })

  const managerRoute = `${config[APP_ENV].manager.host}${vercelManagerRoute}`
  const modalAccept = () => {
    return dispatch(cancelProject()).then(() => {
      dispatch(
        addSuccessAlert(t('projects.owner_panel.alerts.project_deleted'), {
          scroll: true,
        }),
      )
    })
  }

  const handleModalAccept = async (close) => {
    await cancellablePromise(modalAccept())
    close()
  }
  const handlePublishProject = () => {
    dispatch(publishProject())
      .then(() => {
        dispatch(
          addSuccessAlert(t('projects.owner_panel.alerts.project_published'), {
            scroll: true,
          }),
        )
      })
      .catch((err) => {
        if (err === 'BE_MANGOPAY_COMPLIANT') {
          return dispatch(
            addErrorAlert(
              t('projects.owner_panel.project_published.mangopay_error'),
              { scroll: true },
            ),
          )
        }
      })
  }

  const renderPrincingInfos = () => {
    if (pricingPlan?.label === 'legacy') return null

    const planText = handleServiceFee
      ? t('projects.owner_panel.buttons.publish_modal_plan_with_service_fees', {
          plan: pricingPlan?.title.toUpperCase(),
        })
      : t(
          'projects.owner_panel.buttons.publish_modal_plan_without_service_fees',
          {
            plan: pricingPlan?.title.toUpperCase(),
          },
        )

    const feeRateWithoutVat = formatPercent(
      (handleServiceFee
        ? pricingPlan?.feeRateWithoutVat
        : pricingPlan?.feeRateWithoutVatWithoutServiceFee) * 100,
      0,
      1,
      1,
    )

    const feeRate = formatPercent(
      (handleServiceFee
        ? pricingPlan?.feeRate
        : pricingPlan?.feeRateWithoutServiceFee) * 100,
      0,
      1,
      1,
    )

    return (
      <>
        <List
          className="k-u-margin-top-double"
          style={{
            maxWidth: '70%',
            textAlign: 'left',
            margin: 'auto',
          }}
          gap={10}
          markerElement={
            <CheckedIcon
              color="var(--color-primary-500)"
              width="15"
              height="15"
            />
          }
        >
          <Text tag="li" lineHeight="1.3">
            {planText}
          </Text>
          <Text tag="li" lineHeight="1.3">
            {t('projects.owner_panel.buttons.publish_modal_plan_commission', {
              feeRateWithoutVat: feeRateWithoutVat,
              feeRate: feeRate,
            })}
          </Text>
        </List>
        <List
          className="k-u-margin-bottom-double"
          style={{
            maxWidth: '70%',
            textAlign: 'left',
            margin: 'auto',
          }}
          markerElement={
            <CrossIconNext
              color="var(--color-danger-500)"
              width="16"
              height="16"
            />
          }
        >
          <Text tag="li" lineHeight="1.3">
            {t('projects.owner_panel.buttons.publish_modal_no_update')}
          </Text>
        </List>
      </>
    )
  }

  return (
    <ButtonsWrapper className="k-u-flex-justifyContent-end@l-up">
      {canCancel && (
        <Dialog
          className="kiss-Button-fullWidth"
          title={t('projects.owner_panel.buttons.delete.title')}
          primaryActionText={t('projects.owner_panel.buttons.delete.confirm')}
          primaryAction={(close) => handleModalAccept(close)}
          primaryActionModifier="iron"
          secondaryActionText={t('projects.owner_panel.buttons.delete.cancel')}
          secondaryAction={(close) => close()}
        >
          <Button
            type="button"
            modifier="copper"
            fit="content"
            mobileFit="fluid"
            size="small"
            onClick={() => {
              dispatch(removeAllAlerts())
            }}
          >
            {t('projects.owner_panel.buttons.cancel')}
          </Button>
        </Dialog>
      )}

      {!isEmpty(payouts) && (
        <Button
          as="a"
          href={`${managerRoute}/admin/invoices`}
          modifier="hydrogen"
          fit="content"
          size="small"
        >
          {t('projects.owner_panel.buttons.invoice')}
        </Button>
      )}
      {isSuccessful(state) && (
        <Button
          as="a"
          href={routeFor(PROJECT_DONATION_RECEIPT, { project: projectSlug })}
          modifier="hydrogen"
          fit="content"
          size="small"
        >
          {t('projects.owner_panel.buttons.donation_receipt')}
        </Button>
      )}
      {(isEditProjectActivated(state) || isAdmin) && !deletedAt && (
        <Button
          as="a"
          className="kiss-Button-fullWidth"
          modifier={canSubmit ? 'hydrogen' : 'helium'}
          fit="content"
          size="small"
          href={managerRoute}
        >
          <EditIconNext aria-hidden />
          <span>{t('projects.owner_panel.buttons.edit')}</span>
        </Button>
      )}

      {canSubmit && (
        <Button
          type="button"
          modifier="helium"
          fit="content"
          className="kiss-Button-fullWidth"
          size="small"
          onClick={() => {
            dispatch(submitProject()).then(() => {
              dispatch(
                addSuccessAlert(
                  t('projects.owner_panel.alerts.project_submitted'),
                  { scroll: true },
                ),
              )
            })
          }}
        >
          {t('projects.owner_panel.buttons.submit_for_validation')}
        </Button>
      )}
      {canStartPublication && (
        <Dialog
          title={t('projects.owner_panel.buttons.publish_modal_confirm', {
            parseHtml: true,
          })}
          primaryActionText={t(
            'projects.owner_panel.buttons.publish_modal_confirm_button',
          )}
          primaryAction={(close) => {
            handlePublishProject()
            close()
          }}
          primaryActionProps={{
            fit: 'fluid',
            className: 'k-u-margin-right-single@s-up',
          }}
          secondaryActionText={t('projects.owner_panel.buttons.delete.cancel')}
          secondaryAction={(close) => close()}
          secondaryActionProps={{
            fit: 'fluid',
            className: 'k-u-margin-left-single@s-up',
          }}
          content={
            <>
              <Text tag="p" lineHeight="1.3">
                {t(
                  'projects.owner_panel.buttons.publish_modal_confirm_content_permanent',
                )}
              </Text>
              {renderPrincingInfos()}
              <Text tag="p" lineHeight="1.3" className="k-u-margin-top-single">
                {t(
                  'projects.owner_panel.buttons.publish_modal_confirm_content_cgu_permanent',
                  {
                    termsUrl: routeFor(CGU),
                    parseHtml: true,
                  },
                )}
              </Text>
            </>
          }
        >
          <Button type="button" modifier="helium" fit="content" size="small">
            {t('projects.owner_panel.buttons.publish')}
          </Button>
        </Dialog>
      )}
    </ButtonsWrapper>
  )
}

export default Buttons
