import {
  HEADER_HEIGHT,
  HeroLayout,
  mq,
  pxToRem,
  LongArrowIcon,
} from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import { useSelector } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'
import {
  getPageColors,
  getProject,
  currentUserIsBacking,
} from '../../../../page-state/selectors'
import Profile from './profile'
import Hero from './hero'
import {
  getRouteFor,
  PROJECT_NAVIGATION_NEWS_TAB_ROUTE,
  SUBSCRIBER_PROJECT_NEWS_ROUTE,
} from 'kiss/routes/redux'

const GlobalStyle = createGlobalStyle`
  .k-ProfileCard {
    max-width: ${pxToRem(300)};
  }
  .k-FloatingMenu {
    @media ${mq.mobileAndTablet} {
      margin-inline: ${pxToRem(-20)};
    }
  }
  .k-HeroLayout__page .k-HeroLayout__lastAside.k-HeroLayout__sticky--desktop {
    z-index: 3 !important;
  }
  .k-HeroLayout__firstAside.story-topAdjusted .k-HeroLayout__sticky__inside {
    top: ${pxToRem(HEADER_HEIGHT + 120)} !important;
  }
  .k-HeaderNav--isStuck + * {
    .k-HeroLayout__topMenu,
    .k-HeroLayout__topMenuBg {
      .k-HeroLayout__sticky__insideTop {
        top: ${pxToRem(HEADER_HEIGHT)} !important;
      }
    }
    @media ${mq.mobileAndTablet} {
      .k-HeroLayout__topMenu,
      .k-HeroLayout__topMenuBg,
      .k-HeroLayout__firstAside {
        &.k-HeroLayout__sticky--both {
          top: ${pxToRem(HEADER_HEIGHT)} !important;
        }
      }
    }
  }
`

const StyledBackLink = styled.a`
  height: ${pxToRem(50)};
  display: inline-flex;
  align-items: center;
  gap: ${pxToRem(5)};

  @media ${mq.desktop} {
    height: ${pxToRem(60)};
  }
`

const NewsLayout = ({ children }) => {
  const t = useTranslation()
  const pageColors = useSelector(getPageColors)
  const project = useSelector(getProject)
  const { viewportIsMOrLess } = useMediaQuery()
  const routeFor = useSelector(getRouteFor)
  const userIsBacking = useSelector(currentUserIsBacking)

  const backLink = userIsBacking
    ? SUBSCRIBER_PROJECT_NEWS_ROUTE
    : PROJECT_NAVIGATION_NEWS_TAB_ROUTE

  return (
    <DefaultLayout>
      <GlobalStyle />
      <HeroLayout style={pageColors}>
        {viewportIsMOrLess && <Hero />}

        <HeroLayout.Main hasTopMenu>
          {!viewportIsMOrLess && (
            <>
              <HeroLayout.Main.Image>
                <img src={project?.bannerImage?.url} alt="" />
              </HeroLayout.Main.Image>
              <HeroLayout.Main.TopMenu sticky="both">
                <StyledBackLink
                  href={routeFor(backLink, { project: project.slug })}
                  className="k-u-link k-u-link-font1"
                >
                  <LongArrowIcon direction="left" color="currentColor" />
                  <span>{t('project.news.back_link')}</span>
                </StyledBackLink>
              </HeroLayout.Main.TopMenu>
              <HeroLayout.Main.FirstAside sticky="desktop">
                <Profile />
              </HeroLayout.Main.FirstAside>
            </>
          )}
          <HeroLayout.Main.Content>
            {viewportIsMOrLess && (
              <StyledBackLink
                href={routeFor(backLink, { project: project.slug })}
                className="k-u-link k-u-link-font1"
              >
                <LongArrowIcon direction="left" color="currentColor" />
                <span>{t('project.news.back_link')}</span>
              </StyledBackLink>
            )}

            {children}
          </HeroLayout.Main.Content>
        </HeroLayout.Main>
      </HeroLayout>
    </DefaultLayout>
  )
}

export default NewsLayout
