import { gql } from '@apollo/client'
import includes from 'lodash/fp/includes'
import { useMutation } from '../../api/graphql-query'
import { TIMEOUT } from '../config'
import { getBrowserInfo } from '../helpers/browser-infos'
import isTimeout from '../helpers/is-timeout'

const PAY_GIFT_CARD_MUTATION = gql`
  mutation PayGiftCard(
    $id: ID!
    $browserInfo: BrowserInfoInput
    $creditCard: CreditCardInput!
  ) {
    payGiftCard(id: $id, browserInfo: $browserInfo, creditCard: $creditCard) {
      error
      redirectUrl
    }
  }
`

const usePayGiftCard = () => {
  const [payGiftCard] = useMutation(PAY_GIFT_CARD_MUTATION)
  return {
    callPayGiftCard: async ({ id, cardRegistrationUuid, registrationData }) => {
      try {
        const response = await payGiftCard({
          variables: {
            id,
            creditCard: {
              cardRegistrationUuid: cardRegistrationUuid,
              registrationData: registrationData,
            },
            browserInfo: getBrowserInfo(),
          },
        })
        if (isTimeout(response)) {
          return TIMEOUT
        }
        return response.data.payGiftCard.redirectUrl
      } catch (e) {
        if (includes('Timeout')(e.message)) {
          return TIMEOUT
        }
        throw e
      }
    },
  }
}

export default usePayGiftCard
