import { GridCol, Marger } from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import RichContentCarouselNavigation from 'kiss/modules/components/rich-content/carousel-navigation'
import RichContentText from 'kiss/modules/components/rich-content/text'
import Seo from 'kiss/modules/prismic-page/slices/components/seo'
import find from 'lodash/fp/find'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  AsideContainer,
  StickyContent,
  StyledContainer,
  StyledGrid,
} from '../styles'
import { getSlices } from './redux'

const CampaignTipsLayout = () => {
  const slices = useSelector(getSlices)

  const seo = find({ type: 'seo' })(slices)
  const navigationCarousel = find({ type: 'navigation_carousel' })(slices)
  const richText = find({ type: 'rich_text' })(slices)

  return (
    <DefaultLayout>
      {seo && <Seo {...seo} />}

      <StyledContainer>
        <StickyContent>
          <RichContentCarouselNavigation {...navigationCarousel} />
        </StickyContent>
        <AsideContainer>
          <Marger top={{ default: 5, fromS: 10 }} bottom={{ default: 5 }}>
            <StyledGrid>
              <GridCol
                col-xs="12"
                col-s="10"
                offset-s="1"
                col-l="8"
                offset-l="2"
              >
                <Marger top={{ default: 5, fromS: 10 }}>
                  <RichContentText content={richText.content} />
                </Marger>
              </GridCol>
            </StyledGrid>
          </Marger>
        </AsideContainer>
      </StyledContainer>
    </DefaultLayout>
  )
}

export default CampaignTipsLayout
