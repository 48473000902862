import { RewardCard } from '@kisskissbankbank/kitten'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getProjectState,
  isContributingActionDisabled as isContributingActionDisabledSelector,
  isCountGoalType,
  isRewardDisabled,
  isRewardUnavailable as isRewardUnavailableSelector,
} from 'kiss/modules/project-page/page-state/selectors'

import { isFinished } from 'kiss/utils/project/state'
import React from 'react'
import { useSelector } from 'react-redux'

const Button = ({ reward, buttonProps, displayButton }) => {
  const t = useTranslation()
  const projectState = useSelector(getProjectState)
  const shouldDisplayButton = displayButton && !isFinished(projectState)
  const isDisabled = useSelector(isRewardDisabled)(reward)

  const isContributingActionDisabled = useSelector(
    isContributingActionDisabledSelector,
  )

  const projectIsCountGoalType = useSelector(isCountGoalType)
  const isRewardUnavailable = useSelector(isRewardUnavailableSelector)(reward)

  if (!shouldDisplayButton) return null

  const buttonLabel = (() => {
    switch (true) {
      case !!projectIsCountGoalType:
        return t('reward_card.count_goal_action')
      case !!isRewardUnavailable:
        return t('reward_card.run_out_action')
      default:
        return t('reward_card.action')
    }
  })()

  if (buttonProps.isLoading) {
    return (
      <RewardCard.Button>
        <LoadingAnimation color="var(--color-grey-000)" />
      </RewardCard.Button>
    )
  }

  return (
    <RewardCard.Button
      {...buttonProps}
      disabled={
        buttonProps?.disabled || isContributingActionDisabled || isDisabled
      }
    >
      {buttonProps?.children || buttonLabel}
    </RewardCard.Button>
  )
}

export default Button
