import React from 'react'
import { useSelector } from 'react-redux'

import Campaign from './campaign'
import { isPermanent } from './page-state/selectors'
import PermanentVisitor from './permanent/visitor'

const ProjectPage = () => {
  const permanent = useSelector(isPermanent)
  return permanent ? <PermanentVisitor /> : <Campaign />
}

export default ProjectPage
