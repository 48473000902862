import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ChangeCardSuccess from './../change-card-success'
import ChangeCardFailure from './../change-card-failure'
import { getSubscription, getPaymentState } from './redux'
import { useSubscription } from 'kiss/modules/subscription-modal/hooks'

const ChangeCardPolling = ({ isSynchronousConfirmation, subscriptionId }) => {
  const paymentState = useSelector(getPaymentState)
  const paymentRefunded = paymentState === 'refunded'
  const subscription = useSelector(getSubscription)

  const { getSubscription: getSubscriptionFromApi, data } = useSubscription()

  useEffect(() => {
    if (isSynchronousConfirmation) {
      getSubscriptionFromApi({
        variables: {
          id: subscriptionId,
        },
      })
    }
  }, [isSynchronousConfirmation, getSubscriptionFromApi])

  if (isSynchronousConfirmation && data?.subscription) {
    return <ChangeCardSuccess subscription={data.subscription} />
  }

  const reactivationSuccessful =
    paymentState === '' && subscription.state === 'active'

  return paymentRefunded || reactivationSuccessful ? (
    <ChangeCardSuccess subscription={subscription} />
  ) : (
    <ChangeCardFailure />
  )
}

export default ChangeCardPolling
