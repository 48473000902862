import {
  ArrowIcon,
  Button,
  Details,
  FlexWrapper,
  RadioSet,
  SlideModal,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { useTranslation } from 'kiss/hooks/use-translation'
import * as qs from 'neoqs'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getCategories } from '../redux'
import useSearch from '../use-search'

const Content = ({ close }) => {
  const t = useTranslation()
  const { search } = useLocation()
  const categories = useSelector(getCategories)
  const { updateSearch, resetSearch } = useSearch()
  const { state, category } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })
  const defaultCheckedStateValue = state || 'all'

  const [stateSelection, setStateSelection] = useState(defaultCheckedStateValue)
  const [categorySelection, setCategorySelection] = useState(category)
  return (
    <>
      <SlideModal.Title>
        {t('browsing.mobile.filter.title', { parseHtml: true })}
      </SlideModal.Title>
      <SlideModal.Content>
        <FlexWrapper gap={20}>
          <RadioSet
            id="mobile-filter-state"
            name="mobile-filter-state"
            label={t('browsing.mobile.filter.state')}
            weight="400"
            onChange={(e) => {
              const stateValue = e.target?.id?.split('_')
              setStateSelection(stateValue[0])
            }}
            items={[
              {
                text: t('browsing.filter.state.all'),
                id: 'all_mobile-state-filter',
                defaultChecked: defaultCheckedStateValue === 'all',
              },
              {
                text: t('browsing.filter.state.in_progress'),
                id: 'started_mobile-state-filter',
                defaultChecked: defaultCheckedStateValue === 'started',
              },
              {
                text: t('browsing.filter.state.completed'),
                id: 'successful_mobile-state-filter',
                defaultChecked: defaultCheckedStateValue === 'successful',
              },
            ]}
          />
          <Details
            summaryProps={{
              className:
                'k-u-flex k-u-flex-gap-single k-u-flex-alignItems-center',
            }}
            summaryRender={({ open }) => (
              <>
                <span className="k-u-font-label-medium k-u-flex-grow-single">
                  {t('browsing.mobile.filter.category')}
                </span>
                <ArrowIcon aria-hidden direction={open ? 'top' : 'bottom'} />
              </>
            )}
          >
            <RadioSet
              id="mobile-filter-category"
              name="mobile-filter-category"
              className="k-u-margin-top-single"
              weight="400"
              onChange={(e) => {
                const stateValue = e.target?.id?.split('_')
                setCategorySelection(stateValue[0])
              }}
              items={categories.map(({ slug }) => {
                return {
                  text: (
                    <span>
                      <span aria-hidden>
                        {t(`browsing.categories.${slug}.logo`)}
                      </span>{' '}
                      {t(`browsing.categories.${slug}.name`)}
                    </span>
                  ),
                  id: `${slug}_mobile-category-filter`,
                  defaultChecked: category === slug,
                }
              })}
            />
          </Details>
        </FlexWrapper>
      </SlideModal.Content>
      <SlideModal.Actions>
        <Button
          modifier="hydrogen"
          onClick={() => {
            resetSearch()
            close()
          }}
        >
          {t('browsing.mobile.filter.init')}
        </Button>
        <Button
          modifier="beryllium"
          onClick={() => {
            const searchObject = {
              state: stateSelection === 'all' ? undefined : stateSelection,
              category: categorySelection,
            }
            updateSearch(searchObject)
            close()
          }}
        >
          {t('browsing.mobile.filter.show')}
        </Button>
      </SlideModal.Actions>
    </>
  )
}

const Mobile = ({ className = '' }) => {
  const t = useTranslation()
  return (
    <div className={classNames(className, '')}>
      <SlideModal
        trigger={
          <Button fit="fluid" modifier="boron">
            {t('browsing.mobile.filter.trigger')}
          </Button>
        }
      >
        {Content}
      </SlideModal>
    </div>
  )
}

export default Mobile
