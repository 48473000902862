import { gql } from '@apollo/client'
import { useImperativeQuery, useMutation } from 'kiss/api/graphql-query'
import includes from 'lodash/fp/includes'
import { TIMEOUT } from '../config'
import { getBrowserInfo } from '../helpers/browser-infos'
import isTimeout from '../helpers/is-timeout'

const GET_ORDER = gql`
  query OrderQuery($id: ID!) {
    order(id: $id) {
      id
      payment {
        secureModeRedirectUrl
      }
    }
  }
`

const PAY_CREDIT_CARD_MUTATION = gql`
  mutation PayCreditCard(
    $projectId: ID!
    $creditCard: CreditCardInput!
    $browserInfo: BrowserInfoInput
  ) {
    payCreditCard(
      projectId: $projectId
      creditCard: $creditCard
      browserInfo: $browserInfo
    ) {
      redirectUrl
      error
    }
  }
`

const useErrorRedirection = () => {
  const getOrder = useImperativeQuery(GET_ORDER)
  return {
    getErrorRedirectionUrl: async ({ orderId }) => {
      const { data } = await getOrder({ id: orderId })
      return data?.order?.payment?.secureModeRedirectUrl || TIMEOUT
    },
  }
}

const usePayCreditCard = () => {
  const [payCreditCard] = useMutation(PAY_CREDIT_CARD_MUTATION)
  const { getErrorRedirectionUrl } = useErrorRedirection()
  return {
    payCreditCard: async ({
      cardRegistrationUuid,
      registrationData,
      projectId,
      orderId,
    }) => {
      try {
        const response = await payCreditCard({
          variables: {
            projectId,
            creditCard: {
              cardRegistrationUuid: cardRegistrationUuid,
              registrationData: registrationData,
            },
            browserInfo: getBrowserInfo(),
          },
        })
        if (isTimeout(response)) {
          return await getErrorRedirectionUrl({ orderId })
        }
        return response.data.payCreditCard.redirectUrl
      } catch (e) {
        if (includes('Timeout')(e.message)) {
          return await getErrorRedirectionUrl({ orderId })
        }
        throw e
      }
    },
  }
}

export default usePayCreditCard
