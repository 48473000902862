import React from 'react'
import styled from 'styled-components'
import { LiftOff } from 'kiss/components/images/lift-off'
import {
  GridCol,
  FlexWrapper,
  Container,
  Grid,
  Text,
  Title,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'

const FlexWrapperContainer = styled(FlexWrapper)`
  height: 100%;
  align-items: center;
  justify-content: center;
`
const ChangeCardSuccess = ({ subscription }) => {
  const t = useTranslation()
  const paymentContext = subscription.payment ? 'classic' : 'due-installment'

  const label = subscription.variation
    ? subscription.variation.reward.subscribablePeriodLabel
    : subscription.contribution.project.subscribablePeriodLabel

  return (
    <Container>
      <Grid>
        <GridCol offset-xs="0" col-m="10" offset-m="1" col-l="6" offset-l="3">
          <FlexWrapperContainer>
            <LiftOff width="95" height="95" />
            <Title
              modifier="tertiary"
              data-test-id="thank-you-title"
              className="
              k-u-align-center
              k-u-margin-left-none"
            >
              {paymentContext === 'classic'
                ? t(
                    'contributor.orders.means-of-payment.modal.confirmation.title.classic',
                    { parseHtml: true },
                  )
                : t(
                    'contributor.orders.means-of-payment.modal.confirmation.title.due-installment',
                    { parseHtml: true },
                  )}
            </Title>
          </FlexWrapperContainer>
        </GridCol>
        <GridCol offset-xs="0" col-m="10" offset-m="1" col-l="6" offset-l="3">
          <FlexWrapperContainer>
            <Text className="k-u-align-center">
              {paymentContext === 'classic'
                ? t(
                    'contributor.orders.means-of-payment.modal.confirmation.text.classic',
                    {
                      parseHtml: true,
                      amount: subscription.amount.cents / 100,
                      subscribablePeriod: label,
                    },
                  )
                : t(
                    'contributor.orders.means-of-payment.modal.confirmation.text.due-installment',
                  )}
            </Text>
          </FlexWrapperContainer>
        </GridCol>
      </Grid>
    </Container>
  )
}

export default ChangeCardSuccess
