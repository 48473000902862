import React from 'react'
import { Helmet } from 'react-helmet'
import { createGlobalStyle } from 'styled-components'

const AxeptioStyle = createGlobalStyle`
  .ax-widget-container {
    max-width: 420px !important;
  }
`

const GoogleTagManager = () => {
  return (
    <>
      <AxeptioStyle />
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://tss.kisskissbankbank.com/fe2brpnixv2hllq.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-TCJGCRN');`}
        </script>
      </Helmet>
    </>
  )
}

export default GoogleTagManager
