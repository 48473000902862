import React from 'react'
import PropTypes from 'prop-types'
import Label from 'kiss/components/formik/label'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Paragraph } from '@kisskissbankbank/kitten'

const InPerson = ({ description }) => {
  const t = useTranslation()
  return (
    <>
      <Label label={t('project.modal.shipping.in_person.label')} />
      {description && <Paragraph modifier="tertiary">{description}</Paragraph>}
    </>
  )
}

InPerson.propTypes = {
  description: PropTypes.string,
}

InPerson.defaultProps = {
  description: '',
}

export default InPerson
