import Alerts from 'kiss/app/alerts/alerts'
import Footer from 'kiss/app/footer'
import Header from 'kiss/app/header'
import PageLayout from 'kiss/app/layouts/page-layout'
import { PROJECT_ROUTE } from 'kiss/routes/redux'
import PropTypes from 'prop-types'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'

const DefaultLayout = ({
  children,
  displayFooter,
  AboveHeader,
  tinyLogo,
  isHeaderSticky,
  className,
}) => {
  const showMinimalistFooter = useRouteMatch([PROJECT_ROUTE])
  return (
    <PageLayout className={className}>
      <AboveHeader />
      <Header tinyLogo={tinyLogo} isSticky={isHeaderSticky} />
      <main id="main">
        <Alerts />
        {children}
      </main>
      {displayFooter && <Footer minimalist={showMinimalistFooter} />}
    </PageLayout>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  displayFooter: PropTypes.bool,
  AboveHeader: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  tinyLogo: PropTypes.bool,
  isHeaderSticky: PropTypes.bool,
}

DefaultLayout.defaultProps = {
  displayFooter: true,
  AboveHeader: () => null,
  tinyLogo: false,
  isHeaderSticky: null,
}

export default DefaultLayout
