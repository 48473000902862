import usePayGiftCard from './calls/pay-gift-card'
import usePaylineTokenization from './calls/payline-tokenization'
import useRegisterGiftCard from './calls/register-gift-card'
import { getPrefix } from './config/card-type'

const useGiftCardPayment = () => {
  const { callRegisterGiftCard } = useRegisterGiftCard()
  const { callPaylineTokenization } = usePaylineTokenization()
  const { callPayGiftCard } = usePayGiftCard()
  return {
    payGiftCardWithCreditCard: async ({
      giftCardId,
      amountCents,
      cardNumber,
      cvv,
      expiry,
    }) => {
      const cardType = getPrefix(cardNumber)
      const cardRegistration = await callRegisterGiftCard({
        giftCardId,
        cardType,
      })
      const paylineTokenization = await callPaylineTokenization({
        cardNumber,
        cvv,
        expiry,
        cardRegistration,
        amountCents,
      })
      const redirectUrl = await callPayGiftCard({
        id: giftCardId,
        cardRegistrationUuid: cardRegistration.uuid,
        registrationData: paylineTokenization,
      })
      return redirectUrl
    },
  }
}

export default useGiftCardPayment
