import React, { useRef, useState } from 'react'
import {
  Text,
  Title,
  LongArrowIcon,
  CarouselNext,
  ScreenConfig,
  Container,
  useLazyObserver,
} from '@kisskissbankbank/kitten'
import {
  FOUR_GUTTER_LARGE_IN_CONTAINER,
  FOUR_GUTTER_LARGE_IN_XL_CONTAINER,
  TWO_GUTTER_THIN_IN_XXS_CONTAINER,
  TWO_IN_CONTAINER,
} from 'kiss/utils/responsive-image/image-width-definitions'
import { useMediaQuery } from 'kiss/media-queries/redux'
import ProjectCard from 'kiss/components/cards/project-card'
import { useTranslation } from 'kiss/hooks/use-translation'

const responsiveImageOptions = {
  imageSizes: [165, 182, 270, 310, 490],
  breakpoints: [0, ScreenConfig.S.min, ScreenConfig.L.min, ScreenConfig.XL.min],
  imageSizesInViewport: [
    TWO_GUTTER_THIN_IN_XXS_CONTAINER,
    TWO_IN_CONTAINER,
    FOUR_GUTTER_LARGE_IN_CONTAINER,
    FOUR_GUTTER_LARGE_IN_XL_CONTAINER,
  ],
}

const Engagement = ({ title, description, projects, link }) => {
  const [navProps, setNavProps] = useState({})
  const t = useTranslation()
  const { viewportIsSOrLess, viewportIsMOrLess } = useMediaQuery()

  const lazyElement = useRef(null)
  const shouldLoadImages = useLazyObserver(lazyElement)
  return (
    <>
      <div ref={lazyElement} className="kiss-Homepage__engagements__block">
        <Title
          tag="h2"
          modifier="tertiary"
          family="antiqueolive"
          noMargin
          cssColor="inherit"
          className="k-u-margin-bottom-single k-u-padding-horizontal-double@m-down"
        >
          {title}
        </Title>
        <div
          className="k-u-flex
          k-u-flex-justifyContent-sb@l-up
          k-u-padding-horizontal-double@m-down
          k-u-flex-direction-column@m-down
          k-u-margin-bottom-triple@l-up"
        >
          <Text cssColor="inherit" size="small">
            {description}
          </Text>
          <Text
            cssColor="inherit"
            size="small"
            weight="500"
            className="kiss-Homepage__engagements__block__link k-u-link k-u-link-background1"
            tag="a"
            href={link}
          >
            <LongArrowIcon
              color="currentColor"
              height="16"
              className="k-u-margin-right-noneHalf"
            />
            {t('new_home.manifesto.see_more_engaged_projects')}
          </Text>
        </div>

        <CarouselNext.Navigation
          {...navProps}
          buttonProps={{ size: 'small' }}
          className="k-u-margin-bottom-double k-u-flex k-u-flex-justifyContent-end k-u-margin-horizontal-double k-u-hidden@l-up"
        />

        <CarouselNext
          baseGap={30}
          itemsPerPage={viewportIsSOrLess ? 1 : 3}
          itemMinWidth={viewportIsSOrLess ? 200 : 300}
          viewportIsXSOrLess={viewportIsSOrLess}
          viewportIsMOrLess={viewportIsMOrLess}
          navigationPropsGetter={setNavProps}
          showOtherPages={viewportIsMOrLess}
          shadowSize={viewportIsSOrLess ? 15 : 30}
          className="k-u-hidden@l-up"
        >
          {projects.map((project, index) => (
            <ProjectCard
              project={project}
              key={index}
              colorMode="dark"
              hoverableTitle
              shouldLoadImages={shouldLoadImages}
              responsiveImageOptions={responsiveImageOptions}
            />
          ))}
        </CarouselNext>

        <Container className="kiss-Homepage__engagements__carousel k-u-hidden@m-down">
          {projects.map((project, index) => (
            <ProjectCard
              project={project}
              key={index}
              colorMode="dark"
              hoverableTitle
              shouldLoadImages={shouldLoadImages}
              responsiveImageOptions={responsiveImageOptions}
            />
          ))}
        </Container>
      </div>
    </>
  )
}

export default Engagement
