import React from 'react'

export const PaymentIllustration = () => (
  <svg
    width={200}
    height={200}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M52 0H0l9.27 5.07L5 8.173 24.736 22.74l-11.537 5.6 10.605 17.967L14 56h38a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z"
        id="a"
      />
      <path
        d="M4 0h23.895l9.236 5.07-4.254 3.102 19.66 14.568-11.494 5.6 10.565 17.967L41.842 56H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
        id="c"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#FFEBE0" cx={100} cy={100} r={100} />
      <g transform="rotate(17 -191.146 392.521)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fill="#FFF" xlinkHref="#a" />
        <path
          stroke="red"
          d="M1.956.5l8.248 4.511-4.357 3.164 19.85 14.654-11.793 5.723 10.527 17.836-9.214 9.112H52a3.5 3.5 0 0 0 3.5-3.5V4A3.5 3.5 0 0 0 52 .5H1.956z"
        />
        <g mask="url(#b)">
          <g transform="translate(2.8 11.2)">
            <path
              d="M41.3 36a5.9 5.9 0 1 1 0-11.8 5.9 5.9 0 0 1 0 11.8zm0-2a3.9 3.9 0 1 0 0-7.8 3.9 3.9 0 0 0 0 7.8z"
              fill="#222"
              fillRule="nonzero"
            />
            <circle fill="red" cx="35.7" cy="30.1" r="4.9" />
            <path
              d="M35.7 36a5.9 5.9 0 1 1 0-11.8 5.9 5.9 0 0 1 0 11.8zm0-2a3.9 3.9 0 1 0 0-7.8 3.9 3.9 0 0 0 0 7.8z"
              fill="#222"
              fillRule="nonzero"
            />
            <path fill="#0D9DDB" d="M0 0h53.2v9.8H0z" />
            <path
              d="M2 2v5.8h49.2V2H2zM0 0h53.2v9.8H0V0z"
              fill="#222"
              fillRule="nonzero"
            />
          </g>
        </g>
        <path
          d="M3.912 1l7.225 3.952-4.443 3.227 19.966 14.74-12.05 5.846L25.06 46.47 16.433 55H52a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H3.912z"
          stroke="#222"
          strokeWidth={2}
          mask="url(#b)"
        />
      </g>
      <g transform="rotate(-15 353.83 -110.5)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <use fill="#FFF" xlinkHref="#c" />
        <path fill="#0D9DDB" mask="url(#d)" d="M0 11.2h53V21H0z" />
        <path
          d="M2 19h49v-5.8H2V19zm-2-7.8h53V21H0v-9.8z"
          fill="#222"
          fillRule="nonzero"
          mask="url(#d)"
        />
        <path
          fill="#222"
          mask="url(#d)"
          d="M9.09 33.695h13.947v2.8H9.09zM8.729 39.248h18.132v2.8H8.729zM8.368 44.8h22.316v2.8H8.368z"
        />
        <path
          d="M33.409 5.31L27.382 2H4a2 2 0 0 0-2 2v48a2 2 0 0 0 2 2h37.018l8.078-8.019-10.873-18.493 10.476-5.102-19.2-14.227 3.91-2.85zm9.438 22.15l-1.804.88.938 1.593-.062.204 9.689 16.17L41.842 56H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h23.895l9.236 5.07-2.56 1.868-.503-.373-.003.741-1.188.866 1.18.875-.002.741.505-.368 17.977 13.32-9.69 4.72z"
          fill="#222"
          fillRule="nonzero"
          mask="url(#d)"
        />
      </g>
      <path
        fill="#222"
        fillRule="nonzero"
        d="M88.37 57.835l-3.642 2.521 4.371 9.078 1.802-.868-3.63-7.538 4.359-3.017-8.976-7.767-1.308 1.512zM100.49 77.464l1.688 1.072 3.085-4.86-3.969-4.167 3.52-4.928-1.628-1.162-4.48 6.272 4.03 4.233zM78.35 72.76l7 6 1.3-1.52-7-6z"
      />
    </g>
  </svg>
)
