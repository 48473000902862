import React from 'react'
import { useSelector } from 'react-redux'

import { useTranslation } from 'kiss/hooks/use-translation'
import { isoDateToLocaleFormat } from 'kiss/utils/iso-date-to-locale-format'

import {
  LockOutlineIcon,
  RefundIcon,
  ShieldCheckIcon,
  GiftIcon,
} from '@kisskissbankbank/kitten'

import ExplainLine from 'kiss/modules/contribute/components/summary/components/explain-line'
import { getLocale } from 'kiss/app/redux'
import { getCampaignEndAt } from 'kiss/modules/contribute/cart/redux'
import { isPermanentProject } from 'kiss/modules/contribute/redux'

export const ExplainGiftCard = (props) => {
  const t = useTranslation()
  return (
    <ExplainLine icon={GiftIcon} {...props}>
      <ExplainLine.Text>
        {t('cart.contribute.summary.gift_card')}
      </ExplainLine.Text>
    </ExplainLine>
  )
}

const ExplainBlock = () => {
  const t = useTranslation()

  const locale = useSelector(getLocale)
  const endDate = useSelector(getCampaignEndAt)
  const projectIsPermanent = useSelector(isPermanentProject)

  const endDateFormatted = isoDateToLocaleFormat(endDate, {
    locale,
    format: 'dd MMMM yyyy',
  })

  return (
    <>
      {' '}
      <ExplainLine icon={LockOutlineIcon}>
        <ExplainLine.Text>
          {t('cart.contribute.summary.reassure.lock')}
        </ExplainLine.Text>
      </ExplainLine>
      {!projectIsPermanent && (
        <ExplainLine icon={RefundIcon}>
          <ExplainLine.Text>
            {t('cart.contribute.summary.reassure.money', {
              date: endDateFormatted,
            })}
          </ExplainLine.Text>
        </ExplainLine>
      )}
      <ExplainLine icon={ShieldCheckIcon}>
        <ExplainLine.Text>
          {t('cart.contribute.summary.reassure.shield')}
        </ExplainLine.Text>
      </ExplainLine>
    </>
  )
}

export default ExplainBlock
