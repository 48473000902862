import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, useField } from 'formik'
import {
  Field,
  pxToRem,
  RadioButtonSet as KittenRadioButtonSet,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import styled from 'styled-components'

const StyledRadioButtonSet = styled(KittenRadioButtonSet)`
  &.kiss-Form-RadioButtonSet__orionGrid .k-Form-RadioButtonSet__radioContainer {
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: ${ScreenConfig.S.min}px) {
      gap: ${pxToRem(20)};
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

const RadioButtonSet = ({
  name,
  disabled,
  items,
  error,
  validate,
  ...others
}) => {
  const [field, meta] = useField({ name, validate })

  return (
    <>
      <StyledRadioButtonSet
        id={name}
        items={items.map((itemProps) => {
          return {
            ...field,
            ...itemProps,
            defaultChecked: itemProps.value === field.value,
          }
        })}
        error={(meta.error && meta.touched) || error}
        disabled={disabled}
        {...others}
      />

      <ErrorMessage name={name} component={Field.ErrorMessage} />
    </>
  )
}

RadioButtonSet.propTypes = {
  name: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
}

RadioButtonSet.defaultProps = {
  disabled: false,
  error: false,
}

export default RadioButtonSet
