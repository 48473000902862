export const isBare = (projectState) => projectState === 'bare'
export const isDraft = (projectState) => projectState === 'draft'
export const isStarted = (projectState) => projectState === 'started'
export const isSuccessful = (projectState) => projectState === 'successful'
export const isFailed = (projectState) => projectState === 'failed'

export const isFinished = (projectState) =>
  ['failed', 'successful'].includes(projectState)

export const isVisible = (projectState) =>
  ['started', 'failed', 'successful'].includes(projectState)

export const isNotPublished = (projectState) =>
  ['bare', 'draft', 'submitted', 'improve', 'accepted', 'rejected'].includes(
    projectState,
  )
export const isEditProjectActivated = (projectState) =>
  [
    'failed',
    'successful',
    'started',
    'accepted',
    'submitted',
    'improve',
    'draft',
  ].includes(projectState)
