import { useTranslation } from 'kiss/hooks/use-translation'
import Login from 'kiss/modules/authenticate/components/login'
import React from 'react'
import { Helmet } from 'react-helmet'
import AuthenticateLayout from '../components/layout'
import ConditionalTitle from './components/conditional-title'

const FacebookOrEmail = () => {
  const t = useTranslation()

  return (
    <AuthenticateLayout>
      <Helmet title={t('authenticate.login.seo.title')} />
      <ConditionalTitle />
      <div className="k-u-margin-top-quadruple">
        <Login />
      </div>
    </AuthenticateLayout>
  )
}

export default FacebookOrEmail
