import { HorizontalStroke, Title } from '@kisskissbankbank/kitten'
import { LoaderWithParagraph } from 'kiss/components/loading/loader-with-paragraph'
import { useTranslation } from 'kiss/hooks/use-translation'
import SignUpForm from 'kiss/modules/authenticate/components/sign-up'
import { getRouteFor as getRouteForSelector, HOMEPAGE } from 'kiss/routes/redux'
import {
  isLogged as isLoggedSelector,
  sessionFetched as sessionFetchedSelector,
} from 'kiss/session/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import AuthenticateLayout from '../components/layout'

const SignUp = () => {
  const t = useTranslation()
  const sessionFetched = useSelector(sessionFetchedSelector)
  const isLogged = useSelector(isLoggedSelector)
  const getRouteFor = useSelector(getRouteForSelector)
  const [isRedirecting, setRedirecting] = useState(false)

  useEffect(() => {
    if (!sessionFetched) return

    if (isLogged) {
      setRedirecting(true)
      RoutingHelper.redirect(getRouteFor(HOMEPAGE))
    }
  }, [sessionFetched])

  return (
    <AuthenticateLayout>
      <Helmet title={t('authenticate.registration.seo.title')} />

      {sessionFetched && isRedirecting && (
        <div
          className="k-u-margin-vertical-decuple k-u-align-center"
          aria-hidden="true"
        >
          <LoaderWithParagraph
            loaderPosition="bottom"
            paragraphProps={{ modifier: 'primary' }}
          >
            {t('authenticate.registration.already_authenticated')}
          </LoaderWithParagraph>
        </div>
      )}

      {sessionFetched && !isRedirecting && (
        <>
          <div className="k-u-margin-vertical-double">
            <Title modifier="tertiary" tag="h3">
              {t('authenticate.registration.title.body_standard', {
                parseHtml: true,
              })}
            </Title>
          </div>

          <div className="k-u-margin-bottom-quadruple">
            <HorizontalStroke size="large" />
          </div>

          <SignUpForm />
        </>
      )}
    </AuthenticateLayout>
  )
}

export default SignUp
