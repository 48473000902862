import { getLocale } from 'kiss/app/redux'
import { useSelector } from 'react-redux'

const useNumbers = () => {
  const locale = useSelector(getLocale)
  return {
    formatDecimal: (number, minimumFractionDigits = 0) => {
      return new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits,
        maximumFractionDigits: minimumFractionDigits,
      }).format(number)
    },
    formatPercent: (
      number,
      minimumFractionDigits = 0,
      maximumFractionDigits = 0,
    ) =>
      new Intl.NumberFormat(locale, {
        style: 'percent',
        minimumFractionDigits,
        maximumFractionDigits,
      }).format(number / 100),
    formatMoney: (number, currency = 'eur') => {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        maximumFractionDigits: 0,
      }).format(number)
    },
  }
}

export default useNumbers
