import {
  ButtonWithTooltip,
  domElementHelper,
  EnvelopeIcon,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, MESSAGING, UNREAD_CONVERSATIONS } from 'kiss/routes/redux'
import { getChatUsername, isCurrentUserAdmin } from 'kiss/session/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import RequestUnreadMessages from './request-unread-messages'

const MessagesButton = ({ hasUnreadMessages = false }) => {
  const isSynapseEnabled = SYNAPSE_ENABLED
  const t = useTranslation()
  const { pathname } = useLocation()
  const routeFor = useSelector(getRouteFor)
  const isAdmin = useSelector(isCurrentUserAdmin)
  const isPathMatching = (path) =>
    matchPath(pathname, {
      path: path,
      exact: false,
    })

  const unreadConversationsRoute = routeFor(
    isAdmin && isSynapseEnabled ? MESSAGING : UNREAD_CONVERSATIONS,
  )

  return (
    <ButtonWithTooltip
      tooltipText={t('layouts.header.inbox')}
      buttonProps={{
        as: 'a',
        href: unreadConversationsRoute,
        fit: 'icon',
        size: 'small',
        rounded: true,
        hasBullet:
          hasUnreadMessages && !isPathMatching(unreadConversationsRoute),
        modifier: isPathMatching(unreadConversationsRoute)
          ? 'helium'
          : 'hydrogen',
      }}
      className="k-u-hidden@m-down"
      data-test-id="header-nav-messages-item"
    >
      <EnvelopeIcon />
    </ButtonWithTooltip>
  )
}

const MessagingActions = () => {
  const isSynapseEnabled = SYNAPSE_ENABLED
  const { pathname } = useLocation()
  const isPathMatching = (path) =>
    matchPath(pathname, {
      path: path,
      exact: false,
    })
  const chatUserName = useSelector(getChatUsername)
  const isAdmin = useSelector(isCurrentUserAdmin)
  return !chatUserName ||
    isPathMatching(MESSAGING) ||
    !isAdmin ||
    !isSynapseEnabled ||
    !domElementHelper.canUseDom() ? (
    <MessagesButton />
  ) : (
    <RequestUnreadMessages>
      {({ hasUnreadMessages }) => (
        <MessagesButton hasUnreadMessages={hasUnreadMessages} />
      )}
    </RequestUnreadMessages>
  )
}

export default MessagingActions
