import { ArrowIcon, Checkbox, TextButton } from '@kisskissbankbank/kitten'
import FocusTrap from 'focus-trap-react'
import { useTranslation } from 'kiss/hooks/use-translation'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import keys from 'lodash/fp/keys'
import map from 'lodash/fp/map'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getProjectTags } from '../../../../../page-state/selectors'
import Filter from '../../components/filter'

const TagsFilter = ({ onSelectTags, defaultValues, searchParams }) => {
  const t = useTranslation()

  const tagsList = useSelector(getProjectTags)

  const [showFilter, setShowFilter] = useState(false)
  const handleButtonClick = () => setShowFilter((current) => !current)
  const handleFilterClose = () => setShowFilter(false)

  if (isEmpty(tagsList)) return null

  return (
    <FocusTrap
      active={showFilter}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <div className="menu">
        <TextButton
          className="k-u-link k-u-link-primary1 k-u-cursor-pointer"
          aria-label={showFilter ? 'Fermer le menu' : 'Ouvrir le menu'}
          onClick={handleButtonClick}
        >
          {t('project.news.filters.tags.more')}
          <ArrowIcon direction="bottom" />
        </TextButton>

        {showFilter && (
          <Filter
            values={searchParams}
            defaultValues={defaultValues}
            onFilterClose={handleFilterClose}
            mobileFooterActionLabel={t(
              'project.news.filters.date.standalone.mobile_footer',
            )}
          >
            <>
              {flow(
                keys,
                map((key) => {
                  return (
                    <Checkbox
                      id={key}
                      key={key}
                      label={`${key} (${tagsList[key]})`}
                      value={key}
                      name="tags"
                      checked={searchParams.tags?.includes(key)}
                      onChange={onSelectTags}
                    />
                  )
                }),
              )(tagsList)}
            </>
          </Filter>
        )}
      </div>
    </FocusTrap>
  )
}

export default TagsFilter
