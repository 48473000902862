import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import DefinitionList from './definition-list'
import {
  getProjectState,
  getLastStateAt,
  getDeletedAt,
  getSuspendedAt,
  getProjectExtratimeState,
} from 'kiss/modules/project-page/page-state/selectors'
import { getLocale } from 'kiss/app/redux'
import { isoDateToLocaleFormat } from 'kiss/utils/iso-date-to-locale-format'
import { Text } from '@kisskissbankbank/kitten'

const State = () => {
  const t = useTranslation()
  const state = useSelector(getProjectState)
  const lastStateAt = useSelector(getLastStateAt)
  const deletedAt = useSelector(getDeletedAt)
  const suspendedAt = useSelector(getSuspendedAt)
  const locale = useSelector(getLocale)
  const isExtratime = useSelector(getProjectExtratimeState)

  const stateText = `${t(`project.states.${state}`)} (${isoDateToLocaleFormat(
    lastStateAt,
    {
      locale,
    },
  )})`
  const deletedAtText = (
    <Text weight="600" size="medium" color="font1">
      {` - `}
      {t('projects.admin_panel.deleted_project', {
        date: isoDateToLocaleFormat(deletedAt, { locale }),
      })}
    </Text>
  )

  const getText = () => {
    if (suspendedAt) return t('projects.admin_panel.suspended_project')
    else if (isExtratime) return t('projects.admin_panel.extra_time')
    else return stateText
  }

  return (
    <>
      <DefinitionList.Title>
        <Text weight="600" size="medium" color="font1">
          {t('projects.admin_panel.state')}
        </Text>
      </DefinitionList.Title>
      <DefinitionList.Description>
        <Text weight="400" size="medium" color="font1">
          {getText()}
          {deletedAt && deletedAtText}
        </Text>
      </DefinitionList.Description>
    </>
  )
}

export default State
