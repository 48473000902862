import React, { memo } from 'react'
import styled from 'styled-components'
import {
  Container,
  GridCol,
  Grid,
  Title,
  Text,
  pxToRem,
  parseHtml,
  TYPOGRAPHY,
  RichText,
  mq,
  FlexWrapper,
} from '@kisskissbankbank/kitten'
import RichContentText from 'kiss/modules/components/rich-content/text'
import RichContentButton from 'kiss/modules/components/rich-content/button'
import getTitleId from 'kiss/utils/get-title-id'

const StyledContainer = styled(Container)`
  img {
    display: block;
    width: 100%;
  }

  .k-TextWithImage__content {
    order: 1;

    @media ${mq.desktop} {
      order: var(--order);
    }
  }
`

const TextWithIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }
`

const Figure = styled.figure`
  margin: 0;
  position: relative;
`

const FigureCaption = styled.figcaption`
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: calc(100% - ${pxToRem(30 * 2)});
  padding: ${pxToRem(20)} ${pxToRem(30)} 0 ${pxToRem(10)};
  background: white;
`

const StyledText = styled(Text)`
  margin: 0;
  font-size: ${pxToRem(12)};

  @media ${mq.tabletAndDesktop} {
    font-size: ${pxToRem(14)};
  }
`

const Icon = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(50)};
  height: ${pxToRem(50)};
  border-radius: var(--border-radius-rounded);
  box-sizing: border-box;
  background-color: var(--color-grey-000);
  border: var(--border);
  ${TYPOGRAPHY.fontStyles['500']};
  font-size: ${pxToRem(14)};
`

const TextWithImage = ({
  imagePosition,
  title,
  description,
  callToAction,
  blockquoteBackgroundColor,
  blockquoteIconUrl,
  blockquoteContent,
  image,
}) => {
  return (
    <StyledContainer>
      <Grid
        className="k-u-flex-alignItems-center"
        style={{ '--order': imagePosition === 'right' ? 0 : 1 }}
      >
        <GridCol
          className="k-TextWithImage__content"
          col-xs="10"
          offset-xs="1"
          col-l="4"
        >
          <FlexWrapper gap={20}>
            {title.text && (
              <Title
                id={getTitleId(title.text)}
                tag={`h${title.level}`}
                modifier="quaternary"
                noMargin
              >
                {title.text}
              </Title>
            )}

            {description && (
              <RichContentText
                content={description}
                paragraphProps={{
                  fontSizeDefault: 'tertiary',
                  fontSizeFromS: null,
                }}
              />
            )}

            {blockquoteContent[0] && blockquoteContent[0].html && (
              <TextWithIcon>
                <Icon
                  style={{
                    backgroundColor: `#${blockquoteBackgroundColor}`,
                    borderColor: `#${blockquoteBackgroundColor}`,
                  }}
                >
                  <img src={blockquoteIconUrl} alt="" />
                </Icon>

                <RichText as="div">
                  <Text
                    tag="p"
                    weight="500"
                    fontStyle="italic"
                    size="small"
                    lineHeight="normal"
                    className="k-u-margin-bottom-singleHalf"
                  >
                    {parseHtml(blockquoteContent[0].html, {
                      sanitize: false,
                    })}
                  </Text>
                </RichText>
              </TextWithIcon>
            )}

            {callToAction.text && (
              <RichContentButton
                mobileFit="fluid"
                modifier="hydrogen"
                className="k-u-margin-top-double k-u-alignSelf-start"
                {...callToAction}
              />
            )}
          </FlexWrapper>
        </GridCol>

        <GridCol col-xs="10" offset-xs="1" col-l="5">
          {image.url && (
            <Figure>
              <img loading="lazy" src={image.url} alt={image.alt} />

              {image.copyright && (
                <FigureCaption>
                  <StyledText
                    tag="p"
                    lineHeight="normal"
                    color="font1"
                    weight="400"
                  >
                    {parseHtml(image.copyright, { sanitize: false })}
                  </StyledText>
                </FigureCaption>
              )}
            </Figure>
          )}
        </GridCol>
      </Grid>
    </StyledContainer>
  )
}

export default memo(TextWithImage)
