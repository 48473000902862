import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Comment from 'kiss/modules/project-page/common/news/Comment'
import { pxToRem, mq } from '@kisskissbankbank/kitten'
import styled from 'styled-components'

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(5)};

  @media ${mq.tabletAndDesktop} {
    gap: ${pxToRem(20)};
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(-${pxToRem(30)});
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &.first-render > * {
    animation: appear 0.5s forwards;
    opacity: 0;
  }

  // Start animation for first visible elements with
  // an increasing delay
  & > *:nth-child(1) {
    animation-delay: 0s;
  }
  & > *:nth-child(2) {
    animation-delay: 0.1s;
  }
  & > *:nth-child(3) {
    animation-delay: 0.2s;
  }
  & > *:nth-child(4) {
    animation-delay: 0.3s;
  }
  & > *:nth-child(5) {
    animation-delay: 0.4s;
  }
`

const CommentsList = ({ comments, newsId }) => {
  const isFirstRenderRef = useRef(true)
  useEffect(() => {
    isFirstRenderRef.current = false
  }, [])
  return (
    <StyledList className={isFirstRenderRef.current ? 'first-render' : ''}>
      {comments.map((props) => (
        <Comment
          key={`${props.uuid}-${props.text}`}
          newsId={newsId}
          {...props}
        />
      ))}
    </StyledList>
  )
}

export default CommentsList

CommentsList.propTypes = {
  newsId: PropTypes.string,
}

CommentsList.defaultProps = {
  newsId: undefined,
}
