import React, { useState } from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getDeliveryAmountCents,
  getShippingCountryCode,
  getDonationAmount,
  getOrderTotal,
  getTotalAmount,
  getTotalCurrency,
  getOrderLines,
  isStartOnNextPeriod,
  getServiceFeeAmount,
} from 'kiss/modules/contribute/cart/redux'
import CartSummary from '../../../../components/summary/index'
import Reassure from '../../../../components/summary/components/reassure'
import Separator from '../../../../components/summary/components/separator'
import AmountLine from '../../../../components/summary/components/amount-line'
import {
  IconBadge,
  QuestionMarkIcon,
  Text,
  Toggletip,
  Button,
} from '@kisskissbankbank/kitten'
import ExplainBlock from '../components/explain-block'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { isoDateToLocaleFormat } from 'kiss/utils/iso-date-to-locale-format'
import Z_INDEX from 'kiss/utils/dom/z-index-config'

const PermanentCartSummary = () => {
  const t = useTranslation()
  const params = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const totalAmount = useSelector(getTotalAmount)
  const orderTotal = useSelector(getOrderTotal)
  const totalCurrency = useSelector(getTotalCurrency)
  const donationAmount = useSelector(getDonationAmount)
  const shippingCountryCode = useSelector(getShippingCountryCode)
  const deliveryAmountCents = useSelector(getDeliveryAmountCents)
  const orderLines = useSelector(getOrderLines)
  const startOnNextPeriod = useSelector(isStartOnNextPeriod)
  const reward = orderLines[0]?.variation?.reward
  const serviceFeeAmount = useSelector(getServiceFeeAmount)

  const getFormattedAmount = (amount) =>
    t('project_info.amount_with_currency', {
      amount: t(amount, { formatNumber: { decimal: 2 } }),
      currency: t(`currency_symbol.${totalCurrency}`),
      parseHtml: true,
    })

  return (
    <CartSummary
      onSubmit={() => setIsSubmitting(true)}
      setIsSubmitting={setIsSubmitting}
    >
      {donationAmount !== 0 ? (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.summary.recurring_donation', {
              date: isoDateToLocaleFormat(Date.now(), {
                format: 'MMMM yyyy',
                locale: params.locale,
              }),
            })}
          </Text>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(donationAmount)}
          </Text>
        </AmountLine>
      ) : (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.summary.subscription', {
              date:
                (startOnNextPeriod
                  ? reward?.nextPeriodLabel
                  : reward?.subscribablePeriodLabel) || '',
            })}
          </Text>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(orderTotal)}
          </Text>
        </AmountLine>
      )}

      {shippingCountryCode && (
        <AmountLine>
          <Text size="small" weight="400" color="font1">
            {t('cart.contribute.summary.shipping', {
              country: t(`countries.${shippingCountryCode}`),
              parseHtml: true,
            })}
          </Text>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(deliveryAmountCents / 100)}
          </Text>
        </AmountLine>
      )}
      {serviceFeeAmount !== 0 && (
        <AmountLine>
          <div>
            <Text size="small" weight="400" color="font1">
              {t('cart.contribute.summary.service_fees.label')}
            </Text>
            <Toggletip
              bubbleProps={{ zIndex: Z_INDEX.global.aboveAll }}
              targetElement={
                <IconBadge size="micro">
                  <QuestionMarkIcon width="8" height="8" />
                </IconBadge>
              }
              className="k-u-margin-left-single"
            >
              <Text>{t('cart.contribute.summary.service_fees.tooltip')}</Text>
            </Toggletip>
          </div>
          <Text size="small" weight="400" color="font1">
            {getFormattedAmount(serviceFeeAmount / 100)}
          </Text>
        </AmountLine>
      )}
      <Separator />
      <AmountLine>
        <Text size="huge" weight="500" color="font1">
          {t('cart.contribute.summary.total')}
        </Text>
        <Text data-test-id="cart-total" size="huge" weight="600" color="font1">
          {getFormattedAmount(totalAmount)}
        </Text>
      </AmountLine>

      <Separator className="k-u-hidden@xs-down" />
      <Button
        modifier="helium"
        fit="fluid"
        disabled={totalAmount === 0}
        data-test-id="cart-submit-button"
        type={!isSubmitting ? 'submit' : 'button'}
        className="k-u-hidden@xs-down"
      >
        {isSubmitting ? (
          <LoadingAnimation color="var(--color-primary-500)" />
        ) : (
          t('cart.contribute.summary.button.total')
        )}
      </Button>
      <Separator />
      <ExplainBlock />
      <Separator />
      <Reassure />
    </CartSummary>
  )
}
export default PermanentCartSummary
