import mutation from 'kiss/api/graphql-query'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import includes from 'lodash/fp/includes'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import { getProjectId } from 'kiss/modules/project-page/page-state/selectors'
import { MARK_OWNER_PANEL_INITIAL_DATA_AS_FETCHED } from 'kiss/modules/project-page/page-state/redux'
import cancelProjetQuery from 'kiss/graphql/mutations/project/cancel_project.graphql'
import submitProjectQuery from 'kiss/graphql/mutations/project/submit_project.graphql'
import publishProjectQuery from 'kiss/graphql/mutations/project/publish_project.graphql'
import { UPDATE_PROJECT } from '../../../../page-state/redux'

export const markOwnerPanelInitialDataAsFetched = () => ({
  type: MARK_OWNER_PANEL_INITIAL_DATA_AS_FETCHED,
  payload: {
    ownerPanelInitialDataFetched: true,
  },
})

export const cancelProject = () => async (dispatch, getState) => {
  const state = getState()
  const projectId = getProjectId(state)

  try {
    const project = await mutation(
      cancelProjetQuery,
      {
        id: projectId,
      },
      state,
    )
    dispatch({
      type: UPDATE_PROJECT,
      payload: project.cancelProject,
    })
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const submitProject = () => async (dispatch, getState) => {
  const state = getState()
  const projectId = getProjectId(state)

  try {
    const project = await mutation(
      submitProjectQuery,
      {
        id: projectId,
      },
      state,
    )
    dispatch({
      type: UPDATE_PROJECT,
      payload: project.submitProject,
    })
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const publishProject = () => async (dispatch, getState) => {
  const state = getState()
  const projectId = getProjectId(state)

  try {
    const project = await mutation(
      publishProjectQuery,
      {
        id: projectId,
      },
      state,
    )
    dispatch({
      type: UPDATE_PROJECT,
      payload: project.publishProject,
    })
  } catch (e) {
    if (flow(first, getOr('')('code'), includes('be_mangopay_compliant'))(e)) {
      throw 'BE_MANGOPAY_COMPLIANT'
    }
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}
