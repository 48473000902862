import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Grid } from '@kisskissbankbank/kitten'
import Header from '../../thankyou/components/header'
import Social from '../../thankyou/components/social'
import { useTranslation } from 'kiss/hooks/use-translation'
import SimilarProjects from '../../thankyou/components/similar-projects'

const ThankYou = () => {
  const t = useTranslation()
  return (
    <>
      <Helmet title={t('contribute.thank_you.head.title')} />
      <Container>
        <Grid>
          <Header title={t('contribute.thank_you.header.title_permanent')} />
          <Social withPadding />
        </Grid>
      </Container>
      <SimilarProjects />
    </>
  )
}

export default ThankYou
