import React from 'react'
import Rewards from './components/rewards'
import SimpleDescription from './components/simple-description'
import styled from 'styled-components'
import RewardsHighlightHalo from 'kiss/modules/project-page/campaign/components/actions/rewards-highlight-halo'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { Container, Grid, GridCol, pxToRem } from '@kisskissbankbank/kitten'
import NewsPreview from '../news-preview'

const StyledGridCol = styled(GridCol)`
  position: relative;

  .kiss-Halo.kiss-Halo {
    position: absolute;
    top: ${pxToRem(10)};
    left: ${pxToRem(10)};
  }

  .k-RewardCard {
    width: 100%;

    .DraftEditor-editorContainer {
      cursor: inherit !important;
    }
  }
`

const DescriptionRewards = () => {
  const { viewportIsMOrLess } = useMediaQuery()

  return (
    <Container>
      <Grid>
        <GridCol col-xs="12" col-l="7" offset-xl="1" id="news-preview">
          <NewsPreview />
        </GridCol>

        <GridCol col-xs="12" col-l="7" offset-xl="1" id="description">
          <SimpleDescription />
        </GridCol>

        {!viewportIsMOrLess && (
          <StyledGridCol
            col-xs="10"
            col-l="4"
            col-xl="3"
            offset-xs="1"
            id="rewards"
          >
            <RewardsHighlightHalo className="kiss-Halo" />
            <Rewards />
          </StyledGridCol>
        )}
      </Grid>
    </Container>
  )
}

export default DescriptionRewards
