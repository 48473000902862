import React from 'react'
import { Helmet } from 'react-helmet'

const PWAMeta = () => {
  return (
    <Helmet>
      <link rel="manifest" href="/manifest.json" />
      <link rel="apple-touch-icon" href="/pwa/icon-ios.png" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="KissKissBankBank" />
      <meta name="theme-color" content="var(--color-primary-500)" />
      <meta
        name="msapplication-navbutton-color"
        content="var(--color-primary-500)"
      />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="var(--color-primary-500)"
      />
      <meta name="msapplication-starturl" content="/" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
    </Helmet>
  )
}

export default PWAMeta
