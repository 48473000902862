import { Grid, GridCol } from '@kisskissbankbank/kitten'
import Label from 'kiss/components/formik/label'
import Phone from 'kiss/components/formik/phone'
import SelectCountries from 'kiss/components/formik/select-countries'
import SimpleText from 'kiss/components/formik/simple-text'
import { useTranslation } from 'kiss/hooks/use-translation'
import isEmpty from 'lodash/fp/isEmpty'
import React from 'react'
import AddressBis from './address-bis'

const MAX_LENGTH_ADDRESS = 38

const Form = () => {
  const t = useTranslation()

  return (
    <>
      <Grid>
        <GridCol
          col-s="12"
          col-l="6"
          className="k-u-margin-bottom-triple@m-down"
        >
          <Label htmlFor="billingAddress[firstName]">
            {t('contribute.billing_address.form.firstname.label')}
          </Label>

          <SimpleText
            name="billingAddress[firstName]"
            autocomplete="given-name"
            placeholder={t(
              'contribute.billing_address.form.firstname.placeholder',
            )}
            validate={(value) => {
              if (isEmpty(value?.trim())) {
                return t('contribute.billing_address.required_field')
              }
            }}
          />
        </GridCol>
        <GridCol col-s="12" col-l="6">
          <Label htmlFor="billingAddress[lastName]">
            {t('contribute.billing_address.form.lastname.label')}
          </Label>

          <SimpleText
            name="billingAddress[lastName]"
            autocomplete="family-name"
            placeholder={t(
              'contribute.billing_address.form.lastname.placeholder',
            )}
            validate={(value) => {
              if (isEmpty(value?.trim())) {
                return t('contribute.billing_address.required_field')
              }
            }}
          />
        </GridCol>
      </Grid>

      <div className="k-u-margin-vertical-triple">
        <Grid>
          <GridCol col-s="12" col-xl="8">
            <Label htmlFor="billingAddress[companyName]">
              {t('contribute.billing_address.form.company.label')}
            </Label>

            <SimpleText
              name="billingAddress[companyName]"
              autocomplete="organization"
              placeholder={t(
                'contribute.billing_address.form.company.placeholder',
              )}
            />
          </GridCol>
        </Grid>
      </div>

      <div className="k-u-margin-vertical-triple">
        <Label htmlFor="billingAddress[phone]">
          {t('contribute.billing_address.form.phone.label')}
        </Label>

        <Grid>
          <GridCol col-s="12" col-xl="6">
            <Phone
              name="billingAddress[phone]"
              aria-describedby="phone-sublabel"
              validate={(value) => {
                if (!value) {
                  return t('contribute.billing_address.form.phone.error')
                }
              }}
            />
          </GridCol>
        </Grid>
      </div>

      <Label htmlFor="billingAddress[address1]">
        {t('contribute.billing_address.form.address.label')}
      </Label>

      <SimpleText
        name="billingAddress[address1]"
        placeholder={t('contribute.billing_address.form.address.placeholder', {
          parseHtml: true,
        })}
        maxLength={MAX_LENGTH_ADDRESS}
        validate={(value) => {
          if (isEmpty(value?.trim())) {
            return t('contribute.billing_address.required_field')
          }
          if (value?.length === MAX_LENGTH_ADDRESS) {
            return t('contribute.billing_address.error.max_length')
          }
        }}
      />
      <AddressBis />

      <Grid>
        <GridCol col="4" className="k-u-margin-bottom-triple">
          <Label htmlFor="billingAddress[postalCode]">
            {t('contribute.billing_address.form.postal_code.label')}
          </Label>

          <SimpleText
            name="billingAddress[postalCode]"
            autoComplete="kisskiss-prevent-autocomplete"
            inputMode="text"
            placeholder={t(
              'contribute.billing_address.form.postal_code.placeholder',
            )}
            validate={(value) => {
              if (isEmpty(value?.trim())) {
                return t('contribute.billing_address.required_field')
              }
            }}
          />
        </GridCol>
        <GridCol col="8" className="k-u-margin-bottom-triple">
          <Label htmlFor="billingAddress[city]">
            {t('contribute.billing_address.form.city.label')}
          </Label>

          <SimpleText
            name="billingAddress[city]"
            autoComplete="kisskiss-prevent-autocomplete"
            placeholder={t('contribute.billing_address.form.city.placeholder')}
            maxLength={MAX_LENGTH_ADDRESS}
            validate={(value) => {
              if (isEmpty(value?.trim())) {
                return t('contribute.billing_address.required_field')
              }
              if (value?.length === MAX_LENGTH_ADDRESS) {
                return t('contribute.billing_address.error.max_length')
              }
            }}
          />
        </GridCol>
      </Grid>
      <div className="k-u-margin-bottom-quadruple">
        <SelectCountries
          name="billingAddress[countryCode]"
          labelText={t('contribute.billing_address.form.country.label')}
          hideLabel={false}
          allCountries={false}
          placeholder={t('contribute.billing_address.form.country.placeholder')}
          searchable
          showFranceOnFirst
          inputProps={{
            'data-form-type': 'other',
          }}
        />
      </div>
    </>
  )
}

export default Form
