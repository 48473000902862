import React, { useEffect, useState } from 'react'

import { addErrorAlert } from 'kiss/app/alerts/redux'
import { getCurrentGuestEmail } from 'kiss/session/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from 'kiss/api/graphql-query'
import { useLocation } from 'react-router'
import { useTranslation } from 'kiss/hooks/use-translation'
import userQuery from 'kiss/graphql/queries/authenticate/user-query.graphql'

import {
  getRouteFor as getRouteForSelector,
  SIGN_IN,
  SIGN_UP,
} from 'kiss/routes/redux'

import { object, string } from 'yup'
import * as qs from 'neoqs'

import { Button } from '@kisskissbankbank/kitten'
import { Formik, useFormikContext } from 'formik'
import EnabledAfterMount from 'kiss/app/common/enabled-after-mount'
import Label from 'kiss/components/formik/label'
import PropTypes from 'prop-types'
import SimpleText from 'kiss/components/formik/simple-text'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import ExternalAuth from '../external'

const AutoSelectInput = ({ name, disabled, ...props }) => {
  const { submitCount, setFieldTouched } = useFormikContext()

  useEffect(() => {
    if (disabled) return
    !!document && document?.getElementById(name)?.focus()
  }, [disabled])

  return (
    <SimpleText
      name={name}
      disabled={disabled}
      {...props}
      onBlur={() => {
        if (submitCount === 0) {
          setFieldTouched('email', false)
        }
      }}
    />
  )
}

const Login = ({
  signInRoute,
  signUpRoute,
  externalReturnTo,
  routeParams,
  isSPA = false,
}) => {
  const t = useTranslation()
  const dispatch = useDispatch()

  const getRouteFor = useSelector(getRouteForSelector)

  const { search } = useLocation()
  const history = useHistory()
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true })

  const [isLoading, setIsLoading] = useState(false)

  const initialEmail = useSelector(getCurrentGuestEmail)

  const handleError = () => {
    dispatch(addErrorAlert('Error: something went wrong!'))
    setIsLoading(false)
  }

  const [checkUserByEmail, { variables }] = useLazyQuery(userQuery, {
    onCompleted: (values) => {
      if (!values) handleError()

      const params = qs.stringify({ ...searchParams, email: variables.email })
      const route = !values.user ? signUpRoute : signInRoute
      const nextUrl = `${getRouteFor(route, routeParams)}?${params}`

      if (isSPA) {
        history.push(nextUrl)
      } else {
        RoutingHelper.redirect(nextUrl)
      }
    },
    onError: handleError,
  })

  const validationSchema = object().shape({
    email: string()
      .email(
        t('authenticate.facebook_or_email.email.messages.errors.invalidEmail'),
      )
      .required(
        t('authenticate.facebook_or_email.email.messages.errors.required'),
      ),
  })

  const onSubmit = (values) => {
    setIsLoading(true)
    checkUserByEmail({ variables: { email: values.email.trim() } })
  }

  return (
    <>
      <Formik
        initialValues={{ email: initialEmail }}
        validationSchema={validationSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ handleSubmit }) => {
          return (
            <EnabledAfterMount>
              {(enabled) => (
                <form className="k-u-align-left" onSubmit={handleSubmit}>
                  <Label
                    htmlFor="email"
                    label={t('authenticate.login.form.email.label')}
                  />
                  <div className="k-u-margin-bottom-single">
                    <AutoSelectInput
                      name="email"
                      placeholder={t('login.form.email.placeholder')}
                      disabled={!enabled}
                      normalize={(value) => value.trim()}
                      type="email"
                    />
                  </div>

                  {isLoading && <SubmitLoader fit="fluid" size="medium" />}
                  {!isLoading && (
                    <Button
                      type="submit"
                      fit="fluid"
                      modifier="helium"
                      disabled={!enabled}
                      data-test-id="authenticate-submit"
                    >
                      {t('authenticate.facebook_or_email.submit_button.label')}
                    </Button>
                  )}
                </form>
              )}
            </EnabledAfterMount>
          )
        }}
      </Formik>

      <ExternalAuth returnTo={externalReturnTo} routeParams={routeParams} />
    </>
  )
}

Login.propTypes = {
  signInRoute: PropTypes.string,
  signUpRoute: PropTypes.string,
  facebookSignUpRoute: PropTypes.string,
  externalReturnTo: PropTypes.string,
  routeParams: PropTypes.object,
}

Login.defaultProps = {
  signInRoute: SIGN_IN,
  signUpRoute: SIGN_UP,
  externalReturnTo: null,
  routeParams: {},
}

export default Login
