import { v4 as randomUuid } from 'uuid'

const NAME = 'ALERTS'
export const ALERTS_ID = 'alerts'

const initialState = {
  items: [],
  scroll: false,
}

const ADD_ALERT = `${NAME}/ADD_ALERT`
const REMOVE_ALERT = `${NAME}/REMOVE_ALERT`
const RESET_SCROLL_VALUE = `${NAME}/RESET_SCROLL_VALUE`

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALERT:
    case REMOVE_ALERT:
    case RESET_SCROLL_VALUE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export const addAlert = (alertType, message, options = {}) => {
  return (dispatch, getState) => {
    const state = getState()
    const existingItems = getAlerts(state)
    const newItem = {
      uuid: randomUuid(),
      type: alertType,
      message,
    }
    const payload = {
      items: [...existingItems, newItem],
      scroll: options.scroll,
    }

    return dispatch({ type: ADD_ALERT, payload })
  }
}

export const addErrorAlert = (message, options) =>
  addAlert('danger', message, options)
export const addSuccessAlert = (message, options) =>
  addAlert('success', message, options)
export const addInfoAlert = (message, options) =>
  addAlert('info', message, options)

export const removeAlert = (alertUuid) => (dispatch, getState) => {
  const state = getState()
  const existingItems = getAlerts(state)
  const payload = {
    items: existingItems.filter((item) => item.uuid !== alertUuid),
  }

  return dispatch({ type: REMOVE_ALERT, payload })
}

export const removeAllAlerts = () => (dispatch) =>
  dispatch({ type: REMOVE_ALERT, payload: { items: [] } })

export const removeErrorAlerts = () => (dispatch, getState) => {
  const state = getState()
  const existingItems = getAlerts(state)
  const payload = {
    items: existingItems.filter((item) => item.type !== 'danger'),
  }

  return dispatch({ type: REMOVE_ALERT, payload })
}

export const resetScrollValue = () => (dispatch) =>
  dispatch({ type: RESET_SCROLL_VALUE, payload: { scroll: false } })

export const getAlerts = (state) => state?.[NAME]?.items

export const getScroll = (state) => state?.[NAME]?.scroll

export default { [NAME]: reducer }
