import React, { memo } from 'react'
import styled from 'styled-components'
import RichContentText from 'kiss/modules/components/rich-content/text'
import RichContentButton from 'kiss/modules/components/rich-content/button'
import {
  Container,
  Grid,
  GridCol,
  pxToRem,
  Text,
  Title,
  mq,
  FlexWrapper,
} from '@kisskissbankbank/kitten'
import getTitleId from 'kiss/utils/get-title-id'

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${pxToRem(40)};

  @media ${mq.tabletAndDesktop} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${pxToRem(50)};
  }

  @media ${mq.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }

  .kiss-TextWithImageGrids__imageGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${pxToRem(15)};

    @media ${mq.desktop} {
      grid-gap: ${pxToRem(20)};
    }

    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
      aspect-ratio: 1 / 1;
      display: block;
    }
  }
`

const TextWithImageGrids = ({ title, description, callToAction, grids }) => {
  return (
    <Container>
      <Grid>
        <GridCol col-xs="10" offset-xs="1">
          <StyledGrid>
            <FlexWrapper gap={20}>
              {title.text && (
                <Title
                  tag={`h${title.level}`}
                  modifier="tertiary"
                  noMargin
                  className="k-u-align-center@xs-down"
                  id={getTitleId(title.text)}
                >
                  {title.text}
                </Title>
              )}

              {description && (
                <RichContentText
                  content={description}
                  paragraphProps={{
                    fontSizeDefault: 'primary',
                    fontSizeFromS: null,
                  }}
                />
              )}

              {callToAction.text && (
                <RichContentButton
                  {...callToAction}
                  className="k-u-alignSelf-start k-u-margin-top-double k-u-hidden@xs-down"
                />
              )}
            </FlexWrapper>

            {grids &&
              grids.map((grid) => (
                <div key={grid.label}>
                  <div className="kiss-TextWithImageGrids__imageGrid">
                    {grid.images.map((image, index) => {
                      if (!image.url) return

                      return (
                        <a
                          href={image.redirectionUrl}
                          key={`image-${image.url}-${index}`}
                        >
                          <img loading="lazy" src={image.url} alt={image.alt} />
                        </a>
                      )
                    })}
                  </div>

                  <Text
                    tag="p"
                    size="small"
                    weight="500"
                    color="font1"
                    className="k-u-margin-top-singleHalf k-u-align-center"
                  >
                    {grid.label}
                  </Text>
                </div>
              ))}
          </StyledGrid>

          {callToAction.text && (
            <RichContentButton
              fit="fluid"
              {...callToAction}
              className="k-u-margin-top-triple k-u-hidden@s-up"
            />
          )}
        </GridCol>
      </Grid>
    </Container>
  )
}

export default memo(TextWithImageGrids)
