import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getAssetFor } from 'kiss/assets/redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import {
  Container,
  CONTAINER_PADDING,
  Grid,
  GridCol,
  parseHtml,
  pxToRem,
  ScreenConfig,
  SimpleCarousel,
  VisuallyHidden,
} from '@kisskissbankbank/kitten'

import TipSimpleCarousel from 'kiss/modules/communication/fund-my-project/components/tips/simple-carousel'

const IMG_HEIGHT = 416
const IMG_WIDTH = 555

const StyledImage = styled.div`
  background-color: var(--color-cyan-100);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding-bottom: ${(IMG_HEIGHT / IMG_WIDTH) * 100}%;
  background-image: url(${(props) => props.backgroundImage});

  @media (min-width: ${ScreenConfig.S.min}px) and (max-width: ${ScreenConfig.M
      .max}px) {
    margin-left: -${pxToRem(CONTAINER_PADDING)};
    width: calc(100% + ${pxToRem(CONTAINER_PADDING)});
  }
`

const TipsSection = () => {
  const assetFor = useSelector(getAssetFor)
  const t = useTranslation()

  return (
    <Container>
      <Grid>
        <GridCol offset-l="1" col-s="5" offset-s="0" col-xs="10" offset-xs="1">
          <StyledImage
            backgroundImage={assetFor('maker/fund_my_project/tip.png')}
            title={t('fund_my_project.tips.image_alt')}
          />
        </GridCol>

        <ListText />
      </Grid>
    </Container>
  )
}

const ListText = () => {
  const t = useTranslation()

  const tips = [
    {
      key: 1,
      title: t('fund_my_project.tips.tip1.title'),
      paragraph: t('fund_my_project.tips.tip1.paragraph'),
    },
    {
      key: 2,
      title: t('fund_my_project.tips.tip2.title'),
      paragraph: t('fund_my_project.tips.tip2.paragraph', {
        parseHtml: true,
      }),
    },
    {
      key: 3,
      title: t('fund_my_project.tips.tip3.title'),
      paragraph: t('fund_my_project.tips.tip3.new_paragraph', {
        parseHtml: true,
      }),
    },
    {
      key: 4,
      title: t('fund_my_project.tips.tip4.title'),
      paragraph: t('fund_my_project.tips.tip4.paragraph', {
        parseHtml: true,
      }),
    },
  ]

  return (
    <GridCol col-l="4" col-s="6" offset-s="1" col-xs="10" offset-xs="1">
      <VisuallyHidden as="h2">
        {t('fund_my_project.tips.hidden_title')}
      </VisuallyHidden>
      <SimpleCarousel id="SimpleCarousel">
        {tips.map((item) => (
          <TipSimpleCarousel
            key={item.key}
            title={parseHtml(item.title, { sanitize: false })}
            paragraph={item.paragraph}
          />
        ))}
      </SimpleCarousel>
    </GridCol>
  )
}

export default TipsSection
