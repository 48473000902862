import React from 'react'

import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'

import {
  FACEBOOK,
  getRouteFor,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
  YOUTUBE,
} from 'kiss/routes/redux'

import {
  Button,
  FacebookIconNext,
  InstagramIconNext,
  LinkedinIconNext,
  YoutubeIcon,
} from '@kisskissbankbank/kitten'

import classNames from 'classnames'
import styled from 'styled-components'

const StyledSocialIcon = styled.img`
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`

export const SocialButton = (props) => {
  const routeFor = useSelector(getRouteFor)

  const assetFor = useSelector(getAssetFor)

  const buttonProps = {
    as: 'a',
    target: '_blank',
    rel: 'noopener',
    fit: 'icon',
    modifier: 'beryllium',
    rounded: true,
  }

  return (
    <div
      {...props}
      className={classNames('k-Footer__socialButton', props.className)}
    >
      <Button href={routeFor(FACEBOOK)} {...buttonProps}>
        <span className="k-u-a11y-visuallyHidden">
          Facebook: KissKissBankBank
        </span>
        <FacebookIconNext />
      </Button>

      <Button href={routeFor(LINKEDIN)} {...buttonProps}>
        <span className="k-u-a11y-visuallyHidden">
          Linkedin: KissKissBankBank
        </span>
        <LinkedinIconNext />
      </Button>

      <Button href={routeFor(TWITTER)} {...buttonProps}>
        <span className="k-u-a11y-visuallyHidden">Twitter: KissBankers</span>
        <StyledSocialIcon
          src={assetFor('logos/LogoX.svg')}
          alt={'logos/LogoX.svg'}
        />
      </Button>

      <Button href={routeFor(INSTAGRAM)} {...buttonProps}>
        <span className="k-u-a11y-visuallyHidden">
          Instagram: KissKissBankBank
        </span>
        <InstagramIconNext />
      </Button>

      <Button href={routeFor(YOUTUBE)} {...buttonProps}>
        <span className="k-u-a11y-visuallyHidden">YouTube: KissKissCamp</span>
        <YoutubeIcon width="23" height="20" />
      </Button>
    </div>
  )
}
