import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import { getProject } from 'kiss/modules/project-page/page-state/selectors'

import { Title, pxToRem } from '@kisskissbankbank/kitten'
import styled from 'styled-components'

import Social from 'kiss/modules/contribute/thankyou/components/social'
import Comment from 'kiss/modules/contribute/thankyou/components/comment'

const StyledContainer = styled.div`
  padding: ${pxToRem(30)};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${pxToRem(32)};
  border-radius: 10px;
  background: var(--color-grey-100);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
`

const StyledAvatar = styled.img`
  --avatar-size: ${pxToRem(120)};

  box-sizing: border-box;
  object-fit: cover;
  object-position: center;
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: ${pxToRem(166)};
  border: ${pxToRem(4)} solid var(--color-grey-100);
`

const WaitForConfirmationCard = () => {
  const project = useSelector(getProject)

  const t = useTranslation()

  return (
    <StyledContainer>
      <Title
        modifier="tertiary"
        family="antiqueolive"
        className="k-u-align-center"
      >
        {t('cart.contribute.wait_for_confirmation.title')}
      </Title>
      <StyledAvatar
        src={
          project?.organization?.avatarImage?.url ||
          project?.owner?.avatarImage?.url
        }
        alt={project?.organization?.name || project?.owner?.name}
      />
      <Social />
      <Comment />
    </StyledContainer>
  )
}

export default WaitForConfirmationCard
