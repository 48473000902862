import {
  Container,
  Grid,
  GridCol,
  mq,
  pxToRem,
  Title,
} from '@kisskissbankbank/kitten'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  currentUserHasBacked,
  getPageColors,
  getProjectExtratimeState,
  getProjectId,
  getProjectState,
  getTotalOrders,
  isCurrentUserOwner,
} from 'kiss/modules/project-page/page-state/selectors'
import { getCurrentUserSlug } from 'kiss/session/redux'
import { isSuccessful } from 'kiss/utils/project/state'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import useContributions from '../../../hooks/use-contributions'
import Contact from './contact'
import Details from './details'
import OtherProjects from './other-projects'

const StyledContributorPanel = styled.div`
  --hr-width: 1px;
  --horizontal-gap: ${pxToRem(45)};
  --vertical-gap: ${pxToRem(30)};
  background-color: var(--color-primary-100);

  .kiss-Contributor {
    padding-block: ${pxToRem(30)};

    @media ${mq.tabletAndDesktop} {
      padding-block: ${pxToRem(50)};
    }
  }

  .kiss-Contributor__container {
    display: flex;
    gap: var(--vertical-gap) var(--horizontal-gap);

    .kiss-Contributor__details,
    .kiss-Contributor__otherProjects .k-Button {
      flex: 0 0 auto;
      justify-content: space-between;
    }

    @media ${mq.mobile} {
      &,
      .kiss-Contributor__otherProjects {
        flex-direction: column;
      }
    }

    @media ${mq.tablet} {
      flex-wrap: wrap;

      .kiss-Contributor__details,
      .kiss-Contributor__contact {
        flex: 1 0 calc(50% - var(--horizontal-gap) - var(--hr-width));
      }

      .kiss-Contributor__otherProjects {
        justify-content: space-between;
        flex-basis: 100%;
      }
    }
  }

  .kiss-Contributor__separator {
    border: 0;
    margin: 0;
    flex: 0 0 var(--hr-width);
    background-color: var(--color-grey-400);

    &:last-of-type {
      @media ${mq.tablet} {
        display: none;
      }
    }
  }
`

const ContributorPanel = () => {
  const t = useTranslation()
  const hasBacked = useSelector(currentUserHasBacked)
  const isOwner = useSelector(isCurrentUserOwner)
  const state = useSelector(getProjectState)
  const isExtratime = useSelector(getProjectExtratimeState)
  const totalOrders = useSelector(getTotalOrders)
  const userSlug = useSelector(getCurrentUserSlug)
  const projectId = useSelector(getProjectId)
  const pageColors = useSelector(getPageColors)

  const { getContributions, contributionsOrdersFormatted, loading } =
    useContributions()

  useEffect(() => {
    if (userSlug && projectId) {
      getContributions({ variables: { slug: userSlug, projectId: projectId } })
    }
  }, [userSlug, projectId])

  if (!hasBacked || isOwner || (!isSuccessful(state) && !isExtratime))
    return null

  return (
    <StyledContributorPanel style={pageColors}>
      <Container className="kiss-Contributor">
        <Grid>
          <GridCol col-l="10" offset-l="1">
            {loading ? (
              <div className="k-u-margin-vertical-double k-u-flex k-u-flex-alignItems-center k-u-flex-justifyContent-center">
                <LoadingAnimation />
              </div>
            ) : (
              <>
                <Title
                  modifier="quaternary"
                  className="k-u-margin-bottom-triple"
                >
                  {t('projects.contributor_panel.title', {
                    contributors: totalOrders,
                    parseHtml: true,
                  })}
                </Title>
                <div className="kiss-Contributor__container">
                  <Details contributions={contributionsOrdersFormatted} />
                  <hr className="kiss-Contributor__separator" />
                  <Contact />
                  <hr className="kiss-Contributor__separator" />
                  <OtherProjects />
                </div>
              </>
            )}
          </GridCol>
        </Grid>
      </Container>
    </StyledContributorPanel>
  )
}

export default ContributorPanel
