import styled from 'styled-components'
import { pxToRem } from '@kisskissbankbank/kitten'

const Content = styled.div`
  box-sizing: border-box;
  min-height: calc(100vh - ${pxToRem(80)});
  padding-block: ${pxToRem(80)};
`

export default Content
