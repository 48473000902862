import * as qs from 'neoqs'

const withLocale = (path, state) => `/${state.locale}${path}`

// Users endpoints

export const facebookLoginPath = (params) => {
  const qsParams = params ? `?${qs.stringify(params)}` : ''
  return `/users/auth/facebook${qsParams}`
}

export const googleLoginPath = (params) => {
  const qsParams = params ? `?${qs.stringify(params)}` : ''
  return `/users/auth/google_oauth2${qsParams}`
}

// Maker endpoints
export const mentorProjectPath = (slug, state) =>
  withLocale(`/projects/${slug}/mentorships`, state)
