import { FlexWrapper, mq, pxToRem, HeroLayout } from '@kisskissbankbank/kitten'
import React from 'react'
import styled from 'styled-components'
import { SHARE_SECTION_ID } from '../../constants/id'
import Engagements from './components/engagements'
import Social from './components/social'
import Website from './components/website'
import Mentors from './components/mentors'

const StyledAsideCard = styled(FlexWrapper)`
  @media ${mq.mobileAndTablet} {
    border: var(--border);
    padding: ${pxToRem(30)} ${pxToRem(10)};
    align-items: center;
    border-radius: var(--border-radius-m);

    .kiss-AsideTitle {
      text-align: center;
      font-size: ${pxToRem(10)};
    }
  }
`

const Identity = ({ children }) => {
  return (
    <HeroLayout.Main.LastAside sticky="desktop" id={SHARE_SECTION_ID}>
      <StyledAsideCard gap={20}>
        <Social />
        <Website />
        <Engagements />
        <Mentors />
        {children}
      </StyledAsideCard>
    </HeroLayout.Main.LastAside>
  )
}

export default Identity
