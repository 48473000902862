import React from 'react'
import algoliasearch from 'algoliasearch'
import {
  connectSearchBox,
  Configure,
  InstantSearch,
} from 'react-instantsearch-dom'
import config from 'kiss/config'
import SearchBox from './search-box'

const searchClient = algoliasearch(
  config[APP_ENV].algolia.appId,
  config[APP_ENV].algolia.apiKey,
)

const SearchButton = () => {
  const algoliaSearchClient = {
    ...searchClient,
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        })
      }
      return searchClient.search(requests)
    },
  }
  const DebouncedSearchBox = connectSearchBox(SearchBox)

  return (
    <InstantSearch
      searchClient={algoliaSearchClient}
      indexName={config[APP_ENV].algolia.indexName}
    >
      <Configure hitsPerPage={5} filters="state:started OR state:successful" />
      <DebouncedSearchBox defaultRefinement="" delay={300} />
    </InstantSearch>
  )
}

export default SearchButton
