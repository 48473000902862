import styled from 'styled-components'
import { pxToRem } from '@kisskissbankbank/kitten'

const Wrapper = styled.div`
  background-color: var(--color-grey-200);
  border-radius: var(--border-radius-m);
  padding: ${pxToRem(30)};
`

export default Wrapper
