import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, FUNNEL } from 'kiss/routes/redux'

import {
  pxToRem,
  mq,
  Title,
  Text,
  Button,
  HandIllustration,
  PatchNewIllustration,
  MoneyBankIllustration,
  LibraryIllustration,
} from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const UnderHeroWrapper = styled.section`
  display: flex;
  justify-content: center;
  color: var(--color-grey-900);
  text-align: center;

  > * {
    z-index: 2;
  }

  .kiss-Homepage-underHero__background__left {
    background-color: var(--color-warning-100);
    width: 50vw;
    left: 0;

    @media ${mq.mobileAndTablet} {
      width: 100vw;
    }

    @media ${mq.tablet} {
      padding-top: ${pxToRem(40)};
    }
  }

  .kiss-Homepage-underHero__background__center {
    background-color: var(--color-danger-100);
    width: 50vw;
    right: 0;

    @media ${mq.mobileAndTablet} {
      width: 100vw;
    }
  }

  .kiss-Homepage-underHero__background__right {
    background-color: var(--color-success-100);
    width: 50vw;
    right: 0;

    @media ${mq.mobileAndTablet} {
      width: 100vw;
    }
  }

  @media ${mq.mobileAndTablet} {
    display: block;
  }

  .kiss-Homepage__underHero__title {
    z-index: 3;
    position: absolute;
    margin-top: ${pxToRem(65)};

    @media ${mq.mobileAndTablet} {
      margin-bottom: -${pxToRem(60)};
      margin-top: 0;

      padding-bottom: ${pxToRem(30)};
      position: relative;
    }
    @media ${mq.mobile} {
      padding-top: ${pxToRem(30)};
    }
  }

  .kiss-Homepage__underHero__block {
    padding: ${pxToRem(190)} ${pxToRem(100)} ${pxToRem(100)};
    @media ${mq.mobileAndTablet} {
      padding: ${pxToRem(50)} ${pxToRem(30)};
      object-fit: cover;
    }
  }

  .kiss-Homepage__underHero__patch {
    display: inline-flex;
    position: absolute;
    margin-left: ${pxToRem(20)};
    margin-top: ${pxToRem(20)};

    @media ${mq.mobileAndTablet} {
      margin-left: 0;
    }

    svg {
      @media ${mq.mobileAndTablet} {
        width: 90%;
      }
    }
  }
`

const UnderHero = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <UnderHeroWrapper>
      <Title
        tag="h2"
        modifier="tertiary"
        noMargin
        family="antiqueolive"
        className="kiss-Homepage__underHero__title k-u-hidden@m-down"
      >
        {t('new_home.under_hero.title', { parseHtml: true })}
      </Title>
      <div className="kiss-Homepage-underHero__background__left">
        <Title
          tag="h2"
          modifier="tertiary"
          noMargin
          family="antiqueolive"
          className="kiss-Homepage__underHero__title k-u-hidden@l-up"
        >
          {t('new_home.under_hero.title', { parseHtml: true })}
        </Title>
        <div className="kiss-Homepage__underHero__block">
          <MoneyBankIllustration width="165" height="293" />
          <div className="k-u-margin-vertical-double">
            <Text
              weight="600"
              size="huge"
              cssColor="var(--color-warning-500)"
              family="antiqueolive"
            >
              {t('new_home.under_hero.campaign.title')}
            </Text>
            <Text
              tag="p"
              weight="500"
              size="medium"
              className="k-u-margin-top-noneHalf"
            >
              {t('new_home.under_hero.campaign.description')}
            </Text>
          </div>

          <Button
            as="a"
            size="small"
            href={routeFor(FUNNEL)}
            style={{
              backgroundColor: 'var(--color-warning-500)',
              borderColor: 'var(--color-warning-500)',
              color: 'var(--color-grey-000)',
            }}
          >
            {t('new_home.under_hero.button')}
          </Button>
        </div>
      </div>

      <div className="kiss-Homepage-underHero__background__center">
        <div className="kiss-Homepage__underHero__block">
          <LibraryIllustration
            width="200"
            height="293"
            viewBox="0 0 1365 1293"
          />
          <div className="k-u-margin-vertical-double">
            <Text
              weight="600"
              size="huge"
              cssColor="var(--color-danger-700)"
              family="antiqueolive"
            >
              {t('new_home.under_hero.pre_orders.title')}
            </Text>
            <Text
              tag="p"
              weight="500"
              size="medium"
              className="k-u-margin-top-noneHalf"
            >
              {t('new_home.under_hero.pre_orders.description')}
            </Text>
          </div>

          <Button as="a" size="small" modifier="iron" href={routeFor(FUNNEL)}>
            {t('new_home.under_hero.button')}
          </Button>
        </div>
      </div>

      <div className="kiss-Homepage-underHero__background__right">
        <div className="kiss-Homepage__underHero__block">
          <HandIllustration width="165" height="293" viewBox="0 0 573 1133" />
          <div className="kiss-Homepage__underHero__patch">
            <PatchNewIllustration width="86" height="86" />
          </div>
          <div className="k-u-margin-vertical-double">
            <Text
              weight="600"
              size="huge"
              cssColor="var(--color-success-700)"
              family="antiqueolive"
            >
              {t('new_home.under_hero.permanent.title', { parseHtml: true })}
            </Text>
            <Text
              tag="p"
              weight="500"
              size="medium"
              className="k-u-margin-top-noneHalf"
            >
              {t('new_home.under_hero.permanent.description')}
            </Text>
          </div>

          <Button
            as="a"
            size="small"
            modifier="krypton"
            href={routeFor(FUNNEL)}
          >
            {t('new_home.under_hero.button')}
          </Button>
        </div>
      </div>
    </UnderHeroWrapper>
  )
}

export default UnderHero
