import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ScreenConfig, stepToRem, Text } from '@kisskissbankbank/kitten'

const StyledBigText = styled(Text)`
  font-size: ${stepToRem(-1)};
  @media (min-width: ${ScreenConfig.XS.min}px) {
    font-size: ${stepToRem(0)};
  }
  @media (min-width: ${ScreenConfig.M.min}px) {
    font-size: ${stepToRem(1)};
  }
`

const StyledSmallText = styled(Text)`
  font-size: ${stepToRem(-2)};
  @media (min-width: ${ScreenConfig.M.min}px) {
    font-size: ${stepToRem(-1)};
  }
`

const Info = ({ value, text, valueDataTestId, textDataTestId }) => {
  return (
    <>
      <StyledBigText
        tag="div"
        color="font1"
        weight="600"
        data-test-id={textDataTestId}
      >
        {value}
      </StyledBigText>

      <StyledSmallText
        tag="div"
        color="font1"
        weight="400"
        data-test-id={valueDataTestId}
      >
        {text}
      </StyledSmallText>
    </>
  )
}

Info.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  valueDataTestId: PropTypes.string,
  textDataTestId: PropTypes.string,
}

Info.defaultProps = {
  valueDataTestId: null,
  textDataTestId: null,
}

export default Info
