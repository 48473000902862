import React, { memo } from 'react'
import RichContentText from 'kiss/modules/components/rich-content/text'
import RichContentButton from 'kiss/modules/components/rich-content/button'
import {
  Container,
  Grid,
  GridCol,
  IconList,
  parseHtml,
  TitleWithStroke,
  RichText,
  FlexWrapper,
} from '@kisskissbankbank/kitten'
import getTitleId from 'kiss/utils/get-title-id'

const TextWithIconList = ({ title, description, callToAction, items }) => (
  <Container>
    <Grid className="k-u-flex-alignItems-center">
      <GridCol col-xs="10" offset-xs="1" col-l="4">
        <FlexWrapper gap={30}>
          {title.text && (
            <TitleWithStroke
              tag={`h${title.level}`}
              modifier="tertiary"
              noMargin
              className="k-u-align-center@xs-down"
              id={getTitleId(title.text)}
            >
              {title.text}
            </TitleWithStroke>
          )}

          {description && (
            <RichContentText
              content={description}
              paragraphProps={{
                fontSizeDefault: 'primary',
                fontSizeFromS: null,
              }}
            />
          )}

          {callToAction.text && (
            <RichContentButton
              {...callToAction}
              className="k-u-alignSelf-start k-u-hidden@m-down"
            />
          )}
        </FlexWrapper>
      </GridCol>

      <GridCol col-xs="10" offset-xs="1" col-l="5">
        {items && (
          <IconList>
            {items.map((item, index) => (
              <IconList.Item
                key={`${item.content.html}-${index}`}
                icon={item.iconUrl && <img src={item.iconUrl} alt="" />}
                iconProps={{
                  style: {
                    backgroundColor: `#${item.iconBackgroundColor}`,
                    borderColor: `#${item.iconBackgroundColor}`,
                  },
                }}
              >
                <RichText as="span">
                  {parseHtml(item.content.html, { sanitize: false })}
                </RichText>
              </IconList.Item>
            ))}
          </IconList>
        )}

        {callToAction.text && (
          <RichContentButton
            as="a"
            mobileFit="fluid"
            {...callToAction}
            className="k-u-margin-top-triple k-u-align-center k-u-hidden@l-up"
          />
        )}
      </GridCol>
    </Grid>
  </Container>
)

export default memo(TextWithIconList)
