import { EyeIconNext, FlexWrapper, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import OrderModal from 'kiss/modules/order-modal'
import { getRouteFor, USER_PROFILE_CONTRIBUTIONS } from 'kiss/routes/redux'
import { getCurrentUserSlug } from 'kiss/session/redux'
import { isoDateToLocaleFormat } from 'kiss/utils/iso-date-to-locale-format'
import compact from 'lodash/fp/compact'
import flattenDeep from 'lodash/fp/flattenDeep'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import map from 'lodash/fp/map'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const getLastDeliveryDate = (locale, contributions) => {
  const deliveryDates = flow(
    map((contribution) =>
      contribution?.orderLines.map((orderLine) => {
        if (!orderLine.shippingDate) return null
        return new Date(orderLine.shippingDate)
      }),
    ),
    flattenDeep,
    compact,
  )(contributions)

  if (isEmpty(deliveryDates)) return null
  return isoDateToLocaleFormat(new Date(Math.max(...deliveryDates)), {
    format: 'MMMM yyyy',
    locale,
  })
}

const Details = ({ contributions }) => {
  const t = useTranslation()
  const { locale } = useParams()
  const user = useSelector(getCurrentUserSlug)
  const routeFor = useSelector(getRouteFor)
  const ordersTotal = contributions.length

  if (ordersTotal === 0) return null

  const lastDeliveryDate = getLastDeliveryDate(locale, contributions)

  const SeveralContributionsDetails = () => {
    return (
      <>
        <div>
          <Text weight="500">
            {t('projects.contributor_panel.details.contribution_plural', {
              parseHtml: true,
              total: ordersTotal,
            })}
          </Text>
          {lastDeliveryDate && (
            <div>
              <Text weight="500">
                {t('projects.contributor_panel.details.delivery_date', {
                  parseHtml: true,
                })}
              </Text>{' '}
              <Text weight="400">{lastDeliveryDate}</Text>
            </div>
          )}
        </div>
        <Text
          tag="a"
          weight="500"
          className="k-u-link k-u-link-primary1 k-u-cursor-pointer k-u-flex k-u-flex-alignItems-center k-u-flex-gap-single"
          href={routeFor(USER_PROFILE_CONTRIBUTIONS, {
            user,
          })}
        >
          <EyeIconNext
            color="currentColor"
            height="18"
            width="22"
            viewBox="1 2 22 18"
          />
          {t('projects.contributor_panel.details.all_contributions')}
        </Text>
      </>
    )
  }

  const OneContributionDetails = () => {
    return (
      <>
        <div>
          <div>
            <Text weight="500">
              {t('projects.contributor_panel.details.contribution', {
                parseHtml: true,
              })}
            </Text>{' '}
            <Text weight="400">
              {t('project_info.amount_with_currency', {
                amount: t(contributions[0].totalAmount.cents / 100, {
                  formatNumber: { decimal: 2 },
                }),
                currency: t(
                  `currency_symbol.${contributions[0].totalAmount.currency}`,
                ),
                parseHtml: true,
              })}
            </Text>
          </div>
          {lastDeliveryDate && (
            <div>
              <Text weight="500">
                {t('projects.contributor_panel.details.delivery_date', {
                  parseHtml: true,
                })}
              </Text>{' '}
              <Text weight="400">{lastDeliveryDate}</Text>
            </div>
          )}
        </div>

        <OrderModal
          order={contributions[0]}
          trigger={
            <Text
              tag="button"
              type="button"
              weight="500"
              className="k-u-link k-u-link-primary1 k-u-cursor-pointer k-u-flex k-u-flex-alignItems-center k-u-flex-gap-noneHalf"
            >
              <EyeIconNext
                color="currentColor"
                height="18"
                width="22"
                viewBox="1 2 22 18"
              />
              {t('projects.contributor_panel.details.contribution_details')}
            </Text>
          }
        />
      </>
    )
  }

  return (
    <FlexWrapper gap={10} className="kiss-Contributor__details">
      {ordersTotal > 1 ? (
        <SeveralContributionsDetails />
      ) : (
        <OneContributionDetails />
      )}
    </FlexWrapper>
  )
}

export default Details
