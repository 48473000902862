import { Button, GoogleIconNext } from '@kisskissbankbank/kitten'
import { googleLoginPath } from 'kiss/api/rails-endpoints'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor as getRouteForSelector } from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'

const GoogleButton = ({ returnTo, routeParams, className }) => {
  const t = useTranslation()
  const getRouteFor = useSelector(getRouteForSelector)
  return (
    <Button
      fit="fluid"
      className={className}
      as="a"
      href={googleLoginPath({
        return_to_path: returnTo ? getRouteFor(returnTo, routeParams) : null,
      })}
      data-test-id="authenticate-google-connect"
    >
      <GoogleIconNext
        className="k-ColorSvg"
        width={20}
        height={20}
        color="#1977F3"
      />
      <span>{t('authenticate.google.label')}</span>
    </Button>
  )
}

export default GoogleButton
