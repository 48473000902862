import React, { useMemo } from 'react'
import flow from 'lodash/fp/flow'
import some from 'lodash/fp/some'
import prop from 'lodash/fp/prop'
import { getDeliveryCountryAmounts } from 'kiss/modules/project-page/page-state/selectors'
import ShippingAddress from './shipping_address'
import CommonShipping from 'kiss/modules/components/reward-modal/shipping'

const hasNoShipping = (reward) =>
  flow(prop('deliveryModes'), some({ code: 'none' }))(reward)

const Shipping = ({ reward }) => {
  const deliveryCountryAmounts = useMemo(
    () => getDeliveryCountryAmounts(reward),
    [reward],
  )

  if (hasNoShipping(reward)) return null

  return (
    <>
      <CommonShipping
        reward={reward}
        shippingAddressComponent={
          <ShippingAddress
            deliveryCountries={deliveryCountryAmounts}
            period={reward?.period}
            hideLabel={!!document?.getElementById('radio-postal')}
            handleSelectChange={() => {
              document?.getElementById('radio-postal')?.click()
            }}
          />
        }
      ></CommonShipping>
    </>
  )
}

export default Shipping
