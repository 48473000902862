import React, { useEffect } from 'react'
import {
  ArrowIcon,
  Button,
  Container,
  CONTAINER_PADDING,
  CONTAINER_PADDING_THIN,
  Grid,
  GridCol,
  SCREEN_SIZE_M,
  Title,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { BROWSING_POPULAR, getRouteFor } from 'kiss/routes/redux'
import ProjectCard from 'kiss/components/cards/project-card'
import styled from 'styled-components'
import { pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectSlug, getSimilarProjects } from '../../redux'
import { loadSimilarProjects } from '../redux'

const StyledWrapper = styled.div`
  .kiss-SimilarProjects__button {
    flex-shrink: 0;
    margin-left: ${pxToRem(10)};

    @media (max-width: ${ScreenConfig.M.max}px) {
      position: absolute;
      bottom: 0;
      right: ${pxToRem(CONTAINER_PADDING_THIN)};

      @media (min-width: ${ScreenConfig.S.min}px) {
        right: ${pxToRem(CONTAINER_PADDING)};
      }
    }
  }

  .kiss-SimilarProjects__projectsContainer {
    position: relative;
    padding-bottom: ${pxToRem(80)};
  }

  .kiss-SimilarProjects__projectsView {
    display: grid;
    grid-gap: ${pxToRem(20)};
    grid-template-columns: repeat(1, 1fr);
    padding-left: ${pxToRem(20)};
    padding-right: ${pxToRem(20)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      grid-gap: ${pxToRem(20)};
      grid-template-columns: repeat(2, 1fr);
      padding-left: ${pxToRem(35)};
      padding-right: ${pxToRem(35)};
    }

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      padding: 0;
      grid-gap: ${pxToRem(30)};
      grid-template-columns: repeat(4, 1fr);
    }

    &.kiss-BrowsingGrid--loading {
      filter: grayscale(1) opacity(0.4);
      transition: filter 0.4s;
    }
  }
`

const SimilarProjects = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const dispatch = useDispatch()

  const projectSlug = useSelector(getProjectSlug)
  const similarProjects = useSelector(getSimilarProjects)

  useEffect(() => {
    async function dispatchSimilarProjectLoad() {
      dispatch(loadSimilarProjects(projectSlug))
    }
    dispatchSimilarProjectLoad()
  }, [])

  return (
    <StyledWrapper>
      <Container className="k-u-margin-bottom-double k-u-margin-bottom-triple@s-up">
        <Grid>
          <GridCol
            col-l="10"
            offset-l="1"
            className="k-u-flex k-u-flex-alignItems-end k-u-flex-justifyContent-sb"
          >
            <Title tag="h2" modifier="quaternary" noMargin>
              {t('contribute.thank_you.carousel.title')}
            </Title>

            <Button
              as="a"
              href={routeFor(BROWSING_POPULAR)}
              className="kiss-SimilarProjects__button k-u-hidden@m-down"
            >
              <span>{t('contribute.thank_you.carousel.button.label')}</span>
              <ArrowIcon width="6" />
            </Button>
          </GridCol>
        </Grid>
      </Container>

      <Container
        fullWidthBelowScreenSize={SCREEN_SIZE_M}
        className="kiss-SimilarProjects__projectsContainer"
      >
        <Grid>
          <GridCol col-l="10" offset-l="1">
            <div className="kiss-SimilarProjects__projectsView">
              {similarProjects.map((project) => (
                <ProjectCard project={project} key={project.id} />
              ))}
            </div>

            <Button
              as="a"
              href={routeFor(BROWSING_POPULAR)}
              className="kiss-SimilarProjects__button k-u-hidden@l-up"
            >
              <span>{t('contribute.thank_you.carousel.button.label')}</span>
              <ArrowIcon width="6" />
            </Button>
          </GridCol>
        </Grid>
      </Container>
    </StyledWrapper>
  )
}

export default SimilarProjects
