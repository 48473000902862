import { ContributionCard, Text, pxToRem } from '@kisskissbankbank/kitten'
import {
  getOrderLines,
  hasOrderLines as hasOrderLinesSelector,
  removeVariation,
  updateTip,
} from '../../../cart/redux'
import { useDispatch, useSelector } from 'react-redux'

import QuantityManager from './quantity-manager'
import React from 'react'
import flow from 'lodash/fp/flow'
import { getJSONContent } from '@kisskissbankbank/underwood'
import getOr from 'lodash/fp/getOr'
import head from 'lodash/fp/head'
import isEmpty from 'lodash/fp/isEmpty'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'

const DescriptionWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-right: ${pxToRem(40)};
`

const InfoMessageWrapper = styled.div`
  padding: ${pxToRem(17)};
  padding-left: ${pxToRem(20)};
  border-radius: 0.5rem;
`

const getFirstBlockText = (description) =>
  flow(
    getJSONContent,
    getOr([])('blocks'),
    head,
    getOr('')('text'),
  )(description)

const Rewards = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const orderLines = useSelector(getOrderLines)
  const hasOrderLines = useSelector(hasOrderLinesSelector)

  if (!hasOrderLines) return null

  return (
    <>
      {orderLines.map(({ id, quantity, totalAmount, variation }, index) => {
        const { reward, remainingQuantity } = variation
        const rawDescription = getFirstBlockText(reward?.description)
        const formattedAmount = t('project_info.amount_with_currency', {
          amount: t(totalAmount?.cents / 100 || 0, {
            formatNumber: { decimal: 2 },
          }),
          currency: t(`currency_symbol.${totalAmount?.currency}`),
          parseHtml: true,
        })

        const hasNoStockLeft =
          (remainingQuantity && remainingQuantity === quantity) ||
          reward.remaining === 0

        return (
          <div
            className="k-u-margin-bottom-singleHalf"
            key={`${reward.id}-${index}`}
            data-test-id={`reward-card-${index}`}
          >
            <ContributionCard
              onClose={() => {
                dispatch(
                  removeVariation({
                    variationId: variation.id,
                  }),
                )
                if (orderLines.length === 1) {
                  dispatch(updateTip(0))
                }
              }}
            >
              {reward?.image?.url && (
                <ContributionCard.Image>
                  <img src={reward.image.url} alt="" />
                </ContributionCard.Image>
              )}
              <ContributionCard.Title
                style={{ overflow: 'hidden', maxWidth: '100%' }}
              >
                {isEmpty(reward.label) ? (
                  <DescriptionWrapper>{rawDescription}</DescriptionWrapper>
                ) : (
                  reward.label
                )}
                {variation.label && (
                  <ContributionCard.Description>
                    {variation.label}
                  </ContributionCard.Description>
                )}
              </ContributionCard.Title>
              <QuantityManager
                quantity={quantity}
                orderLineId={id}
                hasNoStockLeft={hasNoStockLeft}
              />
              <ContributionCard.Amount>
                {formattedAmount}
              </ContributionCard.Amount>
            </ContributionCard>
            {hasNoStockLeft && (
              <InfoMessageWrapper className="k-u-margin-top-noneHalf k-u-background-color-primary6">
                <Text size="small">
                  {t('contribute.cart.quantity.info.max')}
                </Text>
              </InfoMessageWrapper>
            )}
          </div>
        )
      })}
    </>
  )
}

export default Rewards
