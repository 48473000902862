import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useQuery } from 'kiss/api/graphql-query'
import CountriesQuery from 'kiss/graphql/queries/maker/countries.graphql'
import CompliantCountriesQuery from 'kiss/graphql/queries/maker/compliant_countries.graphql'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import Dropdown from 'kiss/components/formik/dropdown-select'
import { useTranslation } from 'kiss/hooks/use-translation'
import orderBy from 'lodash/fp/orderBy'
import deburr from 'lodash/fp/deburr'
import { pxToRem } from '@kisskissbankbank/kitten'

const Loader = styled(LoadingAnimation)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pxToRem(50)};
`

const formatOptions = (data, t, showFranceOnFirst, defaultCountries = []) => {
  const options = data.countries.map((country) => ({
    value: country.code,
    label: t(`countries.${country.code}`),
    deburredLabel: deburr(t(`countries.${country.code}`)),
  }))
  const orderedCountries = [
    ...defaultCountries,
    ...orderBy(['deburredLabel'])(['asc'])(options),
  ]
  return showFranceOnFirst
    ? [{ value: 'FR', label: t(`countries.FR`) }, ...orderedCountries]
    : orderedCountries
}

const SelectCountries = ({
  showFranceOnFirst,
  searchable,
  defaultCountries,
  allCountries,
  ...others
}) => {
  const t = useTranslation()
  const { data, loading } = useQuery(
    allCountries ? CountriesQuery : CompliantCountriesQuery,
  )
  if (loading) {
    return <Loader className="k-u-align-center" />
  }
  if (!data) return null

  return (
    <Dropdown
      options={formatOptions(data, t, showFranceOnFirst, defaultCountries)}
      searchable={searchable}
      {...others}
    />
  )
}

SelectCountries.propTypes = {
  name: PropTypes.string.isRequired,
  showFranceOnFirst: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  searchable: PropTypes.bool,
  defaultCountries: PropTypes.array,
  allCountries: PropTypes.bool,
}

SelectCountries.defaultProps = {
  disabled: false,
  showFranceOnFirst: true,
  searchable: false,
  placeholder: '',
  defaultCountries: [],
  allCountries: true,
}

export default SelectCountries
