import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getLocale } from 'kiss/app/redux'
import { getNews } from '../../page-state/selectors'
import useProject from '../hooks/use-project'
import { useTranslation } from '../../../../hooks/use-translation'
import { ContentCard, parseHtml } from '@kisskissbankbank/kitten/src'
import styled from 'styled-components'

const StyledContentCard = styled(ContentCard)`
  background-color: #e7f1ff;
  padding-top: 30px;
  font-family: var(--font-family-generalsans);
`

export default function NewsPreview() {
  const t = useTranslation()
  const locale = useSelector(getLocale)
  const { project, loading } = useProject()
  const newsconnection = useSelector(getNews)
  const [news] = newsconnection ?? []

  if (loading || project?.pricingPlans?.label === 'starter' || !news)
    return null

  const date = new Date(news?.publicationAt)

  const dateFormated = date.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })

  return (
    <StyledContentCard className="k-u-margin-bottom-triple">
      <div>{dateFormated}</div>

      <ContentCard.Title className="k-u-flex k-u-flex-alignItems-center k-u-flex-justifyContent-start">
        {parseHtml(news?.title)}
      </ContentCard.Title>

      <Link to={`/${locale}/projects/${project?.slug}/tabs/news`}>
        {t('project.news.see_more')}
      </Link>
    </StyledContentCard>
  )
}
