export const getBrowserInfo = () => {
  return {
    javaEnabled: navigator.javaEnabled(),
    language: navigator.language,
    colorDepth: screen.colorDepth,
    screenHeight: screen.height,
    screenWidth: screen.width,
    timeZoneOffset: new Date().getTimezoneOffset().toString(),
    javascriptEnabled: true,
  }
}
