import { Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const FundingPercentInfo = ({ count }) => {
  const t = useTranslation()
  return (
    <div>
      <Text weight="400" size="small">
        {t('browsing.project_card.percent_mobile', {
          parseHtml: true,
          count,
        })}
      </Text>
      <Text weight="500" size="small">
        {`${t(count, { formatNumber: true })} %`}
      </Text>
    </div>
  )
}

export default FundingPercentInfo
