import { gql } from '@apollo/client'
import { useMutation } from '../../api/graphql-query'

const REGISTER_CARD_MUTATION = gql`
  mutation RegisterCard($cardType: String!, $subscriptionId: String) {
    registerCard(subscriptionId: $subscriptionId, cardType: $cardType) {
      accessKey
      registrationUrl
      preregistrationData
      uuid
    }
  }
`

const useCallRegisterSubscriptionCard = () => {
  const [registerCardMutation] = useMutation(REGISTER_CARD_MUTATION)

  return {
    callRegisterSubscriptionCard: async ({ cardType, subscriptionId }) => {
      try {
        const { data } = await registerCardMutation({
          variables: { cardType, subscriptionId },
        })
        return data.registerCard
      } catch (e) {
        throw Error('callRegisterCard failed')
      }
    },
  }
}

export default useCallRegisterSubscriptionCard
