import { gql } from '@apollo/client'
import includes from 'lodash/fp/includes'
import { useMutation } from '../../api/graphql-query'
import { getBrowserInfo } from '../helpers/browser-infos'
import { NO_REDIRECT_URL, TIMEOUT } from '../config'
import isTimeout from '../helpers/is-timeout'

const RESTART_SUBSCRIPTION = gql`
  mutation RestartSubscription(
    $subscriptionId: ID!
    $creditCard: CreditCardInput!
    $browserInfo: BrowserInfoInput
  ) {
    restartSubscription(
      subscriptionId: $subscriptionId
      creditCard: $creditCard
      browserInfo: $browserInfo
    ) {
      redirectUrl
      error
    }
  }
`

const useRestartSubscription = () => {
  const [restartSubscription] = useMutation(RESTART_SUBSCRIPTION)

  return {
    restartSubscription: async ({
      subscriptionId,
      cardRegistrationUuid,
      registrationData,
    }) => {
      try {
        const response = await restartSubscription({
          variables: {
            subscriptionId,
            creditCard: {
              cardRegistrationUuid: cardRegistrationUuid,
              registrationData: registrationData,
            },
            browserInfo: getBrowserInfo(),
          },
        })
        if (isTimeout(response)) {
          throw TIMEOUT
        }
        if (!response.data.restartSubscription.redirectUrl) {
          throw NO_REDIRECT_URL
        }
        return response.data.restartSubscription.redirectUrl
      } catch (e) {
        if (includes('Timeout')(e.message)) {
          throw TIMEOUT
        }
        throw e
      }
    },
  }
}

export default useRestartSubscription
