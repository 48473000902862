import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { PaymentIllustration } from 'kiss/payment/illustration'
import { GridCol, Marger, Text, Title } from '@kisskissbankbank/kitten'
import { getErrorCode } from '../change-card-polling/redux'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const ErrorMessage = styled.div`
  text-align: center;
  font-weight: 500;
`

const ChangeCardFailure = () => {
  const t = useTranslation()
  const errorCode = useSelector(getErrorCode)

  const isGenericError = () =>
    t(`payment_errors.mangopay.${errorCode}`).indexOf(
      'payment_errors.mangopay',
    ) !== -1

  return (
    <>
      <GridCol>
        <Marger top="8" bottom="3">
          <Title className="k-u-align-center" noMargin modifier="secondary">
            {t('contributor.orders.means-of-payment.modal.failure.title')}
          </Title>
        </Marger>
        {errorCode && (
          <ErrorMessage>
            <Text color="font1" weight="400">
              {`${t('payment_errors.mangopay.head')} ${t(
                `payment_errors.mangopay.${
                  isGenericError() ? 'generic' : errorCode
                }`,
              )}.`}
              <br />
              {t('payment_errors.mangopay.subheader')}
            </Text>
          </ErrorMessage>
        )}

        <Marger top="3" bottom="3" className="k-u-align-center">
          <PaymentIllustration />
        </Marger>
      </GridCol>
    </>
  )
}

export default ChangeCardFailure
