import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Paragraph } from '@kisskissbankbank/kitten'
import {
  PROJECT_ROUTE,
  getRouteFor as getRouteForSelector,
} from 'kiss/routes/redux'
import { NAVIGATION_ID } from 'kiss/modules/project-page/page-state/redux'
import { getOrderTotal } from 'kiss/modules/contribute/cart/redux'
import { getProjectSlug } from 'kiss/modules/contribute/redux'

const NoResult = (props) => {
  const { buttonText, description } = props
  const totalAmount = useSelector(getOrderTotal)
  const projectSlug = useSelector(getProjectSlug)
  const getRouteFor = useSelector(getRouteForSelector)

  if (totalAmount !== 0) return null

  return (
    <div {...props}>
      <div className="k-u-margin-bottom-quadruple">
        <Paragraph modifier="secondary" noMargin>
          {description}
        </Paragraph>
      </div>

      <Button
        tag="a"
        href={`${getRouteFor(PROJECT_ROUTE, {
          project: projectSlug,
        })}#${NAVIGATION_ID}`}
        modifier="helium"
        size="large"
        fit="fluid"
      >
        {buttonText}
      </Button>
    </div>
  )
}

export default NoResult
