import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { MAX_LENGTH_ADDRESS } from '../modal'

const AddressBis = () => {
  const t = useTranslation()

  const onValidate = (value) => {
    if (value?.length === MAX_LENGTH_ADDRESS) {
      return t('contribute.shipping_address.error.max_length')
    }
  }

  return (
    <>
      <div className="k-u-margin-vertical-triple">
        <Label htmlFor="address2">
          {t('contribute.shipping_address.form.address2.label')}
        </Label>

        <SimpleText
          name="address2"
          autocomplete="address-line2"
          placeholder={t(
            'contribute.shipping_address.form.address2.placeholder',
            { parseHtml: true },
          )}
          maxLength={MAX_LENGTH_ADDRESS}
          validate={onValidate}
        />
      </div>
      <div className="k-u-margin-vertical-triple">
        <Label htmlFor="address3">
          {t('contribute.shipping_address.form.address3.label')}
        </Label>

        <SimpleText
          name="address3"
          autocomplete="address-line3"
          maxLength={MAX_LENGTH_ADDRESS}
          validate={onValidate}
        />
      </div>
      <div className="k-u-margin-vertical-triple">
        <Label htmlFor="address4">
          {t('contribute.shipping_address.form.address4.label')}
        </Label>

        <SimpleText
          name="address4"
          autocomplete="address-line4"
          maxLength={MAX_LENGTH_ADDRESS}
          validate={onValidate}
        />
      </div>
    </>
  )
}

export default AddressBis
