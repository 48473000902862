import {
  HEADER_HEIGHT,
  FlexWrapper,
  Button,
  HeroLayout,
  mq,
  pxToRem,
  EnvelopeIconNext,
} from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useMediaQuery } from 'kiss/media-queries/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getRouteFor,
  USER_MESSAGE_NEW,
  SUBSCRIBER_PROJECT_ABOUT_ROUTE,
} from 'kiss/routes/redux'
import { getPageColors, getProject } from '../../../page-state/selectors'
import Identity from '../../components/identity'
import OwnerPanel from '../../components/toolbars/owner-panel'
import Menu from './menu'
import Profile from './profile'
import Hero from '../../components/hero'
import ShareButton from '../../components/share-button'

const GlobalStyle = createGlobalStyle`
  .k-ProfileCard {
    max-width: ${pxToRem(300)};
  }
  .k-FloatingMenu {
    @media ${mq.mobileAndTablet} {
      margin-inline: ${pxToRem(-20)};
    }
  }
  .k-HeroLayout__page .k-HeroLayout__lastAside.k-HeroLayout__sticky--desktop {
    z-index: 3 !important;
  }
  .k-HeroLayout__firstAside.story-topAdjusted .k-HeroLayout__sticky__inside {
    top: ${pxToRem(HEADER_HEIGHT + 120)} !important;
  }
  .k-HeaderNav--isStuck + * {
    .k-HeroLayout__topMenu,
    .k-HeroLayout__topMenuBg {
      .k-HeroLayout__sticky__insideTop {
        top: ${pxToRem(HEADER_HEIGHT)} !important;
      }
    }
    @media ${mq.mobileAndTablet} {
      .k-HeroLayout__topMenu,
      .k-HeroLayout__topMenuBg,
      .k-HeroLayout__firstAside {
        &.k-HeroLayout__sticky--both {
          top: ${pxToRem(HEADER_HEIGHT)} !important;
        }
      }
    }
  }
`
const SubscriberLayout = ({ children }) => {
  const t = useTranslation()
  const pageColors = useSelector(getPageColors)
  const project = useSelector(getProject)
  const routeFor = useSelector(getRouteFor)
  const { viewportIsMOrLess } = useMediaQuery()

  const isAboutPage = useRouteMatch(SUBSCRIBER_PROJECT_ABOUT_ROUTE)

  const userId = project?.owner
    ? project?.owner?.slug
    : project?.organization?.contact?.slug

  return (
    <DefaultLayout>
      <GlobalStyle />
      <OwnerPanel />
      <HeroLayout style={pageColors}>
        {viewportIsMOrLess && (
          <>
            <Hero>
              <FlexWrapper
                gap={10}
                className="k-u-flex-direction-row@l-up k-u-flex-direction-column k-u-flex-alignItems-center"
              >
                <FlexWrapper direction="row" gap={10}>
                  <ShareButton />
                  <Button
                    tag="a"
                    href={routeFor(USER_MESSAGE_NEW, {
                      user: userId,
                    })}
                    fit="content"
                    size="small"
                    modifier="helium"
                    rounded
                    className="k-u-hidden@l-up"
                  >
                    <EnvelopeIconNext />
                    <span>{t('permanent_project.actions.contact')}</span>
                  </Button>
                </FlexWrapper>
              </FlexWrapper>
            </Hero>
            <HeroLayout.Main>
              <HeroLayout.Main.FirstAside sticky="both">
                <Menu />
              </HeroLayout.Main.FirstAside>
              {isAboutPage && <Identity />}
              {children}
            </HeroLayout.Main>
          </>
        )}

        {!viewportIsMOrLess && (
          <HeroLayout.Main hasTopMenu>
            <HeroLayout.Main.Image>
              <img src={project?.bannerImage?.url} alt="" />
            </HeroLayout.Main.Image>
            <HeroLayout.Main.FirstAside sticky="both">
              <Profile />
            </HeroLayout.Main.FirstAside>
            <HeroLayout.Main.TopMenu>
              <Menu />
            </HeroLayout.Main.TopMenu>
            {isAboutPage && <Identity />}
            {children}
          </HeroLayout.Main>
        )}
      </HeroLayout>
    </DefaultLayout>
  )
}

export default SubscriberLayout
