import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import PropTypes from 'prop-types'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'

import {
  CheckedIcon,
  Comment as KittenComment,
  IconBadge,
  Text,
} from '@kisskissbankbank/kitten'
import { getDefaultAvatar } from 'kiss/utils/default-avatar'
import { imageDensityAttributes } from 'kiss/utils/responsive-image/image-density-attributes'
import { getProjectSlug, isLogged } from '../../page-state/selectors'
import { CloseReplyFormsContext } from 'kiss/modules/project-page/common/contexts/CloseReplyForms'
import {
  likeNewsComment,
  unlikeNewsComment,
} from 'kiss/modules/project-page/campaign/news/redux'
import ActionLinks from '../../campaign/news/components/action-links'
import CommentForm from 'kiss/modules/project-page/common/news/CommentForm'
import Replies from 'kiss/modules/project-page/common/news/Replies'

const Comment = ({
  uuid,
  commenter: commenterDefault,
  text: textDefault,
  publicationAt,
  currentUserHasLiked,
  likesCount,
  parentCommentId,
  replies,
  replyOnClick,
  isSecondary,
  className,
  newsId,
}) => {
  const [loading, setLoading] = useState(false)
  const [replyFormShowed, setShowReplyForm] = useState(false)
  const [isEditingComment, setIsEditingComment] = useState(false)

  const t = useTranslation()
  const slug = useSelector(getProjectSlug)
  const isCurrentUserLoggedIn = useSelector(isLogged)

  const dispatch = useDispatch()

  const { idFormOpen, setIdFormOpen, shouldCloseForms, setShouldCloseForms } =
    useContext(CloseReplyFormsContext)

  useEffect(() => {
    if (!replyFormShowed) return

    document.getElementById(`reply-form-${uuid}`)?.focus()
  }, [replyFormShowed])

  const likeOrUnlike = async () => {
    setLoading(true)

    await (currentUserHasLiked
      ? dispatch(unlikeNewsComment({ commentId: uuid, slug }))
      : dispatch(likeNewsComment({ commentId: uuid, slug })))

    setLoading(false)
    document.activeElement.blur()
  }

  const handleReplyOnClick = () => {
    if (!replyFormShowed && !idFormOpen) {
      setIdFormOpen(uuid)
    }

    if (!replyFormShowed && idFormOpen) {
      setIdFormOpen(uuid)
      setShouldCloseForms(true)
    }

    if (replies) return setShowReplyForm(!replyFormShowed)

    replyOnClick()
  }

  useEffect(() => {
    if (shouldCloseForms && idFormOpen !== uuid) {
      setShowReplyForm(false)
    }
  }, [shouldCloseForms, idFormOpen])

  const defaultAvatar = getDefaultAvatar({ username: '', id: '0' })

  const commenter = commenterDefault || {
    username: null,
    slug: null,
    avatarImage: {
      url: defaultAvatar,
      normalUrl: defaultAvatar,
      thumbUrl: defaultAvatar,
    },
  }
  const text = textDefault || t('project.comments.deleted_comment')

  const duration = differenceInMilliseconds(Date.now(), new Date(publicationAt))

  const msPerDay = 24 * 60 * 60 * 1000
  const msPerHour = msPerDay / 24
  const msPerMinute = msPerHour / 60

  const durationPerDay = Math.floor(duration / msPerDay)
  const durationPerHour = Math.floor(duration / msPerHour)
  const durationPerMinute = Math.floor(duration / msPerMinute)

  let date

  if (durationPerDay > 0) {
    date = t('project_info.remaining_time.day', {
      count: durationPerDay,
    })
  } else if (durationPerHour > 0) {
    date = t('project_info.remaining_time.hour', {
      count: durationPerHour,
    })
  } else {
    date = t('project_info.remaining_time.minute', {
      count: durationPerMinute,
    })
  }

  const formattedText = text.split('\n\n').map((fragment) => {
    return (
      <Text
        key={`${uuid}-${fragment}`}
        tag="p"
        weight="400"
        color="font1"
        size="small"
        lineHeight="normal"
        className="k-u-margin-top-single"
      >
        {fragment.split('\n').map((el, index) => (
          <span
            className="k-u-block"
            key={`${uuid}-${fragment}-fragment-${index}`}
          >
            {el}
          </span>
        ))}
      </Text>
    )
  })
  const canLikeOrUnlike = isCurrentUserLoggedIn && !!commenter.slug

  if (isEditingComment) {
    return (
      <CommentForm
        commentUpdated={{ uuid, text }}
        commentButtonLabel={t('project.comments.button.edit')}
        id={`edit-comment-form-${uuid}`}
        onClose={() => setIsEditingComment(false)}
      />
    )
  }

  return (
    <div>
      <KittenComment
        id={`comment-${uuid}`}
        ownerName={
          commenter?.verifiedProfile ? (
            <div className="k-u-line-height-normal">
              {commenter.username}
              <IconBadge
                shape="star"
                status="info"
                size="nano"
                className="k-u-margin-left-single k-u-margin-right-noneHalf"
              >
                <CheckedIcon />
              </IconBadge>
            </div>
          ) : (
            commenter.username
          )
        }
        avatarImgProps={imageDensityAttributes(
          commenter?.avatarImage.normalUrl,
          {
            imageWidth: 80,
          },
        )}
        commentDate={date}
        footer={
          <ActionLinks
            uuid={uuid}
            ownerSlug={commenter.slug}
            currentUserHasLiked={currentUserHasLiked}
            likeOnClick={likeOrUnlike}
            replyOnClick={handleReplyOnClick}
            loading={loading}
            newsId={newsId}
            parentCommentId={parentCommentId}
            onClickEdit={() => setIsEditingComment(true)}
          />
        }
        headerActions={
          <KittenComment.LikeButton
            hasLiked={currentUserHasLiked}
            as={canLikeOrUnlike ? 'button' : 'span'}
            onClick={canLikeOrUnlike ? likeOrUnlike : null}
            disabled={loading || !canLikeOrUnlike}
            accessibilityLabel={t('project.comments.like_button.label', {
              count: likesCount,
            })}
          >{`${likesCount}`}</KittenComment.LikeButton>
        }
        isSecondary={isSecondary}
        className={className}
      >
        {formattedText}
      </KittenComment>

      {replies && (
        <Replies
          replies={replies}
          ownerSlug={commenter.slug}
          commentUuid={uuid}
          isShowingForm={replyFormShowed}
          hideForm={() => setShowReplyForm(false)}
          replyOnClick={handleReplyOnClick}
          newsId={newsId}
          parentCommentId={parentCommentId}
          closeForm={() => setShowReplyForm(false)}
        />
      )}
    </div>
  )
}

Comment.propTypes = {
  replyOnClick: PropTypes.func,
  isSecondary: PropTypes.bool,
}

Comment.defaultProps = {
  replyOnClick: () => {},
  isSecondary: false,
}

export default Comment
