import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMedia } from './media-query-hook'
import { updateMediaQueries } from './redux'
import { usePrevious } from 'kiss/utils/use-previous'

const ColorSchemeMediaQuery = () => {
  const dispatch = useDispatch()

  const darkModeMediaQuery = '(prefers-color-scheme:dark)'

  const prefersDarkMode = useMedia({
    queries: [darkModeMediaQuery],
    values: [true],
    defaultValue: false,
  })

  const previousPreference = usePrevious(prefersDarkMode)

  useEffect(() => {
    if (prefersDarkMode === previousPreference) return null

    dispatch(
      updateMediaQueries({
        prefersDarkMode,
        prefersLightMode: !prefersDarkMode,
      }),
    )
  }, [prefersDarkMode])

  return null
}

export default ColorSchemeMediaQuery
