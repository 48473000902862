import {
  FacebookButtonIcon,
  FacebookButtonIconWords,
} from '@kisskissbankbank/kitten'
import SocialClickHandler from 'kiss/components/social/social-click-handler'
import { useTranslation } from 'kiss/hooks/use-translation'
import PropTypes from 'prop-types'
import React from 'react'

const FacebookButton = ({ style, children, sharedUrl }) => {
  const t = useTranslation()
  const buildFacebookUrl = () => {
    const encodedUrl = encodeURIComponent(sharedUrl)
    return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`
  }

  return (
    <SocialClickHandler>
      {(handleClick) => {
        return children ? (
          <FacebookButtonIconWords
            size="large"
            onClick={() => handleClick('Facebook', buildFacebookUrl())}
          >
            {children}
          </FacebookButtonIconWords>
        ) : (
          <FacebookButtonIcon
            style={style}
            onClick={() => handleClick('Facebook', buildFacebookUrl())}
            aria-label={t('app.sharing.share_on_facebook')}
          />
        )
      }}
    </SocialClickHandler>
  )
}

FacebookButton.propTypes = {
  sharedUrl: PropTypes.string.isRequired,
  style: PropTypes.object,
}

FacebookButton.defaultProps = {
  style: {},
}

export default FacebookButton
