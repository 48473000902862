import React from 'react'
import PropTypes from 'prop-types'
import Shipping from '../shipping'
import CommonFormContent from 'kiss/modules/components/reward-modal/desktop/form-content'

const FormContent = ({ reward, children }) => {
  return (
    <CommonFormContent reward={reward}>
      <Shipping reward={reward} />
      {children}
    </CommonFormContent>
  )
}

FormContent.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default FormContent
