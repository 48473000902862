import { domElementHelper } from '@kisskissbankbank/kitten'
import { waitFor } from 'kiss/utils/wait-for'
import React, { createContext, useEffect, useState } from 'react'

export const FacebookPixelContext = createContext(() => null)

const FacebookPixelProviderInner = ({ children }) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (domElementHelper.canUseDom()) {
      waitFor('dataLayer', () => {
        setLoading(false)
      })
    }
  }, [])
  return (
    <FacebookPixelContext.Provider
      value={{
        dataLayer: domElementHelper.canUseDom() && window.dataLayer,
        loading,
      }}
    >
      {children}
    </FacebookPixelContext.Provider>
  )
}

const FacebookPixelProvider = ({ children }) => {
  if (APP_ENV !== 'production') return children
  return <FacebookPixelProviderInner>{children}</FacebookPixelProviderInner>
}

export default FacebookPixelProvider
