import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import {
  Button,
  Paragraph,
  parseHtml,
  pxToRem,
  Text,
  Title,
} from '@kisskissbankbank/kitten'

const StyledIllustration = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: block;
  background-color: var(--color-primary-100);
  &.k-Card__image--small {
    padding: ${pxToRem(15)};
  }
`

const CardWithButton = ({
  titleProps,
  title,
  paragraph,
  notice,
  buttonProps,
  illustration,
  smallerImage,
}) => {
  return (
    <>
      <div className="k-Card__imageContainer k-u-margin-bottom-double">
        <StyledIllustration
          className={`k-Card__image ${smallerImage && 'k-Card__image--small'}`}
        >
          {illustration}
        </StyledIllustration>
      </div>
      <div className="k-u-margin-top-double k-u-margin-bottom-single">
        <Title
          {...titleProps}
          tag="h3"
          noMargin
          modifier="senary"
          cssColor="var(--color-grey-900)"
        >
          {title}
        </Title>
      </div>
      <div
        className={classNames(
          'k-u-margin-top-single',
          'k-u-margin-bottom-triple',
          'k-CardWithButton__paragraph',
        )}
      >
        <Paragraph modifier="tertiary" noMargin>
          {parseHtml(paragraph)}
        </Paragraph>
      </div>

      <div
        className={classNames(
          'k-u-margin-top-singleHalf',
          'k-u-margin-bottom-quadruple',
          { 'k-CardWithButton__notice': notice },
        )}
      >
        <Paragraph className="k-CardWithButton__notice--paragraph noMargin">
          <Text size="micro" weight="500" color="font1">
            {parseHtml(notice)}
          </Text>
        </Paragraph>
      </div>

      {buttonProps && <Button {...buttonProps} />}
    </>
  )
}

export default CardWithButton
