import React from 'react'
import CampaignSeachResultCard from './campaign'
import SubscriptionSeachResultCard from './subscription'

const SeachResultCard = ({ project, hideOnMobile }) => {
  return project.permanent ? (
    <SubscriptionSeachResultCard
      project={project}
      hideOnMobile={hideOnMobile}
    />
  ) : (
    <CampaignSeachResultCard project={project} hideOnMobile={hideOnMobile} />
  )
}

export default SeachResultCard
