import { Button, Text } from '@kisskissbankbank/kitten'
import { Form as FormikForm, Formik } from 'formik'
import { addErrorAlert, removeErrorAlerts } from 'kiss/app/alerts/redux'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import ScrollOnError from 'kiss/components/formik/scroll-on-error'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor } from 'kiss/routes/redux'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useContributeStepper from '../hooks/use-stepper'
import { getProjectSlug } from '../redux'
import {
  getBillingAddress,
  updateBillingAddress,
} from '../shipping-address/redux'
import Form from './components/form'

export const MAX_LENGTH_ADDRESS = 38

const BillingAddress = () => {
  const t = useTranslation()
  const billingAddress = useSelector(getBillingAddress)
  const history = useHistory()
  const dispatch = useDispatch()
  const projectSlug = useSelector(getProjectSlug)
  const routeFor = useSelector(getRouteFor)
  const { nextRoute } = useContributeStepper()

  return (
    <Formik
      initialValues={{
        billingAddress: {
          firstName: billingAddress?.firstName ?? '',
          lastName: billingAddress?.lastName ?? '',
          companyName: billingAddress?.companyName ?? '',
          phone: billingAddress?.phone ?? '',
          address1: billingAddress?.address1 ?? '',
          additional2: billingAddress?.address2 ?? '',
          additional3: billingAddress?.address3 ?? '',
          additional4: billingAddress?.address4 ?? '',
          postalCode: billingAddress?.postalCode ?? '',
          city: billingAddress?.city ?? '',
          countryCode: billingAddress?.countryCode ?? '',
        },
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          dispatch(removeErrorAlerts())
          await dispatch(updateBillingAddress({ values, sameAddress: false }))
          history.push(routeFor(nextRoute, { project: projectSlug }))
        } catch (e) {
          setSubmitting(false)
          dispatch(
            addErrorAlert(t('contribute.shipping_address.form.alert.error')),
          )
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <FormikForm>
            <ScrollOnError
              message={t('contribute.shipping_address.form.alert.error')}
            />
            <Text
              tag="h1"
              size="large"
              weight="600"
              className="k-u-margin-bottom-double"
            >
              {t('contribute.billing_address.title')}
            </Text>

            <Form />

            <div className="k-u-margin-bottom-double">
              {isSubmitting ? (
                <SubmitLoader fit="fluid" />
              ) : (
                <Button
                  type="submit"
                  modifier="helium"
                  data-test-id="shipping-next-button"
                  fit="fluid"
                  size="large"
                >
                  {t('contribute.shipping_address.button.submit')}
                </Button>
              )}
            </div>
          </FormikForm>
        )
      }}
    </Formik>
  )
}

export default BillingAddress
