import {
  HorizontalProjectCard,
  PeopleIconNext,
  Text,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, PROJECT_ROUTE } from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'

const SubscriptionSeachResultCard = ({ project, hideOnMobile }) => {
  const { name, avatar_url, slug, subscription_orders } = project
  const routeFor = useSelector(getRouteFor)
  const t = useTranslation()
  return (
    <HorizontalProjectCard
      title={name}
      noProgress
      description={name}
      imageProps={{
        src: avatar_url,
      }}
      info={
        subscription_orders && (
          <div className="k-u-flex k-u-flex-alignItems-center">
            <PeopleIconNext
              aria-hidden
              width="12"
              height="12"
              color="var(--color-primary-500)"
              className="k-u-margin-right-noneHalf"
            />

            <Text size="micro" weight="500">
              {t('search.project.subscribers', { count: subscription_orders })}
            </Text>
          </div>
        )
      }
      href={routeFor(PROJECT_ROUTE, {
        project: slug,
      })}
      className={classNames({
        'k-u-hidden@s-down': hideOnMobile,
      })}
    />
  )
}

export default SubscriptionSeachResultCard
