import { HEADER_HEIGHT } from '@kisskissbankbank/kitten'
import React, { createContext, useReducer } from 'react'
import { v4 as randomUuid } from 'uuid'
import { scrollTo } from '../../utils/animation/scroll-to'
import { ALERTS_ID } from './redux'

const applySroll = () => {
  scrollTo(document.getElementById(ALERTS_ID), 500, {
    offset: -1 * HEADER_HEIGHT,
  })
}

const initialState = {
  alerts: [],
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { alerts: [...state.alerts, action.payload] }
    case 'removeAll':
      return initialState
    case 'removeErrorAlerts':
      return { alerts: state.alerts.filter((item) => item.type !== 'danger') }
    case 'removeAlert':
      return {
        alerts: state.alerts.filter(
          (item) => item.uuid !== action.payload.alertUuid,
        ),
      }
  }
}

const removeAllAlerts = () => ({
  type: 'removeAll',
})

const addSuccessAlert = (message, options) => {
  if (options.scroll) {
    applySroll()
  }
  return {
    type: 'update',
    payload: {
      uuid: randomUuid(),
      type: 'success',
      message,
    },
  }
}

const addErrorAlert = (message, options) => {
  if (options.scroll) {
    applySroll()
  }
  return {
    type: 'update',
    payload: {
      uuid: randomUuid(),
      type: 'danger',
      message,
    },
  }
}

const removeErrorAlerts = () => ({ type: 'removeErrorAlerts' })

const removeAlert = (alertUuid) => ({
  type: 'removeAlert',
  payload: { alertUuid },
})

export const AlertsContext = createContext({ alerts: [] })

export const AlertsProvider = ({ children }) => {
  const [{ alerts }, dispatch] = useReducer(reducer, initialState)
  return (
    <AlertsContext.Provider
      value={{
        alerts,
        removeAllAlerts: () => dispatch(removeAllAlerts()),
        removeErrorAlerts: () => dispatch(removeErrorAlerts()),
        removeAlert: (alertUuid) => dispatch(removeAlert(alertUuid)),
        addSuccessAlert: (message, options) =>
          dispatch(addSuccessAlert(message, options)),
        addErrorAlert: (message, options) =>
          dispatch(addErrorAlert(message, options)),
      }}
    >
      {children}
    </AlertsContext.Provider>
  )
}
