import React from 'react'

import PropTypes from 'prop-types'

import ProjectCard from 'kiss/components/cards/project-card'
import {
  Carousel,
  CONTAINER_PADDING_THIN,
  CONTAINER_PADDING,
  GUTTER,
  pxToRem,
  ScreenConfig,
} from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 0 ${pxToRem(CONTAINER_PADDING_THIN)};

  @media (min-width: ${ScreenConfig.L.min}px) {
    padding-left: ${pxToRem(GUTTER)};
  }
`

const RichContentProjectCarousel = ({ projects }) => {
  return (
    <StyledContainer>
      <Carousel
        itemMinWidth={280}
        hidePaginationOnMobile
        baseItemMarginBetween={CONTAINER_PADDING}
        paginationPosition={{ default: 'bottom' }}
        shadowSize={30}
      >
        {projects
          .filter((project) => !!project)
          .map((project) => {
            return (
              <ProjectCard key={project.id} loading={false} project={project} />
            )
          })}
      </Carousel>
    </StyledContainer>
  )
}

RichContentProjectCarousel.propTypes = {
  projects: PropTypes.array.isRequired,
}

export default RichContentProjectCarousel
