import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Text,
  Title,
  Container,
  mq,
  pxToRem,
  CarouselNext,
  parseHtml,
} from '@kisskissbankbank/kitten'
import { useMediaQuery } from 'kiss/media-queries/redux'

import keyBy from 'lodash/fp/keyBy'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import { getNewsSlices } from '../redux'
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import flatten from 'lodash/fp/flatten'

const ChallengesWrapper = styled.section`
  background-color: var(--color-grey-100);
  margin-top: ${pxToRem(-50)};
  padding: ${pxToRem(110)} 0 ${pxToRem(90)};

  @media ${mq.mobile} {
    padding: ${pxToRem(50)} 0 ${pxToRem(50)};
  }

  .k-Title {
    @media ${mq.mobile} {
      margin: 0 ${pxToRem(20)};
    }

    max-width: ${pxToRem(790)};
    margin: 0 auto ${pxToRem(50)};
    text-align: center;
  }

  .k-CarouselNext--showOtherPages .k-CarouselNext__inner {
    --container-padding: ${pxToRem(50 - 4)};

    @media ${mq.tabletAndDesktop} {
      --container-padding: 15vw;
    }
  }

  .k-CarouselNext__page {
    height: 100% !important;
  }

  .kiss-Homepage__challenges__carouselHead {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: ${pxToRem(30)};
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    max-width: ${pxToRem(1440)};
  }

  .kiss-Homepage__challenges__challengeCard__item {
    display: flex;
    box-sizing: border-box;
    background-color: var(--color-grey-000);
    box-shadow: var(--box-shadow-m);
    border-radius: var(--border-radius-m);
    padding: ${pxToRem(20)};
    flex-direction: column;
    gap: ${pxToRem(20)};
    transition: box-shadow var(--transition);
    text-decoration: none;
    color: var(--color-grey-900);
    overflow: hidden;
    height: 100%;

    &:hover,
    &:focus {
      box-shadow: var(--box-shadow-hover-m);
    }

    img {
      aspect-ratio: 2 / 1;
      object-fit: cover;
      object-position: center;
      margin: ${pxToRem(-20)} ${pxToRem(-20)} 0;
    }
  }
`

const ChallengeCard = ({ article = {} }) => {
  const responsiveImages = keyBy('name')(article.image.views)

  return (
    <a
      key={`ChallengeCard${article.item}`}
      href={article.redirectionUrl}
      className="kiss-Homepage__challenges__challengeCard__item"
    >
      <img
        src={responsiveImages?.landscape?.url || article.image.url}
        alt="Image alt"
      />
      <Text size="large" weight="500" lineHeight="normal">
        {parseHtml(article.title, { sanitize: false })}
      </Text>
      <Text size="small" lineHeight="normal">
        {parseHtml(article.content, { sanitize: false })}
      </Text>
    </a>
  )
}

const Challenges = () => {
  const t = useTranslation()
  const { viewportIsSOrLess, viewportIsMOrLess } = useMediaQuery()
  const [navProps, setNavProps] = useState({})

  const newsSlices = useSelector(getNewsSlices)

  const articles = flow(
    map((newsSlice) => newsSlice.articles),
    flatten,
  )(newsSlices)

  return (
    <ChallengesWrapper>
      <Title
        tag="h2"
        modifier="tertiary"
        family="antiqueolive"
        className="kiss-Homepage__challenges__title k-u-margin-bottom-single@s-down"
      >
        {t('new_home.challenges.title')}
      </Title>
      <Container className="kiss-Homepage__challenges__carouselHead k-u-hidden@m-down">
        {articles.map((article, index) => (
          <ChallengeCard article={article} key={index} />
        ))}
      </Container>

      <Container>
        <CarouselNext.Navigation
          {...navProps}
          buttonProps={{ size: 'small' }}
          className="k-u-hidden@l-up k-u-flex-justifyContent-end k-u-margin-bottom-double"
        />
      </Container>

      <CarouselNext
        baseGap={30}
        cycle
        itemsPerPage={viewportIsSOrLess ? 1 : 3}
        itemMinWidth={viewportIsSOrLess ? 200 : 300}
        viewportIsXSOrLess={viewportIsSOrLess}
        viewportIsMOrLess={viewportIsMOrLess}
        navigationPropsGetter={setNavProps}
        showOtherPages={viewportIsMOrLess}
        shadowSize={viewportIsSOrLess ? 15 : 30}
        className="k-u-hidden@l-up"
      >
        {articles.map((article, index) => (
          <ChallengeCard article={article} key={index} />
        ))}
      </CarouselNext>
    </ChallengesWrapper>
  )
}

export default Challenges
