import { Button, DownloadIcon } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const DownloadFiscal = ({ order }) => {
  const t = useTranslation()
  const receipts = order?.fiscalReceiptsConnection?.edges
  const receipt = receipts[receipts.length - 1]?.node

  const buttonProps = {
    tag: 'a',
    target: '_blank',
    rel: 'noopener',
    modifier: 'helium',
    iconPosition: 'left',
  }

  return (
    <>
      {receipt && (
        <div className="k-u-align-center k-u-margin-bottom-triple">
          <Button href={receipt?.url} {...buttonProps}>
            <DownloadIcon />
            {t('contributor.orders.donation_receipt.download')}
          </Button>
        </div>
      )}
    </>
  )
}

export default DownloadFiscal
