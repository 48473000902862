import React, { createContext, useState } from 'react'
import { useParams } from 'react-router-dom'

export const CommentsContext = createContext({})

export const CommentsProvider = ({ children }) => {
  const { id: sharedNewsId } = useParams()

  const [newsCommentsToShow, setNewsCommentsToShow] = useState(sharedNewsId)

  return (
    <CommentsContext.Provider
      value={{
        newsCommentsToShow,
        setNewsCommentsToShow,
      }}
    >
      {children}
    </CommentsContext.Provider>
  )
}
