import React from 'react'
import {
  ArrowContainer,
  Modal,
  pxToRem,
  Text,
  Button,
  Paragraph,
} from '@kisskissbankbank/kitten'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getOrganizationOrOwnerName, getTotalCurrency } from '../../cart/redux'

const CurrencyIconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  border-radius: var(--border-radius-rounded);
  background-color: var(--color-primary-500);
  margin-right: ${pxToRem(15)};
`

const StyledText = styled(Text)`
  align-self: center;
  line-height: 1.2;
`

const DefiscModal = ({ onClose }) => {
  const currency = useSelector(getTotalCurrency)
  const representativeName = useSelector(getOrganizationOrOwnerName)
  const t = useTranslation()
  return (
    <ArrowContainer
      position="top"
      color="var(--color-primary-100)"
      className="k-u-flex"
    >
      <CurrencyIconCircle>
        <Text color="background1" weight="500">
          {t(`currency_symbol.${currency}`)}
        </Text>
      </CurrencyIconCircle>

      <StyledText tag="p" weight="400" color="font1" size="micro">
        {t('defisc.banner.shipping.text')}
        <Modal
          closeButtonLabel={t('defisc.modal.button_close')}
          trigger={
            <Text
              tag="button"
              type="button"
              weight="500"
              color="primary1"
              className="k-u-link k-u-link-primary1 k-u-cursor-pointer"
            >
              {t('defisc.banner.text_link')}
            </Text>
          }
          onClose={onClose}
        >
          {({ close }) => {
            return (
              <Modal.Content align="left">
                <Paragraph modifier="secondary">
                  {t('defisc.modal.content', {
                    owner: representativeName,
                    parseHtml: true,
                  })}
                </Paragraph>
                <Modal.Actions>
                  <Button modifier="helium" onClick={close}>
                    {t('defisc.modal.button')}
                  </Button>
                </Modal.Actions>
              </Modal.Content>
            )
          }}
        </Modal>
      </StyledText>
    </ArrowContainer>
  )
}

export default DefiscModal
