import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  hasDonation as hasDonationSelector,
  hasOrderLines as hasOrderLinesSelector,
} from '../../../cart/redux'
import { getProjectSlug } from 'kiss/modules/contribute/redux'
import { Text } from '@kisskissbankbank/kitten'
import {
  getRouteFor as getRouteForSelector,
  PROJECT_ROUTE,
} from 'kiss/routes/redux'
import { NAVIGATION_ID } from 'kiss/modules/project-page/page-state/redux'

const AddReward = () => {
  const t = useTranslation()
  const hasOrderLines = useSelector(hasOrderLinesSelector)
  const hasDonation = useSelector(hasDonationSelector)
  const projectSlug = useSelector(getProjectSlug)
  const getRouteFor = useSelector(getRouteForSelector)

  if (!hasOrderLines && !hasDonation) return null

  const projectUrl = `${getRouteFor(PROJECT_ROUTE, {
    project: projectSlug,
  })}#${NAVIGATION_ID}`

  return (
    <Text
      tag="a"
      href={projectUrl}
      size="small"
      weight="600"
      className="k-u-link k-u-link-primary1 k-u-cursor-pointer k-u-margin-top-double k-u-block"
    >
      {`+ ${t('cart.rewards.add_reward')}`}
    </Text>
  )
}

export default AddReward
