import { CommentForm } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getCurrentUserImage,
  isLogged as isLoggedSelector,
} from 'kiss/session/redux'
import { imageDensityAttributes } from 'kiss/utils/responsive-image/image-density-attributes'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  currentUserCanComment as currentUserCanCommentSelector,
  postComment,
  updateComment,
} from '../redux'

const Form = ({
  id,
  commentButtonLabel,
  commentUuid,
  className,
  commentUpdated,
  onSubmit,
  onClose,
}) => {
  const [isLoading, setLoading] = useState(false)
  const [formKey, setFormKey] = useState('')
  const t = useTranslation()
  const isLogged = useSelector(isLoggedSelector)
  const currentUserImage = useSelector(getCurrentUserImage)
  const currentUserCanComment = useSelector(currentUserCanCommentSelector)
  const dispatch = useDispatch()

  if (!isLogged) return null
  if (!currentUserCanComment) return null

  const handleSubmit = (text) => {
    setLoading(true)
    // Reset form
    setFormKey(new Date().getTime())

    if (commentUpdated) {
      dispatch(
        updateComment({
          commentUuid: commentUpdated.uuid,
          parentCommentId: commentUuid,
          text,
        }),
      )
      setLoading(false)
    } else {
      dispatch(postComment({ commentUuid, text }))
      setLoading(false)
    }
    onSubmit()
  }

  const avatarImgProps = imageDensityAttributes(currentUserImage.normalUrl, {
    imageWidth: 80,
  })

  return (
    <>
      <CommentForm
        key={formKey}
        className={className}
        id={id}
        buttonText={commentButtonLabel || t('project_comment_form.button')}
        aria-label={
          commentUuid
            ? t('project.comments.news.form.reply.placeholder')
            : t('project_comment_form.placeholder')
        }
        placeholder={
          commentUuid
            ? t('project.comments.news.form.reply.placeholder')
            : t('project_comment_form.placeholder')
        }
        onSubmit={handleSubmit}
        avatarImgProps={avatarImgProps}
        disabled={isLoading}
        defaultValue={commentUpdated?.text}
        onClose={onClose}
        closeButtonText={t('project.comments.news.form.close_button.label')}
      />
    </>
  )
}

Form.propTypes = {
  id: PropTypes.string,
  commentButtonLabel: PropTypes.string,
  commentUuid: PropTypes.string,
  onSubmit: PropTypes.func,
}

Form.defaultProps = {
  id: null,
  commentButtonLabel: null,
  commentUuid: null,
  onSubmit: () => {},
}

export default Form
