import { Button, EnvelopeIcon, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import useMessaging from 'kiss/hooks/use-messaging'
import { getProject } from 'kiss/modules/project-page/page-state/selectors'
import {
  getRouteFor,
  MESSAGING_ROOM,
  USER_MESSAGE_NEW,
} from 'kiss/routes/redux'
import { isCurrentUserAdmin } from 'kiss/session/redux'
import React from 'react'
import { useSelector } from 'react-redux'

const Contact = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const project = useSelector(getProject)
  const isAdmin = useSelector(isCurrentUserAdmin)

  const userId = project?.owner
    ? project?.owner?.slug
    : project?.organization?.contact?.slug

  const userChatUsername = project?.owner
    ? project?.owner?.chatUsername
    : project?.organization?.contact?.chatUsername

  const { canContact, loading } = useMessaging({
    user: { chatUsername: userChatUsername },
  })

  const messageUrl = isAdmin
    ? routeFor(MESSAGING_ROOM, {
        room: userChatUsername,
      })
    : routeFor(USER_MESSAGE_NEW, {
        user: userId,
      })

  if (loading || (isAdmin && !canContact)) return null

  return (
    <div>
      <Text
        tag="h3"
        size="micro"
        transform="uppercase"
        className="kiss-AsideTitle k-u-margin-top-none k-u-margin-bottom-noneHalf k-u-letter-spacing-10"
      >
        {t('permanent_project.social.contact.title')}
      </Text>
      <div className="k-u-margin-top-single">
        <Button tag="a" href={messageUrl} fit="content" rounded size="small">
          <EnvelopeIcon />
          <span>{t('permanent_project.social.contact.button')}</span>
        </Button>
      </div>
    </div>
  )
}

export default Contact
