import React from 'react'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import { gql } from '@apollo/client'
import {
  AlertBox,
  Grid,
  GridCol,
  Modal,
  Text,
  Button,
} from '@kisskissbankbank/kitten'
import CompanyName from './company-name'
import AddressBis from './address-bis'
import Label from 'kiss/components/formik/label'
import Phone from 'kiss/components/formik/phone'
import SimpleText from 'kiss/components/formik/simple-text'
import SelectCountries from 'kiss/components/formik/select-countries'
import { useMutation } from 'kiss/api/graphql-query'
import { useTranslation } from 'kiss/hooks/use-translation'

export const MAX_LENGTH_ADDRESS = 38

const UPDATE_ADDRESS_MUTATION = gql`
  mutation updateAddress($id: ID, $address: AddressInput!) {
    updateAddress(id: $id, address: $address) {
      id
      firstName
      lastName
      companyName
      phone
      address1
      address2
      address3
      address4
      postalCode
      city
      countryCode
    }
  }
`

const ShippingAddressModal = ({ address, orderAddressId = null, refetch }) => {
  const t = useTranslation()
  const [updateAddress] = useMutation(UPDATE_ADDRESS_MUTATION)

  return (
    <>
      <Modal
        size="medium"
        trigger={
          <Text
            size="small"
            weight="500"
            tag="button"
            type="button"
            className="k-u-reset-button k-u-cursor-pointer k-u-link k-u-link-primary1 k-u-margin-top-single"
          >
            {t('contributor.orders.delivery.edit')}
          </Text>
        }
      >
        {({ close }) => {
          return (
            <>
              <Modal.Title>
                {t('contributor.orders.delivery.modal.title')}
              </Modal.Title>
              <Modal.Form>
                <Formik
                  initialValues={{
                    firstName: address?.firstName ?? '',
                    lastName: address?.lastName ?? '',
                    companyName: address?.companyName ?? '',
                    phone: address?.phone ?? '',
                    address1: address?.address1 ?? '',
                    address2: address?.address2 ?? '',
                    address3: address?.address3 ?? '',
                    address4: address?.address4 ?? '',
                    postalCode: address?.postalCode ?? '',
                    city: address?.city ?? '',
                    countryCode: address?.countryCode ?? 'FR',
                  }}
                  validationSchema={object().shape({
                    firstName: string().required(
                      t('contribute.shipping_address.form.firstname.error'),
                    ),
                    lastName: string().required(
                      t('contribute.shipping_address.form.lastname.error'),
                    ),
                    phone: string().required(
                      t('contribute.shipping_address.form.phone.error'),
                    ),
                    address1: string().required(
                      t('contribute.shipping_address.form.address.error'),
                    ),
                    postalCode: string().required(
                      t('contribute.shipping_address.form.postal_code.error'),
                    ),
                    city: string().required(
                      t('contribute.shipping_address.form.city.error'),
                    ),
                    countryCode: string().required(
                      t('contribute.shipping_address.form.country.error'),
                    ),
                  })}
                  onSubmit={async (values) => {
                    await updateAddress({
                      variables: {
                        id: orderAddressId || address.id,
                        address: values,
                      },
                    })

                    await refetch()
                    close()
                  }}
                >
                  {({ values, isSubmitting }) => {
                    return (
                      <Form className="k-u-margin-top-triple">
                        <Grid>
                          <GridCol col="6">
                            <Label htmlFor="firstName">
                              {t(
                                'contribute.shipping_address.form.firstname.label',
                              )}
                            </Label>

                            <SimpleText
                              name="firstName"
                              autocomplete="given-name"
                              placeholder={t(
                                'contribute.shipping_address.form.firstname.placeholder',
                              )}
                            />
                          </GridCol>
                          <GridCol col="6">
                            <Label htmlFor="lastName">
                              {t(
                                'contribute.shipping_address.form.lastname.label',
                              )}
                            </Label>

                            <SimpleText
                              name="lastName"
                              autocomplete="family-name"
                              placeholder={t(
                                'contribute.shipping_address.form.lastname.placeholder',
                              )}
                            />
                          </GridCol>
                        </Grid>

                        <CompanyName />
                        <div className="k-u-margin-bottom-triple">
                          <Label htmlFor="phone">
                            {t('contribute.shipping_address.form.phone.label')}
                          </Label>

                          <AlertBox
                            displayIcon={false}
                            fit="content"
                            size="small"
                          >
                            <Text weight="400" size="nano" id="phone-sublabel">
                              {t(
                                'contribute.shipping_address.form.phone.sublabel',
                              )}
                            </Text>
                          </AlertBox>

                          <Grid>
                            <GridCol col-s="12" col-l="6">
                              <Phone
                                name="phone"
                                aria-describedby="phone-sublabel"
                              />
                            </GridCol>
                          </Grid>
                        </div>
                        <Label htmlFor="address1">
                          {t('contribute.shipping_address.form.address.label')}
                        </Label>
                        <SimpleText
                          name="address1"
                          autocomplete="address-line1"
                          placeholder={t(
                            'contribute.shipping_address.form.address.placeholder',
                            { parseHtml: true },
                          )}
                          maxLength={MAX_LENGTH_ADDRESS}
                          validate={(value) => {
                            if (value?.length === MAX_LENGTH_ADDRESS) {
                              return t(
                                'contribute.shipping_address.error.max_length',
                              )
                            }
                          }}
                        />
                        <AddressBis />

                        <Grid>
                          <GridCol
                            col-s="12"
                            col-l="4"
                            className="k-u-margin-bottom-triple"
                          >
                            <Label htmlFor="postalCode">
                              {t(
                                'contribute.shipping_address.form.postal_code.label',
                              )}
                            </Label>

                            <SimpleText
                              name="postalCode"
                              inputMode="text"
                              autocomplete="postal-code"
                              placeholder={t(
                                'contribute.shipping_address.form.postal_code.placeholder',
                              )}
                              validate={(value) => {
                                if (
                                  values.countryCode === 'FR' &&
                                  value.length > 5
                                ) {
                                  return t(
                                    'contribute.shipping_address.form.postal_code.fr_error',
                                  )
                                }
                              }}
                            />
                          </GridCol>
                          <GridCol
                            col-s="12"
                            col-l="8"
                            className="k-u-margin-bottom-triple"
                          >
                            <Label htmlFor="city">
                              {t('contribute.shipping_address.form.city.label')}
                            </Label>

                            <SimpleText
                              name="city"
                              autocomplete="address-level2"
                              placeholder={t(
                                'contribute.shipping_address.form.city.placeholder',
                              )}
                            />
                          </GridCol>
                        </Grid>
                        <Label htmlFor="countryCode">
                          {t('contribute.shipping_address.form.country.label')}
                        </Label>
                        <Grid>
                          <GridCol col="6">
                            <SelectCountries name="countryCode" disabled />
                          </GridCol>
                        </Grid>

                        <Modal.Actions>
                          <Button
                            modifier="hydrogen"
                            onClick={close}
                            size="medium"
                            disabled={isSubmitting}
                          >
                            {t('app.submit.cancel')}
                          </Button>
                          <Button
                            modifier="helium"
                            size="medium"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            {t('app.submit.validate')}
                          </Button>
                        </Modal.Actions>
                      </Form>
                    )
                  }}
                </Formik>
              </Modal.Form>
            </>
          )
        }}
      </Modal>
    </>
  )
}

export default ShippingAddressModal
