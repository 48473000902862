import { Container, Grid, GridCol } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { useTranslation } from 'kiss/hooks/use-translation'
import SignIn from 'kiss/modules/contribute/auth/sign-in'
import SignUp from 'kiss/modules/contribute/auth/sign-up'
import { isPolling } from 'kiss/modules/contribute/redux'
import {
  BILLING_ADDRESS,
  CARD_PAYMENT_FAILURE,
  CART,
  CONTRIBUTE,
  CONTRIBUTE_LOGIN,
  CONTRIBUTE_SIGN_IN,
  CONTRIBUTE_SIGN_UP,
  IDENTITY_CHECK,
  PAYMENT_CHOICES,
  PAYMENT_CHOICES_RETURN,
  SHIPPING_ADDRESS,
  THANK_YOU,
} from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import Login from '../auth/login'
import BillingAddress from '../billing-address'
import CardPaymentFailure from '../card-failure'
import IdentityCheck from '../identity-check'
import Polling from '../polling'
import ShippingAddress from '../shipping-address'
import Cart from './cart'
import ProjectCartSummary from './components/summary/cart'
import FlowSummary from './components/summary/flow'
import PaymentChoices from './payment-choices'
import ThankYou from './thankyou'

const ContributionFlowContainer = ({ children, wideContent = false }) => {
  return (
    <Container>
      <Grid>
        <GridCol
          className={classNames('k-ContributionFlow__grid', {
            'k-ContributionFlow--wideContent': wideContent,
          })}
          col-xs="10"
          offset-xs="1"
          col-l="12"
          offset-l="0"
          col-xl="11"
          offset-xl="1"
        >
          <div className="k-ContributionFlow__ContentCol">{children}</div>
          <div className="k-ContributionFlow__SummaryCol">
            <Route path={[CONTRIBUTE]} exact>
              <div className="k-u-margin-top-nonuple@l-up">
                <ProjectCartSummary />
              </div>
            </Route>
            <Route
              path={[
                SHIPPING_ADDRESS,
                BILLING_ADDRESS,
                PAYMENT_CHOICES,
                CONTRIBUTE_LOGIN,
                CONTRIBUTE_SIGN_IN,
                CONTRIBUTE_SIGN_UP,
              ]}
            >
              <FlowSummary />
            </Route>
          </div>
        </GridCol>
      </Grid>
    </Container>
  )
}

const ProjectContributeFlow = ({ pageColors }) => {
  const t = useTranslation()
  const isPollingActive = useSelector(isPolling)

  return (
    <section style={pageColors}>
      <Switch>
        <Route exact path={CONTRIBUTE_LOGIN}>
          <Login />
        </Route>
        <Route exact path={CONTRIBUTE_SIGN_IN}>
          <SignIn />
        </Route>
        <Route exact path={CONTRIBUTE_SIGN_UP}>
          <SignUp title={t('contribute.authenticate.title.signup')} />
        </Route>
        <Route exact path={CART}>
          <ContributionFlowContainer wideContent>
            <Cart />
          </ContributionFlowContainer>
        </Route>
        <Route path={SHIPPING_ADDRESS}>
          <ContributionFlowContainer>
            <ShippingAddress />
          </ContributionFlowContainer>
        </Route>
        <Route path={BILLING_ADDRESS}>
          <ContributionFlowContainer>
            <BillingAddress />
          </ContributionFlowContainer>
        </Route>
        <Route path={IDENTITY_CHECK}>
          <ContributionFlowContainer>
            <IdentityCheck />
          </ContributionFlowContainer>
        </Route>
        <Route exact path={PAYMENT_CHOICES}>
          {isPollingActive ? (
            <Polling />
          ) : (
            <ContributionFlowContainer>
              <PaymentChoices />
            </ContributionFlowContainer>
          )}
        </Route>
        <Route exact path={PAYMENT_CHOICES_RETURN}>
          <Polling />
        </Route>
        <Route exact path={THANK_YOU}>
          <ThankYou />
        </Route>
        <Route exact path={CARD_PAYMENT_FAILURE}>
          <CardPaymentFailure />
        </Route>
      </Switch>
    </section>
  )
}

export default ProjectContributeFlow
