import React from 'react'
import { Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'

const Reassure = () => {
  const t = useTranslation()
  return (
    <>
      <Text
        size="small"
        weight="500"
        cssColor="var(--color-grey-700)"
        tag="p"
        className="k-u-margin-top-triple"
      >
        {t('cart.contribute.summary.reassure.cart.title')}
      </Text>
      <Text
        size="small"
        weight="400"
        cssColor="var(--color-grey-700)"
        tag="p"
        className="k-u-margin-top-single"
      >
        {t('cart.contribute.summary.reassure.cart.body')}
      </Text>
    </>
  )
}

export default Reassure
