import { gql } from '@apollo/client'
import { useMutation } from '../../api/graphql-query'
import { sentryCaptureException } from '../../app/sentry'

const REGISTER_CARD_MUTATION = gql`
  mutation RegisterCard($orderId: String, $cardType: String!) {
    registerCard(orderId: $orderId, cardType: $cardType) {
      accessKey
      registrationUrl
      preregistrationData
      uuid
    }
  }
`

const useCallRegisterCard = () => {
  const [registerCardMutation] = useMutation(REGISTER_CARD_MUTATION)

  return {
    callRegisterCard: async ({ cardType, orderId, userId, cartState }) => {
      if (!orderId) {
        sentryCaptureException(new Error(`missing orderId (${orderId})`), {
          tags: {
            type: 'payment_no-order-id',
          },
          user: {
            id: userId,
          },
          ...(cartState && {
            extra: {
              cart_state: cartState,
            },
          }),
        })
      }
      try {
        const { data } = await registerCardMutation({
          variables: { orderId, cardType },
        })
        return data.registerCard
      } catch (e) {
        throw Error('callRegisterCard failed')
      }
    },
  }
}

export default useCallRegisterCard
