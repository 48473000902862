import { Title } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { isOptionsEnabled } from 'kiss/modules/project-page/page-state/selectors'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import NewVariations from '../new-variations'
import Variations from '../variations'

const Choices = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const CODE_NONE = 'none'

const FormContent = ({ reward, children }) => {
  const t = useTranslation()
  const hasAtLeastOneDeliveryMode =
    reward?.deliveryModes?.length > 0 &&
    reward?.deliveryModes?.[0]?.code !== CODE_NONE

  const isUsingNewOptions = useSelector(isOptionsEnabled)
  const isUserCanChooseNextPeriod =
    reward?.nextPeriodSubscribable && reward?.currentPeriodSubscribable

  return (
    <Choices>
      {reward.hasVariationSelection && hasAtLeastOneDeliveryMode && (
        <Title tag="h3" modifier="senary" className="k-u-margin-bottom-triple">
          {t('project.modal.options.title_variations_and_delivery')}
        </Title>
      )}
      {reward.hasVariationSelection && !hasAtLeastOneDeliveryMode && (
        <Title tag="h3" modifier="senary" className="k-u-margin-bottom-triple">
          {t('project.modal.options.title')}
        </Title>
      )}
      {reward.hasVariationSelection &&
        (isUsingNewOptions ? (
          <NewVariations
            variations={reward.variations}
            variationsOptions={reward.variationsOptions}
          />
        ) : (
          <Variations
            variations={reward.variations}
            variationsOptions={reward.variationsOptions}
          />
        ))}
      {!reward.hasVariationSelection && hasAtLeastOneDeliveryMode && (
        <Title tag="h3" modifier="senary" className="k-u-margin-bottom-double">
          {isUserCanChooseNextPeriod
            ? t('project.modal.options.title_variations_and_delivery')
            : t('project.modal.shipping.method.label')}
        </Title>
      )}
      {children}
    </Choices>
  )
}

FormContent.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default FormContent
