import { useEffect, useState } from 'react'
import PropType from 'prop-types'
import { useFormikContext } from 'formik'
import { useDispatch } from 'react-redux'
import { addErrorAlert, removeErrorAlerts } from 'kiss/app/alerts/redux'
import isEmpty from 'lodash/fp/isEmpty'

const _ScrollOnError = ({ message }) => {
  const { errors, isValidating, submitCount } = useFormikContext()
  const [errorShowCount, incErrorShow] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(removeErrorAlerts())
  }, [submitCount])
  useEffect(() => {
    if (!isValidating && !isEmpty(errors) && submitCount > errorShowCount) {
      dispatch(addErrorAlert(message, { scroll: true }))
      incErrorShow(errorShowCount + 1)
    }
  }, [isValidating, !isEmpty(errors), submitCount])
  return null
}

_ScrollOnError.propTypes = {
  message: PropType.string.isRequired,
}

export default _ScrollOnError
