import {
  HeroLayout,
  Container,
  Grid,
  GridCol,
  Title,
  List,
  Paragraph,
  Separator,
  Text,
  ColorHeartWithClickIconNext,
  ColorGiftIconNext,
  ColorCrossCircleIconNext,
  ColorCheckedShieldIconNext,
  pxToRem,
  LargeArrowIconNext,
} from '@kisskissbankbank/kitten'
import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import {
  getProject,
  isProjectOnlyRecurringDonation,
} from 'kiss/modules/project-page/page-state/selectors'

const StyledGridCol = styled(GridCol)`
  display: flex;
  gap: ${pxToRem(20)};
  padding-bottom: ${pxToRem(20)};

  svg {
    margin-top: ${pxToRem(5)};
    flex: 0 0 ${pxToRem(30)};
    max-width: ${pxToRem(30)};
  }
`
const Step = ({ icon, text, className, colL = '3' }) => (
  <StyledGridCol col="12" col-s="6" col-l={colL} className={className}>
    {icon}
    <Paragraph tag="span" modifier="secondary" className="k-u-line-height-1-25">
      {text}
    </Paragraph>
  </StyledGridCol>
)

const Promo = () => {
  const t = useTranslation()
  const project = useSelector(getProject)

  const isOnlyRecurringDonation = useSelector(isProjectOnlyRecurringDonation)

  if (isOnlyRecurringDonation)
    return (
      <HeroLayout.Promo>
        <Container>
          <Grid>
            <GridCol col="12">
              <Text
                tag="h3"
                weight="600"
                transform="uppercase"
                size="small"
                className="k-u-letter-spacing-10 k-u-margin-top-none k-u-margin-bottom-singleHalf"
              >
                {t('permanent_project.text.how_it_works.title')}
              </Text>
            </GridCol>
            {[
              {
                icon: (
                  <ColorHeartWithClickIconNext
                    color="var(--color-grey-900)"
                    secondaryColor="var(--color-primary-500)"
                  />
                ),
                text: t(
                  'permanent_project.text.how_it_works.step1_recurring_donation',
                ),
              },
              {
                icon: (
                  <ColorCheckedShieldIconNext
                    color="var(--color-grey-900)"
                    secondaryColor="var(--color-primary-500)"
                  />
                ),
                text: t(
                  'permanent_project.text.how_it_works.step2_recurring_donation',
                ),
              },
              {
                icon: (
                  <ColorCrossCircleIconNext
                    color="var(--color-grey-900)"
                    secondaryColor="var(--color-primary-500)"
                  />
                ),
                text: t(
                  'permanent_project.text.how_it_works.step3_recurring_donation',
                ),
              },
            ].map((step) => (
              <Step
                key={`promo-${step.text}`}
                icon={step.icon}
                text={step.text}
                colL="4"
                className="k-u-flex-alignItems-center k-u-padding-bottom-none"
              />
            ))}
          </Grid>
        </Container>
      </HeroLayout.Promo>
    )

  const cheapestReward = project?.rewards
    // Unlimited reward or limited rewards that are still remaining
    ?.filter(
      (reward) => !reward.quantity || (reward.quantity && reward.remaining > 0),
    )
    ?.sort((a, b) => (a.price.cents > b.price.cents ? 1 : -1))?.[0]

  return (
    <HeroLayout.Promo>
      <Container>
        <Grid>
          <GridCol
            col="12"
            col-s="6"
            col-l="4"
            offset-l="2"
            className="k-u-margin-bottom-triple"
          >
            <Title tag="h2" noMargin modifier="tertiary">
              {t('permanent_project.text.subscribe.title')}
            </Title>
            <Text
              className="k-u-margin-top-noneHalf"
              cssColor="var(--color-grey-700)"
              tag="div"
            >
              {t(
                cheapestReward?.period === 'monthly'
                  ? 'permanent_project.text.subscribe.amount_monthly'
                  : 'permanent_project.text.subscribe.amount_quarterly',
                {
                  amount: t(cheapestReward?.price?.cents / 100, {
                    formatNumber: { decimal: 2 },
                  }),
                  currency: t(
                    `currency_symbol.${cheapestReward?.price?.currency}`,
                  ),
                  parseHtml: true,
                },
              )}
            </Text>
          </GridCol>
          <GridCol col="12" col-s="6" col-l="4">
            <Text
              tag="h3"
              weight="600"
              transform="uppercase"
              size="small"
              className="k-u-letter-spacing-10 k-u-margin-top-none k-u-margin-bottom-singleHalf"
            >
              {t('permanent_project.text.subscribe.why.title')}
            </Text>
            <List
              gap={10}
              markerElement={
                <LargeArrowIconNext
                  color="var(--color-primary-500)"
                  width="10"
                />
              }
            >
              <Text size="medium" className="k-u-line-height-1-25">
                {t('permanent_project.text.subscribe.why.reason1')}
              </Text>
              <Text size="medium" className="k-u-line-height-1-25">
                {t('permanent_project.text.subscribe.why.reason2')}
              </Text>
              <Text size="medium" className="k-u-line-height-1-25">
                {t('permanent_project.text.subscribe.why.reason3')}
              </Text>
            </List>
          </GridCol>
        </Grid>
        <Separator
          darker
          className="k-u-margin-top-quintuple k-u-margin-bottom-double k-u-hidden@xs-down"
        />
        <Grid className="k-u-margin-top-triple">
          <GridCol col="12">
            <Text
              tag="h3"
              weight="600"
              transform="uppercase"
              size="small"
              className="k-u-letter-spacing-10 k-u-margin-top-none k-u-margin-bottom-singleHalf"
            >
              {t('permanent_project.text.how_it_works.title')}
            </Text>
          </GridCol>
          {[
            {
              icon: (
                <ColorHeartWithClickIconNext
                  color="var(--color-grey-900)"
                  secondaryColor="var(--color-primary-500)"
                />
              ),
              text: t('permanent_project.text.how_it_works.step1'),
            },
            {
              icon: (
                <ColorCheckedShieldIconNext
                  color="var(--color-grey-900)"
                  secondaryColor="var(--color-primary-500)"
                />
              ),
              text: t('permanent_project.text.how_it_works.step2'),
            },
            {
              icon: (
                <ColorGiftIconNext
                  color="var(--color-grey-900)"
                  secondaryColor="var(--color-primary-500)"
                />
              ),
              text: t('permanent_project.text.how_it_works.step3'),
            },
            {
              icon: (
                <ColorCrossCircleIconNext
                  color="var(--color-grey-900)"
                  secondaryColor="var(--color-primary-500)"
                />
              ),
              text: t('permanent_project.text.how_it_works.step4'),
            },
          ].map((step) => (
            <Step
              key={`promo-${step.text}`}
              icon={step.icon}
              text={step.text}
            />
          ))}
        </Grid>
      </Container>
    </HeroLayout.Promo>
  )
}

export default Promo
