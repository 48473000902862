import dateFormat from 'date-fns/format'
import fr from 'date-fns/locale/fr'
import enGB from 'date-fns/locale/en-GB'

const availableLocales = { fr, en: enGB }
const formatDefault = 'd MMMM yyyy HH:mm:ss OOOO'

export const isoDateToLocaleFormat = (
  isoTime,
  options = {
    format: formatDefault,
    locale: 'fr',
  },
) => {
  const format = options.format || formatDefault

  return dateFormat(new Date(isoTime), format, {
    locale: availableLocales[options.locale],
  })
}
