import React from 'react'
import ViewportMediaQueries from './viewport'
import ColorSchemeMediaQuery from './color-scheme'

const MediaQueries = () => (
  <>
    <ViewportMediaQueries />
    <ColorSchemeMediaQuery />
  </>
)

export default MediaQueries
