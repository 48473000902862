import { Modal, pxToRem, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import styled from 'styled-components'

const RgpdText = styled(Text)`
  p {
    margin-bottom: ${pxToRem(15)};
  }
`

const RgpdModal = ({ regularText = false, textColor = 'font1', signUp }) => {
  const t = useTranslation()
  return (
    <Modal
      trigger={<Trigger regularText={regularText} textColor={textColor} />}
    >
      <Modal.Title>{t('rgpd.modal.title')}</Modal.Title>
      <Modal.Content align="left">
        <RgpdText
          tag="div"
          color="font1"
          weight="400"
          size="small"
          lineHeight="1.3"
        >
          {signUp
            ? t('rgpd.modal.content.signup', { parseHtml: true })
            : t('rgpd.modal.content.signin', { parseHtml: true })}
        </RgpdText>
      </Modal.Content>
    </Modal>
  )
}
const Trigger = ({ regularText, textColor, ...props }) => {
  const t = useTranslation()

  return (
    <button
      className="k-u-reset-button k-u-align-left"
      type="button"
      {...props}
    >
      <Text
        color={textColor}
        size="micro"
        weight="400"
        fontStyle={regularText ? 'normal' : 'italic'}
      >
        {t('rgpd.modal.text')}{' '}
      </Text>
      <Text
        color="primary1"
        size="micro"
        weight="500"
        fontStyle={regularText ? 'normal' : 'italic'}
        className="k-u-link k-u-link-primary1"
      >
        {t('rgpd.modal.action_link')}
      </Text>
    </button>
  )
}

export default RgpdModal
