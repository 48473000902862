import React from 'react'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import head from 'lodash/fp/head'
import isEmpty from 'lodash/fp/isEmpty'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { ContributionCard, pxToRem } from '@kisskissbankbank/kitten'
import { getJSONContent } from '@kisskissbankbank/underwood'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getOrderLines,
  hasOrderLines as hasOrderLinesSelector,
  isStartOnNextPeriod,
  removeVariation,
} from '../../../cart/redux'
import CartHelper from './cart-helper'

const DescriptionWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin-right: ${pxToRem(40)};
`

const StyledAmount = styled(ContributionCard.Amount)`
  grid-column: span 2;
`

const getFirstBlockText = (description) =>
  flow(
    getJSONContent,
    getOr([])('blocks'),
    head,
    getOr('')('text'),
  )(description)

const SubscriptionReward = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const orderLines = useSelector(getOrderLines)
  const hasOrderLines = useSelector(hasOrderLinesSelector)
  const startOnNextPeriod = useSelector(isStartOnNextPeriod)

  if (!hasOrderLines) return null

  const { totalAmount, variation } = orderLines[0]
  const { reward } = variation
  const rawDescription = getFirstBlockText(reward?.description)

  const formattedAmount = t(
    reward?.period === 'monthly'
      ? 'permanent_project.text.cart.amount_monthly'
      : 'permanent_project.text.cart.amount_quarterly',
    {
      amount: t(totalAmount?.cents / 100 || 0, {
        formatNumber: { decimal: 2 },
      }),
      currency: t(`currency_symbol.${totalAmount?.currency}`),
      parseHtml: true,
    },
  )
  return (
    <>
      <div className="k-u-margin-bottom-singleHalf" data-test-id="reward-card">
        <ContributionCard
          onClose={() => {
            dispatch(
              removeVariation({
                variationId: variation.id,
              }),
            )
          }}
        >
          {reward?.image?.url && (
            <ContributionCard.Image>
              <img src={reward.image.url} alt="" />
            </ContributionCard.Image>
          )}
          <ContributionCard.Title style={{ maxWidth: '100%' }}>
            {isEmpty(reward.label) ? (
              <DescriptionWrapper>{rawDescription}</DescriptionWrapper>
            ) : (
              reward.label
            )}
            {variation.label && (
              <ContributionCard.Description>
                {variation.label}
              </ContributionCard.Description>
            )}
          </ContributionCard.Title>
          <StyledAmount>{formattedAmount}</StyledAmount>
        </ContributionCard>
      </div>
      <CartHelper
        date={
          startOnNextPeriod
            ? reward?.nextPeriodLabel
            : reward?.subscribablePeriodLabel
        }
        period={reward.period}
      />
    </>
  )
}

export default SubscriptionReward
