import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import WidgetCard from 'kiss/components/widget-card'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getProject } from '../../../../page-state/selectors'
import {
  Button,
  EmbedIcon,
  Modal,
  pxToRem,
  TextCopy,
  FlexWrapper,
} from '@kisskissbankbank/kitten'

const StyledWidgetCardWrapper = styled.div`
  align-self: center;
  width: calc(100% - 2 * ${pxToRem(20 + 2)});
  max-width: ${pxToRem(300)};
`

const WidgetCardModal = () => {
  const t = useTranslation()
  const project = useSelector(getProject)
  const { slug, name, publicUrl } = project

  return (
    <Modal
      closeButtonLabel={t('widget.modal.button.close_label')}
      trigger={
        <Button
          fit="icon"
          modifier="hydrogen"
          aria-label={t('widget.modal.button.copy')}
          title={t('widget.modal.button.copy')}
        >
          <EmbedIcon />
        </Button>
      }
    >
      {() => (
        <>
          <Modal.Title
            modifier="tertiary"
            noMargin
            className="k-u-margin-bottom-double k-u-align-center"
          >
            {t('widget.modal_title')}
          </Modal.Title>
          <FlexWrapper>
            <TextCopy
              buttonText={t('widget.modal.button.copy')}
              className="k-u-weight-400"
            >
              {`<iframe style="border-width:0;" id="kisskiss-${slug}" title="${name}" width="375" height="500" src="${publicUrl}/widget_v2"></iframe>`}
            </TextCopy>
            <StyledWidgetCardWrapper className="k-u-margin-top-triple">
              <WidgetCard project={project} />
            </StyledWidgetCardWrapper>
          </FlexWrapper>
        </>
      )}
    </Modal>
  )
}

export default WidgetCardModal
