import {
  isServerAllowed,
  urlBuilder,
} from 'kiss/utils/responsive-image-handler'
import { isGif, isSvg } from '../validators'

export const imageDensityAttributes = (
  imagePath,
  { imageWidth, imageHeight = 0, upscale = true, format = 'jpg' } = {},
) => {
  if (!imagePath) return

  if (
    isSvg(imagePath) ||
    isGif(imagePath) ||
    !ENABLE_RESPONSIVE_IMAGES ||
    !isServerAllowed(imagePath)
  ) {
    return { src: imagePath }
  }

  const formatString = `format:${format}`
  const filters = ['quality:95', 'quality:60']

  const url1x = urlBuilder(imagePath, {
    width: imageWidth,
    height: imageHeight,
    filters: [filters[0], formatString],
    upscale,
  })
  const url2x = urlBuilder(imagePath, {
    width: imageWidth * 2,
    height: imageHeight * 2,
    filters: [filters[1], formatString],
    upscale,
  })
  const url3x = urlBuilder(imagePath, {
    width: imageWidth * 3,
    height: imageHeight * 3,
    filters: [filters[1], formatString],
    upscale,
  })

  return {
    src: url1x,
    srcSet: `${url1x}, ${url2x} 2x, ${url3x} 3x`,
  }
}
