export default {
  global: {
    filterModal: 102,
    secondBelowHeader: 103,
    belowHeader: 104,
    overlay: 105,
    header: 106,
    aboveHeader: 107,
    secondAboveHeader: 108,
    headerWithOpenMenu: 109,
    belowModal: 110,
    modal: 111,
    cookieBanner: 200,
    quickAccessLink: 300,
    aboveAll: 999999,
  },
  local: {
    aboveSelect: 1001,
  },
}
