import { FloatingMenu } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getFaqs,
  getProject,
  getProjectRewards,
} from 'kiss/modules/project-page/page-state/selectors'
import {
  getRouteFor,
  SUBSCRIBER_PROJECT_ABOUT_ROUTE,
  SUBSCRIBER_PROJECT_FAQ_ROUTE,
  SUBSCRIBER_PROJECT_NEWS_ROUTE,
  SUBSCRIBER_PROJECT_SUBSCRIPTION_ROUTE,
} from 'kiss/routes/redux'
import { scrollTo } from 'kiss/utils/animation/scroll-to'
import isEmpty from 'lodash/fp/isEmpty'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ABOUT_ID, FAQ_ID, NEWS_ID, REWARDS_ID } from '../../constants/id'

const MenuItem = ({ route, id, children }) => {
  const [isActive, setActive] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const routeFor = useSelector(getRouteFor)
  const project = useSelector(getProject)

  const builtRoute = routeFor(route, {
    project: project?.slug,
  })

  useEffect(() => {
    setActive(location.pathname === builtRoute)
  }, [location.pathname])

  return (
    <FloatingMenu.Item
      isActive={isActive}
      as="a"
      href={builtRoute}
      id={id}
      onClick={(e) => {
        e.preventDefault()
        history.push(builtRoute)
        scrollTo(document.getElementById(id), 300)
      }}
    >
      {children}
    </FloatingMenu.Item>
  )
}

const Menu = () => {
  const t = useTranslation()
  const faqs = useSelector(getFaqs)
  const rewards = useSelector(getProjectRewards)

  return (
    <FloatingMenu aria-label={t('permanent_project.menu.title')} horizontal>
      <MenuItem route={SUBSCRIBER_PROJECT_NEWS_ROUTE} id={NEWS_ID}>
        {t('permanent_project.menu.news')}
      </MenuItem>
      <MenuItem route={SUBSCRIBER_PROJECT_SUBSCRIPTION_ROUTE} id={REWARDS_ID}>
        {isEmpty(rewards)
          ? t('permanent_project.menu.support')
          : t('permanent_project.menu.subscriptions')}
      </MenuItem>
      <MenuItem route={SUBSCRIBER_PROJECT_ABOUT_ROUTE} id={ABOUT_ID}>
        {t('permanent_project.menu.about')}
      </MenuItem>

      {faqs.length > 0 && (
        <MenuItem route={SUBSCRIBER_PROJECT_FAQ_ROUTE} id={FAQ_ID}>
          {t('permanent_project.menu.faq')}
        </MenuItem>
      )}
    </FloatingMenu>
  )
}

export default Menu
