import React from 'react'
import { Checkbox, Grid, GridCol, TextCopy } from '@kisskissbankbank/kitten'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  disableShareProject,
  enableShareProject,
} from 'kiss/modules/project-page/page-state/actions'
import {
  getProjectState,
  getProjectUrl,
  getDraftPageToken,
  isDraftPageSharingEnabled,
} from 'kiss/modules/project-page/page-state/selectors'

const PROJECT_STATUS_CAN_SHARE = ['draft', 'submitted', 'improve', 'accepted']

const PreviewLink = () => {
  const t = useTranslation()
  const dispatch = useDispatch()

  const draftSharingEnabled = useSelector(isDraftPageSharingEnabled)
  const draftToken = useSelector(getDraftPageToken)
  const projectUrl = useSelector(getProjectUrl)
  const state = useSelector(getProjectState)

  const onClickForShare = async () => {
    if (!draftSharingEnabled) {
      return dispatch(enableShareProject())
    }
    return dispatch(disableShareProject())
  }

  if (!PROJECT_STATUS_CAN_SHARE.includes(state)) return null

  return (
    <>
      <Checkbox
        className="k-u-margin-none"
        label={t('projects.owner_panel.activate_sharing_link')}
        onClick={onClickForShare}
        checked={draftSharingEnabled}
      >
        {t('projects.owner_panel.activate_sharing_link_description')}
      </Checkbox>
      {draftSharingEnabled && (
        <Grid>
          <GridCol col-m="6">
            <TextCopy
              alertMessage={t('projects.owner_panel.link_copy_confirmation')}
              buttonText={t('projects.owner_panel.copy_link')}
              description={t('projects.owner_panel.link_copy_description')}
              forceOneLine
              size="small"
              modifier="helium"
            >{`${projectUrl}/preview?draft_page_token=${draftToken}`}</TextCopy>
          </GridCol>
        </Grid>
      )}
    </>
  )
}

export default PreviewLink
