import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getMentors,
  getProjectName,
} from 'kiss/modules/project-page/page-state/selectors'
import { getRouteFor, MENTOR_PAGE } from 'kiss/routes/redux'
import { pxToRem, Text } from '@kisskissbankbank/kitten'

const Mentors = (props) => {
  const t = useTranslation()
  const projectName = useSelector(getProjectName)
  const mentors = useSelector(getMentors)
  const routeFor = useSelector(getRouteFor)

  return (
    <div {...props}>
      <Text
        tag="h3"
        size="micro"
        transform="uppercase"
        className="kiss-AsideTitle k-u-margin-top-none k-u-margin-bottom-noneHalf k-u-letter-spacing-10 k-u-flex k-u-flex-justifyContent-center@s-down"
      >
        {t('project_details.mentor')}
      </Text>

      <StyledFlex className="k-u-flex k-u-flex-justifyContent-center@s-down">
        {mentors.map((mentor) => (
          <StyledMentor
            key={mentor.username}
            href={routeFor(MENTOR_PAGE, { mentor: mentor.slug })}
          >
            <img
              src={mentor.image.url}
              alt={t('project_details.mentor_alt', {
                user_name: mentor.username,
                project_name: projectName,
              })}
            />
          </StyledMentor>
        ))}
      </StyledFlex>
    </div>
  )
}

const StyledFlex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const StyledMentor = styled.a`
  display: inline-block;
  margin-top: ${pxToRem(10)};
  margin-right: ${pxToRem(15)};

  img {
    display: block;
    height: ${pxToRem(60)};
  }
`

export default Mentors
