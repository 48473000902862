import { Container, mq, pxToRem, Title } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import SignInForm from 'kiss/modules/authenticate/components/sign-in'
import { getCookie, removeCookie } from 'kiss/modules/contribute/cart/redux'
import useContributeStepper from 'kiss/modules/contribute/hooks/use-stepper'
import { getProjectId, getProjectSlug } from 'kiss/modules/contribute/redux'
import { getRouteFor as getRouteForSelector } from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const SignInWrapper = styled.div`
  width: 100%;
  padding: ${pxToRem(40)};
  border-radius: var(--border-radius-l);
  box-shadow: var(--box-shadow-l);
  @media ${mq.desktop} {
    width: ${pxToRem(400)};
  }
`

const SignIn = () => {
  const t = useTranslation()
  const projectSlug = useSelector(getProjectSlug)
  const projectId = useSelector(getProjectId)
  const getRouteFor = useSelector(getRouteForSelector)
  const authenticateReturnTo = -getCookie('authenticateReturnTo')
  const { nextRoute } = useContributeStepper()
  const nextUrl =
    authenticateReturnTo || getRouteFor(nextRoute, { project: projectSlug })

  const handleAfterRedirect = () => {
    removeCookie('authenticateReturnTo')
  }

  return (
    <Container>
      <div className="k-u-flex k-u-flex-justifyContent-center">
        <SignInWrapper>
          <Title
            modifier="quaternary"
            tag="h2"
            className="k-u-margin-bottom-single k-u-align-center"
          >
            {t('login.form.title')}
          </Title>

          <SignInForm
            nextUrl={nextUrl}
            afterRedirect={handleAfterRedirect}
            projectId={projectId}
          />
        </SignInWrapper>
      </div>
    </Container>
  )
}

export default SignIn
