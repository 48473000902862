import { gql } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from 'kiss/api/graphql-query'
import { CONTRIBUTION_FIELDS } from './use-subscription'

const SUBSCRIPTIONS_PER_PAGE = 30

const REFRESH_SUBSCRIPTION_CARD = gql`
  query RefreshSubscriptionCard($id: ID) {
    subscription(id: $id) {
      id
      currentCard {
        expirationDate
        numbers
      }
    }
  }
`

const USER_SUBSCRIPTIONS_QUERY = gql`
  ${CONTRIBUTION_FIELDS}
  query GetSubscriptions($slug: String, $subscriptionsFirst: Int) {
    user(slug: $slug) {
      id
      subscriptionsConnection(
        first: $subscriptionsFirst
        subscriptionStatus: [active, past_due, suspended, cancelled]
      ) {
        totalCount
        edges {
          node {
            id
            amount {
              cents
              currency
            }
            cancelledAt
            contribution {
              ...ContributionFields
            }
            donation
            endDate
            period
            state
            currentCard {
              expirationDate
              numbers
            }
            subscriptionDate
            address {
              id
              firstName
              lastName
              companyName
              phone
              address1
              address2
              postalCode
              city
              countryCode
              user {
                email
              }
            }
            installments {
              id
              debitOn
              state
              label
              order {
                id
                deliveryModes
                totalAmount {
                  cents
                  currency
                }
              }
            }
            lastFulfilledInstallment {
              label
              state
              order {
                id
                deliveryModes
                payment {
                  card {
                    expirationDate
                    numbers
                  }
                }
                orderAddress {
                  id
                  firstName
                  lastName
                  companyName
                  phone
                  address1
                  address2
                  postalCode
                  city
                  countryCode
                  user {
                    email
                  }
                }
              }
            }
            currentInstallment {
              label
              state
              order {
                id
                deliveryModes
                payment {
                  card {
                    expirationDate
                    numbers
                  }
                }
              }
            }
            contribution {
              project {
                subscribablePeriodLabel
              }
            }
            variation {
              id
              label
              reward {
                id
                label
                shippingDate {
                  month
                  year
                }
                subscribablePeriodLabel
                description
                image {
                  url(size: cropped)
                  thumbUrl: url(size: thumb)
                  normalUrl: url(size: normal)
                }
                price {
                  cents
                  currency
                }
              }
            }
          }
        }
      }
    }
  }
`

const orderLinesFormatted = (orders) => {
  return orders.map((order) => {
    return {
      ...order,
      projectName: order.contribution.project.name,
      projectSlug: order.contribution.project.slug,
      projectImage: order.contribution.project.image,
      projectState: order.contribution.project.state,
      projectCampaignEndAt: order.contribution.project.campaignEndAt,
      projectSuspendedAt: order.contribution.project.suspendedAt,
    }
  })
}

const useSubscriptions = () => {
  const { user } = useParams()
  const { data, loading, refetch } = useQuery(
    USER_SUBSCRIPTIONS_QUERY,
    {
      slug: user,
      subscriptionsFirst: SUBSCRIPTIONS_PER_PAGE,
    },
    { notifyOnNetworkStatusChange: true },
  )
  const [refreshSubscriptionCard, { loading: refreshSubscriptionCardLoading }] =
    useLazyQuery(REFRESH_SUBSCRIPTION_CARD)

  const subscriptionsOrders =
    data?.user?.subscriptionsConnection?.edges?.map(({ node }) => node) ?? []

  return {
    user: data?.user,
    totalCount: data?.user?.subscriptionsConnection?.totalCount,
    subscriptionsOrdersFormatted: orderLinesFormatted(subscriptionsOrders),
    loading,
    refetch,
    refreshSubscriptionCard,
    refreshSubscriptionCardLoading,
  }
}

export default useSubscriptions
