import { FlexWrapper, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const FiltersNav = ({ children }) => {
  const t = useTranslation()

  return (
    <FlexWrapper
      gap={10}
      direction="row"
      className="k-u-flex-alignItems-center k-u-margin-bottom-single"
    >
      <Text
        weight="500"
        size="small"
        color="font1"
        className="k-u-hidden@m-down"
      >
        {t('project.news.sort_by')}
      </Text>

      {children}
    </FlexWrapper>
  )
}

export default FiltersNav
