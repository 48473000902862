import {
  TwitterButtonIcon,
  TwitterButtonIconWords,
} from '@kisskissbankbank/kitten'
import SocialClickHandler from 'kiss/components/social/social-click-handler'
import { useTranslation } from 'kiss/hooks/use-translation'
import PropTypes from 'prop-types'
import React from 'react'

const TwitterButton = ({
  sharedUrl,
  projectName,
  ownerName,
  style,
  children,
}) => {
  const t = useTranslation()

  const buildTwitterUrl = () => {
    const encodedUrl = encodeURIComponent(sharedUrl)
    const text = encodeURIComponent(
      t('project_details.social.share_msg', {
        project: projectName,
        owner: ownerName,
      }),
    )

    return (
      'https://twitter.com/intent/tweet/' +
      `?url=${encodedUrl}` +
      `&text=${text}` +
      '&via=Kissbankers'
    )
  }

  return (
    <SocialClickHandler>
      {(handleClick) => {
        return children ? (
          <TwitterButtonIconWords
            size="large"
            onClick={() => handleClick('Twitter', buildTwitterUrl())}
          >
            {children}
          </TwitterButtonIconWords>
        ) : (
          <TwitterButtonIcon
            style={style}
            onClick={() => handleClick('Twitter', buildTwitterUrl())}
            aria-label={t('app.sharing.share_on_twitter')}
          />
        )
      }}
    </SocialClickHandler>
  )
}

TwitterButton.propTypes = {
  sharedUrl: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  style: PropTypes.object,
}

TwitterButton.defaultProps = {
  style: {},
}

export default TwitterButton
