import * as qs from 'neoqs'

export const addFlowConnectionFlag = (url, extraParams = {}) => {
  if (!(typeof url === 'string')) {
    return '/?flow_connection=true'
  }
  const [base, search = ''] = url.split('?')
  let params = qs.parse(search, { ignoreQueryPrefix: true })
  params = { ...params, ...extraParams }
  params.flow_connection = true
  const queryParams = qs.stringify(params, { addQueryPrefix: true })
  return `${base}${queryParams}`
}
