import React, { useState } from 'react'
import DefinitionList from './definition-list'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getProjectState,
  getCurrentUserPermissions,
  getAcceptedPaymentProviders,
  getProcessedAt,
} from 'kiss/modules/project-page/page-state/selectors'
import { markProjectAsProcessed } from './actions'
import { addSuccessAlert, removeAllAlerts } from 'kiss/app/alerts/redux'
import { Button, Text } from '@kisskissbankbank/kitten'
import Dialog from 'kiss/app/modal/dialog'

const MarkAsProcessed = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const [isSubmitting, markAsSubmitting] = useState(false)
  const acceptedPaymentProviders = useSelector(getAcceptedPaymentProviders)
  const hasAcceptedPaymentProviders = acceptedPaymentProviders.length >= 1
  const { canAccept } = useSelector(getCurrentUserPermissions)
  const isProcessed = useSelector(getProcessedAt)
  const state = useSelector(getProjectState)
  const canMarkAsProcessed = !isProcessed && canAccept
  const handleSubmit = () => {
    markAsSubmitting(true)
    dispatch(removeAllAlerts())
    dispatch(markProjectAsProcessed())
      .then(({ errors }) => {
        if (!errors) {
          dispatch(
            addSuccessAlert(
              t('projects.admin_panel.alerts.project_processed'),
              { scroll: true },
            ),
          )
        }
      })
      .finally(() => markAsSubmitting(false))
  }

  if (['started', 'successful', 'failed'].includes(state)) return null

  return (
    <>
      <DefinitionList.Title>
        <Text weight="600" size="medium" color="font1">
          {t('projects.admin_panel.mark_as_processed')}
        </Text>
      </DefinitionList.Title>
      <DefinitionList.Description>
        {!hasAcceptedPaymentProviders && (
          <Text weight="400" size="medium" color="font1" tag="div">
            {t('projects.admin_panel.payment_providers_error')}
          </Text>
        )}
        {canMarkAsProcessed && (
          <Dialog
            title={t('projects.admin_panel.modal.are_you_sure')}
            primaryActionText={t('app.yes')}
            primaryAction={(close) => {
              handleSubmit()
              close()
            }}
          >
            <Button type="button" modifier="hydrogen" size="small">
              {isSubmitting
                ? t('projects.admin_panel.please_wait')
                : t('projects.admin_panel.mark_as_processed')}
            </Button>
          </Dialog>
        )}
        {hasAcceptedPaymentProviders && !canMarkAsProcessed && (
          <Text weight="400" size="medium" color="font1">
            {t('projects.admin_panel.project_is_processed')}
          </Text>
        )}
      </DefinitionList.Description>
    </>
  )
}

export default MarkAsProcessed
