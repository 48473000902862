import React from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import { HeaderNav } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { isLogged as isLoggedSelector } from 'kiss/session/redux'
import {
  getRouteFor,
  BROWSING,
  FUND_MY_PROJECT,
  PROJECT_ROUTE,
} from 'kiss/routes/redux'

const Navigation = () => {
  const routeFor = useSelector(getRouteFor)
  const isLogged = useSelector(isLoggedSelector)
  const t = useTranslation()
  const { pathname } = useLocation()
  const isPathMatching = (path) =>
    matchPath(pathname, {
      path: path,
      exact: true,
    })

  return (
    <>
      <HeaderNav.Nav.Item
        href={routeFor(BROWSING)}
        isSelected={isPathMatching(BROWSING)}
      >
        {t('layouts.header.explore_the_projects')}
      </HeaderNav.Nav.Item>

      {!isPathMatching(PROJECT_ROUTE) && isLogged && (
        <HeaderNav.Nav.Item
          href={routeFor(FUND_MY_PROJECT)}
          isSelected={isPathMatching(FUND_MY_PROJECT)}
          data-test-id="header-start-your-project"
        >
          {t('layouts.header.start_your_project')}
        </HeaderNav.Nav.Item>
      )}
    </>
  )
}

export default Navigation
