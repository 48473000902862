import React, { memo } from 'react'
import RichContentButton from 'kiss/modules/components/rich-content/button'
import {
  Container,
  Grid,
  GridCol,
  Text,
  Title,
  Triptych as KittenTriptych,
  FlexWrapper,
} from '@kisskissbankbank/kitten'
import getTitleId from 'kiss/utils/get-title-id'

const CardBase = ({ image, title, link }) => {
  return (
    <FlexWrapper gap={30}>
      <img
        loading="lazy"
        src={image.url}
        alt={image.alt}
        style={{ width: '100%', display: 'block' }}
      />

      <Text color="font1" size="huge" weight="600">
        {title}
      </Text>

      <RichContentButton
        fit="content"
        mobileFit="fluid"
        modifier="hydrogen"
        className="k-u-alignSelf-center"
        {...link}
      />
    </FlexWrapper>
  )
}

const Card = CardBase

const Triptych = ({ title, blocks }) => (
  <Container>
    <Grid>
      <GridCol col-xs="10" offset-xs="1">
        <KittenTriptych
          title={
            <Title
              id={getTitleId(title.text)}
              tag={`h${title.level}`}
              modifier="secondary"
              noMargin
            >
              {title.text}
            </Title>
          }
          item1={
            <Card
              image={blocks[0].image}
              title={blocks[0].title.text}
              link={blocks[0].link}
            />
          }
          item2={
            <Card
              image={blocks[1].image}
              title={blocks[1].title.text}
              link={blocks[1].link}
            />
          }
          item3={
            <Card
              image={blocks[2].image}
              title={blocks[2].title.text}
              link={blocks[2].link}
            />
          }
        />
      </GridCol>
    </Grid>
  </Container>
)

export default memo(Triptych)
