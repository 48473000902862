import React from 'react'
import { useSelector } from 'react-redux'
import { getCurrentUserUsername } from 'kiss/session/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, STOP_MASQUERADE_USER } from 'kiss/routes/redux'
import { Alert } from '@kisskissbankbank/kitten'

const MasqueradeAlert = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const username = useSelector(getCurrentUserUsername)
  return (
    <Alert
      status="success"
      closeButtonLabel={t('alerts.close_button')}
      closeButton
    >
      {t('masquerade.masquerading_as', { user: username })}{' '}
      <a href={routeFor(STOP_MASQUERADE_USER)} rel="nofollow">
        {t('masquerade.stop')}
      </a>
    </Alert>
  )
}

export default MasqueradeAlert
