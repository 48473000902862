import {
  KissKissLoadingAnimation,
  Modal,
  Paragraph,
  Button,
} from '@kisskissbankbank/kitten'
import React from 'react'

const Dialog = ({
  title,
  content,
  primaryAction,
  primaryActionProps = {},
  primaryActionLoading = false,
  primaryActionModifier = 'helium',
  primaryActionText,
  secondaryAction,
  secondaryActionProps = {},
  secondaryActionText,
  children = null,
  modalProps,
  ...props
}) => (
  <Modal
    trigger={children}
    hasCloseButton={false}
    modalProps={{
      shouldCloseOnOverlayClick: false,
      ...modalProps,
    }}
    {...props}
  >
    {({ close }) => {
      return (
        <>
          {!!title && <Modal.Title>{title}</Modal.Title>}

          <Modal.Content>
            {!!content && (
              <Paragraph
                modifier="tertiary"
                noMargin
                className="k-u-align-center"
              >
                {content}
              </Paragraph>
            )}

            <Modal.Actions>
              {!!(secondaryAction || secondaryActionProps) &&
                !!secondaryActionText && (
                  <Button
                    modifier="hydrogen"
                    onClick={() => secondaryAction(close)}
                    size="medium"
                    {...secondaryActionProps}
                  >
                    {secondaryActionText}
                  </Button>
                )}

              {!!(primaryAction || primaryActionProps) &&
                !!primaryActionText && (
                  <Button
                    modifier={primaryActionModifier}
                    onClick={() => primaryAction(close)}
                    size="medium"
                    {...primaryActionProps}
                  >
                    {primaryActionLoading ? (
                      <KissKissLoadingAnimation />
                    ) : (
                      primaryActionText
                    )}
                  </Button>
                )}
            </Modal.Actions>
          </Modal.Content>
        </>
      )
    }}
  </Modal>
)

export default Dialog
