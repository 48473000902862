import React from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Title, Text, pxToRem } from '@kisskissbankbank/kitten'
import { getAssetFor } from 'kiss/assets/redux'

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${pxToRem(16)};
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${pxToRem(60)};
`

const Message = ({ title, text, imageSrc }) => {
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledContainer>
      <TextContainer>
        <Title
          modifier="tertiary"
          family="antiqueolive"
          className="k-u-align-center"
        >
          {title}
        </Title>
        <Text className="k-u-align-center" lineHeight="normal">
          {text}
        </Text>
      </TextContainer>
      <div className="k-u-alignSelf-center">
        <img
          src={assetFor(imageSrc)}
          alt={imageSrc}
          width="164px"
          height="70px"
        />
      </div>
    </StyledContainer>
  )
}

export default Message
