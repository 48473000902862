import React, { useState } from 'react'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getCurrentProjectRouteFor,
  getVariationsCount,
  isAggressivelyCached,
} from 'kiss/modules/project-page/page-state/selectors'
import { CART } from 'kiss/routes/redux'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { Badge, Button, Cart } from '@kisskissbankbank/kitten'

const GoToCartButton = (props) => {
  const t = useTranslation()
  const variationsCount = useSelector(getVariationsCount)
  const currentProjectRouteFor = useSelector(getCurrentProjectRouteFor)
  const aggressivelyCached = useSelector(isAggressivelyCached)
  const [clicked, setClicked] = useState(false)
  return clicked ? (
    <Button
      data-test-id="contribute-button"
      modifier="helium"
      size="large"
      type="button"
      {...props}
    >
      <LoadingAnimation color="var(--color-grey-000)" />
    </Button>
  ) : (
    <Button
      data-test-id="contribute-button"
      modifier="helium"
      type="button"
      onClick={() => {
        setClicked(true)
        RoutingHelper.redirect(currentProjectRouteFor(CART))
      }}
      size="large"
      {...props}
    >
      <span>{t('project.button.goto_cart')}</span>
      {!aggressivelyCached && (
        <Badge color="var(--color-danger-500)" Icon={Cart}>
          {variationsCount}
        </Badge>
      )}
    </Button>
  )
}

export default GoToCartButton
