import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { useTranslation } from 'kiss/hooks/use-translation'

import * as qs from 'neoqs'
import styled from 'styled-components'
import loadable from '@loadable/component'

import {
  HeroLayout,
  KissKissLoadingAnimation,
  mq,
  pxToRem,
  useLazyObserver,
} from '@kisskissbankbank/kitten'

import {
  getExclusiveRewardsFromCookie,
  loadProjectRewardsByReference,
  setExclusiveRewardsToCookie,
  updateProjectSlug,
} from 'kiss/modules/project-page/page-state/actions'
import { getFaqs } from 'kiss/modules/project-page/page-state/selectors'
import { addErrorAlert } from 'kiss/app/alerts/redux'

import isEmpty from 'lodash/fp/isEmpty'

import Helmet from 'kiss/modules/project-page/permanent/components/helmet'
import PermanentProjectAbout from 'kiss/modules/project-page/permanent/common-pages/about'
import PermanentProjectLayout from 'kiss/modules/project-page/permanent/visitor/components/layout'
import {
  ABOUT_ID,
  FAQ_ID,
  NEWS_ID,
  REWARDS_ID,
} from 'kiss/modules/project-page/permanent/constants/id'
import PermanentProjectRewards from 'kiss/modules/project-page/permanent/visitor/rewards'
import News from 'kiss/modules/project-page/permanent/common-pages/news'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(50)};

  @media ${mq.desktop} {
    gap: ${pxToRem(70)};
  }

  .kiss-PermanentProject__LoadableSection {
    min-height: ${pxToRem(100)};

    &:last-child {
      min-height: 100vh;
    }

    .kiss-PermanentProject__LoadableSection__loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

const Loader = () => (
  <div className="kiss-PermanentProject__LoadableSection__loader">
    <KissKissLoadingAnimation />
  </div>
)

const FAQ = () => {
  const faqRef = useRef(null)
  const shouldLoadFaq = useLazyObserver(faqRef)

  const PermanentProjectFaq = loadable(
    () =>
      import(
        /* webpackChunkName: 'permanent-project-faq', webpackrefetch: true */ 'kiss/modules/project-page/permanent/common-pages/faq'
      ),
    {
      fallback: <Loader />,
    },
  )

  return (
    <div
      id={FAQ_ID}
      ref={faqRef}
      className="kiss-PermanentProject__LoadableSection"
    >
      {shouldLoadFaq && <PermanentProjectFaq />}
    </div>
  )
}

const PermanentProjectPage = () => {
  const faqs = useSelector(getFaqs)

  const dispatch = useDispatch()

  const t = useTranslation()

  const { search } = useLocation()

  const { project: projectSlug } = useParams()

  const searchParams = qs.parse(search, { ignoreQueryPrefix: true })
  const secretReward = searchParams['secret-reward']
  const userHasAlreadyASubscription = searchParams['already_subscribe']

  const newsRef = useRef(null)

  useEffect(() => {
    dispatch(updateProjectSlug(projectSlug))
    initExclusiveRewards()
    if (userHasAlreadyASubscription) {
      dispatch(addErrorAlert(t('project.permanent.already_subscribed')))
    }
  }, [])

  const initExclusiveRewards = () => {
    let references

    if (isEmpty(secretReward)) {
      references = dispatch(getExclusiveRewardsFromCookie())
    } else {
      const exclusiveRewards =
        typeof secretReward === 'string'
          ? secretReward.split(',')
          : secretReward

      references = dispatch(setExclusiveRewardsToCookie(exclusiveRewards))
    }

    if (isEmpty(references)) return

    dispatch(loadProjectRewardsByReference(references))
  }

  return (
    <>
      <Helmet />
      <PermanentProjectLayout>
        <HeroLayout.Main.Content>
          <StyledWrapper>
            <PermanentProjectRewards id={REWARDS_ID} />
            <PermanentProjectAbout id={ABOUT_ID} />
            <div
              id={NEWS_ID}
              ref={newsRef}
              className="kiss-PermanentProject__LoadableSection"
            >
              <News />
            </div>
            {faqs.length > 0 && <FAQ />}
          </StyledWrapper>
        </HeroLayout.Main.Content>
      </PermanentProjectLayout>
    </>
  )
}

export default PermanentProjectPage
