import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  getEncryptedMetaConversionsApiAccessToken,
  getFacebookPixelRef,
  getGoalType,
  getProjectCategoriesName,
  getProjectId,
  getProjectName,
  getProjectSlug,
  isCurrentUserOwner as isCurrentUserOwnerSelector,
} from 'kiss/modules/project-page/page-state/selectors'
import {
  getEncryptedMetaConversionsApiAccessToken as getEncryptedMetaConversionsApiAccessTokenContribute,
  getFacebookPixelRef as getFacebookPixelRefContribute,
  getGoalType as getGoalTypeContribute,
  getProjectCategoriesName as getProjectCategoriesNameContribute,
  getProjectId as getProjectIdContribute,
  getProjectName as getProjectNameContribute,
  getProjectSlug as getProjectSlugContribute,
} from 'kiss/modules/contribute/redux'
import { getCurrentUser } from 'kiss/session/redux'
import {
  getCart,
  getTotalAmount,
  getTotalCurrency,
} from 'kiss/modules/contribute/cart/redux'

import { FacebookPixelContext } from './provider'
import { FacebookPixelHelper } from './helper'

const FacebookPixelTracker = ({ eventName }) => {
  const { dataLayer, loading } = useContext(FacebookPixelContext)

  const currentUser = useSelector(getCurrentUser)
  const isCurrentUserOwner = useSelector(isCurrentUserOwnerSelector)

  const projectId = useSelector(getProjectId)
  const projectIdContribute = useSelector(getProjectIdContribute)

  const facebookPixelRef = useSelector(getFacebookPixelRef)
  const facebookPixelRefContribute = useSelector(getFacebookPixelRefContribute)

  const encryptedMetaConversionsApiAccessToken = useSelector(
    getEncryptedMetaConversionsApiAccessToken,
  )
  const encryptedMetaConversionsApiAccessTokenContribute = useSelector(
    getEncryptedMetaConversionsApiAccessTokenContribute,
  )

  const projectName = useSelector(getProjectName)
  const projectNameContribute = useSelector(getProjectNameContribute)

  const projectSlug = useSelector(getProjectSlug)
  const projectSlugContribute = useSelector(getProjectSlugContribute)

  const goalType = useSelector(getGoalType)
  const goalTypeContribute = useSelector(getGoalTypeContribute)

  const projectCategoriesName = useSelector(getProjectCategoriesName)
  const projectCategoriesNameContribute = useSelector(
    getProjectCategoriesNameContribute,
  )

  const projectData = {
    uuid: projectId || projectIdContribute,
    facebookPixelRef: facebookPixelRef || facebookPixelRefContribute,
    encryptedMetaConversionsApiAccessToken:
      encryptedMetaConversionsApiAccessToken ||
      encryptedMetaConversionsApiAccessTokenContribute,
    name: projectName || projectNameContribute,
    slug: projectSlug || projectSlugContribute,
    goalType: goalType || goalTypeContribute,
    categories: (
      (projectCategoriesName || projectCategoriesNameContribute)?.split(', ') ||
      []
    ).slice(0, 3),
  }

  const cart = useSelector(getCart)
  const ecommerce = {
    cart: cart,
    totalAmount: useSelector(getTotalAmount),
    totalCurrency: useSelector(getTotalCurrency),
  }
  if (eventName === 'purchase') {
    ecommerce['transaction_id'] = cart.id
  }

  useEffect(() => {
    if (loading) return

    // Legacy dataLayer
    const pageType = isCurrentUserOwner ? 'mon projet' : 'projet'
    const pageSubtype = isCurrentUserOwner ? { subtype: 'state' } : {}

    const trackedData = {
      event: eventName,
      page: {
        type: pageType,
        ...pageSubtype,
      },
    }

    trackedData['user_data'] = FacebookPixelHelper.getUserData(currentUser)

    if (projectData['uuid']) {
      trackedData['project_data'] =
        FacebookPixelHelper.getProjectData(projectData)
    }

    if (
      eventName === 'add_to_cart' ||
      eventName === 'begin_checkout' ||
      eventName === 'purchase'
    ) {
      trackedData['ecommerce'] = FacebookPixelHelper.getEcommerceData(ecommerce)
    }

    FacebookPixelHelper.pushToGtm(dataLayer, trackedData)
  }, [loading, eventName, currentUser, isCurrentUserOwner])

  return null
}

export default FacebookPixelTracker
