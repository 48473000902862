import { createSelector } from '@reduxjs/toolkit'

export const NAME = 'HOME'
export const PRICING_SECTION = 'pricing-section'

export const getSlices = (state) => {
  return state[NAME]?.slices || {}
}

export const getPopularProjects = createSelector(
  (state) => state[NAME]?.popularProjects?.edges || [],
  (edges) => edges.map(({ node }) => node),
)

export const getSelectedProjects = createSelector(
  (state) => state[NAME]?.selectedProjects?.edges || [],
  (edges) => edges.map(({ node }) => node),
)

export const getCultureEngagementProjects = createSelector(
  (state) => state[NAME]?.cultureEngagementProjects?.edges || [],
  (edges) => edges.map(({ node }) => node),
)

export const getAgainstDiscriminationsEngagementProjects = createSelector(
  (state) => state[NAME]?.againstDiscriminationsEngagementProjects?.edges || [],
  (edges) => edges.map(({ node }) => node),
)

export const getEnvironmentEngagementProjects = createSelector(
  (state) => state[NAME]?.environmentEngagementProjects?.edges || [],
  (edges) => edges.map(({ node }) => node),
)

export const getPartnerships = (state) => {
  return state[NAME]?.partnerships || []
}

export const getSuccessfulProjectsCount = (state) => {
  return state[NAME]?.stats?.successfulProjectsCount || 0
}

export const getNewsSlices = createSelector([getSlices], (slices) => {
  return slices.homepage_news
})

export const getSeoSlice = createSelector([getSlices], (slices) => {
  return slices.homepage_seo[0]
})
