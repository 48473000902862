import React, { useEffect, useState } from 'react'
import { ErrorMessage, useField } from 'formik'
import getOr from 'lodash/fp/getOr'
import flow from 'lodash/fp/flow'
import first from 'lodash/fp/first'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Marger, Field } from '@kisskissbankbank/kitten'
import { useMutation } from 'kiss/api/graphql-query'
import UploadDocumentMutation from 'kiss/graphql/mutations/papers_please/upload_document_mutation.graphql'
import KycUploader from '../kycUploader'

const DOCUMENT_STATUS = {
  EMPTY: 'empty',
  UPLOADED: 'uploaded',
  SENT_TO_THE_PLATFORM: 'sent_to_the_platform',
  SENT_TO_THE_PROVIDER: 'sent_to_the_provider',
  ACCEPTED_BY_PLATEFORM: 'accepted_by_platform',
  ACCEPTED_BY_PROVIDER: 'accepted_by_provider',
  REJECTED_BY_PLATFORM: 'rejected_by_platform',
  REJECTED_BY_PROVIDER: 'rejected_by_provider',
  PROVIDER_UPLOAD_FAILED: 'provider_upload_failed',
}

const IdentityCard = ({ identity }) => {
  const document = flow(getOr([])('naturalStack.documents'), first)(identity)
  const [, , helpers] = useField('documentState')
  const t = useTranslation()

  const getUploaderStatusFromDocument = (document) => {
    const filesName = document?.pages
      .map((file) => file.filename)
      .filter((filename) => filename !== null)

    switch (document.state) {
      case DOCUMENT_STATUS.EMPTY:
      case DOCUMENT_STATUS.UPLOADED:
      case DOCUMENT_STATUS.PROVIDER_UPLOAD_FAILED:
        return {
          title: t('users.kyc.documents.identity_card.label'),
          description: t('users.kyc.documents.identity_card.info'),
          documentInfo: t('users.kyc.documents.identity_card.accepted_formats'),
          filesName,
          status: 'ready',
        }
      case DOCUMENT_STATUS.SENT_TO_THE_PLATFORM:
      case DOCUMENT_STATUS.SENT_TO_THE_PROVIDER:
        return {
          title: t('users.kyc.documents.identity_card.wait_title'),
          description: t('users.kyc.documents.identity_card.wait_description'),
          filesName,
          status: 'wait',
        }
      case DOCUMENT_STATUS.ACCEPTED_BY_PLATEFORM:
      case DOCUMENT_STATUS.ACCEPTED_BY_PROVIDER:
        return {
          title: t('users.kyc.documents.identity_card.valid'),
          filesName,
          status: 'accepted',
        }
      case DOCUMENT_STATUS.REJECTED_BY_PLATFORM:
      case DOCUMENT_STATUS.REJECTED_BY_PROVIDER:
        return {
          title: t('users.kyc.documents.identity_card.error.label'),
          description: t('users.kyc.documents.upload.replace'),
          rejectionReason: document.rejectionReason,
          filesName,
          status: 'denied',
        }
    }
  }

  const [identityCardDocuments, setIdentityCardDocuments] = useState(
    getUploaderStatusFromDocument(document),
  )

  useEffect(() => {
    setIdentityCardDocuments(getUploaderStatusFromDocument(document))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document])

  const [uploadDocument] = useMutation(UploadDocumentMutation)
  if (!identityCardDocuments) {
    return null
  }

  const onUpload = async (files) => {
    try {
      const { data } = await uploadDocument({
        variables: {
          slotId: document.slotId,
          files,
        },
      })
      const doc = getUploaderStatusFromDocument(data.uploadDocument)
      setIdentityCardDocuments(doc)
      helpers.setValue(doc.status)
    } catch (err) {
      setIdentityCardDocuments({ status: 'error' })
    }
  }

  const onRetryUpload = () => {
    const docUpdated = {
      title: t('users.kyc.documents.identity_card.label'),
      description: t('users.kyc.documents.identity_card.info'),
      documentInfo: t('users.kyc.documents.identity_card.accepted_formats'),
      filesName: [],
      status: 'ready',
    }
    setIdentityCardDocuments(docUpdated)
    helpers.setValue('empty')
  }

  return (
    <Marger bottom="3" key={document.id}>
      <KycUploader
        document={identityCardDocuments}
        onChange={onUpload}
        onRetry={onRetryUpload}
      />
      <ErrorMessage name="documentState">
        {(msg) => <Field.ErrorMessage>{msg}</Field.ErrorMessage>}
      </ErrorMessage>
    </Marger>
  )
}

export default IdentityCard
