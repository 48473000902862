import { gql } from '@apollo/client'
import {
  ContributionCard,
  useDeepCompareEffect,
} from '@kisskissbankbank/kitten'
import { useLazyQuery, useMutation } from 'kiss/api/graphql-query'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getProjectSlug } from 'kiss/modules/contribute/redux'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCartId, updateCart } from '../../../cart/redux'

const UPDATE_ORDER_LINE = gql`
  mutation UpdateOrderLine($id: ID!, $quantity: Int!) {
    updateOrderLine(id: $id, quantity: $quantity) {
      id
      quantity
      totalAmount {
        cents
        currency
      }
      itemAmount {
        cents
        currency
      }
    }
  }
`

const GET_FRESH_CART_DATA = gql`
  query GetFreshData($slug: String!, $cartId: ID) {
    project(slug: $slug) {
      id
      cart(id: $cartId) {
        id
        displayBillingAddress
        serviceFeeAmount {
          cents
          currency
        }
        orderTotal {
          cents
          currency
        }
        deliveryAmount {
          cents
          currency
        }
        redeemedAmount {
          cents
          currency
        }
        total {
          cents
          currency
        }
        lines {
          id
          deliveryModeCode
          quantity
          totalAmount {
            cents
            currency
          }
          variation {
            id
            remainingQuantity
            reward {
              id
              remaining
            }
          }
        }
      }
    }
  }
`

const QuantityManager = ({ quantity, orderLineId, hasNoStockLeft }) => {
  const [loading, isLoading] = useState(false)
  const dispatch = useDispatch()
  const t = useTranslation()
  const [updateOrderLine] = useMutation(UPDATE_ORDER_LINE, {
    id: orderLineId,
  })
  const cartId = useSelector(getCartId)
  const projectSlug = useSelector(getProjectSlug)
  const [updateFreshData, { data }] = useLazyQuery(GET_FRESH_CART_DATA, {
    variables: {
      slug: projectSlug,
      cartId,
    },
    fetchPolicy: 'network-only',
  })
  const updateQuantity = (quantity) => async () => {
    try {
      isLoading(true)
      await updateOrderLine({
        variables: { quantity },
      })
      await updateFreshData()
    } catch (e) {
      console.error(e)
    } finally {
      isLoading(false)
    }
  }
  useDeepCompareEffect(() => {
    if (!data) return
    dispatch(updateCart(data?.project?.cart))
  }, [data])
  return (
    <ContributionCard.PillNumber
      inputProps={{
        'aria-label': t('contribute.cart.quantity.aria_label.input'),
        value: quantity,
        disabled: loading,
      }}
      minusButtonProps={{
        disabled: quantity === 1 || loading,
        onClick: updateQuantity(quantity - 1),
        'aria-label': t('contribute.cart.quantity.aria_label.minus'),
      }}
      plusButtonProps={{
        disabled: hasNoStockLeft || loading,
        onClick: updateQuantity(quantity + 1),
        'aria-label': t('contribute.cart.quantity.aria_label.plus'),
      }}
    />
  )
}

export default QuantityManager
