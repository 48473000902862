import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'kiss/hooks/use-translation'
import { CommentForm as KittenCommentForm } from '@kisskissbankbank/kitten'
import {
  getCurrentUserImage,
  isLogged as isLoggedSelector,
} from 'kiss/session/redux'
import { imageDensityAttributes } from 'kiss/utils/responsive-image/image-density-attributes'
import PropTypes from 'prop-types'
import { getProjectSlug } from '../../page-state/selectors'
import { postNewsComment, updateNewsComment } from '../../campaign/news/redux'

const CommentForm = ({
  id,
  commentButtonLabel,
  commentUuid,
  commentUpdated,
  className,
  newsId,
  onSubmit,
  onClose,
}) => {
  const [isLoading, setLoading] = useState(false)
  const [formKey, setFormKey] = useState('')
  const t = useTranslation()
  const isLogged = useSelector(isLoggedSelector)
  const currentUserImage = useSelector(getCurrentUserImage)
  const dispatch = useDispatch()
  const slug = useSelector(getProjectSlug)

  if (!isLogged) return null

  const handleSubmit = async (text) => {
    setLoading(true)
    // Reset form
    setFormKey(new Date().getTime())
    try {
      dispatch(
        commentUpdated
          ? updateNewsComment({
              commentUuid: commentUpdated.uuid,
              text,
              slug,
            })
          : postNewsComment({
              commentUuid,
              text,
              newsId: commentUuid ? undefined : newsId,
              slug,
            }),
      )
      onSubmit()
    } catch (e) {
      // Do nothing
    }
    setLoading(false)
  }

  const avatarImgProps = imageDensityAttributes(currentUserImage.normalUrl, {
    imageWidth: 80,
  })

  return (
    <KittenCommentForm
      key={formKey}
      className={className}
      id={id}
      buttonText={commentButtonLabel || t('project_comment_form.button')}
      aria-label={
        commentUuid
          ? t('project.comments.news.form.reply.placeholder')
          : t('project.comments.news.form.placeholder')
      }
      placeholder={
        commentUuid
          ? t('project.comments.news.form.reply.placeholder')
          : t('project.comments.news.form.placeholder')
      }
      onSubmit={handleSubmit}
      avatarImgProps={avatarImgProps}
      disabled={isLoading}
      defaultValue={commentUpdated?.text}
      onClose={onClose}
      closeButtonText={t('project.comments.news.form.close_button.label')}
    />
  )
}

CommentForm.propTypes = {
  id: PropTypes.string,
  commentButtonLabel: PropTypes.string,
  commentUuid: PropTypes.string,
  newsId: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
}

CommentForm.defaultProps = {
  id: null,
  commentButtonLabel: null,
  commentUuid: null,
  newsId: null,
  onSubmit: () => {},
  onClose: undefined,
}

export default CommentForm
