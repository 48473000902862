import { pxToRem, Text } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import React from 'react'

const ExplainLine = ({ children, icon, className }) => {
  return (
    <div
      className={classNames('k-u-flex k-u-flex-alignItems-center', className)}
    >
      <div style={{ minWidth: pxToRem(36) }}>
        {React.createElement(icon, {
          height: 24,
          color: 'var(--color-grey-700)',
          secondaryColor: 'var(--color-grey-700)',
          'aria-hidden': true,
        })}
      </div>
      {children}
    </div>
  )
}

ExplainLine.Text = ({ size = 'small', children }) => (
  <Text tag="div" size={size} weight="400" cssColor="var(--color-grey-700)">
    {children}
  </Text>
)

ExplainLine.Text.displayName = 'ExplainLineText'

export default ExplainLine
