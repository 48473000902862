import React from 'react'
import { useSelector } from 'react-redux'
import { Button, FlexWrapper, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  hasEngagements as hasEngagementsSelector,
  getEngagements,
} from 'kiss/modules/project-page/page-state/selectors'
import { getRouteFor, ENGAGEMENT_BROWSING, BROWSING } from 'kiss/routes/redux'

const OtherProjects = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  const hasEngagements = useSelector(hasEngagementsSelector)
  const engagements = useSelector(getEngagements)
  const mainEngagement =
    hasEngagements &&
    t(`engagement.${engagements[0].code}.name`, {
      parseHtml: true,
    })
  return (
    <FlexWrapper
      direction="row"
      gap={[30, 10]}
      className="kiss-Contributor__otherProjects k-u-flex-alignItems-center"
    >
      <Text size="medium" color="font1">
        {hasEngagements
          ? t('projects.contributor_panel.discover.other_projects', {
              parseHtml: true,
              category: mainEngagement,
            })
          : t('projects.contributor_panel.discover.other_projects_generic', {
              parseHtml: true,
            })}
      </Text>

      <Button
        as="a"
        modifier="helium"
        fit="content"
        mobileFit="fluid"
        href={
          hasEngagements
            ? routeFor(ENGAGEMENT_BROWSING, {
                engagement: engagements[0].code,
              })
            : routeFor(BROWSING)
        }
      >
        {t('projects.contributor_panel.discover.button')}
      </Button>
    </FlexWrapper>
  )
}

export default OtherProjects
