import { pxToRem, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import * as qs from 'neoqs'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useBrowsing from '../hooks/use-browsing'
import { getCategories } from '../redux'
import FocusTrap from 'focus-trap-react'
import FilterButton from './components/filter-button'
import Filter from './components/filter'
import styled from 'styled-components'

const FILTER_ID = 'category-button'

const ButtonsListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: ${pxToRem(900 - 2 * 20)};
  gap: ${pxToRem(2)} ${pxToRem(20)};

  .kiss-ButtonsList__button {
    height: ${pxToRem(30)};
    display: flex;
    align-items: center;
    gap: ${pxToRem(10)};

    &:hover,
    &:focus,
    &:active {
      font-weight: 500 !important;
    }

    span[aria-hidden] {
      min-width: ${pxToRem(20)};
      text-align: center;
    }
  }

  .kiss-ButtonsList__button--all {
    grid-column: 1 / span 4;
  }
`

const Category = (props) => {
  const [showFilter, setShowFilter] = useState(false)
  const t = useTranslation()
  const categories = useSelector(getCategories)
  const { updateSearch } = useBrowsing()
  const { search } = useLocation()
  const { category } = qs.parse(search, { ignoreQueryPrefix: true })

  const handleButtonClick = () => setShowFilter((current) => !current)
  const handleFilterClose = () => setShowFilter(false)

  return (
    <FocusTrap
      active={showFilter}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <div style={{ position: 'relative' }} {...props}>
        <FilterButton
          id={FILTER_ID}
          onClick={handleButtonClick}
          showFilter={showFilter}
        >
          {category === 'all' || !category ? (
            <span>{t('browsing.categories.all')}</span>
          ) : (
            <>
              <span aria-hidden>
                {t(`browsing.categories.${category}.logo`)}
              </span>{' '}
              {t(`browsing.categories.${category}.name`)}
            </>
          )}
        </FilterButton>

        {showFilter && (
          <Filter onFilterClose={handleFilterClose}>
            <ButtonsListWrapper>
              <Text
                as="button"
                size="small"
                className="k-u-reset-button kiss-ButtonsList__button kiss-ButtonsList__button--all"
                onClick={() => {
                  updateSearch({ category: 'all' })
                  handleFilterClose()
                }}
              >
                {t('browsing.categories.all')}
              </Text>
              {categories.map(({ slug }) => {
                return (
                  <Text
                    key={slug}
                    as="button"
                    size="small"
                    className="k-u-reset-button kiss-ButtonsList__button"
                    onClick={() => {
                      updateSearch({ category: slug })
                      handleFilterClose()
                    }}
                    weight={slug === category ? '500' : '400'}
                  >
                    <span aria-hidden>
                      {t(`browsing.categories.${slug}.logo`)}
                    </span>{' '}
                    {t(`browsing.categories.${slug}.name`)}
                  </Text>
                )
              })}
            </ButtonsListWrapper>
          </Filter>
        )}
      </div>
    </FocusTrap>
  )
}

export default Category
