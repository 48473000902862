import React, { Component, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentUserId } from 'kiss/session/redux'
import { useTranslation } from '../../hooks/use-translation'
import { sentryCaptureException } from '../sentry'
import Dialog from 'kiss/app/modal/dialog'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: undefined, errorInfo: undefined }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, error, errorInfo })
  }

  render() {
    const { children } = this.props
    const { hasError, error, errorInfo } = this.state
    if (hasError) {
      return <ErrorModal error={error} errorInfo={errorInfo} />
    }
    return children
  }
}

const ErrorModal = ({ error, errorInfo }) => {
  const t = useTranslation()
  const currentUserId = useSelector(getCurrentUserId)
  useEffect(() => {
    sentryCaptureException(new Error(error), {
      tags: {
        query: 'error-boundary',
      },
      user: {
        id: currentUserId,
      },
      extra: {
        errorInfo,
      },
    })
  }, [])

  return (
    <Dialog
      isOpen
      data-test-id="error-boundary-modal"
      title={t('app.error_boundary.title')}
      content={t('app.error_boundary.message')}
      secondaryActionProps={{
        as: 'a',
        href: '/',
        onClick: () => {},
      }}
      secondaryActionText={t('app.error_boundary.button.homepage')}
      primaryAction={() => window.location.reload(true)}
      primaryActionText={t('app.error_boundary.button.reload')}
    />
  )
}

export default ErrorBoundary
