import { gql } from '@apollo/client'
import { parse } from 'date-fns'
import getOr from 'lodash/fp/getOr'
import map from 'lodash/fp/map'
import { useSelector } from 'react-redux'
import { useLazyQuery } from '../../../../api/graphql-query'
import { getProjectId } from '../../page-state/selectors'

const USER_CONTRIBUTIONS_QUERY = gql`
  query GetContributions($slug: String, $projectId: String) {
    user(slug: $slug) {
      ordersConnection(
        orderStatus: [confirmed, refunded, cannot_be_fulfilled]
        isSubscription: false
        projectId: $projectId
      ) {
        edges {
          node {
            id
            disbursed
            reference
            validationDate
            orderStatus
            paymentProviderCode
            userNeedsIdentification
            editable
            payment {
              amount {
                cents
              }
            }
            orderLines: linesConnection {
              edges {
                __typename
                node {
                  __typename
                  id
                  itemAmount {
                    __typename
                    cents
                    currency
                  }
                  deliveryModeCode
                  variation {
                    __typename
                    id
                    label
                    reward {
                      __typename
                      id
                      label
                      shippingDate {
                        __typename
                        month
                        year
                      }
                      description
                      image {
                        __typename
                        url(size: cropped)
                        thumbUrl: url(size: thumb)
                        normalUrl: url(size: normal)
                        filename
                      }
                      price {
                        __typename
                        cents
                        currency
                      }
                    }
                  }
                }
              }
            }
            fiscalReceiptsConnection {
              edges {
                node {
                  id
                  url
                }
              }
            }
            contribution {
              id
              backer {
                id
                firstName
                lastName
                username
                email
              }
              project {
                id
                name
                slug
                state
                campaignEndAt
                suspendedAt
                image {
                  url(size: widget)
                  largeUrl: url(size: large)
                  filename
                }
                organization {
                  name
                  avatarImage {
                    url(size: thumb)
                  }
                  contact {
                    slug
                  }
                }
                owner {
                  username
                  slug
                  avatarImage {
                    url(size: thumb)
                  }
                }
              }
            }
            totalAmount {
              cents
              currency
            }
            deliveryModes
            trackingStatus
            orderStatus
            paymentProviderCode
            totalAmount {
              __typename
              cents
              currency
            }
            donationAmount {
              __typename
              cents
              currency
            }
            tipAmount {
              __typename
              cents
              currency
            }
            deliveryAmount {
              __typename
              cents
              currency
            }
            shippingAddress {
              __typename
              firstName
              lastName
              address1
              address2
              postalCode
              city
              countryCode
            }
          }
        }
      }
    }
  }
`

const orderLinesFormatted = map((order) => {
  let orderLines = getOr([])('edges')(order.orderLines).map((orderLine) => {
    const year = orderLine?.node?.variation?.reward.shippingDate?.year || null
    const month = orderLine?.node?.variation?.reward.shippingDate?.month
    return {
      ...orderLine?.node?.variation?.reward,
      option: orderLine?.node?.variation?.label,
      deliveryModeCode: orderLine?.node?.deliveryModeCode,
      itemAmount: orderLine?.node?.itemAmount,
      shippingDate:
        year && month ? parse(`${year}-${month}`, 'yyyy-M', new Date()) : null,
    }
  })

  return {
    ...order,
    projectName: order.contribution.project.name,
    projectSlug: order.contribution.project.slug,
    projectImage: order.contribution.project.image,
    projectState: order.contribution.project.state,
    projectCampaignEndAt: order.contribution.project.campaignEndAt,
    projectSuspendedAt: order.contribution.project.suspendedAt,
    orderLines: orderLines,
  }
})

const useContributions = () => {
  const projectId = useSelector(getProjectId)
  const [getContributions, { data, loading }] = useLazyQuery(
    USER_CONTRIBUTIONS_QUERY,
  )

  const contributionsOrders =
    data?.user?.ordersConnection?.edges
      ?.map(({ node }) => node)
      ?.filter((order) => !!order.contribution) ?? []

  return {
    getContributions,
    contributionsOrdersFormatted: orderLinesFormatted(
      contributionsOrders,
    ).filter((order) => order.contribution.project.id === projectId),
    loading,
  }
}

export default useContributions
