import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, HOMEPAGE } from 'kiss/routes/redux'

import Dialog from 'kiss/app/modal/dialog'

const ExplicitPopover = ({ explicit }) => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <Dialog
      isOpen
      size="large"
      title={t(`project.popover.explicit.title.${explicit}`)}
      content={t(`project.popover.explicit.content.${explicit}`)}
      secondaryActionText={t('project.popover.explicit.button.back')}
      secondaryAction={() => window.location(routeFor(HOMEPAGE))}
      secondaryActionProps={{
        as: 'a',
        href: '/',
        onClick: () => {},
      }}
      primaryActionText={t('project.popover.explicit.button.ok')}
      primaryAction={(close) => close()}
    />
  )
}

export default ExplicitPopover
