import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import { HOMEPAGE, getRouteFor } from 'kiss/routes/redux'

import {
  Paragraph,
  KissKissBankBankLogo,
  Title,
  mq,
} from '@kisskissbankbank/kitten'

import classNames from 'classnames'
import styled from 'styled-components'

const StyledMiniFooterHead = styled.div`
  background-color: var(--color-grey-100);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  @media ${mq.tablet} {
    flex-direction: row;
    justify-content: space-around;
    -webkit-box-align: start;
    align-items: start;
    flex-wrap: wrap;
  }

  @media ${mq.desktop} {
    flex-direction: row;
  }
`

const StyledBaselineText = styled.div`
  padding: 0 10px 10px 10px;

  @media ${mq.desktop} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    padding: 20px 0;
  }

  h2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0px 20px;
  }

  @media (min-width: 1079px) {
    h2 {
      font-size: 20px;
      font-weight: 700;
      margin: 0px;
    }
  }
`

const StyledContainerText = styled.div`
  align-self: center;
  padding-top: 10px;

  @media ${mq.tablet} {
    align-self: center;
    padding-top: 10px;
    display: flex;
    flex: 1 0 100%;
    justify-content: center;
  }

  @media ${mq.desktop} {
    padding: 20px 0 20px 20px;
  }
`

const StyledText = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: 'generalSans';
`

export const CreatedWith = (props) => {
  const t = useTranslation()

  const routeFor = useSelector(getRouteFor)

  return (
    <div
      className={classNames('k-Footer__createdWith', props.className)}
      {...props}
    >
      <StyledMiniFooterHead>
        <StyledContainerText>
          <StyledText>{t('layouts.footer.made_with')}</StyledText>
        </StyledContainerText>
        <div className="k-Footer__createdWith--logo">
          <a href={routeFor(HOMEPAGE)}>
            <KissKissBankBankLogo width="165" />
          </a>
        </div>

        <StyledBaselineText>
          <Title noMargin modifier="septenary" cssColor="var(--color-grey-900)">
            {t('layouts.footer.baseline.title', { parseHtml: true })}
          </Title>
          <Paragraph
            modifier="secondary"
            noMargin
            className="k-u-color-grey-900"
          >
            {t('layouts.footer.baseline.subtitle', { parseHtml: true })}
          </Paragraph>
        </StyledBaselineText>
      </StyledMiniFooterHead>
    </div>
  )
}
