import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import {
  isServerAllowed,
  urlBuilder,
} from 'kiss/utils/responsive-image-handler'
import { isSvg } from 'kiss/utils/validators'

import {
  getProject,
  isSubscribersCountHidden,
} from 'kiss/modules/project-page/page-state/selectors'
import RepresentativeInfo from './representative-info'
import {
  FlexWrapper,
  HeroLayout,
  mq,
  pxToRem,
  Text,
  Title,
  TYPOGRAPHY,
} from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledCounter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  .kiss-ProjectPage-hero-number {
    ${TYPOGRAPHY.fontStyles['600']}
    font-size: ${pxToRem(28)};
    letter-spacing: -0.015em;
  }

  @media ${mq.tabletAndDesktop} {
    .kiss-ProjectPage-hero-number {
      font-size: ${pxToRem(46)};
      letter-spacing: -0.02em;
    }
  }
`

const StyledAvatar = styled.img`
  --avatar-size: ${pxToRem(100)};

  @media ${mq.tabletAndDesktop} {
    --avatar-size: ${pxToRem(170)};
  }

  box-sizing: border-box;
  object-fit: cover;
  object-position: center;
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: var(--avatar-size);
  border: ${pxToRem(10)} solid var(--color-grey-000);
`

const imageSizes = [
  [320, 300],
  [385, 300],
  [420, 300],
  [640, 300],
  [768, 300],
  [1080, 500],
  [1280, 500],
  [1440, 500],
  [1920, 500],
]

const getBannerImageAttributes = (imageUrl) => {
  if (!imageUrl) return
  if (
    isSvg(imageUrl) ||
    !ENABLE_RESPONSIVE_IMAGES ||
    !isServerAllowed(imageUrl)
  ) {
    return { src: imageUrl, srcSet: null }
  }
  const filters = ['quality:70', 'format:jpg']

  const baseImage = imageSizes[0]
  const src = urlBuilder(imageUrl, {
    width: baseImage[0],
    height: baseImage[1],
    filters: filters,
    upscale: true,
  })

  const srcSet = imageSizes
    .reduce((sizeAcc, sizeCur) => {
      return sizeAcc.concat(
        `${urlBuilder(imageUrl, {
          width: sizeCur[0],
          height: sizeCur[1],
          filters: filters,
          upscale: true,
        })} ${sizeCur[0]}w`,
      )
    }, [])
    .join(', ')

  return { src, srcSet }
}

const Hero = ({ children }) => {
  const project = useSelector(getProject)
  const hideSubscribersCount = useSelector(isSubscribersCountHidden)

  const t = useTranslation()

  const bannerImageAttributes = getBannerImageAttributes(
    project.bannerImage.url,
  )

  const splitedshortDesc = project?.shortDesc.split('\n\n\n')
  const firstLine = splitedshortDesc[0]
  const remainingLines = splitedshortDesc?.slice(1)?.join('\n\n\n')

  return (
    <HeroLayout.Hero>
      <HeroLayout.Hero.Image>
        <img
          src={bannerImageAttributes?.src || project?.bannerImage?.url}
          srcSet={bannerImageAttributes?.srcSet || null}
          alt=""
          role="presentation"
        />
      </HeroLayout.Hero.Image>

      <HeroLayout.Hero.Block>
        <StyledAvatar
          src={
            project?.organization?.avatarImage?.url ||
            project?.owner?.avatarImage?.url
          }
          alt={project?.organization?.name || project?.owner?.name}
        />
      </HeroLayout.Hero.Block>

      <HeroLayout.Hero.Block>
        <FlexWrapper className="k-u-flex-alignItems-center" gap={30}>
          <Title noMargin modifier="primary">
            {project?.name}
          </Title>
          <div className="kiss-Intro__owner">
            <RepresentativeInfo />
          </div>
          <div>
            <Text lineHeight="1.3" tag="p" size="giant">
              {firstLine}
            </Text>
            {remainingLines && (
              <Text lineHeight="1.3" tag="p" size="giant">
                {remainingLines}
              </Text>
            )}
          </div>
          {!hideSubscribersCount && (
            <StyledCounter>
              <span className="kiss-ProjectPage-hero-number">
                {project?.subscriptionsConnection?.totalCount || 0}
              </span>
              <Text
                tag="h3"
                weight="600"
                transform="uppercase"
                size="small"
                className="k-u-letter-spacing-10 k-u-margin-top-noneHalf"
              >
                {t(
                  project?.subscriptionsConnection?.totalCount > 1
                    ? 'permanent_project.text.subscribers'
                    : 'permanent_project.text.subscriber',
                )}
              </Text>
            </StyledCounter>
          )}
          {children}
        </FlexWrapper>
      </HeroLayout.Hero.Block>
    </HeroLayout.Hero>
  )
}

export default Hero
