import {
  HorizontalStroke,
  Paragraph,
  pxToRem,
  ScreenConfig,
  Title,
} from '@kisskissbankbank/kitten'
import React from 'react'
import styled from 'styled-components'

const StyledMarger = styled.div`
  margin-top: ${pxToRem(30)};

  @media (min-width: ${ScreenConfig.S.min}px) {
    margin-top: 0;
  }

  @media (min-width: ${ScreenConfig.L.min}px) {
    margin-top: ${pxToRem(100)};
  }
`

const TipSimpleCarousel = ({ title, paragraph }) => {
  return (
    <StyledMarger>
      <div className="k-u-margin-bottom-double">
        <Title modifier="quinary" noMargin tag="h3" family="antiqueolive">
          {title}
        </Title>
      </div>

      <div className="k-u-margin-top-double k-u-margin-bottom-triple">
        <HorizontalStroke style={{ width: 40 }} />
      </div>

      <div className="k-u-margin-top-triple">
        <Paragraph modifier="tertiary" noMargin>
          {paragraph}
        </Paragraph>
      </div>
    </StyledMarger>
  )
}

export default TipSimpleCarousel
