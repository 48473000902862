import React from 'react'
import Form from './components/form'
import List from './components/list'
import LoadMore from './components/load-more'
import NoResult from './components/no-result'
import { Container, Grid, GridCol } from '@kisskissbankbank/kitten'
import { CloseReplyFormsProvider } from 'kiss/modules/project-page/common/contexts/CloseReplyForms'

const Comments = () => (
  <Container>
    <Grid>
      <GridCol col-l="6" offset-l="3">
        <Form className="k-u-margin-bottom-double" />
        <CloseReplyFormsProvider>
          <List />
        </CloseReplyFormsProvider>
        <LoadMore />
        <NoResult />
      </GridCol>
    </Grid>
  </Container>
)

export default Comments
