import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import { getRouteFor, ORGANIZATION_PROFILE_PUBLIC } from 'kiss/routes/redux'
import { getOrganization } from 'kiss/modules/project-page/page-state/selectors'

import isEmpty from 'lodash/fp/isEmpty'

import { ArrowIcon, DropdownMenu } from '@kisskissbankbank/kitten'
import Avatar from './avatar'

const RepresentativeInfo = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const isOrganization = useSelector(getOrganization)

  if (isEmpty(isOrganization)) {
    return null
  }

  return (
    <>
      <div className="k-u-flex k-u-flex-gap-single k-u-flex-alignItems-center">
        <DropdownMenu
          menuPosition="right"
          expandButton={false}
          button={({ open }) => (
            <Avatar>
              <ArrowIcon direction={open ? 'top' : 'bottom'} />
            </Avatar>
          )}
        >
          {!isEmpty(isOrganization) && (
            <DropdownMenu.Link
              href={routeFor(ORGANIZATION_PROFILE_PUBLIC, {
                slug: isOrganization?.slug,
              })}
            >
              {t('project_hero.project.view_owner_profile')}
            </DropdownMenu.Link>
          )}
        </DropdownMenu>
      </div>
    </>
  )
}

export default RepresentativeInfo
