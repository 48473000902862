import {
  CONTAINER_MAX_WIDTH,
  Grid,
  pxToRem,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${ScreenConfig.L.min}px) {
    flex-direction: row;
  }
`

export const StickyContent = styled.div`
  display: flex;
  flex: 1;

  @media (min-width: ${ScreenConfig.L.min}px) {
    height: 100vh;
    position: sticky;
    top: 0;
  }
`

export const AsideContainer = styled.div`
  flex: 1;
  max-width: 100vw;

  @media (min-width: ${ScreenConfig.L.min}px) {
    /* Prevent Carousel from extending this div */
    max-width: 50vw;
  }
`

export const StyledGrid = styled(Grid)`
  margin: 0 ${pxToRem(10)};

  @media (min-width: ${ScreenConfig.S.min}px) {
    margin: 0 ${pxToRem(30)};
  }

  @media (min-width: ${ScreenConfig.L.min}px) {
    margin: 0;
    max-width: ${CONTAINER_MAX_WIDTH / 2}px;
  }
`
