import React, { useState } from 'react'
import Label from 'kiss/components/formik/label'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Grid, GridCol, RadioSet } from '@kisskissbankbank/kitten'
import NaturalForm from './natural-form'
import LegalValidation from './legal-validation'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import first from 'lodash/fp/first'

const FormKyc = ({ identity, refetchData, isAdmin }) => {
  const t = useTranslation()
  const [type, setType] = useState(identity.legalType)
  const documentState = flow(
    getOr([])('naturalStack.documents'),
    first,
    getOr('')('state'),
  )(identity)
  return (
    <Grid className="k-u-margin-bottom-octuple">
      <GridCol col-m="12" col-l={isAdmin ? '12' : '6'}>
        <div className="k-u-margin-bottom-triple">
          <Label htmlFor="type">{t('users.kyc.form.type.label')}</Label>
          <RadioSet
            name="type"
            id="kyc-type-radio-button"
            disabled={documentState === 'accepted_by_platform'}
            onClick={(e) => {
              setType(e.target.value)
            }}
            items={[
              {
                value: 'natural',
                id: 'type-person',
                defaultChecked: identity.legalType === 'natural',
                text: t('users.kyc.form.type.person'),
              },
              {
                value: 'legal',
                id: 'type-entity',
                defaultChecked: identity.legalType !== 'natural',
                text: t('users.kyc.form.type.entity'),
              },
            ]}
          />
        </div>
        {type === 'legal' && <LegalValidation identity={identity} />}
        {type === 'natural' && (
          <NaturalForm identity={identity} refetchData={refetchData} />
        )}
      </GridCol>
    </Grid>
  )
}

export default FormKyc
