import getOr from 'lodash/fp/getOr'

const NAME = 'UPDATE_CARD_POLLING'
export const UPDATE_SUBSCRIPTION = `${NAME}/UPDATE_SUBSCRIPTION`

export const getSubscription = (state) =>
  getOr({})(`${NAME}.subscription`)(state)

export const getPaymentState = (state) =>
  getOr('')(`${NAME}.subscription.payment.state`)(state)

export const getErrorCode = (state) =>
  getOr(null)(`${NAME}.subscription.payment.errorCode`)(state)

const reducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION:
      return action.payload

    default:
      return state
  }
}

export default { [NAME]: reducer }
