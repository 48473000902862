import { ProjectCard } from '@kisskissbankbank/kitten'
import { getAssetFor } from 'kiss/assets/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { FUNNEL_STEPS, getRouteFor } from 'kiss/routes/redux'
import { isBare } from 'kiss/utils/project/state'
import { responsiveImageAttributes } from 'kiss/utils/responsive-image/responsive-image-attributes'
import React from 'react'
import { useSelector } from 'react-redux'
import CampaignLines from './campaign'
import { getProjectCardState, PROJECT_STATE } from './config'
import SubscriptionLines from './subscription'

const getImageAttributes = (imageUrl, responsiveImageOptions) => {
  if (!imageUrl || !responsiveImageOptions) return

  return responsiveImageAttributes(imageUrl, responsiveImageOptions)
}

const KissKissProjectCard = ({
  project,
  shouldLoadImages = true,
  stretch = true,
  className = '',
  children,
  responsiveImageOptions,
  forceDesktop = false,
  titleTag = 'h3',
  colorMode = 'light',
  hoverableTitle = false,
  ...props
}) => {
  const {
    name,
    id,
    publicUrl,
    image,
    state,
    inExtratime,
    loading,
    lastVisitedStep,
    isPermanent = false,
  } = project

  const imageAttributes = getImageAttributes(
    image?.croppedUrl,
    responsiveImageOptions,
  )

  const projectState = (function () {
    if (inExtratime) return PROJECT_STATE.EXTRATIME
    return PROJECT_STATE[state.toUpperCase()] || null
  })()
  const { status, sticker } = getProjectCardState(projectState)
  const assetFor = useSelector(getAssetFor)
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const hasVideos = () => image?.mp4Url || image?.webmUrl

  const funnelUrl = routeFor(FUNNEL_STEPS, {
    project: id,
    step: lastVisitedStep?.subStepCode,
  })
  const url = isBare(state) && lastVisitedStep ? funnelUrl : publicUrl

  if (loading || props.loading) {
    return (
      <ProjectCard loading aria-hidden>
        <ProjectCard.Title />
        <ProjectCard.Line />
        <ProjectCard.ItemsLine>
          <ProjectCard.Item />
          <ProjectCard.Item />
          <ProjectCard.Item />
        </ProjectCard.ItemsLine>
        <ProjectCard.Progress />
      </ProjectCard>
    )
  }

  return (
    <ProjectCard
      className={className}
      stretch={stretch}
      status={status}
      href={url}
      colorMode={colorMode}
      hoverableTitle={hoverableTitle}
      imageProps={{
        src: shouldLoadImages
          ? imageAttributes
            ? imageAttributes.src
            : image?.homepageUrl || image?.croppedUrl || image?.url
          : assetFor('default_16x10.svg'),
        srcSet: shouldLoadImages
          ? imageAttributes && imageAttributes.srcSet
          : null,
        sizes: shouldLoadImages
          ? imageAttributes && imageAttributes.sizes
          : null,
        alt: '',
        loading: 'lazy',
        role: 'presentation',
      }}
      videoSources={
        shouldLoadImages && hasVideos()
          ? [
              {
                type: 'video/webm',
                src: image?.webmUrl,
                key: 'image_video/webm',
              },
              {
                type: 'video/mp4',
                src: image?.mp4Url,
                key: 'image_video/mp4',
              },
            ]
          : []
      }
    >
      {sticker && (
        <ProjectCard.Sticker className="k-u-transform-capitalize k-u-hidden@xs-down">
          {t(sticker)}
        </ProjectCard.Sticker>
      )}
      <ProjectCard.Title tag={titleTag}>
        {name || t('app.project_card.no_title')}
      </ProjectCard.Title>

      {isPermanent ? (
        <SubscriptionLines project={project} forceDesktop={forceDesktop} />
      ) : (
        <CampaignLines project={project} forceDesktop={forceDesktop} />
      )}
      {children}
    </ProjectCard>
  )
}

export default KissKissProjectCard
