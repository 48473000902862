import {
  Button,
  Grid,
  GridCol,
  IconBadge,
  QuestionMarkIcon,
  Text,
  Toggletip,
} from '@kisskissbankbank/kitten'
import { Form, Formik } from 'formik'
import { addErrorAlert, removeErrorAlerts } from 'kiss/app/alerts/redux'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import Cvv from 'kiss/components/credit-card/cvv'
import Expiration from 'kiss/components/credit-card/expiration'
import Number from 'kiss/components/credit-card/number'
import Checkbox from 'kiss/components/formik/checkbox'
import { useTranslation } from 'kiss/hooks/use-translation'
import { TIMEOUT, useCampaignCartPayment, usePaymentErrors } from 'kiss/payment'
import { getCurrentUserId } from 'kiss/session/redux'
import { getRouteFor as getRouteForSelector, CGU } from 'kiss/routes/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCartId, getTotalAmount } from '../../../cart/redux'
import { getProjectId, updatePolling } from '../../../redux'

const CardForm = ({ formattedAmount }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const getRouteFor = useSelector(getRouteForSelector)
  const projectId = useSelector(getProjectId)
  const userId = useSelector(getCurrentUserId)
  const orderId = useSelector(getCartId)
  const amountCents = useSelector(getTotalAmount)
  const cartState = useSelector(getCartId)
  const {
    isTimeoutPaymentRequest,
    isPaylineError,
    getPaylineErrorMessage,
    sendPaymentErrorToSentry,
  } = usePaymentErrors()

  const { payCartWithCreditCard } = useCampaignCartPayment({
    orderId,
    userId,
    projectId,
    amountCents,
    cartState,
  })

  return (
    <Formik
      initialValues={{
        cardNumber: '',
        expiry: '',
        cvv: undefined,
        termsOfServiceAndPayment: false,
      }}
      onSubmit={async (values) => {
        dispatch(removeErrorAlerts())
        try {
          const creditCardUrl = await payCartWithCreditCard({
            cardNumber: values.cardNumber,
            cvv: values.cvv,
            expiry: values.expiry,
          })
          if (creditCardUrl && creditCardUrl !== TIMEOUT) {
            return RoutingHelper.redirect(creditCardUrl)
          }
          return dispatch(updatePolling(true))
        } catch (e) {
          if (isPaylineError(e)) {
            dispatch(
              addErrorAlert(getPaylineErrorMessage(e), {
                scroll: true,
              }),
            )
          } else if (isTimeoutPaymentRequest(e)) {
            dispatch(updatePolling(true))
          } else {
            dispatch(
              addErrorAlert(t('contribute.payment_choice.error'), {
                parseHtml: true,
                scroll: true,
              }),
            )
          }

          sendPaymentErrorToSentry(e)
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Text
              className="k-u-margin-bottom-triple"
              tag="div"
              cssColor="var(--color-primary-700)"
              size="micro"
              weight="400"
            >
              {t('contribute.payment_choice.subtitle.card')}
            </Text>
            <Grid>
              <GridCol col="12" col-m="6">
                <Number />
              </GridCol>
            </Grid>
            <Grid>
              <GridCol col="6" col-m="3">
                <Expiration />
              </GridCol>
              <GridCol col="6" col-m="3">
                <Cvv />
              </GridCol>
            </Grid>
            <Checkbox
              name="termsOfServiceAndPayment"
              validate={(value) => {
                if (value) return
                return t('contribute.payment_choice.cgu_required')
              }}
            >
              {t('contribute.payment_choice.cgu_campaign', {
                terms_url: getRouteFor(CGU),
                parseHtml: true,
              })}
            </Checkbox>

            {isSubmitting ? (
              <SubmitLoader fit="fluid" className="k-u-margin-top-double" />
            ) : (
              <Button
                modifier="helium"
                size="large"
                fit="fluid"
                type="submit"
                className="k-u-margin-top-double"
                data-test-id="credit-card-payment-validation"
              >
                {t('contribute.payment_choice.confirm', {
                  amount: formattedAmount,
                })}
              </Button>
            )}
            <div className="k-u-margin-top-double k-u-margin-bottom-triple k-u-align-center@s-down">
              <Text fontStyle="italic" size="small">
                {t('contribute.payment_choice.paypal_text')}
              </Text>
              <Toggletip
                targetElement={
                  <IconBadge size="small">
                    <QuestionMarkIcon width="12" height="12" />
                  </IconBadge>
                }
                className="k-u-margin-left-single"
              >
                <Text>{t('contribute.payment_choice.paypal_tooltip')}</Text>
              </Toggletip>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CardForm
