import React from 'react'
import styled from 'styled-components'
import { gql } from '@apollo/client'
import { useQuery } from 'kiss/api/graphql-query'
import {
  ButtonImage,
  pxToRem,
  Text,
  ScreenConfig,
} from '@kisskissbankbank/kitten'

const Avatar = styled(ButtonImage)`
  border-radius: 0;
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  border: ${pxToRem(3)} solid var(--color-grey-000);

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    width: ${pxToRem(120)};
    height: ${pxToRem(120)};
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -${pxToRem(70)};
  margin-bottom: ${pxToRem(30)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    margin-top: -${pxToRem(100)};
  }
`

const GET_CHALLENGE_QUERY = gql`
  query GetChallenge($slug: String!) {
    challenge(slug: $slug) {
      name
      image {
        url
      }
    }
  }
`

const Challenge = ({ challenge }) => {
  const { data, loading } = useQuery(GET_CHALLENGE_QUERY, {
    slug: challenge,
  })
  const challengeName = data?.challenge?.name
  const challengeImage = data?.challenge?.image?.url
  if (loading || !challengeName) return null
  return (
    <Wrapper>
      <Avatar tag="span" img={{ src: challengeImage }} withoutPointerEvents />
      <Text
        weight="400"
        size="small"
        color="font1"
        className="k-u-margin-top-single"
      >
        {challengeName}
      </Text>
    </Wrapper>
  )
}

export default Challenge
