import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getRouteFor, MENTOR_PAGE_EDITION } from 'kiss/routes/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { EditIcon, Button } from '@kisskissbankbank/kitten'

const MentorButton = () => {
  const t = useTranslation()
  const history = useHistory()
  const { user: mentorSlug } = useParams()

  const routeFor = useSelector(getRouteFor)

  return (
    <Button
      as="a"
      size="small"
      modifier="helium"
      rounded
      onClick={(e) => {
        e.preventDefault()
        history.push(routeFor(MENTOR_PAGE_EDITION, { mentor: mentorSlug }))
      }}
      href={routeFor(MENTOR_PAGE_EDITION, { mentor: mentorSlug })}
      fit="content"
      mobileFit="icon"
    >
      <EditIcon color="var(--color-grey-000)" aria-hidden />
      <span className="k-u-hidden@l-up k-u-a11y-visuallyHidden">
        {t('mentor_page.edition.button.edit')}
      </span>
      <span className="k-u-hidden@m-down">
        {t('mentor_page.edition.button.edit')}
      </span>
    </Button>
  )
}

export default MentorButton
