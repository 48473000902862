import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getProject } from 'kiss/modules/project-page/page-state/selectors'
import {
  FlexWrapper,
  FacebookButtonIcon,
  TwitterButtonIcon,
  InstagramButtonIcon,
  YoutubeButtonIcon,
  Text,
} from '@kisskissbankbank/kitten'

const Social = () => {
  const t = useTranslation()
  const project = useSelector(getProject)

  const { facebookUrl, twitterUrl, instagramUrl, youtubeUrl } = project || {}

  const hasNoSocialNetworks =
    !facebookUrl && !twitterUrl && !instagramUrl && !youtubeUrl

  if (hasNoSocialNetworks) return null

  return (
    <div>
      <Text
        tag="h3"
        size="micro"
        transform="uppercase"
        className="kiss-AsideTitle k-u-margin-top-none k-u-margin-bottom-single k-u-letter-spacing-10"
      >
        {t('permanent_project.social.networks.title')}
      </Text>
      <FlexWrapper direction="row" gap={10} className="k-u-margin-top-single">
        {facebookUrl && (
          <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
            <FacebookButtonIcon rounded size="small" />
          </a>
        )}

        {twitterUrl && (
          <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
            <TwitterButtonIcon rounded size="small" />
          </a>
        )}

        {instagramUrl && (
          <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
            <InstagramButtonIcon rounded size="small" />
          </a>
        )}
        {youtubeUrl && (
          <a href={youtubeUrl} target="_blank" rel="noopener noreferrer">
            <YoutubeButtonIcon rounded size="small" />
          </a>
        )}
      </FlexWrapper>
    </div>
  )
}

export default Social
