import { Modal } from '@kisskissbankbank/kitten'
import SubscriptionCard from 'kiss/components/cards/subscription-card'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  imageProps,
  StyledBlock,
} from 'kiss/modules/components/reward-modal/desktop'
import Action from 'kiss/modules/components/reward-modal/desktop/action'
import Form from 'kiss/modules/components/reward-modal/form'
import {
  getPageColors,
  getProjectState,
} from 'kiss/modules/project-page/page-state/selectors'
import { isNotPublished } from 'kiss/utils/project/state'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import FormContent from './form-content'

const DesktopModal = ({ reward, ...props }) => {
  const t = useTranslation()
  const pageColors = useSelector(getPageColors)

  const projectState = useSelector(getProjectState)
  const isProjectNotPublished = isNotPublished(projectState)

  return (
    <Modal isOpen closeButtonLabel={t('global.close')} size="large" {...props}>
      <StyledBlock style={pageColors}>
        <SubscriptionCard
          reward={reward}
          className="kiss-RewardModal__content__card"
          imageProps={imageProps}
          displayButton={false}
          truncateTitle={false}
        />
        <div className="kiss-RewardModal__content__form">
          <Form reward={reward}>
            {({ handleSubmit, isSubmitting }) => {
              return (
                <FormContent reward={reward}>
                  <Action
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    buttonText={t('project.modal.submit_subscription')}
                    disabled={isProjectNotPublished}
                  />
                </FormContent>
              )
            }}
          </Form>
        </div>
      </StyledBlock>
    </Modal>
  )
}

DesktopModal.propTypes = {
  reward: PropTypes.object.isRequired,
}

export default DesktopModal
