import {
  AlertBox,
  BankCardIconNext,
  ColorCrossCircleIconNext,
  CubeIconNext,
  pxToRem,
  Text,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  padding-bottom: ${pxToRem(10)};
  &:last-child {
    padding-bottom: ${pxToRem(0)};
  }
`

const SubscriptionHelper = ({ period, startDateText }) => {
  const t = useTranslation()

  const textHelper2 =
    period === 'monthly'
      ? t('project.modal.subscription_helper2_monthly', { parseHtml: true })
      : t('project.modal.subscription_helper2_quarterly', { parseHtml: true })

  return (
    <AlertBox
      status="disabled"
      displayIcon={false}
      size="large"
      className="k-u-margin-bottom-double"
    >
      <StyledDiv className="k-u-flex k-u-flex-gap-singleHalf k-u-flex-alignItems-center">
        <CubeIconNext
          color="var(--color-grey-700)"
          style={{ flex: '0 0 24px' }}
        />
        <Text size="small">
          {t('project.modal.subscription_helper1', {
            date: startDateText,
            parseHtml: true,
          })}
        </Text>
      </StyledDiv>
      <StyledDiv className="k-u-flex k-u-flex-gap-singleHalf k-u-flex-alignItems-center">
        <BankCardIconNext
          color="var(--color-grey-700)"
          style={{ flex: '0 0 24px' }}
        />
        <Text size="small">{textHelper2}</Text>
      </StyledDiv>
      <StyledDiv className="k-u-flex k-u-flex-gap-singleHalf k-u-flex-alignItems-center">
        <ColorCrossCircleIconNext
          color="var(--color-grey-700)"
          secondaryColor="var(--color-grey-700)"
          className="k-ColorSvg"
          style={{ flex: '0 0 24px' }}
        />
        <Text size="small">
          {t('project.modal.subscription_helper3', { parseHtml: true })}
        </Text>
      </StyledDiv>
    </AlertBox>
  )
}

export default SubscriptionHelper
