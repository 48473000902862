import { domElementHelper } from '@kisskissbankbank/kitten'
import config from 'kiss/config'
import { PROJECT_WIDGET } from 'kiss/routes/redux'
import { waitFor } from 'kiss/utils/wait-for'
import React, { createContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useRouteMatch } from 'react-router-dom'

export const StonlyContext = createContext({
  stonlyTracker: () => null,
  stonlyWidget: () => null,
  loading: true,
})

const StonlyProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (domElementHelper.canUseDom()) {
      waitFor('StonlyWidget', () => {
        setLoading(false)
      })
    }
  }, [])
  return (
    <>
      <Helmet>
        <script>{`var STONLY_WID = '${config[APP_ENV].stonly.id}';`}</script>
        <script>{`!(function (s, t, o, n, l, y, w, g) {
        s.StonlyWidget ||
          (((w = s.StonlyWidget = function () {
            w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
          }).queue = []),
          ((y = t.createElement(o)).async = !0),
          (g = new XMLHttpRequest()).open(
            'GET',
            n + 'version?v=' + Date.now(),
            !0
          ),
          (g.onreadystatechange = function () {
            4 === g.readyState &&
              ((y.src =
                n +
                'stonly-widget.js?v=' +
                (200 === g.status ? g.responseText : Date.now())),
              (l = t.getElementsByTagName(o)[0]).parentNode.insertBefore(y, l));
          }),
          g.send());
      })(window, document, 'script', 'https://stonly.com/js/widget/v2/')`}</script>
        <script>
          {`(function(s,t,o,n,l,y,_) {
            if (s.stonlyTrack) return;
            _ = s.stonlyTrack = function() {
            _._api ? _._api.apply(_, arguments) : _.queue.push(arguments);
        };
            _.queue = [];
            y = t.createElement(o);
            y.async = !0;
            y.src = n;
            l = t.getElementsByTagName(o)[0];
            l.parentNode.insertBefore(y, l);
        })(window,document,'script','https://stonly.com/js/tracker/stn.js');
            stonlyTrack('init', '${config[APP_ENV].stonly.id}');`}
        </script>
      </Helmet>
      <StonlyContext.Provider
        value={{
          stonlyTracker: domElementHelper.canUseDom() && window?.stonlyTrack,
          stonlyWidget: domElementHelper.canUseDom() && window?.StonlyWidget,
          loading,
        }}
      >
        {children}
      </StonlyContext.Provider>
    </>
  )
}

const Provider = ({ children }) => {
  const widgetMatch = useRouteMatch(PROJECT_WIDGET)
  if (APP_ENV !== 'production') return children
  if (widgetMatch?.isExact) return children

  return <StonlyProvider>{children}</StonlyProvider>
}

export default Provider
