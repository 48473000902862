import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getRewardsHaloStatus } from 'kiss/modules/project-page/page-state/selectors'
import { hideRewardsHalo } from 'kiss/modules/project-page/page-state/actions'
import { HighlightHalo } from '@kisskissbankbank/kitten'

class RewardsHighlightHalo extends Component {
  render() {
    if (!this.props.rewardsHaloStatus) return null

    return (
      <HighlightHalo
        animationDelay={0.5}
        animationCycles={3}
        animationCycleDuration={3.5}
        onHaloAnimationEnd={this.props.hideRewardsHalo}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  rewardsHaloStatus: getRewardsHaloStatus(state),
})

const mapDispatchToProps = { hideRewardsHalo }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RewardsHighlightHalo)
