import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOrders,
  getLastProjectOrderCursor,
  getTotalOrders,
} from 'kiss/modules/project-page/page-state/selectors'
import { loadProjectBackers } from 'kiss/modules/project-page/page-state/actions'
import { useTranslation } from 'kiss/hooks/use-translation'
import BackersList from './components/backers-list'
import LoadMoreButton from 'kiss/components/project/button-load-more'
import {
  Container,
  Grid,
  GridCol,
  Paragraph,
  ScreenConfig,
} from '@kisskissbankbank/kitten'

const Backers = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const totalOrders = useSelector(getTotalOrders)
  const orders = useSelector(getOrders)
  const cursor = useSelector(getLastProjectOrderCursor)
  const [loadingMore, setLoadingMore] = useState(false)
  const [lastCursor, setLastCursor] = useState(cursor)
  if (lastCursor !== cursor) {
    setLastCursor(cursor)
    setLoadingMore(false)
  }

  useEffect(() => {
    dispatch(loadProjectBackers(lastCursor))
  }, [])

  const handleLoadMore = () => {
    setLoadingMore(true)
    dispatch(loadProjectBackers(lastCursor))
  }

  return (
    <Container>
      <Grid>
        <GridCol col-l="8" offset-l="2">
          {totalOrders !== 0 && (
            <StyledGrid>
              <BackersList orders={orders} />
            </StyledGrid>
          )}

          {totalOrders > orders.length && (
            <LoadMoreButton
              className="k-u-margin-top-quintuple"
              loading={loadingMore}
              label={t('project_backers.load_more')}
              onClick={handleLoadMore}
            />
          )}

          {totalOrders === 0 && (
            <Paragraph
              noMargin
              modifier="secondary"
              className="k-u-align-center"
            >
              {t('project_backers.no_result')}
            </Paragraph>
          )}
        </GridCol>
      </Grid>
    </Container>
  )
}

const StyledGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: ${ScreenConfig.M.max}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${ScreenConfig.S.max}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export default Backers
