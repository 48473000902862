import { getSSRHost } from 'kiss/app/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { ENGAGEMENT_BROWSING } from 'kiss/routes/redux'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import * as qs from 'neoqs'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

const Seo = ({ categories }) => {
  const t = useTranslation()
  const { search } = useLocation()
  const { engagement } = useParams()
  const host = useSelector(getSSRHost)
  const { category } = qs.parse(search, { ignoreQueryPrefix: true })
  const categoryName = flow(find({ slug: category }), get('name'))(categories)
  let title
  let description

  if (engagement) {
    title = t(`engagement.${engagement}.seo.title`)
    description = t(`engagement.${engagement}.seo.description`)
  } else if (categoryName) {
    title = t('browsing.page.title.with_category', { category: categoryName })
    description = t('browsing.page.description.with_category', {
      category: categoryName,
    })
  } else {
    title = t('browsing.page.title.default')
    description = t('browsing.page.description.default')
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {engagement && (
        <link
          rel="canonical"
          hrefLang="fr"
          href={`${host}${ENGAGEMENT_BROWSING.replace(':locale', 'fr').replace(
            ':engagement',
            engagement,
          )}`}
        />
      )}
      {engagement && (
        <link
          rel="alternate"
          hrefLang="en"
          href={`${host}${ENGAGEMENT_BROWSING.replace(':locale', 'en').replace(
            ':engagement',
            engagement,
          )}`}
        />
      )}
    </Helmet>
  )
}

export default Seo
