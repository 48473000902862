import { TagButton, Grid, GridCol } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { BROWSING, ENGAGEMENT_BROWSING, getRouteFor } from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { getEngagementsList } from './redux'

const MAIN_ENGAGEMENTS = [
  'independent-culture',
  'environment-and-biodiversity',
  'fight-against-discrimination',
]

const Engagements = ({ className = '' }) => {
  const { search } = useLocation()
  const t = useTranslation()
  const history = useHistory()
  const routeFor = useSelector(getRouteFor)
  const engagementsList = useSelector(getEngagementsList)
  const { engagement } = useParams()

  const mainEngagements = engagementsList.filter((engagement) =>
    MAIN_ENGAGEMENTS.includes(engagement.code),
  )
  const otherEngagements = engagementsList.filter(
    (engagement) => !MAIN_ENGAGEMENTS.includes(engagement.code),
  )

  return (
    <Grid className={className}>
      <GridCol col-l={10} offset-l={1}>
        <ul
          className="k-u-margin-top-triple
            k-u-flex
            k-u-flex-justifyContent-center
            k-u-flex-wrap-wrap
            k-u-flex-gap-single"
        >
          {mainEngagements.map(({ code }) => (
            <li key={code}>
              <TagButton
                rounded
                size="small"
                active={engagement === code}
                onClick={() => {
                  if (engagement === code) {
                    history.push(`${routeFor(BROWSING)}${search}`)
                  } else {
                    const route = routeFor(ENGAGEMENT_BROWSING, {
                      engagement: code,
                    })
                    history.push(`${route}${search}`)
                  }
                }}
              >
                {t(`engagement.${code}.name`, {
                  parseHtml: true,
                })}
              </TagButton>
            </li>
          ))}
        </ul>
        <ul
          className="k-u-flex
            k-u-flex-justifyContent-center
            k-u-flex-wrap-wrap
            k-u-flex-gap-single
            k-u-margin-top-single"
        >
          {otherEngagements.map(({ code }) => (
            <li key={code}>
              <TagButton
                rounded
                size="small"
                active={engagement === code}
                onClick={() => {
                  if (engagement === code) {
                    history.push(`${routeFor(BROWSING)}${search}`)
                  } else {
                    const route = routeFor(ENGAGEMENT_BROWSING, {
                      engagement: code,
                    })
                    history.push(`${route}${search}`)
                  }
                }}
              >
                {t(`engagement.${code}.name`, {
                  parseHtml: true,
                })}
              </TagButton>
            </li>
          ))}
        </ul>
      </GridCol>
    </Grid>
  )
}

export default Engagements
