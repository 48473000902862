import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'

import config from 'kiss/config'
import {
  getProjectName,
  getDescription,
  getDescriptionFunding,
  isPermanent,
  getTeamDescription,
} from 'kiss/modules/project-page/page-state/selectors'

import Social from './social'
import { Displayer } from '@kisskissbankbank/underwood'
import { Grid, GridCol, TitleWithStroke } from '@kisskissbankbank/kitten'

const SimpleDescription = () => {
  const t = useTranslation()
  const description = useSelector(getDescription)
  const permanent = useSelector(isPermanent)
  const descriptionFunding = useSelector(getDescriptionFunding)
  const title = useSelector(getProjectName)
  const teamDescription = useSelector(getTeamDescription)

  return (
    <>
      <div className="k-u-margin-bottom-quintuple">
        <TitleWithStroke tag="h2" modifier="tertiary">
          {title}
        </TitleWithStroke>

        <Displayer
          useRichTextStyle
          text={description}
          perfEnabled
          configResponsiveImageHandler={config[APP_ENV].responsiveImageHandler}
        />
        {teamDescription && (
          <Displayer
            useRichTextStyle
            text={teamDescription}
            perfEnabled
            configResponsiveImageHandler={
              config[APP_ENV].responsiveImageHandler
            }
          />
        )}
      </div>

      <Grid>
        <GridCol col="12" col-s="6">
          <div className="k-u-margin-bottom-quintuple">
            <Social />
          </div>
        </GridCol>
      </Grid>
      {!permanent && (
        <>
          <TitleWithStroke tag="h2" modifier="tertiary">
            {t('project_description.description_funding')}
          </TitleWithStroke>
          <div className="k-u-margin-vertical-quadruple">
            <Displayer
              useRichTextStyle
              text={descriptionFunding}
              perfEnabled
              configResponsiveImageHandler={
                config[APP_ENV].responsiveImageHandler
              }
            />
          </div>
        </>
      )}
    </>
  )
}

export default SimpleDescription
