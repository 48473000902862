import { gql } from '@apollo/client'
import { useQuery } from 'kiss/api/graphql-query'
import { useParams } from 'react-router'

const GET_CART = gql`
  query GetProject($slug: String!) {
    project(slug: $slug) {
      id
      cart {
        id
        startOnNextPeriod
        serviceFeeAmount {
          cents
          currency
        }
        amountToPay {
          cents
          currency
        }
        deliveryAmount {
          cents
          currency
        }
        linesAmount {
          cents
          currency
        }
        shippingAddress {
          countryCode
        }
        donationAmount {
          cents
          currency
        }
        tipAmount {
          cents
          currency
        }
        redeemedAmount {
          cents
          currency
        }
        giftCard {
          code
          remainingAmount {
            cents
            currency
          }
        }
        lines {
          id
          quantity
          variation {
            id
            reward {
              id
              label
              description
              subscribablePeriodLabel
              nextPeriodLabel
              image {
                url(size: thumb)
              }
            }
          }
        }
      }
    }
  }
`

const useCart = () => {
  const params = useParams()
  const { data, loading, refetch } = useQuery(GET_CART, {
    slug: params.project,
  })

  return {
    cart: data?.project?.cart ?? {},
    loading,
    refetch,
    projectId: data?.project?.id,
  }
}

export default useCart
