import React from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'kiss/hooks/use-translation'

import {
  EnvelopeIcon,
  FacebookIconNext,
  FlexWrapper,
  GridCol,
  MessengerIconNext,
  pxToRem,
  ScreenConfig,
  Text,
  WhatsappIconNext,
} from '@kisskissbankbank/kitten'

import config from 'kiss/config'

import {
  getOrganizationOrOwnerName,
  getProjectName,
  getProjectUrl,
} from 'kiss/modules/contribute/redux'
import { getAssetFor } from 'kiss/assets/redux'

import SocialButton from 'kiss/modules/contribute/thankyou/components/social-button'

const StyledSocialIcon = styled.img`
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`

const FlexWrapperContainer = styled(FlexWrapper)`
  align-items: center;
  gap: ${pxToRem(16)};
  ${(props) =>
    props.withPadding &&
    `
    padding: ${pxToRem(30)} 0 ${pxToRem(30)} 0;
    @media screen and (min-width: ${ScreenConfig.S.min}px) {
      padding: ${pxToRem(50)} 0;
  }
  `};
`

const FlexButtonsContainer = styled(FlexWrapper)`
  @media screen and (min-width: ${ScreenConfig.XS.min}px) {
    max-width: ${pxToRem(325)};
  }
`
const Social = ({ withPadding }) => {
  const t = useTranslation()
  const projectUrl = useSelector(getProjectUrl)
  const projectName = useSelector(getProjectName)
  const ownerName = useSelector(getOrganizationOrOwnerName)

  const assetFor = useSelector(getAssetFor)

  const encodedUrl = encodeURIComponent(projectUrl)
  const text = encodeURIComponent(
    t('project_details.social.share_msg', {
      project: projectName,
      owner: ownerName,
    }),
  )

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <GridCol offset-xs="0" col-m="10" offset-m="1" col-l="6" offset-l="3">
      <FlexWrapperContainer withPadding={withPadding}>
        <Text className="k-u-align-center">
          {t('contribute.thank_you.comment.social.title', {
            parseHtml: true,
          })}
        </Text>
        <FlexButtonsContainer direction="row" gap={10}>
          <SocialButton
            subject={t('contribute.thank_you.email.subject')}
            body={t('contribute.thank_you.email.body', {
              url: projectUrl,
              project: projectName,
            })}
          >
            <EnvelopeIcon width={22} height={22} />
          </SocialButton>
          <SocialButton
            name="Facebook"
            url={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
          >
            <FacebookIconNext />
          </SocialButton>
          <SocialButton
            name="Twitter"
            url={`https://twitter.com/intent/tweet/?url=${encodedUrl}&text=${text}&via=Kissbankers`}
          >
            <StyledSocialIcon
              src={assetFor('logos/LogoX.svg')}
              alt={'logos/LogoX.svg'}
            />
          </SocialButton>
          <SocialButton
            name="Whatsapp"
            url={`https://api.whatsapp.com/send?text=${text}%20${encodedUrl}`}
          >
            <WhatsappIconNext />
          </SocialButton>
          {isMobile ? (
            <SocialButton
              name="Messenger"
              url={`fb-messenger://share?link=${encodedUrl}&app_id=${config[APP_ENV].facebook.appId}`}
            >
              <MessengerIconNext />
            </SocialButton>
          ) : (
            <SocialButton
              name="Copy"
              customHandleClick={() => copyToClipboard(projectUrl)}
            >
              <StyledSocialIcon
                src={assetFor('illustrations/generique/Copy.svg')}
                alt={'illustrations/generique/Copy.svg'}
              />
            </SocialButton>
          )}
        </FlexButtonsContainer>
      </FlexWrapperContainer>
    </GridCol>
  )
}

export default Social
