import { ArrowIcon, Button, pxToRem } from '@kisskissbankbank/kitten'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import React from 'react'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  width: 100%;
  padding-inline: ${pxToRem(15)};

  &[aria-expanded='true'] {
    z-index: ${Z_INDEX.global.filterModal};
  }
`

const FilterButton = ({ children, showFilter, ...props }) => {
  return (
    <StyledButton
      type="button"
      size="small"
      aria-expanded={!!showFilter}
      active={!!showFilter}
      className="kiss-FilterButton"
      fit="fluid"
      modifier="nitrogen"
      {...props}
    >
      <span className="k-u-flex-grow-single k-u-align-left k-u-ellipsis">
        {children}
      </span>
      <ArrowIcon direction={showFilter ? 'top' : 'bottom'} />
    </StyledButton>
  )
}

export default FilterButton
