import {
  Label,
  RewardCard,
  Text,
  TextInputWithUnit,
} from '@kisskissbankbank/kitten'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getCartDonationAmount,
  getCurrency,
  isDonationDisabled as isDonationDisabledSelector,
} from 'kiss/modules/project-page/page-state/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

const SimpleDonationCard = ({
  handleSubmit,
  handleChange,
  handleBlur,
  handleFocus,
  errorState,
  errorMessage,
  isLoading,
  ...props
}) => {
  const t = useTranslation()
  const currency = useSelector(getCurrency)
  const isDonationDisabled = useSelector(isDonationDisabledSelector)

  const initialDonationAmount = useSelector(getCartDonationAmount)

  return (
    <RewardCard disabled={isDonationDisabled} {...props}>
      <RewardCard.Contents>
        <RewardCard.Title id="donation_card_title" className="k-u-weight-600">
          {t('donation_card.title')}
        </RewardCard.Title>

        <RewardCard.Form>
          <form id="donation-form" onSubmit={handleSubmit}>
            <Label
              htmlFor="donation-amount"
              className="k-u-margin-bottom-single"
            >
              {t('donation_card.input.label')}
            </Label>
            <TextInputWithUnit
              placeholder={t('donation_card.input.placeholder')}
              id="donation-amount"
              unit={t(`currency_symbol.${currency}`)}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              defaultValue={initialDonationAmount}
              type="number"
              autoComplete="off"
              error={errorState}
              disabled={isDonationDisabled}
            />
            {errorState && (
              <Text
                size="micro"
                color="error"
                weight="500"
                className="k-u-margin-top-single"
              >
                {errorMessage}
              </Text>
            )}
          </form>
        </RewardCard.Form>
      </RewardCard.Contents>

      {isLoading ? (
        <RewardCard.Button>
          <LoadingAnimation />
        </RewardCard.Button>
      ) : (
        <RewardCard.Button
          type="submit"
          form="donation-form"
          disabled={isDonationDisabled}
        >
          {t('donation_card.action')}
        </RewardCard.Button>
      )}
    </RewardCard>
  )
}

export default SimpleDonationCard
