import { Button } from '@kisskissbankbank/kitten'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import PropTypes from 'prop-types'
import React from 'react'

const Action = ({ isSubmitting, handleSubmit, buttonText, disabled }) => {
  return isSubmitting ? (
    <Button fit="fluid" modifier="helium" tag="span">
      <LoadingAnimation color="var(--color-grey-000)" />
    </Button>
  ) : (
    <Button
      fit="fluid"
      modifier="helium"
      type="submit"
      data-test-id="modal-submit-button"
      onClick={handleSubmit}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  )
}

Action.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

Action.defaultProps = {
  isSubmitting: false,
  disabled: false,
}

export default Action
