import React from 'react'
import PropTypes from 'prop-types'
import { Grid, GridCol } from '@kisskissbankbank/kitten'

const Panel = ({ children, ...props }) => (
  <Grid {...props}>
    <GridCol col-l="6" offset-l="3" className="k-u-align-center">
      {children}
    </GridCol>
  </Grid>
)

Panel.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Panel
