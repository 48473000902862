import {
  AvatarWithTextAndBadge,
  ButtonWithTooltip,
  EditIconNext,
  GiftIcon,
  HeaderMenu,
  HeaderNav,
  Text,
} from '@kisskissbankbank/kitten'
import { getAssetFor } from 'kiss/assets/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getMentorSlug } from 'kiss/modules/mentor-page/redux'
import {
  ADMIN,
  getRouteFor,
  GIFT_CARD,
  MENTOR_PAGE_PROJECTS_TAB,
  MESSAGING,
  USER_PROFILE,
  USER_PROFILE_CONTRIBUTIONS,
  USER_PROFILE_FAVORITES,
  USER_PROFILE_PROJECTS,
  UNREAD_CONVERSATIONS,
} from 'kiss/routes/redux'
import {
  getCurrentUserImage,
  getCurrentUserSlug,
  getCurrentUserUsername,
  getLastProjectUrl,
  isCurrentUserAdmin as isCurrentUserAdminSelector,
  isMentor,
  logout,
} from 'kiss/session/redux'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import MentorButton from './mentor-header-buttons'
import MessagingActions from './messaging'
import OrganizationManagerLink from './organization-manager-link'

const UserLoggedIn = ({ bonusComponent }) => {
  const t = useTranslation()
  const { user } = useParams()
  const currentUserImage = useSelector(getCurrentUserImage)
  const currentUserUsername = useSelector(getCurrentUserUsername)
  const currentUserSlug = useSelector(getCurrentUserSlug)
  const isCurrentUserAdmin = useSelector(isCurrentUserAdminSelector)
  const lastProjecturl = useSelector(getLastProjectUrl)

  const routeFor = useSelector(getRouteFor)
  const assetFor = useSelector(getAssetFor)
  const dispatch = useDispatch()

  const isSynapseEnabled = SYNAPSE_ENABLED
  const unreadConversationsRoute = routeFor(
    isCurrentUserAdmin && isSynapseEnabled ? MESSAGING : UNREAD_CONVERSATIONS,
  )
  const { pathname } = useLocation()
  const isPathMatching = (path) =>
    matchPath(pathname, {
      path: path,
      exact: true,
    })

  // mentor case
  const currentUserIsMentor = useSelector(isMentor)
  const isProfilePage = !!isPathMatching(USER_PROFILE)
  const mentorSlug = useSelector(getMentorSlug)
  const showEditionButton =
    (currentUserIsMentor || (isCurrentUserAdmin && user === mentorSlug)) &&
    isProfilePage

  const profileRoute = routeFor(USER_PROFILE, {
    user: currentUserSlug,
  })

  const myProjectsRoute = routeFor(USER_PROFILE_PROJECTS, {
    user: currentUserSlug,
  })

  const myFavoritesRoute = routeFor(USER_PROFILE_FAVORITES, {
    user: currentUserSlug,
  })

  const contributionsRoute = routeFor(USER_PROFILE_CONTRIBUTIONS, {
    user: currentUserSlug,
  })

  const mentorProjectsRoute = routeFor(MENTOR_PAGE_PROJECTS_TAB, {
    mentor: currentUserSlug,
  })

  const adminRoute = routeFor(ADMIN)
  const giftCardRoute = routeFor(GIFT_CARD)

  const handleLogoutClick = () =>
    dispatch(logout()).then(
      (response) => (location.href = response.redirectPath),
    )

  return (
    <HeaderNav.Logged>
      <ButtonWithTooltip
        tooltipText={t('layouts.header.gift_card')}
        buttonProps={{
          as: 'a',
          href: giftCardRoute,
          fit: 'icon',
          size: 'small',
          rounded: true,
          modifier: 'hydrogen',
        }}
        data-test-id="header-nav-gift-card-item"
        className="k-u-hidden@m-down"
      >
        <GiftIcon width={15} height={16} />
      </ButtonWithTooltip>
      <MessagingActions />
      {lastProjecturl && (
        <ButtonWithTooltip
          tooltipText={t('layouts.header.manage_project')}
          buttonProps={{
            as: 'a',
            href: lastProjecturl,
            fit: 'icon',
            size: 'small',
            rounded: true,
            modifier: 'helium',
          }}
          data-test-id="header-nav-manage-project-item"
        >
          <EditIconNext />
        </ButtonWithTooltip>
      )}
      {showEditionButton && <MentorButton />}

      {bonusComponent || null}

      <HeaderNav.UserMenu dropdownAnchorSide="right">
        <HeaderNav.UserMenu.Button
          backgroundColor="var(--color-grey-000)"
          backgroundColorHover="transparent"
          backgroundColorActive="transparent"
          color="var(--color-grey-900)"
          colorHover="var(--color-primary-500)"
          colorActive="var(--color-grey-900)"
        >
          <AvatarWithTextAndBadge>
            <AvatarWithTextAndBadge.Image
              alt=""
              src={
                currentUserImage
                  ? currentUserImage.url
                  : assetFor('default_square.svg')
              }
            />

            <AvatarWithTextAndBadge.Text
              className="k-u-hidden@xs-down"
              withEllipsisOverflow
            >
              <Text lineHeight="normal" weight="500">
                {currentUserUsername}
              </Text>
            </AvatarWithTextAndBadge.Text>
          </AvatarWithTextAndBadge>
        </HeaderNav.UserMenu.Button>

        <HeaderNav.UserMenu.Navigation>
          <HeaderMenu
            noBorder
            borderSide={false}
            backgroundColors={{
              hover: 'var(--color-grey-000)',
            }}
            position="right"
          >
            <HeaderMenu.Item
              href={profileRoute}
              isSelected={!!isPathMatching(profileRoute)}
            >
              {t('layouts.header.view_profile')}
            </HeaderMenu.Item>
            {currentUserIsMentor && (
              <HeaderMenu.Item
                href={mentorProjectsRoute}
                isSelected={!!isPathMatching(mentorProjectsRoute)}
              >
                {t('layouts.header.view_mentor_projects')}
              </HeaderMenu.Item>
            )}
            <OrganizationManagerLink />

            <HeaderMenu.Item
              href={contributionsRoute}
              isSelected={!!isPathMatching(contributionsRoute)}
            >
              {t('layouts.header.my_contributions')}
            </HeaderMenu.Item>
            <HeaderMenu.Item
              href={unreadConversationsRoute}
              isSelected={!!isPathMatching(unreadConversationsRoute)}
              className="k-u-hidden@l-up"
            >
              {t('layouts.header.my_messages')}
            </HeaderMenu.Item>
            <HeaderMenu.Item
              href={myProjectsRoute}
              isSelected={!!isPathMatching(myProjectsRoute)}
            >
              {t('layouts.header.my_projects')}
            </HeaderMenu.Item>
            <HeaderMenu.Item
              href={myFavoritesRoute}
              isSelected={!!isPathMatching(myFavoritesRoute)}
            >
              {t('layouts.header.my_favorites_projects')}
            </HeaderMenu.Item>

            {isCurrentUserAdmin && (
              <>
                <HeaderMenu.Item
                  href={adminRoute}
                  isSelected={!!isPathMatching(adminRoute)}
                >
                  {t('layouts.header.admin')}
                </HeaderMenu.Item>
              </>
            )}

            <HeaderMenu.Item
              onClick={handleLogoutClick}
              tag="button"
              type="button"
              data-test-id="header-user-menu-logout"
              className="k-u-background-color-background3"
            >
              {t('devise.shared.sign_out')}
            </HeaderMenu.Item>
          </HeaderMenu>
        </HeaderNav.UserMenu.Navigation>
      </HeaderNav.UserMenu>
    </HeaderNav.Logged>
  )
}

export default UserLoggedIn
