import Label from 'kiss/components/formik/label'
import RadioButtonSet from 'kiss/components/formik/radio-button-set'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const SubscriptionStarting = ({ reward }) => {
  const t = useTranslation()
  const isUserCanChooseNextPeriod =
    reward?.nextPeriodSubscribable && reward?.currentPeriodSubscribable

  const items = [
    {
      id: 'currentPeriod',
      value: false,
      text: t('project.modal.start_subscription.current_period'),
    },
    {
      id: 'nextPeriod',
      value: true,
      text: t(`project.modal.start_subscription.next_period_${reward.period}`, {
        nextPeriod: reward.nextPeriodLabel,
      }),
    },
  ]

  if (!isUserCanChooseNextPeriod) return null

  return (
    <div className="k-u-margin-bottom-double">
      <Label
        htmlFor="startOnNextPeriod"
        label={t('project.modal.start_subscription.label', { parseHtml: true })}
      />
      <RadioButtonSet
        name="startOnNextPeriod"
        size="small"
        items={items}
        controlled
      />
    </div>
  )
}

export default SubscriptionStarting
