import React from 'react'
import { useSelector } from 'react-redux'
import Info from './info'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getGoalCount,
  getCollectedCount,
} from 'kiss/modules/project-page/page-state/selectors'

const CollectedCountInfo = () => {
  const goalCount = useSelector(getGoalCount)
  const collectedCount = useSelector(getCollectedCount)

  const t = useTranslation()

  const goal = t(goalCount, { formatNumber: true })
  const text = t('project_info.funded_amount', { goal_with_currency: goal })
  const value = t('project_info.collected_count', {
    count: collectedCount,
    value: t(collectedCount, { formatNumber: true }),
    parseHtml: true,
  })

  return <Info text={text} value={value} />
}

export default CollectedCountInfo
