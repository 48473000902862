import query from 'kiss/api/graphql-query'
import { getLocale } from 'kiss/app/redux'
import projectsRequest from 'kiss/graphql/queries/search/projects_list.graphql'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import { ITEMS_PER_PAGE } from './context'

const NAME = 'SEARCH'

const UPDATE_PROJECTS = `${NAME}/UPDATE_PROJECTS`
const UPDATE_LOADING = `${NAME}/UPDATE_LOADING`

function reducer(state = { isLoading: false }, action) {
  switch (action.type) {
    case UPDATE_PROJECTS:
    case UPDATE_LOADING:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const updateLoading = (isLoading) => ({
  type: UPDATE_LOADING,
  payload: { isLoading },
})

export const fetchProjects = (searchParams) => async (dispatch, getState) => {
  const state = getState()
  const locale = getLocale(state)
  dispatch(updateLoading(true))
  const result = await query(
    projectsRequest,
    { ...searchParams, locale, itemsPerPage: ITEMS_PER_PAGE },
    state,
  )
  dispatch({
    type: UPDATE_PROJECTS,
    payload: { search: result.projectsSearch },
  })
  dispatch(updateLoading(false))
}

export const getCategories = (state) => get(`${NAME}.categories`)(state)
export const getProjects = (state) => get(`${NAME}.search.items`)(state)
export const getTotal = (state) => getOr(1)(`${NAME}.search.total`)(state)
export const isLoading = (state) => getOr(false)(`${NAME}.isLoading`)(state)

export default { [NAME]: reducer }
