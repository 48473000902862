import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Alert } from '@kisskissbankbank/kitten'
import {
  getProjectSlug,
  needToShowMentorModal as needToShowMentorModalSelector,
} from 'kiss/modules/project-page/page-state/selectors'
import { addMentor } from 'kiss/modules/project-page/page-state/actions'
import Dialog from 'kiss/app/modal/dialog'

const AlertMentor = () => {
  const t = useTranslation()
  const projectSlug = useSelector(getProjectSlug)
  const needToShowMentorModal = useSelector(needToShowMentorModalSelector)
  const dispatch = useDispatch()

  if (!needToShowMentorModal) return null

  return (
    <Alert closeButton>
      <span className="k-u-block k-u-margin-bottom-singleHalf">
        {t('maker.flash.mentor_project')}
      </span>
      <Dialog
        title={t('mentorships.mentor_project.confirmation')}
        primaryActionText={t('mentorships.mentor_project.yes_button')}
        primaryAction={(close) => {
          dispatch(addMentor(projectSlug, t('projects.flashes.mentored')))
          close()
        }}
        secondaryActionText={t('mentorships.mentor_project.cancel_button')}
        secondaryAction={(close) => close()}
      >
        <button type="button" className="k-u-link k-u-link-primary1">
          {t('maker.flash.button.mentor_project')}
        </button>
      </Dialog>
    </Alert>
  )
}

export default AlertMentor
