import React, { memo } from 'react'
import styled from 'styled-components'
import RichContentText from 'kiss/modules/components/rich-content/text'
import RichContentButton from 'kiss/modules/components/rich-content/button'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import {
  Container,
  Grid,
  GridCol,
  Marger,
  parseHtml,
  pxToRem,
  ScreenConfig,
  Title,
  TYPOGRAPHY,
  Video,
  RichText,
} from '@kisskissbankbank/kitten'
import getTitleId from 'kiss/utils/get-title-id'

const StyledGrid = styled(Grid)`
  align-items: center;

  .kiss-TextWithVideo__content {
    order: 1;

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      order: var(--order);
    }
  }

  .kiss-TextWithVideo__textWithIcon {
    display: flex;
    align-items: center;

    .kiss-TextWithVideo__icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${pxToRem(50)};
      height: ${pxToRem(50)};
      border-radius: var(--border-radius-rounded);
      box-sizing: border-box;
      background-color: var(--color-grey-000);
      border: var(--border);
      ${TYPOGRAPHY.fontStyles['500']};
      font-size: ${pxToRem(14)};
    }
  }
`

const TextWithVideo = ({
  videoPosition,
  title,
  description,
  callToAction,
  blockquoteBackgroundColor,
  blockquoteIconUrl,
  blockquoteContent,
  videoUrl,
}) => (
  <Container>
    <StyledGrid style={{ '--order': videoPosition === 'right' ? 0 : 1 }}>
      <GridCol
        col-xs="10"
        offset-xs="1"
        col-l="4"
        className="kiss-TextWithVideo__content"
      >
        {title.text && (
          <Marger top={{ default: 3, fromS: 4, fromL: 0 }} bottom="1">
            <Title
              id={getTitleId(title.text)}
              tag={`h${title.level}`}
              modifier="quaternary"
              noMargin
            >
              {title.text}
            </Title>
          </Marger>
        )}

        {description && (
          <Marger
            top={!title.text ? { default: 3, fromL: 0 } : null}
            bottom="2"
          >
            <RichContentText
              content={description}
              paragraphProps={{
                fontSizeDefault: 'tertiary',
                fontSizeFromS: null,
              }}
            />
          </Marger>
        )}

        {blockquoteContent[0] && blockquoteContent[0].html && (
          <Marger
            top={!description ? { default: 2, fromL: 0 } : null}
            bottom={{ default: 3, fromS: 4 }}
          >
            <div className="kiss-TextWithVideo__textWithIcon">
              <span
                className="kiss-TextWithVideo__icon"
                style={{
                  backgroundColor: `#${blockquoteBackgroundColor}`,
                  borderColor: `#${blockquoteBackgroundColor}`,
                }}
              >
                <img src={blockquoteIconUrl} alt="" />
              </span>

              <RichText>
                <p className="k-u-margin-left-singleHalf">
                  <em>
                    {parseHtml(blockquoteContent[0].html, { sanitize: false })}
                  </em>
                </p>
              </RichText>
            </div>
          </Marger>
        )}

        {callToAction.text && (
          <RichContentButton
            modifier="hydrogen"
            mobileFit="fluid"
            {...callToAction}
          />
        )}
      </GridCol>

      <GridCol col-xs="10" offset-xs="1" col-l="5">
        <div>
          <Video playsInline src={`${videoUrl}#t=0.1`}>
            <Video.Loader>
              <LoadingAnimation />
            </Video.Loader>
          </Video>
        </div>
      </GridCol>
    </StyledGrid>
  </Container>
)

export default memo(TextWithVideo)
