import React from 'react'

import { gql } from '@apollo/client'

import { useQuery } from 'kiss/api/graphql-query'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'

import { getRouteFor, VERCEL_ORGANIZATION_MANAGER } from 'kiss/routes/redux'
import config from 'kiss/config'

import { HeaderMenu } from '@kisskissbankbank/kitten'

const USER_QUERY = gql`
  query GetUserorganizationsAndProjects($states: [ProjectState]) {
    currentUser {
      id
      organizations {
        edges {
          node {
            id
            slug
            projects(states: $states) {
              edges {
                node {
                  id
                  slug
                  state
                }
              }
            }
          }
        }
      }
    }
  }
`

const WITH_MANAGER_STATES = [
  'accepted',
  'draft',
  'improve',
  'started',
  'successful',
  'failed',
]

const getFirstOrganization = (data) =>
  data?.currentUser?.organizations?.edges?.[0]

const getFirstOrganizationProject = (data) => {
  const firstOrganization = getFirstOrganization(data)
  return firstOrganization?.node?.projects?.edges?.[0]
}

const OrganizationManagerLink = () => {
  const { data } = useQuery(USER_QUERY, {
    states: WITH_MANAGER_STATES,
  })

  const routeFor = useSelector(getRouteFor)
  const t = useTranslation()
  const organization = getFirstOrganization(data)
  const project = getFirstOrganizationProject(data)
  const projectLink = project?.node?.slug || project?.node?.id
  const organizationLink = organization?.node?.slug

  const link = `${config[APP_ENV].manager.host}${routeFor(
    VERCEL_ORGANIZATION_MANAGER,
    {
      organization: organizationLink,
      project: projectLink,
    },
  )}`

  if (!organizationLink || !projectLink) return null

  return (
    <HeaderMenu.Item href={link} isSelected={false}>
      {t('layouts.header.organization.projects')}
    </HeaderMenu.Item>
  )
}

export default OrganizationManagerLink
