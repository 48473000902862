import React, { useEffect, useState } from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'

import { useDispatch, useSelector } from 'react-redux'
import { loadMoreCommentsForNews } from 'kiss/modules/project-page/campaign/news/redux'
import { getProjectSlug } from 'kiss/modules/project-page/page-state/selectors'
import { getNews } from 'kiss/modules/project-page/page-state/selectors'

import LoadMoreButton from 'kiss/components/project/button-load-more'

const LoadMore = ({ newsId, newsCommentsTotal, newsComments, lastCursor }) => {
  const t = useTranslation()

  const projectSlug = useSelector(getProjectSlug)
  const newscomments = useSelector(getNews)

  const dispatch = useDispatch()

  const [loadingMore, setLoadingMore] = useState(false)

  useEffect(() => {
    setLoadingMore(false)
  }, [newscomments])

  const handleLoadMore = () => {
    setLoadingMore(true)

    dispatch(
      loadMoreCommentsForNews({
        newsId: newsId,
        slug: projectSlug,
        lastCursor: lastCursor,
      }),
    )
  }

  if (newsCommentsTotal <= newsComments?.length) {
    return null
  }

  return (
    <LoadMoreButton
      className="k-u-margin-top-double k-u-margin-horizontal-auto k-u-block"
      loading={loadingMore}
      label={t('project_backers.load_more')}
      onClick={handleLoadMore}
    />
  )
}

export default LoadMore
