import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { ContributionCard } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  removeDonation,
  hasDonation as hasDonationSelector,
  getDonationAmount,
  getDonationCurrency,
} from '../../../cart/redux'
import { getProjectName } from '../../../redux'

const StyledAmount = styled(ContributionCard.Amount)`
  grid-column: span 2;
`

const RecurringDonation = ({ className }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const hasDonation = useSelector(hasDonationSelector)
  const donationAmount = useSelector(getDonationAmount)
  const donationCurrency = useSelector(getDonationCurrency)
  const projectName = useSelector(getProjectName)

  if (!hasDonation) return null

  const formattedAmount = t('permanent_project.text.cart.amount_monthly', {
    amount: t(donationAmount, {
      formatNumber: { decimal: 2 },
    }),
    currency: t(`currency_symbol.${donationCurrency}`),
    parseHtml: true,
  })

  return (
    <div className={className} data-test-id="donation-card">
      <ContributionCard onClose={() => dispatch(removeDonation())}>
        <ContributionCard.Title
          style={{ overflow: 'hidden', maxWidth: '100%' }}
        >
          <ContributionCard.Description className="k-u-margin-bottom-single">
            {t('contribute.cart.subscriptions.monthly_support_for', {
              parseHtml: true,
            })}
          </ContributionCard.Description>
          {projectName}
        </ContributionCard.Title>
        <StyledAmount>{formattedAmount}</StyledAmount>
      </ContributionCard>
    </div>
  )
}

export default RecurringDonation
