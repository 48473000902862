import { getSSRHost } from 'kiss/app/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getLanguages,
  getOrganizationOrOwnerName,
  getProjectImageCropped,
  getProjectName,
  getProjectSlug,
  getProjectState,
  getProjectVideo,
  getShortDesc,
  isIndexable as isIndexableSelector,
} from 'kiss/modules/project-page/page-state/selectors'
import {
  getRouteFor,
  PROJECT_NAVIGATION_ROUTE,
  PROJECT_ROUTE,
} from 'kiss/routes/redux'
import { isStarted } from 'kiss/utils/project/state'
import { urlBuilder } from 'kiss/utils/responsive-image-handler'
import React from 'react'
import { Helmet as ReactHelmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

const DAY = 86400
const PROJECT_TABS_WITH_META = ['news', 'backers', 'comments', 'faqs']

const Helmet = () => {
  const t = useTranslation()
  const {
    params: { item: tab },
  } = useRouteMatch(PROJECT_NAVIGATION_ROUTE)
  const routeFor = useSelector(getRouteFor)
  const host = useSelector(getSSRHost)
  const projectName = useSelector(getProjectName)
  const projectState = useSelector(getProjectState)
  const projectSlug = useSelector(getProjectSlug)
  const representativeName = useSelector(getOrganizationOrOwnerName)
  const shortDesc = useSelector(getShortDesc)
  const imageCropped = useSelector(getProjectImageCropped)
  const video = useSelector(getProjectVideo)
  const isIndexable = useSelector(isIndexableSelector)
  const languages = useSelector(getLanguages)
  const defaultLanguage = languages[0] || 'fr'

  const title = t('project.seo.title', {
    name: projectName,
    username: representativeName,
  })

  const description = PROJECT_TABS_WITH_META.includes(tab)
    ? t(`meta.projects.${tab}.description`, { title: projectName })
    : shortDesc

  const ogVideoProperty = video
    ? {
        property: 'og:video',
        content: video,
      }
    : {}

  const noIndexMeta = !isIndexable
    ? {
        name: 'robots',
        content: 'noindex, nofollow',
      }
    : {}

  const ogImageUrl = urlBuilder(imageCropped, {
    width: 1200,
    filters: ['quality:90', `format:jpeg`],
  })

  return (
    <ReactHelmet
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: shortDesc || '',
        },
        {
          property: 'og:ttl',
          content: isStarted(projectState) ? 4 * DAY : 28 * DAY,
        },
        {
          property: 'og:image',
          content: ogImageUrl,
        },
        {
          property: 'og:image:alt',
          content: projectName,
        },
        {
          property: 'og:image:width',
          content: 1200,
        },
        {
          property: 'og:image:height',
          content: 750,
        },
        ogVideoProperty,
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:description',
          content: shortDesc || '',
        },
        {
          property: 'twitter:image',
          content: ogImageUrl,
        },
        {
          property: 'twitter:image:alt',
          content: projectName,
        },
        {
          property: 'twitter:url',
          content: `${host}${routeFor(PROJECT_ROUTE, {
            project: projectSlug,
          })}`,
        },
        {
          property: 'twitter:site',
          content: '@Kissbankers',
        },
        noIndexMeta,
      ]}
      link={[
        {
          hreflang: 'fr',
          href: `${host}${routeFor(PROJECT_ROUTE, {
            project: projectSlug,
            locale: 'fr',
          })}`,
          rel: defaultLanguage === 'fr' ? 'canonical' : 'alternate',
        },
        {
          hreflang: 'en',
          href: `${host}${routeFor(PROJECT_ROUTE, {
            project: projectSlug,
            locale: 'en',
          })}`,
          rel: defaultLanguage === 'en' ? 'canonical' : 'alternate',
        },
      ]}
    />
  )
}

export default Helmet
