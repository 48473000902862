import {
  CheckedCircleIcon,
  ClockCircleIcon,
  HorizontalProjectCard,
  Text,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { differenceInDays } from 'date-fns'
import { getRouteFor, PROJECT_ROUTE } from 'kiss/routes/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from '../../../../hooks/use-translation'

const PROJECT_STATUS = {
  SUCCESSUL: 'successful',
  STARTED: 'started',
}

const Status = ({ state, endAt }) => {
  const t = useTranslation()

  if (state === PROJECT_STATUS.STARTED) {
    const difference = differenceInDays(new Date(endAt), new Date())
    let text = t('search.project.status.started')
    if (difference < 8)
      text = t('search.project.status.last_days', {
        parseHtml: true,
      })
    if (difference === 1)
      text = t('search.project.status.last_day', {
        parseHtml: true,
      })

    return (
      <>
        <ClockCircleIcon
          aria-hidden
          width="12"
          height="12"
          color="var(--color-grey-000)"
          bgColor="var(--color-primary-500)"
          className="k-u-margin-right-noneHalf"
        />
        <Text
          weight="500"
          size="micro"
          color={difference < 8 ? 'primary1' : 'font1'}
        >
          {text}
        </Text>
      </>
    )
  }

  return (
    <>
      <CheckedCircleIcon
        aria-hidden
        width="12"
        height="12"
        color="var(--color-grey-000)"
        bgColor="var(--color-success-500)"
        className="k-u-margin-right-noneHalf"
      />
      <Text weight="500" size="micro" color="font1">
        {t('search.project.status.successful')}
      </Text>
    </>
  )
}

const CampaignSeachResultCard = ({ project, hideOnMobile }) => {
  const {
    name,
    owner_username,
    funding_percent,
    avatar_url,
    state,
    end_at,
    slug,
  } = project
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <HorizontalProjectCard
      title={name}
      description={owner_username || ''}
      progress={funding_percent || 0}
      progressColor="var(--color-primary-500)"
      progressLabel={t('search.project.progress_label')}
      imageProps={{
        src: avatar_url,
      }}
      info={
        <div className="k-u-flex k-u-flex-alignItems-center">
          <Status state={state} endAt={end_at} />
        </div>
      }
      href={routeFor(PROJECT_ROUTE, {
        project: slug,
      })}
      className={classNames({
        'k-u-hidden@s-down': hideOnMobile,
      })}
    />
  )
}

export default CampaignSeachResultCard
