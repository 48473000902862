import {
  CONTAINER_PADDING,
  CONTAINER_PADDING_THIN,
  GUTTER,
  ScreenConfig,
} from '@kisskissbankbank/kitten'

const GUTTER_THIN = 10
const GUTTER_LARGE = 40

// Mobile-specific definitions
export const ONE_IN_XXS_CONTAINER = `100vw - ${CONTAINER_PADDING_THIN * 2}px`
export const TWO_IN_XXS_CONTAINER = `(100vw - ${
  CONTAINER_PADDING_THIN * 2
}px - ${GUTTER}px) / 2`

// Mobile-specific definitions with thin gutters
export const TWO_GUTTER_THIN_IN_XXS_CONTAINER = `(100vw - ${
  CONTAINER_PADDING_THIN * 2
}px - ${GUTTER_THIN}px) / 2`

// How many images in a full-length container
export const TWO_IN_CONTAINER = `(100vw - ${
  CONTAINER_PADDING * 2
}px - ${GUTTER}px) / 2`
export const THREE_IN_CONTAINER = `(100vw - ${CONTAINER_PADDING * 2}px - ${
  GUTTER * 2
}px) / 3`
export const FOUR_IN_CONTAINER = `(100vw - ${CONTAINER_PADDING * 2}px - ${
  GUTTER * 3
}px) / 4`

// How many images in a full-length container with large gutters
export const FOUR_GUTTER_LARGE_IN_CONTAINER = `(100vw - ${
  CONTAINER_PADDING * 2
}px - ${GUTTER_LARGE * 3}px) / 4`

// Fixed XL Container

// How many images in a full-length fixed container at XL size
export const FOUR_IN_XL_CONTAINER = `(${
  ScreenConfig.XL.min - CONTAINER_PADDING * 2
}px - ${GUTTER * 3}px) / 4`

// How many images in a full-length fixed container at XL size with large gutters
export const FOUR_GUTTER_LARGE_IN_XL_CONTAINER = `(${
  ScreenConfig.XL.min - CONTAINER_PADDING * 2
}px - ${GUTTER_LARGE * 3}px) / 4`
