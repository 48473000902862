import {
  Container,
  CONTAINER_MAX_WIDTH,
  CONTAINER_PADDING,
  Grid,
  GridCol,
  GUTTER,
  Marger,
  NUM_COLUMNS,
  pxToRem,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import {
  getProjectExtratimeState,
  hasChallenges as hasChallengesSelector,
  hasEngagements as hasEngagementsSelector,
  hasMentors as hasMentorsSelector,
  isFiscalReceiptsEnabled,
} from 'kiss/modules/project-page/page-state/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ProjectActions from './components/actions'
import Challenges from './components/challenges'
import CollectingInfo from './components/collecting-info'
import Contributors from './components/contributors'
import DefiscModal from './components/defisc-modal'
import Engagements from './components/engagements'
import Mentors from './components/mentors'
import ProgressBar from './components/progress'
import ProjectMedia, { mediaRatio } from './components/project-media'
import RemainingTime from './components/remaining-time'
import RepresentativeInfo from './components/representative-info'
import Social from './components/social'

const Intro = () => {
  const isExtratime = useSelector(getProjectExtratimeState)
  const fiscalReceiptsEnabled = useSelector(isFiscalReceiptsEnabled)
  const hasEngagements = useSelector(hasEngagementsSelector)
  const hasChallenges = useSelector(hasChallengesSelector)
  const hasMentors = useSelector(hasMentorsSelector)

  const displayBottomRow = hasEngagements || hasChallenges || hasMentors

  const displayDefiscModal = fiscalReceiptsEnabled

  return (
    <Container as="section">
      <Grid>
        <GridCol col="12" col-s="10" offset-s="1">
          <IntroGrid className="kiss-Intro__grid">
            <ProjectMedia className="kiss-Intro__projectMedia" />

            <div
              className={classNames('kiss-Intro__informations', {
                'kiss-Intro__informations--displayBottomRow': displayBottomRow,
              })}
            >
              <div className="kiss-Intro__owner">
                <RepresentativeInfo />
              </div>

              <div className="kiss-Intro__infoBlockLine">
                <Marger className="kiss-Intro__infoBlock" top="1" bottom="1">
                  <Contributors />
                </Marger>

                {!isExtratime && (
                  <Marger className="kiss-Intro__infoBlock" top="1" bottom="1">
                    <RemainingTime />
                  </Marger>
                )}

                <Marger className="kiss-Intro__infoBlock" top="1" bottom="1">
                  <CollectingInfo />
                </Marger>
              </div>

              <div className="kiss-Intro__progress">
                <ProgressBar />
              </div>

              <ProjectActions />

              {displayDefiscModal && (
                <div className="kiss-Intro__defisc">
                  <DefiscModal />
                </div>
              )}

              <Social className="kiss-Intro__social" />
            </div>
            {displayBottomRow && (
              <div className="kiss-Intro__metaInfo">
                {hasEngagements && <Engagements />}
                {hasChallenges && <Challenges />}
                {hasMentors && <Mentors />}
              </div>
            )}
          </IntroGrid>
        </GridCol>
      </Grid>
    </Container>
  )
}

const containerWidth = CONTAINER_MAX_WIDTH - CONTAINER_PADDING * 2 + GUTTER
const tenColumns = (containerWidth / NUM_COLUMNS) * 10 - GUTTER
const twoFifthOfTenCols = ((tenColumns - 40) / 5) * 2
const columnMinHeight = ((mediaRatio * 3) / 2) * twoFifthOfTenCols

const IntroGrid = styled.div`
  &.kiss-Intro__grid {
    display: grid;
    grid-row-gap: ${pxToRem(20)};
    margin-bottom: ${pxToRem(50)};

    @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
      grid-row-gap: ${pxToRem(30)};
    }

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      grid-template-columns: 3fr 2fr;
      grid-column-gap: ${pxToRem(40)};
      margin-bottom: ${pxToRem(80)};
    }
  }

  .kiss-Intro__projectMedia {
    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }
  .kiss-Intro__informations {
    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      grid-column-start: 2;
      grid-column-end: 3;

      &.kiss-Intro__informations--displayBottomRow {
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
    @media (min-width: ${pxToRem(ScreenConfig.XL.min)}) {
      align-self: self-start;
      min-height: ${pxToRem(columnMinHeight)};
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .kiss-Intro__metaInfo {
    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    & > *:not(:empty) + * {
      margin-top: ${pxToRem(30)};
    }
  }
  .kiss-Intro__infoBlockLine {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .kiss-Intro__infoBlock {
    line-height: 1.4;

    &:not(:last-child) {
      margin-right: ${pxToRem(25)};
      @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
        margin-right: ${pxToRem(40)};
      }
    }
  }
  .kiss-Intro__progress {
    margin-top: ${pxToRem(8)};
  }
  .kiss-Intro__defisc {
    margin-top: ${pxToRem(20)};
  }
  .kiss-Intro__owner {
    position: relative;
    margin-bottom: ${pxToRem(15)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      margin-bottom: ${pxToRem(20)};
    }
  }
  .kiss-Intro__social {
    margin-top: ${pxToRem(15)};
  }
`

export default Intro
