import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getProjectSlug,
  getProjectUuid,
  isContributingActionDisabled as isContributingActionDisabledSelector,
  isCountGoalType,
  isRewardDisabled as isRewardDisabledSelector,
  isRewardUnavailable as isRewardUnavailableSelector,
} from 'kiss/modules/project-page/page-state/selectors'
import { addVariation } from 'kiss/modules/contribute/cart/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { CART, getRouteFor as getRouteForSelector } from 'kiss/routes/redux'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { Button } from '@kisskissbankbank/kitten'

const SimpleAction = ({ reward, buttonText, disabled = false, ...props }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const projectSlug = useSelector(getProjectSlug)
  const projectId = useSelector(getProjectUuid)
  const getRouteFor = useSelector(getRouteForSelector)
  const isRewardDisabled = useSelector(isRewardDisabledSelector)(reward)
  const isContributingActionDisabled = useSelector(
    isContributingActionDisabledSelector,
  )
  const [hasClicked, click] = useState(false)
  const projectIsCountGoalType = useSelector(isCountGoalType)
  const isRewardUnavailable = useSelector(isRewardUnavailableSelector)(reward)

  const isDisabled =
    isRewardDisabled || isContributingActionDisabled || disabled

  let label = t('reward_card.action')

  if (projectIsCountGoalType) {
    label = t('reward_card.count_goal_action')
  }
  if (isRewardUnavailable) {
    label = t('reward_card.run_out_action')
  }

  const basicButtonProps = {
    fit: 'fluid',
    modifier: 'helium',
    type: 'button',
    ...props,
  }

  const handleClick = () => {
    click(true)
    dispatch(
      addVariation({
        variationId: reward.variations[0].uuid,
        deliveryModeCode: reward?.deliveryModes?.[0]?.code ?? 'none',
        projectId,
        projectSlug,
      }),
    )
      .then(() => {
        RoutingHelper.redirect(getRouteFor(CART, { project: projectSlug }))
      })
      .catch(() => click(false))
  }

  return hasClicked ? (
    <Button {...basicButtonProps}>
      <LoadingAnimation color="var(--color-grey-000)" />
    </Button>
  ) : (
    <Button {...basicButtonProps} disabled={isDisabled} onClick={handleClick}>
      {buttonText || label}
    </Button>
  )
}

export default SimpleAction
