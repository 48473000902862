import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getChallenges } from 'kiss/modules/project-page/page-state/selectors'
import { pxToRem, ScreenConfig, Text } from '@kisskissbankbank/kitten'

const StyledBanner = styled.div`
  border-radius: var(--border-radius-xl);
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'var(--color-grey-200)'};
  padding: ${pxToRem(20)};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${pxToRem(30)};

  &:last-child {
    margin-bottom: 0;
  }
`

const StyledParagraph = styled.div`
  @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
    display: flex;
    align-items: center;
  }
`

const StyledLogo = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: center;
  margin-left: ${pxToRem(20)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    margin-left: ${pxToRem(80)};
  }
`

const StyledSvg = styled.img`
  width: ${pxToRem(50)};
  flex: 0;

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    width: ${pxToRem(60)};
  }
`

const Challenges = () => {
  const challenges = useSelector(getChallenges)

  return challenges.map(
    ({
      id,
      color,
      name,
      description,
      image: { url: challengeImageUrl },
      externalUrl,
      mentor: {
        username,
        image: { url: mentorImageUrl },
      },
    }) => {
      const bannerProps = externalUrl
        ? {
            as: 'a',
            href: externalUrl,
            target: '_blank',
            rel: 'nofollow noopener noreferrer',
          }
        : {}
      return (
        <StyledBanner key={id} backgroundColor={color} {...bannerProps}>
          <StyledParagraph>
            <Text
              tag="p"
              weight="500"
              color="font1"
              size="medium"
              className="k-u-margin-bottom-single@m-up"
            >
              {name}
            </Text>

            <Text
              tag="p"
              weight="400"
              color="font1"
              size="small"
              lineHeight="normal"
              className="k-u-hidden@s-down"
            >
              {description}
            </Text>
          </StyledParagraph>

          {(mentorImageUrl || challengeImageUrl) && (
            <StyledLogo>
              <StyledSvg
                src={challengeImageUrl || mentorImageUrl}
                alt={username}
              />
            </StyledLogo>
          )}
        </StyledBanner>
      )
    },
  )
}

export default Challenges
