import React from 'react'
import { useSelector } from 'react-redux'
import Info from './info'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getEndAt,
  getSuspendedAt,
  getDuration,
  getProjectState,
} from 'kiss/modules/project-page/page-state/selectors'
import { getLocale } from 'kiss/app/redux'
import { isoDateToLocaleFormat } from 'kiss/utils/iso-date-to-locale-format'

const ProjectInfoRemainingTime = () => {
  const endAt = useSelector(getEndAt)
  const suspendedAt = useSelector(getSuspendedAt)
  const duration = useSelector(getDuration)
  const state = useSelector(getProjectState)
  const locale = useSelector(getLocale)

  const t = useTranslation()

  if (suspendedAt) {
    return (
      <Info text={t('projects.infos.remaining_time.suspended')} value="-" />
    )
  }

  const now = new Date().getTime()
  const end = endAt * 1000
  const durationUntilTheEnd = end - now

  const msPerDay = 24 * 60 * 60 * 1000
  const msPerHour = msPerDay / 24
  const msPerMinute = msPerHour / 60

  const durationPerDay = Math.floor(durationUntilTheEnd / msPerDay)
  const durationPerHour = Math.floor(durationUntilTheEnd / msPerHour)
  const durationPerMinute = Math.floor(durationUntilTheEnd / msPerMinute)

  let value
  let text
  let count

  if (!endAt) {
    count = { count: duration }
    value = t('project_info.remaining_time.day', count)
    text = t('project_info.remaining_time.day_left', count)
  } else if (state !== 'started' || durationPerMinute <= 0) {
    value = isoDateToLocaleFormat(new Date(end), {
      format: locale === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy',
      locale,
    })
    text = t('project_info.remaining_time.end_at')
  } else if (durationPerDay > 0) {
    count = { count: durationPerDay }
    value = t('project_info.remaining_time.day', count)
    text = t('project_info.remaining_time.day_left', count)
  } else if (durationPerHour > 0) {
    count = { count: durationPerHour }
    value = t('project_info.remaining_time.hour', count)
    text = t('project_info.remaining_time.hour_left', count)
  } else {
    count = { count: durationPerMinute }
    value = t('project_info.remaining_time.minute', count)
    text = t('project_info.remaining_time.minute_left', count)
  }

  return <Info text={text} value={value} />
}

export default ProjectInfoRemainingTime
