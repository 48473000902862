import { Button } from '@kisskissbankbank/kitten'
import { addErrorAlert, removeErrorAlerts } from 'kiss/app/alerts/redux'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useCampaignBankwirePayment } from 'kiss/payment'
import { masquerading } from 'kiss/session/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectId } from '../../../redux'

const BankWireButton = () => {
  const t = useTranslation()
  const isMasquerading = useSelector(masquerading)
  const dispatch = useDispatch()
  const projectId = useSelector(getProjectId)
  const { callPayBankwire } = useCampaignBankwirePayment()
  const [isLoading, setLoading] = useState(false)
  if (!isMasquerading) {
    return null
  }
  return (
    <Button
      fit="fluid"
      modifier="helium"
      className="k-u-margin-vertical-double"
      onClick={async () => {
        if (isLoading) {
          return
        }
        setLoading(true)
        dispatch(removeErrorAlerts())
        try {
          const paymentUrl = await callPayBankwire({ projectId })
          RoutingHelper.redirect(paymentUrl)
        } catch (e) {
          dispatch(addErrorAlert(t('contribute.payment_choice.bankwire.error')))
          setLoading(false)
        }
      }}
    >
      {isLoading ? (
        <LoadingAnimation color="var(--color-grey-000)" />
      ) : (
        t('contribute.payment_choice.bankwire.button')
      )}
    </Button>
  )
}

export default BankWireButton
