import React from 'react'
import { Alert, WarningIcon, Text } from '@kisskissbankbank/kitten'
import { useLocation } from 'react-router-dom'
import * as qs from 'neoqs'
import { useTranslation } from 'kiss/hooks/use-translation'

const Expired = () => {
  const t = useTranslation()
  const { search } = useLocation()
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true })
  const isExpired = parsedSearch?.reason === 'expired' || false
  if (!isExpired) return null
  return (
    <Alert status="warning" closeButton icon={<WarningIcon />}>
      <Text>{t('project.alert.expired')}</Text>
    </Alert>
  )
}

export default Expired
