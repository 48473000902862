import React from 'react'
import {
  addErrorAlert,
  addSuccessAlert,
  removeAllAlerts,
} from 'kiss/app/alerts/redux'
import { Formik, Form } from 'formik'
import { Button } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useDispatch } from 'react-redux'
import { useMutation } from 'kiss/api/graphql-query'
import UpdateContributorIdentity from 'kiss/graphql/mutations/sporran/update_contributor_identity.graphql'
import UpdateCompany from 'kiss/graphql/mutations/sporran/update_company.graphql'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import Label from 'kiss/components/formik/label'
import Dropdown from 'kiss/components/formik/dropdown-select'
import StatusMessage from './status-message'
import * as Yup from 'yup'

const LegalValidation = ({ identity }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const [sendUserData] = useMutation(UpdateContributorIdentity)
  const [sendCompanyData] = useMutation(UpdateCompany)
  return (
    <Formik
      initialValues={{ status: '' }}
      validationSchema={Yup.object().shape({
        status: Yup.string().required(t('app.forms.error.required')),
      })}
      onSubmit={async ({ status }) => {
        dispatch(removeAllAlerts())
        try {
          await sendUserData({
            variables: {
              id: identity.id,
              identity: {
                legalType: 'legal',
              },
            },
          })
          await sendCompanyData({
            variables: {
              id: identity.id,
              company: {
                legalForm: status,
              },
            },
          })
          dispatch(
            addSuccessAlert(t('users.kyc.documents.send.legal.success'), {
              scroll: true,
            }),
          )
        } catch (e) {
          console.error(e)
          dispatch(
            addErrorAlert(t('app.server.error'), {
              scroll: true,
            }),
          )
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="k-u-margin-bottom-triple">
            <Label htmlFor="status">{t('users.kyc.form.status.label')}</Label>
            <Dropdown
              name="status"
              placeholder={t('users.kyc.form.status.placeholder')}
              options={[
                {
                  value: 'business',
                  label: t('users.kyc.form.status.entreprise'),
                },
                {
                  value: 'soletrader',
                  label: t('users.kyc.form.status.autoentreprise'),
                },
                {
                  value: 'association',
                  label: t('users.kyc.form.status.association'),
                },
                {
                  value: 'local_authority',
                  label: t('users.kyc.form.status.locale'),
                },
              ]}
            />
            <StatusMessage />
            <Button
              fit="fluid"
              size="large"
              type="submit"
              modifier="helium"
              className="k-u-margin-top-double"
            >
              {isSubmitting ? (
                <LoadingAnimation
                  className="k-Button__icon"
                  color="var(--color-grey-000)"
                />
              ) : (
                t('app.submit.validate')
              )}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

LegalValidation.propTypes = {}

export default LegalValidation
