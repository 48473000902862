import { createSelector } from '@reduxjs/toolkit'
import {
  getProjectUuid,
  currentUserHasBacked,
  isCurrentUserManager,
} from 'kiss/modules/project-page/page-state/selectors'
import {
  NAME,
  UPDATE_PROJECT_COMMENTS,
  LIKE_COMMENT,
  UNLIKE_COMMENT,
  MANAGER_COMMENTS_PER_LOAD,
  COMMENTS_PER_LOAD,
  UPDATE_COMMENTS,
} from 'kiss/modules/project-page/page-state/redux'
import { isCurrentUserAdmin, getCurrentUserSlug } from 'kiss/session/redux'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import query from 'kiss/api/graphql-query'
import createCommentQuery from 'kiss/graphql/mutations/project/create_comment.graphql'
import projectCommentsRequest from 'kiss/graphql/project/comments_list.graphql'
import deleteCommentQuery from 'kiss/graphql/mutations/project/delete_comment.graphql'
import likeQuery from 'kiss/graphql/mutations/project/like.graphql'
import unlikeQuery from 'kiss/graphql/mutations/project/unlike.graphql'
import updateCommentQuery from 'kiss/graphql/mutations/project/update_comment.graphql'
import commentsQuery from 'kiss/graphql/queries/project/comments.graphql'

export const postComment =
  ({ commentUuid, text }) =>
  async (_dispatch, getState) => {
    const state = getState()
    const projectId = !commentUuid ? getProjectUuid(state) : null
    const commentId = commentUuid
    try {
      await query(createCommentQuery, { projectId, commentId, text }, state)
      _dispatch(refreshComments({ projectId: getProjectUuid(state) }))
    } catch {
      _dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const updateComment =
  ({ commentUuid, parentCommentId, text }) =>
  async (dispatch, getState) => {
    const state = getState()
    const commentId = commentUuid

    try {
      const response = await query(
        updateCommentQuery,
        { commentId, text },
        state,
      )

      dispatch({
        type: UPDATE_PROJECT_COMMENTS,
        payload: {
          commentsConnection: {
            edges: updateCommentEdges(
              { ...response.commentUpdate, parentCommentId },
              state,
            ),
          },
        },
      })
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

const updateCommentEdges = (comment, state) => {
  const comments = getComments(state)

  const commentId = comment.parentCommentId || comment.id || comment.uuid

  const commentIndex = comments.findIndex((c) => c.uuid === commentId)

  if (comment.parentCommentId) {
    const replyId = comment.id || comment.uuid

    const replyIndex = comments[commentIndex]?.replies.findIndex(
      (reply) => reply.uuid === replyId,
    )

    return {
      [commentIndex]: {
        node: {
          replies: {
            [replyIndex]: comment,
          },
        },
      },
    }
  }

  return {
    [commentIndex]: {
      node: comment,
    },
  }
}

export const deleteComment = (commentUuid) => (dispatch, getState) => {
  const state = getState()

  return query(deleteCommentQuery, { uuid: commentUuid }, state)
}

const refreshComments =
  ({ projectId }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      const response = await query(commentsQuery, { id: projectId }, state, {
        fetchPolicy: 'no-cache',
      })

      dispatch({
        type: UPDATE_COMMENTS,
        payload: {
          commentsConnection: response.project.commentsConnection,
        },
      })
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const getLastProjectCommentCursor = (state) => {
  const edges = state[NAME]?.commentsConnection?.edges || []
  const lastEdge = edges[edges.length - 1]
  return lastEdge?.cursor
}

export const loadMoreProjectComments = () => (dispatch, getState) => {
  const state = getState()
  const { slug } = state[NAME]

  return query(
    projectCommentsRequest,
    {
      slug,
      commentsAfter: getLastProjectCommentCursor(state),
      commentsLimit: definePerPage(state),
    },
    state,
    {
      fetchPolicy: 'no-cache',
    },
  ).then(
    (result) => {
      const existingComments = state[NAME]?.commentsConnection?.edges
      const newComments = result?.project?.commentsConnection?.edges
      const commentsCursor = newComments?.length
        ? newComments[newComments.length - 1]?.cursor
        : undefined

      dispatch({
        type: UPDATE_PROJECT_COMMENTS,
        payload: {
          commentsConnection: {
            edges: [...existingComments, ...newComments],
          },
          commentsCursor,
        },
      })
    },
    (_error) => {
      dispatch(addErrorAlert('Error: something went wrong!'))
    },
  )
}

const definePerPage = (state) => {
  if (isCurrentUserManager(state) || isCurrentUserAdmin(state)) {
    return MANAGER_COMMENTS_PER_LOAD
  } else {
    return COMMENTS_PER_LOAD
  }
}

export const like = (commentId) => (dispatch, getState) => {
  const state = getState()

  return query(likeQuery, { commentId }, state).then(
    (response) => {
      return dispatch({
        type: LIKE_COMMENT,
        payload: {
          commentsConnection: {
            edges: updateCommentEdges(response.like, state),
          },
        },
      })
    },
    (_error) => {
      return dispatch(addErrorAlert('Error: something went wrong!'))
    },
  )
}

export const unlike = (commentId) => (dispatch, getState) => {
  const state = getState()

  return query(unlikeQuery, { commentId }, state).then(
    (response) => {
      return dispatch({
        type: UNLIKE_COMMENT,
        payload: {
          commentsConnection: {
            edges: updateCommentEdges(response.unlike, state),
          },
        },
      })
    },
    (_error) => {
      return dispatch(addErrorAlert('Error: something went wrong!'))
    },
  )
}

export const currentUserCanComment = createSelector(
  [currentUserHasBacked, isCurrentUserManager, isCurrentUserAdmin],
  (currentUserHasBacked, isCurrentUserManager, isCurrentUserAdmin) =>
    currentUserHasBacked || isCurrentUserManager || isCurrentUserAdmin,
)

export const currentUserCanDeleteComment = createSelector(
  [isCurrentUserManager, isCurrentUserAdmin, getCurrentUserSlug],
  (isCurrentUserManager, isCurrentUserAdmin, currentUserSlug) => {
    return (commentOwnerSlug) =>
      isCurrentUserManager ||
      isCurrentUserAdmin ||
      currentUserSlug === commentOwnerSlug
  },
)

export const getComments = (state) => {
  const edges = state[NAME]?.commentsConnection?.edges || []

  return edges.map(({ node }) => node)
}

export const getCommentsTotalCount = (state) => {
  return state[NAME]?.commentsConnection?.totalCount ?? 0
}
