import { Formik } from 'formik'
import { gql } from '@apollo/client'
import { useMutation } from 'kiss/api/graphql-query'
import { useTranslation } from 'kiss/hooks/use-translation'
import updateCartShippingAddressQuery from 'kiss/graphql/mutations/contribute/update_cart_shipping_address.graphql'
import { addVariation } from 'kiss/modules/contribute/cart/redux'
import {
  getDeliveryCountryAmounts,
  getProjectSlug,
  getProjectUuid,
  getShippingAddressCountryCode,
  hasFees,
  isPermanent,
} from 'kiss/modules/project-page/page-state/selectors'
import { CART, getRouteFor as getRouteForSelector } from 'kiss/routes/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import flow from 'lodash/fp/flow'
import prop from 'lodash/fp/prop'
import some from 'lodash/fp/some'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

const UPDATE_CART_STARTING_PERIOD_MUTATION = gql`
  mutation UpdateCartStartingPeriod(
    $projectId: ID
    $startOnNextPeriod: Boolean!
  ) {
    updateCartStartingPeriod(
      projectId: $projectId
      startOnNextPeriod: $startOnNextPeriod
    ) {
      id
    }
  }
`
const hasNoShipping = flow(prop('deliveryModes'), some({ code: 'none' }))

const Form = ({ reward, children }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const projectSlug = useSelector(getProjectSlug)
  const projectId = useSelector(getProjectUuid)
  const projectIsPermanent = useSelector(isPermanent)
  const getRouteFor = useSelector(getRouteForSelector)
  const [updateShippingAddressByApi] = useMutation(
    updateCartShippingAddressQuery,
  )
  const [updateCartStartingPeriod] = useMutation(
    UPDATE_CART_STARTING_PERIOD_MUTATION,
  )

  return (
    <Formik
      initialValues={{
        variation: !reward.hasVariationSelection
          ? reward.variations[0].uuid
          : undefined,
        seletedOptionValueIds: [],
        countryCode: useSelector(getShippingAddressCountryCode),
        shipping: undefined,
        startOnNextPeriod: false,
      }}
      validationSchema={Yup.object().shape({
        variation: Yup.string().required(t('project.modal.options.error')),
        ...(!hasNoShipping(reward) && {
          shipping: Yup.string().required(
            t('project.modal.shipping.method.error'),
          ),
        }),
      })}
      onSubmit={async (
        { countryCode, shipping, variation, startOnNextPeriod },
        { setSubmitting },
      ) => {
        if (projectIsPermanent) {
          await updateCartStartingPeriod({
            variables: {
              projectId,
              startOnNextPeriod: startOnNextPeriod === 'true' ? true : false,
            },
          })
        }

        dispatch(
          addVariation({
            projectId,
            projectSlug,
            variationId: variation,
            deliveryModeCode: shipping,
            deliveryZoneCode: countryCode,
          }),
        )
          .then(() => {
            return hasFees(getDeliveryCountryAmounts(reward)) &&
              shipping === 'postal'
              ? updateShippingAddressByApi({
                  variables: { projectSlug, shippingAddress: { countryCode } },
                })
              : Promise.resolve()
          })
          .then(() => {
            RoutingHelper.redirect(getRouteFor(CART, { project: projectSlug }))
          })
          .catch(() => setSubmitting(false))
      }}
    >
      {({ handleSubmit, isSubmitting, isValid }) => {
        return children({ handleSubmit, isSubmitting, isValid })
      }}
    </Formik>
  )
}

export default Form
