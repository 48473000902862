import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Enabled = styled.div`
  transition: opacity 0.5s;

  &.kiss-EnabledAfterMount--enabled {
    opacity: 1;
  }

  &:not(.kiss-EnabledAfterMount--enabled) {
    opacity: 0.5;
    pointer-events: none;
  }
`

const EnabledAfterMount = ({ children }) => {
  const [enabled, enable] = useState(false)

  useEffect(() => enable(true), [])

  return (
    <Enabled
      className={classNames('kiss-EnabledAfterMount', {
        'kiss-EnabledAfterMount--enabled': enabled,
      })}
    >
      {children(enabled)}
    </Enabled>
  )
}

export default EnabledAfterMount
