import { gql } from '@apollo/client'

export const LoadMoreCommentForNewsQuery = gql`
  query LoadMoreCommentForNewsQuery(
    $slug: String!
    $newsId: ID!
    $lastCursor: String
  ) {
    project(slug: $slug) {
      id
      newsConnection(sortDirection: DESC, published: [true], ids: [$newsId]) {
        totalCount
        edges {
          node {
            title
            id
            commentsConnection(first: 12, after: $lastCursor) {
              totalCount
              edges {
                cursor
                node {
                  uuid
                  text
                  publicationAt
                  deletedAt
                  currentUserHasLiked
                  likesCount
                  commenter {
                    slug
                    username
                    avatarImage {
                      url(size: normal)
                      normalUrl: url(size: normal)
                      thumbUrl: url(size: thumb)
                    }
                  }
                  replies {
                    uuid
                    parentCommentId
                    text
                    publicationAt
                    deletedAt
                    currentUserHasLiked
                    likesCount
                    commenter {
                      slug
                      username
                      avatarImage {
                        url(size: normal)
                        normalUrl: url(size: normal)
                        thumbUrl: url(size: thumb)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
