import React from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'

import { useTranslation } from 'kiss/hooks/use-translation'
import config from 'kiss/config'

import { ContentCard, parseHtml, Title } from '@kisskissbankbank/kitten'
import { Displayer } from '@kisskissbankbank/underwood'
import {
  getProject,
  getVideoTag,
} from 'kiss/modules/project-page/page-state/selectors'
import { ContributeButton } from 'kiss/modules/project-page/permanent/components/contribute-button'
import { ABOUT_ID } from 'kiss/modules/project-page/permanent/constants/id'

const MEDIA_RATIO = 10 / 16

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;

  @supports (aspect-ratio: 16 / 10) {
    aspect-ratio: 16 / 10;
  }

  @supports not (aspect-ratio: 16 / 10) {
    height: 0;
    padding-bottom: calc(${MEDIA_RATIO} * 100%);
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Display = ({ description }) => (
  <Displayer
    useRichTextStyle
    text={description}
    perfEnabled
    configResponsiveImageHandler={config[APP_ENV].responsiveImageHandler}
    className="k-u-margin-bottom-double"
  />
)

const About = ({ withContributeButton = true }) => {
  const project = useSelector(getProject)
  const videoTag = useSelector(getVideoTag)

  const t = useTranslation()

  const FundingDescriptionContent = () => (
    <div>
      {project.descriptionFunding && (
        <Title
          tag="h2"
          modifier="septenary"
          className="k-u-margin-bottom-double"
        >
          {t('project_description.description_funding')}
        </Title>
      )}
      <Display description={project.descriptionFunding} />
    </div>
  )

  return (
    <div id={ABOUT_ID}>
      <Title
        tag="h2"
        modifier="quaternary"
        className="k-u-margin-bottom-double"
      >
        {t('permanent_project.menu.about')}
      </Title>
      <ContentCard className="k-u-hidden@m-down">
        {videoTag && (
          <ContentCard.Title>
            <VideoWrapper>
              {parseHtml(videoTag, { sanitize: false })}
            </VideoWrapper>
          </ContentCard.Title>
        )}
        <ContentCard.Content className="k-u-flex k-u-flex-direction-column k-u-flex-gap-quadruple">
          <Display description={project.description} />
          {project.teamDescription && (
            <Display description={project.teamDescription} />
          )}
          <FundingDescriptionContent />
        </ContentCard.Content>
      </ContentCard>
      <div className="k-u-hidden@l-up k-u-flex k-u-flex-direction-column k-u-flex-gap-quadruple">
        <Display description={project.description} />
        <FundingDescriptionContent />
      </div>
      {withContributeButton && <ContributeButton className="k-u-hidden@l-up" />}
    </div>
  )
}

export default About
