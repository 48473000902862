import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import isEmpty from 'lodash/fp/isEmpty'
import { useTranslation } from 'kiss/hooks/use-translation'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { Text } from '@kisskissbankbank/kitten'
import { MAX_LENGTH_ADDRESS } from './form'

const AddressBis = ({ showAddressBis }) => {
  const t = useTranslation()
  const [showField, setFieldVisible] = useState(false)
  const [field] = useField('additional2')

  useEffect(() => {
    if (!isEmpty(field.value) || showAddressBis) {
      setFieldVisible(true)
    }
  }, [showAddressBis])

  const onValidate = (value) => {
    if (value?.length === MAX_LENGTH_ADDRESS) {
      return t('contribute.shipping_address.error.max_length')
    }
  }

  if (!showField) {
    return (
      <div className="k-u-margin-bottom-triple k-u-margin-top-singleHalf">
        <Text
          tag="button"
          type="button"
          size="small"
          weight="500"
          className="k-u-link k-u-link-primary1 k-u-reset-button"
          onClick={() => setFieldVisible(true)}
        >
          {t('contribute.shipping_address.address.optin')}
        </Text>
      </div>
    )
  }

  return (
    <>
      <div className="k-u-margin-vertical-triple">
        <Label htmlFor="additional2">
          {t('contribute.shipping_address.form.address2.label')}
        </Label>

        <SimpleText
          name="additional2"
          autoComplete="kisskiss-prevent-autocomplete"
          placeholder={t(
            'contribute.shipping_address.form.address2.placeholder',
            { parseHtml: true },
          )}
          maxLength={MAX_LENGTH_ADDRESS}
          validate={onValidate}
        />
      </div>
      <div className="k-u-margin-vertical-triple">
        <Label htmlFor="additional3">
          {t('contribute.shipping_address.form.address3.label')}
        </Label>

        <SimpleText
          name="additional3"
          autoComplete="kisskiss-prevent-autocomplete"
          maxLength={MAX_LENGTH_ADDRESS}
          validate={onValidate}
        />
      </div>
      <div className="k-u-margin-vertical-triple">
        <Label htmlFor="additional4">
          {t('contribute.shipping_address.form.address4.label')}
        </Label>

        <SimpleText
          name="additional4"
          autoComplete="kisskiss-prevent-autocomplete"
          maxLength={MAX_LENGTH_ADDRESS}
          validate={onValidate}
        />
      </div>
    </>
  )
}

export default AddressBis
