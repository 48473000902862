import React, { useEffect } from 'react'

import PropTypes from 'prop-types'
import { ErrorMessage, useField } from 'formik'

import { Field } from '@kisskissbankbank/kitten'

const SimpleText = ({
  name,
  form,
  type = 'text',
  disabled = false,
  defaultValue,
  style,
  className,
  onFocus = () => {},
  onChange = () => {},
  onBlur = () => {},
  normalize,
  validate = () => {},
  helpHiddenOnBlur = false,
  help = '',
  ...others
}) => {
  const [field, meta, { setValue }] = useField({ name, validate })

  useEffect(() => {
    if (!defaultValue) return
    if (field.value) return

    setValue(defaultValue)
  }, [])

  return (
    <>
      <div style={style} className={className}>
        <Field.Input
          {...field}
          id={name}
          error={meta.error && meta.touched}
          onFocus={onFocus}
          onChange={(e) => {
            field.onChange(e)
            if (normalize) {
              setValue(normalize(e.target.value))
            }
            onChange(e)
          }}
          onBlur={(e) => {
            field.onBlur(e)
            if (normalize) {
              setValue(normalize(e.target.value))
            }
            onBlur(e)
          }}
          data-test-id={name}
          type={type}
          disabled={disabled}
          {...others}
        />
        {help && (
          <Field.Help hiddenOnBlur={helpHiddenOnBlur}>{help}</Field.Help>
        )}
      </div>

      <ErrorMessage name={name}>
        {(message) => <Field.ErrorMessage>{message}</Field.ErrorMessage>}
      </ErrorMessage>
    </>
  )
}

SimpleText.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  helpHiddenOnBlur: PropTypes.bool,
  help: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  normalize: PropTypes.func,
}

export default SimpleText
