export const GooglePlaceHelper = {
  /**
   * Helper to get address data
   *
   * @params {Object} place - The place object returned by Google Places API
   * @return {Object} address fields from `place`
   *   e.g
   *   {
   *     administrative_area_level_1: "Île-de-France",
   *     country: "France",
   *     locality: "Paris",
   *     postal_code: "75017",
   *     route: "Rue Jouffroy-d'Abbans",
   *     street_number: "19 Bis",
   *   }
   */
  getRawAddressData(place) {
    const googlePlacesFieldTypes = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'short_name',
      postal_code: 'short_name',
      neighborhood: 'long_name',
      sublocality: 'long_name',
      political: 'long_name',
    }

    return place.address_components.reduce((acc, addressComponent) => {
      const addressType = addressComponent.types[0]

      if (googlePlacesFieldTypes[addressType]) {
        const value = addressComponent[googlePlacesFieldTypes[addressType]]

        acc[addressType] = value
      }

      return acc
    }, {})
  },

  getAddressFields(place) {
    const rawAddressData = this.getRawAddressData(place)
    const streetNumber = rawAddressData.street_number || ''
    const route = rawAddressData.route || ''
    const address =
      rawAddressData.sublocality ||
      rawAddressData.political ||
      rawAddressData.neighborhood ||
      `${streetNumber} ${route}`.trim()

    return {
      address,
      zipcode: rawAddressData.postal_code || '',
      countryCode: rawAddressData.country || '',
      city:
        rawAddressData.locality ||
        rawAddressData.administrative_area_level_1 ||
        '',
      region: rawAddressData.administrative_area_level_1 || '',
    }
  },
}
