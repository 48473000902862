import {
  pxToRem,
  ScreenConfig,
  Separator,
  Text,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import map from 'lodash/fp/map'
import reduce from 'lodash/fp/reduce'
import React from 'react'
import styled from 'styled-components'
import { OrderStatus } from './components/order-status'
import DownloadReceipt from './download-receipt'
import DownloadInvoice from './download-invoice'
import { StyledWrapper } from './styles'

const DetailsText = styled(Text)`
  @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
    line-height: ${pxToRem(12)};
  }
`

const hasAmount = (amount) => getOr(0)('cents')(amount) > 0

const Amount = ({ amount, bold = false }) => {
  const t = useTranslation()
  const Component = bold ? Text : DetailsText
  const amountText = t('project_info.amount_with_currency', {
    amount: t(amount.cents / 100, {
      formatNumber: { decimal: 2 },
    }),
    currency: t(`currency_symbol.${amount.currency}`),
    parseHtml: true,
  })
  return (
    <Component size="small" weight={bold ? '500' : '400'} color="font1">
      {amountText}
    </Component>
  )
}

const Payment = ({ order }) => {
  const t = useTranslation()

  let sortedRewards = flow(
    getOr([])('orderLines'),
    reduce((acc, orderLine) => {
      acc[orderLine.id] = {
        label: orderLine.label,
        cents: orderLine.itemAmount.cents,
        currency: orderLine.itemAmount.currency,
        quantity: orderLine.quantity,
      }
      return acc
    }, {}),
  )(order)

  return (
    <div>
      <Text
        size="huge"
        tag="h3"
        weight="500"
        className="k-u-margin-bottom-single"
      >
        {t('users.contributions.details.payment')}
      </Text>
      <StyledWrapper>
        <div className="kiss-ContributionDetails__title">
          <Text weight="500" size="medium">
            {t('contributor.orders.details.total')}
          </Text>
          <Amount amount={order.payment?.amount || order.totalAmount} bold />
        </div>
        <Separator className="k-u-margin-top-singleHalf k-u-margin-bottom-singleHalf" />
        {!isEmpty(sortedRewards) &&
          map((reward) => (
            <div className="k-ContributionsDetails__rewardLine">
              <DetailsText color="font1" size="small" weight="400">
                <Text weight="500" size="medium">
                  {reward.label}
                </Text>
                {reward.quantity > 1 && `, Qté : ${reward.quantity}`}
              </DetailsText>
              <Amount amount={reward} />
            </div>
          ))(sortedRewards)}
        {hasAmount(order.donationAmount) && (
          <div className="k-ContributionsDetails__rewardLine">
            <DetailsText color="font1" size="small" weight="400">
              {t('contributor.orders.details.donation')}
            </DetailsText>
            <Amount amount={order.donationAmount} />
          </div>
        )}
        {hasAmount(order.tipAmount) && (
          <div className="k-ContributionsDetails__rewardLine">
            <DetailsText color="font1" size="small" weight="400">
              {t('contributor.orders.details.tip')}
            </DetailsText>
            <Amount amount={order.tipAmount} />
          </div>
        )}
        {hasAmount(order.deliveryAmount) && (
          <div className="k-ContributionsDetails__rewardLine">
            <DetailsText color="font1" size="small" weight="400">
              {t('contributor.orders.details.fees')}
            </DetailsText>
            <Amount amount={order.deliveryAmount} />
          </div>
        )}
        {hasAmount(order.serviceFeeAmount) && (
          <div className="k-ContributionsDetails__rewardLine">
            <DetailsText color="font1" size="small" weight="400">
              {t('contributor.orders.details.service_fees')}
            </DetailsText>
            <Amount amount={order.serviceFeeAmount} />
          </div>
        )}
        <Separator className="k-u-margin-top-singleHalf k-u-margin-bottom-singleHalf" />
        <div className="kiss-ContributionDetails__title">
          <Text weight="500" size="medium">
            {t('users.contributions.details.status')}
          </Text>
          <OrderStatus orderStatus={order.orderStatus} />
        </div>
        <DownloadReceipt order={order} />
        <DownloadInvoice order={order} />
      </StyledWrapper>
    </div>
  )
}

export default Payment
