import React from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Title, Button, Paragraph } from '@kisskissbankbank/kitten'
import { getRouteFor } from 'kiss/routes/redux'
import {
  CONTRIBUTE_FOOTER_ID,
  getProjectSlug,
} from 'kiss/modules/contribute/redux'
import { useHistory } from 'react-router'
import { useTranslation } from 'kiss/hooks/use-translation'
import useContributeStepper from '../hooks/use-stepper'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body,
  #App-react-component {
    min-height: 100vh;
  }

  #${CONTRIBUTE_FOOTER_ID} {
    position: sticky;
    top: 100vh;
  }
`

const IdentityCheck = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const history = useHistory()
  const projectSlug = useSelector(getProjectSlug)
  const { nextRoute } = useContributeStepper()
  const nextUrl = routeFor(nextRoute, { project: projectSlug })

  return (
    <>
      <GlobalStyle />
      <Helmet title={t('contribute.identity_check.seo.title')} />

      <Title modifier="quaternary" className="k-u-margin-bottom-triple">
        {t('contribute.identity_check.title')}
      </Title>

      <Paragraph modifier="secondary" className="k-u-margin-bottom-singleHalf">
        {t('contribute.identity_check.paragraph_1', { parseHtml: true })}
      </Paragraph>

      <Paragraph modifier="secondary" className="k-u-margin-bottom-quintuple">
        {t('contribute.identity_check.paragraph_2', { parseHtml: true })}
      </Paragraph>

      <Button
        modifier="helium"
        fit="fluid"
        as="a"
        href={nextUrl}
        onClick={(e) => {
          e.preventDefault()
          history.push(nextUrl)
        }}
        data-test-id="identity-check-next-button"
      >
        {t('contribute.identity_check.button')}
      </Button>
    </>
  )
}

export default IdentityCheck
