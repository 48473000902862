import { Grid, GridCol, TitleWithStroke } from '@kisskissbankbank/kitten'
import ErrorBoundary from 'kiss/app/layouts/error-boundary'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getTotalCurrency } from 'kiss/modules/contribute/cart/redux'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useCart } from '../../hooks'
import CardForm from './form'

const PaymentChoices = () => {
  const t = useTranslation()
  const totalCurrency = useSelector(getTotalCurrency)
  const { cart, loading } = useCart()
  const totalAmount = cart?.amountToPay?.cents / 100
  const formattedTotalAmount = t('project_info.amount_with_currency', {
    amount: t(totalAmount, { formatNumber: { decimal: 2 } }),
    currency: t(`currency_symbol.${totalCurrency}`),
    parseHtml: true,
  })

  if (loading) return null
  return (
    <Grid>
      <GridCol col-l="10">
        <ErrorBoundary>
          <Helmet title={t('contribute.payment_choice.head.title')} />

          <TitleWithStroke modifier="quaternary" className="k-u-hidden@xs-down">
            {t('payment_choice.title')}
          </TitleWithStroke>

          <CardForm
            prefix="cb_visa_mastercard"
            formattedAmount={formattedTotalAmount}
          />
        </ErrorBoundary>
      </GridCol>
    </Grid>
  )
}

export default PaymentChoices
