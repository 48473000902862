import {
  Button,
  HorizontalStroke,
  Marger,
  Title,
} from '@kisskissbankbank/kitten'
import { Form, Formik } from 'formik'
import query from 'kiss/api/graphql-query'
import { addErrorAlert, removeAlert } from 'kiss/app/alerts/redux'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import Label from 'kiss/components/formik/label'
import NewPassword from 'kiss/components/formik/new-password'
import { useTranslation } from 'kiss/hooks/use-translation'
import updatePassword from 'kiss/graphql/authenticate/update_password.graphql'
import { getRouteFor, SIGN_IN } from 'kiss/routes/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import * as qs from 'neoqs'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import * as yup from 'yup'
import AuthenticateLayout from '../components/layout'
import PasswordHelper, {
  calculateProgress,
} from '../components/password-helper'

const defaultValues = {
  password: '',
  passwordConfirmation: '',
}

const ResetPasswordForm = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const [showPasswordHelper, setShowPasswordHelper] = useState(false)
  const { search } = useLocation()
  const routeFor = useSelector(getRouteFor)
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true })

  const errorPath = 'authenticate.registration.error'
  return (
    <AuthenticateLayout>
      <Helmet title={t('authenticate.update_password.seo.title')} />
      <Marger bottom="2">
        <Title modifier="tertiary" tag="h3">
          {t('authenticate.update_password.title')}
        </Title>
      </Marger>

      <Marger bottom="4">
        <HorizontalStroke size="large" />
      </Marger>

      <Formik
        enableReinitialize
        initialValues={defaultValues}
        validationSchema={yup.object({
          password: yup
            .string()
            .test(
              'is-secure',
              t(`${errorPath}.password.secure`),
              (value) => calculateProgress(value) === 100,
            ),
          passwordConfirmation: yup
            .string()
            .oneOf(
              [yup.ref('password')],
              t(`${errorPath}.password_confirmation.no_match`),
            ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(removeAlert())
          return query(updatePassword, {
            token: searchParams?.reset_password_token,
            password: values.password,
            passwordConfirmation: values.passwordConfirmation,
          })
            .then(() => {
              setSubmitting(false)
              RoutingHelper.redirect(`${routeFor(SIGN_IN)}?new_password=true`)
            })
            .catch(() => {
              setSubmitting(false)
              dispatch(addErrorAlert(t('app.server.error'), { scroll: true }))
            })
        }}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              <div className="k-u-margin-bottom-triple">
                <Label htmlFor="password">
                  {t('authenticate.update_password.password.label')}
                </Label>
                <NewPassword
                  name="password"
                  iconLabel="show password"
                  hiddenIconLabel="hidden password"
                  placeholder={t(
                    'authenticate.update_password.password.placeholder',
                  )}
                  aria-describedby="security-notification"
                  autoComplete="new-password"
                  onFocus={() => setShowPasswordHelper(true)}
                  onBlur={() => setShowPasswordHelper(false)}
                />
              </div>
              <Label htmlFor="passwordConfirmation">
                {t('authenticate.update_password.password_confirmation.label')}
              </Label>
              <NewPassword
                name="passwordConfirmation"
                iconLabel="show password"
                hiddenIconLabel="hidden password"
                placeholder={t(
                  'authenticate.update_password.password_confirmation.placeholder',
                )}
                aria-describedby="security-notification"
                autoComplete="new-password"
              />
              {showPasswordHelper && (
                <PasswordHelper password={values.password} />
              )}
              <Marger top="4" bottom="1">
                {isSubmitting ? (
                  <SubmitLoader fit="fluid" />
                ) : (
                  <Button
                    modifier="helium"
                    size="large"
                    type="submit"
                    fit="fluid"
                  >
                    {t('authenticate.update_password.submit_button')}
                  </Button>
                )}
              </Marger>
            </Form>
          )
        }}
      </Formik>
    </AuthenticateLayout>
  )
}

export default ResetPasswordForm
