import React from 'react'

import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import {
  BulletList,
  Container,
  CONTAINER_PADDING,
  Grid,
  GridCol,
  pxToRem,
  ScreenConfig,
  stepToRem,
  Text,
  TitleWithStroke,
} from '@kisskissbankbank/kitten'
import FundMyProjectButton from 'kiss/modules/communication/fund-my-project/components/fund-my-project-button'

import styled from 'styled-components'
import classNames from 'classnames'

const IMG_HEIGHT = 416
const IMG_WIDTH = 555

const StyledKeySection = styled(Container)`
  .kiss-FundMyProject__KeySection__list {
    order: 0;

    @media (min-width: ${ScreenConfig.S.min}px) {
      order: -1;
    }
  }

  .kiss-FundMyProject__KeySection__bulletList {
    line-height: 1.6;
    color: var(--color-grey-900);
  }

  .kiss-FundMyProject__KeySection__bulletList--text {
    font-size: ${stepToRem(-1)};

    @media (min-width: ${ScreenConfig.S.min}px) {
      font-size: ${stepToRem(0)};
    }
  }

  .kiss-FundMyProject__KeySection__image {
    background-color: var(--color-cyan-100);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-bottom: ${(IMG_HEIGHT / IMG_WIDTH) * 100}%;
    background-image: var(--background-image);

    @media (min-width: ${ScreenConfig.S.min}px) and (max-width: ${ScreenConfig.M
        .max}px) {
      margin-right: -${pxToRem(CONTAINER_PADDING)};
      width: calc(100% + ${pxToRem(CONTAINER_PADDING)});
    }
  }

  .anchor {
    display: block;
    position: relative;
    top: -30px;
    visibility: hidden;
  }
`

const KeySection = () => {
  const t = useTranslation()

  return (
    <StyledKeySection>
      <a className="anchor" id="support-services"></a>
      <Grid>
        <GridCol col-l="9" offset-l="1">
          <TitleWithStroke
            tag="h2"
            family="antiqueolive"
            modifier="tertiary"
            noMargin
            className="k-u-align-center@xs-down"
          >
            {t('fund_my_project.key.title', {
              parseHtml: true,
            })}
          </TitleWithStroke>
        </GridCol>
      </Grid>
      <List />
    </StyledKeySection>
  )
}

const List = ({ className }) => {
  const t = useTranslation()

  const assetFor = useSelector(getAssetFor)

  return (
    <Grid className={className}>
      <GridCol offset-l="1" col-s="5" offset-s="1">
        <div
          className="kiss-FundMyProject__KeySection__image"
          style={{
            '--background-image': `url(${assetFor(
              'maker/fund_my_project/key.png',
            )})`,
          }}
          title={t('fund_my_project.key.image_alt')}
        />
      </GridCol>

      <GridCol
        col-l="4"
        offset-l="1"
        col-s="6"
        offset-s="0"
        className="kiss-FundMyProject__KeySection__list"
      >
        <div
          className={classNames(
            'k-u-margin-top-triple@s-down',
            'k-u-margin-top-none@m-up',
          )}
        >
          <BulletList
            className="kiss-FundMyProject__KeySection__bulletList"
            items={[
              {
                key: '1',
                style: { marginBottom: '20px' },
                item: (
                  <Text className="kiss-FundMyProject__KeySection__bulletList--text">
                    {t('fund_my_project.key.item1')}
                  </Text>
                ),
              },
              {
                key: '2',
                style: { marginBottom: '20px' },
                item: (
                  <Text className="kiss-FundMyProject__KeySection__bulletList--text">
                    {t('fund_my_project.key.item2')}
                  </Text>
                ),
              },
              {
                key: '3',
                style: { marginBottom: '20px' },
                item: (
                  <Text className="kiss-FundMyProject__KeySection__bulletList--text">
                    {t('fund_my_project.key.item3', { parseHtml: true })}
                  </Text>
                ),
              },
            ]}
          />
        </div>

        <div
          className={classNames(
            'k-u-align-center@xs-down',
            'k-u-align-left@s-up',
            'k-u-margin-top-quadruple',
          )}
        >
          <FundMyProjectButton buttonText={t('fund_my_project.key.button')} />
        </div>
      </GridCol>
    </Grid>
  )
}

export default KeySection
