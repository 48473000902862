import { BackerCard, CheckedIcon, IconBadge } from '@kisskissbankbank/kitten'
import { getLocale } from 'kiss/app/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getDefaultAvatar } from 'kiss/utils/default-avatar'
import { imageDensityAttributes } from 'kiss/utils/responsive-image/image-density-attributes'
import getOr from 'lodash/fp/getOr'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const BackersList = ({ orders }) => {
  const t = useTranslation()
  const locale = useSelector(getLocale)

  return (
    <>
      {orders.map((order) => {
        const { id, totalAmount, validationDate, contribution } = order
        const currencyTranslate = t(
          `currency_symbol.${getOr(0)('currency')(totalAmount)}`,
        )
        const formattedAmount = t(getOr('eur')('cents')(totalAmount) / 100, {
          formatNumber: true,
        })
        const date = new Date(validationDate)
        let backer = getOr({})('backer')(contribution)

        const username =
          !backer || getOr(true)('hideContributor')(contribution)
            ? t('project.backer.anonymous_username')
            : backer.username

        const imgProps =
          !backer || getOr(true)('hideContributor')(contribution)
            ? { src: getDefaultAvatar({ random: true }) }
            : imageDensityAttributes(backer.image.url, { imageWidth: 80 })

        const formattedDate = date.toLocaleDateString(locale, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })

        let descriptionAmount = t('project_info.amount_with_currency', {
          amount: formattedAmount,
          currency: currencyTranslate,
          parseHtml: true,
        })

        // Add non-breaking space between amount and currency.
        descriptionAmount = descriptionAmount.replace(' ', '\xa0')

        const description =
          totalAmount && !getOr(true)('hideAmount')(contribution)
            ? t('project_backers.description', {
                amount: `<span class="k-u-weight-500
                k-u-color-primary-500">${descriptionAmount}</span>`,
                date: `<span class="k-u-weight-500">
                ${formattedDate}</span>`,
                parseHtml: true,
              })
            : t('project_backers.description_without_amount', {
                date: `<span class="k-u-weight-500">
                ${formattedDate}</span>`,
                parseHtml: true,
              })

        return (
          <StyledItem key={`${id}-${date}-backer-card`}>
            <BackerCard
              title={
                backer?.verifiedProfile ? (
                  <div>
                    {username}
                    <IconBadge
                      shape="star"
                      status="info"
                      size="nano"
                      className="k-u-margin-left-single"
                    >
                      <CheckedIcon />
                    </IconBadge>
                  </div>
                ) : (
                  username
                )
              }
              imgProps={imgProps}
              description={description}
            />
          </StyledItem>
        )
      })}
    </>
  )
}
const StyledItem = styled.div`
  display: flex;
`

BackersList.propTypes = {
  orders: PropTypes.array.isRequired,
}

export default BackersList
