import { Title } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import SignInForm from 'kiss/modules/authenticate/components/sign-in'
import React from 'react'
import { Helmet } from 'react-helmet'
import AuthenticateLayout from '../components/layout'

const SignIn = () => {
  const t = useTranslation()

  return (
    <AuthenticateLayout>
      <Helmet title={t('authenticate.login.seo.title')} />

      <div className="k-u-margin-bottom-quadruple k-u-align-center">
        <Title modifier="tertiary" tag="h3">
          {t('login.form.title')}
        </Title>
      </div>

      <SignInForm />
    </AuthenticateLayout>
  )
}

export default SignIn
