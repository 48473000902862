import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { RewardCard, Tag } from '@kisskissbankbank/kitten'

const Tags = ({ reward, isProjectFinished = false }) => {
  const t = useTranslation()

  return (
    <>
      {(!!reward.totalOrders || !!reward.quantity) && (
        <RewardCard.TagList>
          {!!reward.totalOrders && (
            <Tag as="li" variant="light">
              <strong className="k-u-weight-500">{reward.totalOrders}</strong>{' '}
              {t('reward_card.backers', { count: reward.totalOrders })}
            </Tag>
          )}

          {!isProjectFinished && !!reward.quantity && (
            <Tag as="li" variant="light">
              <strong className="k-u-weight-500">
                {reward.remaining ? reward.remaining : 0}
              </strong>{' '}
              {t('reward_card.available', {
                count: reward.remaining ? reward.remaining : 0,
              })}
            </Tag>
          )}
        </RewardCard.TagList>
      )}
    </>
  )
}

export default Tags
