import {
  HEADER_HEIGHT,
  FlexWrapper,
  Button,
  HeroLayout,
  KissKissLoadingAnimation,
  mq,
  pxToRem,
  EnvelopeIconNext,
} from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import {
  getPageColors,
  getProject,
} from 'kiss/modules/project-page/page-state/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import AdminPanel from '../../components/toolbars/admin-panel'
import OwnerPanel from '../../components/toolbars/owner-panel'
import Hero from '../../components/hero'
import Identity from '../../components/identity'
import Contact from '../../components/identity/components/contact'
import Menu from './menu'
import Promo from './promo'
import ShareButton from '../../components/share-button'
import FollowButton from '../../components/follow-button'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { getRouteFor, USER_MESSAGE_NEW } from 'kiss/routes/redux'
import { REWARDS_ID } from '../../constants/id'
import { scrollTo } from '../../helpers/scroll-to'
import Goal from './goal'

const GlobalStyle = createGlobalStyle`
  @media ${mq.mobileAndTablet} {
    .k-FloatingMenu {
      margin-inline: ${pxToRem(-20)};
    }

    .k-HeaderNav--isStuck + * .k-HeroLayout__sticky--both.k-HeroLayout__firstAside {
      top: ${pxToRem(HEADER_HEIGHT)} !important;
    }
  }
`

const PermanentProjectLayout = ({ children }) => {
  const t = useTranslation()
  const project = useSelector(getProject)
  const pageColors = useSelector(getPageColors)
  const routeFor = useSelector(getRouteFor)

  const { viewportIsMOrLess } = useMediaQuery()

  const handleClickSubscribe = () => {
    scrollTo(REWARDS_ID, viewportIsMOrLess)
  }

  const userId = project?.owner
    ? project?.owner?.slug
    : project?.organization?.contact?.slug

  const isOnlyDonation =
    project?.donationEnabled && project?.rewards?.length === 0

  if (!project) {
    return (
      <KissKissLoadingAnimation className="k-u-flex k-u-flex-justifyContent-center" />
    )
  }

  return (
    <DefaultLayout tinyLogo>
      <GlobalStyle />
      <OwnerPanel />
      <AdminPanel />
      <HeroLayout style={pageColors}>
        <Hero>
          <FlexWrapper
            gap={10}
            className="k-u-flex-direction-row@l-up k-u-flex-direction-column k-u-flex-alignItems-center"
          >
            <FlexWrapper direction="row" gap={10}>
              <ShareButton />
              <Button
                tag="a"
                href={routeFor(USER_MESSAGE_NEW, {
                  user: userId,
                })}
                fit="content"
                size="small"
                rounded
                className="k-u-hidden@l-up"
              >
                <EnvelopeIconNext />
                <span>{t('permanent_project.actions.contact')}</span>
              </Button>
            </FlexWrapper>
            <FlexWrapper direction="row" gap={10}>
              <FollowButton />
              <Button
                fit="content"
                size="small"
                modifier="helium"
                rounded
                onClick={handleClickSubscribe}
              >
                {t('permanent_project.text.button.subscribe')}
              </Button>
            </FlexWrapper>
          </FlexWrapper>
          <Goal />
        </Hero>
        {!isOnlyDonation && <Promo />}
        <HeroLayout.Main>
          <HeroLayout.Main.FirstAside sticky="both">
            <Menu />
          </HeroLayout.Main.FirstAside>
          <Identity>
            <Contact />
          </Identity>
          {children}
        </HeroLayout.Main>
      </HeroLayout>
    </DefaultLayout>
  )
}

export default PermanentProjectLayout
