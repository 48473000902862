import { Checkbox, Text, useDeepCompareEffect } from '@kisskissbankbank/kitten'
import FocusTrap from 'focus-trap-react'
import { useTranslation } from 'kiss/hooks/use-translation'
import concat from 'lodash/fp/concat'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import keys from 'lodash/fp/keys'
import map from 'lodash/fp/map'
import * as qs from 'neoqs'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { getProjectTags } from '../../../../page-state/selectors'
import Filter from '../components/filter'
import FilterButton from '../components/filter-button'

const defaultValues = { tags: [] }

const TagsFilter = () => {
  const t = useTranslation()
  const { search } = useLocation()
  const tagsList = useSelector(getProjectTags)
  const { tags } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })
  const [showFilter, setShowFilter] = useState(false)
  const handleButtonClick = () => setShowFilter((current) => !current)
  const handleFilterClose = () => setShowFilter(false)

  const [searchParams, setSearchParams] = useState(defaultValues)

  useDeepCompareEffect(() => {
    setSearchParams({
      tags: tags || [],
    })
  }, [tags])

  const onSelectTags = ({ target: { checked, value, name } }) => {
    const searchKey = name.replace('[]', '')

    setSearchParams({
      ...searchParams,
      [searchKey]: checked
        ? concat(searchParams[searchKey], value)
        : searchParams[searchKey].filter((id) => id !== value),
    })
  }

  if (isEmpty(tagsList)) return null

  return (
    <FocusTrap
      active={showFilter}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <div>
        <FilterButton
          onClick={handleButtonClick}
          showFilter={showFilter}
          id="tags"
          selected={!isEmpty(tags)}
          className="k-u-flex-justifyContent-sb"
        >
          {t('project.news.filter.tags')}
        </FilterButton>

        {showFilter && (
          <Filter
            values={searchParams}
            defaultValues={defaultValues}
            onFilterClose={handleFilterClose}
            mobileFooterActionLabel={t(
              'project.news.filters.date.standalone.mobile_footer',
            )}
          >
            <>
              <Text
                tag="p"
                size="small"
                weight="500"
                color="font1"
                className="k-u-margin-bottom-double"
              >
                {t('project.news.filter.tags')}
              </Text>
              {flow(
                keys,
                map((key) => {
                  return (
                    <Checkbox
                      id={key}
                      key={key}
                      label={`${key} (${tagsList[key]})`}
                      value={key}
                      name="tags"
                      checked={searchParams.tags?.includes(key)}
                      onChange={onSelectTags}
                    />
                  )
                }),
              )(tagsList)}
            </>
          </Filter>
        )}
      </div>
    </FocusTrap>
  )
}

export default TagsFilter
