import {
  Accordeon,
  Button,
  Container,
  Grid,
  GridCol,
  Paragraph,
  Text,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getFaqs,
  getManagerPageRoute,
  isCurrentUserOwner,
} from 'kiss/modules/project-page/page-state/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import AdminPanel from '../components/toolbars/tabs-admin-panel'

const Faqs = () => {
  const t = useTranslation()
  const faqs = useSelector(getFaqs)
  const currentUserIsOwner = useSelector(isCurrentUserOwner)
  const managerPageRoute = useSelector(getManagerPageRoute)

  return (
    <Container>
      {currentUserIsOwner && (
        <AdminPanel className="k-u-margin-bottom-triple k-u-margin-bottom-quintuple@s-up">
          {faqs.length > 0 && (
            <Button fit="content" as="a" href={`${managerPageRoute}#faq`}>
              {t('project.faq.list_faq')}
            </Button>
          )}
          <Button
            fit="content"
            as="a"
            modifier="helium"
            href={`${managerPageRoute}#faq`}
            className="k-u-margin-left-single@s-up k-u-margin-top-single@xs-down"
          >
            {t('project.faq.add_faq', { parseHtml: true })}
          </Button>
        </AdminPanel>
      )}

      <Grid>
        <GridCol col-l="6" offset-l="3">
          <Accordeon closeOnClick isAnimated multiple>
            {faqs.map((faq) => (
              <Accordeon.Item key={faq.id}>
                <Accordeon.Header>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="500"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {faq.question}
                  </Text>
                </Accordeon.Header>
                <Accordeon.Content>
                  <Paragraph
                    tag="span"
                    modifier="secondary"
                    normalLineHeight
                    noMargin
                  >
                    {faq.answer.split('\n').map((item, key) => {
                      return (
                        <span key={key}>
                          {item}
                          <br />
                        </span>
                      )
                    })}
                  </Paragraph>
                </Accordeon.Content>
              </Accordeon.Item>
            ))}
          </Accordeon>
        </GridCol>
      </Grid>
    </Container>
  )
}

export default Faqs
