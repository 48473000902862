import React from 'react'
import { AlertBox, CubeIconNext, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'

const CartHelper = ({ date, period }) => {
  const t = useTranslation()

  const textHelper2 =
    period === 'monthly'
      ? t('contribute.cart.subscriptions.helper2_monthly', {
          parseHtml: true,
        })
      : t('contribute.cart.subscriptions.helper2_quarterly', {
          parseHtml: true,
        })

  return (
    <AlertBox
      status="disabled"
      displayIcon={false}
      size="large"
      className="k-u-hidden@s-down"
    >
      <div className="k-u-flex k-u-flex-gap-singleHalf k-u-flex-alignItems-center">
        <CubeIconNext
          color="var(--color-grey-700)"
          style={{ flex: '0 0 24px' }}
        />
        <div>
          <Text tag="p">
            {t('contribute.cart.subscriptions.helper', {
              parseHtml: true,
              date,
            })}
          </Text>
          <Text tag="p">{textHelper2}</Text>
        </div>
      </div>
    </AlertBox>
  )
}

export default CartHelper
