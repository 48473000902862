import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import { useTranslation } from 'kiss/hooks/use-translation'

import { mq, pxToRem } from '@kisskissbankbank/kitten'

import DefaultLayout from 'kiss/app/layouts/default-layout'
import CarouselSection from 'kiss/modules/communication/fund-my-project/components/carousel-section'
import HeaderWithBackground from 'kiss/modules/communication/fund-my-project/components/header-with-background'
import KeySection from 'kiss/modules/communication/fund-my-project/components/key-section'
import PartnersSection from 'kiss/modules/communication/fund-my-project/components/partners-section'
import TimelineSection from 'kiss/modules/communication/fund-my-project/components/timeline-section'
import TipsSection from 'kiss/modules/communication/fund-my-project/components/tips/tips-section'
import SolutionsTriptych from 'kiss/modules/communication/fund-my-project/components/triptych/solutions-triptych'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  --wrapper-spacing: ${pxToRem(50)};
  margin-bottom: var(--wrapper-spacing);
  gap: var(--wrapper-spacing);

  @media ${mq.desktop} {
    --wrapper-spacing: ${pxToRem(80)};
  }
  @media ${mq.desktop} {
    --wrapper-spacing: ${pxToRem(100)};
  }
`

const FundMyProject = () => {
  const t = useTranslation()

  return (
    <DefaultLayout>
      <Helmet
        title={t('fund_my_project.header.title.default')}
        meta={[
          {
            name: 'description',
            content: t('fund_my_project.header.description.default'),
          },
        ]}
      />

      <StyledWrapper>
        <HeaderWithBackground />
        <SolutionsTriptych />
        <KeySection />
        <TimelineSection />
        <TipsSection />
        <CarouselSection />
        <PartnersSection />
      </StyledWrapper>
    </DefaultLayout>
  )
}

export default FundMyProject
