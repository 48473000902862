import React, { useEffect } from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useDispatch, useSelector } from 'react-redux'
import DefinitionList from './definition-list'
import ProjectState from './state'
import Owner from './owner'
import ProjectInfo from './info'
import Challenges from './challenges'
import Feedbacks from './feedbacks'
import MarkAsProcessed from './mark-as-processed'
import Buttons from './buttons'
import { isCurrentUserAdmin as isAdmin } from 'kiss/session/redux'
import {
  fetchProjectAdminPanelData,
  markAdminPanelInitialDataAsFetched,
} from './actions'
import {
  getProjectSlug,
  isAdminPanelDataFetched,
} from 'kiss/modules/project-page/page-state/selectors'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import {
  AdaptableGrid as Grid,
  AdaptableGridCol as GridCol,
  Container,
  Marger,
  Title,
} from '@kisskissbankbank/kitten'

const AdminPanel = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const dataFetched = useSelector(isAdminPanelDataFetched)
  const isCurrentUserAdmin = useSelector(isAdmin)
  const projectSlug = useSelector(getProjectSlug)

  useEffect(() => {
    if (isCurrentUserAdmin && projectSlug) {
      dispatch(fetchProjectAdminPanelData()).then(() => {
        dispatch(markAdminPanelInitialDataAsFetched())
      })
    }
  }, [isCurrentUserAdmin, projectSlug])

  if (!isCurrentUserAdmin) return null

  return (
    <Container>
      <Grid className="k-u-flex-alignItems-end">
        <GridCol col="12" col-s="6" offset-s="1">
          <Title
            modifier="quaternary"
            margin={false}
            className="k-u-margin-top-double"
          >
            {t('projects.admin_panel.title')}
          </Title>
        </GridCol>
        <GridCol col="12" col-s="5">
          {dataFetched && <Buttons />}
        </GridCol>
      </Grid>
      <Grid>
        <GridCol col="12" col-s="11" offset-s="1">
          <Marger
            top={{ default: 2, fromS: 4 }}
            bottom={{ default: 2, fromS: 4 }}
          >
            {!dataFetched && (
              <div className="k-u-margin-bottom-double k-u-flex k-u-flex-alignItems-center k-u-flex-justifyContent-center">
                <LoadingAnimation />
              </div>
            )}
            {dataFetched && (
              <DefinitionList>
                <ProjectState />
                <Owner />
                <ProjectInfo />
                <MarkAsProcessed />
                <Challenges />
                <Feedbacks />
              </DefinitionList>
            )}
          </Marger>
        </GridCol>
      </Grid>
    </Container>
  )
}

export default AdminPanel
